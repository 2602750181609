import { useEffect, useState } from "react";
import state from "../../../state/state.js"
import s from '../mySportClubs/SportClubs.module.css'
import sAdmin from '../adminCompetition/adminCompetition.module.css';
import lang from "../../../state/language.js";





function CommunityCoaches({isSmallScreen,langSwitch}) {

  const [data, setData] = useState('');
  const [visibleAthletes, setVisibleAthletes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const athletesPerPage = 45;
  const [fetching, setFetching] = useState(true);

  function scrollHandler(e) {
    const scrollHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );

    if (scrollHeight - (window.scrollY + window.innerHeight) < 100) {
      setFetching(true);
    }
  }


  useEffect(() => {
    if (fetching&&data.length == 0) {
      const endIndex = currentPage * athletesPerPage;
      const newVisibleAthletes = result.slice(0, endIndex);
      setVisibleAthletes((prevVisibleAthletes) => [ ...newVisibleAthletes]);
    
     
      setCurrentPage(currentPage + 1);
      setFetching(false);
    }
  }, [fetching]);

  useEffect(() => {
    if(data.length == 0){
    document.addEventListener('scroll', scrollHandler);
    document.addEventListener('touchmove', scrollHandler);
  }
    return function () {
      document.removeEventListener('scroll', scrollHandler);
      document.removeEventListener('touchmove', scrollHandler);
    }
  }, []);

const notCoahes= state.athletes.filter(item=>item.status==='Тренер')

  const handleTitleChange = (e) => {
    setData(e.target.value);
    // Якщо дані введені, викликаємо фільтрацію і вивід результатів
    if (e.target.value.trim() !== '') {
      const notCoahes = state.athletes.filter(item => item.status === 'Спортсмен');
      const filteredResults = notCoahes.filter(item => {
        const fullName = `${item.secondName} ${item.personName} ${item.fatherName}`.toLowerCase();
        const searchData = e.target.value.toLowerCase();
        return fullName.includes(searchData);
      });
      setVisibleAthletes(filteredResults);
    } else {
      // Якщо дані не введені, використовуємо весь масив notCoahes
      const endIndex = currentPage * athletesPerPage;
      const newVisibleAthletes = notCoahes.slice(0, endIndex);
      setVisibleAthletes(newVisibleAthletes);
      setCurrentPage(currentPage + 1);
    }
  };

 


//   форма пошуку
let result
data.length>0?
   result = notCoahes.filter(item => {
    const fullName = `${item.secondName} ${item.personName} ${item.fatherName}`.toLowerCase();
    const searchData = data.toLowerCase();  
    return fullName.includes(searchData);
  }):result=notCoahes


////////////////////////////////////////////////////////////////////////

  return (<>


        <div className={sAdmin.communityFind}>
          <label htmlFor="title"><span><b>
          {lang[langSwitch]. startTypingName /*Почніть вводити призвище та ім'я*/} 
            </b></span> </label><br /><br />
          <input onChange={handleTitleChange} type="text" id="title" name="title" />
         </div>
    <div className={!isSmallScreen?s.contaner4:null}>
    {visibleAthletes.map((coach) => {
const myTeamArray=state.athletes.filter(item=>item.coach==coach.id||item.coach2==coach.id)
const myCoachArray=state.athletes.filter(item=>item.coach==coach.id&&item.status==='Тренер'||item.coach2==coach.id&&item.status==='Тренер')
const mainCoach= state.sportClubs.filter(item => item.creator == coach.id);


return(<>
  

     
           <div className={s.communityCell}>{/* блок с фото тренера */}
               <div className={s.categoryImg2}>
                   <img src={coach.photo?coach.photo:state.noAvatarPhoto} 
                   alt={state.myCoach(coach.id)} />
               </div>

               <div className={s.categoryData}>
                   <div className={s.coachName}>{`${coach.secondName} 
                           ${coach.personName} 
                            ${coach.fatherName}`}</div>
 <div className={s.region}>
                             <div className={s.athleteId}>  <b>id: {coach.id}</b> </div>
                
                  {state.regionShort(coach.region)}</div>
                      {coach.regionLeader?(<>
                   <div>
                   {lang.regionLeders(langSwitch,coach.regionLeader)}
                   
                    </div>
                   </>):null}
                   <div >
                    
                   {lang[langSwitch].qualificationShort /*Кваліф.: */}
                    
                    <b> {langSwitch==='ukr'?coach.dan:
                          langSwitch==='eng'?lang.danEng(coach.dan):null}                      
                      &nbsp;/ &nbsp;
                      {langSwitch==='ukr'?coach.rank:
                          langSwitch==='eng'?lang.rankEng(coach.rank):null} 
                      
                      </b></div>
                   <div >
                   {lang[langSwitch].PCSS /*ФСТ */}:
                    <b> {coach.fst}</b></div>
                
                  <div >
                    {mainCoach.length>0?(<>
                 <div className={s.contaner5}>
                  <div>
                     {lang[langSwitch].seniorCoachShort /* Старший тренер*/}: 
                     </div>
                    <div>
                      <b> {mainCoach.map((item,index)=>{
                        return(<><span key={index}>{item.name}
                        {index+1<mainCoach.length?(<br/>):null}</span></>)
                        })}</b>
                       </div> 
                       </div>
                    </>):null}
                  </div>



                   <div>
                   {lang[langSwitch].team /*Команда*/}:&nbsp;&nbsp;
                    <b>{myTeamArray.length} </b> 
                    {lang[langSwitch].athletesInGen /* атлетів */}
                    <b> {myCoachArray.length} </b>
                    {lang[langSwitch].coachesInGen /*тренерів*/}
                     </div>
               </div>
</div>
     {isSmallScreen?(<br/>):null} 

    </>)})}
  </div> </>)
}

export default  CommunityCoaches
