import React, { useEffect, useState } from 'react';
import s from './adminCompetition.module.css'
import lang from '../../../state/language';
import state from '../../../state/state';
import ShootingPlacesCell from './ShootingPlacesCell';

function ShootingPlaces ({resultForSaving, grid, langSwitch, setGrids, keyData}) {

const [gridAthletes, setGridAthletes] = useState (grid.athleteList)




let placesArray = []
if(gridAthletes && Array.isArray(gridAthletes)&& gridAthletes.length>0){
 placesArray = Array.from({ length: gridAthletes.length }, (_, i) => i + 1);
}

//додаємо результат стрільби спортсмена до сіток
useEffect(() => {

  setGridAthletes((prev) => 
    prev.map((item) => {
      const indexResult = resultForSaving.findIndex(
        (itemResult) => +itemResult.id === +item.id
      );

      return {
        ...item,
        shootingResult: indexResult === -1 ? 0 : resultForSaving[indexResult].shootingResult
      };
    })
  );
//////////////////////////////////////////////////////////////////////////


setGridAthletes((prev)=>{
  const data = prev.sort((a, b)=> +b.shootingResult - +a.shootingResult)
  let placeArrayCounter = 0, lastAthleteResult = 0

  const result = data.map((itemAthlete, index)=>{
    if (index===0){//перша ітерація
      lastAthleteResult = itemAthlete.shootingResult //зберігаємо результати для на випадок кількох претендентів на одне місце
      placeArrayCounter++ //лічимо номери масиву з місцями
return{...itemAthlete, shootingPlace:placesArray[placeArrayCounter-1]}
    }else{
      if (lastAthleteResult === itemAthlete.shootingResult){
        return{...itemAthlete, shootingPlace:placesArray[placeArrayCounter-1]}
      }else{
        lastAthleteResult = itemAthlete.shootingResult //зберігаємо результати для на випадок кількох претендентів на одне місце
        placeArrayCounter++ //лічимо номери масиву з місцями
  return{...itemAthlete, shootingPlace:placesArray[placeArrayCounter-1]}
      }

     
    }
  })
return result
})

}, [resultForSaving]);  

useEffect(() => {
  setGrids((prev) => {
    return prev.map((prevItem) => {
      if (grid.category === prevItem.category) {
        const updatedAthleteList = prevItem.athleteList.map((data) => {
          const foundItem = gridAthletes.find((item) => +item.id === +data.id);
          return foundItem ? foundItem : data;
        });

        return { ...prevItem, athleteList: updatedAthleteList };
      }
      return prevItem;
    });
  });
}, [gridAthletes]);






return(<>
{gridAthletes.map((itemAthlete, index)=>{
  return (<React.Fragment key={keyData+index}>
    <ShootingPlacesCell 
          itemAthlete={itemAthlete}
          num={index+1}
          setGridAthletes={setGridAthletes}
          langSwitch={langSwitch}
    />

</React.Fragment>)
})}
</>)

};

export default ShootingPlaces;