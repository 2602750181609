import { useEffect, useState } from 'react'
import state from '../../../../state/state'
import s from './adminPoomsae.module.css'
import AthleteForCD from './athleteForCD'


// один блок сітки при работі на пультах
function AthletesTableForCD({cortSecretary, setSaveSwitcher, judgeData, indexDbData, athletesListData, grid, setErrorMessage, setWinnerAthletes, setWinner,langSwitch,competition,setGridForRestore,setPoomsaeGrids}) {
const battleNumbers=grid.battleNumbers
const cort=grid.cort

    const [gridId, setGridId] = useState(null);

    const [judgeScoreArray, setJudgeScoreArray] = useState([]);
    const [judgeScoreResult, setJudgeScoreResult] = useState(null);
    const [judgeScoreTechnique, setJudgeScoreTechnique] = useState(null)
    const [judgeScorePresentation, setJudgeScorePresentation] = useState(null)
    
    const [round, setRound] = useState(null);
    
    const [athletesList, setAthletesList] = useState(athletesListData?athletesListData:[]);
    const [preliminaryTechniqueData, setPreliminaryTechniqueData] = useState([])
    const [preliminaryPresentationData, setPreliminaryPresentationData] = useState([])



    const [semiFinalAtletes, setSemiFinalAtletes] = useState(athletesListData.length > 8 && athletesListData.length < 20 ? athletesListData : grid.semiFinalAtletes&&Array.isArray( grid.semiFinalAtletes)&& grid.semiFinalAtletes.length>0?grid.semiFinalAtletes: [])
    const [semiFinalTechniqueData, setSemiFinalTechniqueData] = useState([])
    const [semiFinalPresentationData, setSemiFinalPresentationData] = useState([])


    const [finalAtletes, setFinalAtletes] = useState(athletesListData.length < 9 ? athletesListData : grid.finalAtletes&&Array.isArray(grid.finalAtletes)&&grid.finalAtletes.length>0?grid.finalAtletes:[])
    const [finalTechniqueData, setFinalTechniqueData] = useState([])
    const [finalPresentationData, setFinalPresentationData] = useState([])

    const [control0, setControl0] = useState(false)
    const [control, setControl] = useState(false)
    const [control2, setControl2] = useState(false)
     const [control3, setControl3] = useState(false)


  useEffect(()=>{
    if(!grid.finalAtletes||grid.finalAtletes.length===0){ 
        grid.finalAtletes=finalAtletes 
     
    }
     if(!grid.semiFinalAtletes || grid.semiFinalAtletes.length===0){ 
        grid.semiFinalAtletes=semiFinalAtletes
     
    }
  
  },[semiFinalAtletes, finalAtletes,])


    useEffect(() => {
        if (!control0&&indexDbData) {
            setAthletesList(athletesListData)
            
            setControl0(true)
        }

        
        if (!control) {
            setSemiFinalAtletes(() => {

                if (indexDbData && (grid.semiFinalAtletes && Array.isArray(grid.semiFinalAtletes) && grid.semiFinalAtletes.length > 0)) {
                    return grid.semiFinalAtletes
                }

                else if (athletesListData.length > 8 && athletesListData.length < 20) {
                    return athletesListData
                } else { return [] }
            }

            );
        }
    }, [athletesListData, grid.semiFinalAtletes, athletesListData]);

    useEffect(() => {
       if(!control2){
            setFinalAtletes(()=>{

                if(indexDbData&&(grid.finalAtletes && Array.isArray(grid.finalAtletes) && grid.finalAtletes.length > 0)){
                    return grid.finalAtletes
                }

               else if(athletesListData.length < 9){
                    return athletesListData
                }
                
                else{ return []}
            }

            );
      }
    }, [athletesListData, grid.finalAtletes]);


    useEffect(() => {grid.athletes = athletesList}, [athletesList]);
    //попередні залежності для наступного хука
    const [prevDependencies, setPrevDependencies] = useState({gridId:null});
      
      useEffect(() => {
          if ( gridId !== prevDependencies.gridId ) {
              // Виконати код тільки якщо всі залежності змінилися
            
              function update(valueData, func) {
         
                  func((prevState) => {
                      const index = prevState.findIndex((item) => item.gridId === gridId);
                      const newValue = Number(valueData);
      
                      if (index !== -1) {
                          const updatedState = [...prevState];
                          updatedState[index] = { ...updatedState[index], result: newValue };
                          return updatedState;
                      } else {
                          return [
                              ...prevState,
                              { gridId: gridId, result: newValue }
                          ];
                      }
                  });
              }
              if(round==='preliminary'){
                update(judgeScoreTechnique, setPreliminaryTechniqueData)
                 update(judgeScorePresentation, setPreliminaryPresentationData)
            }
            if(round==='semiFinal'){
                update(judgeScoreTechnique, setSemiFinalTechniqueData)
                 update(judgeScorePresentation, setSemiFinalPresentationData)
            }
            
            if(round==='final'){
                    update(judgeScoreTechnique, setFinalTechniqueData)
                    update(judgeScorePresentation, setFinalPresentationData)
            }
                
              // Оновлюємо стейт з новими значеннями залежностей
              setPrevDependencies({
               
                  gridId
              });
          }
      }, [judgeScoreArray, judgeScoreResult, judgeScoreTechnique, judgeScorePresentation, gridId ]);
      



    let athletesList1, athletesList2, athletesList3, count = 0;

    if (round === 'semiFinal'&&semiFinalAtletes.length===0) {
        if (athletesList.length > 19) {
            if (athletesList.every(athlete => athlete.preliminaryTotal !== null)) {
                athletesList1 = JSON.parse(JSON.stringify(athletesList)).sort((a, b) => b.preliminaryTotal - a.preliminaryTotal);
                athletesList1.forEach((item, index) => {
                    item.place = index + 1;
                });
              
                if (!control) {
                     finalResultForAthletes(athletesList1,'preliminaryResult','preliminaryTotal','preliminaryScore')
           
                    if(semiFinalAtletes.length>0){
                        setSemiFinalAtletes((prev)=>{                       
                            const prevArray = structuredClone(prev)
                            const data = athletesList1.slice(0, 19)
                              const result=[]
                              prevArray.forEach((item)=>{
                               if(item.judgeScore&&item.judgeScore.semiFinalScore) {
                                result.push(item)
                                }else{
                                    const index = data.findIndex((ind)=>ind.gridId===item.gridId)
                                    result.push(data[index])
                                }
                            })
                        return result               
                        });
                        }else{
                            setSemiFinalAtletes(athletesList1.slice(0, 19))
                        }
                    
                    setControl(true); 
                    grid.athletes=athletesList
                     grid.semiFinalAtletes=semiFinalAtletes                         
                
                    setPoomsaeGrids((prev)=>{
                        const prevGrids=structuredClone(prev)//глибока копія
                        const prevGridIndex = prevGrids.findIndex((item)=>item.category===grid.category)
                        prevGrids[prevGridIndex] = grid
                        return prevGrids
                    })
                   

                    setGridForRestore(grid)               
                                                       
                }
            }
        }
    }

    if (round === 'final'&& finalAtletes.length===0) {
        if (semiFinalAtletes.length > 8) {
            if (semiFinalAtletes.every(athlete => athlete.semiFinalTotal !== null)) {
                athletesList2 = JSON.parse(JSON.stringify(semiFinalAtletes)).sort((a, b) => b.semiFinalTotal - a.semiFinalTotal);
                athletesList2.forEach((item, index) => {
                    item.place = index + 1;
                });
                
                if (!control2) {
                    finalResultForAthletes(athletesList2,'semiFinalResult','semiFinalTotal','semiFinalScore')
                   
                   if(finalAtletes.length>0){
                    setFinalAtletes((prev)=>{                       
                        const prevArray = structuredClone(prev)
                        const data = athletesList2.slice(0, 8)
                          const result=[]
                          prevArray.forEach((item)=>{
                           if(item.judgeScore&&item.judgeScore.finalScore) {
                            result.push(item)
                            }else{
                                const index = data.findIndex((ind)=>ind.gridId===item.gridId)
                                result.push(data[index])
                            }
                        })
                    return result               
                    });
                    }else{
                        setFinalAtletes(athletesList2.slice(0, 8))
                    }
                    setControl2(true);   
                    grid.athletes=athletesList
                    grid.finalAtletes=finalAtletes           
                    
                    setPoomsaeGrids((prev)=>{
                        const prevGrids=structuredClone(prev)//глибока копія
                        const prevGridIndex = prevGrids.findIndex((item)=>item.category===grid.category)
                        prevGrids[prevGridIndex] = grid
                        return prevGrids
                    })
                   

                    setGridForRestore(grid)               
                   
                }
            }
        }
    }
 
          if (round === 'winner') {
           
        if (finalAtletes.length > 0) {
           
            if (finalAtletes.every(athlete => athlete.finalTotal !== null)) {
                athletesList3 = JSON.parse(JSON.stringify(finalAtletes)).sort((a, b) => b.finalTotal - a.finalTotal);
                athletesList3.forEach((item, index) => {
                    item.place = index + 1;                                       
                });                

                if (!control3) {
                    finalResultForAthletes(athletesList3,'finalResults', 'finalTotal','finalScore')
                    setWinnerAthletes(athletesList3.slice(0, 4));
                    setWinner(athletesList3[0])                   
                    grid.athletes=athletesList                   
                   
                    setPoomsaeGrids((prev)=>{
                        const prevGrids=structuredClone(prev)//глибока копія
                        const prevGridIndex = prevGrids.findIndex((item)=>item.category===grid.category)
                        prevGrids[prevGridIndex] = grid
                        return prevGrids
                    })
                   

                    setGridForRestore(grid)
                    
                    setControl3(true);
                }
            }
        }
    }
    function finalResultForAthletes(array, data,  data_3, score) {
        setAthletesList((prevData) => {
          // Створюємо новий масив на основі prevData, змінюючи потрібні елементи
        
          return prevData.map((athlete) => {
           
            // Знаходимо відповідний елемент в масиві array за gridId
            const foundItem = array.find((item) => item.gridId === athlete.gridId);
           const judgeData = judgeScoreArray.find((item) => item.gridId === athlete.gridId);
      
            // Якщо знайдений елемент існує, оновлюємо його дані
            if (foundItem) {
              return {
                ...athlete,
                place: foundItem.place,
                [data]:foundItem[data],                
                [data_3]:foundItem[data_3],
                fullScore:foundItem.fullScore,
                judgeScore: {
                    ...athlete.judgeScore, // Зберігаємо інші значення judgeScore
                    [score]: judgeData?judgeData.scorse:foundItem.judgeScore[score]
                  }
              };
            }
      
            // Якщо знайдений елемент не існує, повертаємо елемент без змін
            return athlete;
          });
        });
       
          
      }
      
  


    function buttonFunction(text, round) {
        return (<div className={s.noPrint}>
            {round === "preliminary" && (<>               
                    <button onClick={() => { addScores(round, setAthletesList, preliminaryTechniqueData, preliminaryPresentationData) }}>
                        {text}
                    </button>            
            </>)}
            {round === "semiFinal" && (<>               
                    <button onClick={() => { addScores(round, setSemiFinalAtletes, semiFinalTechniqueData, semiFinalPresentationData) }}>
                        {text}
                    </button>  
            </>)}
            {round === "final" && (<>
                    <button onClick={() => { addScores(round,setFinalAtletes, finalTechniqueData, finalPresentationData, grid.completed=true)}}>
                        {text}
                    </button>
            </>)}
        </div>)
    }
    function addScores(round, func, tech, present) {

if(!round || !func || tech.length===0 || present.length===0){
    if(round === 'final'){setRound('winner')}
    if (round === 'preliminary') {setRound('semiFinal')}
    if (round === 'semiFinal') {setRound('final')}
    return}

        // Оновлюємо список атлетів
        func((prevAthletesList) => {
            const updatedAthletesList = prevAthletesList.map((item) => {
                const techniqueIndex = tech.findIndex((t) => t.gridId === item.gridId);
                const presentationIndex = present.findIndex((p) => p.gridId === item.gridId);
              
        
                if (techniqueIndex !== -1 && presentationIndex !== -1) {
                    const technique = tech[techniqueIndex]?.result || 0;
                    const presentation = present[presentationIndex]?.result || 0;
                   
        
                    const count =  Math.round(((technique + presentation)*100)/100)
        
                    const fullScore = item.fullScore ? item.fullScore + count : count; // Додаємо перевірку на ініціалізацію fullScore
        
                    // Оновлюємо результат атлета в залежності від раунду
                    if (round === 'preliminary') {
                        return {
                            ...item,
                            preliminaryResult: {
                                technique,
                                presentation,
                                allScore: Number((technique + presentation).toFixed(2))
                            },
                            
                            preliminaryTotal: count,
                            fullScore: count
                        };
                    }
        
                    if (round === 'semiFinal') {
                        return {
                            ...item,
                            semiFinalResult: {
                                technique,
                                presentation,
                                allScore: Number((technique + presentation).toFixed(2))
                            },                            
                            semiFinalTotal: count,
                            fullScore: fullScore
                        };
                    }
        
                    if (round === 'final') {
                        return {
                            ...item,
                            finalResults: {
                                technique,
                                presentation,
                                allScore: Number((technique + presentation).toFixed(2))
                            },                           
                            finalTotal: count,
                            fullScore: fullScore
                        };
                    }
                } else {
                    setErrorMessage('Ви прописали не усі рахунки відбіркових виступів.');
                    return item; // Повертаємо оригінальний елемент, якщо немає результатів
                }
            });
        
            return updatedAthletesList; // Повертаємо оновлений список
        });
        
        

        if (round === 'preliminary') {
            setRound('semiFinal')
            setPreliminaryTechniqueData([]);
            setPreliminaryPresentationData([]);           
        }
        if (round === 'semiFinal') {
            setRound('final')
            setSemiFinalTechniqueData([]);
            setSemiFinalPresentationData([]);           
        }
        if (round === 'final') {
            setRound('winner')
            setFinalTechniqueData([]);
            setFinalPresentationData([]);           
        }
    }
       // Модальне вікно з сітками

       ////////////////////////////////////////////////////////////////////////////////

return (<>

<tbody className={s.tBody}>
        
        <tr>
        {/* відбірковий тур */} 
        {athletesListData.length > 19 ? (<>
            <td style={{ width: '33%' }}><table><tr><td>
                {athletesList.map((item, index) => {
                    const battleNumber =battleNumbers[count]
                    count++
                return(
                    <AthleteForCD  
                        key={grid.category+index}              
                   category={grid.category} 
                    id={competition.id}
                    item={item}
                    index={index}
                    cort={cort}                  
                    battleNumber={battleNumber}
                    langSwitch={langSwitch}
                    judgeData={judgeData}
                    setJudgeScoreTechnique={setJudgeScoreTechnique}
                    setJudgeScorePresentation={setJudgeScorePresentation}
                    setJudgeScoreResult={setJudgeScoreResult}
                    setJudgeScoreArray={setJudgeScoreArray}
                    setGridId={setGridId}
                    setRound={setRound}
                    indexDbData={indexDbData}
                    round='preliminary'
                    setPoomsaeGrids={setPoomsaeGrids}
                    setSaveSwitcher={setSaveSwitcher}
                    cortSecretary={cortSecretary}
                    />
                    )
                })}
              
                {buttonFunction('Рахунки відбіркового туру', 'preliminary')}
                </td></tr></table>
            </td>
        </>) : null}

        {/* півфінал */}
      
            {semiFinalAtletes.length > 0 ? (<>
 <td style={{ width: athletesList.length > 19 ? '33%' : '50%' }}> 
 <table><tr><td>
                {semiFinalAtletes.map((item, index) => { 
                          const battleNumber =battleNumbers[count]   
                               count++
                  return(
                 <AthleteForCD
                    key={grid.category+index}
                   category={grid.category} 
                    id={competition.id}
                    item={item}
                    index={index}
                    cort={cort}                  
                    battleNumber={battleNumber}
                    langSwitch={langSwitch}
                    judgeData={judgeData}
                    setJudgeScoreTechnique={setJudgeScoreTechnique}
                    setJudgeScorePresentation={setJudgeScorePresentation}
                    setJudgeScoreResult={setJudgeScoreResult}
                    setJudgeScoreArray={setJudgeScoreArray}
                    setGridId={setGridId}
                    setRound={setRound}
                    indexDbData={indexDbData}
                    round='semiFinal'
                    setPoomsaeGrids={setPoomsaeGrids}
                    setSaveSwitcher={setSaveSwitcher}
                    cortSecretary={cortSecretary}
                    />
                  
                 )
                })}
{buttonFunction('Рахунки Півфіналу', 'semiFinal')} 
         </td></tr></table>
        </td>
            </>) : (<>
            {/* порожній масив півфіналу */}
                {athletesList.length > 19 ? (<>
                    <td style={{ width: athletesList.length > 19 ? '33%' : '50%' }}> 
                    <table><tr><td>
                    {Array(19).fill(null).map((item, index) => {                       
                        return (
                            <div className={index === 0 ? s.tableFirstItem : s.tableItem} key={index}>
                                <div className={s.battleNumForJudge}>{cort}.{battleNumbers[count]}</div>
                                <div className={s.invisible}> {count++}</div>
                                <div>
                                    <div className={s.athleteDivForJudge} >

                                    </div>

                                    <div className={s.teamDivForJudge}></div>

                                </div>
                            </div>
                       )
                    })}            
         </td></tr></table>
         </td>
                </>) : null}
    
            </>)}
           

        {/* фінал */}
     
            
            {finalAtletes.length>0?(<>
                <td style={{ width: athletesList.length > 19 ? '33%' : '50%' }}> 
                <table><tr><td>
     
       {finalAtletes.map((item, index) => {   
                     const battleNumber =battleNumbers[count]
                     count++
                     return(           
                  <AthleteForCD
                  key={grid.category+index} 
                   category={grid.category} 
                    id={competition.id}
                    item={item}
                    index={index}
                    cort={cort}                  
                    battleNumber={battleNumber}
                    langSwitch={langSwitch}
                    judgeData={judgeData}
                    setJudgeScoreTechnique={setJudgeScoreTechnique}
                    setJudgeScorePresentation={setJudgeScorePresentation}
                    setJudgeScoreResult={setJudgeScoreResult}
                    setJudgeScoreArray={setJudgeScoreArray}
                    setGridId={setGridId}
                    setRound={setRound}
                    indexDbData={indexDbData}
                    round='final'
                    setPoomsaeGrids={setPoomsaeGrids}
                    setSaveSwitcher={setSaveSwitcher}
                    cortSecretary={cortSecretary}
                    />
                )  
                })}
               
                {buttonFunction('Рахунки Фіналу', 'final')}
                </td></tr></table>
                 </td>
                </>):(<>
                {/* Порожній масив фіналу */}
                  
                    <td style={{ width: athletesList.length > 19 ? '33%' : '50%' }}> 
                    <table><tr><td>
                    {Array(8).fill(null).map((item, index) => {
                     
                        return (                        
                            <div className={index === 0 ? s.tableFirstItem : s.tableItem} key={index}>
                                <div className={s.battleNumForJudge}>{cort}.{battleNumbers[count]}</div>
                                <div className={s.invisible}> {count++}</div>
                                <div>
                                    <div className={s.athleteDivForJudge} >

                                    </div>

                                    <div className={s.teamDivForJudge}></div>

                                </div>
                            </div>
                        )
                    })}
              
         </td></tr></table>
         </td>
              
                </>)}
            
                </tr>

</tbody>
       
    </>)
}

export default AthletesTableForCD