import React from 'react';
import s from '../result.module.css';
import lang from '../../../../state/language';

function InformCards({setSwitcher,langSwitch}) {

   

    return (<>
   <div className={s.resultTitle}>
    <div className={s.h3}>
    {lang[langSwitch].informationPointers /*Інформаційні вказівники*/} </div>
   </div>

        <div className={s.mainDivForPrint}> <table ><tbody><tr><td>           
            <div className={s.arrows}>
            <img  src={'/img/main/arrow1.png'}/>
            </div>
         </td></tr></tbody></table></div>
       <div className={s.mainDivForPrint}> <table ><tbody><tr><td>           
            <div className={s.arrows}>
            <img  src={'/img/main/arrow2.png'}/>
            </div>
         </td></tr></tbody></table></div>
<div className={s.mainDivForPrint}> <table ><tbody><tr><td>           
            <div className={s.arrows}>
            <img  src={'/img/main/arrow3.png'}/>
            </div>
         </td></tr></tbody></table></div>

 <div className={s.mainDivForPrint}> <table ><tbody><tr><td>           
            <div className={s.infoForHall}>
            {lang[langSwitch].secretary /*Секретар*/} 
               </div>
         </td></tr></tbody></table></div>
         
<div className={s.mainDivForPrint}> <table ><tbody><tr><td>           
            <div className={s.inspectionTest}>
              {lang[langSwitch].mandateCommission /*Мандатна комісія*/}
               </div>
         </td></tr></tbody></table></div> 
<div className={s.mainDivForPrint}> <table ><tbody><tr><td>           
            <div className={s.inspectionTest}>
            {lang[langSwitch].weighingMen /*Зважування чоловіки*/}
               </div>
         </td></tr></tbody></table></div> 
         <div className={s.mainDivForPrint}> <table ><tbody><tr><td>           
            <div className={s.inspectionTest}>
            {lang[langSwitch].weighingWoman /*Зважування жінки*/}
               </div>
         </td></tr></tbody></table></div> 

<div className={s.mainDivForPrint}> <table ><tbody><tr><td>           
            <div className={s.inspectionTest}>
            {lang[langSwitch].preliminaryWeighing /*Попереднє зважування*/}
               </div>
         </td></tr></tbody></table></div> 

<div className={s.mainDivForPrint}> <table ><tbody><tr><td>           
            <div className={s.inspectionTest}>
            {lang[langSwitch].mealDistribution /*Видача обідів*/}
               </div>
         </td></tr></tbody></table></div> 
<div className={s.mainDivForPrint}> <table ><tbody><tr><td>           
            <div className={s.inspectionTest}>
            {lang[langSwitch].entryForbidden /*Вхід Заборонено*/}
               </div>
         </td></tr></tbody></table></div>

         <div className={s.mainDivForPrint}> <table ><tbody><tr><td>           
            <div className={s.inspectionTest}>
               {lang[langSwitch].entryInShoesProhibited /* Вхід у взутті Заборонено*/}
               </div>
         </td></tr></tbody></table></div> 

      <div className={s.mainDivForPrint}> <table ><tbody><tr><td>           
            <div className={s.inspectionTest}>
              {lang[langSwitch].warmUpArea /* Зона розминки*/}
               </div>
         </td></tr></tbody></table></div> 

 <div className={s.mainDivForPrint}> <table ><tbody><tr><td>           
            <div className={s.dontUse}>
            {lang[langSwitch].useProhibited /*Користуватися Заборонено*/}
               </div>
         </td></tr></tbody></table></div> 

        <div className={s.resultTitle}>
        <div className={s.containerBadgeTop}>
<div className={s.itemResultsFooter1}>
<button type='button'  onClick={()=>{window.print()}}>
{lang[langSwitch].print /*Друкувати*/}
   </button>
</div>

<div className={s.itemResultsFooter2}>

    <button  
    type='button' 
    onClick={()=>{setSwitcher(0)}}
    className={s.redButton}
    >
    {lang[langSwitch].close /*Закрити*/}
      </button>
        
</div></div>
</div>

        
   </> );
}

export default InformCards;
