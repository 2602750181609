import UpdateArchiveTR from "./UpdateArchiveTR"
import s from '../results/result.module.css'
import state from "../../../state/state"
import { useState } from "react"
import lang from "../../../state/language"


function AddAthleteInArchive({ 
            category, 
            moderator,
            updateArchive,
            resultData,
            grids,
            setDataForDB,
            langSwitch }) {
                
    const [wiewForm, setWiewForm] = useState(false)
    const [place, setPlace] = useState(null)
    const [id, setId] = useState(null)
     const [team, setTeam] = useState(null)


    const handlePlaceChange = (e) => { const value = e.target.value; setPlace(value.trim()); };
    const handleIdChange = (e) => { const value = e.target.value; setId(value.trim()); };
     const handleTeamChange = (e) => { const value = e.target.value; setTeam(value); };


    if (place && id &&team) {
        const athleteArray = state.athletes.filter((item) => item.id == id)
         const athlete=athleteArray[0]
        athlete.competitionPlace = place
        athlete.competitionCategory = category.category
        athlete.team=team==='region'?athlete.region:team==='Спортклуб'?athlete.sportClub:null
   
        category.athletes.push(athlete)
        resultData.push(athlete)
        setPlace(null);setId(null); setTeam(null);
    }
    category.athletes.sort((a,b)=>a.competitionPlace-b.competitionPlace)

    function forUpdate(){
        const competitionResult=resultData
        const a  ={ competitionResult,grids}
        setDataForDB(a)
        
        updateArchive(
            lang[langSwitch].saveChangesInArchive/*Зберегти в архів зміни внесені в протокол змаганнь?*/
        )
    }
    return (<>
        <tr>
            <td colspan='8' className={s.category}>

                {moderator ? (<>
                    <div style={{ cursor: 'pointer' }} onClick={() => { setWiewForm(!wiewForm) }}>
                        {state.weightCatUkr (category.category, langSwitch)}


                    </div>
                    {wiewForm ? (<>
                        <table style={{ width: '98%', margin: '1%' }}>
                            <tr >

                                <td style={{ border: 'none' ,width:'25%'}}>
                                    <label htmlFor="id">
                                        <b> id </b> </label><br />
                                    <input style={{ width: '50px', textAlign: 'center' }}
                                        onChange={handleIdChange}
                                        type="text"
                                        id="id"
                                        name="id" />
                                </td>

                                <td style={{ border: 'none',width:'25%'}}>
                                    <label htmlFor="place">
                                        <b>Місце</b> </label><br />
                                    <input style={{ width: '50px', textAlign: 'center' }}
                                        onChange={handlePlaceChange}
                                        type="text"
                                        id="place"
                                        name="place" />
                                </td>

                             

                                <td style={{ border: 'none' ,width:'25%'}}>
                                    <label htmlFor="status"><span className={s.teamChangeMenuSpan}>Команда:</span> </label><br />
                                    <select onChange={handleTeamChange} id="status" name="status">
                                        <option value={null}> </option>
                                        <option value="region">Обласний осередок</option>
                                        <option value="club">Спортклуб</option>
                                    </select>
                                </td>



                                <td style={{ border: 'none' ,width:'25%'}}>
                                    <button 
                                    className={s.button}
                                    onClick={()=>{forUpdate()
                                    setWiewForm(false)}}>
                                    Зберегти</button>
                                </td>
                            </tr>
                        </table>

                    </>) : null}

                </>) : state.weightCatUkr (category.category, langSwitch)}
            </td>
        </tr>



        {category.athletes.map((item) => {


            return (<>
                <UpdateArchiveTR кеу={item.id} item={item} resultData={resultData}/>
            </>)
        })}


    </>)
}

export default AddAthleteInArchive