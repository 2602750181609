import axios from 'axios';
import React, { useState, useEffect } from 'react';
import state from '../../../../state/state';
import indexedDBConnection from '../../../../state/indexedDBConnection';
import s from '../forAllUsers.module.css'
import sMenu from '../../controlPanel/menu/menu.module.css'
import lang from '../../../../state/language';
import VideoGaleryItem from './videoGaleryItem';
import EditVideoGalery from '../../controlPanel/media/editVideoGalery';


function VideoContent({ isSmallScreen, langSwitch, moderator }) {

    const [editStart, setEditStart] = useState(false);
    const [editVideoGalery, setEditVideoGalery] = useState(false);     
    const [mediaContent, setMediaContent] = useState(state.mediaContent); 
    const [videoGaleryData, setVideoGaleryData] = useState(state.mediaContent&&state.mediaContent.filter((item)=>item.typeContent==='video')); 
    const [videoGalery, setVideoGalery] = useState([]); 
    const [activeTab, setActiveTab] = useState(state.galleryType[0]);
    

    if (mediaContent.length === 0||!mediaContent||mediaContent==='null') {
      axios.get(state.api.getMediaContent)
        .then((response) => {
          if (response.data.mediaContent && Array.isArray(response.data.mediaContent) && response.data.mediaContent.length > 0) {
            setMediaContent(response.data.mediaContent)
            state.mediaContent = mediaContent
            indexedDBConnection.saveData('mediaContent',[...mediaContent]);
            setVideoGaleryData(
                response.data.mediaContent.filter((item)=>item.typeContent==='video')
            )
          }
        })
        .catch((error) => {
          console.error('Помилка запиту до сервера:', error);
        });
    }
    useEffect(()=>{
        setVideoGalery(videoGaleryData.filter((item)=>item.type===activeTab))
    },[activeTab,videoGaleryData])

  return (<>
   
        {moderator?(<br/>):(
        <h1 className={sMenu.h1} 
        style={{textAlign:'center', marginBottom:'40px'}}>
              { lang[langSwitch].videoGallery /*Відео галерея*/ }
            </h1>    
            )} 
<div className={s.galeryMenuWriper}>
 <div className={sMenu.tabsContainer}>
       <div className={sMenu.tabs1}> 
{ state.galleryType.map((tab, tabIndex) => (
                <div
                  key={tabIndex}
                  className={activeTab === tab ? `${sMenu.tab} ${sMenu.tabActive}` : sMenu.tab}
                  onClick={() => {
                    setActiveTab(tab);                   
                  }}
                >
                  {tab}
                </div>
              ))}
        </div> 
    </div>
    </div>



      {editStart?(
          <EditVideoGalery
            isSmallScreen={isSmallScreen} 
            langSwitch={langSwitch} 
            setEditStart={setEditStart}
            editVideoGalery={editVideoGalery}/>
       ):   
       videoGalery.length>0 && videoGalery.map((gallery, index) => { 
       
      return(<div key={index}>
        <VideoGaleryItem  
          index={index}
          isSmallScreen={isSmallScreen}
          langSwitch={langSwitch}
          gallery={gallery}
          moderator={moderator}
          setEditStart={setEditStart}
          setEditVideoGalery={setEditVideoGalery}
          />
      </div>)}
    )
}

    </>);
}

export default VideoContent;









