import axios from 'axios';
import React, { useState, useEffect } from 'react';
import state from '../../../state/state';
import indexedDBConnection from '../../../state/indexedDBConnection';
import NewsPageItem from './NewsPageItem';
import EditNews from '../controlPanel/news/editNews';
import s from './forAllUsers.module.css'
import lang from '../../../state/language';


function NewsPage({ isSmallScreen, langSwitch, moderator }) {
  const [visibleNews, setVisibleNews] = useState([]); // Список новин для відображення
  const [fetching, setFetching] = useState(false); // Чи триває зараз завантаження
  const [cacheIndex, setCacheIndex] = useState(0); // Індекс читання кешованих новин
  const [editStart, setEditStart] = useState(false);
  const [editNews, setEditNews] = useState(false);     

  const numRows = 50; // Кількість записів за один запит

  function getNews() {
    const lastItemId = visibleNews.length > 0 ? visibleNews[visibleNews.length - 1].id : null;

    axios.get(state.api.getNews, {
      params: { id: lastItemId, numRows }
    })
      .then(response => {
        if (response.data.news && response.data.news.length > 0) {
          setVisibleNews(prev => [...prev, ...response.data.news]); // Додаємо нові новини в список
        state.news=[... state.news,...response.data.news ]
        indexedDBConnection.saveData('news',state.news)
        setCacheIndex(state.news.length)
        }
      })
      .catch(error => {
        console.error('Помилка запиту:', error);
      })
      .finally(() => {
        setFetching(false);
      });
  }

  function loadMoreNews() {
    if (cacheIndex < state.news .length) {
      // Якщо ще є новини у `state.news`
      const newItems = state.news .slice(cacheIndex, cacheIndex + numRows);
      setVisibleNews(prev => [...prev, ...newItems]);
      setCacheIndex(prev => prev + numRows);
      setFetching(false);
    } else {
      // Якщо кеш закінчився — запитуємо з сервера
      getNews();
    }
  }

  function scrollHandler() {
    if (window.innerHeight + window.scrollY >= document.body.scrollHeight - 100) {
      if (!fetching) {
        setFetching(true);
      }
    }
  }

  useEffect(() => {
    if (fetching) {
      loadMoreNews();
    }
  }, [fetching]);

  useEffect(() => {
    // Початкове завантаження кешу
    if (state.news .length > 0) {
      loadMoreNews();
    } else {
      getNews();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler);
    document.addEventListener('touchmove', scrollHandler);

    return () => {
      document.removeEventListener('scroll', scrollHandler);
      document.removeEventListener('touchmove', scrollHandler);
    };
  }, []);
 
  return (
    <div >
       {moderator?(<br/>):(
       <h1 className={s.h1} 
     style={{textAlign:'center', marginBottom:'40px'}}>
        {lang[langSwitch].news /*Новини*/}
        </h1> 
        )}  

      {editStart?(
          <EditNews 
            isSmallScreen={isSmallScreen} 
            langSwitch={langSwitch} 
            setEditStart={setEditStart}
            editNews={editNews}/>
       ):   
     visibleNews.map((news, index) => { 
       
      return(<div key={index}>
        <NewsPageItem 
          index={index}
          isSmallScreen={isSmallScreen}
          langSwitch={langSwitch}
          news={news}
          moderator={moderator}
          setEditStart={setEditStart}
          setEditNews={setEditNews}
          />
      </div>)}
    )
}
    </div>
  );
}

export default NewsPage;
