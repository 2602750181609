import React from 'react';
import s from './footer.module.css'
import lang from '../../state/language';

function Footer ({isSmallScreen, langSwitch}) {
  return (<div className={s.footerBox}>
    {lang[langSwitch].gridsSystemFooter/*GridsSystem - Система адміністрування спортивних подій адаптована до вашого виду спорту.*/  }

  </div>);
};

export default Footer;