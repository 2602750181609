
import s from '../result.module.css'

import state from '../../../../state/state.js';
import React from 'react';





function ShotingResultByAthlets({competition,langSwitch}){


  const resultData=JSON.parse(competition.results).competitionResult


  // Групуємо спортсменів за категорією
  const groupedByCategory = resultData.reduce((acc, item) => {
    const category = item.competitionCategory;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(item);
    return acc;
  }, {});
  
  const sortedByPlaceRandom = Object.keys(groupedByCategory).reduce((acc, category) => {
    const athletes = groupedByCategory[category];
    const sortedAthletes = athletes.sort((a, b) => +a.shootingPlace - +b.shootingPlace);
  
    acc.push({ category, athletes: sortedAthletes });
    return acc;
  }, []);
  //сортуємо категорії по порядку
  const sortedByPlace=[]
  state.tournConstructor.categoryOrder.map((order)=>{
    sortedByPlaceRandom.map((item)=>{
      if(item.category===order){sortedByPlace.push(item)}
    })
  })








    return(<>  

<div className={s.mainDivForPrint}>
 <table className={s.mailTableResultByAthletsShooting}>{/* початок зовнішньої таблиці */}
 <thead>
<tr>
  <td colSpan='9'>

    <div className={s.headerTitle}>
<div className={s.competitionTitle}>{competition.title}</div>
<div className={s.competitionLocation}>{competition.location}</div>

<div >
  {competition.dateStart&&competition.dateFinish?(<>
  <u><b>{state.formatDate(competition.dateStart).slice(0, 2)}</b>- 
        <b>{state.formatDate(competition.dateFinish)} р.</b></u>         
      </>):(<>
    <u><b>  {competition.dateOfCompetition}р.</b></u> 
      </>)}
        <br/> <br/>
        <div className={s.textTitle}>Результати індивідуальних виступів спортсменів у програмі "Стрільба"</div>

        </div>
 
    </div>
</td>
</tr>
      <tr className={s.headerTable}>
        <td>Місце</td>
        <td >Бали</td>
        <td>Учасник</td>
        <td>Дата народження</td>
        <td>Спортивна<br/>кваліф.</td>
        <td>Гип/Дан</td>
        <td>ФСТ</td>
        <td>Тренер / <br/>Тренер 2</td>
        <td>Осередок/<br/>спортклуб</td>

      </tr>
      </thead>
      <tbody>

 

  {sortedByPlace.map((category,index)=>( 
    <React.Fragment key={category.category + index}>

<tr >
<td colSpan='9' className={s.category}>

{state.weightCatUkr (category.category, langSwitch) }


</td>
</tr>

{category.athletes.map((item,index)=>( <React.Fragment key={item.id+index}>
 
<tr >
  <td>{item.shootingPlace}</td>
  <td>{item.shootingResult}</td>
  <td>{item.secondName} {item.personName}</td>
  <td>{state.formatDate(item.birthday)}</td>
  <td>{item.rank}</td>
  <td>{item.dan}</td>
  <td>{item.fst}</td>
  <td> {state.myCoach(item.coach)} / <br/>{state.myCoach(item.coach2)}</td>
  <td >{state.regionShort(item.region)} / <br/>{item.sportClub}</td>
</tr>
</React.Fragment>))}

</React.Fragment>))}


</tbody>
<tfoot>
  <tr>
    <td colSpan='5'>
<div className={s.foterOfDocument}>
<br/>
     Гол. суддя  &nbsp;&nbsp;<b> {state.myCoach(competition.mainJudge)} </b>
 &nbsp;&nbsp; <span className={s.infoDescription}>
   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
     (підпис)   
   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>  &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;

&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;м.п.
     </div>


</td>
<td colSpan='4' >

     <div className={s.foterOfDocument} >
      <br/>
     Гол. секретар &nbsp;&nbsp; <b> {state.myCoach(competition.secretary)}</b>  
 &nbsp;&nbsp; <span className={s.infoDescription}>
   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
     (підпис)   
   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>  &nbsp;&nbsp; 
 &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          
     </div>
   </td>  </tr>
     </tfoot>
</table> {/* кінець зовнішньої таблиці */}
<br/><br/>


</div>
    </>)
}

export default ShotingResultByAthlets