import { useParams } from "react-router-dom";
import state from "../../../../state/state";
import s from '../result.module.css'
import lang from "../../../../state/language";
import { useState } from "react";
import SmallSwither from "./smallSwither";
import RandomPoomsaeItemA from "./randomPoomsaeItemA";



function RandomPoomsae({langSwitch, isSmallScreen}){
    const { id } = useParams();
    const [personalData,setPersonalData]=useState([])
    const [coupleData,setCoupleData]=useState([])
    const [teamData,setTeamData]=useState([])
     const [numberOfPoomsae,setNumberOfPoomsae]=useState(2)

    const competition = state.competition.filter((tourn) => tourn.id == id)[0];

   let poomsaeOptions,poomsaeCategories;

   //Кількість комплексів
 const numbers = Array.from({ length: 2 }, (_, i) => i + 1);
 const handleSelect = (event) => {setNumberOfPoomsae(parseInt(event.target.value, 10))};
const br=true
  
    if(competition.poomsaeOptions){
      try{
        poomsaeOptions=JSON.parse(competition.poomsaeOptions)
      }catch{
        poomsaeOptions=competition.poomsaeOptions
      }
    }else{return}
    //ручне перемикання суддівськї системи
    //  const system=true
     const system=poomsaeOptions.competitionSystem==='Система виключення'?true:false
       
      try{
        poomsaeCategories=JSON.parse(competition.grids)
      }catch{
        poomsaeCategories=competition.grids
      }  


const pomsaeData={
  personal:[
    {category:'Мол. юнаки' ,
       Poomsae:['Taegeuk 1 Jang','Taegeuk 2 Jang','Taegeuk 3 Jang','Taegeuk 4 Jang','Taegeuk 5 Jang','Taegeuk 6 Jang']},
    {category:'Юнаки',
       Poomsae:['Taegeuk 1 Jang','Taegeuk 2 Jang','Taegeuk 3 Jang','Taegeuk 4 Jang','Taegeuk 5 Jang','Taegeuk 6 Jang','Taegeuk 7 Jang','Taegeuk 8 Jang']},      
     {category:'Кадети',
      Poomsae:['Taegeuk 4 Jang', 'Taegeuk 5 Jang', 'Taegeuk 6 Jang', 'Taegeuk 7 Jang', 'Taegeuk 8 Jang', 'Koryo', 'Keumgang', 'Taeback']},
     {category:'юніори',
      Poomsae:['Taegeuk 5 Jang', 'Taegeuk 6 Jang', 'Taegeuk 7 Jang', 'Taegeuk 8 Jang', 'Koryo', 'Keumgang', 'Taeback', 'Pyongwon']},
     {category: 'Дорослі30',
      Poomsae:['Taegeuk 7 Jang', 'Taegeuk 8 Jang', 'Koryo', 'Keumgang', 'Taeback', 'Pyongwon', 'Shipjin', 'Jitae']},
     {category:'Дорослі -40',
      Poomsae:['Taegeuk 7 Jang', 'Taegeuk 8 Jang', 'Koryo', 'Keumgang', 'Taeback', 'Pyongwon', 'Shipjin', 'Jitae']},
     {category:'Дорослі -50',
      Poomsae:['Taegeuk 8 Jang', 'Koryo', 'Keumgang', 'Taeback', 'Pyongwon', 'Shipjin', 'Jitae', 'Chonkwon']},
     {category:'Дорослі -60',
      Poomsae:['Koryo', 'Keumgang', 'Taeback', 'Pyongwon', 'Shipjin', 'Jitae', 'Chonkwon', 'Hansu']},
     {category:'Дорослі -65',
      Poomsae:['Koryo', 'Keumgang', 'Taeback', 'Pyongwon', 'Shipjin', 'Jitae', 'Chonkwon', 'Hansu']},
     {category:'Дорослі 65+',
      Poomsae:['Koryo', 'Keumgang', 'Taeback', 'Pyongwon', 'Shipjin', 'Jitae', 'Chonkwon', 'Hansu']},
  ],
  couple:[
    { category: 'молодші юнаки', 
      Poomsae:['Taegeuk 1 Jang', 'Taegeuk 2 Jang', 'Taegeuk 3 Jang', 'Taegeuk 4 Jang', 'Taegeuk 5 Jang']},   
    { category: 'юнаки', 
      Poomsae:['Taegeuk 1 Jang', 'Taegeuk 2 Jang', 'Taegeuk 3 Jang', 'Taegeuk 4 Jang', 'Taegeuk 5 Jang', 'Taegeuk 6 Jang', 'Taegeuk 7 Jang', 'Taegeuk 8 Jang']},
    { category: 'кадети', 
      Poomsae:['Taegeuk 4 Jang', 'Taegeuk 5 Jang', 'Taegeuk 6 Jang', 'Taegeuk 7 Jang', 'Taegeuk 8 Jang', 'Koryo', 'Keumgang']},    
    { category:'юніори', 
      Poomsae:['Taegeuk 4 Jang', 'Taegeuk 5 Jang', 'Taegeuk 6 Jang', 'Taegeuk 7 Jang', 'Taegeuk 8 Jang', 'Koryo', 'Keumgang', 'Taeback']},
    { category:'Дорослі -30', 
      Poomsae:['Taegeuk 6 Jang', 'Taegeuk 7 Jang', 'Taegeuk 8 Jang', 'Koryo', 'Keumgang', 'Taeback', 'Pyongwon', 'Shipjin']},
    { category:'Дорослі -40', 
      Poomsae:['Taegeuk 6 Jang', 'Taegeuk 7 Jang', 'Taegeuk 8 Jang', 'Koryo', 'Keumgang', 'Taeback', 'Pyongwon', 'Shipjin']},
    { category:'Дорослі -50', 
      Poomsae:['Taegeuk 8 Jang', 'Koryo', 'Keumgang', 'Taeback', 'Pyongwon', 'Shipjin', 'Jitae', 'Choenkwon']},
    { category:'Дорослі -60', 
      Poomsae:['Koryo', 'Keumgang', 'Taeback', 'Pyongwon', 'Shipjin', 'Jitae', 'Chonkwon', 'Hansu']},
    { category:  'Дорослі 60+', 
      Poomsae:['Koryo', 'Keumgang', 'Taeback', 'Pyongwon', 'Shipjin', 'Jitae', 'Chonkwon', 'Hansu']},
  ],
    team:[
      { category: 'молодші юнаки', 
        Poomsae:['Taegeuk 1 Jang', 'Taegeuk 2 Jang', 'Taegeuk 3 Jang', 'Taegeuk 4 Jang', 'Taegeuk 5 Jang']},
      { category: 'юнаки', 
        Poomsae:['Taegeuk 1 Jang', 'Taegeuk 2 Jang', 'Taegeuk 3 Jang', 'Taegeuk 4 Jang', 'Taegeuk 5 Jang', 'Taegeuk 6 Jang', 'Taegeuk 7 Jang', 'Taegeuk 8 Jang']},
      { category: 'кадети', 
        Poomsae:['Taegeuk 4 Jang', 'Taegeuk 5 Jang', 'Taegeuk 6 Jang', 'Taegeuk 7 Jang', 'Taegeuk 8 Jang', 'Koryo', 'Keumgang']},
      { category: 'юніори', 
        Poomsae:['Taegeuk 4 Jang', 'Taegeuk 5 Jang', 'Taegeuk 6 Jang', 'Taegeuk 7 Jang', 'Taegeuk 8 Jang', 'Koryo', 'Keumgang', 'Taeback']},
      { category: 'Дорослі -30', 
        Poomsae:['Taegeuk 6 Jang', 'Taegeuk 7 Jang', 'Taegeuk 8 Jang', 'Koryo', 'Keumgang', 'Taeback', 'Pyongwon', 'Shipjin']},
      { category: 'Дорослі -40', 
        Poomsae:['Taegeuk 6 Jang', 'Taegeuk 7 Jang', 'Taegeuk 8 Jang', 'Koryo', 'Keumgang', 'Taeback', 'Pyongwon', 'Shipjin']},
      { category: 'Дорослі -50', 
        Poomsae:['Taegeuk 8 Jang', 'Koryo', 'Keumgang', 'Taeback', 'Pyongwon', 'Shipjin', 'Jitae', 'Choenkwon']},
      { category: 'Дорослі -60', 
        Poomsae:['Koryo', 'Keumgang', 'Taeback', 'Pyongwon', 'Shipjin', 'Jitae', 'Chonkwon', 'Hansu']},
      { category: 'Дорослі 60+', 
        Poomsae:['Koryo', 'Keumgang', 'Taeback', 'Pyongwon', 'Shipjin', 'Jitae', 'Chonkwon', 'Hansu']},
  ]
} 

const lotteryData={personal:[],couple:[],team:[]}
function dataMaker(){                    
   
  function roundsCounter(length){
     let rounds
    if(system){
       if(length<=8){rounds=1}
       if(length>9&&length<=19){rounds=2}
       if(length>19){rounds=3}
    }else{
      if(length<=2){rounds=1}
      if(length>2&&length<=4){rounds=2}
      if(length>4&&length<=8){rounds=3}
      if(length>8&&length<=16){rounds=4}
      if(length>16&&length<=32){rounds=5}
      if(length>32&&length<=64){rounds=6}
    }
   
    
    return rounds
  }
  poomsaeCategories.forEach((item)=>{

if(item.category==='jrYounkersMaleTraditionalPersonaleClassA'||item.category==='jrYounkersMaleTraditionalPersonaleClassB'||item.category==='jrYounkersFemaleTraditionalPersonaleClassA'||item.category==='jrYounkersFemaleTraditionalPersonaleClassB')
  {lotteryData.personal.push({
        category:item.category, 
        Poomsae:pomsaeData.personal[0].Poomsae, 
        rounds:roundsCounter(item.athletes.length)})
  }
if(item.category==='younkersMaleTraditionalPersonaleClassA'||item.category==='younkersMaleTraditionalPersonaleClassB'||item.category==='younkersFemaleTraditionalPersonaleClassA'||item.category==='younkersFemaleTraditionalPersonaleClassB')
  {lotteryData.personal.push({
        category:item.category, 
        Poomsae:pomsaeData.personal[1].Poomsae, 
        rounds:roundsCounter(item.athletes.length)})
  }
if(item.category==='cadetsMaleTraditionalPersonaleClassA'||item.category==='cadetsMaleTraditionalPersonaleClassB'||item.category==='cadetsFemaleTraditionalPersonaleClassA'||item.category==='cadetsFemaleTraditionalPersonaleClassB')
  {lotteryData.personal.push({
        category:item.category, 
        Poomsae:pomsaeData.personal[2].Poomsae, 
        rounds:roundsCounter(item.athletes.length)})
  }
if(item.category==='juniorsMaleTraditionalPersonaleClassA'||item.category==='juniorsMaleTraditionalPersonaleClassB'||item.category==='juniorsFemaleTraditionalPersonaleClassA'||item.category==='juniorsFemaleTraditionalPersonaleClassB')
  {lotteryData.personal.push({
        category:item.category, 
        Poomsae:pomsaeData.personal[3].Poomsae, 
        rounds:roundsCounter(item.athletes.length)})
  }
if(item.category==='adultsMale30TraditionalPersonaleClassA'||item.category==='adultsMale30TraditionalPersonaleClassB'||item.category==='adultsFemale30TraditionalPersonaleClassA'||item.category==='adultsFemale30TraditionalPersonaleClassB')
  {lotteryData.personal.push({
        category:item.category, 
        Poomsae:pomsaeData.personal[4].Poomsae, 
        rounds:roundsCounter(item.athletes.length)})
  }
if(item.category==='adultsMale40TraditionalPersonaleClassA'||item.category==='adultsMale40TraditionalPersonaleClassB'||item.category==='adultsFemale40TraditionalPersonaleClassA'||item.category==='adultsFemale40TraditionalPersonaleClassB')
  {lotteryData.personal.push({
        category:item.category, 
        Poomsae:pomsaeData.personal[5].Poomsae, 
        rounds:roundsCounter(item.athletes.length)})
  }
if(item.category==='adultsMale50TraditionalPersonaleClassA'||item.category==='adultsMale50TraditionalPersonaleClassB'||item.category==='adultsFemale50TraditionalPersonaleClassA'||item.category==='adultsFemale50TraditionalPersonaleClassB')
  {lotteryData.personal.push({
        category:item.category, 
        Poomsae:pomsaeData.personal[6].Poomsae, 
        rounds:roundsCounter(item.athletes.length)})
  }
if(item.category==='adultsMale60TraditionalPersonaleClassA'||item.category==='adultsMale60TraditionalPersonaleClassB'||item.category==='adultsFemale60TraditionalPersonaleClassA'||item.category==='adultsFemale60TraditionalPersonaleClassB')
  {lotteryData.personal.push({
        category:item.category, 
        Poomsae:pomsaeData.personal[7].Poomsae, 
        rounds:roundsCounter(item.athletes.length)})
  }
if(item.category==='adultsMale65TraditionalPersonaleClassA'||item.category==='adultsMale65TraditionalPersonaleClassB'||item.category==='adultsFemale65TraditionalPersonaleClassA'||item.category==='adultsFemale65TraditionalPersonaleClassB')
  {lotteryData.personal.push({
        category:item.category, 
        Poomsae:pomsaeData.personal[8].Poomsae, 
        rounds:roundsCounter(item.athletes.length)})
  }
if(item.category==='adultsMale65PlusTraditionalPersonaleClassA'||item.category==='adultsMale65PlusTraditionalPersonaleClassB'||item.category==='adultsFemale65PlusTraditionalPersonaleClassA'||item.category==='adultsFemale65PlusTraditionalPersonaleClassB')
  {lotteryData.personal.push({
        category:item.category, 
        Poomsae:pomsaeData.personal[9].Poomsae, 
        rounds:roundsCounter(item.athletes.length)})
  }
if(item.category==='adultsMale65PlusTraditionalPersonaleClassA'||item.category==='adultsMale65PlusTraditionalPersonaleClassB'||item.category==='adultsFemale65PlusTraditionalPersonaleClassA'||item.category==='adultsFemale65PlusTraditionalPersonaleClassB')
  {lotteryData.personal.push({
        category:item.category, 
        Poomsae:pomsaeData.personal[9].Poomsae, 
        rounds:roundsCounter(item.athletes.length)})
  }



if(item.category==='jrYounkersTraditionalCoupleClassA'||item.category==='jrYounkersTraditionalCoupleClassB')
  {lotteryData.couple.push({
        category:item.category, 
        Poomsae:pomsaeData.couple[0].Poomsae, 
        rounds:roundsCounter(item.athletes.length)})
  }
if(item.category==='younkersTraditionalCoupleClassA'||item.category==='younkersTraditionalCoupleClassB')
  {lotteryData.couple.push({
        category:item.category, 
        Poomsae:pomsaeData.couple[1].Poomsae, 
        rounds:roundsCounter(item.athletes.length)})
  }
if(item.category==='cadetsTraditionalCoupleClassA'||item.category==='cadetsTraditionalCoupleClassB')
  {lotteryData.couple.push({
        category:item.category, 
        Poomsae:pomsaeData.couple[2].Poomsae, 
        rounds:roundsCounter(item.athletes.length)})
  }
if(item.category==='juniorsTraditionalCoupleClassA'||item.category==='juniorsTraditionalCoupleClassB')
  {lotteryData.couple.push({
        category:item.category, 
        Poomsae:pomsaeData.couple[3].Poomsae, 
        rounds:roundsCounter(item.athletes.length)})
  }
if(item.category==='adults30TraditionalCoupleClassA'||item.category==='adults30TraditionalCoupleClassB')
  {lotteryData.couple.push({
        category:item.category, 
        Poomsae:pomsaeData.couple[4].Poomsae, 
        rounds:roundsCounter(item.athletes.length)})
  }
if(item.category==='adults40TraditionalCoupleClassA'||item.category==='adults40TraditionalCoupleClassB')
  {lotteryData.couple.push({
        category:item.category, 
        Poomsae:pomsaeData.couple[5].Poomsae, 
        rounds:roundsCounter(item.athletes.length)})
  }
if(item.category==='adults50TraditionalCoupleClassA'||item.category==='adults50TraditionalCoupleClassB')
  {lotteryData.couple.push({
        category:item.category, 
        Poomsae:pomsaeData.couple[6].Poomsae, 
        rounds:roundsCounter(item.athletes.length)})
  }
if(item.category==='adults60TraditionalCoupleClassA'||item.category==='adults60TraditionalCoupleClassB')
  {lotteryData.couple.push({
        category:item.category, 
        Poomsae:pomsaeData.couple[7].Poomsae, 
        rounds:roundsCounter(item.athletes.length)})
  }
if(item.category==='adults60PlusTraditionalCoupleClassA'||item.category==='adults60PlusTraditionalCoupleClassB')
  {lotteryData.couple.push({
        category:item.category, 
        Poomsae:pomsaeData.couple[7].Poomsae, 
        rounds:roundsCounter(item.athletes.length)})
  }


if(item.category==='jrYounkersMaleTraditionalTeamClassA'||item.category==='jrYounkersMaleTraditionalTeamClassB'||item.category==='jrYounkersFemaleTraditionalTeamClassA'||item.category==='jrYounkersFemaleTraditionalTeamClassB')
  {lotteryData.team.push({
        category:item.category, 
        Poomsae:pomsaeData.team[0].Poomsae, 
        rounds:roundsCounter(item.athletes.length)})
  }
if(item.category==='younkersMaleTraditionalTeamClassA'||item.category==='younkersMaleTraditionalTeamClassB'||item.category==='younkersFemaleTraditionalTeamClassA'||item.category==='younkersFemaleTraditionalTeamClassB')
  {lotteryData.team.push({
        category:item.category, 
        Poomsae:pomsaeData.team[1].Poomsae, 
        rounds:roundsCounter(item.athletes.length)})
  }
if(item.category==='cadetsMaleTraditionalTeamClassA'||item.category==='cadetsMaleTraditionalTeamClassB'||item.category==='cadetsFemaleTraditionalTeamClassA'||item.category==='cadetsFemaleTraditionalTeamClassB')
  {lotteryData.team.push({
        category:item.category, 
        Poomsae:pomsaeData.team[2].Poomsae, 
        rounds:roundsCounter(item.athletes.length)})
  }
if(item.category==='juniorsMaleTraditionalTeamClassA'||item.category==='juniorsMaleTraditionalTeamClassB'||item.category==='juniorsFemaleTraditionalTeamClassA'||item.category==='juniorsFemaleTraditionalTeamClassB')
  {lotteryData.team.push({
        category:item.category, 
        Poomsae:pomsaeData.team[3].Poomsae, 
        rounds:roundsCounter(item.athletes.length)})
  }
if(item.category==='adultsMale30TraditionalTeamClassA'||item.category==='adultsMale30TraditionalTeamClassB'||item.category==='adultsFemale30TraditionalTeamClassA'||item.category==='adultsFemale30TraditionalTeamClassB')
  {lotteryData.team.push({
        category:item.category, 
        Poomsae:pomsaeData.team[4].Poomsae, 
        rounds:roundsCounter(item.athletes.length)})
  }
if(item.category==='adultsMale40TraditionalTeamClassA'||item.category==='adultsMale40TraditionalTeamClassB'||item.category==='adultsFemale40TraditionalTeamClassA'||item.category==='adultsFemale40TraditionalTeamClassB')
  {lotteryData.team.push({
        category:item.category, 
        Poomsae:pomsaeData.team[5].Poomsae, 
        rounds:roundsCounter(item.athletes.length)})
  }
if(item.category==='adultsMale50TraditionalTeamClassA'||item.category==='adultsMale50TraditionalTeamClassB'||item.category==='adultsFemale50TraditionalTeamClassA'||item.category==='adultsFemale50TraditionalTeamClassB')
  {lotteryData.team.push({
        category:item.category, 
        Poomsae:pomsaeData.team[6].Poomsae, 
        rounds:roundsCounter(item.athletes.length)})
  }
if(item.category==='adultsMale60TraditionalTeamClassA'||item.category==='adultsMale60TraditionalTeamClassB'||item.category==='adultsFemale60TraditionalTeamClassA'||item.category==='adultsFemale60TraditionalTeamClassB')
  {lotteryData.team.push({
        category:item.category, 
        Poomsae:pomsaeData.team[7].Poomsae, 
        rounds:roundsCounter(item.athletes.length)})
  }
if(item.category==='adultsMale60PlusTraditionalTeamClassA'||item.category==='adultsMale60PlusTraditionalTeamClassB'||item.category==='adultsFemale60PlusTraditionalTeamClassA'||item.category==='adultsFemale60PlusTraditionalTeamClassB')
  {lotteryData.team.push({
        category:item.category, 
        Poomsae:pomsaeData.team[7].Poomsae, 
        rounds:roundsCounter(item.athletes.length)})
  }
  })

}
dataMaker()

function start(){  
if (poomsaeOptions.category.traditional.personal){setPersonalData(random(lotteryData.personal,numberOfPoomsae))}
if (poomsaeOptions.category.traditional.couple){setCoupleData(random(lotteryData.couple,numberOfPoomsae))}
if (poomsaeOptions.category.traditional.team){setTeamData(random(lotteryData.team,numberOfPoomsae))}
}


function random(data, numElements) {
  return data.map(item => {
    const poomsaeSets = Array.from({ length: item.rounds }, () => {
      const selectedPoomsae = [];

      for (let i = 0; i < numElements; i++) {
        const randomIndex = Math.floor(Math.random() * item.Poomsae.length);
        selectedPoomsae.push(item.Poomsae[randomIndex]);
      }

      return selectedPoomsae;
    });

    return {
      category: item.category,
      Poomsae: poomsaeSets,
      rounds: item.rounds
    };
  });
}

 
    return(<>
     <div className={s.noPrint}>
     <div><h1 className={s.h1}> {competition.title} </h1></div>
       <div><h2 className={s.h2}>
       {lang[langSwitch].lotteryOfMandatoryComplexes /*Жеребкування обов'язкових комплексів*/}      
        </h2>
       </div>
      

<div className={`${s.h4} ${s.containerResults}`}>
   <div className={s.itemResults1}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </div>
   <div className={s.itemResults2}>
 {system?lang[langSwitch].selectAgeCategories/*  Оберіть вікові категорії*/:null}
  </div>
 <div className={s.itemResultsRigth}>

 <div className={s.poomsaeLable}>
 {lang[langSwitch].numberOfPoomsae/*Кількість пумсе*/}
            <br/>
          <select value={numberOfPoomsae} onChange={handleSelect} style={{ marginBottom: '10px' }}>
            <option 
            value=""
            placeholder={numberOfPoomsae}
            ></option>
            {numbers.map((number) => (
              <option key={number} value={number}>
                {number}
              </option>
            ))}
          </select>
        </div>  
 </div>
</div>
  </div>


<div className={s.paddingBottom}>
 <RandomPoomsaeItemA 
      competition={competition} 
      langSwitch={langSwitch} 
      personalData={personalData}
      coupleData={coupleData}
      teamData={teamData}
      start={start}
      system={system}
      isSmallScreen={isSmallScreen}
      />
       </div>
          
       
    </>)
}

export default RandomPoomsae