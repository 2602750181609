
import state from '../../../../state/state'
import s from '../adminCompetition.module.css'

function EntryPrintFooter({
    isFtuRegionLeader, 
    isOlfdLeader, 
    isStateSportDepartmentLeader,
    dateEntry,
    mainJudge,
    secretary
}){

    return(<>
    <tfoot>
        <tr>
           <td colSpan='9'>
          Зазначені в списку
<span className={s.infoDescription}>

  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
(кількість  цифрами  та  у  дужках  словами)
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 

</span> особи пройшли належну підготовку та допущені до участі у заході.    
          </td>
      </tr> 


     <tr>
           <td colSpan='9'>
           {isFtuRegionLeader?isFtuRegionLeader:(<>Керівник обласного осередку федерації ВСБ України</>)}
      
      &nbsp; у ______________________ області &nbsp;&nbsp;
     <span className={s.infoDescription}>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
      (прізвище, ініціали) 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
</span>
 &nbsp;&nbsp;
<span className={s.infoDescription}>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
     (підпис)   
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>
          </td>
      </tr>  
      
      
      <tr>
           <td colSpan='9'>
          Тренер команди 
    &nbsp;&nbsp;  <span className={s.infoDescription}>
   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
      (прізвище, ініціали) 
     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
</span> &nbsp;&nbsp;

<span className={s.infoDescription}>
   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
     (підпис)   
   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>   
&nbsp;&nbsp; Представник команди		&nbsp;&nbsp;
     <span className={s.infoDescription}>
   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
      (прізвище, ініціали) 
     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
</span>
 &nbsp;&nbsp;
<span className={s.infoDescription}>
   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
     (підпис)   
   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span> 
          </td>
      </tr> 
      
      
      <tr>
           <td colSpan='9'>
          Допуск до участі у змаганнях мають
    &nbsp; <span className={s.infoDescription}>

  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
(кількість  цифрами  та  у  дужках  словами)

&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
</span> &nbsp; осіб. 
&nbsp;&nbsp;&nbsp;&nbsp;
        Лікар 
    &nbsp; <span className={s.infoDescription}>
   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
     (підпис)   
   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>  &nbsp; 
м.п.   &nbsp;   <b>
  {dateEntry}p.
  </b> 
          </td>
      </tr> 
      <tr>
           <td colSpan='9'>
          {isOlfdLeader?isOlfdLeader:(<>Керівник   ОЛФД</>)}
        
       
        &nbsp;&nbsp;  <span className={s.infoDescription}>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
         (прізвище, ініціали) 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
   </span> &nbsp;&nbsp; м.п.
   
    &nbsp;&nbsp; <span className={s.infoDescription}>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
        (підпис)   
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
   </span>  &nbsp;&nbsp; 

   &nbsp;&nbsp;
Лікар змагань
&nbsp;&nbsp;  <span className={s.infoDescription}>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
      (прізвище, ініціали ) 
     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
</span> &nbsp;&nbsp;м.п.
 &nbsp;&nbsp; <span className={s.infoDescription}>
   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
     (підпис)   
   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>  &nbsp;&nbsp; 
          </td>
      </tr> 
      <tr>
           <td colSpan='9'>
          {isStateSportDepartmentLeader?isStateSportDepartmentLeader:(<>Начальник управління з питань фізичної культури і спорту</>)}
     
     &nbsp;&nbsp;  <span className={s.infoDescription}>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
          (прізвище, ініціали) 
         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
    </span> &nbsp;&nbsp;
     &nbsp;&nbsp; <span className={s.infoDescription}>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
         (підпис)   
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>  &nbsp;&nbsp;
          </td>
      </tr> 
      <tr>
           <td colSpan='9'>
          Категорії вказано вірно та зміни не підлягають, дані вказано вірно та зміни не підлягають
 &nbsp;  <span className={s.infoDescription}>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      (прізвище, ініціали представника команди) 
     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span> &nbsp;
 <span className={s.infoDescription}>
   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
     (підпис)   
   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
 </span>
          </td>
      </tr> 
      <tr>
           <td colSpan='9'>
          Мандатною комісією допущено __________________ спортсменів.
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          Головний суддя <span className={s.infoDescription}>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
         (підпис)   
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
 <b> {state.myCoach(mainJudge)}</b> &nbsp;&nbsp; &nbsp;&nbsp;
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
Головний секретар <span className={s.infoDescription}>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
         (підпис)   
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
 <b> {state.myCoach(secretary)} </b>&nbsp;&nbsp;
          </td>
      </tr> 
     

    </tfoot>
    
    </>)
}

export default EntryPrintFooter