import React, { useState } from 'react';
import ConfirmButtons from '../../adminCompetition/confirmButtons';
import lang from '../../../../state/language';
import s from '../menu/menu.module.css'
import sForAll from '../../forAllUsers/forAllUsers.module.css'
import sAdmin  from '../../adminCompetition/adminCompetition.module.css'
import state from '../../../../state/state'
import compressImage from '../../photo/compressImage'
import axios from 'axios';
import indexedDBConnection from '../../../../state/indexedDBConnection';

function EditLeader({ isSmallScreen, langSwitch, setEditStart, editLeader }) {
  const [compressedFileURL, setCompressedFileURL] = useState(null);
  const [compressedFile, setCompressedFile] = useState(null);  
   const [photos, setPhotos] = useState(editLeader.photos);
  const [name, setName] = useState(editLeader.name);
  const [jobTitle, setJobTitle] = useState(editLeader.jobTitle);
  const [description, setDescription] = useState(editLeader.description);
  const [messageText, setMessageText] = useState('');
  const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');



  const openConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(true); }
  const closeConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(false); }

  function saveLeaderConfirm() {
      setMessageText(lang[langSwitch].editLeadersTitle + '?'   /*Редагувати керівників*/)
      openConfirmButtonsModal()
     }

  const confirmFunction = (messageText) => {
    if (messageText === lang[langSwitch].editLeadersTitle + '?'   /*Редагувати керівників*/
    ) {
      //ВИКЛИК ФУНКЦІЇ Оновлення СТАТТІ
      uploadData()
    }

  }

  function handleNameChange(e) { setName(e.target.value) }

  function handleJobTitleChange(e) { setJobTitle(e.target.value) }

  function handleDescriptionChange(e) { setDescription(e.target.value) }

  const handleFileChange = (event) => {

    const selectedFile = event.target.files[0];
    setPhotos(state.imgAdress.leaderShip + selectedFile.name)

    // Викликати функцію стиснення зображення та отримати стиснутий файл
    compressImage(selectedFile, {
      maxSizeMB: 0.3,
      maxWidth: 310,
      maxHeight: 394,
    })
      .then((compressedFile) => {
        setCompressedFile(compressedFile)
        // Створити URL для стиснутого файлу
        const compressedFileURL = URL.createObjectURL(compressedFile);

        // Встановити URL стиснутого файлу у стані компонента
        setCompressedFileURL(compressedFileURL);
      })
      .catch((error) => {
        console.error('Помилка стиснення файлу:', error);
      });
  };

function   updateProfile(){

   axios.put(state.api.editLeader, {
        id: editLeader.id,
        description:description,
        name:name,
        jobTitle:jobTitle,
        photos:photos
      })
        .then(response => {             
          const index = state.leaders.findIndex((item)=> +item.id === +editLeader.id )
          state.leaders[index]={
            id: editLeader.id,
            description:description,
            name:name,
            jobTitle:jobTitle,
            photos:photos
          }
                indexedDBConnection.saveData ('leaders', Object.assign(state.leaders))        
                window.location.reload(); // при перезавантаженні дані беруться з кешу        
              })
        .catch(error => {
          console.error(lang[langSwitch]. serverRequestError/*Помилка запиту до сервера: */, error);
          setErrorMessage(lang[langSwitch]. serverRequestError/*Помилка запиту до сервера: */, error)
  
        });


}

  function uploadData(){
    if (compressedFile){

  const formData = new FormData();
      formData.append('image', compressedFile);

      axios.post(state.api.uploadLeaderPhoto, formData)
        .then(response => {
          // Отримано відповідь від сервера після завантаження зображення
       
          if (response.status === 200 && response.data.message === 'Файл успішно збережено') {
          if (editLeader.photos!==null){
//ортимуємо назву старого фото
  const parts=editLeader.photos.split('/');
    const photoName = parts[parts.length - 1];
 
 //видалення старого фото
    axios.delete(state.api.deleteLeaderPhoto, {
      data: {                
        fileName: photoName
      }
    })
    .then(response => { // Обробка успішного видалення  
    })
    .catch(error => { console.log('Помилка видалення старого аватару:', error);// Обробка помилок
    });  
          }
     
          // Після завантаження зображення ви можете виконати PUT-запит для оновлення профілю
          updateProfile();
        } else {
          throw new Error('Сервер повернув відповідь без успішного статусу');
        }

        })
        .catch(error => {
          console.error('Помилка під час збереження зображення:', error);
          setErrorMessage( lang[langSwitch]. errorOccurredWhileSavingImage+': ' /*Помилка під час збереження зображення*/,error)
        });

    }else{
      updateProfile()
    }
  }


  return (<div>
    <div className={sForAll.leaderPhoto}>
      <img src={editLeader.photos} />
    </div>
    <div>

      <div className={s.titleBox}>
        <div className={s.editButtonItem}>
          <div className={s.newsDate}>
            {editLeader.name}

          </div>

          <input
            type='text'
            className={s.inputLeader2}
            onChange={(e) => { handleNameChange(e) }}
            placeholder={lang[langSwitch].surname   /*Призвище*/
              + ', ' + lang[langSwitch].name   /*Ім'я*/
              + ', ' + lang[langSwitch].patronymic   /*По-батькові*/}
          />
        </div>
        <br />

        <div className={s.deleteButtonItem}
          style={{ textAlign: 'right', paddingRight: '20px' }}>
          <button
            type='button'
            onClick={() => { setEditStart(false) }}>
            {lang[langSwitch].close /*Закрити*/}
          </button>

        </div>
      </div>
    </div>

    <div className={s.newsDate}>

      {editLeader.jobTitle}
    </div>
    {/* {langSwitch==='ukr'?state.userProfile.rank:
                langSwitch==='eng'?lang.rankEng(state.userProfile.rank):null} */}

    <select onChange={handleJobTitleChange} defaultValue="">
      <option value="" disabled hidden>
        {lang[langSwitch].chooseJobTitle /*Оберіть посаду*/}
      </option>
      {state.leadersJobTitle.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ))}
    </select>


    <div>
      <div className={s.newsDate}><br />
        {lang[langSwitch].description   /*Опис*/}:

      </div>

      <textarea
        type='text'
        value={description}
        className={s.leaderDescription}
        onChange={(e) => { handleDescriptionChange(e) }}
      />
    </div>

    {errorMessage ? (<><br /><br /><div className={sAdmin.errorMessage} onClick={() => { setErrorMessage('') }}>
      {errorMessage}</div><br /></>) : ''}

    <div>

      <div>
        <div className={s.newsDate}>
          {lang[langSwitch].photo   /*Фото*/}:

        </div>

        <div>
          {compressedFileURL && (
            <img src={compressedFileURL} alt="Стигнутий файл" />
          )}<br />
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
          /><br /><br />
        </div>
      </div>

    </div>
    <div>


      <button type='button'
        onClick={() => { saveLeaderConfirm() }}>
        {lang[langSwitch].saveInDB   /*Зберегти в БД*/}
      </button>
    </div>






    <ConfirmButtons
      isOpen={isConfirmButtonsModalOpen}
      onClose={closeConfirmButtonsModal}
      confirmFunction={confirmFunction}
      messageText={messageText}
      langSwitch={langSwitch}
    />
  </div>);
};

export default EditLeader;