
import s from '../adminCompetition/adminCompetition.module.css';
import state from "../../../state/state.js";
import lang from "../../../state/language.js";


function CoachesList({ isOpen, onClose, competition,isSmallScreen,langSwitch }) {

  const entryAthletes = state.tournConstructor.entryAthletesFind(competition.athletes)   
 
  const coachesId = new Set
if(entryAthletes){
  entryAthletes.map((item)=>{
    coachesId.add(item.coach)
  })
}
  const coachesArray=Array.from(coachesId)

  const coaches = state.athletes.filter((item) => coachesArray.includes(item.id))


///////////////////////////////////////////////////////////////////////////////////////////
  
  
  const groupedByCoach = entryAthletes.reduce((acc, athlete) => {
    const coach = athlete.coach;
  
    // Додати новий об'єкт для команди, якщо її ще немає в результаті
    if (!acc[coach]) {
      acc[coach] = { coach, athletes: [] };
    }
  
    // Додати поточного атлета до команди
    acc[coach].athletes.push(athlete);
  
    return acc;
  }, {});
  
  // Перетворити об'єкт згрупованих команд у масив
  const resultArray = Object.values(groupedByCoach);
 
 

/////////////////////////////////////////////////////////////////////////////////////////
  // const createMarkup = (html) => {return { __html: html };};
  return (<>
    {isOpen?(<>
      <div className={s.modalWriper}>
    <div key="CoachesList Modal" className={s.modal}  contentLabel="CoachesList Modal" >


<div className={s.title}>{competition.title}
<p><u><b>{state.formatDate(competition.dateStart).slice(0, 2)}</b>- 
        <b>{state.formatDate(competition.dateFinish)}&nbsp;
        {lang[langSwitch].yearShort /*р.*/}
        </b></u> <br/><br/></p>
              </div>  {/*Закриття шапки */}

      <div className={s.gridContainerForCategories}>
        {coaches.map((item) => (
          <div key={item.id} className={s.cell}>
                <div className={s.categoryImg}>
                    <img src={item.photo?item.photo:state.noAvatarPhoto} 
                    alt={state.myCoach(item.id)} />
                </div>

            <div className={s.categoryData}>
                    <div className={s.coachName}>{ `${item.secondName} ${item.personName} ${item.fatherName}`}</div>
                    <div className={s.region}>
                    {langSwitch==='ukr'?item.region:
                   langSwitch==='eng'? lang.regionsEng(item.region):null
                    }
                     
                      </div>
                     <div >
                      {lang[langSwitch].qualificationShort /*Кваліф.*/}
                      <b> {item.dan} &nbsp;/ &nbsp;{item.rank}</b></div> 
                    <div >
                      {lang[langSwitch].PCSS /*ФСТ*/}: 
                      <b> {item.fst}</b></div> 
                    <div >
                    
                     {lang[langSwitch].sportsClubShort /*C/к*/}:  
                      <b> {item.sportClub}</b></div> 
                     <div >
                      {lang[langSwitch].declared /*Заявлено*/}:&nbsp; 
                      <b>
                     { resultArray.map((item2) => (
                        item2.coach == item.id ? item2.athletes.length  : null
                      )).filter((length) => length !== null)
                      } </b>
                      {lang[langSwitch].participantsShort /* уч.*/}                    
                      </div> 
            </div>
          </div>
        ))}
      </div>

      {/* <div dangerouslySetInnerHTML={createMarkup(regulationText)} /> */}
      <button type='button' onClick={onClose}>
      {lang[langSwitch].close /* Закрити*/}
        </button>
    </div>
    </div>
    </>):null}
  </>)

}

export default CoachesList