import React, { useEffect } from 'react';
import s from './adminCompetition.module.css';

function PrintGrids(props) {

 console.log('props: ',props)

  ///////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        // Код для виклику друку
        window.print();
      }, []); // Пустий масив залежностей гарантує виклик ефекту лише після першого рендерингу компонента
    
     return( <>  



      {props.gridsDB?(   
        <div >
          {props.gridsDB.map((grid) => {
            const Component = props.componentMap[grid.numberOfAthletes];
            if (Component) {
              return (
                <Component
                  key={grid.category}
                  id={grid.category}
                  athletes={grid.athletes}
                  category={grid.category}
                  competition={props.competition}
                  battleNumbers={grid.battleNumbers}
                  cort={grid.cort}
                  gridDB={grid}
                />
              );
            }
          
          })}
        </div>   ):  
(<h1 className={s.h1}>Для виведення сіток на друк їх потрібно попередньо згенерувати та зберегти в БД.</h1>)} 


</> )  
  }
  export default PrintGrids
  