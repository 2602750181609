import lang from '../../../../state/language'
import state from '../../../../state/state'
import s from '../result.module.css'

function ResultByTeamsCutOffSystem({competition}){
    let athletes
    try{athletes= JSON.parse(competition.athletes)}catch{athletes= competition.athletes} 
    const resultDataTemp=JSON.parse(competition.results)
   const resultData = state.dataForcutOfSystemResult(resultDataTemp,athletes)

   let resultData1=[]
   resultData.forEach((item)=>{
        item.poomsaeResult.place.forEach((item2)=>{
          resultData1.push(item2)
        })
  
  })
  
  const groupedByTeam = resultData1.reduce((acc, item) => {
      const coachId = item.team || ''; // Використовуємо порожній рядок, якщо coach не визначено  
    
      // Перевіряємо, чи не порожні або не визначені поля coach та coach2
      if (coachId.trim() !== '') {
        // Перевіряємо, чи існує група для тренера, якщо ні, створюємо
        const coachGroup = acc.find(group => group.coach == coachId);
        if (!coachGroup) {
          acc.push({
            coach: coachId,
            athletes: [],
          });
        }    
    
          const groupIndex = acc.findIndex(group => group.coach == coachId);
          acc[groupIndex].athletes.push(item);
        }
     
         
      return acc;
    }, []);
  
  
    const updatedGroupedByTeam = groupedByTeam.map((item) => ({
      ...item, // Копіюємо всі властивості з поточного об'єкта
      team:item.coach,
      gold: item.athletes.filter((a) => a.place == 1).length, // Додаємо нову властивість
      silver:item.athletes.filter((a) => a.place == 2).length,
      bronze:item.athletes.filter((a) => a.place == 3).length,
      points:(item.athletes.filter((a) => a.place == 1).length*12)+
      (item.athletes.filter((a) => a.place == 2).length*5)+
      (item.athletes.filter((a) => a.place == 3).length*2)
    }));
    
    const data = updatedGroupedByTeam.sort((a, b) => b.points - a.points);
   
  
  let count=1;
    return(<>
  
        <div className={s.mainDivForPrint}>
        <table className={s.mailTableResultByAthlets}>{/* початок зовнішньої таблиці */}
         <thead>
         <tr>
          <td colSpan='8'>
            <div className={s.headerTitle}>
        <div className={s.competitionTitle}>{competition.title}</div>
        <div className={s.competitionLocation}>{competition.location}</div>
        <div >
          {competition.dateStart&&competition.dateFinish?(<>
          <u><b>{state.formatDate(competition.dateStart).slice(0, 2)}</b>- 
                <b>{state.formatDate(competition.dateFinish)} р.</b></u>         
              </>):(<>
            <u><b>  {competition.dateOfCompetition}р.</b></u> 
              </>)}
                <br/> <br/></div>
                <div className={s.textTitle}>Результати виступів спортсменів в командному заліку</div>
              
            </div>
            </td>
        </tr>
              <tr className={s.headerTable}>
                <td>№</td>
                <td>Назва команди</td>
                <td>Золото</td>
                <td>Срібло</td>
                <td>Бронза</td>
                <td>Бали</td>        
              </tr>
              </thead>
              <tbody>
           
          {data.map((item)=>(<>
           
              <tr key={item.id}>
                <td>{count}</td>
                <td>{item.team} </td>
                <td>{item.gold}</td>
                <td>{item.silver}</td>
                <td>{item.bronze}</td>
                <td>{item.points}</td>
               
              </tr>
             <div className={s.invisible}> {count++}</div>
         
          </>))}
          </tbody>
        </table> {/* кінець зовнішньої таблиці */}
        <br/><br/>

        <table className={s.footerTable}> 
          <tbody><tr><td>
        <div className={s.foterOfDocument}>
             Гол. суддя  &nbsp;&nbsp;<b> {state.myCoach(competition.mainJudge)} </b>
         &nbsp;&nbsp; <span className={s.infoDescription}>
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
             (підпис)   
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>  &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
        
        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;м.п.
             </div>
        </td>     
        <td>
             <div className={s.foterOfDocument}>
             Гол. секретар &nbsp;&nbsp; <b> {state.myCoach(competition.secretary)}</b>  
         &nbsp;&nbsp; <span className={s.infoDescription}>
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
             (підпис)   
           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>  &nbsp;&nbsp; 
         &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  
             </div>
        </td></tr> </tbody></table>
        </div>
            
            </>)
}

export default ResultByTeamsCutOffSystem