import { NavLink } from "react-router-dom"
import state from "../../../state/state.js"
import s from "./SportClubs.module.css"
import { useState } from "react";
import sAdmin from '../adminCompetition/adminCompetition.module.css'
import axios from "axios";
import ConfirmButtons from "../adminCompetition/confirmButtons";
import SportClubItem from "./sportClubItem";
import lang from "../../../state/language.js";
import indexedDBConnection from "../../../state/indexedDBConnection.js";




function MySportClubs({isSmallScreen,langSwitch}) {

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

   const sportclubArrey = state.sportClubs.filter(club => club.creator ==  state.userProfile.id||(state.userProfile.status==='Тренер'?club.name===state.userProfile.sportClub:null));

   function eMailSendFunction(mailData ){
    
      axios.post(state.api.emailSend,{mailData})
      .then(response=>{console.log(response.data)})
      .catch(error=>{console.error('Помилка при відправленні POST-запиту:', error)})
} 
   
    function deleteSportClub(id, fileName) {
        axios
            .delete(state.api.deleteSportClub, {
                params: {
                    id: id,
                    fileName: fileName, // Додано передачу імені файлу
                },
                headers: { 'Content-Type': 'application/json' },
            })
            .then((response) => {
            

                const deletedClubName=state.sportClubs.filter(sportClub => sportClub.id == id)[0].name
                const athletes=state.athletes.filter(athlete =>athlete.sportClub===deletedClubName)
                const mailData=[]             
                
                
                athletes.map((item)=>{
                    ////////////////////////////////////////////////////////////                    
                    axios
                    .post(state.api.updateSportClubNameInAthleteProfile, {
                       name:' ',
                       id:item.id,
                        headers: {'Content-Type': 'application/json'}
                    })
                    .then((response) => {
                      const athleteIndex=state.athletes.findIndex(item2=>item2.id==item.id) 
                      state.athletes[athleteIndex].sportClub=' '
                     
                      const athleteObject = {
                        id: item.id,
                        // userEmail: 'Sergeyburak79@gmail.com',
                        userEmail: item.eMail,
                        subject:`Повідомлення про видалення спортклубу`,
                        text:`${item.sex==='male'?'Шановний ':'Шановнa '} ${item.personName} ${item.fatherName}, спортивний клуб <b>${deletedClubName}</b>, було видалено вашим тренером, будь ласка оберіть інший клуб для подальшої заявки на турнири`,
                        gridsPdfForMale:null
                      };                  
                      // Додаємо об'єкт до масиву mailData
                      mailData.push(athleteObject);
                      eMailSendFunction(mailData)/*повідомляємо спортсменів*/
                    })
                    .catch((error) => {
                      setErrorMessage(oldData=>[...oldData, 
                        lang[langSwitch].failedUpdateClubInAthleteProfile /*Невдалось оновити назву клубу в профілі спортсмена:*/ +
                        state.athleteName(item.id) + error]);
                    });                   
                    ////////////////////////////////////////////////////////////////////
                     })                     
                    
                const updatedSportClubs = state.sportClubs.filter(sportClub => sportClub.id !== id);  
                state.sportClubs =updatedSportClubs
                indexedDBConnection.deleteData('sportClubs', id)
                setSuccessMessage(
                    lang[langSwitch].successfulDeleteSportsClub //Спортклуб та файл успішно видалено, можете продовжувати роботу
                );
                    window.location.href = '/MySportClubs'
                 
              })
            .catch((error) => {
                setErrorMessage(
                     lang[langSwitch].queryError //Помилка Axios-запиту, невдалось оновити статус
                    + error);
            });

    }
    ////////////////////////////////////////////////////////////////////////////////////////////
    const [messageText, setMessageText] = useState('');
    const [dataFileName, setDataFileName] = useState('');
    const [dataId, setDataId] = useState(null);
    const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState(false);
    function deleteResultConfirm(id) {

        setMessageText(
            lang[langSwitch].deleteSportsClub //Видалити спортивний клуб?
        )
        openConfirmButtonsModal()

    }
    const confirmFunction = (messageText) => {
        if (messageText === 
            lang[langSwitch].deleteSportsClub //Видалити спортивний клуб?
        ) {

let fileName

            // Перевірка на випадок відсутності фото
            if (!dataFileName) {
                // Якщо фото відсутнє, обробіть цей випадок відповідним чином
                // console.log("Фото відсутнє");
            } else {
                // Розділіть шлях до файла за допомогою слешів (/), а потім візьміть останній елемент (ім'я файлу)
                fileName = dataFileName.split('/').pop();

                // Тепер fileName містить тільки ім'я файлу
                // console.log("Ім'я файлу:", fileName);
            }

            deleteSportClub(dataId, fileName)
        }

    }
    const openConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(true); }
    const closeConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(false); }
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    return (<>
    <div className={s.mySportClubBox}>
<div className={s.invisiblePrint}>
        <div className={s.headTitle}>

        <h1 className={s.h1}>
                {lang[langSwitch].mySportsClubs}{/*Мої спортклуби*/}
                </h1>
<br />
        </div>

        {successMessage ?
            (<div className={sAdmin.successMessage} onClick={() => { setSuccessMessage('') }}>{successMessage} </div>) :
            errorMessage ? (<div className={sAdmin.errorMessage} onClick={() => { setErrorMessage('') }}>{errorMessage} </div>) : ''}
</div>
</div>
<div className={s.contaner6}>
        {sportclubArrey ? sportclubArrey.map((club) => {

            return (<div  key={club.id}>
                
              <SportClubItem              
                club={club} 
                setDataId={setDataId} 
                setDataFileName={setDataFileName}
                deleteResultConfirm={deleteResultConfirm}
                isSmallScreen={isSmallScreen}
                 langSwitch={langSwitch}
                />


            </div>)
        }) : setErrorMessage(<>
            {lang[langSwitch].notCreatedSportsClub}{/*Ви ще на створили Спортклубу*/}
       </> )}

       </div>

        <ConfirmButtons
            isOpen={isConfirmButtonsModalOpen}
            onClose={closeConfirmButtonsModal}
            confirmFunction={confirmFunction}
            messageText={messageText}
            langSwitch={langSwitch}
        />

    </>)
}

export default MySportClubs