import { NavLink, useParams } from 'react-router-dom';
import s from './result.module.css'
import sAdmin from '../adminCompetition/adminCompetition.module.css'
import state from '../../../state/state.js';
import Permit from './esleForPrint/permit';
import { useState } from 'react';
import FoodCarts from './esleForPrint/foodCarts';
import CardsForHall from './esleForPrint/cardsForHall';
import InformCards from './esleForPrint/informCards';
import lang from '../../../state/language.js';
import FreestalNotes from './esleForPrint/freestalNotes.js';
import TraditionalNotes from './esleForPrint/traditionalNotes.js';
import CompetitionHeder from '../adminCompetition/competitionHeder.js';



function EslePrint({isSmallScreen,langSwitch}) {
 
    const { id } = useParams();//отримуємо id трурниру
    const competitionArray = state.competition.filter((tourn) => tourn.id == id); //турнир в массиві
    const competition = competitionArray[0]//отримуємо турнир з БД
    
    const [switcher,setSwitcher]=useState(0)

    return (<>
    <div className={s.resultTitle}>
    <CompetitionHeder 
    logo={competition.logo} 
    title={competition.title}
    notes={lang[langSwitch].miscellaneousForPrinting /*Різне для друку*/} 
    />



            
</div>
<div className={s.containerResults}>
<div className={s.itemResults1}>
<button type='button' className={s.button} onClick={()=>{setSwitcher(1)}}>
{lang[langSwitch].passes /*Перепустки*/}
    </button>

{Number(competition.poomse)===1?(<>
<button type='button' className={s.button} onClick={()=>{setSwitcher(5)}}>
{lang[langSwitch].freestalNotes/*Нотатки фрістал*/}
</button>
<button type='button' className={s.button} onClick={()=>{setSwitcher(6)}}>
{lang[langSwitch].traditionalNotes/*Нотатки традиц.*/}
</button>
</>):null}
</div>
<div className={s.itemResults2}>
<button type='button' className={s.button} onClick={()=>{setSwitcher(2)}}>
{lang[langSwitch].foodStamps /*Талони на харчув.*/}
    </button>
</div>
<div className={s.itemResults3}>
<button type='button' className={s.button} onClick={()=>{setSwitcher(3)}}>
{lang[langSwitch].tablets /*Табличкі*/}
    </button>
</div>
<div className={s.itemResults4}>
<button type='button'className={s.button} onClick={()=>{setSwitcher(4)}}>
{lang[langSwitch].pointers /*Вказівники*/}
    </button>
</div>
<div className={s.itemResults5}>
<NavLink to={`/AdminCompetition/${competition.id}`}> 
        <button  type='button' className={`${s.button} ${s.redButton}`} >
        {lang[langSwitch].comeBack /*Повернутись*/}    
            </button>
        </NavLink>
</div>

</div>
{switcher===1?
    <Permit competition={competition} setSwitcher={setSwitcher} langSwitch={langSwitch}/>
:null}
{switcher===2?
    <FoodCarts competition={competition} setSwitcher={setSwitcher}langSwitch={langSwitch}/>
:null}
{switcher===3?
    <CardsForHall competition={competition} setSwitcher={setSwitcher}langSwitch={langSwitch}/>
:null}
{switcher===4?
    <InformCards competition={competition} setSwitcher={setSwitcher}langSwitch={langSwitch}/>
:null}
{switcher===5?
    <FreestalNotes competition={competition} setSwitcher={setSwitcher}langSwitch={langSwitch}/>
:null}
{switcher===6?
    <TraditionalNotes competition={competition} setSwitcher={setSwitcher}langSwitch={langSwitch}/>
:null}

    </>)
}

export default EslePrint