import s from './userProfile.module.css'
import state from '../../../state/state.js';
import ChangePasswordForm from './changePasswordForm';
import React, { useState } from "react";
import axios from 'axios';
import compressImage from '../photo/compressImage';
import CoachChoice from './coachChoice';
import ConfirmButtons from '../adminCompetition/confirmButtons';
import SportClubChoice from './sportClubChoice';
import lang from '../../../state/language.js';
import indexedDBConnection from '../../../state/indexedDBConnection.js';




function EditProfile(props) {
 const langSwitch=props.langSwitch
  state.messageCount()//лічильник непрочитаних повідомлень

  const [coachChoiceModalOpen, setCoachChoiceModalOpen] = useState(false);
  const [coachCoach2, setCoachCoach2] = useState(1);
  const [coachForScrin, setCoachForScrin] = useState(null);
   const [coachForScrin2, setCoachForScrin2] = useState(null);
      const [sportClubData, setSportClubData] = useState(state.userProfile.sportClub);
       const [pass, setPass] = useState(state.userProfile.pass);
  const [sportClubChoiceModalOpen, setSportClubChoiceModalOpen] = useState (false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userPhoto, setUserPhoto] = useState(null);
 const [userPhotoSwitcher, setUserPhotoSwitcher] = useState(false);
   // Модальне вікно з тренера

   const openCoachChoiceModal = () => { setCoachChoiceModalOpen(true); }
   const closeCoachChoiceModal = () => { setCoachChoiceModalOpen(false); }


   // Модальне вікно з Тренерами
 const openSportClubChoiceModal = () => {setSportClubChoiceModalOpen(true);}
 const closeSportClubChoiceModal = () => {setSportClubChoiceModalOpen(false);}

 if (!userPhotoSwitcher){
  setUserPhoto(state.userProfile.photo)
  setUserPhotoSwitcher(true)
}


  const handleSecondNameChange = (e) => { const value = e.target.value; state.tempUserProfile.secondName = value.trim(); };

  const handlePersonNameChange = (e) => { const value = e.target.value; state.tempUserProfile.personName = value.trim() };

  const handleFatherNameChange = (e) => { const value = e.target.value; state.tempUserProfile.fatherName = value.trim() };

  const handleBirthdayChange = (e) => { const value = e.target.value; state.tempUserProfile.birthday = value.trim() };




  const sex = [
    lang[langSwitch].sexM, //Чоловік
    lang[langSwitch].sexF //Жінка
]
  const handleSexChange = (e) => {
    let value = e.target.value;
    if (value === 'Чоловік'||value === 'Male') { value = 'male' }
    else if (value === 'Жінка'||value === 'Female') { value = 'female' }

    state.tempUserProfile.sex = value.trim()
  };


  const handleWeihtChange = (e) => {
    const inputValue = e.target.value;

    state.tempUserProfile.weiht = parseFloat(inputValue.replace(',', '.'))

  };

  const handleHeightChange = (e) => {
    let inputValue = e.target.value;

    state.tempUserProfile.height = parseFloat(inputValue.replace(',', '.'));
  };


  // вибираємо спорстменів що не є тренерами
  const notCoach = state.athletes.filter(athlete => athlete.status.includes('Тренер'));

function putCoach1 (value){
  state.addCoach(state.userProfile.id,value,'coach')
  setCoachForScrin(value)
}
function putCoach2 (value){
  state.addCoach(state.userProfile.id,value,'coach2')
  setCoachForScrin2(value)
}



  const handleRegionChange = (e) => { const value = e.target.value; state.tempUserProfile.region = value.trim() };
  
  const region = [...state.region].sort();


  
  const handledDanChange = (e) => { const value = e.target.value; state.tempUserProfile.dan = value.trim() };
  const dan = state.dan;

  const handleRankChange = (e) => { const value = e.target.value; state.tempUserProfile.rank = value.trim() };
  const rank = [...state.rank];

  const handleFstChange = (e) => { const value = e.target.value; state.tempUserProfile.fst = value.trim() };
  const fst = [...state.fst];



  const handleInsuranceCompChange = (e) => { const value = e.target.value; state.tempUserProfile.insuranceComp = value.trim() };

  const handleInsuranceNumChange = (e) => { const value = e.target.value; state.tempUserProfile.insuranceNum = value.trim() };

  const handleInsuranceDateChange = (e) => { const value = e.target.value; state.tempUserProfile.insuranceDate = value.trim() };

  const handleeEMailNumChange = (e) => { const value = e.target.value; 
    const checkEMail = state.athletes.filter(item=>item.eMail===value.trim())
    const newEMail = value.trim()
    if(checkEMail.length===0||state.userProfile.eMail===newEMail){
     state.tempUserProfile.eMail = newEMail
    }
    else{setErrorMessage(
      lang[langSwitch].usedMail //Вказаний вами e-Mail вже використовується іншим користувачем
    )}
    
 };

  const handleMedicalCertificateDateChange = (e) => { const value = e.target.value; state.tempUserProfile.medicalCertificateDate = value.trim()  };

  const handleMedicalCertificateNumChange = (e) => { const value = e.target.value; state.tempUserProfile.medicalCertificateNum = value.trim() };

  const handleLoginDateChange = (e) => { 
    const value = e.target.value.trim(); 
   
    const loginTest = state.athletes.some((item)=>item.login===value)
    if (loginTest&&value!==state.userProfile.login){
      setErrorMessage( lang[langSwitch].usedLoginByAnotherUser/*Обраний вами логін вже використовується іншим користувачем*/)
    }else{setErrorMessage(null)
    state.tempUserProfile.login = value
    }
 };
 
  //обробка фото 
  const [compressedFileURL, setCompressedFileURL] = useState(null);
  const [compressedFile, setCompressedFile] = useState(null);
 
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    state.tempUserProfile.photo = state.imgAdress.userProfilePhoto + selectedFile.name;



    // Викликати функцію стиснення зображення та отримати стиснутий файл
    compressImage(selectedFile, {
      maxSizeMB: 0.3,
      maxWidth: 310,
      maxHeight: 394,
    })
      .then((compressedFile) => {
    
        setCompressedFile(compressedFile)
        // Створити URL для стиснутого файлу
        const compressedFileURL = URL.createObjectURL(compressedFile);

        // Встановити URL стиснутого файлу у стані компонента
        setCompressedFileURL(compressedFileURL);
      })
      .catch((error) => {
        console.error('Помилка стиснення файлу:', error);
      });
  };

  // отримуємо назву файлу, для видалення старого фото 

  //завантаження на сервер

  function update() {

    if (compressedFileURL) {
      const formData = new FormData();
      formData.append('image', compressedFile);

      axios.post(state.api.uploadAvatar, formData)
        .then(response => {
          // Отримано відповідь від сервера після завантаження зображення
       
          if (response.status === 200 && response.data.message === 'Файл успішно збережено') {
          if (state.userProfile.photo!==null){
//ортимуємо назву старого фото
  const parts =userPhoto.split('/');
    const photoName = parts[parts.length - 1];
 
 //видалення старого фото
    axios.delete(state.api.deleteProfileAvatar, {
      data: {                
        fileName: photoName
      }
    })
    .then(response => { // Обробка успішного видалення  
    })
    .catch(error => { console.log('Помилка видалення старого аватару:', error);// Обробка помилок
    });  
          }
     
          // Після завантаження зображення ви можете виконати PUT-запит для оновлення профілю
          updateProfile();
        } else {
          throw new Error('Сервер повернув відповідь без успішного статусу');
        }

        })
        .catch(error => {
          console.error('Помилка під час збереження зображення:', error);
          setErrorMessage( lang[langSwitch]. errorOccurredWhileSavingImage+': ' /*Помилка під час збереження зображення*/,error)
        });
    } else {
      // Якщо `compressedFileURL` не існує, виконуємо тільки PUT-запит для оновлення профілю   
      updateProfile();
    }
  }

  function updateProfile() { 
if(!state.tempUserProfile.login||state.tempUserProfile.login===''){
  setErrorMessage( lang[langSwitch].lostLogin/*Було втрачено важливі дані. Будь ласка заповніть поле "Логін"*/)
  return
}
if(!state.testObjects(state.userProfile,state.tempUserProfile)){

  state.tempUserProfile.sportClub=sportClubData
  
    axios.put(state.api.updateProfile, {
      id: state.tempUserProfile.id,
      secondName: state.tempUserProfile.secondName,
      personName: state.tempUserProfile.personName,
      fatherName: state.tempUserProfile.fatherName,
      birthday: state.tempUserProfile.birthday,
      status: state.tempUserProfile.status,
      sex: state.tempUserProfile.sex,
      weiht: state.tempUserProfile.weiht,
      height: state.tempUserProfile.height,
      coach: state.tempUserProfile.coach,
      coach2: state.tempUserProfile.coach2,
      sportClub: sportClubData,
      region: state.tempUserProfile.region,
      dan: state.tempUserProfile.dan,
      rank: state.tempUserProfile.rank,
      fst: state.tempUserProfile.fst,
      insuranceComp: state.tempUserProfile.insuranceComp,
      insuranceNum: state.tempUserProfile.insuranceNum,
      insuranceDate: state.tempUserProfile.insuranceDate,
      eMail: state.tempUserProfile.eMail,
      medicalCertificateDate: state.tempUserProfile.medicalCertificateDate,
      medicalCertificateNum: state.tempUserProfile.medicalCertificateNum,
      login: state.tempUserProfile.login,
      pass: pass,
      photo: state.tempUserProfile.photo
    })
      .then(response => {       
          indexedDBConnection.saveData ('userProfile', Object.assign(state.tempUserProfile))                           
              state.userProfile = JSON.parse( JSON.stringify(state.tempUserProfile))
              state.globalId = state.userProfile ? parseInt(state.userProfile.id) : null;        
              window.location.reload(true); // при перезавантаженні дані беруться з кешу        
            })
      .catch(error => {
        console.error(lang[langSwitch]. serverRequestError/*Помилка запиту до сервера: */, error);
        setErrorMessage(lang[langSwitch]. serverRequestError/*Помилка запиту до сервера: */, error)

      });
    }
    
  }
 function deleteProfile(){


  axios
  .delete(state.api.deleteUserProfile, {
      params: {
          id: state.userProfile.id,
          fileName: state.userProfile.photo, // Додано передачу імені файлу
      },
      headers: { 'Content-Type': 'application/json' },
  })
  .then((response) => {
    
   state.exit()
  window.location.reload();// Перезавантаження сторінки
    })
  .catch((error) => {
      setErrorMessage(
        lang[langSwitch].serverRequestError/*Помилка запиту до сервера: */ + error);
  });

 }
 ////////////////////////////////ПРОГРАМНА ЧАСТИНА КНОПОК ПІДТВЕРДЖЕННЯ////////////////////////////////////////////////////////////
 const [messageText, setMessageText] = useState('');
 const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState(false);

  function editResultConfirm(query) {// ФУНКЦІЯ ЯКА ЗАПУСКАЄ ОПИТУВАННЯ
//    встановлений текст над кнопками є трігером, 
//якщо він повертається з компоненти - спрацьовує функція
if(query==='update profile'){
      setMessageText(
        lang[langSwitch].saveChangesInProfileQuery //Зберегти зміни у вашому профілі?
      )  
      openConfirmButtonsModal()
}
if(query==='delete profile'){
  setMessageText(
    lang[langSwitch].deleteProfileQuery //УВАГА ВИДАЛЕННЯ ПРОФІЛЮ Є НЕЗВОРОТНИМ ПРОЦЕСОМ! Ви погоджуєте дію?
  )  
  openConfirmButtonsModal()
}

  }
  const confirmFunction = (messageText) => {
      if (messageText === 
        lang[langSwitch].saveChangesInProfileQuery //Зберегти зміни у вашому профілі?
      ) {
        update() //ФУНКЦІЯ ЯКА ВИКОНУЄТЬСЯ В РАЗІ ПІДТВЕРДЖЕННЯ
      }
 if (messageText === 
  lang[langSwitch].deleteProfileQuery //УВАГА ВИДАЛЕННЯ ПРОФІЛЮ Є НЕЗВОРОТНИМ ПРОЦЕСОМ! Ви погоджуєте дію?
 ) {
        deleteProfile() //ФУНКЦІЯ ЯКА ВИКОНУЄТЬСЯ В РАЗІ ПІДТВЕРДЖЕННЯ
      }
  }
  const openConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(true); }
  const closeConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(false); }
///////////////////////////////////////////////////////////////////////////////////////////////////
  return (<>
  <div className={!props.isSmallScreen?s.container:s.containerSmall}>
    <div className={s.header}>


      <div className={s.warning}>
      {lang[langSwitch].warningToSubmitCompetition}{/* Увага! Для подачі заяки на участь в змаганнях повинні бути заповненні усі поля форми без вийнятку! */}
      </div>
     
    </div>

    <div className={s.leftCol}>

      <form className={s.form}>
        <p>
          <label htmlFor="secondName"><span className={s.span}>
          {lang[langSwitch].lastName}{/*Прізвище*/}:
            </span> {state.userProfile.secondName}</label><br />
          <input onChange={handleSecondNameChange} type="text" id="secondName" name="secondName" />
        </p>
        <p>
          <label htmlFor="personName"><span className={s.span}>
          {lang[langSwitch].firstName}{/*Ім'я*/}:
            </span> {state.userProfile.personName}</label><br />
          <input onChange={handlePersonNameChange} type="text" id="personName" name="personName" />
        </p>

        <p>
          <label htmlFor="fatherName"><span className={s.span}>
          {lang[langSwitch].patronymic}{/*По-батькові*/}: 
            </span> {state.userProfile.fatherName}</label><br />
          <input onChange={handleFatherNameChange} type="text" id="fatherName" name="fatherName" />
        </p>
        <p>
          <label htmlFor="birthday"><span className={s.span}>
          {lang[langSwitch].dateOfBirth}{/*Дата народження*/}:
            </span> {props.formatDate(state.userProfile.birthday)} 
          {props.formatDate(state.userProfile.birthday)!==null?
         lang[langSwitch].yearShort /*р.*/
          :''}</label><br />
          <input onChange={handleBirthdayChange} type="date" id="birthday" name="birthday" />
        </p>




        {state.userProfile.sex === null ? (
          <>
            <div>
              <div className={s.smallRed}>
              {lang[langSwitch].warningSex}{/*Будьте уважні! Подальші зміни в полі "Стать" робитимуться тількі службою технічної підтримки по заявці вашого тренера!*/}
                </div><br />
              <label htmlFor="sex"><span className={s.span}>
              {lang[langSwitch].enterYourGender}{/*Вкажіть вашу стать*/}
                </span></label><br />
              <select onChange={handleSexChange} id="sex" name="sex" >
                <option value=""></option>
                {sex.map((sex) => (
                  <option key={sex} value={sex}>
                    {sex}
                  </option>
                ))}
              </select>
            </div>
          </>
        ) : (
             null 
        )}


        <div>
          <p>
            <label htmlFor="weiht"><span className={s.span}>
            {lang[langSwitch].weight}{/*вага*/}:
              </span> {state.userProfile.weiht} 
              {lang[langSwitch]. kg /*кг.*/}
              </label><br />
            <input  type="text" id="weiht" name="weiht" onChange={handleWeihtChange}/>
          </p>
        </div>

        <div>
          <p>
            <label htmlFor="height"><span className={s.span}>
            {lang[langSwitch].height}{/*Зріст*/}:
              </span> {state.userProfile.height}
              {lang[langSwitch]. cm /*см.*/}
               </label><br />
            <input
              type="text"
              id="height"
              name="height"

              onChange={handleHeightChange}
            />
          </p>
        </div>

        <p>
          <label htmlFor="region"><span className={s.span}>
          {lang[langSwitch].regionalOffice}{/*Обласний осередок*/}:
            </span><br /> 
            {langSwitch==='ukr'?state.userProfile.region:
            langSwitch==='eng'?lang.regionsEng(state.userProfile.region):null            
            }
            
            </label><br />
          <select onChange={handleRegionChange} 
         className= {props.isSmallScreen?s.regionSelect:null}
          id="region" name="region" >
            <option value="">
            {lang[langSwitch].chooseRegionalOffice}{/*Оберіть обласний осередок*/}
              </option>
            {region.map((region) => (
              <option key={region} value={region}>
                {region}
              </option>
            ))}
          </select>
        </p>
       
        <p >
          <label htmlFor="coach">
            <span className={s.span}>
            {lang[langSwitch].sportsClub}{/*Спортклуб*/}:&nbsp;
              </span> 
            {sportClubData?sportClubData: state.tempUserProfile.sportClub}
          </label>
          <br />

         <button type='button' className={s.button} onClick={()=>{openSportClubChoiceModal()}}>
         {lang[langSwitch].chooseSportsClub}{/*Оберіть спортклуб*/}
          </button>

        </p>


    <p >
          <label htmlFor="coach">
            <span className={s.span}>
              1 {lang[langSwitch].coach}{/*тренер*/}:
              </span> 
            {coachForScrin?state.myCoach(coachForScrin):state.myCoach(state.userProfile.coach)}
          </label>
          <br />

         <button type='button' className={s.button} onClick={()=>{openCoachChoiceModal(); setCoachCoach2(1)}}>
         {lang[langSwitch].chooseCoach}{/*Оберіть тренера*/}
          </button>

        </p>

        <p>
          <label htmlFor="coach2">
            <span className={s.span}>2 {lang[langSwitch].coach}{/*тренер*/}:</span> 
             {coachForScrin2?state.myCoach(coachForScrin2):state.myCoach(state.userProfile.coach)}
          </label>
          <br />

          <button type='button' className={s.button} onClick={()=>{openCoachChoiceModal(); setCoachCoach2(2)}}>
          {lang[langSwitch].chooseCoach}{/*Оберіть тренера*/}
            </button>


        </p>



        <p>
          <label htmlFor="dan"><span className={s.span}>
          {lang[langSwitch].qualification}{/*кваліфікація*/}:&nbsp;
            </span> 
             {langSwitch==='ukr'?state.userProfile.dan:
            langSwitch==='eng'?lang.danEng(state.userProfile.dan):null}
            </label><br />
          <select onChange={handledDanChange} id="dan" name="dan" >
            <option value=""></option>
            {dan.map((dan) => (
              <option key={dan} value={dan}>
                {dan}
              </option>
            ))}
          </select>
        </p>

        <p>
          <label htmlFor="rank"><span className={s.span}>
          {lang[langSwitch].rank}{/*Розряд*/}:&nbsp;
            </span> 
            {langSwitch==='ukr'?state.userProfile.rank:
            langSwitch==='eng'?lang.rankEng(state.userProfile.rank):null}
            </label><br />
          <select onChange={handleRankChange} id="rank" name="rank" >
            <option value=""></option>
            {rank.map((rank) => (
              <option key={rank} value={rank}>
                {rank}
              </option>
            ))}
          </select>
        </p>

        <p>
          <label htmlFor="fst"><span className={s.span}>
          {lang[langSwitch].PCSS}{/*ФСТ*/}:
            </span> {state.userProfile.fst}</label><br />
          <select onChange={handleFstChange} id="fst" name="fst" >
            <option value=""></option>
            {fst.map((fst) => (
              <option key={fst} value={fst}>
                {fst}
              </option>
            ))}
          </select>
        </p>

      </form >

    </div>


    <div className={s.rigthCol}>
      <form className={s.form} >

        <p>
          <label htmlFor="insuranceComp"><span className={s.span}>
          {lang[langSwitch].insuranceCompany}{/*Страх.комп.*/}:
            </span> {state.userProfile.insuranceComp}</label><br />
          <input onChange={handleInsuranceCompChange} type="text" id="insuranceComp" name="insuranceComp" />
        </p>

        <p>
          <label htmlFor="insuranceNum"><span className={s.span}>
          {lang[langSwitch].numberOfInsurancePolicy}{/*Сер./ном. полісу*/}:
            </span> {state.userProfile.insuranceNum}</label><br />
          <input onChange={handleInsuranceNumChange} type="text" id="insuranceNum" name="insuranceNum" />
        </p>

        <p>
          <label htmlFor="insuranceDate"><span className={s.span}>
          {lang[langSwitch].insuranceDate}{/*Термін дії полісу*/}:
            </span> {props.formatDate(state.userProfile.insuranceDate)}
          {props.formatDate(state.userProfile.insuranceDate)!==null?
          lang[langSwitch]. yearShort /*р.*/
          :''}</label><br />
          <input onChange={handleInsuranceDateChange} type="date" id="insuranceDate" name="insuranceDate" />
        </p>
        <p>
          <label htmlFor="medicalCertificateNum"><span className={s.span}> 
          {lang[langSwitch].numberOfMedicalCertificate}{/*Сер./ном. мед. сертиф.*/}:
            </span> {state.userProfile.medicalCertificateNum}</label><br />
          <input onChange={handleMedicalCertificateNumChange} type="text" id="medicalCertificateNum" name="medicalCertificateNum" />
        </p>
        <p>
          <label htmlFor="medicalCertificateDate"><span className={s.span}>
          {lang[langSwitch].medicalCertificateDate}{/*Термін дії мед. сертиф.*/}:
            </span> {props.formatDate(state.userProfile.medicalCertificateDate)}
          {props.formatDate(state.userProfile.medicalCertificateDate)!==null?
          lang[langSwitch]. yearShort /*р.*/
          :''}</label><br />
          <input onChange={handleMedicalCertificateDateChange} type="date" id="medicalCertificateDate" name="medicalCertificateDate" />
        </p>

        <div className={s.smallGreen}>
        {lang[langSwitch].eMailUsed}{/*e-Mail використовується для розсилкі турнірних сіток та відновлення паролю!*/}
          </div>

        <p>
          <label htmlFor="eMail"><span className={s.span}>e-Mail:</span> {state.userProfile.eMail}</label><br />
          <input onChange={handleeEMailNumChange} type="text" id="eMail" name="eMail" />
        </p>


  <p>
          <label htmlFor="log"><span className={s.span}>
          {lang[langSwitch].login}{/*Логін*/}:
            </span>
            {state.userProfile.login}
            </label>
            <br />
          <input onChange={handleLoginDateChange} type="text" id="log" name="log"  autoComplete='off'/>
        </p>
</form>
<form className={s.form}>

      
        <div>
          <label htmlFor="pass"><div className={s.smallGreen}>
          {lang[langSwitch].passwordChangeWarning}{/*При зміні паролю зважте, що система не приймає символи кирилиці!*/}
            </div></label><br/>
          <ChangePasswordForm 
          setPass={setPass}
          updatePassword={state.userProfile.pass}
          langSwitch={langSwitch}
          /><br />
        </div>


        <div>
          <label htmlFor="photo"><span className={s.span}>
          {lang[langSwitch].photo}{/*Фото*/}:
            </span></label><br />
          <div>
            {compressedFileURL && (
              <img src={compressedFileURL} alt="Стигнутий файл" />
            )}<br />
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
            /><br />
          </div>
        </div>

      </form>
      
    </div>
 
    <div className={s.footer}>
   
<div className={!props.isSmallScreen?s.footerL:null}>



  <button type='button' onClick={()=>{editResultConfirm('update profile')}}  style={{ cursor: 'pointer' }}>
  {lang[langSwitch].save}{/*Зберегти*/}
  </button></div>

<div className={!props.isSmallScreen?s.footerR:null}>
 { props.isSmallScreen?(<><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/></>):null}
  <button type='button'className={s.exitButton} onClick={()=>{editResultConfirm('delete profile')}}  style={{ cursor: 'pointer' }}>
  {lang[langSwitch].deleteProfile}{/*Видалити профіль*/}
  </button>
</div></div>


    
    <div>  {/*Модальне вікно з вибором  тренера */}
        <CoachChoice 
        isOpen={coachChoiceModalOpen} 
        onClose={closeCoachChoiceModal} 
        notCoach={notCoach}
        putCoach= {coachCoach2==1?putCoach1:putCoach2}  
        isSmallScreen={props.isSmallScreen}
        langSwitch={langSwitch}         
         />

<SportClubChoice
          setSportClubData={setSportClubData}
          isOpen={sportClubChoiceModalOpen}
          onClose={closeSportClubChoiceModal} 
          isSmallScreen={props.isSmallScreen}
          langSwitch={langSwitch}         
        />  

<ConfirmButtons
            isOpen={isConfirmButtonsModalOpen}
            onClose={closeConfirmButtonsModal}
            confirmFunction={confirmFunction}
            messageText={messageText}
            langSwitch={langSwitch}
        />
      </div>
     
     
  </div>
     {errorMessage && <><br/> <p className={s.errorMessage} onClick={()=>{setErrorMessage('')}}>
  {errorMessage}</p></>}
</>)
}
export default EditProfile