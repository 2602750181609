import s from './grids.module.css'
import state from '../../../../state/state.js'
import lang from '../../../../state/language.js';
import GridFooter from './GridFooter.js';



function GridForAthlets4(props) {
	const poomse=props.competition.poomse==1?true:false 

	const athleteList = props.grid.athleteList

	const {
		score1=null, score2=null, score3=null	} = props.grid.battleScores||{}

	const {
		winner=null, winner1=null, winner2=null	} = props.grid.winners||{}
	
	const winnerAthletes = props.grid.winnerAthletes?props.grid.winnerAthletes:null

	return (<>
		<div className={s.mainDiv}>
			<div className={s.title}>
				<h3>{props.competition.title}</h3>
				<h3 >
  {props.competition.dateStart&&props.competition.dateFinish?(<>
  <u><b>{state.formatDate(props.competition.dateStart).slice(0, 2)}</b>- 
        <b>{state.formatDate(props.competition.dateFinish)} р.</b></u>         
      </>):(<>
    <u><b>  {props.competition.dateOfCompetition}р.</b></u> 
      </>)}
        <br/></h3>
				<h4 id={props.id}>
				{poomse?lang.poomsaeCatUkr(props.langSwitch,props.grid.category):
	state.weightCatUkr (props.grid.category,props.langSwitch, true)}
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					Корт: {props.cort}</h4>
			</div>
			<table width="600" >
				<tbody>
					<tr>
						<td></td>
						<td>
							
							
							{winner !== null ? (<>
<div className={s.sportCenter}><div className={s.divAthleteInGrid} >
	<div><img className={s.goldCup} alt='фото' src = '/img/main/goldCup1.png'/></div>
	{winner.teamPoomsae?state.poomsaeTeam(winner.teamPoomsae):state.athleteNameShort(winner.id)}
<br />
	<div className={s.battleScore}>{score1?score1:null}</div>{/*рахунок поєдинка*/}
	
	</div> </div>
	</>) :
			<div className={s.figthCenter}>{props.cort}.{props.battleNumbers[2]} </div>}
			
			{/*ввод рахунку поєдинка*/}
		
			<br/>		
			</td>
				<td></td>
					</tr>
					<tr>
						<td className={s.blueLeft}>
								<div className={s.divAthleteInGrid}>
									
										{ athleteList[0].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[0].teamPoomsae)}<i>{athleteList[0]['team']}</i></>) : (<>{state.athleteName(athleteList[0]['id'])}<br /><i>{athleteList[0]['team']}</i></>) }
									</div>

							
							<div className={s.figthLeft} >
								{props.cort}.{props.battleNumbers[0]}
								</div>
						
						</td>
						<td className={s.nextRed}><div className={s.sportCenter}>

							{/*  фінал */}


							{winner1 !== null ? (<>

								<div className={s.divAthleteInGrid} >
									{winner1.teamPoomsae?state.poomsaeTeam(winner1.teamPoomsae):state.athleteNameShort(winner1.id)}
<br /> </div>
							</>) :
								(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>)}
								<div className={s.battleScore}>{score2?score2:null}</div>
						</div>
						</td>
						<td className={s.blueRight}>
							<table><tbody>
								<tr>
							<td className={s.figthRight} >
								{props.cort}.{props.battleNumbers[1]}
								</td>
							<td className={s.sportRight}>
								<div className={s.divAthleteInGrid}>
									{ athleteList[2].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[2].teamPoomsae)}<i>{athleteList[2]['team']}</i></>) : (<>{state.athleteName(athleteList[2]['id'])}<br /><i>{athleteList[2]['team']}</i></>) }
									</div>
							</td>
						</tr>
						</tbody></table>
						</td>
					</tr>
					<tr>
						<td></td><td className={s.next}></td><td ></td>
					</tr>
					<tr>
						<td className={s.redLeft}><table width="94%"><tbody><tr>
							<td>
								<div className={s.divAthleteInGrid}>
									{ athleteList[1].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[1].teamPoomsae)}<i>{athleteList[1]['team']}</i></>) : (<>{state.athleteName(athleteList[1]['id'])}<br /><i>{athleteList[1]['team']}</i></>) }
									</div>
							</td>
							<td className={s.colorLeft}>Син.</td>
						</tr></tbody></table>
						</td>
						<td className={s.next}><div className={s.sportCenter}>

							{/*  та фінал */}
							{winner2 !== null ? (<>
								<div className={s.divAthleteInGrid} >
									{winner2.teamPoomsae?state.poomsaeTeam(winner2.teamPoomsae):state.athleteNameShort(winner2.id)}
<br /> </div>
							</>) :
								(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>)}
					<div className={s.battleScore}>{score3?score3:null}</div>
					</div>	
					</td>
						<td className={s.redRight}>
							<table><tbody>
								<tr>
							<td className={s.colorRight}>Чер.</td>
							<td className={s.sportRight}>
								<div className={s.divAthleteInGrid}>
										{ athleteList[3].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[3].teamPoomsae)}<i>{athleteList[3]['team']}</i></>) : (<>{state.athleteName(athleteList[3]['id'])}<br /><i>{athleteList[3]['team']}</i></>) }
									</div>


							</td>
						</tr></tbody></table></td>
					</tr>

				</tbody>
			</table>
{/* ///////////////////////////////////////////////////////////////// */}
<GridFooter competition={props.competition} winnerAthletes={winnerAthletes} />
	


		</div>
	</>)
}

export default GridForAthlets4