import s from '../adminCompetition/adminCompetition.module.css';
import state from "../../../state/state.js";
import { useState } from "react";
import TeamsCell from "./teamsCell";
import lang from "../../../state/language.js";


function TeamsList({ isOpen, onClose, competition, langSwitch}) {

  const [teamsCellModalOpen, setTeamsCellModalOpen] = useState (false);

  
  const [catForModal, setCatForModal] = useState (null);

 // Модальне вікно з Категоріями спортсменів
 const openTeamsCellModal = (cat) => {
  setCatForModal(cat)
 
    setTeamsCellModalOpen(true);
  }
  const closeTeamsCellModal = () => {setTeamsCellModalOpen(false);}

///////////////////////////////////////////////////////////////////////////////////////

const sortAthletesByTeams = (athletes)=>{//Сортуємо спортсменів по категоріям
  const entryAthletes = state.tournConstructor.entryAthletesFind(athletes)   

  const groupedByTeam = entryAthletes.reduce((acc, athlete) => {
    const team = athlete.team;
  
    // Додати новий об'єкт для команди, якщо її ще немає в результаті
    if (!acc[team]) {
      acc[team] = { team, athletes: [] };
    }
  
    // Додати поточного атлета до команди
    acc[team].athletes.push(athlete);
  
    return acc;
  }, {});
  
  // Перетворити об'єкт згрупованих команд у масив
  const resultArray = Object.values(groupedByTeam);
  
  return resultArray
  }

  let entryTeams
 if (competition.athletes){
   entryTeams=sortAthletesByTeams(competition.athletes)
}

///////////////////////////////////////////////////////////////////////////////////////////


  // const createMarkup = (html) => {return { __html: html };};
  return (<>
    {isOpen?(<>
      <div className={s.modalWriper}>
    <div  className={s.modal}  contentLabel="TeamsList Modal" >


<div className={s.title} >{competition.title}
<p><u><b>{state.formatDate(competition.dateStart).slice(0, 2)}</b>- 
        <b>{state.formatDate(competition.dateFinish)}&nbsp;
        {lang[langSwitch].yearShort /*р.*/}</b></u> <br/><br/></p>
              </div>  {/*Закриття шапки */}

      <div className={s.gridContainerForCategories} key='gridContainerForCategories'>
        {entryTeams.map((cat, index) => (
          <div key={cat.team+index} className={s.categoryBlock}>
            <div className={s.boxTitile}  onClick={()=>{openTeamsCellModal(cat)}}
             style={{ cursor: 'pointer' }}>
            <div className={s.categoryBlockTitle}>
              {cat.team}</div>
<div className={s.region}>
   {cat.athletes.length%10===1?
   lang[langSwitch].declared1 /*Заявлений*/+':'+' '
   :
   lang[langSwitch].declared /*Заявлено*/+':'+' '
   }
  <b>{cat.athletes.length}</b>
{cat.athletes.length%10===0?
' '+lang[langSwitch].participants /*учасників*/
:''}
{cat.athletes.length%10===1&&cat.athletes.length!==11?
' '+lang[langSwitch].participant /*учасник*/
:''}
{(cat.athletes.length % 10 >= 2 && cat.athletes.length % 10 <= 4) && (cat.athletes.length % 100 < 10 || cat.athletes.length % 100 >= 20) ? 
' '+lang[langSwitch].participants1 /*учасникa*/
: ''}

{cat.athletes.length%10>4||cat.athletes.length===11?
' '+lang[langSwitch].participants /*учасників*/
:''}
<br/>&nbsp;</div>
          </div>

          </div>
        ))}
      </div>

 {/*Виклик Вікна */}
      {/* <div dangerouslySetInnerHTML={createMarkup(regulationText)} /> */}
      <button type='button' onClick={onClose}>
      {lang[langSwitch].close /*Закрити*/}
        </button>
 
    </div>
   </div>
   </>):null}
   {catForModal?
 
 <TeamsCell cat={catForModal}
          isOpen={teamsCellModalOpen}
          onClose={closeTeamsCellModal} 
          competition={competition}
          langSwitch={langSwitch}         
        />:null}        



 </>)

}

export default TeamsList