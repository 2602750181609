import lang from '../../../../state/language'
import state from '../../../../state/state'
import s from './grids.module.css'

function GridFooter({competition,wiew,deleteGridsResult,gridsResultMaker,
                    winnerAthletes,winner}){

					
    return(<>
    			<div className={s.footerBox}>
			<div className={s.footer1}> 
<div className={s.footer1Item1}>
<span className={s.secretary}>
 Гол. суддя 
 </span>
  &nbsp;&nbsp;<b> {state.myCoach(competition.mainJudge)} </b>
&nbsp;&nbsp; <span className={s.infoDescription}>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
 (підпис)   
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>   </div>

<div className={s.footer1Item2}>
{wiew?(<>
<button className={s.deleteScoreButton}onClick={()=>{deleteGridsResult()}}>Прибрати рахунки</button>
</>):(<>	<div className={s.warningSpell}>Натиснути обов'язково!</div>
<button className={s.scoreButton}onClick={()=>{gridsResultMaker()}}>Додати рахунки</button>
</>)}
</div>


 <div className={s.footer1Item3}>
 <span className={s.secretary}>
 Гол. секретар 
 </span> 
 &nbsp;&nbsp; <b> {state.myCoach(competition.secretary)}</b>  
&nbsp;&nbsp; <span className={s.infoDescription}>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
 (підпис)   
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span> 
</div>	      
<div className={s.footer1Item4}>
<span >
м.п.&nbsp;&nbsp; &nbsp;&nbsp;</span>
</div>	

		</div>
			<div className={s.footer2}>
			<table className={s.resultTable}>
			<tbody>
							<tr>
								<td className={s.tdRight}><b>I місце</b></td>
								<td className={s.tdLeft}>{winner&&winnerAthletes[0]?
								winnerAthletes[0].teamPoomsae?
								state.poomsaeTeam(winnerAthletes[0].teamPoomsae):
								state.myCoach(winnerAthletes[0].id)
								
								:null}</td>
							</tr>
							<tr>
								<td className={s.tdRight}><b>II місце</b></td>
								<td className={s.tdLeft}>{winner&&winnerAthletes[1]?
								winnerAthletes[1].teamPoomsae?
								state.poomsaeTeam(winnerAthletes[1].teamPoomsae):
								state.myCoach(winnerAthletes[1].id)
								:null}</td>
							</tr>
							<tr>
								<td className={s.tdRight}><b>III місце</b></td>
								<td className={s.tdLeft}>{winner&&winnerAthletes[2]?
								winnerAthletes[2].teamPoomsae?
								state.poomsaeTeam(winnerAthletes[2].teamPoomsae):
								state.myCoach(winnerAthletes[2].id)
								:null}</td>
							</tr>
							<tr>
								<td className={s.tdRight}><b>III місце</b></td>
								<td className={s.tdLeft}>{winner&&winnerAthletes[3]?
								winnerAthletes[3].teamPoomsae?
								state.poomsaeTeam(winnerAthletes[3].teamPoomsae):
								state.myCoach(winnerAthletes[3].id)
								:null}</td>
							</tr>
						</tbody>
			</table>
			</div>
</div>
    </>)
}

export default GridFooter