import lang from '../../../../state/language'
import state from '../../../../state/state'
import s from '../result.module.css'


function ResultByAthletsCutOffSystem({competition}){
    let athletes
    try{athletes= JSON.parse(competition.athletes)}catch{athletes= competition.athletes} 
    const resultDataTemp=JSON.parse(competition.results)
   const resultData = state.dataForcutOfSystemResult(resultDataTemp,athletes)
   
  //  console.log('athletes: ',athletes)
  //  console.log('resultDataTemp: ',resultDataTemp)
  //  console.log('resultData: ',resultData)
    return(<>
 
        <div className={s.mainDivForPrint}>
        <table className={s.mailTableResultByAthletsPoomsae}>{/* початок зовнішньої таблиці */}
        <thead>
       <tr>
         <td colSpan='10'>
       
           <div className={s.headerTitle}>
       <div className={s.competitionTitle}>{competition.title}</div>
       <div className={s.competitionLocation}>{competition.location}</div>
       
       <div >
         {competition.dateStart&&competition.dateFinish?(<>
         <u><b>{state.formatDate(competition.dateStart).slice(0, 2)}</b>- 
               <b>{state.formatDate(competition.dateFinish)} р.</b></u>         
             </>):(<>
           <u><b>  {competition.dateOfCompetition}р.</b></u> 
             </>)}
               <br/> <br/>
               <div className={s.textTitle}>Загальні результати виступів спортсменів</div>
       
               </div>
        
           </div>
       </td>
       </tr>
             <tr className={s.headerTable}>
               <td>№</td>
               <td>Учасник</td>
               <td>Категорія/<br/>Місце</td>
               <td>Дата<br/>народження</td>
               <td>Спорт.<br/>кваліф.</td>
               <td>Гип/Дан</td>
               <td>ФСТ</td>
               <td>Тренер/<br/>Тренер 2</td>
               <td>Осередок/<br/>Спортклуб/<br/>Команда</td>
       
             </tr>
             </thead>
            
            <tbody>
             {resultData.map((item,index)=>{
               return(<>
        <tr key={item+index}   className={s.bottomBorder2}>
               <td >{index+1}</td>
               <td >&nbsp;{state.myCoachFull(item.id)}</td>
       
               <table className={s.catAndPlacePoomsaeTable}>
               {item.poomsaeResult.place.map((item2,index2)=>{          
                 return(<>
                 
                 <tr  className={index2===item.poomsaeResult.place.length-1?null:s.bottomBorder}>
                 <td  className={s.catAndPlacePoomsae}>{lang.poomsaeCatUkr('ukr',item2.category)}</td>
                 <td  className={s.catAndPlacePoomsae}>{item2.place}</td>
                 </tr >
                 </>)
               })}
               </table>
       
               <td >{state.formatDate(item.birthday)}</td>
               <td >{item.rank}</td>
               <td >{item.dan}</td>
               <td >{item.fst}</td>
               <td > {state.myCoach(item.coach)}/ <br/>{state.myCoach(item.coach2)}</td>
               <td >{state.regionShort(item.region)}/ <br/>{item.sportClub}
             {item.secondTeam?(<>/<br/>{item.secondTeam}</>):null}
               
               </td>
       
        </tr>
       
               </>)
             })}
            </tbody>
            
            <tfoot>
         <tr>
           <td colspan='3'>
       <div className={s.foterOfDocument}>
       <br/>
            Гол. суддя  &nbsp;&nbsp;<b> {state.myCoach(competition.mainJudge)} </b>
        &nbsp;&nbsp; <span className={s.infoDescription}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
            (підпис)   
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       </span>  &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
       
       &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;м.п.
            </div>
       
       
       </td>
       <td colspan='6'>
       
            <div className={s.foterOfDocument}>
             <br/>
            Гол. секретар &nbsp;&nbsp; <b> {state.myCoach(competition.secretary)}</b>  
        &nbsp;&nbsp; <span className={s.infoDescription}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
            (підпис)   
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       </span>  &nbsp;&nbsp; 
        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                 
            </div>
          </td>  </tr>
            </tfoot>
            
            
             </table>
             </div>
        </>)
}

export default ResultByAthletsCutOffSystem