import { useEffect, useState } from "react";
import state from "../../../state/state.js";
import sAdmin from '../adminCompetition/adminCompetition.module.css';
import MyResultOnCompetitiion from "../userProfile/myResultOnCompetitiion.js";
import lang from "../../../state/language.js";
import { NavLink } from "react-router-dom";




function CommunityAthletes({isSmallScreen,langSwitch}){
  state.athletes.sort((a, b) => a.id - b.id)
    const [data, setData] = useState('');

    const [visibleAthletes, setVisibleAthletes] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const athletesPerPage = 45
    const [fetching, setFetching] = useState(true);

    const [athletesModals, setAthletesModals] = useState(Array(visibleAthletes.length).fill(false));

    const openMyResultOnCompetitiionModal = (index) => {
    const newAthletesModals = [...athletesModals];
    newAthletesModals[index] = true;
    setAthletesModals(newAthletesModals);
  };

  const closeMyResultOnCompetitiionModal = (index) => {
    const newAthletesModals = [...athletesModals];
    newAthletesModals[index] = false;
    setAthletesModals(newAthletesModals);
  };
//////////////////////////////////////////////////////////////////////////////////

    function scrollHandler(e) {
      const scrollHeight = Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.body.clientHeight,
        document.documentElement.clientHeight
      );
  
      if (scrollHeight - (window.scrollY + window.innerHeight) < 100) {
        setFetching(true);
      }
    }
  
    useEffect(() => {
      if (fetching&&data.length == 0) {
        const endIndex = currentPage * athletesPerPage;
        const newVisibleAthletes = result.slice(0, endIndex);
        setVisibleAthletes((prevVisibleAthletes) => [ ...newVisibleAthletes]);
      
       
        setCurrentPage(currentPage + 1);
        setFetching(false);
      }
    }, [fetching]);
  
    useEffect(() => {
      if(data.length == 0){
      document.addEventListener('scroll', scrollHandler);
      document.addEventListener('touchmove', scrollHandler);
    }
      return function () {
        document.removeEventListener('scroll', scrollHandler);
        document.removeEventListener('touchmove', scrollHandler);
      }
    }, []);

///////////////////////////////////////////////////////////////////////////////////////////////

  const notCoahes= state.athletes.filter(item=>item.status==='Спортсмен')
  
    const handleTitleChange = (e) => {
      setData(e.target.value);
      // Якщо дані введені, викликаємо фільтрацію і вивід результатів
      if (e.target.value.trim() !== '') {
        const notCoahes = state.athletes.filter(item => item.status === 'Спортсмен');
        const filteredResults = notCoahes.filter(item => {
          const fullName = `${item.secondName} ${item.personName} ${item.fatherName}`.toLowerCase();
          const searchData = e.target.value.toLowerCase();
          return fullName.includes(searchData);
        });
        setVisibleAthletes(filteredResults);
      } else {
        // Якщо дані не введені, використовуємо весь масив notCoahes
        const endIndex = currentPage * athletesPerPage;
        const newVisibleAthletes = notCoahes.slice(0, endIndex);
        setVisibleAthletes(newVisibleAthletes);
        setCurrentPage(currentPage + 1);
      }
    };

   
  
  
  //   форма пошуку
  let result
  data.length>0?
     result = notCoahes.filter(item => {
      const fullName = `${item.secondName} ${item.personName} ${item.fatherName}`.toLowerCase();
      const searchData = data.toLowerCase();  
      return fullName.includes(searchData);
    }):result=notCoahes


    return(<>

    
<div className={sAdmin.communityFind}>
          <label htmlFor="title"><span><b>
          {lang[langSwitch].startTypingName /* Почніть вводити призвище та ім'я*/}
            </b></span> </label><br /><br />
          <input onChange={handleTitleChange} type="text" id="title" name="title" />
         </div>
         <div className={sAdmin.gridContainerForCategories}>
           

     {
       visibleAthletes.map((athlete,index)=>{



        return(<>

        <div className={sAdmin.listForCoach}
        key={athlete.id}
        > 

        </div>
          
                <div className={sAdmin.cell}  >            
               
               <div className={sAdmin.categoryName}>
                 <div>  {state.athleteName(athlete.id)} </div>
                  <div className={sAdmin.athleteId}>  <b>id: {athlete.id}</b> </div>
                 <div className={sAdmin.region}><u>
                 {langSwitch==='ukr'?athlete.region:
                   langSwitch==='eng'? lang.regionsEng(athlete.region):null
                    }
                  </u></div>
                 <div className={sAdmin.region}>
                 {lang[langSwitch].coachShort /*трен.*/}:  <b> {state.myCoach(athlete.coach)}</b>; &nbsp;
                 {lang[langSwitch].coachShort /*трен.*/}2:  <b> {state.myCoach(athlete.coach2)}</b>
                 </div>
               </div>
               <div className={sAdmin.categoryImg}>
{Number(state.userProfile.id)  === Number(athlete.coach) || Number(state.userProfile.id) === Number(athlete.coach2)?(<>
                <NavLink to={`/editProfileByCoach/${athlete.id}`}>
                 <img 
                 src={athlete.photo?athlete.photo:state.noAvatarPhoto}               
                 alt={state.myCoach(athlete.id)} />
                </NavLink>
</>):(<>
                 <img 
                 src={athlete.photo?athlete.photo:state.noAvatarPhoto}               
                 alt={state.myCoach(athlete.id)} />
</>)}
              
                 

                 </div> 
                 <div className={sAdmin.categoryData}>
                 <div><b>{athlete.sportClub} </b></div>
                   <div>
                   {lang[langSwitch].qualificationShort /*кваліф.:*/}
                 <b>  {langSwitch==='ukr'?athlete.dan:
                   langSwitch==='eng'? lang.danEng(athlete.dan):null
                    } 
                    </b> 
                   </div>

                   <div> 
                   {lang[langSwitch].rank /*розряд*/}:
                   <b>  {langSwitch==='ukr'?athlete.rank:
                   langSwitch==='eng'? lang.rankEng(athlete.rank):null
                    } 
                    </b> 
                   </div>
                 
                   <div> 
                  {lang[langSwitch].height /*зріст*/}:  
                    <b> {athlete.height} </b>
                    {lang[langSwitch].cm /* см.*/} </div>
                  <div> 
                   {lang[langSwitch].weight /*вага*/}: 
                    <b> {athlete.weiht} </b>
                    {lang[langSwitch].kg /*кг. */} 
                    </div>

                  <div>
                    {lang[langSwitch].sex /*стать */}: 
                    <b> {athlete.sex === 'male' ? 
                    lang[langSwitch].sexM /*Чоловік*/
                     : null}
                      {athlete.sex === 'female' ?
                       lang[langSwitch].sexF /*Жінка*/
                       : null}
                      {athlete.sex === null || athlete.sex === undefined ? 
                     lang[langSwitch].notSpecified /*Не вказано*/ 
                      : null}
                    </b>
                  </div>
                  <div>
                   {lang[langSwitch].fights /*Бої*/}: 
                    <b> {athlete.battles}</b> </div>
                  <div>
                  {lang[langSwitch].victory /*Перемоги*/}: 
                    <b> {athlete.victory}</b></div>
                  
                    <div> 
                   {lang[langSwitch].rating /*рейтинг*/}: 
                    <b> {athlete.rating}
                      </b> {lang[langSwitch].points /*балів*/} </div>
                  
                  </div>
                  <div className={sAdmin.categoryRating}>
<button type='button' className={sAdmin.buttonOfPersonalResult} onClick={()=>{openMyResultOnCompetitiionModal(index)}}>
{lang[langSwitch].results /*Pезультати*/} 
  </button>
</div>          
               </div>
     
       
               <MyResultOnCompetitiion 
name={ athlete.secondName+' '+athlete.personName+' '+athlete.fatherName}
isOpen={athletesModals[index]}
onClose={() => closeMyResultOnCompetitiionModal(index)}
myCompetition={JSON.parse(athlete.myCompetition)}
isSmallScreen={isSmallScreen}
langSwitch={langSwitch}
/>
        </>)
       }) 
     }


  </div>  </>)
}

export default CommunityAthletes