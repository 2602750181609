import state from "../../../state/state.js"
import s from '../adminCompetition/adminCompetition.module.css';
import lang from "../../../state/language.js";

function TeamsCell({ isOpen, onClose, cat, competition, langSwitch }) {

  return (<>
   {isOpen?(<>
    <div className={s.modalWriper}>
    <div key="categoryCell Modal" className={s.modal}  contentLabel="categoryCell Modal" >
      <div key={cat.category} className={s.categoryBlock}>
        <div className={s.title}>{competition.title}
          <p><u><b>{state.formatDate(competition.dateStart).slice(0, 2)}</b>-
            <b>{state.formatDate(competition.dateFinish)}&nbsp;
              {lang[langSwitch].yearShort /*р.*/}
            </b></u> <br /><br /></p>
        </div> {/* Закриття шапки */}


        <div className={s.boxTitile1}>
          <div className={s.categoryBlockTitle} >
            {cat.team}</div>
          <div className={s.region}>
            {cat.athletes.length % 10 === 1 ?
              lang[langSwitch].declared1 /*Заявлений*/ + ':' + ' '
              :
              lang[langSwitch].declared /*Заявлено*/ + ':' + ' '
            }
            <b>{cat.athletes.length}</b>
            {cat.athletes.length % 10 === 0 ?
              ' ' + lang[langSwitch].participants /*учасників*/
              : ''}
            {cat.athletes.length % 10 === 1 && cat.athletes.length !== 11 ?
              ' ' + lang[langSwitch].participant /*учасник*/
              : ''}
            {(cat.athletes.length % 10 >= 2 && cat.athletes.length % 10 <= 4) && (cat.athletes.length % 100 < 10 || cat.athletes.length % 100 >= 20) ?
              ' ' + lang[langSwitch].participants1 /*учасникa*/
              : ''}

            {cat.athletes.length % 10 > 4 || cat.athletes.length === 11 ?
              ' ' + lang[langSwitch].participants /*учасників*/
              : ''}
            <br />&nbsp;</div>
        </div>

        <div className={s.gridContainerForCategories}>
          {cat.athletes.map((athlete) => (
            
              <div className={s.cell} key={athlete.id}>
                <div className={s.categoryName}>
                  <div>  {state.athleteName(athlete.id)} </div>
                  <div className={s.region}>
                    <u>
                    {langSwitch==='ukr'?athlete.region:
                   langSwitch==='eng'? lang.regionsEng(athlete.region):null
                    }
                      </u>
                      </div>
                  <div className={s.region}>
                  {lang[langSwitch].coachShort /*трен.*/}:  <b> {state.myCoach(athlete.coach)}</b>; &nbsp;
                  {lang[langSwitch].coachShort /*трен.*/}2:  <b> {state.myCoach(athlete.coach2)}</b>

                  </div>
                </div>

                <div className={s.categoryImg}>
                  <img src={athlete.photo ? athlete.photo : state.noAvatarPhoto}
                    alt={state.myCoach(athlete.id)} />
                </div>

                <div className={s.categoryData}>

                  <div> 
                   {lang[langSwitch].qualificationShort /*кваліф.*/}: 
                    <b> {athlete.dan}</b>  </div>
                  <div> 
                   {lang[langSwitch].rank /* розряд*/}: 
                    <b> {langSwitch==='ukr'?athlete.rank:
                   langSwitch==='eng'? lang.rankEng(athlete.rank):null
                    } 
                      </b></div>
                  <div>
                   {lang[langSwitch].height /*зріст*/}:  
                    <b> {athlete.height} </b>
                    {lang[langSwitch].cm /*см.*/}
                    </div>
                  <div> 
                   {lang[langSwitch].weight /*вага*/}: 
                    <b> {athlete.weiht} </b>
                    {lang[langSwitch].kg /*кг.*/} 
                  </div>

                  <div>
                   {lang[langSwitch].sex /*стать*/}: 
                   <b> {athlete.sex === 'male' ? 
                  lang[langSwitch].sexM /*Чоловік*/
                   : null}
                      {athlete.sex === 'female' ?
                      lang[langSwitch].sexF /*Жінка*/
                       : null}
                      {athlete.sex === null || athlete.sex === undefined ? 'athlete.sex' : null}

                    </b>

                  </div>
                  <div>
                   { lang[langSwitch].fights /*поєдинків*/}:
                     <b> {athlete.battles}</b>
                  </div>
                  <div>
                   {lang[langSwitch].victory /*перемог*/}:  
                    <b> {athlete.victory}</b>

                  </div>
                  <div> 
                     {lang[langSwitch].rating /*рейтинг*/}: 
                    <b> {athlete.rating} </b>
                    {lang[langSwitch].points /*балів*/}  
                     </div>
                </div>


              </div>
          ))}  </div> </div>



      <button type='button' onClick={onClose}>
        {lang[langSwitch].close /*Закрити*/} 
        </button>
    </div>
    </div>
    </>):null}
  </>)
}
export default TeamsCell