import React from 'react';
import s from '../menu/menu.module.css'
import lang from '../../../../state/language';
import NewsPage from '../../forAllUsers/NewsPage';

function NewsForEdit ({langSwitch, isSmallScreen}) {
  
  return (<div>
      <div className={s.h1}>
      {lang[langSwitch].editNewsTitle   /*Редагувати новину*/}
    </div>
   <NewsPage isSmallScreen={isSmallScreen} langSwitch={langSwitch} moderator={true}/>

  </div>);
};

export default NewsForEdit;