import React, { useState } from 'react';
import s from './sportsHalls.module.css'
import sAdmin from '../adminCompetition/adminCompetition.module.css'
import MapOfUkrane from './mapOfUkrane';
import lang from '../../../state/language';

function SportsHalls({ isSmallScreen, langSwitch }) {

  const [sportHallsOpen, setSportHallsOpen] = useState('')



  return (<div >
    <div className={sAdmin.h1}><b>
      {lang[langSwitch].federationMilitaryAndSportsUkraine/*Федерація військово - спортивних багатоборств України*/}
    </b></div>

    <div className={s.sportsHallsContaner}>
      <div>
        <MapOfUkrane langSwitch={langSwitch} setOpen={setSportHallsOpen} />
      </div>
      <div>
      {sportHallsOpen=== lang[langSwitch].Sumy &&(<div className={s.titleOfRegion}>{lang[langSwitch].Sumy}</div>)}
      {sportHallsOpen=== lang[langSwitch].Poltava &&(<div className={s.titleOfRegion}>{lang[langSwitch].Poltava}</div>)}
      {sportHallsOpen=== lang[langSwitch].Kharkiv &&(<div className={s.titleOfRegion}>{lang[langSwitch].Kharkiv}</div>)}
      {sportHallsOpen=== lang[langSwitch].Luhansk &&(<div className={s.titleOfRegion}>{lang[langSwitch].Luhansk}</div>)}
      {sportHallsOpen=== lang[langSwitch].Donetsk &&(<div className={s.titleOfRegion}>{lang[langSwitch].Donetsk}</div>)}
      {sportHallsOpen=== lang[langSwitch].Dnipro &&(<div className={s.titleOfRegion}>{lang[langSwitch].Dnipro}</div>)}
      {sportHallsOpen=== lang[langSwitch].Zaporizhzhia &&(<div className={s.titleOfRegion}>{lang[langSwitch].Zaporizhzhia}</div>)}
      {sportHallsOpen=== lang[langSwitch].Simferopol &&(<div className={s.titleOfRegion}>{lang[langSwitch].Simferopol}</div>)}
      {sportHallsOpen=== lang[langSwitch].Kherson &&(<div className={s.titleOfRegion}>{lang[langSwitch].Kherson}</div>)}
      {sportHallsOpen=== lang[langSwitch].Mykolaiv &&(<div className={s.titleOfRegion}>{lang[langSwitch].Mykolaiv}</div>)}
      {sportHallsOpen=== lang[langSwitch].Odesa &&(<div className={s.titleOfRegion}>{lang[langSwitch].Odesa}</div>)}
      {sportHallsOpen=== lang[langSwitch].Kropyvnytskyi &&(<div className={s.titleOfRegion}>{lang[langSwitch].Kropyvnytskyi}</div>)}
      {sportHallsOpen=== lang[langSwitch].Cherkasy &&(<div className={s.titleOfRegion}>{lang[langSwitch].Cherkasy}</div>)}
      {sportHallsOpen=== lang[langSwitch].Vinnytsia &&(<div className={s.titleOfRegion}>{lang[langSwitch].Vinnytsia}</div>)}
      {sportHallsOpen=== lang[langSwitch].Khmelnytskyi &&(<div className={s.titleOfRegion}>{lang[langSwitch].Khmelnytskyi}</div>)}
      {sportHallsOpen=== lang[langSwitch].Ternopil &&(<div className={s.titleOfRegion}>{lang[langSwitch].Ternopil}</div>)}
      {sportHallsOpen=== lang[langSwitch].Chernivtsi &&(<div className={s.titleOfRegion}>{lang[langSwitch].Chernivtsi}</div>)}
      {sportHallsOpen=== lang[langSwitch].IvanoFrankivsk &&(<div className={s.titleOfRegion}>{lang[langSwitch].IvanoFrankivsk}</div>)}
      {sportHallsOpen=== lang[langSwitch].Uzhhorod &&(<div className={s.titleOfRegion}>{lang[langSwitch].Uzhhorod}</div>)}
      {sportHallsOpen=== lang[langSwitch].Lviv &&(<div className={s.titleOfRegion}>{lang[langSwitch].Lviv}</div>)}
      {sportHallsOpen=== lang[langSwitch].Lutsk &&(<div className={s.titleOfRegion}>{lang[langSwitch].Lutsk}</div>)}
      {sportHallsOpen=== lang[langSwitch].Rivne &&(<div className={s.titleOfRegion}>{lang[langSwitch].Rivne}</div>)}
      {sportHallsOpen=== lang[langSwitch].Zhytomyr &&(<div className={s.titleOfRegion}>{lang[langSwitch].Zhytomyr}</div>)}
      {sportHallsOpen=== lang[langSwitch].Chernihiv &&(<div className={s.titleOfRegion}>{lang[langSwitch].Chernihiv}</div>)}
      {sportHallsOpen=== lang[langSwitch].KyivRegion &&(<div className={s.titleOfRegion}>{lang[langSwitch].KyivRegion}</div>)}
      {sportHallsOpen=== lang[langSwitch].Kyiv &&(<div className={s.titleOfRegion}>{lang[langSwitch].Kyiv}</div>)}
      </div>
    </div>



  </div>);
};

export default SportsHalls;