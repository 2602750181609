import React from 'react';
import s from './navBar.module.css'
import state from '../../state/state';


function LanguagePanel ({langSwitch, setLangSwitch}) {

  if(langSwitch!=state.language){state.language=langSwitch}
  function langSwitcher(data){
    state.language=data
    setLangSwitch(data)
    localStorage.setItem('langSwitch',data)
  }
  return (
    
<div >
  <div className={s.languadeDiv}>
    <img className={state.language==='ukr'?s.languadeImg:s.languadeImg1} 
    src='/img/language/Ukraine.png' alt="Ukrainian" 
    onClick={()=>{
      langSwitcher('ukr')
      window.location.reload()
    }}
    /> 
     </div>  
        <div className={s.languadeDiv}><br/>
    <img className={state.language==='eng'?s.languadeImg:s.languadeImg1} 
    src='/img/language/United-Kingdom(Great-Britain).png' alt="English" 
    onClick={()=>{
      langSwitcher('eng')
      window.location.reload()
    }}
    />   
    </div>    
  </div>
  );
};

export default LanguagePanel;