import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import s from './myCompetition.module.css'


function TextEditor({setResult, text="", isSmallScreen, textInformation='', textButton, textInstruction='',funcForRun}) {

  const editorRef = useRef(null);

  const log = () => { 
    if (editorRef.current) {
      setResult(editorRef.current.getContent()) 
      
    

      editorRef.current.setContent(textInstruction );
    }
  };

let textData
text===''||!text?
  textData=textInformation //вміст поля вводу Текст або Інформація
  :
  textData=text
      
  return (
    <>

      <Editor
        apiKey="ybtrvy8rhz8r3tzwa0s556f5jj9bh0euntb7noifmi4jr5cb" // Додайте свій отриманий API-ключ тут
        onInit={(evt, editor) => {
          editorRef.current = editor;
        }}
        initialValue={textData}
        init={{
          // width:700,
          height: 500,
          menubar: true,
          plugins: ['table',
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
          ],
          
          toolbar: 'undo|redo|formatselect|' +
          'copy|cut|paste|pastetext|blockquote|'+
          'fontfamily|fontsize|fontsizeinput|lineheight|'+
            'bold|italic|underline|strikethrough|'+
            ' forecolor|alignleft|aligncenter| ' +
            'alignright|alignjustify|'+
            'bullist|numlist|outdent|indent|' +
            ' table ',
            toolbar_mode: 'wrap',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; background: none !important; } * { background: none !important; }',
            setup: (editor) => {
              editor.on('Paste PostProcess', (event) => {
                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = event.content;
                tempDiv.querySelectorAll('*').forEach(el => {
                  el.style.background = 'transparent'; // Видаляє фон
                  el.removeAttribute('bgcolor'); // Видаляє атрибут, якщо є
                });
                event.content = tempDiv.innerHTML;
              });
            }
        }}/>
        
        <div style={{textAlign:isSmallScreen?'center':'left'}}>
      <button 
        className={s.textEditorButton} 
        type='button' 
        onClick={()=>{
          log()
          funcForRun&&funcForRun()
          }}>
      {textButton}
        </button>
    </div>
    </>
  );
}

export default TextEditor;
