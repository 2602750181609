//вибір та заявка спортсменів на змагання. 
//містить модальне вікно для видору спортсменів, прийом та обробку даних та 
// axios виклики для відправки даних в БД.

import React, { useEffect, useState } from "react";
import s from '../adminCompetition/adminCompetition.module.css';
import state from "../../../state/state.js";
import axios from "axios";
import lang from "../../../state/language.js";
import competitionFunctions from './competitionFunctions';
import indexedDBConnection from "../../../state/indexedDBConnection.js";

function EntryPoomsae({ isOpen, onClose, tournId, isSmallScreen, langSwitch }) {
  state.messageCount()//лічильник непрочитаних повідомлень 

  
 

  const competition = state.competition.filter((tourn) => tourn.id == tournId)[0];
 
  let poomsaeOptions,poomsaeCategories, category


 if (competition.poomsaeOptions) {
    try { poomsaeOptions = JSON.parse(competition.poomsaeOptions) }
    catch { poomsaeOptions = competition.poomsaeOptions }
  }
  else {
   return
  }
if (competition.poomsaeCategories) {
    try { poomsaeCategories = JSON.parse(competition.poomsaeCategories) }
    catch { poomsaeCategories = competition.poomsaeCategories }
  }
     
  const [selectedAthletes, setSelectedAthletes] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [team, setTeam] = useState('');
  const [errorAthletes, setErrorAthletes] = useState([]);
  const [errorAthletes1, setErrorAthletes1] = useState([]);
  const [errorAthletesMain, setErrorAthletesMain] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [numberAthletesInCategory, setNumberAthletesInCategory] = useState(null);
  const [classSwitcher, setClassSwitcher] = useState('ClassA');
  const [classB, setClassB] = useState(!poomsaeOptions.classB);
 const [indicator, setIndicator] = useState(null);
  const [secondTeam, setSecondTeam] = useState(null);
 const [regionAthetesSwitcher, setRegionAthetesSwitcher] = useState(false);
 const [data, setData] = useState('');
 const [secretaryTeam, setSecretaryTeam] = useState(true);


 let myAthletes1; let myAthletes;
 if((state.userProfile.id==competition.mainJudge||state.userProfile.id==competition.secretary)&&!secretaryTeam){
   myAthletes1 = state.athletes
 }else{
 myAthletes1 = state.athletes.filter((athlete) => {// вибір моїх спортсменів
   if(regionAthetesSwitcher){
   return athlete.region === state.userProfile.region;
   }else{
   return athlete.coach == state.userProfile.id || athlete.coach2 == state.userProfile.id;
   }
   
 });
 }
  if(poomsaeCategories){
 myAthletes1 = myAthletes1.filter((item) => {
  let count = 0;

  poomsaeCategories.forEach((item2) => {// перевіряємо в скількох категоріях заявлений спортсмен
    item2.athletes.forEach((athletesItem) => {
      if (Array.isArray(athletesItem)) {
        athletesItem.forEach((teamItem) => {
          if (item.id == teamItem.id) {
            count++;
          }
        });
      } else {
        if (item.id == athletesItem.id) {
          count++;
        }
      }
    });
  });


  return count < poomsaeOptions.poomsaeLimit; 
});
}

 const handleTitleChange = (e) => { setData(e.target.value) };  
  //   форма пошуку
  myAthletes = myAthletes1.filter(athlete => {
    const fullName = `${athlete.secondName} ${athlete.personName}`.toLowerCase();
    const searchData = data.toLowerCase();

    return fullName.includes(searchData);
  });

 function handleRegionAthetesChange(){setRegionAthetesSwitcher(!regionAthetesSwitcher)}
 
 const handleSecondTeamChange = (e) => { setSecondTeam(e.target.value.trim()) };

  function handleClassChange(){
    const newClassB = !classB;
    setClassB(!classB);
    setSelectedAthletes([]) 
   if(newClassB) {setClassSwitcher('ClassB')}else{setClassSwitcher('ClassA')}   
    }

  //надсилаємо тренеру перелік проблем у профілі спортсмену
  useEffect(() => {
    if (errorAthletesMain.length > 0) {
      state.errorMassageSend(errorAthletesMain, state.userProfile.id, langSwitch);
    }
  }, [errorAthletesMain]);

  const categoryesPoomsae = []
  
  //Дані для меню категорій на підставі параметрів з БД 
  competitionFunctions.makeCategoryesPoomsae(langSwitch,poomsaeOptions,categoryesPoomsae) 

function enteredAthletesFilter() {//фільтр заявлених спортсменів
  if(!indicator||!poomsaeCategories)return
  let currentCategories  //заявлені категорії
  currentCategories = poomsaeCategories.filter(item => item.category.includes(indicator));

    const currentArray=[]//вибираєм заявлених атлетів
    
if( Array.isArray(currentCategories)&&currentCategories.length>0){
  if(Array.isArray(currentCategories[0].athletes[0])){
  //пари та команди
  currentCategories.forEach((item)=>{
    item.athletes.forEach((athleteArray)=>{
      athleteArray.forEach((atleteItem)=>{
        currentArray.push(atleteItem) })
    })
  })
  }else{//індивідуальні виступи
    currentCategories.forEach((item)=>{
      item.athletes.forEach((atleteItem)=>{currentArray.push(atleteItem) })
    })   
  }
  }
    //фільтруємо "моїх атлетів" на підставі масиву заявлених спортсменів
    myAthletes = myAthletes.filter(myAthlete => 
      !currentArray.some(currentAthlete => currentAthlete.id == myAthlete.id)
    );

}
  enteredAthletesFilter()
  const handleTeamChange = (e) => { const value = e.target.value; setTeam(value) };


  //замінюємо id на профіль користувача
let athletesProfile=state.athletes.filter((sportsman)=>{return selectedAthletes.includes(sportsman.id)})

  function categoryChecker(entryPoomsaeCategories, arrayPoomsaeCategories) {
 
    // Додаємо спортсменів в існуючі категорії
    entryPoomsaeCategories.forEach((entryCategory) => {
      category.forEach((categoryItem) => {
        if (entryCategory.category === categoryItem.category) {
          if (Array.isArray(categoryItem.athletes[0])) {
            // Обробка командних категорій
            categoryItem.athletes.forEach((categoryTeam) => {
              const isTeamPresent = entryCategory.athletes.some(entryTeam => {
                return entryTeam.some(entryAthlete => categoryTeam.some(categoryAthlete => entryAthlete.id === categoryAthlete.id));
              });
  
              if (!isTeamPresent) {
                entryCategory.athletes.push(categoryTeam);
              } else {
                categoryTeam.forEach(categoryAthlete => {
                  // console.log('Conflicting athlete ID:', categoryAthlete.id);
                });
              }
            });
          } else {
            // Обробка індивідуальних категорій
            categoryItem.athletes.forEach((categoryAthlete) => {
              if (!entryCategory.athletes.some(entryAthlete => entryAthlete.id == categoryAthlete.id)) {
                entryCategory.athletes.push(categoryAthlete);
              } else {
                // console.log('Conflicting athlete ID:', categoryAthlete.id);
              }
            });
          }
        }
      });
    });
  
    // Додаємо раніше не заявлені категорії
    category.forEach((categoryItem) => {
      if (!entryPoomsaeCategories.some(entryCategory => entryCategory.category === categoryItem.category)) {
        entryPoomsaeCategories.push(categoryItem);
      }
    });
  
    // Додаємо категорії до arrayPoomsaeCategories
    entryPoomsaeCategories.forEach((cat) => {
      arrayPoomsaeCategories.push(cat);
    });  
  
  }

  function sexFilter() {// сортуємо спортсменів за статтю

    if (selectedCategory === lang[langSwitch].traditionalIndividualMale/*Традиційне. Індивідуальні виступи. Чоловіки*/ ||
      selectedCategory === lang[langSwitch].traditionalTeamMale/*Традиційне. Командні виступи.Чоловіки*/ ||
      selectedCategory === lang[langSwitch].freestyleIndividualMale/*Фрістайл. Індивідуальні виступи.Чоловіки*/ ||
      selectedCategory === lang[langSwitch].mixedIndividualMale/*Змішане. Індивідуальні виступи.Чоловіки*/ ||
      selectedCategory === lang[langSwitch].mixedTeamMale/*Змішане. Командні виступи.Чоловіки*/) {
      myAthletes = myAthletes.filter((item) => item.sex === 'male')
    }
    if (selectedCategory === lang[langSwitch].traditionalIndividualFemale/*Традиційне. Індивідуальні виступи. Жінки*/ ||
      selectedCategory === lang[langSwitch].traditionalTeamFemale/*Традиційне. Командні виступи.Жінки*/ ||
      selectedCategory === lang[langSwitch].freestyleIndividualFemale/*Фрістайл. Індивідуальні виступи.Жінки*/ ||
      selectedCategory === lang[langSwitch].mixedIndividualFemale/*Змішане. Індивідуальні виступи.Жінки*/ ||
      selectedCategory === lang[langSwitch].mixedTeamFemale/*Змішане. Командні виступи.Жінки*/) {
      myAthletes = myAthletes.filter((item) => item.sex === 'female')
    }
    if (
      selectedCategory === lang[langSwitch].mixedIndividualFemale/*Змішане. Індивідуальні виступи.Жінки*/ ||
      selectedCategory === lang[langSwitch].mixedIndividualMale/*Змішане. Індивідуальні виступи.Чоловіки*/ ||
      selectedCategory === lang[langSwitch].mixedTeamMale/*Змішане. Командні виступи.Чоловіки*/ ||
      selectedCategory === lang[langSwitch].mixedTeamFemale/*Змішане. Командні виступи.Жінки*/||
      selectedCategory === lang[langSwitch].mixedCouple/*Змішане. Парні виступи.*/||
      selectedCategory === lang[langSwitch].mixedTeamMix/*Змішане. Змішані команди.*/) {
      myAthletes = myAthletes.filter((item) => state.age(item.birthday) >17)
    }

     } 
sexFilter()

    function ageCategoryFilter() {
      // Перевірка, чи вибрані категорії та спортсмени
      if (selectedCategory.length === 0 || selectedAthletes.length === 0) {return}
    
      let ageDetektor = [];
      let currentAgeCategory = [];
    
      // Встановлення вікових діапазонів
      if ((selectedCategory === lang[langSwitch].traditionalCouple ||
          selectedCategory === lang[langSwitch].traditionalTeamMale ||
          selectedCategory === lang[langSwitch].traditionalTeamFemale) &&
        selectedAthletes.length > 0
      ) {
        ageDetektor = [
          { a: 8, b: 9 },
          { a: 10, b: 11 },
          { a: 12, b: 14 },
          { a: 15, b: 17 },
          { a: 18, b: 30 },
          { a: 31, b: 40 },
          { a: 41, b: 50 },
          { a: 51, b: 60 },
          { a: 61, b: 65 },
          { a: 65, b: 200 },
        ];
      } else if (
        selectedCategory === lang[langSwitch].freestyleCouple &&
        selectedAthletes.length > 0
      ) { ageDetektor = [{ a: 0, b: 17 }, { a: 18, b: 200 }];
      } else {
        return;
      }
    
      // Отримання профілю вибраного спортсмена
      const selectedAthleteProfile=myAthletes.find((profile) => profile.id == selectedAthletes[0]);
   
      // Знаходження поточної вікової категорії
      currentAgeCategory = ageDetektor.filter((item) => {
        const age = state.age(selectedAthleteProfile.birthday);
        return item.a <= age && item.b >= age;
      });
        
      if (currentAgeCategory.length === 0) {return; }
    
      // Фільтрація спортсменів за віковими категоріями
      myAthletes = myAthletes.filter((item) => {
        const age = state.age(item.birthday);
        return age >= currentAgeCategory[0].a && age <= currentAgeCategory[0].b;
      });

    }
    
  ageCategoryFilter();

  function test() {
    if(selectedAthletes.length===0){
      setErrorMessage(lang[langSwitch].notSelectedAnyAthlete/*Ви не обрали жодного спортсмена!*/)
      return
    }
    // ПЕРЕВІРКА: чи усі поля заповнені в профілі
    function filterForAthletes2(atletesData, date) {
     
      const errorMessages = [];
     const filteredAthletes = atletesData.filter((sportsman2) => {
       const athleteErrors = []; 

       if (sportsman2.activFtu!=1) { athleteErrors.push(lang[langSwitch].errorAthletes.activFtu); }
       if (sportsman2.activSite!=1) { athleteErrors.push(lang[langSwitch].errorAthletes.activSite); }
       if (sportsman2.fatherName === null || sportsman2.fatherName === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.fatherName);}
       if (sportsman2.birthday === null || sportsman2.birthday === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.birthday);}
       if (sportsman2.sex === null || sportsman2.sex === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.sex); }
       if (sportsman2.eMail === null || sportsman2.eMail === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.eMail); }
       if (sportsman2.photo === null || sportsman2.photo === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.photo);}
       if (sportsman2.coach === null || sportsman2.coach === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.coach); }
       if (sportsman2.sportClub === null || sportsman2.sportClub === undefined||sportsman2.sportClub===' ') { athleteErrors.push(lang[langSwitch].errorAthletes.sportClub); }
       if (sportsman2.region === null || sportsman2.region === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.region); }
       if (sportsman2.dan === null || sportsman2.dan === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.dan); }
       if (sportsman2.rank === null || sportsman2.rank === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.rank); }
       if (sportsman2.fst === null || sportsman2.fst === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.fst); }
       if (sportsman2.insuranceComp === null || sportsman2.insuranceComp === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.insuranceComp); }
       if (sportsman2.insuranceNum === null || sportsman2.insuranceNum === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.insuranceNum); }
       if (sportsman2.insuranceDate === null || sportsman2.insuranceDate === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.insuranceDate); }
       if (new Date(sportsman2.insuranceDate) < new Date(date)) { athleteErrors.push(lang[langSwitch].errorAthletes.insuranceActive);}
       if (sportsman2.medicalCertificateNum === null || sportsman2.medicalCertificateNum === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.medicalCertificateNum); }
       if (sportsman2.medicalCertificateDate === null || sportsman2.medicalCertificateDate === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.medicalCertificateDate);}
       if (new Date(sportsman2.medicalCertificateDate) < new Date(date)) { athleteErrors.push(lang[langSwitch].errorAthletes.medicalCertificateActive); }
       if (sportsman2.status === null || sportsman2.status === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.status); }


       if (athleteErrors.length > 0) {         
         errorMessages.push({
           name: `${sportsman2.secondName} ${sportsman2.personName}`,
           errors: athleteErrors            
         });  
       }
       return athleteErrors.length === 0;
     });
     if (errorMessages.length > 0) {
        const errorReports = errorMessages.map((athleteError) => {
         return `<div><br/>
          ${lang[langSwitch].anAthlete /*Спортсмену*/}: 
         <u><b> ${athleteError.name}</b></u>, 
         ${lang[langSwitch].refusedRegistration /*відмовлено в реєстрації на турнир по наступних причинах:*/}
           </div>${athleteError.errors.join('')}`;
       });
        
       setErrorAthletesMain(errorReports);      
         
             }
         
     return filteredAthletes;
   }
   athletesProfile=filterForAthletes2(athletesProfile, competition.dateStart)
  
    athletesProfile.forEach((item) => {//додаємо команду в профіль спортсмена
if(secondTeam){
  item.secondTeam=secondTeam
  item.team=secondTeam
}else{
  if( team === "club"){item.team=item.sportClub}
  if(team === "region" ){ item.team = item.region}
}
    }
    )
 
  
    if ((selectedCategory === lang[langSwitch].traditionalIndividualMale/*Традиційне. Індивідуальні виступи.чоловіки*/ ||
      selectedCategory === lang[langSwitch].traditionalIndividualFemale/*Традиційне. Індивідуальні виступи.жінки*/) &&
      Array.isArray(athletesProfile) && athletesProfile.length > 0) {
      category = state.tournConstructor.poomseCategories.traditional.personal(athletesProfile,classSwitcher)
   
    }
    if (selectedCategory === lang[langSwitch].traditionalCouple/*Традиційне. Парні виступи.*/ &&
      Array.isArray(athletesProfile) && athletesProfile.length > 0) {
      category = state.tournConstructor.poomseCategories.traditional.couple(athletesProfile,classSwitcher)
    }
    if ((selectedCategory === lang[langSwitch].traditionalTeamMale/*Традиційне. Командні виступи. чоловіки*/ ||
      selectedCategory === lang[langSwitch].traditionalTeamFemale/*Традиційне. Командні виступи.жінки*/) &&
      Array.isArray(athletesProfile) && athletesProfile.length > 0) {
      category = state.tournConstructor.poomseCategories.traditional.team(athletesProfile,classSwitcher)
    }
    if ((selectedCategory === lang[langSwitch].freestyleIndividualMale/*Фрістайл. Індивідуальні виступи.чоловіки*/ ||
      selectedCategory === lang[langSwitch].freestyleIndividualFemale/*Фрістайл. Індивідуальні виступи.жінки*/) &&
      Array.isArray(athletesProfile) && athletesProfile.length > 0) {
      category = state.tournConstructor.poomseCategories.freestyle.personal(athletesProfile,classSwitcher)
    }
    if (selectedCategory === lang[langSwitch].freestyleCouple/*Фрістайл. Парні виступи.*/ &&
      Array.isArray(athletesProfile) && athletesProfile.length > 0) {
      category = state.tournConstructor.poomseCategories.freestyle.couple(athletesProfile,classSwitcher)
    }
    if (selectedCategory === lang[langSwitch].freestyleTeam/*Фрістайл. Командні виступи.*/ &&
      Array.isArray(athletesProfile) && athletesProfile.length > 0) {
      category = state.tournConstructor.poomseCategories.freestyle.team(athletesProfile,classSwitcher)
    }
    if ((selectedCategory === lang[langSwitch].mixedIndividualMale/*Змішане. Індивідуальні виступи.чоловіки*/ ||
      selectedCategory === lang[langSwitch].mixedIndividualFemale/*Змішане. Індивідуальні виступи.жінки*/) &&
      Array.isArray(athletesProfile) && athletesProfile.length > 0) {
      category = state.tournConstructor.poomseCategories.mixPoomsae.personal(athletesProfile,classSwitcher)
    }
    if (selectedCategory === lang[langSwitch].mixedCouple/*Змішане. Парні виступи.*/ &&
      Array.isArray(athletesProfile) && athletesProfile.length > 0) {
      category = state.tournConstructor.poomseCategories.mixPoomsae.couple(athletesProfile,classSwitcher)
    }

    if ((selectedCategory === lang[langSwitch].mixedTeamMale/*Змішане. Командні виступи.чоловіки*/ ||
      selectedCategory === lang[langSwitch].mixedTeamFemale/*Змішане. Командні виступи.жінки*/) &&
      Array.isArray(athletesProfile) && athletesProfile.length > 0) {
      category = state.tournConstructor.poomseCategories.mixPoomsae.team(athletesProfile,classSwitcher)
    }
    if (selectedCategory === lang[langSwitch].mixedTeamMix/*Змішане. Змішані команди.*/ &&
      Array.isArray(athletesProfile) && athletesProfile.length > 0) {
      category = state.tournConstructor.poomseCategories.mixPoomsae.mixedTeam(athletesProfile,classSwitcher)
    }
  
if(!category||category.length===0){return}
   
   function updateCompetition(){
  
    if(competition.active==1||state.userProfile.id==competition.mainJudge||state.userProfile.id==competition.secretary)//Перевіряємо чи відкрита реєстрація на турнир
      {   
        axios //Оторимуємо свіжі дані з БД по заявлених атлетах
        .post(state.api.getAthletesForPoomsaeComp, { id: tournId })
        .then((response) => {
          
            const arrayAthletes=[]
            const arrayPoomsaeCategories=[]
 
            let entryAthletes
             try{entryAthletes=JSON.parse(response.data.athletes)} catch{entryAthletes=response.data.athletes} 
            
            let entryPoomsaeCategories
           try{entryPoomsaeCategories=JSON.parse(response.data.poomsaeCategories)} catch{entryPoomsaeCategories=response.data.poomsaeCategories}

            //  створюємо масив заявлених на турнир спортсменів
if (!entryAthletes||(Array.isArray(entryAthletes)&&entryAthletes.length===0)||entryAthletes===' '){
  athletesProfile.forEach((item)=>{arrayAthletes.push(item)})
}else{
    // Створення масиву унікальних атлетів
     entryAthletes.forEach((item)=>{arrayAthletes.push(item)})
const uniqueAthletesFromProfile = athletesProfile.filter(profileAthlete => {
  return !entryAthletes.some(entryAthlete => entryAthlete.id == profileAthlete.id);
});
// масив заявлених на турнир спортсменів
  uniqueAthletesFromProfile.forEach((item)=>{arrayAthletes.push(item)})
}

//  створюємо масив заявлених на турнир категорій
if (!entryPoomsaeCategories||(Array.isArray(entryPoomsaeCategories)&&entryPoomsaeCategories.length===0)||entryPoomsaeCategories===' '){
    category.forEach((item)=>{arrayPoomsaeCategories.push(item)})
 }else{
 categoryChecker(entryPoomsaeCategories,arrayPoomsaeCategories)
}

//перевірки вільних місць на турнирі
if (arrayAthletes.length >= competition.numberOfAthletes &&
  state.userProfile.id !== competition.mainJudge &&
  state.userProfile.id !== competition.secretary) {
 
    setErrorMessage(
      lang[langSwitch].haveNoPlases /* Нажаль кількість обраних вами спорстменів перевищує кількість доступних для реєстрації місць.*/
      )
   return
   
  }
else {
  axios// Додаємо нових спортсменів до змагань
    .post(state.api.addNewAthletesOnPoomsae, {
      id: tournId,
      newAthletes: JSON.stringify(arrayAthletes),
      poomsaeCategories: JSON.stringify(arrayPoomsaeCategories)
    })
    .then((response) => {

      const index = state.competition.findIndex(item => item.id == tournId);
      state.competition[index].athletes = JSON.stringify(arrayAthletes);
      state.competition[index].poomsaeCategories = JSON.stringify(arrayPoomsaeCategories);
                indexedDBConnection.saveData('competition',  state.competition)
                  .then(() => {})
                  .catch((error) => {
                    console.error('Помилка при збереженні даних в IndexedDB:', error);
                  });

      setSuccessMessage(
        lang[langSwitch].entrySuccessfulleSubmitted /*Заявку успішно подано.*/
      );
      setSelectedAthletes([]);













      const updateData={
        id:state.userProfile.id,
        lastChangesDate:state.lastChangesDateFormat()//Дата останьої зміни профіля
      }
    axios.post(state.api.postLastChangesDate, { updateData: updateData })
    .then(response => {   })
    .catch(error => {
      // console.error('Помилка Axios-запиту, невдалось зберегти результати турниру в профілі атлетів:', error);
    });

















    

    })
    .catch((error) => {
      if (state.userProfile.id == competition.mainJudge ||state.userProfile.id == competition.secretary) {
        const index = state.competition.findIndex(item => item.id == tournId);
        state.competition[index].athletes = JSON.stringify(arrayAthletes);
        state.competition[index].poomsaeCategories = JSON.stringify(arrayPoomsaeCategories);
                  indexedDBConnection.saveData('competition',  state.competition)
                    .then(() => {
                       setSuccessMessage(
        lang[langSwitch].athleteAddedOffline /*Спортсмена додано в режимі offline.*/
      );
                    })
                    .catch((error) => {
                      console.error('Помилка при збереженні даних в IndexedDB:', error);
                    });
  
        
    }
    

      setErrorMessage(lang[langSwitch].serverRequestError /*Помилка запиту до сервера: */ + error);
    });
}
        
        //закінчення перевірки вільних місць на турнирі

      })
        .catch((error) => {
        setErrorMessage(lang[langSwitch].serverRequestError /*Помилка запиту до сервера: */+ error);
      });
    
  }
  else{//закриття перевірки реєстрації на турнир
    setErrorMessage(
    lang[langSwitch].registrationForTournamentCompleted /*Нажаль реєстрацію на цей турнир завершено.*/
  )}

} //закінчення функції викликів до БД
  
updateCompetition()

}//закінчення функції test

  const createMarkup = (html) => {
    return { __html: html };
  };

  return (<>
    {isOpen?(<>
      <div className={s.modalWriper}>
    <div appElement={document.getElementById('root')} className={s.modal}  contentLabel="Regulation Modal">
      <div className={errorAthletes1.length === 0 ? '' : `${s.errorMessage1}`} dangerouslySetInnerHTML={createMarkup(errorAthletes1)}
        onClick={() => { setErrorAthletes1([]) }} />
      <div className={errorAthletesMain.length === 0 ? '' : `${s.errorMessage1}`} dangerouslySetInnerHTML={createMarkup(errorAthletesMain)}
        onClick={() => { setErrorAthletesMain([]) }} />

      <div className={errorAthletes.length === 0 ? '' : `${s.errorMessage1}`} dangerouslySetInnerHTML={createMarkup(errorAthletes)}
        onClick={() => { setErrorAthletes([]) }} />
         {successMessage ?
        (<><br /><br /><div className={s.successMessage} onClick={() => { setSuccessMessage('') }}>
          {successMessage}</div><br /></>) :
        errorMessage ? (<><br /><br /><div className={s.errorMessage} onClick={() => { setErrorMessage('') }}>
          {errorMessage}</div><br /></>) : ''}
          
      <div className={s.teamContaner}>
        <div className={s.teamChange}>
          <h2 className={s.poomsaeEntryTitle}>
            {lang[langSwitch].myTeam /*Моя команда*/}
          </h2>
          
            <div >
        <label htmlFor="title"><span><b>
        {lang[langSwitch].startTypingName /*  Почніть вводити призвище та ім'я*/}
          </b></span> </label><br /><br />
        <input onChange={handleTitleChange} type="text" id="title" name="title" />
      </div>
    </div>
{/* лічильник заявлених спортсменів */}
        <div className={s.poomsaeCountMenu}>
        <span className={s.teamChangeMenuSpan}>
          {selectedCategory.length>0?(<>
                {lang[langSwitch].chosen/*Обрано*/}: &nbsp;
                  <b>
                      {selectedAthletes.length > 0 ? selectedAthletes.length : '0'}
                            {numberAthletesInCategory?(<><b>
                                &nbsp;/&nbsp;
                                {numberAthletesInCategory}</b>
                             </>):null}
                  </b>
         
          </>):null}
        </span>
        </div>
       
 <div className={state.userProfile.id==competition.mainJudge||state.userProfile.id==competition.secretary?s.poomsaeSwitcherMenuForSecretary:s.poomsaeSwitcherMenu}>
   {state.userProfile.id==competition.mainJudge||state.userProfile.id==competition.secretary?(<>
 <label>
                    <span ><b>
                        {secretaryTeam?
                            lang[langSwitch].myAthletes/*Моя спортсмени*/
                            :
                            lang[langSwitch].allAthletesFromDB/*Усі спортсмени*/}
                    </b> </span>
                    <div className={s.switchBox} style={{paddingBottom:'10px'}}>
                        <input
                            type='checkbox'
                            checked={secretaryTeam}
                            onChange={()=>{setSecretaryTeam(!secretaryTeam)}}

                        />
                    </div>
                </label>
</>):null}


   {/*Класс змагань з Пхумсе*/}
   {poomsaeOptions.classB?(<>
   <label>
                    <span ><b>
                        {classSwitcher=== 'ClassB'?
                            lang[langSwitch].classB/*Клас "Б"*/
                            :
                            lang[langSwitch].classA/*Клас "A"*/}
                    </b> </span>
                    <div className={s.switchBox}>
                        <input
                            type='checkbox'
                            checked={classB}
                            onChange={handleClassChange}

                        />
                    </div>
                </label>
                </>):null}
 </div>



           {/* Обираем категорію пхумсе */}
       
   <div className={state.userProfile.regionLeader?s.poomsaeCategoryMenu1:s.poomsaeCategoryMenu}>
        {state.userProfile.regionLeader?(<>
        <label>                   
                        {regionAthetesSwitcher ?
                          lang[langSwitch].athletesOfRegionalCenter/*Спортсмени обласного осередку*/
                            :
                            lang[langSwitch].athletesOfSportsClub/*Спортсмени спортивного клубу*/}
                  
                    <div className={s.switchBox}>
                        <input
                            type='checkbox'
                            checked={regionAthetesSwitcher}
                            onChange={handleRegionAthetesChange}
                        />
                        
                    </div>
                </label>
          </>):null}

          <label htmlFor="status"><span className={s.teamChangeMenuSpan}>
            {lang[langSwitch].selectСategory/*Оберіть категорію*/}:
          </span> </label><br />
          <select onChange={(e)=>
            competitionFunctions.handleCategoryChange(e,
              langSwitch,
              setNumberAthletesInCategory,
              setSuccessMessage,
              setIndicator,
              setSelectedCategory,
              setSelectedAthletes,setErrorMessage
              )
          } id="status" name="status">
            <option value=""> </option>
            {categoryesPoomsae.map((item) => {
              return (
                <option value={item} key={item}>
                  {item}
                </option>
              )
            })}

          </select>
        </div>



        {/* Обираем команду від якої заявляєм спортсменів */}
        <div className={s.poomsaeTeamChangeMenu2}>
          <label htmlFor="status"><span className={s.teamChangeMenuSpan}>
            {lang[langSwitch].selectTeam /*Оберіть команду*/}:
          </span> </label><br />
          <select onChange={handleTeamChange} id="status" name="status">
            <option value=""> </option>
            <option value="region">
              {lang[langSwitch].regionalOffice /* Обласний осередок*/}
            </option>
            <option value="club">
              {lang[langSwitch].sportsClub /* Спортклуб*/}
            </option>
          </select>
          <div><br/>
          <label htmlFor="status"><span className={s.teamChangeMenuSpan}>
         { lang[langSwitch].secondSportsClubIfNeeded/* Другий Спортклуб (за потреби)*/}
          </span> </label><br />
          <input onChange={handleSecondTeamChange} type="text" id="secondName" name="secondName" />
        </div>
        </div>


      </div>
      {/* "плитка спортсменів" */}

      <div className={s.gridContainer}>
        {myAthletes.map((athlete) => (
          <div key={athlete.id} className={selectedAthletes.includes(athlete.id) ? `${s.athleteBlock} ${s.athleteBlock1}` : s.athleteBlock}>
            <img

              src={athlete.photo ? athlete.photo : state.noAvatarPhoto}
              alt={`${athlete.secondName} ${athlete.personName}`}
              style={{ cursor: 'pointer' }}
              onClick={() => competitionFunctions.toggleAthleteSelection(
                    athlete,
                    setSuccessMessage,
                    selectedAthletes,
                    setSelectedAthletes,
                    selectedCategory,
                    langSwitch,setErrorMessage
              )}
            />
            <div className={s.name}>{`${athlete.secondName} ${athlete.personName}`}</div>
          </div>
        ))}

      </div>

      <div className={errorAthletes1.length === 0 ? '' : `${s.errorMessage1}`} dangerouslySetInnerHTML={createMarkup(errorAthletes1)}
        onClick={() => { setErrorAthletes1([]) }} />
      <div className={errorAthletesMain.length === 0 ? '' : `${s.errorMessage1}`} dangerouslySetInnerHTML={createMarkup(errorAthletesMain)}
        onClick={() => { setErrorAthletesMain([]) }} />

      <div className={errorAthletes.length === 0 ? '' : `${s.errorMessage1}`} dangerouslySetInnerHTML={createMarkup(errorAthletes)}
        onClick={() => { setErrorAthletes([]) }} />
         {successMessage ?
        (<><br /><br /><div className={s.successMessage} onClick={() => { setSuccessMessage('') }}>
          {successMessage}</div><br /></>) :
        errorMessage ? (<><br /><br /><div className={s.errorMessage} onClick={() => { setErrorMessage('') }}>
          {errorMessage}</div><br /></>) : ''}


      <div className={isSmallScreen ? s.td1 : null}>
        <button type='button' 
          onClick={() => { 
            myAthletes=[]           
            setErrorMessage(''); 
            setSelectedAthletes([]); 
            setSuccessMessage(''); 
            setErrorAthletesMain([]); 
            setErrorAthletes([]); 
            setErrorAthletes1([]); 
            setNumberAthletesInCategory(null)
            onClose(); 
            }}>
          {lang[langSwitch].close /*Закрити*/}
        </button>
        <button className={!isSmallScreen ? s.buttonR : null}
          type='button'
          onClick={() => {
            if(numberAthletesInCategory===2&&selectedAthletes.length<2){
              setErrorAthletes(`<div>${lang[langSwitch].minimumDeclare/*В цю категорію потрібно заявити мінімум*/} 2 ${lang[langSwitch].ppl/*чол.*/}!</div>`)
              return}
              if(numberAthletesInCategory===3&&selectedAthletes.length<3){
                setErrorAthletes(`<div>${lang[langSwitch].minimumDeclare/*В цю категорію потрібно заявити мінімум*/} 3 ${lang[langSwitch].ppl/*чол.*/}!</div>`)
                return}
                if(numberAthletesInCategory==='5+1'&&selectedAthletes.length<5){
                  setErrorAthletes(`<div>${lang[langSwitch].minimumDeclare/*В цю категорію потрібно заявити мінімум*/} 5 ${lang[langSwitch].ppl/*чол.*/}!</div>`)
                  return}
            if (team !== '') {
              setErrorAthletes([])
              setSuccessMessage('')
              setErrorAthletesMain([])
              test()
            } else { setErrorAthletes(`<div>${lang[langSwitch].selectTeamForCompetetition /*Оберіть команду, за яку виступатимуть спортсмени!*/}</div>`) }
          }}>
          {lang[langSwitch].declare /*Заявити*/}
        </button>

       
      </div>
    </div>
    </div>
    </>):null}
  </>);
}

export default EntryPoomsae;
