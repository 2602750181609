import { useState } from 'react'
import s from '../adminCompetition/adminCompetition.module.css'
import lang from '../../../state/language'

function MakeFoodOptionsCell({date,key,setTimes,options,updateFoodResult,setResult,langSwitch}){

const[breakfast,setBreakfast]=useState(options?options.breakfast:false)
const[dinner,setDinner]=useState(options?options.dinner:false)
const[supper,setSupper]=useState(options?options.supper:false)
const[start,setStart]=useState(true)


const cellResult={
    date:date,
    breakfast:breakfast,
    dinner:dinner,
    supper:supper}

    const handleBreakfast = () => {cellResult.breakfast=!breakfast;setBreakfast(!breakfast);updateFoodResult(cellResult) } 
    const handleDinner = () => {  cellResult.dinner=!dinner;setDinner(!dinner) ;updateFoodResult(cellResult)} 
    const handleSupper = () => {  cellResult.supper=!supper;setSupper(!supper) ;updateFoodResult(cellResult)} 


if(start){
    if(setTimes){
    setTimes(prev => {
        const updatedTimes = prev.filter(item => item.date !== date);
        updatedTimes.push(cellResult);
        return { ...prev, times: updatedTimes };
    });
    setStart(false);  
}

if(setResult){
    setResult(prev => {
        const updatedTimes = prev.times.filter(item => item.date !== date);
        updatedTimes.push(cellResult);
        return { ...prev, times: updatedTimes };
    });
    setStart(false);    
}}

    return(<>
    <div key ={key} style={{textAlign:'center',marginTop:'20px'}} className={s.ageCategorySwitcher1}>{date}</div>
    <div className={s.containerAdmin}>
        <div className={s.itemAdmin1}>

                    <form>
                        <label>
                            <div className={s.switchBox}>
                                <input
                                    type='checkbox'
                                    checked={breakfast}
                                    onChange={handleBreakfast}
                                /><span className={s.ageCategorySwitcher1}>
                                    {lang[langSwitch].breakfast /*Сніданок*/}
                                    </span>
                            </div>
                        </label>
                    </form>
        </div>
        <div className={s.itemAdmin2}>

        <form>
                        <label>
                            <div className={s.switchBox}>
                                <input
                                    type='checkbox'
                                   checked={dinner}
                                    onChange={handleDinner}
                                /><span className={s.ageCategorySwitcher1}>
                                  {lang[langSwitch].lunch /*Обід*/}  
                                    </span>
                            </div>
                        </label>
                    </form>
        </div>
        <div className={s.itemAdmin3}>

        <form>
                        <label>
                            <div className={s.switchBox}>
                                <input
                                    type='checkbox'
                                    checked={supper}
                                    onChange={handleSupper}
                                /><span className={s.ageCategorySwitcher1}>
                                   {lang[langSwitch].dinner /*Вечеря*/}   
                                    </span>
                            </div>
                        </label>
                    </form><br/>

        </div>
                    
                    
    </div>
    </>)
}

export default MakeFoodOptionsCell