import React, { useEffect, useState } from 'react';
import s from './adminCompetition.module.css'
import state from '../../../state/state';

function ShootingCell({ athlete, numder,  setResultForSaving }) {

    const [numbers, setNumbers] = useState(athlete.shootingResult?athlete.shootingResult.points:[]);
    const [shootingResult, setShootingResult] = useState(athlete.shootingResult?athlete.shootingResult.shootingResult:0);



    const resultData = (event) => {
        let value = event.target.value;

        // Видаляємо всі неприпустимі символи (залишаємо тільки цифри, коми, пробіли)
        value = value.replace(/[^0-9, ]/g, "");

        // Видаляємо зайві пробіли перед комами та після ком
        value = value.replace(/\s*,\s*/g, ", "); // Пробіли навколо ком
        value = value.replace(/^\s*|\s*$/g, ""); // Видаляємо пробіли на початку і в кінці

        // Записуємо нове значення в `input`
        event.target.value = value;

        // Перетворюємо на масив чисел
        const numberArray = value
            .split(",") // Розбиваємо рядок по комі
            .map(num => num.trim()) // Видаляємо зайві пробіли
            .filter(num => num !== "") // Видаляємо порожні значення
            .map(num => Number(num)); // Перетворюємо у числа

        setNumbers(numberArray);
    };

  
    useEffect(() => {
        let result = 0
        numbers.map((item) => {
            result = result + item
        })
        setShootingResult(result)
    }, [numbers])

    useEffect(() => {
      
            setResultForSaving((prev) => {
                let result
            const index = prev.findIndex((item)=>+item.id === +athlete.id)
                if (index === -1){
                    result=[
                    ...prev, // Розпаковуємо попередні значення
                    {
                        id: athlete.id,
                        points: numbers,
                        shootingResult: shootingResult
                    }
                    ]
                }else{
                    prev[index].points = numbers
                    prev[index].shootingResult = shootingResult

                    result = prev
                }
             
                

                return result
            });
        
    }, [shootingResult]);
    

    return (<>

        <td>{numder}</td>
        <td style={{ textAlign: 'left', paddingLeft: '10px' }}>
            {state.athleteName(athlete.id)}
        </td>
        <td>
            <form className={s.shootingForm}>
                <input
                    type="text"
                    id="secondName"
                    name="secondName"
                    onChange={resultData}
                    placeholder={numbers}
                />

            </form>
        </td>
        <td><b>{shootingResult ? shootingResult : null}</b></td>
        <td>{athlete.rank} /<br />{athlete.dan}</td>
        <td>{athlete.team}</td>
        <td>{state.regionShort(athlete.region)}</td>
        <td>{athlete.fst}</td>
        <td>{state.myCoach(athlete.coach)}{state.myCoach(athlete.coach2) ? (<> /<br />{state.myCoach(athlete.coach2)}</>) : null}</td>



    </>);
};

export default ShootingCell;