
import s from '../adminCompetition/adminCompetition.module.css';
import state from "../../../state/state.js";
import { useState } from "react";
import CategoryCell from "./categoryCell";
import lang from "../../../state/language.js";
import CategoriesClassesCell from './categoriesClassesCell.js';


function CategoriesList({ 
                      isOpen, 
                      onClose, 
                      competition, 
                      classB1, classB2, classB3, classB4,
                      langSwitch, 
                      poomsaeCategories,
                      poomse}) {
 
  const [categoryCellModalOpen, setCategoryCellModalOpen] = useState (false);
  const [catForModal, setCatForModal] = useState (null);


 // Модальне вікно з Категоріями спортсменів
 const openCategoryCellModal = (cat) => {
  setCatForModal(cat)
 
    setCategoryCellModalOpen(true);
  }
  const closeCategoryCellModal = () => {setCategoryCellModalOpen(false);}
let  categories1=[], categories2=[], categories3=[], categories4=[], grids

if (competition.grids){
try{grids=JSON.parse(competition.grids)}catch(error){grids=competition.grids}
}





const gridsData = []
if(Array.isArray(grids)&&grids.length>0){

state.tournConstructor.categoryOrder.map((category)=>{
    grids.map((item)=>{
      if(item.category==category){gridsData.push(item)}
    })
})

  gridsData.map((item)=>{
    let [category, clasData] = item.category.split("_");
    if (clasData==='B1'){categories1.push({category:item.category, athletes:item.athleteList})}
    if (clasData==='B2'){categories2.push({category:item.category, athletes:item.athleteList})}
    if (clasData==='B3'){categories3.push({category:item.category, athletes:item.athleteList})}
    if (clasData==='B4'){categories4.push({category:item.category, athletes:item.athleteList})}
  })
  
}else{
  if(classB1){
    categories1 = Object.entries(classB1).map(([key, value]) => ({category: key,athletes: value,}));
    }
    if(classB2){
      categories2 = Object.entries(classB2).map(([key, value]) => ({category: key,athletes: value,}));
      }
      if(classB3){
        categories3 = Object.entries(classB3).map(([key, value]) => ({category: key,athletes: value,}));
        }
        if(classB4){
          categories4 = Object.entries(classB4).map(([key, value]) => ({category: key,athletes: value,}));
          }
 
  }


const poomsaeCategoriesData=[]
if (poomse){
state.tournConstructor.categoryOrderPoomsae.forEach((item)=>{
  poomsaeCategories.forEach((item2)=>{
    if(item2.category===item){poomsaeCategoriesData.push(item2)}
  })
})
}
const categoriesData=poomse?poomsaeCategoriesData:null 
  // const createMarkup = (html) => {return { __html: html };};
  return (<>
    {isOpen?(<>
      <div className={s.modalWriper}>
    <div className={s.modal}>


<div className={s.title}>{competition.title}
<p><u><b>{state.formatDate(competition.dateStart).slice(0, 2)}</b>- 
        <b>{state.formatDate(competition.dateFinish)}&nbsp;
        {lang[langSwitch].yearShort /*р.*/}
        </b></u> <br/><br/></p>
              </div> {/* Закриття шапки */}


             
             {classB1 ?(<>
             <div className={s.classNameTitle}>
              {lang[langSwitch].bClass1/*Клас Б-1*/}
              </div>
      <CategoriesClassesCell 
        poomse={poomse}
        categoriesData={categories1} 
        langSwitch={langSwitch}
        openCategoryCellModal={openCategoryCellModal}/>
        </>):null} 

        {classB2  ?(<>
          <div className={s.classNameTitle}>
              {lang[langSwitch].bClass2/*Клас Б-2*/}
              </div>
      <CategoriesClassesCell 
        categoriesData={categories2}
        poomse={poomse} 
        langSwitch={langSwitch}
        openCategoryCellModal={openCategoryCellModal}/>
        </>):null} 

        {classB3  ?(<>
          <div className={s.classNameTitle}>
              {lang[langSwitch].bClass3/*Клас Б-3*/}
              </div>
      <CategoriesClassesCell 
        categoriesData={categories3}
        poomse={poomse} 
        langSwitch={langSwitch}
        openCategoryCellModal={openCategoryCellModal}/>
        </>):null} 

        {classB4 ?(<>
          <div className={s.classNameTitle}>
              {lang[langSwitch].bClass4/*Клас Б-4*/}
              </div>
      <CategoriesClassesCell 
        categoriesData={categories4} 
        poomse={poomse}
        langSwitch={langSwitch}
        openCategoryCellModal={openCategoryCellModal}/>
        </>):null} 



      {/* <div dangerouslySetInnerHTML={createMarkup(regulationText)} /> */}
      <button type='button' onClick={onClose}>Закрити</button>
    </div>
  </div>
  {catForModal?( <CategoryCell cat={catForModal}
          isOpen={categoryCellModalOpen}
          onClose={closeCategoryCellModal} 
          competition={competition}
          langSwitch={langSwitch}
          poomse={poomse} 
          />   ):null} 
  </>):null}
</>)

}

export default CategoriesList