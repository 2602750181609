import React, { useState } from "react";
import s from '../userProfile/userProfile.module.css'
import lang from "../../../state/language";


function PasswordForm({setPass,langSwitch, pass}) {

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState(""); // Додано стан для зберігання повідомлення



  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    
    setShowPassword(!showPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
   
    if (newPassword === confirmPassword) {
      if (newPassword.length >= 5) {
       
          setPass(btoa(newPassword));
         
          setMessage(<div className={s.smallGreen}>
            {lang[langSwitch].successfulPasswordAddMessage}{/*Пароль успішно встановлено. Незабудьте зберегти результати!!! Кнопка знизу ліворуч.*/}
            </div>); // Встановлюємо повідомлення
        
      } else {
        setMessage(<div className={s.smallRed}>
          {lang[langSwitch].passwordSymbolsWarnin}{/*Пароль повинен містити щонайменше 5 символів.*/}
          </div>);
      }
    } else {
      setMessage(<div className={s.smallRed}>
       {lang[langSwitch].notConfirmPass}{/*Новий пароль не співпадає з підтвердженням.*/}
        </div>);
    }
  };

  return (
    <div>
      {message && 
      <div className={s.message} onClick={()=>{setMessage('')}}>
        {message}<br/>
        </div>} {/* Відображення повідомлення на екрані */}     

      <div>
        <label htmlFor="newPassword">
          <span className={s.span}>
          {lang[langSwitch].password}{/*Пароль*/}
          <span className={!pass && s.redStar}>*</span>
          </span><br/> 
          <input
           className={!pass && s.redInput}
          autoComplete="off"
            type={showPassword ? "text" : "password"}
            id="newPassword"
            name="newPassword"
           
            onChange={handleNewPasswordChange}
          />
        </label>
      </div><br/>

      <div>
        <label htmlFor="confirmPassword">
          <span className={s.span}>
          {lang[langSwitch].confirmNewPass}{/*Підтвердіть новий пароль*/}
          <span className={!pass && s.redStar}>*</span>
          </span><br/>
          <input
           className={!pass && s.redInput}
          autoComplete="off"
            type={showPassword ? "text" : "password"}
            id="confirmPassword"
            name="confirmPassword"
           
            onChange={handleConfirmPasswordChange}
          />
        </label>
      </div><br/>
      <div>
        <label>
          <input
            type="checkbox"
            checked={showPassword}
            onChange={toggleShowPassword}
          />
          <span className={s.span}>
          {lang[langSwitch].showPass}{/*Показати паролі*/}
          </span>
        </label>
      </div>
      <button type="button" onClick={handleSubmit}>
      {lang[langSwitch].setPassword}{/*Встановити пароль*/}
      </button>
    </div>
  );
}

export default PasswordForm;
