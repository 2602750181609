import s from './food.module.css'
import state from '../../../../state/state'
import FooterButtons from './footerButtons';
import lang from '../../../../state/language';

function FullFoodOrder ({isSmallScreen, title,isOpen,foodData,foodOptions,langSwitch}){

    return(<>
    
<div className={s.printContent}>
<div className={s.printHeader}>
{lang[langSwitch].orderingFoodForTournamentParticipants /* Замовлення на харчування учасників турниру */}       
 <div className={s.printTitle}>{title} </div>
</div>

<table className={s.foodPrintTable} style={{textAlign:'center'}}>
    <thead>
<tr>
    <td rowSpan={2}> {lang[langSwitch].date /*Дата*/}</td>
    <td colSpan={2}> {lang[langSwitch].breakfast /*Сніданок*/}</td>
    <td colSpan={2}>{lang[langSwitch].lunch /*Обід*/}</td>
    <td colSpan={2}>{lang[langSwitch].dinner /*Вечеря*/}</td>
</tr>
<tr>
    <td>{lang[langSwitch].portions /*Порції*/}</td>
    <td>{lang[langSwitch].cost /*Вартість*/}</td>
    <td>{lang[langSwitch].portions /*Порції*/}</td>
    <td>{lang[langSwitch].cost /*Вартість*/}</td>
    <td>{lang[langSwitch].portions /*Порції*/}</td>
    <td>{lang[langSwitch].cost /*Вартість*/}</td>
</tr>
    </thead>

        {foodData.map((coach,index)=>{
              let  count=0         
            return(<>    
            <tbody style={{ backgroundColor: index % 2 === 0 ? '#ebebeb' : null }}>
           <tr>
           <td colSpan={7}><b>
           {lang[langSwitch].orderNo /*Замовлення №*/}  
            {index+1}</b></td>
           </tr>
            {coach.data.map((item)=>{
                let b=0;let d=0;let s=0;
               if(item.breakfast){b=item.breakfast*foodOptions.prise.breakfast}
               if(item.dinner){d=item.dinner*foodOptions.prise.dinner}
               if(item.supper){s=item.supper*foodOptions.prise.supper}
               count=count+b+d+s
                return(<>
            <tr>
<td>{item.date}</td>
<td>{item.breakfast?item.breakfast+' шт.':null}</td>
<td>{item.breakfast?b+' грн.':null}</td>
<td>{item.dinner?item.dinner+' шт.':null}</td>
<td>{item.dinner?d+' грн.':null}</td>
<td>{item.supper?item.supper+' шт.':null}</td>
<td>{item.supper?s+' грн.':null}</td>
            </tr>
            </>)})}
          
          <tr >

                <td  colSpan={3}>
                    <b>{state.myCoachFull(coach.id)}</b>
                    </td>
                 <td  colSpan={2}>
                 {lang[langSwitch].telShort /*тел.*/}
                 <b> {coach.phone}</b>
                    </td>
                 <td  colSpan={2}>
                  {lang[langSwitch].sum /*сума*/}: 
                  <b> {count} </b> 
               {lang[langSwitch].UAH /* грн.*/}  
                    </td>
            </tr> 
          </tbody>   </>)})}
</table>

</div>


<FooterButtons 
    isOpen={isOpen} 
    isSmallScreen={isSmallScreen} 
    langSwitch={langSwitch}/>
    </>)
}

export default FullFoodOrder