//цей файл компонує кожен елемент циклу, що потім виводиться на сторінці mail

import React, { useState, useEffect } from 'react';
import s from './mail.module.css'
import state from '../../../state/state.js';
import axios from 'axios';
import ConfirmAthlete from './confirmAthlete';
import lang from '../../../state/language.js';
import indexedDBConnection from '../../../state/indexedDBConnection.js';



function PreviewWindow(props) {
  const langSwitch=props.langSwitch
  const [expanded, setExpanded] = useState(false);
  const [isRead, setIsRead] = useState(props.isRead);
  const [buttonClicked, setButtonClicked] = useState(false);

  
  if (isRead == '1') {//тут формується массив з id прочитаних повідомленн, які можна використати для видалення з БД
    
    if (!state.mailId.includes(props.id)) { state.mailId = [props.id];}
  }
  const toggleExpand = () => {
    setButtonClicked(true);
    setExpanded(!expanded);
    if (isRead == '0') {
      setIsRead('1')
    }
  };


  useEffect(() => { //вноситься в бд данні про прочитані повідомлення

    const handlePageLeave = async () => {
      if (Array.isArray(state.mailId) && state.mailId.length > 0) {
        try {
          const response = await axios.post(state.api.updateMailIsRead, {
            ids: state.mailId,
          });

          if (response.data.success) {
            // console.log('Дані успішно оновлені на сервері.');

            const targetId = state.mailId[0];// Отримуємо id, яке ми хочемо встановити як '1'
            const updatedMail = state.mail.map(item => {//створення нового масиву з оновленими значеннями
              if (item.id == targetId) {// Перевіряємо, чи id поточного об'єкта дорівнює цільовому id
                return { ...item, isRead: '1' };// Якщо так, повертаємо новий об'єкт зі зміненим isRead
              }
              return item; // Якщо id не співпадає, повертаємо оригінальний об'єкт без змін
            });

            // Оновлюємо стан state.mail новим масивом
                  
            state.mail = updatedMail;

            indexedDBConnection.saveData ('mail', updatedMail)
            state.messageCount()
            
          } else {
            console.error('Помилка оновлення даних на сервері:', response.data.error);
          }
        } catch (error) {
          console.error('Помилка під час виклику сервера:', error);
        }
      }
    };
    if (buttonClicked) {
      handlePageLeave();
    }
    setButtonClicked(false);

  }, [buttonClicked]);


  function deleteMessage(id){

    axios
.delete(state.api.deleteMailMessage, {
  params: {
      id:id,
      // Додано передачу імені файлу
  },
  headers: { 'Content-Type': 'application/json' },
})
.then((response) => {
const  updateMail = state.mail.filter((m)=>m.id != id)
state.mail=updateMail

indexedDBConnection.deleteData ('mail', id)


})
.catch((error) => {
  // setErrorMessage('Помилка запиту до сервера: ' + error);
});
}

/////////////////////////////////////////////////////////////////
   //Приймаємо текст та формуємо тіло повідомлення
   let parsedData; let text;
   

   if(props.title===lang[langSwitch].reasonsRefusalTitle /*Причини відмови спортсменам у реєстрації на турнир*/){
     text=props.text
   }
   else if(props.title==='Запит від спортсмена'){
    parsedData = JSON.parse(props.text);  
    function updateTeame(userId, coach) {
      toggleExpand()
      axios.put(state.api.acceptAthleteToClub, {
        id: userId,
        [coach]: state.userProfile.id
      })
        .then(response => {
          const index = state.athletes.findIndex((item)=>Number(item.id)===Number(userId))
          state.athletes[index][coach] = state.userProfile.id
          indexedDBConnection.saveData('athletes',state.athletes)
                              .then(()=>{
                                setButtonClicked(true);
                             
                                deleteMessage(props.id)
                              })
                              .catch((error)=>{
                                console.log('Невдалось оновити в IndexedDB дані про призначення тренера спортсмену ',state.myCoach(userId),' деталі: ', error)
                              })
        

        })
        .catch(error => {
          console.error('Помилка запиту до сервера:', error);
        });
    }
    
    
    text = parsedData ?
  
  <ConfirmAthlete 
        parsedData={parsedData} 
        updateTeame={updateTeame} 
        setIsRead={setIsRead} 
        id ={props.id} 
        deleteMessage={deleteMessage}/> 
    : null;
   }

//////////////////////////////////////////////////////////////////////
  const formattedText = () => {
    // Додаємо функцію для форматування тексту
    const formattedHTML = { __html:text };
    return expanded ? (//вміст відкритого вікна повідомлення
      <div dangerouslySetInnerHTML={formattedHTML}></div>
    ) : (//вміст відкритого вікна повідомлення
      <span className={isRead === '0' ? s.title1 : s.title}>
      {isRead === '0' || isRead === false ? (
        <span dangerouslySetInnerHTML={{ __html: `Нове повідомлення: <b>${props.title}</b>` }} />
      ) : (
        <span dangerouslySetInnerHTML={{ __html: `Прочитане повідомлення: <b>${props.title}</b>` }} />
      )}
    </span>
    
    );
  };
  
  
  return (
    <div className={isRead === '0' ? s.divMessage1 : s.divMessage}>
      <div>
        <b><span className={isRead === '0' ? s.date1 : s.date}>
          <u>{props.date}</u>
        </span></b>&nbsp;&nbsp;&nbsp;  {  parsedData? 
        <span className={isRead === '0' ? s.title1 : s.title}>
       <b>{text}</b> </span>:      
        formattedText()}
      </div>


  {parsedData? null :(<button className={s.button1} onClick={toggleExpand}>
        {expanded !== true ? 'Відкрити' : 'Закрити'}
      </button>)}
</div>

   
  );
}

export default PreviewWindow;
