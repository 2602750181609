import s from './grids.module.css'
import state from '../../../../state/state.js'
import { useState } from 'react';
import GridFooter from './GridFooter';
import lang from '../../../../state/language.js';

function Grid7(props) {	
	// console.log(props)
	// const athlete = state.tournConstructor.sortAthletesInGrid(props.athletes)
	const poomse=props.competition.poomse==1?true:false
const [check, setCheck] = useState(0);  
	let athleteInput
if (props.gridDB===null || props.gridDB===undefined){
	const data = state.tournConstructor.sortAthletesInGrid(props.athletes)
//перевіряємо співпадіння команд в першому бою

	  
if(check===0) { 
	state.tournConstructor.checkForDuplicates(data,props.category,poomse, props.langSwitch)
setCheck(1)
}
	athleteInput = data.flatMap(item => [item[1], item[2]]);//робимо простий масив з масив з об'ектів
	athleteInput = athleteInput.filter(item => item !== null); //обрізаємо порожні єлементи масиву
	athleteInput.forEach((athlete, index) => { // створюємо ордер для сотрування
		if (athlete) { athlete.order = index }
	});
}
else{ athleteInput = props.gridDB.athleteList}
	
	const[athleteList1,setAthleteList1]=useState(athleteInput)
	const [currentAthlete, setCurrentAthlete] = useState(null)


	function dragStartHandler(e, athlete) { //беремо об'єкт
		e.target.className = s.StartHandler
		setCurrentAthlete(athlete)
	};
	function dragEndHandler(e) { //об'єкт "сходить" з іншого об'єкта
		e.target.className = s.EndHandler
	};
	function dragOverHandler(e) {//об'єкт "портиває" інший об'єкт
		e.target.className = s.OverHandler
		e.preventDefault()
	};
	function dropHandler(e, droppedAthlete) {
		e.target.className = s.divAthleteInGrid;
		e.preventDefault();
	
		if (currentAthlete) {
			setAthleteList1((prevList) => {
				return prevList.map((a) => {
	
					if (a.gridId === droppedAthlete.gridId) {
						const index = state.competitionResult.findIndex(athl => athl.gridId === a.gridId);
						state.competitionResult[index].competitionPlace = currentAthlete.competitionPlace;
						return { ...a, order: currentAthlete.order, competitionPlace: currentAthlete.competitionPlace };
					}
					if (a.gridId === currentAthlete.gridId) {
						const index = state.competitionResult.findIndex(athl => athl.gridId === a.gridId);
						state.competitionResult[index].competitionPlace = droppedAthlete.competitionPlace;
						return { ...a, order: droppedAthlete.order, competitionPlace: droppedAthlete.competitionPlace };
					}
					// }
					return a;
				});
			});
		}
	}
	
	function sortAthletes(a, b) {
		const getOrder = (item) => item.order
		if (getOrder(a) > getOrder(b)) {
			return 1;
		} else {
			return -1;
		}
	}

	let athleteList //вкладаємо масив у змінну для виводу

	if (currentAthlete === null) { athleteList = athleteList1 }
	else { athleteList = athleteList1.sort(sortAthletes) }


	//////////////////////////////////////////////////////////////////////////////

	// проводимо поєдинки
// зберігаємо в стан просування спортсменів по сітці
	

	const [winner, setWinner] = useState(null);
	const [winner1, setWinner1] = useState(null); 
	const [winner2, setWinner2] = useState(null); 
	const [winner3, setWinner3] = useState(null); 
	const [winner4, setWinner4] = useState(null); 
	const [winner5, setWinner5] = useState(null); 
	

	athleteList1.forEach((a) => { //завантажуємо спортсменів в масив результатів змагань

		// Перевірка, чи спортсмен вже є в state.competitionResult
		const athleteExists = state.competitionResult.some((existingAthlete) => existingAthlete.gridId === a.gridId);

		if (!athleteExists) {
			a.competitionCategory = props.category;//катег. в якій виступає спортсмен
			a.ratingOnComp = 0 //рейтинг зароблений в поточному турнірі
			a.competitionPlace = '5 - 8'  //змінити в кожній сітці
			a.battlesOnComp = 1 //кількість поєдинків на турнирі
			a.victoryOnComp = 0 //кількість перемог на турнирі
			state.competitionResult.push(a);
		}
	});


	function winnerAthlete(athlete, setWinner, rankComp, rankCount, place) {
		const athleteIndex = state.competitionResult.findIndex(athl => athl.gridId === athlete.gridId)
		

		rankComp = parseInt(rankComp)

		let points //бали які спортсмен заробив за поєдинок
		if (rankCount !== null) { points = rankComp * rankCount }
		if (rankCount === null) { points = rankCount }

		state.competitionResult[athleteIndex].ratingOnComp = //редагуємо рейтинг
			state.competitionResult[athleteIndex].ratingOnComp + points			
			
		state.competitionResult[athleteIndex].competitionPlace = place//просуваємся по місцях
		
		state.competitionResult[athleteIndex].battlesOnComp++ //редагуємо кількість поєдинків
			
		//редагуємо кількість перемог, перемог на 1 менше ніж поєдинків (крім 1 місця)
		state.competitionResult[athleteIndex].victoryOnComp = state.competitionResult[athleteIndex].battlesOnComp - 1
				
		if (points / 4 === rankComp) { //за перше місце повертаемо вилучену перемогу
			state.competitionResult[athleteIndex].battlesOnComp--			
		}
		setWinner(athlete)
	}


	function handleCancel(athlete, setWinner, rankComp, rankCount) {


		rankComp = parseInt(rankComp)
		const athleteIndex = state.competitionResult.findIndex(athl => athl.gridId === athlete.gridId)
		
		//редагуємо поєдинки та перемоги		
		state.competitionResult[athleteIndex].battlesOnComp--
				
		state.competitionResult[athleteIndex].victoryOnComp--
	
		state.competitionResult[athleteIndex].ratingOnComp =
			state.competitionResult[athleteIndex].ratingOnComp - (rankCount !== null ? rankComp * rankCount : rankComp)
			
		const i = parseInt(state.competitionResult[athleteIndex].competitionPlace) + 1 //з 1 місця спускаємся на 2, тому +1
		if (i === 2) { state.competitionResult[athleteIndex].competitionPlace = '2' }
		else if (i === 3) { state.competitionResult[athleteIndex].competitionPlace = '3' }
		else if (i >= 4 && i <= 8) { state.competitionResult[athleteIndex].competitionPlace = '5 - 8' }
		else if (i > 8 && i <= 16) { state.competitionResult[athleteIndex].competitionPlace = '9 - 16' }
		else if (i > 16 && i <= 32) { state.competitionResult[athleteIndex].competitionPlace = '17 - 32' }
		else { }

		setWinner(null)

	}


	if (state.saveGridsFunctionStart) {
		props.saveGrids(
			athleteList.length, athleteList, props.competition.title, props.category, props.cort, props.battleNumbers)
	}
	function placeChanger(athlete, place){//змінюєио місця в массиві результатів для проскочивших спортсменів
		const index=state.competitionResult.findIndex(athl => athl.id === athlete.id)
		state.competitionResult[index].competitionPlace=place
						
	}
/////////////////////////////////////////////////////////////////////////////////////
const selectedIds=athleteList.map((item=>(item.gridId)))
const winnerAthletesData = state.competitionResult.filter(athlete => selectedIds.includes(athlete.gridId));

 
const winnerAthletes=state.sortWinnerAthletes(winnerAthletesData)

 
  
/////////////////////////////////////////////////////////////

const [wiew, setWiew] = useState(false);
const [score1, setScore1] = useState(null);
const [score2, setScore2] = useState(null);
const [score3, setScore3] = useState(null);
const [score4, setScore4] = useState(null);
const [score5, setScore5] = useState(null);
const [score6, setScore6] = useState(null);
		
const handleScoreChange = (e) => { setScore1(e.target.value) };
const handleScoreChange2 = (e) => { setScore2(e.target.value) };
const handleScoreChange3 = (e) => { setScore3(e.target.value) };
const handleScoreChange4 = (e) => { setScore4(e.target.value) };
const handleScoreChange5 = (e) => { setScore5(e.target.value) };
const handleScoreChange6 = (e) => { setScore6(e.target.value) };

function gridsResultMaker(){
	setWiew(true);
	const battleScores={}
		for(let i=1;i<=6;i++){
			battleScores[`score${i}`] = eval('score' + i);  
		}
		const winners={}
			for(let i=0;i<=5;i++){
				winners[`winner${i === 0 ? '' : i}`] = eval(`winner${i === 0 ? '' : i}`); 
			}
		
			const result = {}
			result.numberOfAthletes = athleteList.length;
			result.athleteList = athleteList
			result.competitionTitle = props.competition.title
			result.category = props.category
			result.cort = props.cort
			result.battleNumbers = props.battleNumbers
			result.battleScores = battleScores
			result.winners=winners
			result.winnerAthletes=winnerAthletes
			state.gridsResult.push(result)
	}
		
function deleteGridsResult(){
	state.gridsResult=state.gridsResult.filter(item=>item.category!==props.category)
setWiew(false);setScore1(null);setScore2(null);setScore3(null);setScore4(null);
setScore5(null);setScore6(null);
	}
	//console.log(state.gridsResult)
		//////////////////////////////////////////////////////////////////////////////
	
	return (<>
		<div className={s.mainDiv}>
			<div className={s.title}>
				<h3>{props.competition.title}</h3>
				<h3 >
  {props.competition.dateStart&&props.competition.dateFinish?(<>
  <u><b>{state.formatDate(props.competition.dateStart).slice(0, 2)}</b>- 
        <b>{state.formatDate(props.competition.dateFinish)} р.</b></u>         
      </>):(<>
    <u><b>  {props.competition.dateOfCompetition}р.</b></u> 
      </>)}
        <br/></h3>
				<h4 id={props.id}>
				{poomse?lang.poomsaeCatUkr(props.langSwitch,props.category):
	state.weightCatUkr (props.category,props.langSwitch, true)}
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				Корт: {props.cort}</h4>
			</div>
<table width="600" >
  <tbody>
    <tr>
      <td className={s.blueLeft}><table>
		<tbody>
		<tr>
<td>
	
{/* <div className={s.divAthleteInGrid}>{state.athleteName(athlete[0][1]['id'])}<br/><span>{athlete[0][1]['team']}</span></div> */}
<div 	onDragStart={(e)=>dragStartHandler(e,athleteList[0])}
									onDragLeave={(e)=>dragEndHandler(e)}
									onDragEnd={(e)=>dragEndHandler(e)}
									onDragOver={(e)=>dragOverHandler(e)}
									onDrop={(e)=>dropHandler(e,athleteList[0])}
									draggable ='true'
									className={s.divAthleteInGrid}>
										
										<div className={s.invisible}>
											
											{athleteList[0]['competitionPlace']===`3`?
											(placeChanger(athleteList[0],'5 - 8'),
											athleteList[0]['competitionPlace']=`5 - 8`):null
											}
											</div>

								 <div onClick={winner3===null?()=>{winnerAthlete(athleteList[0],setWinner3,props.competition.rankComp,2,3)}:null}>  {/*	4-бали для рейтегну за місце, 1 місце*/}
									
								{ athleteList[0].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[0].teamPoomsae)}<i>{athleteList[0]['team']}</i></>) : (<>{state.athleteName(athleteList[0]['id'])}<br /><i>{athleteList[0]['team']}</i></>) }

							
							</div></div>
</td>
<td className={s.figthLeft} >
	{props.cort}.{props.battleNumbers[0]}
	</td>
</tr>
</tbody>
</table>
		</td>
      <td ></td>
		<td >
			{/* Переможець  */}

{winner !== null ? (<>
<div className={s.sportCenter}><div className={s.divAthleteInGrid} onContextMenu={(e) => {e.preventDefault(); 
	handleCancel(winner,setWinner,props.competition.rankComp,4)}}>
    
	<div><img className={s.goldCup} alt='фото' src = '/img/main/goldCup1.png'/></div>
	{winner.teamPoomsae?state.poomsaeTeam(winner.teamPoomsae):state.athleteNameShort(winner.id)}
<br />
	<div className={s.battleScore}>{score1&&wiew?score1:null}</div>{/*рахунок поєдинка*/}
	</div> </div>
	</>) :
			<div className={s.figthCenter}>{props.cort}.{props.battleNumbers[5]}</div>
			}
							{/*ввод рахунку поєдинка*/}
	{wiew? null:
	<div className={s.noPrint} style={{textAlign:'center'}}>
	<input onChange={handleScoreChange} type="text" id="title" name="title" />
	</div>}<br/>
			</td>
	  <td ></td>
		<td className={s.blueRight}><table><tbody><tr>
<td className={s.figthRight} >
	{props.cort}.{props.battleNumbers[2]}
	</td>
<td className={s.sportRight}>
	
{/* <div className={s.divAthleteInGrid}>{state.athleteName(athlete[2][1]['id'])}<br/><span>{athlete[2][1]['team']}</span></div> */}

<div 	onDragStart={(e)=>dragStartHandler(e,athleteList[4])}
									onDragLeave={(e)=>dragEndHandler(e)}
									onDragEnd={(e)=>dragEndHandler(e)}
									onDragOver={(e)=>dragOverHandler(e)}
									onDrop={(e)=>dropHandler(e,athleteList[4])}
									draggable ='true'
									className={s.divAthleteInGrid}>
										<div className={s.invisible}>
											
											{athleteList[4]['competitionPlace']===`3`?
											(placeChanger(athleteList[4],'5 - 8'),
											athleteList[4]['competitionPlace']=`5 - 8`):null
											}
											</div>
										
								 <div onClick={winner5===null?()=>{winnerAthlete(athleteList[4],setWinner5,props.competition.rankComp,2,3)}:null}>  {/*	4-бали для рейтегну за місце, 1 місце*/}
									
								{ athleteList[4].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[4].teamPoomsae)}<i>{athleteList[4]['team']}</i></>) : (<>{state.athleteName(athleteList[4]['id'])}<br /><i>{athleteList[4]['team']}</i></>) }

							
							</div></div>


</td>
</tr></tbody></table></td>
    </tr>
    <tr>
      <td className={s.redLeft}>
		{/* <div className={s.divAthleteInGrid}>{state.athleteName(athlete[0][2]['id'])}<br/><span>{athlete[0][2]['team']}</span></div> */}
		
		<div 	onDragStart={(e)=>dragStartHandler(e,athleteList[1])}
									onDragLeave={(e)=>dragEndHandler(e)}
									onDragEnd={(e)=>dragEndHandler(e)}
									onDragOver={(e)=>dragOverHandler(e)}
									onDrop={(e)=>dropHandler(e,athleteList[1])}
									draggable ='true'
									className={s.divAthleteInGrid}>
										
										<div className={s.invisible}>
											
											{athleteList[1]['competitionPlace']===`3`?
											(placeChanger(athleteList[1],'5 - 8'),
											athleteList[1]['competitionPlace']=`5 - 8`):null
											}
											</div>
								 <div onClick={winner3===null?()=>{winnerAthlete(athleteList[1],setWinner3,props.competition.rankComp,2,3)}:null}>  {/*	4-бали для рейтегну за місце, 1 місце*/}

								{ athleteList[1].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[1].teamPoomsae)}<i>{athleteList[1]['team']}</i></>) : (<>{state.athleteName(athleteList[1]['id'])}<br /><i>{athleteList[1]['team']}</i></>) }

							
							</div></div>
		
		</td>
      <td className={s.blueLeft}><table><tbody>
        <tr>
          <td>
			
		  {winner3 !==null?(<>
		
		<div className={s.divAthleteInGrid} onContextMenu={(e) => {e.preventDefault();
		 if (winner1 === null) { handleCancel(winner3, setWinner3, props.competition.rankComp, 2); }}}
			onClick={winner1===null?()=>{winnerAthlete(winner3,setWinner1,props.competition.rankComp,3,2)}:null}>
		
		{winner3.teamPoomsae?state.poomsaeTeam(winner3.teamPoomsae):state.athleteNameShort(winner3.id)}
 </div>
		</>):
		(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
	{wiew?
	score4&&wiew?score4:null:
	<div className={s.noPrint} >
	<input onChange={handleScoreChange4} type="text" id="title" name="title" />
		</div>}
	</div>{/*КІНЕЦЬ рахунок з полем вводу*/}
		  </td>
          <td className={s.figthLeft} >
			{props.cort}.{props.battleNumbers[3]}
			</td>
        </tr>
		</tbody></table></td>
		<td className={s.nextRed}><div className={s.sportCenter}>
			
		{winner1 !==null?(<>
		
		<div className={s.divAthleteInGrid} onContextMenu={(e) => {e.preventDefault(); 
		 if (winner === null) { handleCancel(winner1, setWinner1, props.competition.rankComp, 3); }}}
			onClick={winner===null?()=>{winnerAthlete(winner1,setWinner,props.competition.rankComp,4,1)}:null}>
		
		{winner1.teamPoomsae?state.poomsaeTeam(winner1.teamPoomsae):state.athleteNameShort(winner1.id)}
<br /> </div>
		</>):
		(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
			<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
			
	{wiew?
	score2&&wiew?score2:null:
	<div className={s.noPrint} style={{textAlign:'center'}}>
	<input onChange={handleScoreChange2} type="text" id="title" name="title" />
		</div>}
	</div>{/*КІНЕЦЬ рахунок з полем вводу*/}
			</div></td>
	  <td className={s.blueRight}><table><tbody>
	    <tr>
	      <td className={s.figthRight} >
			 {props.cort}.{props.battleNumbers[4]} 
			</td>
	      <td className={s.sportRight}>
			
		  {winner5 !==null?(<>
		
		<div className={s.divAthleteInGrid} onContextMenu={(e) => {e.preventDefault();
	 if (winner2 === null) { handleCancel(winner5, setWinner5, props.competition.rankComp, 2); }}}
			onClick={winner2===null?()=>{winnerAthlete(winner5,setWinner2,props.competition.rankComp,3,2)}:null}>
		
		{winner5.teamPoomsae?state.poomsaeTeam(winner5.teamPoomsae):state.athleteNameShort(winner5.id)}
<br /> </div>
		</>):
		(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
<div className={s.battleScore}> {/* ПОЧАТОК рахунок з полем вводу*/}
	{wiew?
	score6&&wiew?score6:null:
	<div className={s.noPrint} >
	<input onChange={handleScoreChange6} type="text" id="title" name="title" />
		</div>}
	</div> {/* ПОЧАТОК рахунок з полем вводу*/}

		  </td>
	      </tr>
		  </tbody></table></td>
		<td className={s.redRight}>
			
		{/* <div className={s.divAthleteInGrid}>{state.athleteName(athlete[2][2]['id'])}<br/><span>{athlete[2][2]['team']}</span></div> */}

		<div 	onDragStart={(e)=>dragStartHandler(e,athleteList[5])}
									onDragLeave={(e)=>dragEndHandler(e)}
									onDragEnd={(e)=>dragEndHandler(e)}
									onDragOver={(e)=>dragOverHandler(e)}
									onDrop={(e)=>dropHandler(e,athleteList[5])}
									draggable ='true'
									className={s.divAthleteInGrid}>
										<div className={s.invisible}>
											
											{athleteList[5]['competitionPlace']===`3`?
											(placeChanger(athleteList[5],'5 - 8'),
											athleteList[5]['competitionPlace']=`5 - 8`):null
											}
											</div>
										
								 <div onClick={winner5===null?()=>{winnerAthlete(athleteList[5],setWinner5,props.competition.rankComp,2,3)}:null}>  {/*	4-бали для рейтегну за місце, 1 місце*/}
									
								{ athleteList[5].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[5].teamPoomsae)}<i>{athleteList[5]['team']}</i></>) : (<>{state.athleteName(athleteList[5]['id'])}<br /><i>{athleteList[5]['team']}</i></>) }

							
							</div></div>

		</td>
    </tr>
	  
	   <tr>
		  <td></td><td></td><td className={s.next}></td><td></td><td></td>
	  </tr>
	  
	  
	  <tr>
      <td className={s.blueLeft}><table><tbody><tr>
<td>
	
{/* <div className={s.divAthleteInGrid}>{state.athleteName(athlete[1][1]['id'])}<br/><span>{athlete[1][1]['team']}</span></div> */}

<div 	onDragStart={(e)=>dragStartHandler(e,athleteList[2])}
									onDragLeave={(e)=>dragEndHandler(e)}
									onDragEnd={(e)=>dragEndHandler(e)}
									onDragOver={(e)=>dragOverHandler(e)}
									onDrop={(e)=>dropHandler(e,athleteList[2])}
									draggable ='true'
									className={s.divAthleteInGrid}>
										<div className={s.invisible}>
											
											{athleteList[2]['competitionPlace']===`3`?
											(placeChanger(athleteList[2],'5 - 8'),
											athleteList[2]['competitionPlace']=`5 - 8`):null
											}
											</div>
										
								 <div onClick={winner4===null?()=>{winnerAthlete(athleteList[2],setWinner4,props.competition.rankComp,2,3)}:null}>  {/*	4-бали для рейтегну за місце, 1 місце*/}
									
								{ athleteList[2].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[2].teamPoomsae)}<i>{athleteList[2]['team']}</i></>) : (<>{state.athleteName(athleteList[2]['id'])}<br /><i>{athleteList[2]['team']}</i></>) }

							
							</div></div>

</td>
<td className={s.figthLeft} >
	{props.cort}.{props.battleNumbers[1]}
	</td>
</tr></tbody></table>
		</td>
      <td className={s.redLeft}><table><tbody>
        <tr>
          <td>

		  {winner4 !==null?(<>
		
		<div className={s.divAthleteInGrid} onContextMenu={(e) => {e.preventDefault(); 
		 if (winner1 === null) { handleCancel(winner4, setWinner4, props.competition.rankComp, 2); }}}
			onClick={winner1===null?()=>{winnerAthlete(winner4,setWinner1,props.competition.rankComp,3,2)}:null}>
		
		{winner4.teamPoomsae?state.poomsaeTeam(winner4.teamPoomsae):state.athleteNameShort(winner4.id)}
<br /> </div>
		</>):
		(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
	{wiew?
	score5&&wiew?score5:null:
	<div className={s.noPrint} >
	<input onChange={handleScoreChange5} type="text" id="title" name="title" />
		</div>}
	</div>{/*КІНЕЦЬ рахунок з полем вводу*/}
		  </td>
          <td className={s.colorLeft}>Син.</td>
        </tr>
		</tbody></table></td>
	    <td className={s.next}><div className={s.sportCenter}>
			
		{winner2 !==null?(<>
		
		<div className={s.divAthleteInGrid} onContextMenu={(e) => {e.preventDefault(); 
		 if (winner === null) { handleCancel(winner2, setWinner2, props.competition.rankComp, 3); }}}
			onClick={winner===null?()=>{winnerAthlete(winner2,setWinner,props.competition.rankComp,4,1)}:null}>
		
		{winner2.teamPoomsae?state.poomsaeTeam(winner2.teamPoomsae):state.athleteNameShort(winner2.id)}
<br /> </div>
		</>):
		(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
			<div className={s.battleScore}> {/* ПОЧАТОК рахунок з полем вводу*/}
	{wiew?
	score3&&wiew?score3:null:
	<div className={s.noPrint} style={{textAlign:'center'}}>
	<input onChange={handleScoreChange3} type="text" id="title" name="title" />
		</div>}
	</div> {/* КІНЕЦЬ рахунок з полем вводу*/}
			</div></td>
		  <td className={s.redRight}><div className={s.colorRight}>Чер.</div></td>
		<td className={s.singleRight}>
			
		{/* <div className={s.divAthleteInGrid}>{state.athleteName(athlete[3][1]['id'])}<br/><span>{athlete[3][1]['team']}</span></div> */}

		<div 	onDragStart={(e)=>dragStartHandler(e,athleteList[6])}
									onDragLeave={(e)=>dragEndHandler(e)}
									onDragEnd={(e)=>dragEndHandler(e)}
									onDragOver={(e)=>dragOverHandler(e)}
									onDrop={(e)=>dropHandler(e,athleteList[6])}
									draggable ='true'
									className={s.divAthleteInGrid}>
									<div className={s.invisible}>{
											athleteList[6]['competitionPlace']==='5 - 8'?
											(placeChanger(athleteList[6],'3'),
											athleteList[6]['competitionPlace']=`3`):null}
											</div>	
								 <div onClick={winner2===null?()=>{winnerAthlete(athleteList[6],setWinner2,props.competition.rankComp,3,2)}:null}>  {/*	4-бали для рейтегну за місце, 1 місце*/}
									
								{ athleteList[6].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[6].teamPoomsae)}<i>{athleteList[6]['team']}</i></>) : (<>{state.athleteName(athleteList[6]['id'])}<br /><i>{athleteList[6]['team']}</i></>) }

							
							</div></div>

		</td>
    </tr>
    <tr>
      <td className={s.redLeft}>
		
	  {/* <div className={s.divAthleteInGrid}>{state.athleteName(athlete[1][2]['id'])}<br/><span>{athlete[1][2]['team']}</span></div> */}

	  <div 	onDragStart={(e)=>dragStartHandler(e,athleteList[3])}
									onDragLeave={(e)=>dragEndHandler(e)}
									onDragEnd={(e)=>dragEndHandler(e)}
									onDragOver={(e)=>dragOverHandler(e)}
									onDrop={(e)=>dropHandler(e,athleteList[3])}
									draggable ='true'
									className={s.divAthleteInGrid}>
										<div className={s.invisible}>
											
											{athleteList[3]['competitionPlace']===`3`?
											(placeChanger(athleteList[3],'5 - 8'),
											athleteList[3]['competitionPlace']=`5 - 8`):null
											}
											</div>
										
								 <div onClick={winner4===null?()=>{winnerAthlete(athleteList[3],setWinner4,props.competition.rankComp,2,3)}:null}>  {/*	4-бали для рейтегну за місце, 1 місце*/}
									
								{ athleteList[3].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[3].teamPoomsae)}<i>{athleteList[3]['team']}</i></>) : (<>{state.athleteName(athleteList[3]['id'])}<br /><i>{athleteList[3]['team']}</i></>) }

							
							</div></div>

	  </td>
      <td ></td>
	  <td ></td>
	  <td ></td>
		<td ></td>
    </tr>
	 
  </tbody>
</table>
{/* /////////////////////////////////////////////// */}
<GridFooter 
	competition={props.competition} 
	wiew={wiew} 
	deleteGridsResult={deleteGridsResult}
	gridsResultMaker={gridsResultMaker} 
	winnerAthletes={winnerAthletes} 
	winner={winner}/>
		</div> 
	</>)
}

export default Grid7