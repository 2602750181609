import s from './adminCompetition.module.css'
import state from '../../../state/state.js';
import lang from '../../../state/language.js';


function Calculator({competition,setCalcOpen,isSmallScreen,langSwitch,poomse}){

let grids=null
try{grids=JSON.parse(competition.grids)}catch{grids=competition.grids}

let athletes=null
try{athletes=JSON.parse(competition.athletes)}catch{athletes=competition.athletes}

let poomsaeCategories
try{poomsaeCategories=JSON.parse(competition.poomsaeCategories)}catch{poomsaeCategories=competition.poomsaeCategories}

let gold=0; let silver=0; let bronse=0; let gramotu=0



if(poomse){
function countPoomsaeMedales (cat,athletes){
   if(cat==='jrYounkersMaleTraditionalPersonaleClassA'||cat==='jrYounkersFemaleTraditionalPersonaleClassA'|| cat==='younkersMaleTraditionalPersonaleClassA'||cat==='younkersFemaleTraditionalPersonaleClassA'|| cat==='cadetsMaleTraditionalPersonaleClassA'||cat==='cadetsFemaleTraditionalPersonaleClassA'|| cat==='juniorsMaleTraditionalPersonaleClassA'||cat==='juniorsFemaleTraditionalPersonaleClassA'|| cat==='adultsMale30TraditionalPersonaleClassA'||cat==='adultsFemale30TraditionalPersonaleClassA'|| cat==='adultsMale40TraditionalPersonaleClassA'||cat==='adultsFemale40TraditionalPersonaleClassA'|| cat==='adultsMale50TraditionalPersonaleClassA'||cat==='adultsFemale50TraditionalPersonaleClassA'|| cat==='adultsMale60TraditionalPersonaleClassA'||cat==='adultsFemale60TraditionalPersonaleClassA'|| cat==='adultsMale65TraditionalPersonaleClassA'||cat==='adultsFemale65TraditionalPersonaleClassA'|| cat==='adultsMale65PlusTraditionalPersonaleClassA'||cat==='adultsFemale65PlusTraditionalPersonaleClassA'||
      cat==='freestylePersonaleMale17ClassA'||cat==='freestylePersonaleFemale17ClassA'||cat==='freestylePersonaleMale17PlusClassA'|| cat==='freestylePersonaleFemale17PlusClassA'||
      cat==='mixPoomsaePersonaleMaleClassA'||cat==='mixPoomsaePersonaleFemaleClassA'||
      cat==='jrYounkersMaleTraditionalPersonaleClassB'||cat==='jrYounkersFemaleTraditionalPersonaleClassB'||cat==='younkersMaleTraditionalPersonaleClassB'||cat==='younkersFemaleTraditionalPersonaleClassB'||cat==='cadetsMaleTraditionalPersonaleClassB'||cat==='cadetsFemaleTraditionalPersonaleClassB'||cat==='juniorsMaleTraditionalPersonaleClassB'||cat==='juniorsFemaleTraditionalPersonaleClassB'||cat==='adultsMale30TraditionalPersonaleClassB'||cat==='adultsFemale30TraditionalPersonaleClassB'||cat==='adultsMale40TraditionalPersonaleClassB'||cat==='adultsFemale40TraditionalPersonaleClassB'||cat==='adultsMale50TraditionalPersonaleClassB'||cat==='adultsFemale50TraditionalPersonaleClassB'||cat==='adultsMale60TraditionalPersonaleClassB'||cat==='adultsFemale60TraditionalPersonaleClassB'||cat==='adultsMale65TraditionalPersonaleClassB'||cat==='adultsFemale65TraditionalPersonaleClassB'||cat==='adultsMale65PlusTraditionalPersonaleClassB'||cat==='adultsFemale65PlusTraditionalPersonaleClassB'||
      cat==='freestylePersonaleMale17ClassB'||cat==='freestylePersonaleFemale17ClassB'||cat==='freestylePersonaleMale17PlusClassB'||cat==='freestylePersonaleFemale17PlusClassB'||
       cat==='mixPoomsaePersonaleMaleClassB'||cat==='mixPoomsaePersonaleFemaleClassB'
   ){
      if(athletes.length===1){gold++;  gramotu++}
      if(athletes.length===2){gold++; silver++; gramotu=gramotu+2}
      if(athletes.length===3){gold++; silver++; bronse++; gramotu=gramotu+3}
      if(athletes.length>3){gold++; silver++; bronse=bronse+2; gramotu=gramotu+4}
   }
   if(cat==='jrYounkersTraditionalCoupleClassA'||cat==='younkersTraditionalCoupleClassA'||cat==='cadetsTraditionalCoupleClassA'||cat==='juniorsTraditionalCoupleClassA'||cat==='adults30TraditionalCoupleClassA'||cat==='adults40TraditionalCoupleClassA'||cat==='adults50TraditionalCoupleClassA'||cat==='adults60TraditionalCoupleClassA'||cat==='adults60PlusTraditionalCoupleClassA'||
      cat==='freestyleCouple17ClassA'||cat==='freestyleCouple17PlusClassA'||
      cat=== 'mixPoomsaeCoupleClassA'||
       cat==='jrYounkersTraditionalCoupleClassB'||cat==='younkersTraditionalCoupleClassB'||cat==='cadetsTraditionalCoupleClassB'||cat==='juniorsTraditionalCoupleClassB'||cat==='adults30TraditionalCoupleClassB'||cat==='adults40TraditionalCoupleClassB'||cat==='adults50TraditionalCoupleClassB'||cat==='adults60TraditionalCoupleClassB'||cat==='adults60PlusTraditionalCoupleClassB'||
      cat==='freestyleCouple17ClassB'||cat==='freestyleCouple17PlusClassB'||
      cat=== 'mixPoomsaeCoupleClassB'
   ){
      if(athletes.length===1){gold=gold+2;  gramotu=gramotu+2}
      if(athletes.length===2){ gold=gold+2; silver=silver+2; gramotu=gramotu+4}
      if(athletes.length===3){gold=gold+2; silver=silver+2; bronse=bronse+2; gramotu=gramotu+6}
      if(athletes.length>3){gold=gold+2; silver=silver+2; bronse=bronse+4; gramotu=gramotu+8}
   }
    if(cat==='jrYounkersMaleTraditionalTeamClassA'||cat==='jrYounkersFemaleTraditionalTeamClassA'||cat==='younkersMaleTraditionalTeamClassA'||cat==='younkersFemaleTraditionalTeamClassA'||cat==='cadetsMaleTraditionalTeamClassA'||cat==='cadetsFemaleTraditionalTeamClassA'||cat==='juniorsMaleTraditionalTeamClassA'||cat==='juniorsFemaleTraditionalTeamClassA'||cat==='adultsMale30TraditionalTeamClassA'||cat==='adultsFemale30TraditionalTeamClassA'||cat==='adultsMale40TraditionalTeamClassA'||cat==='adultsFemale40TraditionalTeamClassA'||cat==='adultsMale50TraditionalTeamClassA'||cat==='adultsFemale50TraditionalTeamClassA'||cat==='adultsMale60TraditionalTeamClassA'||cat==='adultsFemale60TraditionalTeamClassA'||cat==='adultsMale60PlusTraditionalTeamClassA'||cat==='adultsFemale60PlusTraditionalTeamClassA'||
      cat==='jrYounkersMaleTraditionalTeamClassB'||cat==='jrYounkersFemaleTraditionalTeamClassB'||cat==='younkersMaleTraditionalTeamClassB'||cat==='younkersFemaleTraditionalTeamClassB'||cat==='cadetsMaleTraditionalTeamClassB'||cat==='cadetsFemaleTraditionalTeamClassB'||cat==='juniorsMaleTraditionalTeamClassB'||cat==='juniorsFemaleTraditionalTeamClassB'||cat==='adultsMale30TraditionalTeamClassB'||cat==='adultsFemale30TraditionalTeamClassB'||cat==='adultsMale40TraditionalTeamClassB'||cat==='adultsFemale40TraditionalTeamClassB'||cat==='adultsMale50TraditionalTeamClassB'||cat==='adultsFemale50TraditionalTeamClassB'||cat==='adultsMale60TraditionalTeamClassB'||cat==='adultsFemale60TraditionalTeamClassB'||cat==='adultsMale60PlusTraditionalTeamClassB'||cat==='adultsFemale60PlusTraditionalTeamClassB'||
      cat=== 'mixPoomsaeTeamMaleClassB'||cat==='mixPoomsaeTeamFemaleClassB'||cat==='mixPoomsaeTeamMaleClassA'||cat==='mixPoomsaeTeamFemaleClassA'
      ){
         if(athletes.length===1){gold=gold+3;  gramotu=gramotu+3}
         if(athletes.length===2){ gold=gold+3; silver=silver+3; gramotu=gramotu+6}
         if(athletes.length===3){gold=gold+3; silver=silver+3; bronse=bronse+3; gramotu=gramotu+9}
         if(athletes.length>3){gold=gold+3; silver=silver+3; bronse=bronse+6; gramotu=gramotu+12}
      }
   if(cat==='freestyleTeamClassB'||cat==='freestyleTeamClassA'||cat==='mixPoomsaeMixedTeamClassA'||cat==='mixPoomsaeMixedTeamClassB' 
   ){
      if(athletes.length===1){gold=gold+6;  gramotu=gramotu+6}
      if(athletes.length===2){ gold=gold+6; silver=silver+6; gramotu=gramotu+12}
      if(athletes.length===3){gold=gold+6; silver=silver+6; bronse=bronse+6; gramotu=gramotu+18}
      if(athletes.length>3){gold=gold+6; silver=silver+6; bronse=bronse+12; gramotu=gramotu+24}
   }
}

poomsaeCategories.forEach((item)=>{
   countPoomsaeMedales(item.category,item.athletes)
})


}else{
if(grids&&Array.isArray(grids)&&grids.length>0){

grids.map((item)=>{
 
    if(grids&&item.athleteList.length===1){ gold++;  gramotu++}
    if(grids&&item.athleteList.length===2){ gold++; silver++; gramotu=gramotu+2}
    if(grids&&item.athleteList.length===3){ gold++; silver++; bronse++; gramotu=gramotu+3}
    if(grids&&item.athleteList.length>3){ gold++; silver++; bronse=bronse+2; gramotu=gramotu+4}
})
}
if((!grids||!Array.isArray(grids)||grids.length===0)&&athletes){

   const sortedClasses = state.tournConstructor.sortClasses(athletes)
  
   const sortedClassesData = sortedClasses.map((item) => {
       let category = state.tournConstructor.sortAthletesByCategories(item.athletes);
         
       Object.keys(category).forEach(key => {
         const newKey = `${key}_${item.clas}`; // Додаємо клас до назви ключа
         
         category[newKey] = category[key]; // Створюємо новий ключ
         delete category[key]; // Видаляємо старий ключ
         
             });
       return category;
   });
 
   grids =  Object.assign({}, ...sortedClassesData);




Object.entries(grids).map((item)=>{
    if(grids&&item[1].length===1){ gold++;  gramotu++}
    if(grids&&item[1].length===2){ gold++; silver++; gramotu=gramotu+2}
    if(grids&&item[1].length===3){ gold++; silver++; bronse++; gramotu=gramotu+3}
    if(grids&&item[1].length>3){ gold++; silver++; bronse=bronse+2; gramotu=gramotu+4}
})
}
}

    return(<>
  <div style={{backgroundColor:'#ffffff'}}>
     <div><h1 className={s.h1}> {competition.title} </h1></div>
       <div><h2 className={s.h2}>
       
         </h2></div>


         {lang[langSwitch].calculationOfAttributes /* Калькуляція атрибутикі*/}
         <div className={s.info}><b>
         {lang[langSwitch].warningInCalculator /* Увага! При розрахунку калькуляції автоматичний перенос спортсменів в іншу категорію враховується тільки після публікації сіток. */}
         </b></div>
    <div>
<div style={{
   border:'solid 1px black',
   margin: '5px 20px',
   paddingTop:'5px',
   paddingBottom:'5px'
   }}>
{lang[langSwitch].reference /*тут текст довідки*/}
</div>
<div className={s.info}><b>Розрахунок нагород зроблено з урахуванням  запасного спортсмена в командах по 5 учасників, тобто 5+1.</b></div>
 <div className={s.info}>
 <b><i> 1. </i></b> 
 {lang[langSwitch].goldMedals /*Золотих медалей*/} - 
 <b> {gold}</b>
 {lang[langSwitch].pcs /*шт.*/}
    </div>
    <div className={s.info}>
 <b><i> 2. </i></b> 
 {lang[langSwitch].silverMedals /*Срібних медалей*/} - <b> {silver}</b>
 {lang[langSwitch].pcs /*шт.*/}
    </div>
    <div className={s.info}>
 <b><i> 3. </i></b> 
 {lang[langSwitch].bronzeMedals /*Бронзових медалей*/} - <b> {bronse}</b> 
 {lang[langSwitch].pcs /*шт.*/}
    </div>
    <div className={s.info}>
 <b><i> 4. </i></b> 
 {lang[langSwitch].awardCertificate /*Грамот до медалей*/} - <b> {gramotu}</b> 
 {lang[langSwitch].pcs /*шт.*/}
    </div>
    <div className={s.info}>
 <b><i> 5. </i></b> 
 {lang[langSwitch].certificates /*Сертифікатів учасників змаганнь*/} - <b> {athletes?athletes.length:'0'}</b> 
 {lang[langSwitch].pcs /*шт.*/}
    </div>
    <div className={s.info}>
 <b><i> 6. </i></b> 
 {lang[langSwitch].cupsForFwardingFeams /*Кубків для нагородження команд*/} - <b> 3 - 5</b> 
 {lang[langSwitch].pcs /*шт.*/}
    </div>
    <div className={s.info}>
 <b><i> 7. </i></b> 
 {lang[langSwitch].cupForBestAthlete /*Кубок кращому спортсмену*/} - <b> 1 - 2</b> 
 {lang[langSwitch].pcs /*шт.*/} 
 {lang[langSwitch].perAthleteArInGenderGategories /*(одному спортсмену , чи окремо в гендерних категоріях)*/}
    </div>
<div className={s.info}>
 <b><i> 8. </i></b> 
 {lang[langSwitch].cupForBestCoach /*Кубок кращому тренеру*/} - <b> 1</b> 
 {lang[langSwitch].pcs /*шт.*/}
    </div>
    <div className={s.info}>
 <b><i> 9. </i></b> 
 {lang[langSwitch].cupForBestJudge /*Кубок кращому судді*/} - <b> 1</b> 
 {lang[langSwitch].pcs /*шт.*/}
    </div>
    <div className={s.info}>
 <b><i> 10. </i></b>
 {lang[langSwitch].numberOfDiplomas /*Кількість дипломів дорівнює кількості кубків.*/}
    </div>
    <div className={s.info}> <br/> 
    {lang[langSwitch].thisCalculationBeenMade /*Дану калькулацію складено*/}
  <b> {state.dateForMessage()}&nbsp;
  {lang[langSwitch].yearShort /*р.*/}
   </b> 
   {lang[langSwitch].changesNumberOfParticipants /*при зміні кількості учасників зверніться за новими розрахунками.*/}
    </div>
    </div>

<div className={s.noPrint}>
    <div className={!isSmallScreen?s.swichButtContaner:s.td1}>
                <div className={s.switchButL}>
                
                <button type="button" className={s.adminButton} onClick={()=>{window.print()}}>
                {lang[langSwitch].print /*Друкувати*/}
                </button> 
               
                    </div>
               

                    
                <div className={s.switchButR}>
                   
                        <button className={s.adminButton} type='button' onClick={()=>{setCalcOpen(null)}}>
                        {lang[langSwitch].close /*Закрити*/}
                        </button>
                 
                </div>
                <br/><br/><br/>
            </div>

            </div>
    </div>
    </>)
}

export default Calculator