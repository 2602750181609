import React from 'react';
import s from '../result.module.css';
import lang from '../../../../state/language';


function Permit({competition,setSwitcher,langSwitch}) {

    function dataForTable() {
        const rows = [];

        for (let i = 0; i < 7; i++) {
            const cells = [];

            for (let j = 0; j < 4; j++) {
                cells.push(<td key={j}>
<div className={s.permitTitle}>{competition.title}</div>
<div className={s.infoDescription }><br/>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
    {lang[langSwitch].surname /*Призвище*/}, {lang[langSwitch].name /*Ім'я*/}
    &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </div>
<div style={{paddingTop:'5px'}}>
    
 <span className={s.color}>
      RED
    &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    
    WHITE
    </span>
    </div>
    <div >
    <span className={s.infoDescription }>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    {lang[langSwitch].court /*Корт*/}/{lang[langSwitch].fight /*Бій*/}
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
    &nbsp;&nbsp;&nbsp;&nbsp;
        <span className={s.infoDescription }>
   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
   {lang[langSwitch].signature /*Підпис*/}   
   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
    </div>

                </td>);
            }

            rows.push(<tr key={i}>{cells}</tr>);
        }

        return rows;
    }

    return (<>
   <div className={s.resultTitle}>
    <div className={s.h3}>
    {lang[langSwitch].passesForRefereeControl /*Перепустки для суддівського контролю*/}
        </div>
   </div>

        <div className={s.mainDivForPrint}>
            <table className={s.permitTable}>
                <tbody>
                    {dataForTable()}
                </tbody>
            </table>
        </div>


        <div className={s.resultTitle}>
        <div className={s.containerBadgeTop}>
<div className={s.itemResultsFooter1}>
<button type='button'  onClick={()=>{window.print()}}>
{lang[langSwitch].print /*Друкувати*/}
</button>
</div>

<div className={s.itemResultsFooter2}>

    <button  type='button' onClick={()=>{setSwitcher(0)}}>
    {lang[langSwitch].close /*Закрити*/}
    </button>
        
</div></div>
</div>

        
   </> );
}

export default Permit;
