import axios from 'axios';
import React, { useState, useEffect } from 'react';
import state from '../../../../state/state';
import indexedDBConnection from '../../../../state/indexedDBConnection';
import PhotoGaleryItem from './photoGaleryItem';
import EditPhotoGalery from '../../controlPanel/media/editPhotoGalery';
import s from '../forAllUsers.module.css'
import sMenu from '../../controlPanel/menu/menu.module.css'
import lang from '../../../../state/language';


function PhotoContent({ isSmallScreen, langSwitch, moderator }) {

    const [editStart, setEditStart] = useState(false);
    const [editPhotoGalery, setEditPhotoGalery] = useState(false);     
    const [mediaContent, setMediaContent] = useState(state.mediaContent); 
    const [photoGaleryData, setPhotoGaleryData] = useState(state.mediaContent&&state.mediaContent.filter((item)=>item.typeContent==='photo')); 
    const [photoGalery, setPhotoGalery] = useState([]); 
    const [activeTab, setActiveTab] = useState(state.galleryType[0]);
    

    if (mediaContent.length === 0||!mediaContent||mediaContent==='null') {
      axios.get(state.api.getMediaContent)
        .then((response) => {
          if (response.data.mediaContent && Array.isArray(response.data.mediaContent) && response.data.mediaContent.length > 0) {
            setMediaContent(response.data.mediaContent)
            state.mediaContent = mediaContent
            indexedDBConnection.saveData('mediaContent',[...mediaContent]);
            setPhotoGaleryData(
                response.data.mediaContent.filter((item)=>item.typeContent==='photo')
            )
          }
        })
        .catch((error) => {
          console.error('Помилка запиту до сервера:', error);
        });
    }
    useEffect(()=>{
        setPhotoGalery(photoGaleryData.filter((item)=>item.type===activeTab))
    },[activeTab,photoGaleryData])

  return (<>
   
        {moderator?(<br/>):(
        <h1 className={sMenu.h1} 
        style={{textAlign:'center', marginBottom:'40px'}}>
            {lang[langSwitch].photoGallery /*Фото галерея*/}
            </h1>    
            )} 
<div className={s.galeryMenuWriper}>
 <div className={sMenu.tabsContainer}>
       <div className={sMenu.tabs1}> 
{ state.galleryType.map((tab, tabIndex) => (
                <div
                  key={tabIndex}
                  className={activeTab === tab ? `${sMenu.tab} ${sMenu.tabActive}` : sMenu.tab}
                  onClick={() => {
                    setActiveTab(tab);                   
                  }}
                >
                  {tab}
                </div>
              ))}
        </div> 
    </div>
    </div>



      {editStart?(
          <EditPhotoGalery 
            isSmallScreen={isSmallScreen} 
            langSwitch={langSwitch} 
            setEditStart={setEditStart}
            editPhotoGalery={editPhotoGalery}/>
       ):   
    photoGalery.length>0 && photoGalery.map((gallery, index) => { 
       
      return(<div key={index}>
        <PhotoGaleryItem 
          index={index}
          isSmallScreen={isSmallScreen}
          langSwitch={langSwitch}
          gallery={gallery}
          moderator={moderator}
          setEditStart={setEditStart}
          setEditPhotoGalery={setEditPhotoGalery}
          />
      </div>)}
    )
}

    </>);
}

export default PhotoContent;





