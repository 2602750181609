import React, { useState } from 'react';
import axios from 'axios';
import sAdmin from '../adminCompetition/adminCompetition.module.css'
import s from './SportClubs.module.css';
import state from '../../../state/state.js';
import compressImage from '../photo/compressImage';
import { NavLink, useParams } from 'react-router-dom';
import ConfirmButtons from '../adminCompetition/confirmButtons';
import lang from '../../../state/language.js';
import indexedDBConnection from '../../../state/indexedDBConnection.js';


function EditSportClub({isSmallScreen,langSwitch}) {
  const { id } = useParams();//отримуємо id
  const sportClubArray = state.sportClubs.filter((club) => club.id == id);
  //const sportClub =sportClubArray[0] 
const [sportClub, setSportClub] = useState(sportClubArray[0]);//отримуємо С\к з масиву
 const [photo, setPhoto] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [compressedFileURL, setCompressedFileURL] = useState(null);
  const [compressedFile, setCompressedFile] = useState(null);
 const [tempSportClub, setTempSportClub] = useState(sportClub);

  const handleSportClubNameChange = (e) => { setTempSportClub((oldData)=>({...oldData,name:e.target.value.trim()}))};
 const handleSportClubCityChange = (e) => { setTempSportClub((oldData)=>({...oldData,city:e.target.value.trim()}))};
 
  const handleRegionChange = (e) => { setTempSportClub((oldData)=>({...oldData,region:e.target.value.trim()})) };
  
  const region = [...state.region].sort();
 const athletes = state.athletes.filter(item=>item.sportClub===sportClub.name)

////////////////////////////////////////////////////
const handleFileChange = (event) => {//обробка фото 
  const selectedFile = event.target.files[0];
  setTempSportClub((oldData)=>({
    ...oldData,
    logo:state.imgAdress.sportClubLogo+selectedFile.name})) 

    // Викликати функцію стиснення зображення та отримати стиснутий файл
    compressImage(selectedFile, {
      maxSizeMB: 0.3,
      maxWidth: 400,
      maxHeight: 400,
    })
      .then((compressedFile) => {
       
        setCompressedFile(compressedFile)
        // Створити URL для стиснутого файлу
        const compressedFileURL = URL.createObjectURL(compressedFile);

        // Встановити URL стиснутого файлу у стані компонента
        setCompressedFileURL(compressedFileURL);
      })
      .catch((error) => {
        // console.error('Помилка стиснення файлу:', error);
      });
  };

  // отримуємо назву файлу, для видалення старого фото 

  //завантаження на сервер

  function update() {
    if (compressedFileURL) {
      const formData = new FormData();
      formData.append('image', compressedFile);
      axios.post(state.api.uploadSportClubLogo, formData)
        .then(response => {
          // Отримано відповідь від сервера після завантаження зображення        
          if (sportClub.logo!==null){
            const parts = sportClub.logo.split('/');
            const photoName = parts[parts.length - 1];

            function deleteImage( fileName) {

              axios.delete(state.api.deleteSportClubLogo, {
                data: {                
                  fileName: fileName
                }
              })
              .then(response => { // Обробка успішного видалення  
              })
              .catch(error => {// Обробка помилок
              });
            }
            deleteImage( photoName);// Виклик функції з відповідними параметрами
         }
    
          // Після завантаження зображення ви можете виконати PUT-запит для оновлення профілю
          createSportClub();
        })
        .catch(error => {
          // console.error('Помилка під час відправлення зображення:', error);
        });
    } else {
      // Якщо `compressedFileURL` не існує, виконуємо тільки PUT-запит для оновлення профілю
 
      createSportClub();
    }
  }


//////////////////////////////////////////////////////////
  const createSportClub = () => { 
      
    if (!tempSportClub.name) {
      setErrorMessage(
        lang[langSwitch].clubNameCannotBeEmpty /*Назва клубу не може бути порожньою!*/
      );
      return;
    }

    if (!state.userProfile.region) {
      setErrorMessage(
        lang[langSwitch].mustChooseRegionForCreateClub /*Для створення С\К ви повинні вказати вашу приналежність до обласного осередку на вкладці "Редагувати профіль".*/
       );
      return;     
    }

    if (!tempSportClub.city) {
      setErrorMessage(
       lang[langSwitch].indicateYourCity /*Поле "місто" не може бути порожнім!*/ 
      );
      return;
    }
    if (!state.cityNamePattern.test(tempSportClub.city)){
      setErrorMessage(<>
      { lang[langSwitch].wrongCityNameInputFormat/*Неправильний формат вводу назви міста!*/}
       
      <ul style={{textAlign:'left'}}>
        <li> 
        { lang[langSwitch].dotsNotFllowed/*Недопускається ввод крапок;*/} 
          </li> 
        <li>
        { lang[langSwitch].firstLetterMustBeCapitalized/*Перша літера назви повинна бути великою;*/}
          </li>
       <li> 
       { lang[langSwitch].capitalLetterAfterDash/*Після тире обо пробілу повинна стояти велика літера;*/}
        </li>
       <li> 
       { lang[langSwitch].smallLetterBeforeApostrophe/*Після апострофу повинна стояти маленька літера;*/}
        </li>
        <li>
        { lang[langSwitch].notSpacesAroundHyphen/*Навколо дефісу недопускаються пробіли!*/}
          </li>
        </ul> 
        </>);
    
     return;
    }

    function updateSportclubInState() {
      
const index=state.sportClubs.findIndex(club=>club.id==id)
state.sportClubs[index].region=tempSportClub.region
state.sportClubs[index].name=tempSportClub.name
state.sportClubs[index].logo= tempSportClub.logo
state.sportClubs[index].city=tempSportClub.city

  indexedDBConnection.saveData ('sportClubs', state.sportClubs)
    }
    
axios
.post(state.api.updateSportClub, {
  region:tempSportClub.region,
  name: tempSportClub.name,
  creator:state.userProfile.id,
  logo:  tempSportClub.logo,
  city:tempSportClub.city,
  id:id,
    headers: {'Content-Type': 'application/json'}
})
.then((response) => {
athletes.map((item)=>{
////////////////////////////////////////////////////////////

axios
.post(state.api.updateSportClubNameInAthleteProfile, {
   name: tempSportClub.name,
   id:item.id,
    headers: {'Content-Type': 'application/json'}
})
.then((response) => {
  const athleteIndex=state.athletes.findIndex(item2=>item2.id==item.id) 
  state.athletes[athleteIndex].sportClub=tempSportClub.name
})
.catch((error) => {
  setErrorMessage(oldData=>[...oldData, 
    lang[langSwitch].failedUpdateClubInAthleteProfile /*Невдалось оновити назву клубу в профілі спортсмена:*/ +
    state.athleteName(item.id) + error]);
});

////////////////////////////////////////////////////////////////////
}) 
  setSuccessMessage(
    lang[langSwitch].sportClubSuccessfullyUpdated /*Спортклуб успішно оновлено*/
  );
  updateSportclubInState()
   // console.log(response.data)
 window.location.href = '/MySportClubs'
})
.catch((error) => {
  setErrorMessage(
    lang[langSwitch].serverRequestError /*Помилка запиту до сервера: */ + error);
});
};



 ////////////////////////////////////////////////////////////////////////////////////////////
 const [messageText, setMessageText] = useState('');
 const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState(false);
  function editResultConfirm() {

      setMessageText(
        lang[langSwitch].makeChangesInSportClub /*Внести зміни в спортивному клубі?*/
            )
      openConfirmButtonsModal()

  }
  const confirmFunction = (messageText) => {
      if (messageText === 
        lang[langSwitch].makeChangesInSportClub /*Внести зміни в спортивному клубі?*/
      ) {
        update()
      }

  }
  const openConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(true); }
  const closeConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(false); }
  ///////////////////////////////////////////////////////////////////////////////////////////////////

  return (<>
  
<div className={s.headTitle}>
  {!isSmallScreen?(<>
      <h1  className={s.h1}>
      {lang[langSwitch].editSportsClub /*Редагувати спортклуб*/}  
 </h1>
      </>):(<>
       <h1  className={s.h1}>
       {lang[langSwitch].editSportsClub /*Редагувати спортклуб*/}
        </h1>
        <NavLink to="/MySportClubs">
        <button type='button'>
         {lang[langSwitch].sportsClub /*Спортклуб*/} 
          </button>
      </NavLink>
      </>)}
</div>

  {successMessage?
        (<div className={sAdmin.successMessage} onClick={() => { setSuccessMessage('') }}>{successMessage} </div>) :
        errorMessage?(<div className={sAdmin.errorMessage}onClick={() => { setErrorMessage('') }}>{errorMessage} </div>):''}
        
        


  <div className={!isSmallScreen?s.contaner:s.editClubSmall}>
   
    <div className={s.item1}> {/* Ліва колонка */}
    <p>
          <label htmlFor="photo">
            <span className={s.clubLogoDescription}><i><b>
            {lang[langSwitch]. installSportsClubLogo /*Встановіть логотип Спортклубу*/} 
              </b><br/>
           {lang[langSwitch]. sizeForClubLogo /*Для коректного виводу зображення співвідношення сторін у файлі має бути 1/1*/} 
           </i>
          </span>
          </label><br/>
         
            {compressedFileURL && (
              <img src={compressedFileURL} alt="Стигнутий файл" />
            )}<br />
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
            /><br />
          
        </p>

    </div>{/* Закриття Ліва колонки */}



    <div className={s.item2}> {/* Права колонка */}

    <form >
       <p  className={s.createClubText}><b> {sportClub.name} </b> <br/> 
        
        
        <label htmlFor="sportClubName">
          <span className={s.createClubText}>
          {lang[langSwitch].enterNameOfClub /*Введіть нову назву клубу:*/} </span>
        </label>   <br/> 
       
        <input 
          onChange={handleSportClubNameChange}
          type="text"
          id="sportClubName"
          name="sportClubName"
          
        />
         </p>
         <br/> 
         <p>
          <label htmlFor="region" className={s.createClubText}>
           {lang[langSwitch].regionalOffice/*Обласний осередок: */}:  <br/> 
            <b> {tempSportClub.region}</b></label><br />
          <select 
           className= {isSmallScreen?s.regionSelect:null}
          onChange={handleRegionChange} id="region" name="region" >
            <option value="">
            {lang[langSwitch].chooseRegionalOffice/*Оберіть обласний осередок*/}  
              </option>
            {region.map((region) => (
              <option key={region} value={region}>
                {region}
              </option>
            ))}
          </select>
        </p>
        <br/> 
        <p  className={s.createClubText}><b> {sportClub.city} </b> <br/> 
        
        
        <label htmlFor="sportClubCity">
          <span className={s.createClubText}>
          {lang[langSwitch].enterCityYourClub /*Введіть місто роботи вашого клубу:*/}
             </span>
        </label>   <br/> 
       
        <input 
          onChange={handleSportClubCityChange}
          type="text"
          id="sportClubCity"
          name="sportClubCity"
          
        />
         </p>
      </form>


    </div>{/* Закриття Правої колонки */}

 <button type='button'   
 onClick={editResultConfirm}
//  onClick={update}

 >
  {lang[langSwitch].save /*Зберегти*/}
   </button>

    </div>{/* Закриття контейнера */}


    <ConfirmButtons
            isOpen={isConfirmButtonsModalOpen}
            onClose={closeConfirmButtonsModal}
            confirmFunction={confirmFunction}
            messageText={messageText}
            langSwitch={langSwitch}
        />

   </> );
}

export default EditSportClub;
