import React, { useEffect, useState } from "react";
import s from '../adminCompetition.module.css';
import axios from "axios";
import lang from "../../../../state/language";
import state from "../../../../state/state";
import AthleteItem from "../../controlPanel/athleteItem";
import ConfirmButtons from "../confirmButtons";
import indexedDBConnection from "../../../../state/indexedDBConnection";



function JudgeChoice({ setJudgeChoiceWiew,isSmallScreen, langSwitch,competition }) {

let poomsaeOptions

  if(competition.poomsaeOptions&&!poomsaeOptions){
    try{poomsaeOptions=JSON.parse(competition.poomsaeOptions)}
    catch{poomsaeOptions=competition.poomsaeOptions}
    }

console.log(poomsaeOptions)
const coaches=state.athletes.filter(athlete => athlete.judge.includes('1'))
  // Створіть стан для зберігання списку вибраних спортсменів
  const [notCoahes, setNotCoahes] = useState(coaches);
  const [currentJudge, setCurrentJudge] = useState([]);
  const [cortJudge, setCortJudge] = useState([]);
  const [selectedAthletes, setSelectedAthletes] = useState([]);// обрані атлети
  const [errorMessage, setErrorMessage] = useState(''); //повідомлення про помилки та успіх
  const [successMessage, setSuccessMessage] = useState('');
  const [data, setData] = useState('');
   const [cort, setCort] = useState(null);
  const [messageText, setMessageText] = useState('');
  const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState(false);
const [judge, setJudge] = useState(poomsaeOptions.judge);
///////////////////////
const [visibleAthletes, setVisibleAthletes] = useState([]);
const [currentPage, setCurrentPage] = useState(1);
const athletesPerPage = 45;

const [fetching, setFetching] = useState(true);
const [conrtol, setConrtol] = useState(false);
const [conrtol2, setConrtol2] = useState(false);

useEffect(()=>{ //отримуємо масиви суддів вже задіяних та задіяних на активному корті
  const cortJudgeTemp=[]
  const currentJudgeTemp=[]
 judge.forEach((item)=>{
    item.judges.forEach((j)=>{
    if(item.cort!==Number(cort)){      
      currentJudgeTemp.push(j)
    }else{
      cortJudgeTemp.push(j)
    }
    })
  })
  setCortJudge(cortJudgeTemp)
  setCurrentJudge(currentJudgeTemp)
  setConrtol2(true)
},[cort])

const numbers = Array.from({ length: competition.corts?competition.corts:1 }, (_, i) => i + 1);//це масив з номерами кортів для селекта

useEffect(()=>{ //судді задіяні на активному корті додаються до виділених профілів
  if(conrtol2){
  setSelectedAthletes(cortJudge)
  setConrtol2(false)
}
},[cortJudge])

useEffect(()=>{ //видаляєм з списку суддів що задіяні не на активному корті
 if(conrtol) {
  setNotCoahes(coaches)
if (currentJudge.length>0){
  setNotCoahes((prev)=>{
const prevData=[...prev]
const prevDataFiltered = prevData.filter((item) => {
    return !currentJudge.some((j) => item.id == j);
  }); 
   return prevDataFiltered
  }) 
  
}
setConrtol(false)
}

},[currentJudge])

useEffect(()=>{ // додаємо список суддів до масиву що передається в компоненту
  const endIndex = currentPage * athletesPerPage;
  const newVisibleAthletes = notCoahes.slice(0, endIndex);
  setVisibleAthletes(newVisibleAthletes);
  setCurrentPage(currentPage + 1);
},[notCoahes])


useEffect(()=>{
  if(selectedAthletes.length>0){
  setJudge((prev)=>{ 
    const prevData=[...prev]
    console.log('prevData: ',prevData)
      prevData[cort-1].judges=selectedAthletes

      return prevData
     
  })  
  }
},[selectedAthletes])

function handleSelect(e){
  setSelectedAthletes([])
  setCort(e.target.value);
  setConrtol(true)
}


  function saveResultConfirm() {
    if(selectedAthletes.length>0){
    setMessageText(
      lang[langSwitch].appointJudges //Призначити суддів
    )
    openConfirmButtonsModal()
  }else{setErrorMessage(
    lang[langSwitch].notChosenCandidate //Ви не обрали жодного кандидата!
  )}
  }
  const confirmFunction = (messageText) => {
    if (messageText === lang[langSwitch].appointJudges //Призначити суддів
   ) {
    updateCompetitionData()
    }

  }
  const openConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(true); }
  const closeConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(false); }

//////////////////////////////////////////////////////////////////////////


function scrollHandler(e) {
  const scrollHeight = Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight
  );

  if (scrollHeight - (window.scrollY + window.innerHeight) < 100) {
    setFetching(true);
  }
}

useEffect(() => {
  if (fetching&&data.length == 0) {
    const endIndex = currentPage * athletesPerPage;
    const newVisibleAthletes = result.slice(0, endIndex);
    setVisibleAthletes((prevVisibleAthletes) => [ ...newVisibleAthletes]);
    setCurrentPage(currentPage + 1);
    setFetching(false);
  }
}, [fetching]);

useEffect(() => {
  if(data.length == 0){
  document.addEventListener('scroll', scrollHandler);
  document.addEventListener('touchmove', scrollHandler);
}
  return function () {
    document.removeEventListener('scroll', scrollHandler);
    document.removeEventListener('touchmove', scrollHandler);
  }
}, []);



const handleTitleChange = (e) => {
  setData(e.target.value);
  // Якщо дані введені, викликаємо фільтрацію і вивід результатів
  if (e.target.value.trim() !== '') {
       const filteredResults = notCoahes.filter(item => {
      const fullName = `${item.secondName} ${item.personName} ${item.fatherName}`.toLowerCase();
      const searchData = e.target.value.toLowerCase();
      return fullName.includes(searchData);
    });
    setVisibleAthletes(filteredResults);
  } else {
    // Якщо дані не введені, використовуємо весь масив notCoahes
    const endIndex = currentPage * athletesPerPage;
    const newVisibleAthletes = notCoahes.slice(0, endIndex);
    setVisibleAthletes(newVisibleAthletes);
    setCurrentPage(currentPage + 1);
  }
};

//   форма пошуку
let result
data.length>0?
 result = notCoahes.filter(item => {
  const fullName = `${item.secondName} ${item.personName} ${item.fatherName}`.toLowerCase();
  const searchData = data.toLowerCase();  
  return fullName.includes(searchData);
}):result=notCoahes

///////////////////////////////////////////////////////////

  // Функція для додавання або видалення спортсмена зі списку (плитки) під час заявки
  const toggleAthleteSelection = (athlete) => {
    if (selectedAthletes.includes(athlete.id)) {
      // Спортсмен вже вибраний, видаляємо його зі списку
      setSelectedAthletes(selectedAthletes.filter((id) => id !== athlete.id));

    } else {
      // Спортсмен ще не вибраний, додаємо його до списку
      setSelectedAthletes([...selectedAthletes, athlete.id]);
    }
   
  };


  function updateCompetitionData() {
    poomsaeOptions.judge=judge

    axios 
    .post(state.api.appoindCoachesOnCompetition, {
     
      poomsaeOptions:JSON.stringify(poomsaeOptions),
      id:competition.id,
        headers: {'Content-Type': 'application/json'}
    })
    .then((response) => {
     
  
      setSuccessMessage(
        lang[langSwitch].successfullSavedInDB /*Внесені зміни успішно збережено в Базі Даних*/
      );
  
    const indexOfCompetition = state.competition.findIndex((item)=>item.id==competition.id) 
    state.competition[indexOfCompetition].poomsaeOptions=poomsaeOptions
      indexedDBConnection.saveData('competition',state.competition);
      
      setTimeout(function() {
        setJudgeChoiceWiew(false);
              setErrorMessage('');
              setSuccessMessage('');
              setSelectedAthletes([])   
      }, 1000);
      
  
    })
    .catch((error) => {
      setErrorMessage(
        lang[langSwitch].serverRequestError/*Помилка запиту до сервера:*/  + error);
    });
  
  }

  return (<>
    <div className={s.boxTitile1}><br/><br/>
      <div className={s.h1}><b>
      {lang[langSwitch].appointJudge}{/*Призначити суддю*/}
        </b></div>

      {successMessage ?
        (<><br /><br /><div className={s.successMessage} onClick={() => { setSuccessMessage('') }}>
          {successMessage}</div><br /></>) :
        errorMessage ? (<><br /><br /><div className={s.errorMessage} onClick={() => { setErrorMessage('') }}>
          {errorMessage}</div><br /></>) : ''}

          <div className={!isSmallScreen?s.contentTitleItem1:s.td1}>
        <div className={s.title1}>
          <label htmlFor="title"><span><b>
          {lang[langSwitch].startTypingName}{/*Почніть вводити призвище та ім'я*/}
            </b></span> </label><br /><br />
          <input onChange={handleTitleChange} type="text" id="title" name="title" />

        </div>

        <div className={s.title3}><i>

 {lang[langSwitch].chooseCourt}{/*Оберіть корт*/}<br />
        <select  onChange={handleSelect} style={{ marginBottom: '10px', width: '100px'}}>
            <option value=""></option>
            {numbers.map((number) => (
              <option key={number} value={number}>
                {number}
              </option>
            ))}
          </select><br />



        {lang[langSwitch].chosen}{/*Обрано*/}: 
          </i><b> {selectedAthletes.length} </b><i> 
            {lang[langSwitch].ppl}{/*чол*/}.
              </i></div>

        <div className={s.title7}>
        <div className={!isSmallScreen?s.switchers:s.switchersSmall}>


            <button className={!isSmallScreen?s.switch1L:s.switch1LSmall} type='button' onClick={() => { saveResultConfirm(); }}>
            {lang[langSwitch].saveInDB}{/*Зберегти в БД*/}
            </button>


            <button className={s.switch6L} type='button' onClick={() => {
             setJudgeChoiceWiew(false);
              setErrorMessage('');
              setSuccessMessage('');
              setSelectedAthletes([])
            }}>
             {lang[langSwitch].close}{/*Закрити*/}
              </button>

          </div>
        </div>

      </div><br />



      {/* "плитка спортсменів" */}
     {cort?
     <AthleteItem athletes={visibleAthletes} 
        toggleAthleteSelection={toggleAthleteSelection} 
        selectedAthletes={selectedAthletes} />
     :null}
     



      <ConfirmButtons
        isOpen={isConfirmButtonsModalOpen}
        onClose={closeConfirmButtonsModal}
        confirmFunction={confirmFunction}
        messageText={messageText}
        langSwitch={langSwitch}
      />


    </div>

  </>)
}


export default JudgeChoice