import { useEffect, useState } from 'react'
import state from '../../../../state/state'
import s from './adminPoomsae.module.css'
import { IntermediateResultsPrint } from './intermediateResultsPrint'



function AthletesTable({ athletesListData, grid, setErrorMessage, setWinnerAthletes, setWinner,langSwitch,competition}) {
const battleNumbers=grid.battleNumbers
const cort=grid.cort
const battleNumbersForPrint=[]
let roundForPrint

 
    const [round, setRound] = useState(null);
    const [intermediateResults,  setIntermediateResults] = useState(false);
    const [athletesListForprint,  setAthletesListForprint] = useState(null);
    

    const [athletesList, setAthletesList] = useState(athletesListData?athletesListData:[]);
    const [preliminaryTechniqueData, setPreliminaryTechniqueData] = useState([])
    const [preliminaryPresentationData, setPreliminaryPresentationData] = useState([])
    const [preliminaryTechniqueData_2, setPreliminaryTechniqueData_2] = useState([])
    const [preliminaryPresentationData_2, setPreliminaryPresentationData_2] = useState([])

    const [semiFinalAtletes, setSemiFinalAtletes] = useState(athletesListData.length > 8 && athletesListData.length < 20 ? athletesListData : grid.semiFinalAtletes&&Array.isArray( grid.semiFinalAtletes)&& grid.semiFinalAtletes.length>0?grid.semiFinalAtletes: [])
    const [semiFinalTechniqueData, setSemiFinalTechniqueData] = useState([])
    const [semiFinalPresentationData, setSemiFinalPresentationData] = useState([])
    const [semiFinalTechniqueData_2, setSemiFinalTechniqueData_2] = useState([])
    const [semiFinalPresentationData_2, setSemiFinalPresentationData_2] = useState([])

    const [finalAtletes, setFinalAtletes] = useState(athletesListData.length < 9 ? athletesListData : grid.finalAtletes&&Array.isArray(grid.finalAtletes)&&grid.finalAtletes.length>0?grid.finalAtletes:[])
    const [finalTechniqueData, setFinalTechniqueData] = useState([])
    const [finalPresentationData, setFinalPresentationData] = useState([])
    const [finalTechniqueData_2, setFinalTechniqueData_2] = useState([])
    const [finalPresentationData_2, setFinalPresentationData_2] = useState([])

    const [control, setControl] = useState(false)
    const [control2, setControl2] = useState(false)
     const [control3, setControl3] = useState(false)
    const [wiewPreliminary, setWiewPreliminary] = useState(false)
    const [wiewSemiFinal, setWiewSemiFinal] = useState(false)
    const [wiewFinal, setWiewFinal] = useState(false)




    useEffect(() => {grid.athletes = athletesList}, [athletesList]);
   
   useEffect(() => {
   
        setAthletesList(athletesListData)
        
    if (!control) {
        setSemiFinalAtletes(() => {

            if (grid.semiFinalAtletes && Array.isArray(grid.semiFinalAtletes) && grid.semiFinalAtletes.length > 0) {
                return grid.semiFinalAtletes
            }

            else if (athletesListData.length > 8 && athletesListData.length < 20) {
                return athletesListData
            } else { return [] }
        }

        );
    }
}, [athletesListData, grid.semiFinalAtletes, athletesListData]);

useEffect(() => {
   if(!control2){
        setFinalAtletes(()=>{

            if(grid.finalAtletes && Array.isArray(grid.finalAtletes) && grid.finalAtletes.length > 0){
                return grid.finalAtletes
            }

           else if(athletesListData.length < 9){
                return athletesListData
            }
            
            else{ return []}
        }

        );
  }
}, [athletesListData, grid.finalAtletes]);

    let athletesList1, athletesList2, athletesList3, count = 0;

    if (round === 'semiFinal') {
        if (athletesList.length > 19) {
            if (athletesList.every(athlete => athlete.preliminaryTotal !== null)) {
                athletesList1 = JSON.parse(JSON.stringify(athletesList)).sort((a, b) => b.preliminaryTotal - a.preliminaryTotal);
                athletesList1.forEach((item, index) => {
                    item.place = index + 1;
                });
               
                if (!control) {
                     finalResultForAthletes(athletesList1,'preliminaryResult','preliminaryResult_2','preliminaryTotal')
                    setSemiFinalAtletes(athletesList1.slice(0, 19));
                    setControl(true);
                    setAthletesListForprint(athletesList1.slice(0, 19))
                    
                    
                }
            }
        }
    }

    if (round === 'final') {
        if (semiFinalAtletes.length > 8) {
            if (semiFinalAtletes.every(athlete => athlete.semiFinalTotal !== null)) {
                athletesList2 = JSON.parse(JSON.stringify(semiFinalAtletes)).sort((a, b) => b.semiFinalTotal - a.semiFinalTotal);
                athletesList2.forEach((item, index) => {
                    item.place = index + 1;
                });
                
                if (!control2) {
                    finalResultForAthletes(athletesList2,'semiFinalResult','semiFinalResult_2','semiFinalTotal')
                    setFinalAtletes(athletesList2.slice(0, 8));
                    setControl2(true);
                    setAthletesListForprint(athletesList2.slice(0, 8))
                   
                }
            }
        }
    }
 
          if (round === 'winner') {
           
        if (finalAtletes.length > 0) {
           
            if (finalAtletes.every(athlete => athlete.finalTotal !== null)) {
                athletesList3 = JSON.parse(JSON.stringify(finalAtletes)).sort((a, b) => b.finalTotal - a.finalTotal);
                athletesList3.forEach((item, index) => {
                    item.place = index + 1;                                       
                });                

                if (!control3) {
                    finalResultForAthletes(athletesList3,'finalResults','finalResults_2', 'finalTotal')
                    setWinnerAthletes(athletesList3.slice(0, 4));
                    setWinner(athletesList3[0])
                    setControl3(true);
                    grid.athletes=athletesList
                    grid.semiFinalAtletes=semiFinalAtletes
                    grid.finalAtletes=finalAtletes
                }
            }
        }
    }
    function finalResultForAthletes(array, data, data_2, data_3) {
        setAthletesList((prevData) => {
          // Створюємо новий масив на основі prevData, змінюючи потрібні елементи
        
          return prevData.map((athlete) => {
           
            // Знаходимо відповідний елемент в масиві array за gridId
            const foundItem = array.find((item) => item.gridId === athlete.gridId);
           
            // Якщо знайдений елемент існує, оновлюємо його дані
            if (foundItem) {
              return {
                ...athlete,
                place: foundItem.place,
                [data]:foundItem[data], 
                [data_2]:foundItem[data_2],
                [data_3]:foundItem[data_3],
                fullScore:foundItem.fullScore
              };
            }
      
            // Якщо знайдений елемент не існує, повертаємо елемент без змін
            return athlete;
          });
        });
       
          
      }
      
  
    function resultTable(technique, presentation, allScore, technique_2, presentation_2, allScore_2, midle) {
        return (<>
            <div className={s.resultTableBox}>
                <table className={s.resultTable}>
                    <thead>
                        <tr>
                            <td colSpan='3'>Перший комплекс</td>
                            <td colSpan='3'>Другий комплекс</td>
                            <td rowSpan='2'>Серед.</td>
                        </tr>
                        <tr>
                            <td>Техніка</td>
                            <td>Презент.</td>
                            <td>Загал.</td>
                            <td>Техніка</td>
                            <td>Презент.</td>
                            <td>Загал.</td>
                             
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{technique ? technique : (<>&nbsp;</>)}</td>
                            <td>{presentation ? presentation : (<>&nbsp;</>)}</td>
                            <td>{allScore ? allScore : (<>&nbsp;</>)}</td>
                            <td>{technique_2 ? technique_2 : (<>&nbsp;</>)}</td>
                            <td>{presentation_2 ? presentation_2 : (<>&nbsp;</>)}</td>
                            <td>{allScore_2 ? allScore_2 : (<>&nbsp;</>)}</td>
                            <td>{midle ? midle : (<>&nbsp;</>)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>)
    }
    function inputTable(setFunc1, setFunc2,setFunc3, setFunc4, gridId, value1, value2, value3, value4) {
        function update(e, func) {
            func((prevState) => {
                const index = prevState.findIndex((item) => item.gridId === gridId);
                const newValue = Number(e.target.value);

                if (index !== -1) {
                    // Копіюємо попередній стан і змінюємо значення результату для знайденого індексу
                    const updatedState = [...prevState];
                    updatedState[index] = { ...updatedState[index], result: newValue };
                    return updatedState;
                } else {
                    // Додаємо новий об'єкт, якщо не знайдено
                    return [
                        ...prevState,
                        { gridId: gridId, result: newValue }
                    ];
                }
            });
        }
        return (
            <>
                <div className={s.resultTableBox}>
                    <table className={s.inputTable}>
                        <thead>
                            <tr>
                                <td>Техніка 1</td>
                                <td>Презент. 1</td>
                                <td>Техніка 2</td>
                                <td>Презент. 2</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <input
                                        type="number"
                                        onChange={(e) => { update(e, setFunc1) }}
                                        min="0"
                                        placeholder={value1}
                                    />

                                </td>
                                <td>
                                    <input
                                        type="number"
                                        onChange={(e) => { update(e, setFunc2) }}
                                        min="0"
                                        placeholder={value2}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        onChange={(e) => { update(e, setFunc3) }}
                                        min="0"
                                        placeholder={value3}
                                    />

                                </td>
                                <td>
                                    <input
                                        type="number"
                                        onChange={(e) => { update(e, setFunc4) }}
                                        min="0"
                                        placeholder={value4}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </>
        );
    }
    function buttonFunction(text, round) {
        return (<div className={s.noPrint}>
            {round === "preliminary" && (<>
                {wiewPreliminary ? (<>
                    <button onClick={() => { setWiewPreliminary(false);setControl(false); setRound('preliminary'); setSemiFinalAtletes([]); setErrorMessage(null);  }}>
                        Змінити рахунки
                    </button>
                    <button onClick={() => {openIntermediateResultsModal() }}>
                        Друк
                    </button>
                </>) : (<>
                    <button onClick={() => { addScores(round, setAthletesList, preliminaryTechniqueData, preliminaryPresentationData,preliminaryTechniqueData_2, preliminaryPresentationData_2) }}>
                        {text}
                    </button>
                </>)} 
            </>)}
            {round === "semiFinal" && (<>
                {wiewSemiFinal ? (<>
                    <button onClick={() => { setWiewSemiFinal(false);setControl2(false); setRound('semiFinal');setFinalAtletes([]); setErrorMessage(null); }}>
                        Змінити рахунки
                    </button>
                    <button onClick={() => {openIntermediateResultsModal()}}>
                        Друк
                    </button>
                </>) : (<>
                    <button onClick={() => { addScores(round, setSemiFinalAtletes, semiFinalTechniqueData, semiFinalPresentationData, semiFinalTechniqueData_2, semiFinalPresentationData_2) }}>
                        {text}
                    </button>
                </>)}

            </>)}
            {round === "final" && (<>
                {wiewFinal ? (<>
                    <button onClick={() => { setWiewFinal(false);setControl3(false); setErrorMessage(null); }}>
                        Змінити рахунки
                    </button>
                </>) : (<>
                    <button onClick={() => { addScores(round,setFinalAtletes, finalTechniqueData, finalPresentationData, finalTechniqueData_2, finalPresentationData_2 )}}>
                        {text}
                    </button>
                </>)}

            </>)}
        </div>)
    }
    function addScores(round, func, tech, present, tech_2, present_2) {

        // Оновлюємо список атлетів
        func((prevAthletesList) => {
            const updatedAthletesList = prevAthletesList.map((item) => {
                const techniqueIndex = tech.findIndex((t) => t.gridId === item.gridId);
                const presentationIndex = present.findIndex((p) => p.gridId === item.gridId);
                const techniqueIndex_2 = tech_2.findIndex((t) => t.gridId === item.gridId);
                const presentationIndex_2 = present_2.findIndex((p) => p.gridId === item.gridId);
        
                if (techniqueIndex !== -1 && presentationIndex !== -1) {
                    const technique =tech[techniqueIndex]?.result || 0;
                    const presentation =present[presentationIndex]?.result || 0;
                    const technique_2 = tech_2[techniqueIndex_2]?.result || null;
                    const presentation_2 =present_2[presentationIndex_2]?.result || null;
        
                    const count = technique_2 && presentation_2 
                        ? Number((((technique + technique_2 ) / 2) + ((presentation + presentation_2) / 2)).toFixed(2)) 
                        : Number((technique + presentation).toFixed(2));
        
                    const fullScore = item.fullScore ? item.fullScore + count : count; // Додаємо перевірку на ініціалізацію fullScore

                    // Оновлюємо результат атлета в залежності від раунду
                    if (round === 'preliminary') {
                        return {
                            ...item,
                            preliminaryResult: {
                                technique,
                                presentation,
                                allScore:technique!==null && technique!==undefined && presentation!==null &&  presentation!==undefined? Number((technique + presentation).toFixed(2)):null
                            },
                            preliminaryResult_2: {
                                technique: technique_2,
                                presentation: presentation_2,
                                // allScore: count
                                allScore: technique_2!==null && technique_2!== undefined && presentation_2!==null &&presentation_2!==undefined ? Number((technique_2 + presentation_2).toFixed(2)) : null
                            },
                            preliminaryTotal: count,
                            fullScore: count
                        };
                    }
        
                    if (round === 'semiFinal') {
                        return {
                            ...item,
                            semiFinalResult: {
                                technique,
                                presentation,
                                allScore:technique!==null && technique!==undefined && presentation!==null &&  presentation!==undefined? Number((technique + presentation).toFixed(2)):null
                            },
                            semiFinalResult_2: {
                                technique: technique_2,
                                presentation: presentation_2,
                                allScore: technique_2!==null && technique_2!== undefined && presentation_2!==null &&presentation_2!==undefined ? Number((technique_2 + presentation_2).toFixed(2)) : null
                            },
                            semiFinalTotal: count,
                            fullScore: fullScore
                        };
                    }
        
                    if (round === 'final') {
                        return {
                            ...item,
                            finalResults: {
                                technique,
                                presentation,
                                allScore:technique!==null && technique!==undefined && presentation!==null &&  presentation!==undefined? Number((technique + presentation).toFixed(2)):null
                            },
                            finalResults_2: {
                                technique: technique_2,
                                presentation: presentation_2,
                                allScore: technique_2!==null && technique_2!== undefined && presentation_2!==null &&presentation_2!==undefined ? Number((technique_2 + presentation_2).toFixed(2)) : null
                            },
                            finalTotal: count,
                            fullScore: fullScore
                        };
                    }
                } else {
                    setErrorMessage('Ви прописали не усі рахунки відбіркових виступів.');
                    return item; // Повертаємо оригінальний елемент, якщо немає результатів
                }
            });
        
            return updatedAthletesList; // Повертаємо оновлений список
        });
        
     
        if (round === 'preliminary') {
            setRound('semiFinal')
            setPreliminaryTechniqueData([]);
            setPreliminaryPresentationData([]);
            setWiewPreliminary(true);
        }
        if (round === 'semiFinal') {
            setRound('final')
            setSemiFinalTechniqueData([]);
            setSemiFinalPresentationData([]);
            setWiewSemiFinal(true);
        }
        if (round === 'final') {
            setRound('winner')
            setFinalTechniqueData([]);
            setFinalPresentationData([]);
            setWiewFinal(true);
        }
    }
       // Модальне вікно з сітками

       const openIntermediateResultsModal = () => { setIntermediateResults(true); }
       const closeIntermediateResultsModal = () => { setIntermediateResults(false); }
       ////////////////////////////////////////////////////////////////////////////////

return (<>

<tbody className={s.tBody}>
        
        <tr>
        {/* відбірковий тур */}
        {athletesListData.length > 19 ? (<>
            <td style={{ width: '33%' }}><table>
                <tbody>
                <tr><td>
                {athletesList.map((item, index) => {
              
                    if (item.teamPoomsae && Array.isArray(item.teamPoomsae)) { 
                        return (
                            <div className={index === 0 ? s.tableFirstItem : s.tableItem} key={item.gridId+index}>
                                <div className={s.battleNum}>{cort}.{battleNumbers[count]}</div>   
                                 <div className={s.invisible}> {count++}</div>
                                <div>
                                    <div className={s.athleteDiv} >
                                        {state.poomsaeTeam(item.teamPoomsae)}
                                    </div>
                                    <div className={s.teamDiv}>{state.regionShort(item.team)}</div>
                                </div>

                                {!wiewPreliminary ? (<>
                                    {inputTable(
                                        setPreliminaryTechniqueData, 
                                        setPreliminaryPresentationData, 
                                        setPreliminaryTechniqueData_2, 
                                        setPreliminaryPresentationData_2, 
                                        item.gridId,
                                        item.preliminaryResult.technique, 
                                        item.preliminaryResult.presentation, 
                                        item.preliminaryResult_2.technique, 
                                        item.preliminaryResult_2.presentation 
                                        )}
                                </>) : (<>
                                    {resultTable(
                                        item.preliminaryResult.technique, 
                                        item.preliminaryResult.presentation, 
                                        item.preliminaryResult.allScore,
                                        item.preliminaryResult_2.technique, 
                                        item.preliminaryResult_2.presentation, 
                                        item.preliminaryResult_2.allScore,
                                         item.preliminaryTotal
                                        )}
                                </>)}
                            </div>
                       )
                    } else {
                        return (
                            <div className={index === 0 ? s.tableFirstItem : s.tableItem} key={item.gridId+item.id}>
                                <div className={s.battleNum}>{cort}.{battleNumbers[count]}</div>
                                <div className={s.invisible}> {count++}</div>
                                <div className={s.athleteDivBox}>
                                    <div className={s.athleteDiv} > {state.athleteName(item.id)}</div>
                                    <div className={s.teamDiv}>{state.regionShort(item.team)}</div>

                                </div>

                                {!wiewPreliminary ? (<>
                                    {inputTable(
                                        setPreliminaryTechniqueData, 
                                        setPreliminaryPresentationData, 
                                        setPreliminaryTechniqueData_2, 
                                        setPreliminaryPresentationData_2, 
                                        item.gridId,
                                        item.preliminaryResult.technique, 
                                        item.preliminaryResult.presentation, 
                                        item.preliminaryResult_2.technique, 
                                        item.preliminaryResult_2.presentation 
                                        )}

                                </>) : (<>
                                    {resultTable(
                                        item.preliminaryResult.technique, 
                                        item.preliminaryResult.presentation, 
                                        item.preliminaryResult.allScore,
                                        item.preliminaryResult_2.technique, 
                                        item.preliminaryResult_2.presentation, 
                                        item.preliminaryResult_2.allScore,
                                        item.preliminaryTotal
                                        )}
                                </>)}
                            </div>
                        )
                    }

                })}
              
                {buttonFunction('Рахунки відбіркового туру', 'preliminary')}
                </td></tr>
                </tbody>
                </table>
            </td>
        </>) : null}

        {/* півфінал */}
      
            {semiFinalAtletes.length > 0 ? (<>
 <td style={{ width: athletesList.length > 19 ? '33%' : '50%' }}> 
 <table>
    <tbody>
    <tr><td>
                {semiFinalAtletes.map((item, index) => {              
                    if (item.teamPoomsae && Array.isArray(item.teamPoomsae)) {
                        return (
                            <div className={index === 0 ? s.tableFirstItem : s.tableItem} key={item.gridId+index}>
                                <div className={s.battleNum}>{cort}.{battleNumbers[count]}</div>
                                <div className={s.invisible}>{roundForPrint='Півфінал'}{battleNumbersForPrint.push(battleNumbers[count])}  {count++}</div>
                                <div>
                                    <div className={s.athleteDiv} >
                                        {state.poomsaeTeam(item.teamPoomsae)}
                                    </div>

                                    <div className={s.teamDiv}>{state.regionShort(item.team)}</div>

                                </div>
                                {!wiewSemiFinal ? (<>
                                    {inputTable(
                                        setSemiFinalTechniqueData, 
                                        setSemiFinalPresentationData, 
                                        setSemiFinalTechniqueData_2, 
                                        setSemiFinalPresentationData_2,
                                        item.gridId,
                                        item.semiFinalResult.technique, 
                                        item.semiFinalResult.presentation, 
                                         item.semiFinalResult_2.technique, 
                                        item.semiFinalResult_2.presentation, 
                                        )}
                                </>) : (<>
                                    {resultTable(
                                        item.semiFinalResult.technique, 
                                        item.semiFinalResult.presentation, 
                                        item.semiFinalResult.allScore,
                                        item.semiFinalResult_2.technique, 
                                        item.semiFinalResult_2.presentation, 
                                        item.semiFinalResult_2.allScore,
                                        item.semiFinalTotal
                                        )}
                                </>)}
                            </div>

                       )
                    } else {
                        return (
                            <div className={index === 0 ? s.tableFirstItem : s.tableItem} key={item.gridId+item.id}>
                                <div className={s.battleNum}>{cort}.{battleNumbers[count]}</div>
                                <div className={s.invisible}>{roundForPrint='Півфінал'}{battleNumbersForPrint.push(battleNumbers[count])}  {count++}</div>
                                <div className={s.athleteDivBox}>
                                    <div className={s.athleteDiv}> {state.athleteName(item.id)}</div>

                                    <div className={s.teamDiv}>{state.regionShort(item.team)}</div>

                                </div>

                                {!wiewSemiFinal ? (<>
                                    {inputTable(
                                        setSemiFinalTechniqueData, 
                                        setSemiFinalPresentationData, 
                                        setSemiFinalTechniqueData_2, 
                                        setSemiFinalPresentationData_2,
                                        item.gridId,
                                        item.semiFinalResult.technique, 
                                        item.semiFinalResult.presentation, 
                                         item.semiFinalResult_2.technique, 
                                        item.semiFinalResult_2.presentation, 
                                        )}
                                </>) : (<>
                                    {resultTable(
                                        item.semiFinalResult.technique, 
                                        item.semiFinalResult.presentation, 
                                        item.semiFinalResult.allScore,
                                        item.semiFinalResult_2.technique, 
                                        item.semiFinalResult_2.presentation, 
                                        item.semiFinalResult_2.allScore,
                                        item.semiFinalTotal
                                        )}
                                </>)}
                            </div>
                        )
                    }

                })}
{buttonFunction('Рахунки Півфіналу', 'semiFinal')} 
         </td></tr>
         </tbody>
         </table>
        </td>
            </>) : (<>
            {/* порожній масив півфіналу */}
                {athletesList.length > 19 ? (<>
                    <td style={{ width: athletesList.length > 19 ? '33%' : '50%' }}> 
                    <table>
                        <tbody>
                        <tr><td>
                    {Array(19).fill(null).map((item, index) => {                       
                        return (
                            <div className={index === 0 ? s.tableFirstItem : s.tableItem} key={index}>
                                <div className={s.battleNum}>{cort}.{battleNumbers[count]}</div>
                                <div className={s.invisible}> {count++}</div>
                                <div>
                                    <div className={s.athleteDiv} >

                                    </div>

                                    <div className={s.teamDiv}></div>

                                </div>
                            </div>
                        )
                    })}            
         </td></tr>
         </tbody>
         </table>
         </td>
                </>) : null}
    
            </>)}
           

        {/* фінал */}
       
            
            {finalAtletes.length>0?(<>
                <td style={{ width: athletesList.length > 19 ? '33%' : '50%' }}> 
                <table>
                     <tbody>
                    <tr><td>
                {finalAtletes.map((item, index) => {                 
                    if (item.teamPoomsae && Array.isArray(item.teamPoomsae)) {
                        return (
                            <div className={index === 0 ? s.tableFirstItem : s.tableItem} key={item.gridId+index}>
                                <div className={s.battleNum}>{cort}.{battleNumbers[count]}</div>
                                <div className={s.invisible}>{roundForPrint='Фінал'}{battleNumbersForPrint.push(battleNumbers[count])}  {count++}</div>
                                <div>
                                    <div className={s.athleteDiv} >
                                        {state.poomsaeTeam(item.teamPoomsae)}
                                    </div>
                                    <div className={s.teamDiv}>{state.regionShort(item.team)}</div>
                                </div>

                                {!wiewFinal ? (<>
                                    {inputTable(
                                        setFinalTechniqueData, 
                                        setFinalPresentationData, 
                                        setFinalTechniqueData_2, 
                                        setFinalPresentationData_2, 
                                        item.gridId,
                                        item.finalResults.technique, 
                                        item.finalResults.presentation, 
                                        item.finalResults_2.technique, 
                                        item.finalResults_2.presentation, 
                                        )}
                                </>) : (<>
                                    {resultTable(
                                        item.finalResults.technique, 
                                        item.finalResults.presentation, 
                                        item.finalResults.allScore,
                                        item.finalResults_2.technique, 
                                        item.finalResults_2.presentation, 
                                        item.finalResults_2.allScore,
                                         item.finalTotal
                                        )}
                                </>)}
                            </div>                      
                        )
                    } else {
                        return (
                            <div className={index === 0 ? s.tableFirstItem : s.tableItem} key={item.gridId+item.id}>
                                <div className={s.battleNum}>{cort}.{battleNumbers[count]}</div>
                                <div className={s.invisible}>{roundForPrint='Фінал'}{battleNumbersForPrint.push(battleNumbers[count])}  {count++}</div>
                                <div className={s.athleteDivBox}>
                                    <div className={s.athleteDiv} > {state.athleteName(item.id)}</div>
                                    <div className={s.teamDiv}>{state.regionShort(item.team)}</div>
                                </div>
                                {!wiewFinal ? (<>
                                    {inputTable(
                                        setFinalTechniqueData, 
                                        setFinalPresentationData, 
                                        setFinalTechniqueData_2, 
                                        setFinalPresentationData_2, 
                                        item.gridId, 
                                        item.finalResults.technique, 
                                        item.finalResults.presentation, 
                                        item.finalResults_2.technique, 
                                        item.finalResults_2.presentation, )}
                                </>) : (<>
                                    {resultTable(
                                       item.finalResults.technique, 
                                       item.finalResults.presentation, 
                                       item.finalResults.allScore,
                                       item.finalResults_2.technique, 
                                       item.finalResults_2.presentation, 
                                       item.finalResults_2.allScore,
                                       item.finalTotal
                                       )}
                                </>)}
                            </div>
                        )
                    }
                })}
               
                {buttonFunction('Рахунки Фіналу', 'final')}
                </td></tr>
                </tbody>
                </table>
                 </td>
                </>):(<>
                {/* Порожній масив фіналу */}
                  
                    <td style={{ width: athletesList.length > 19 ? '33%' : '50%' }}> 
                    <table>
                         <tbody>
                        <tr><td>
                    {Array(8).fill(null).map((item, index) => {
                     
                        return (
                            <div className={index === 0 ? s.tableFirstItem : s.tableItem} key={index}>
                                <div className={s.battleNum}>{cort}.{battleNumbers[count]}</div>
                                <div className={s.invisible}> {count++}</div>
                                <div>
                                    <div className={s.athleteDiv} >

                                    </div>

                                    <div className={s.teamDiv}></div>

                                </div>
                            </div>
                        )
                    })}
              
         </td></tr>
         </tbody>
         </table>
         </td>
              
                </>)}
            
                </tr>

</tbody>
{athletesListForprint?(<>
<IntermediateResultsPrint
            langSwitch={langSwitch}          
            isOpen={intermediateResults}
            onClose={closeIntermediateResultsModal} 
            athletesList={athletesListForprint}          
            cort={cort}
            battleNumbers={battleNumbersForPrint}
            category={grid.category}
            competition={competition}
            roundForPrint={roundForPrint}
        />
</>):null}        
    </>)
}

export default AthletesTable