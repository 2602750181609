import lang from '../../../../state/language'
import state from '../../../../state/state'
import s from '../result.module.css'


function ResultByCategoryesCutOffSystem({ competition }) {

    if (!competition.results) { return }
    let resultGrids

    try { resultGrids = JSON.parse(competition.results) }
    catch { resultGrids = competition.results }



    function athleteData(array) {
        array.sort((a, b) => a.place - b.place)
        return array.map((item) => {
            //для пультів
            if ((item.judgeScore && item.judgeScore.finalScore && Array.isArray(item.judgeScore.finalScore) && item.judgeScore.finalScore.length > 0)||
            (item.judgeScore && item.judgeScore.semiFinalScore && Array.isArray(item.judgeScore.semiFinalScore) && item.judgeScore.semiFinalScore.length > 0)||
        (item.judgeScore && item.judgeScore.preliminaryScore && Array.isArray(item.judgeScore.preliminaryScore) && item.judgeScore.preliminaryScore.length > 0)) {

                return (<>

                    <tr key={item.gridId} className={s.bottomBorder2}>
                        <td >{item.place}</td>

                        {/* Команди */}
                        {item.teamPoomsae && Array.isArray(item.teamPoomsae) && item.teamPoomsae.length > 0 ? (<>
                            <td colSpan='4'>
                                <table className={s.innerTable} >
                                    {item.teamPoomsae.map((teamItem) => {
                                        return (<>
                                            <tr>
                                                <td style={{ width: '170px' }}>&nbsp;{state.myCoachFull(teamItem.id)}</td>
                                                <td style={{ width: '80px', textAlign: 'center' }}>{state.regionShort(teamItem.region)}/ <br />{teamItem.sportClub}
                                                    {teamItem.secondTeam ? (<>/<br />{teamItem.secondTeam}</>) : null}</td>
                                                <td style={{ width: '80px', textAlign: 'center' }}> {state.myCoach(teamItem.coach)}/ <br />{state.myCoach(teamItem.coach2)}</td>
                                                <td style={{ width: '77px', textAlign: 'center' }}>{teamItem.fst}</td>
                                            </tr>
                                        </>)
                                    })}
                                </table>
                            </td>

                        </>) : (<>{/* спортсмени */}
                            <td>&nbsp;{state.myCoachFull(item.id)}</td>
                            <td>{state.regionShort(item.region)}/ <br />{item.sportClub}
                                {item.secondTeam ? (<>/<br />{item.secondTeam}</>) : null}</td>
                            <td> {state.myCoach(item.coach)}/ <br />{state.myCoach(item.coach2)}</td>
                            <td>{item.fst}</td>
                        </>)}


                        <td >{(Number(item.preliminaryTotal) + Number(item.semiFinalTotal) + Number(item.finalTotal)).toFixed(2)}</td>

                        <td colSpan='7' style={{ padding: '0px', border: 'none' }}>
                            <table className={s.innerTable} >
                                {/* Відбірковий тур */}
                                {item.judgeScore && item.judgeScore.preliminaryScore && Array.isArray(item.judgeScore.preliminaryScore) && item.judgeScore.preliminaryScore.length > 0 ? (<>


                                    {item.judgeScore.preliminaryScore.map((score, index) => {

                                        return (<>
                                            {index === 0 ? (<>

                                                <tr>
                                                    <td style={{ width: '65px' }} rowSpan={item.judgeScore.preliminaryScore.length * 2}>Відбірковий <br /> {item.preliminaryTotal}</td>
                                                    <td style={{ width: '67px' }} rowSpan='2'>{state.myCoach(score.judge)}</td>{/* Суддя */}
                                                    <td style={{ width: '35px' }}>1</td>{/* Пумсе */}
                                                    <td style={{ width: '35px' }}>{score.technique1}</td>{/*Техніка */}
                                                    <td style={{ width: '35px' }}>{score.presentation1}</td>{/*Презентація */}
                                                    <td style={{ width: '35px' }}>{score.result1}</td>{/*Загальна */}
                                                    <td style={{ width: '35px' }} rowSpan='2'>{score.total}</td>{/*Середня */}
                                                </tr>
                                                <tr>
                                                    <td style={{ width: '35px' }}>2</td>{/* Пумсе */}
                                                    <td style={{ width: '35px' }}>{score.technique2}</td>{/*Техніка */}
                                                    <td style={{ width: '35px' }}>{score.presentation2}</td>{/*Презентація */}
                                                    <td style={{ width: '35px' }}>{score.result2}</td>{/*Загальна */}
                                                </tr>


                                            </>) : (<>
                                                <tr>
                                                    <td style={{ width: '67px' }} rowSpan='2'>{state.myCoach(score.judge)}</td>{/* Суддя */}
                                                    <td style={{ width: '35px' }}>1</td>{/* Пумсе */}
                                                    <td style={{ width: '35px' }}>{score.technique1}</td>{/*Техніка */}
                                                    <td style={{ width: '35px' }}>{score.presentation1}</td>{/*Презентація */}
                                                    <td style={{ width: '35px' }}>{score.result1}</td>{/*Загальна */}
                                                    <td style={{ width: '35px' }} rowSpan='2'>{score.total}</td>{/*Середня */}
                                                </tr>
                                                <tr>
                                                    <td style={{ width: '35px' }}>2</td>{/* Пумсе */}
                                                    <td style={{ width: '35px' }}>{score.technique2}</td>{/*Техніка */}
                                                    <td style={{ width: '35px' }}>{score.presentation2}</td>{/*Презентація */}
                                                    <td style={{ width: '35px' }}>{score.result2}</td>{/*Загальна */}
                                                </tr>
                                            </>)}
                                        </>)
                                    })}


                                </>) : null}

                                {/* Півфінал */}
                                {item.judgeScore && item.judgeScore.semiFinalScore && Array.isArray(item.judgeScore.semiFinalScore) && item.judgeScore.semiFinalScore.length > 0 ? (<>


                                    {item.judgeScore.semiFinalScore.map((score, index) => {

                                        return (<>
                                            {index === 0 ? (<>

                                                <tr>
                                                    <td style={{ width: '65px' }} rowSpan={item.judgeScore.semiFinalScore.length * 2}>Півфінал <br /> {item.semiFinalTotal}</td>
                                                    <td style={{ width: '67px' }} rowSpan='2'>{state.myCoach(score.judge)}</td>{/* Суддя */}
                                                    <td style={{ width: '35px' }}>1</td>{/* Пумсе */}
                                                    <td style={{ width: '35px' }}>{score.technique1}</td>{/*Техніка */}
                                                    <td style={{ width: '35px' }}>{score.presentation1}</td>{/*Презентація */}
                                                    <td style={{ width: '35px' }}>{score.result1}</td>{/*Загальна */}
                                                    <td style={{ width: '35px' }} rowSpan='2'>{score.total}</td>{/*Середня */}
                                                </tr>
                                                <tr>
                                                    <td style={{ width: '35px' }}>2</td>{/* Пумсе */}
                                                    <td style={{ width: '35px' }}>{score.technique2}</td>{/*Техніка */}
                                                    <td style={{ width: '35px' }}>{score.presentation2}</td>{/*Презентація */}
                                                    <td style={{ width: '35px' }}>{score.result2}</td>{/*Загальна */}
                                                </tr>


                                            </>) : (<>
                                                <tr>
                                                    <td style={{ width: '67px' }} rowSpan='2'>{state.myCoach(score.judge)}</td>{/* Суддя */}
                                                    <td style={{ width: '35px' }}>1</td>{/* Пумсе */}
                                                    <td style={{ width: '35px' }}>{score.technique1}</td>{/*Техніка */}
                                                    <td style={{ width: '35px' }}>{score.presentation1}</td>{/*Презентація */}
                                                    <td style={{ width: '35px' }}>{score.result1}</td>{/*Загальна */}
                                                    <td style={{ width: '35px' }} rowSpan='2'>{score.total}</td>{/*Середня */}
                                                </tr>
                                                <tr>
                                                    <td style={{ width: '35px' }}>2</td>{/* Пумсе */}
                                                    <td style={{ width: '35px' }}>{score.technique2}</td>{/*Техніка */}
                                                    <td style={{ width: '35px' }}>{score.presentation2}</td>{/*Презентація */}
                                                    <td style={{ width: '35px' }}>{score.result2}</td>{/*Загальна */}
                                                </tr>
                                            </>)}
                                        </>)
                                    })}


                                </>) : null}

                                {/* Фінал */}
                                {item.judgeScore && item.judgeScore.finalScore && Array.isArray(item.judgeScore.finalScore) && item.judgeScore.finalScore.length > 0 ? (<>


                                    {item.judgeScore.finalScore.map((score, index) => {

                                        return (<>
                                            {index === 0 ? (<>

                                                <tr>
                                                    <td style={{ width: '65px' }} rowSpan={item.judgeScore.finalScore.length * 2}>Фінал <br /> {item.finalTotal}</td>
                                                    <td style={{ width: '67px' }} rowSpan='2'>{state.myCoach(score.judge)}</td>{/* Суддя */}
                                                    <td style={{ width: '35px' }}>1</td>{/* Пумсе */}
                                                    <td style={{ width: '35px' }}>{score.technique1}</td>{/*Техніка */}
                                                    <td style={{ width: '35px' }}>{score.presentation1}</td>{/*Презентація */}
                                                    <td style={{ width: '35px' }}>{score.result1}</td>{/*Загальна */}
                                                    <td style={{ width: '35px' }} rowSpan='2'>{score.total}</td>{/*Середня */}
                                                </tr>
                                                <tr>
                                                    <td style={{ width: '35px' }}>2</td>{/* Пумсе */}
                                                    <td style={{ width: '35px' }}>{score.technique2}</td>{/*Техніка */}
                                                    <td style={{ width: '35px' }}>{score.presentation2}</td>{/*Презентація */}
                                                    <td style={{ width: '35px' }}>{score.result2}</td>{/*Загальна */}
                                                </tr>


                                            </>) : (<>
                                                <tr>
                                                    <td style={{ width: '67px' }} rowSpan='2'>{state.myCoach(score.judge)}</td>{/* Суддя */}
                                                    <td style={{ width: '35px' }}>1</td>{/* Пумсе */}
                                                    <td style={{ width: '35px' }}>{score.technique1}</td>{/*Техніка */}
                                                    <td style={{ width: '35px' }}>{score.presentation1}</td>{/*Презентація */}
                                                    <td style={{ width: '35px' }}>{score.result1}</td>{/*Загальна */}
                                                    <td style={{ width: '35px' }} rowSpan='2'>{score.total}</td>{/*Середня */}
                                                </tr>
                                                <tr>
                                                    <td style={{ width: '35px' }}>2</td>{/* Пумсе */}
                                                    <td style={{ width: '35px' }}>{score.technique2}</td>{/*Техніка */}
                                                    <td style={{ width: '35px' }}>{score.presentation2}</td>{/*Презентація */}
                                                    <td style={{ width: '35px' }}>{score.result2}</td>{/*Загальна */}
                                                </tr>
                                            </>)}
                                        </>)
                                    })}


                                </>) : null}
                            </table>
                        </td>




                    </tr>

                </>)
            } else { //для ручного адміністрування
                let rowsCounter = 0
                if (item.preliminaryResult.allScore||item.preliminaryResult.technique||item.preliminaryResult.presentation) { rowsCounter++ }
                if (item.preliminaryResult_2.allScore||item.preliminaryResult_2.technique||item.preliminaryResult_2.presentation) { rowsCounter++ }
                if (item.semiFinalResult.allScore||item.semiFinalResult.technique||item.semiFinalResult.presentation) { rowsCounter++ }
                if (item.semiFinalResult_2.allScore||item.semiFinalResult_2.technique||item.semiFinalResult_2.presentation) { rowsCounter++ }
                if (item.finalResults_2.allScore||item.finalResults_2.technique||item.finalResults_2.presentation) { rowsCounter++ }
                if (item.finalResults.allScore||item.finalResults.technique||item.finalResults.presentation) { rowsCounter++ }
                return (<>
                    {/*є усі 3  тура */}
                    {(item.preliminaryResult.allScore || item.preliminaryResult_2.allScore) &&
                        (item.semiFinalResult.allScore || item.semiFinalResult_2.allScore) &&
                        (item.finalResults.allScore || item.finalResults_2.allScore) ? (<>

                            <tr key={item.gridId}  >
                                <td rowSpan={rowsCounter}>{item.place} </td>

                                {/* Команди */}
                                {item.teamPoomsae && Array.isArray(item.teamPoomsae) && item.teamPoomsae.length > 0 ? (<>
                                    <td colSpan='4'>
                                        <table className={s.innerTable} >
                                            {item.teamPoomsae.map((teamItem) => {
                                                return (<>
                                                    <tr>
                                                        <td style={{ width: '165px' }}>&nbsp;{state.myCoachFull(teamItem.id)}</td>
                                                        <td style={{ width: '80px', textAlign: 'center' }}>{state.regionShort(teamItem.region)}/ <br />{teamItem.sportClub}
                                                            {teamItem.secondTeam ? (<>/<br />{teamItem.secondTeam}</>) : null}</td>
                                                        <td style={{ width: '80px', textAlign: 'center' }}> {state.myCoach(teamItem.coach)}/ <br />{state.myCoach(teamItem.coach2)}</td>
                                                        <td style={{ width: '77px', textAlign: 'center' }}>{teamItem.fst}</td>
                                                    </tr>
                                                </>)
                                            })}
                                        </table>
                                    </td>
                                    <td rowSpan='2'>Відбірковий</td>
                                    <td>&nbsp;</td>
                                    <td>Перше</td>
                                    <td>{item.preliminaryResult.technique}</td>
                                    <td>{item.preliminaryResult.presentation}</td>
                                    <td>{item.preliminaryResult.allScore}</td>
                                    <td rowSpan='2'>{item.preliminaryTotal}</td>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td style={{ textAlign: 'center' }}>Друге</td>
                                        <td>{item.preliminaryResult_2.technique}</td>
                                        <td>{item.preliminaryResult_2.presentation}</td>
                                        <td>{item.preliminaryResult_2.allScore}</td>
                                    </tr>
                                    <tr>
                                        <td rowSpan='2'>Півфінал</td>
                                        <td>&nbsp;</td>
                                        <td>Перше</td>
                                        <td>{item.semiFinalResult.technique}</td>
                                        <td>{item.semiFinalResult.presentation}</td>
                                        <td>{item.semiFinalResult.allScore}</td>
                                        <td rowSpan='2'>{item.semiFinalTotal}</td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td style={{ textAlign: 'center' }}>Друге</td>
                                        <td>{item.semiFinalResult_2.technique}</td>
                                        <td>{item.semiFinalResult_2.presentation}</td>
                                        <td>{item.semiFinalResult_2.allScore}</td>
                                    </tr>
                                    <tr>
                                        <td rowSpan='2'>Фінал</td>
                                        <td>&nbsp;</td>
                                        <td>Перше</td>
                                        <td>{item.finalResults.technique}</td>
                                        <td>{item.finalResults.presentation}</td>
                                        <td>{item.finalResults.allScore}</td>
                                        <td rowSpan='2'>{item.finalTotal}</td>
                                    </tr>
                                    <tr className={s.bottomBorder2}>
                                        <td>&nbsp;</td>
                                        <td style={{ textAlign: 'center' }}>Друге</td>
                                        <td>{item.finalResults_2.technique}</td>
                                        <td>{item.finalResults_2.presentation}</td>
                                        <td>{item.finalResults_2.allScore}</td>
                                    </tr>
                                </>) : (<>{/* спортсмени */}

                                    <td rowSpan={rowsCounter}>&nbsp;{state.myCoachFull(item.id)}</td>
                                    <td rowSpan={rowsCounter}>{state.regionShort(item.region)}/ <br />{item.sportClub}
                                        {item.secondTeam ? (<>/<br />{item.secondTeam}</>) : null}</td>
                                    <td rowSpan={rowsCounter}> {state.myCoach(item.coach)}/ <br />{state.myCoach(item.coach2)}</td>
                                    <td rowSpan={rowsCounter}>{item.fst}</td>
                                </>)}

                                <td rowSpan={rowsCounter}>{(Number(item.preliminaryTotal) + Number(item.semiFinalTotal) + Number(item.finalTotal)).toFixed(2)}</td>


                                <td rowSpan='2'>Відбірковий</td>
                                <td>&nbsp;</td>
                                <td>Перше</td>
                                <td>{item.preliminaryResult.technique}</td>
                                <td>{item.preliminaryResult.presentation}</td>
                                <td>{item.preliminaryResult.allScore}</td>
                                <td rowSpan='2'>{item.preliminaryTotal}</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td style={{ textAlign: 'center' }}>Друге</td>
                                <td>{item.preliminaryResult_2.technique}</td>
                                <td>{item.preliminaryResult_2.presentation}</td>
                                <td>{item.preliminaryResult_2.allScore}</td>
                            </tr>
                            <tr>
                                <td rowSpan='2'>Півфінал</td>
                                <td>&nbsp;</td>
                                <td>Перше</td>
                                <td>{item.semiFinalResult.technique}</td>
                                <td>{item.semiFinalResult.presentation}</td>
                                <td>{item.semiFinalResult.allScore}</td>
                                <td rowSpan='2'>{item.semiFinalTotal}</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td style={{ textAlign: 'center' }}>Друге</td>
                                <td>{item.semiFinalResult_2.technique}</td>
                                <td>{item.semiFinalResult_2.presentation}</td>
                                <td>{item.semiFinalResult_2.allScore}</td>
                            </tr>
                            <tr>
                                <td rowSpan='2'>Фінал</td>
                                <td>&nbsp;</td>
                                <td>Перше</td>
                                <td>{item.finalResults.technique}</td>
                                <td>{item.finalResults.presentation}</td>
                                <td>{item.finalResults.allScore}</td>
                                <td rowSpan='2'>{item.finalTotal}</td>
                            </tr>
                            <tr className={s.bottomBorder2}>
                                <td>&nbsp;</td>
                                <td style={{ textAlign: 'center' }}>Друге</td>
                                <td>{item.finalResults_2.technique}</td>
                                <td>{item.finalResults_2.presentation}</td>
                                <td>{item.finalResults_2.allScore}</td>
                            </tr>




                        </>) : null}

                    {/*є відбірковий та півфінал */}
                    {(item.preliminaryResult.allScore || item.preliminaryResult_2.allScore) &&
                        (item.semiFinalResult.allScore || item.semiFinalResult_2.allScore) &&
                        (!item.finalResults.allScore && !item.finalResults_2.allScore) ? (<>

                            <tr key={item.gridId}  >
                                <td rowSpan={rowsCounter}>{item.place} </td>

                                {/* Команди */}
                                {item.teamPoomsae && Array.isArray(item.teamPoomsae) && item.teamPoomsae.length > 0 ? (<>
                                    <td colSpan='4'>
                                        <table className={s.innerTable} >
                                            {item.teamPoomsae.map((teamItem) => {
                                                return (<>
                                                    <tr>
                                                        <td style={{ width: '165px' }}>&nbsp;{state.myCoachFull(teamItem.id)}</td>
                                                        <td style={{ width: '80px', textAlign: 'center' }}>{state.regionShort(teamItem.region)}/ <br />{teamItem.sportClub}
                                                            {teamItem.secondTeam ? (<>/<br />{teamItem.secondTeam}</>) : null}</td>
                                                        <td style={{ width: '80px', textAlign: 'center' }}> {state.myCoach(teamItem.coach)}/ <br />{state.myCoach(teamItem.coach2)}</td>
                                                        <td style={{ width: '77px', textAlign: 'center' }}>{teamItem.fst}</td>
                                                    </tr>
                                                </>)
                                            })}
                                        </table>
                                    </td>
                                    <td rowSpan='2'>Відбірковий</td>
                                    <td>&nbsp;</td>
                                    <td>Перше</td>
                                    <td>{item.preliminaryResult.technique}</td>
                                    <td>{item.preliminaryResult.presentation}</td>
                                    <td>{item.preliminaryResult.allScore}</td>
                                    <td rowSpan='2'>{item.preliminaryTotal}</td>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td style={{ textAlign: 'center' }}>Друге</td>
                                        <td>{item.preliminaryResult_2.technique}</td>
                                        <td>{item.preliminaryResult_2.presentation}</td>
                                        <td>{item.preliminaryResult_2.allScore}</td>
                                    </tr>
                                    <tr>
                                        <td rowSpan='2'>Півфінал</td>
                                        <td>&nbsp;</td>
                                        <td>Перше</td>
                                        <td>{item.semiFinalResult.technique}</td>
                                        <td>{item.semiFinalResult.presentation}</td>
                                        <td>{item.semiFinalResult.allScore}</td>
                                        <td rowSpan='2'>{item.semiFinalTotal}</td>
                                    </tr>
                                    <tr className={s.bottomBorder2}>
                                        <td>&nbsp;</td>
                                        <td style={{ textAlign: 'center' }}>Друге</td>
                                        <td>{item.semiFinalResult_2.technique}</td>
                                        <td>{item.semiFinalResult_2.presentation}</td>
                                        <td>{item.semiFinalResult_2.allScore}</td>
                                    </tr>

                                </>) : (<>{/* спортсмени */}

                                    <td rowSpan={rowsCounter}>&nbsp;{state.myCoachFull(item.id)}</td>
                                    <td rowSpan={rowsCounter}>{state.regionShort(item.region)}/ <br />{item.sportClub}
                                        {item.secondTeam ? (<>/<br />{item.secondTeam}</>) : null}</td>
                                    <td rowSpan={rowsCounter}> {state.myCoach(item.coach)}/ <br />{state.myCoach(item.coach2)}</td>
                                    <td rowSpan={rowsCounter}>{item.fst}</td>
                                </>)}

                                <td rowSpan={rowsCounter}>{(Number(item.preliminaryTotal) + Number(item.semiFinalTotal) + Number(item.finalTotal)).toFixed(2)}</td>


                                <td rowSpan='2'>Відбірковий</td>
                                <td>&nbsp;</td>
                                <td>Перше</td>
                                <td>{item.preliminaryResult.technique}</td>
                                <td>{item.preliminaryResult.presentation}</td>
                                <td>{item.preliminaryResult.allScore}</td>
                                <td rowSpan='2'>{item.preliminaryTotal}</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td style={{ textAlign: 'center' }}>Друге</td>
                                <td>{item.preliminaryResult_2.technique}</td>
                                <td>{item.preliminaryResult_2.presentation}</td>
                                <td>{item.preliminaryResult_2.allScore}</td>
                            </tr>
                            <tr>
                                <td rowSpan='2'>Півфінал</td>
                                <td>&nbsp;</td>
                                <td>Перше</td>
                                <td>{item.semiFinalResult.technique}</td>
                                <td>{item.semiFinalResult.presentation}</td>
                                <td>{item.semiFinalResult.allScore}</td>
                                <td rowSpan='2'>{item.semiFinalTotal}</td>
                            </tr>
                            <tr className={s.bottomBorder2}>
                                <td>&nbsp;</td>
                                <td style={{ textAlign: 'center' }}>Друге</td>
                                <td>{item.semiFinalResult_2.technique}</td>
                                <td>{item.semiFinalResult_2.presentation}</td>
                                <td>{item.semiFinalResult_2.allScore}</td>
                            </tr>
                        </>) : null}

                    {/*є відбірковий */}
                    {(item.preliminaryResult.allScore || item.preliminaryResult_2.allScore) &&
                        (!item.semiFinalResult.allScore && !item.semiFinalResult_2.allScore) &&
                        (!item.finalResults.allScore && !item.finalResults_2.allScore) ? (<>

                            <tr key={item.gridId}  >
                                <td rowSpan={rowsCounter}>{item.place} </td>

                                {/* Команди */}
                                {item.teamPoomsae && Array.isArray(item.teamPoomsae) && item.teamPoomsae.length > 0 ? (<>
                                    <td colSpan='4'>
                                        <table className={s.innerTable} >
                                            {item.teamPoomsae.map((teamItem) => {
                                                return (<>
                                                   <tr>
                                                        <td style={{ width: '165px' }}>&nbsp;{state.myCoachFull(teamItem.id)}</td>
                                                        <td style={{ width: '80px', textAlign: 'center' }}>{state.regionShort(teamItem.region)}/ <br />{teamItem.sportClub}
                                                            {teamItem.secondTeam ? (<>/<br />{teamItem.secondTeam}</>) : null}</td>
                                                        <td style={{ width: '80px', textAlign: 'center' }}> {state.myCoach(teamItem.coach)}/ <br />{state.myCoach(teamItem.coach2)}</td>
                                                        <td style={{ width: '77px', textAlign: 'center' }}>{teamItem.fst}</td>
                                                    </tr>
                                                </>)
                                            })}
                                        </table>
                                    </td>
                                    <td rowSpan='2'>Відбірковий</td>
                                    <td>&nbsp;</td>
                                    <td>Перше</td>
                                    <td>{item.preliminaryResult.technique}</td>
                                    <td>{item.preliminaryResult.presentation}</td>
                                    <td>{item.preliminaryResult.allScore}</td>
                                    <td rowSpan='2'>{item.preliminaryTotal}</td>
                                    <tr>
                                        <td className={s.bottomBorder2}>&nbsp;</td>
                                        <td style={{ textAlign: 'center' }}>Друге</td>
                                        <td>{item.preliminaryResult_2.technique}</td>
                                        <td>{item.preliminaryResult_2.presentation}</td>
                                        <td>{item.preliminaryResult_2.allScore}</td>
                                    </tr>

                                </>) : (<>{/* спортсмени */}

                                    <td rowSpan={rowsCounter}>&nbsp;{state.myCoachFull(item.id)}</td>
                                    <td rowSpan={rowsCounter}>{state.regionShort(item.region)}/ <br />{item.sportClub}
                                        {item.secondTeam ? (<>/<br />{item.secondTeam}</>) : null}</td>
                                    <td rowSpan={rowsCounter}> {state.myCoach(item.coach)}/ <br />{state.myCoach(item.coach2)}</td>
                                    <td rowSpan={rowsCounter}>{item.fst}</td>
                                </>)}

                                <td rowSpan={rowsCounter}>{(Number(item.preliminaryTotal) + Number(item.semiFinalTotal) + Number(item.finalTotal)).toFixed(2)}</td>


                                <td rowSpan='2'>Відбірковий</td>
                                <td>&nbsp;</td>
                                <td>Перше</td>
                                <td>{item.preliminaryResult.technique}</td>
                                <td>{item.preliminaryResult.presentation}</td>
                                <td>{item.preliminaryResult.allScore}</td>
                                <td rowSpan='2'>{item.preliminaryTotal}</td>
                            </tr>
                            <tr>
                                <td className={s.bottomBorder2} >&nbsp;</td>
                                <td style={{ textAlign: 'center' }}>Друге</td>
                                <td>{item.preliminaryResult_2.technique}</td>
                                <td>{item.preliminaryResult_2.presentation}</td>
                                <td>{item.preliminaryResult_2.allScore}</td>
                            </tr>
                        </>) : null}

                    {/*є півфінал та фінал */}
                    {(!item.preliminaryResult.allScore && !item.preliminaryResult_2.allScore) &&
                        (item.semiFinalResult.allScore || item.semiFinalResult_2.allScore) &&
                        (item.finalResults.allScore || item.finalResults_2.allScore) ? (<>

                            <tr key={item.gridId}  >
                                <td rowSpan={rowsCounter}>{item.place} </td>

                                {/* Команди */}
                                {item.teamPoomsae && Array.isArray(item.teamPoomsae) && item.teamPoomsae.length > 0 ? (<>
                                    <td colSpan='4'>
                                        <table className={s.innerTable} >
                                            {item.teamPoomsae.map((teamItem) => {
                                                return (<>
                                                    <tr>
                                                        <td style={{ width: '165px' }}>&nbsp;{state.myCoachFull(teamItem.id)}</td>
                                                        <td style={{ width: '80px', textAlign: 'center' }}>{state.regionShort(teamItem.region)}/ <br />{teamItem.sportClub}
                                                            {teamItem.secondTeam ? (<>/<br />{teamItem.secondTeam}</>) : null}</td>
                                                        <td style={{ width: '80px', textAlign: 'center' }}> {state.myCoach(teamItem.coach)}/ <br />{state.myCoach(teamItem.coach2)}</td>
                                                        <td style={{ width: '77px', textAlign: 'center' }}>{teamItem.fst}</td>
                                                    </tr>
                                                </>)
                                            })}
                                        </table>
                                    </td>

                                    <td rowSpan='2'>Півфінал</td>
                                    <td>&nbsp;</td>
                                    <td>Перше</td>
                                    <td>{item.semiFinalResult.technique}</td>
                                    <td>{item.semiFinalResult.presentation}</td>
                                    <td>{item.semiFinalResult.allScore}</td>
                                    <td rowSpan='2'>{item.semiFinalTotal}</td>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <td style={{ textAlign: 'center' }}>Друге</td>
                                        <td>{item.semiFinalResult_2.technique}</td>
                                        <td>{item.semiFinalResult_2.presentation}</td>
                                        <td>{item.semiFinalResult_2.allScore}</td>
                                    </tr>
                                    <tr>
                                        <td rowSpan='2'>Фінал</td>
                                        <td>&nbsp;</td>
                                        <td>Перше</td>
                                        <td>{item.finalResults.technique}</td>
                                        <td>{item.finalResults.presentation}</td>
                                        <td>{item.finalResults.allScore}</td>
                                        <td rowSpan='2'>{item.finalTotal}</td>
                                    </tr>
                                    <tr className={s.bottomBorder2}>
                                        <td>&nbsp;</td>
                                        <td style={{ textAlign: 'center' }}>Друге</td>
                                        <td>{item.finalResults_2.technique}</td>
                                        <td>{item.finalResults_2.presentation}</td>
                                        <td>{item.finalResults_2.allScore}</td>
                                    </tr>
                                </>) : (<>{/* спортсмени */}

                                    <td rowSpan={rowsCounter}>&nbsp;{state.myCoachFull(item.id)}</td>
                                    <td rowSpan={rowsCounter}>{state.regionShort(item.region)}/ <br />{item.sportClub}
                                        {item.secondTeam ? (<>/<br />{item.secondTeam}</>) : null}</td>
                                    <td rowSpan={rowsCounter}> {state.myCoach(item.coach)}/ <br />{state.myCoach(item.coach2)}</td>
                                    <td rowSpan={rowsCounter}>{item.fst}</td>
                                </>)}

                                <td rowSpan={rowsCounter}>{(Number(item.preliminaryTotal) + Number(item.semiFinalTotal) + Number(item.finalTotal)).toFixed(2)}</td>

                                <td rowSpan='2'>Півфінал</td>
                                <td>&nbsp;</td>
                                <td>Перше</td>
                                <td>{item.semiFinalResult.technique}</td>
                                <td>{item.semiFinalResult.presentation}</td>
                                <td>{item.semiFinalResult.allScore}</td>
                                <td rowSpan='2'>{item.semiFinalTotal}</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td style={{ textAlign: 'center' }}>Друге</td>
                                <td>{item.semiFinalResult_2.technique}</td>
                                <td>{item.semiFinalResult_2.presentation}</td>
                                <td>{item.semiFinalResult_2.allScore}</td>
                            </tr>
                            <tr>
                                <td rowSpan='2'>Фінал</td>
                                <td>&nbsp;</td>
                                <td>Перше</td>
                                <td>{item.finalResults.technique}</td>
                                <td>{item.finalResults.presentation}</td>
                                <td>{item.finalResults.allScore}</td>
                                <td rowSpan='2'>{item.finalTotal}</td>
                            </tr>
                            <tr className={s.bottomBorder2}>
                                <td>&nbsp;</td>
                                <td style={{ textAlign: 'center' }}>Друге</td>
                                <td>{item.finalResults_2.technique}</td>
                                <td>{item.finalResults_2.presentation}</td>
                                <td>{item.finalResults_2.allScore}</td>
                            </tr>




                        </>) : null}

                    {/*є  фінал */}
                    {(!item.preliminaryResult.allScore && !item.preliminaryResult_2.allScore) &&
                        (!item.semiFinalResult.allScore && !item.semiFinalResult_2.allScore) &&
                        (item.finalResults.allScore || item.finalResults_2.allScore) ? (<>



                            {/* Команди */}
                            {item.teamPoomsae && Array.isArray(item.teamPoomsae) && item.teamPoomsae.length > 0 ? (<>

                                <tr key={item.gridId}  >
                                    <td rowSpan={rowsCounter}>{item.place} </td>
                                    <td colSpan='4' rowSpan={rowsCounter}>
                                        <table className={s.innerTable} >
                                            {item.teamPoomsae.map((teamItem) => {
                                                return (<>
                                                    <tr>
                                                        <td style={{ width: '165px' }}>&nbsp;{state.myCoachFull(teamItem.id)}</td>
                                                        <td style={{ width: '80px', textAlign: 'center' }}>{state.regionShort(teamItem.region)}/ <br />{teamItem.sportClub}
                                                            {teamItem.secondTeam ? (<>/<br />{teamItem.secondTeam}</>) : null}</td>
                                                        <td style={{ width: '80px', textAlign: 'center' }}> {state.myCoach(teamItem.coach)}/ <br />{state.myCoach(teamItem.coach2)}</td>
                                                        <td style={{ width: '77px', textAlign: 'center' }}>{teamItem.fst}</td>
                                                    </tr>
                                                </>)
                                            })}
                                        </table>
                                    </td>
                                    <td rowSpan={rowsCounter}>{(Number(item.preliminaryTotal) + Number(item.semiFinalTotal) + Number(item.finalTotal)).toFixed(2)}</td>


                                    <td rowSpan='2'>Фінал</td>
                                    <td>&nbsp;</td>
                                    <td>Перше</td>
                                    <td>{item.finalResults.technique}</td>
                                    <td>{item.finalResults.presentation}</td>
                                    <td>{item.finalResults.allScore}</td>
                                    <td rowSpan='2'>{item.finalTotal}</td>
                                </tr>
                                <tr className={s.bottomBorder2}>
                                    <td>&nbsp;</td>
                                    <td style={{ textAlign: 'center' }}>Друге</td>
                                    <td>{item.finalResults_2.technique}</td>
                                    <td>{item.finalResults_2.presentation}</td>
                                    <td>{item.finalResults_2.allScore}</td>
                                </tr>
                            </>) : (<>{/* спортсмени */}
                                <tr key={item.gridId}  >
                                    <td rowSpan={rowsCounter}>{item.place} </td>

                                    <td rowSpan={rowsCounter}>&nbsp;{state.myCoachFull(item.id)}</td>
                                    <td rowSpan={rowsCounter}>{state.regionShort(item.region)}/ <br />{item.sportClub}
                                        {item.secondTeam ? (<>/<br />{item.secondTeam}</>) : null}</td>
                                    <td rowSpan={rowsCounter}> {state.myCoach(item.coach)}/ <br />{state.myCoach(item.coach2)}</td>
                                    <td rowSpan={rowsCounter}>{item.fst}</td>


                                    <td rowSpan={rowsCounter}>{(Number(item.preliminaryTotal) + Number(item.semiFinalTotal) + Number(item.finalTotal)).toFixed(2)}</td>


                                    <td rowSpan='2'>Фінал</td>
                                    <td>&nbsp;</td>
                                    <td>Перше</td>
                                    <td>{item.finalResults.technique}</td>
                                    <td>{item.finalResults.presentation}</td>
                                    <td>{item.finalResults.allScore}</td>
                                    <td rowSpan='2'>{item.finalTotal}</td>
                                </tr>
                             
                                <tr className={s.bottomBorder2}>
                                    <td>&nbsp;</td>
                                    <td style={{ textAlign: 'center' }}>Друге</td>
                                    <td>{item.finalResults_2.technique}</td>
                                    <td>{item.finalResults_2.presentation}</td>
                                    <td>{item.finalResults_2.allScore}</td>
                                </tr>
                            </>)}



                        </>) : null}

                </>)
            }
        })//кінець головного цикла map 


    }


    // /////////////////////////////////////////////////////////////////////////

    return (<>



        {resultGrids.map((grid) => {
        

            return (<>
                <div className={s.mainDivForPrint}>


                    <table className={s.mailTableResultByCategoryesPoomsae}>{/* початок зовнішньої таблиці */}
                        <thead>
                            <tr>
                                <td colSpan='13'>

                                    <div className={s.headerTitle}>
                                        <div className={s.competitionTitle}>{competition.title}</div>
                                        <div className={s.competitionLocation}>{competition.location}</div>

                                        <div >
                                            {competition.dateStart && competition.dateFinish ? (<>
                                                <u><b>{state.formatDate(competition.dateStart).slice(0, 2)}</b>-
                                                    <b>{state.formatDate(competition.dateFinish)} р.</b></u>
                                            </>) : (<>
                                                <u><b>  {competition.dateOfCompetition}р.</b></u>
                                            </>)}
                                            <br /> <br />
                                            <div className={s.textTitle}>{lang.poomsaeCatUkr('ukr', grid.category)}</div>

                                        </div>

                                    </div>
                                </td>
                            </tr>
                            <tr className={s.headerTable}>
                                <td>Місце</td>
                                <td>Учасник</td>
                                <td>Осередок/<br />Спортклуб/<br />Команда</td>
                                <td>Тренер/<br />Тренер 2</td>
                                <td>ФСТ</td>
                                <td>Бали</td>
                                <td>Раунд</td>
                                <td>Суддя</td>
                                <td>Пумсе</td>
                                <td>Технік.</td>
                                <td>Презен.</td>
                                <td>Загал.</td>
                                <td>Серед.</td>
                            </tr>
                        </thead>

                        <tbody>

                            {athleteData(grid.athletes)}



                        </tbody>

                        <tfoot>
                            <tr>
                                <td colspan='5'>
                                    <div className={s.foterOfDocument}>
                                        <br />
                                        Гол. суддя  &nbsp;&nbsp;<b> {state.myCoach(competition.mainJudge)} </b>
                                        &nbsp;&nbsp; <span className={s.infoDescription}>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            (підпис)
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>  &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;

                                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;м.п.
                                    </div>


                                </td>
                                <td colspan='8'>

                                    <div className={s.foterOfDocument}>
                                        <br />
                                        Гол. секретар &nbsp;&nbsp; <b> {state.myCoach(competition.secretary)}</b>
                                        &nbsp;&nbsp; <span className={s.infoDescription}>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            (підпис)
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>  &nbsp;&nbsp;
                                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                    </div>
                                </td>  </tr>
                        </tfoot>


                    </table>

                </div>
            </>)
        })}



    </>)
}

export default ResultByCategoryesCutOffSystem