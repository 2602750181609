import state from '../../../../state/state';
import s from '../adminCompetition.module.css'

function EntryBody({athletes,dateEntry}) {

const sortedCategories=state.tournConstructor.sortAthletesByCategories(athletes)
    let num = 0; // визначте num поза циклом map
    const processedArray = [];
   
    for (const category in sortedCategories) {
      //назва категорії: category
  
      const nestedArray = sortedCategories[category]; 
  
      const row = nestedArray.map(item => { // обробка кожного елемента в масиві
     
const clubOfAthlete=state.sportClubs.filter(club=>club.name===item.sportClub)
        num++;

        return (
          <tr key={num} >
            <td className={s.td1}>{num}</td>
            <td className={s.td2}>{item.secondName} {item.personName} {item.fatherName}</td>
            <td className={s.td3}>{state.formatDate(item.birthday)}р.</td>
            <td className={s.td4}>{state.weightCatUkr(category)}</td>
            <td className={s.td5}>{item.rank}/<br/>{item.dan}</td>
            <td className={s.td6}>{item.fst +' / м.'+ clubOfAthlete[0].city} /<br/>{item.sportClub}</td>
            <td className={s.td7}>{state.myCoach(item.coach)} /<br/>{state.myCoach(item.coach2)}</td>
            <td className={s.td8}>{item.insuranceNum}  &nbsp;/ &nbsp;{state.formatDate(item.insuranceDate)}р.<br/>{item.insuranceComp} </td>
            {/* <td className={s.td9}>№ {item.medicalCertificateNum} <br/> {state.formatDate(item.medicalCertificateDate)}р.</td> */}
            <td className={s.td10}>&nbsp;{dateEntry}p.  &nbsp;
         М.П.
            <br/>Підпис_________ </td>
          </tr>
        );
      });
  
      processedArray.push(row);
    }
  
    return processedArray;
  }
  export default EntryBody
  