import { useEffect, useState } from 'react';
import state from '../../../state/state.js';
import s from './myCompetition.module.css'
import sAdmin from '../adminCompetition/adminCompetition.module.css'
import Select from 'react-select'
import TextEditor from './textEditor';
import compressImage from '../photo/compressImage';
import axios from 'axios';
import { eachDayOfInterval, format } from 'date-fns';
import MakeFoodOptionsCell from './makeFoodOptionsCell.js';
import ConfirmButtons from './../adminCompetition/confirmButtons';
import lang from '../../../state/language.js';
import indexedDBConnection from '../../../state/indexedDBConnection.js';



function AddCompetition({ isSmallScreen, langSwitch }) {
  state.messageCount()//лічильник непрочитаних повідомлень

  const [filePdf, setFilePdf] = useState(null);
  const [compressedFileURL, setCompressedFileURL] = useState(null);
  const [compressedFile, setCompressedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [selectedSecretary, setSelectedSecretary] = useState(null);
  const [selectedMainJudge, setSelectedMainJudge] = useState(null);
  const [needData, setNeedData] = useState(null);
  const [ageCategories, setAgeCategories] = useState([]);
  const [cort, setCort] = useState(null);
   const [reglament, setReglament] = useState(null);
  const [dateStart, setDateStart] = useState(null);
  const [dateFinish, setDateFinish] = useState(null);
  const [isFood, setIsFood] = useState(false);
  const [foodOptions, setFoodOptions] = useState(null);
  const [result, setResult] = useState(
    {
      times: [],
      prise: {
        breakfast: null,
        dinner: null,
        supper: null
      }
    }
  );
  const [competitionTitle, setCompetitionTitle] = useState(null);
  const [competitionLocation, setCompetitionLocation] = useState(null);
  const [competitionRankComp, setCompetitionRankComp] = useState(null);
  const [competitionSecretary, setCompetitionSecretary] = useState(null);
  const [competitionMainJudge, setCompetitionMainJudge] = useState(null);
  const [competitionLogo, setCompetitionLogo] = useState(null);
  const [competitionReglamentFhoto, setCompetitionReglamentFhoto] = useState(null);
  const [competitionAgeCategories, setCompetitionAgeCategories] = useState(null);
  const [competitionCorts, setCompetitionCorts] = useState(null);
  const [competitionNumberOfAthletes, setCompetitionNumberOfAthletes] = useState(null);



  const handleFoodChange = () => {
    if (!dateStart || !dateFinish) {
      setErrorMessage(
        lang[langSwitch].youNotSetTournamentDate /*Ви не встановили дати турниру!*/
      )
    } else {
      setIsFood(!isFood)
    }
  }
  const handleFileChange = (event) => {//обробка фото 
    const selectedFile = event.target.files[0];

    state.tempNewCompetition.logo = state.imgAdress.newCompetitionLogo + selectedFile.name;

    // Викликати функцію стиснення зображення та отримати стиснутий файл
    compressImage(selectedFile, {
      maxSizeMB: 0.5,
      maxWidth: 400,
      maxHeight: 300,
    })
      .then((compressedFile) => {

        setCompressedFile(compressedFile)
        // Створити URL для стиснутого файлу
        const compressedFileURL = URL.createObjectURL(compressedFile);

        // Встановити URL стиснутого файлу у стані компонента
        setCompressedFileURL(compressedFileURL);

      })
      .catch((error) => {
        // console.error('Помилка стиснення файлу:', error);
      });
  };


  //Назва турніру
  const handleTitleChange = (e) => { const value = e.target.value; state.tempNewCompetition.title = value.trim(); };

  //Кількість учасників
  const handleNumberOfAthletes = (e) => { const value = e.target.value; state.tempNewCompetition.numberOfAthletes = value.trim(); };

  //Адреса проведення турніру
  const handleLocationChange = (e) => { const value = e.target.value; state.tempNewCompetition.location = value.trim(); };


  //Дата початку турніру
  const handleDateStartChange = (e) => { const value = e.target.value; setDateStart(value) };


  //Адреса проведення турніру
  const handleDateFinishChange = (e) => { const value = e.target.value; setDateFinish(value) };

  //Кількість Даянгів
  const numbers = Array.from({ length: 24 }, (_, i) => i + 1);
  const handleSelect = (event) => {
    const selectedNumber = parseInt(event.target.value, 10);
    // setCort(selectedNumber);
    state.tempNewCompetition.corts = selectedNumber
  };


  // Ранг Турниру
  const rt = lang[langSwitch].rankTourn //ранг турниру в ленгвіч файлі
  // const rt = state.tournConstructor.rankTourn
  state.rankTurn = Object.keys(rt);

  const handleRankCompChange = (e) => {
    const selectedKey = e.target.value;
    // const selectedValue = state.tournConstructor.rankTourn[selectedKey];
    const selectedValue = lang[langSwitch].rankTourn[selectedKey];

    if (selectedValue) {
      state.tempNewCompetition.rankComp = selectedKey[0];
    } else {
      // Обробка випадку, коли значення не знайдено.
    }
  };


  // Головний секретар


  const options = state.athletes
    .filter((athlete) => athlete.judge == 1)
    .map((coach) => ({
      value: coach.id,
      label: `${coach.secondName} ${coach.personName && coach.personName[0] ? `${coach.personName[0].toUpperCase()}.` : ''
        } ${coach.fatherName && coach.fatherName[0] ? `${coach.fatherName[0].toUpperCase()}.` : ''}`,
    }));

  const handleSelectChange = (selectedOption) => {
    if (selectedOption === null) {
      // Видалення значення зі стану
      setSelectedSecretary(null);
      state.tempNewCompetition.secretary = null; // Встановіть null для secretary
    } else if (selectedOption) {
      setSelectedSecretary(selectedOption);
      state.tempNewCompetition.secretary = selectedOption.value;
    }
  };
  //головний суддя
  const handleSelectMainJudge = (selectedOption) => {
    if (selectedOption === null) {
      // Видалення значення зі стану
      setSelectedMainJudge(null);
      state.tempNewCompetition.mainJudge = null; // Встановіть null для MainJudge
    } else if (selectedOption) {
      setSelectedMainJudge(selectedOption);
      state.tempNewCompetition.mainJudge = selectedOption.value;
    }
  };
  /////////////////////////////////////////////////////////////////////////////

  

  const handleBreakfastPrise = (e) => {
    setResult(prev => ({
      ...prev,
      prise: {
        ...prev.prise,
        breakfast: e.target.value.replace(',', '.')
      }
    }));
  }

  const handleDinnerPrise = (e) => {
    setResult(prev => ({
      ...prev,
      prise: {
        ...prev.prise,
        dinner: e.target.value.replace(',', '.')
      }
    }));
  }
  const handleSupperPrise = (e) => {
    setResult(prev => ({
      ...prev,
      prise: {
        ...prev.prise,
        supper: e.target.value.replace(',', '.')
      }
    }));
  }

  const dStart = new Date(dateStart);
  const dFinish = new Date(dateFinish);

  const dates = eachDayOfInterval({ start: dStart, end: dFinish });
  const formattedDates = dates.map(date => format(date, 'dd.MM.yyyy'));


  function updateFoodResult(data) {

    setResult(prev => {
      const updatedTimes = prev.times.filter(item => item.date !== data.date);
      updatedTimes.push(data);
      return { ...prev, times: updatedTimes };
    });
  }

  function foodDataForDB() {
    if(!result){return}
    if(!result.times||result.times===null){setResult(null)}
   else if (result.times.some(item => item.breakfast || item.dinner || item.supper)) { setFoodOptions(result); }
    else { setResult(null) }
  }
  ///////////////////////////////////////////////////////////////////////////////////

  //Регламент PDF
  let formDataPdf; // Перемінна для зберігання об'єкту FormData
  useEffect(() => {

    if (filePdf) {
      state.tempNewCompetition.reglamentFhoto = state.imgAdress.newCompetitionReglament + filePdf.name;
      formDataPdf = new FormData(); // Створіть новий об'єкт FormData
      formDataPdf.append('file', filePdf); // Додайте файл до об'єкту FormData
      UploadPdf2(formDataPdf);
    } else {
      // console.error('Файл не було вибрано.');
    }
  }, [filePdf]);


  const handlePDFChange = (e) => {
    setFilePdf(e.target.files[0]);
  };

  function UploadPdf2(formDataPdf) {// Завантаження Pdf

    if (formDataPdf) {
      // Перевірте, чи об'єкт formDataPdf існує перед викликом Axios
      axios.post(state.api.uploadPdfTourn, formDataPdf, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
        .then(response => {
          // console.log('Файл було завантажено на сервер', response.data);

        })
        .catch(error => {
          // console.error('Помилка завантаження файлу на сервер', error);
        });
    }
  }


  function updateTournLogo() { // завантаження логотипу турніру
    if (compressedFileURL) {
      const formData = new FormData();
      formData.append('image', compressedFile);
      axios.post(state.api.uploadLogoTourn, formData)
        .then(response => {

          // console.log('Відповідь від сервера:', response.data);       
          if (state.userProfile.photo !== null) {

          }
          // Після завантаження зображення ви можете виконати PUT-запит для оновлення профілю      
        })
        .catch(error => {
          // console.error('Помилка під час відправлення зображення:', error);
        });
    } else {
      // Якщо `compressedFileURL` не існує, виконуємо тільки PUT-запит для оновлення профілю
    }
  }
  // Вибір вікової категорії спортсменів 
  const handleCheckboxChange = (value) => {
    if (ageCategories.includes(value)) {
      setAgeCategories(ageCategories.filter((category) => category !== value));
    } else {
      setAgeCategories([...ageCategories, value]);
    }
  };
  state.tempNewCompetition.ageCategories = ageCategories



  function uploadTournData() {// завантаження даних з текстових полів
    if (!state.tempNewCompetition.title || state.tempNewCompetition.title === '' || state.tempNewCompetition.title === null &&
      !state.tempNewCompetition.corts || state.tempNewCompetition.corts === '' || state.tempNewCompetition.corts === null &&
      !state.tempNewCompetition.location || state.tempNewCompetition.location === '' || state.tempNewCompetition.location === null &&
      !state.tempNewCompetition.rankComp || state.tempNewCompetition.rankComp === '' || state.tempNewCompetition.rankComp === null &&
      !dateStart &&
      !dateFinish &&
      !state.tempNewCompetition.ageCategories || state.tempNewCompetition.ageCategories.length === 0 || state.tempNewCompetition.numberOfAthletes === null
    ) {
      setNeedData(
        lang[langSwitch].needDataForTournament /*Для створення Турніру ви повинні вказати: назву турніру, клас турніру, місце, дати проведення кількість учасників, кількість кортів та вікові категорії!*/
      );


      return; // При незаповнених полях виходимо з функції

    }

    else {

      axios
        .post(state.api.addCompetition, {
          title: state.tempNewCompetition.title,
          location: state.tempNewCompetition.location,
          rankComp: state.tempNewCompetition.rankComp,
          secretary: state.tempNewCompetition.secretary,
          mainJudge: state.tempNewCompetition.mainJudge,
          reglament:reglament,
          dateStart: dateStart,
          dateFinish: dateFinish,
          creator: state.userProfile.id,
          logo: state.tempNewCompetition.logo,
          reglamentFhoto: state.tempNewCompetition.reglamentFhoto,
          ageCategories: JSON.stringify(state.tempNewCompetition.ageCategories),
          corts: state.tempNewCompetition.corts,
          active: 1,
          numberOfAthletes: state.tempNewCompetition.numberOfAthletes,
          foodOptions: JSON.stringify(foodOptions),
          heightCategories: 0,
          olimpicAdults: 0,
          olimpicJuniors: 0,
          poomse:0,
          poomsaeOptions:null,
          headers: { 'Content-Type': 'application/json' }
        })
        .then((response) => {

          setSuccessMessage(
            lang[langSwitch].tournamentSuccessfullyCreated /*Новий турнір успішно створено.*/
          );
          async function updateCompetitionData() {
            try {
                const response = await axios.get(state.api.getCompetition);
                
                if (response.data.competition) {
                    state.competition = response.data.competition;
                    console.log("Дані з сервера отримано та збережено в state:", state.competition);
        
                    await indexedDBConnection.saveData('competition', response.data.competition);
                    console.log('Дані успішно збережено в IndexedDB.');
                }
                
                // Викликаємо інші функції тільки після завершення основних асинхронних операцій
                updateTournLogo();
                UploadPdf2();
                state.tempNewCompetition = [];
        
                // Переходимо на іншу сторінку тільки після всіх операцій
                window.location.href = '/calendar';
        
            } catch (error) {
                console.error('Помилка під час запиту або збереження даних:', error);
            }
        }
        
        // Виклик функції
        updateCompetitionData();
        
        })
        .catch((error) => {
          setErrorMessage(
            lang[langSwitch].serverRequestError /*Помилка запиту до сервера: */ + error);
        });
    }


  }

  ////////////////////////////////ПРОГРАМНА ЧАСТИНА КНОПОК ПІДТВЕРДЖЕННЯ////////////////////////////////////////////////////////////
  const [messageText, setMessageText] = useState('');
  const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState(false);

  function editResultConfirm() {// ФУНКЦІЯ ЯКА ЗАПУСКАЄ ОПИТУВАННЯ
    //    встановлений текст над кнопками є трігером, 
    //якщо він повертається з компоненти - спрацьовує функція
    setMessageText(
      lang[langSwitch].createNewTournament /*Створити новий турнир?*/
    )
    openConfirmButtonsModal()
  }
  const confirmFunction = (messageText) => {
    if (messageText ===
      lang[langSwitch].createNewTournament /*Створити новий турнир?*/
    ) {
      uploadTournData() //ФУНКЦІЯ ЯКА ВИКОНУЄТЬСЯ В РАЗІ ПІДТВЕРДЖЕННЯ
    }

  }
  const openConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(true); }
  const closeConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(false); }
  ///////////////////////////////////////////////////////////////////////////////////
  return (
    <div className={!isSmallScreen ? s.container1 : null}>
      {/* Шапка */}
      <div className={s.Item1}>


        <h1 className={s.addTournTitle}>
          {lang[langSwitch].newTournamentKeirugue /*Новий Турнир керуги*/}
        </h1>
        <span>&nbsp;&nbsp;&nbsp;<b>* </b>
        {lang[langSwitch].mandatoryFields /*Поля обов'язкові для заповнення.*/}
        </span>

        {successMessage ?
          (<><div className={s.successMessage} onClick={() => { setSuccessMessage('') }}>
            {successMessage}</div></>) :
          errorMessage ? (<><div className={s.errorMessage} onClick={() => { setErrorMessage('') }}>
            {errorMessage}</div></>) : ''}
      </div>

      {/* Лівий блок */}
      <div className={s.Item2}>

        <form className={s.form}>

          <p>
            <label htmlFor="title"><span className={s.lable}><b>
            {lang[langSwitch].enterNameOfTournament/*Введіть назву змагань*/}&nbsp;
               *</b></span> </label><br />
            <input onChange={handleTitleChange} type="text" id="title" name="title" />
          </p>
          <p>
            <label htmlFor="location"><span className={s.lable}>
              <b>
              {lang[langSwitch].fullAddressOfTournament /*Вкажіть повну адресу проведення:*/} *
                </b><br />
                {lang[langSwitch].addressDetails /* Область, Місто,  Заклад та Адресу.*/}
                </span> </label><br />
            <input onChange={handleLocationChange} type="text" id="location" name="location" />
          </p>


          <p>
            <label htmlFor="rankComp">
              <span className={s.lable}><b>
              {lang[langSwitch].tournamentClass /*Вкажіть клас турніру*/} *
                </b></span>
            </label>
            <br />
            <select onChange={handleRankCompChange} id="rankComp" name="rankComp">
              <option value=""></option>
              {state.rankTurn.map((rank) => (
                <option key={rank} value={rank}>
                  {rank}
                </option>
              ))}
            </select>
          </p>


          <p>
            <label htmlFor="dateStart"><span className={s.lable}><b>
            {lang[langSwitch].dateStartOfCompetition /*Оберіть дату початку змагань*/} *
              </b></span> </label><br />
            <input onChange={handleDateStartChange} type="date" id="dateStart" name="dateStart" />
          </p>
          <p>
            <label htmlFor="dateFinish"><span className={s.lable}><b>
            {lang[langSwitch].dateEndOfCompetition /* Оберіть дату закінчення змагань*/} *
              </b></span> </label><br />
            <input onChange={handleDateFinishChange} type="date" id="dateFinish" name="dateFinish" />
          </p>

          <p>
            <label htmlFor="numberOfAthletes"><span className={s.lable}><b>
            {lang[langSwitch].numberOfAthletesOnCompetition /*Вкажіть кількість учасників*/} *
              </b></span> </label><br />
            <input onChange={handleNumberOfAthletes}
              style={{ width: '50px' }}
              id="numberOfAthletes"
              name="numberOfAthletes" />
          </p>

          {/* Приймаємо кількість даянгів */}
          <p className={s.lable}><b>
          {lang[langSwitch].numberOfDayangs /*Вкажіть кіл-сть даянгів для керугі*/} * 
            </b><br/>
          <select value={cort} onChange={handleSelect} style={{ marginBottom: '10px' }}>
            <option value=""></option>
            {numbers.map((number) => (
              <option key={number} value={number}>
                {number}
              </option>
            ))}
          </select>
        </p>

          {/* Вибір Секретаря  */}
          <p>
            <label htmlFor="secretary">
              <span className={s.lable}><b>
              {lang[langSwitch].secretary /*Секретар*/}
                </b></span>
            </label>
            <br />
            <Select className={s.secretary}
              id="secretary"
              name="secretary"
              value={selectedSecretary}
              onChange={handleSelectChange}
              options={options}
              isClearable={true}
              placeholder={lang[langSwitch].chooseSecretary /*Оберіть секретаря*/}
            />
          </p>


          {/* Вибір Головного судді  */}
          <p>
            <label htmlFor="mainJudge">
              <span className={s.lable}><b>
              {lang[langSwitch].chiefJudge /*Головний суддя*/} 
                </b></span>
            </label>
            <br />
            <Select className={s.secretary}
              id="mainJudge"
              name="mainJudge"
              value={selectedMainJudge}
              onChange={handleSelectMainJudge}
              options={options}
              isClearable={true}
              placeholder={lang[langSwitch].chooseChiefJudge /*Оберіть головного суддю*/}
            />
          </p>


          <div>    {/*вибір вікової категорії */}
            <p className={s.lable}><b>
            {lang[langSwitch].ageCategoriesOfTournament /*Вкажіть вікові категорії турниру*/}: *</b></p>
            <label>
              <input
                type="checkbox"
                value="jrChildren"
                checked={ageCategories.includes("jrChildren")}
                onChange={() => handleCheckboxChange("jrChildren")}
              />
              <span className={s.lable}>&nbsp;&nbsp;<i>
              {lang[langSwitch].jrChildren /*Малюки*/} 
                </i></span>
            </label>
            <br />
            <label>
              <input
                type="checkbox"
                value="children"
                checked={ageCategories.includes("children")}
                onChange={() => handleCheckboxChange("children")}
              />
              <span className={s.lable}>&nbsp;&nbsp;<i>
              {lang[langSwitch].children /*Діти*/} 
                </i></span>
            </label>
            <br />
            <label>
              <input
                type="checkbox"
                value="jrYounkers"
                checked={ageCategories.includes("jrYounkers")}
                onChange={() => handleCheckboxChange("jrYounkers")}
              />
              <span className={s.lable}>&nbsp;&nbsp;<i>
              {lang[langSwitch].jrYounkers /*Мол. юнаки*/}
                </i></span>
            </label>
            <br />
            <label>
              <input
                type="checkbox"
                value="younkers"
                checked={ageCategories.includes("younkers")}
                onChange={() => handleCheckboxChange("younkers")}
              />
              <span className={s.lable}>&nbsp;&nbsp;<i>
              {lang[langSwitch].younkers /*Юнаки*/} 
                </i></span>
            </label>
            <br />
            <label>
              <input
                type="checkbox"
                value="cadets"
                checked={ageCategories.includes("cadets")}
                onChange={() => handleCheckboxChange("cadets")}
              />
              <span className={s.lable}>&nbsp;&nbsp;<i>
              {lang[langSwitch].cadets /*Кадети*/}
                </i></span>
            </label>
            <br />
            <label>
              <input
                type="checkbox"
                value="juniors"
                checked={ageCategories.includes("juniors")}
                onChange={() => handleCheckboxChange("juniors")}
              />
              <span className={s.lable}>&nbsp;&nbsp;<i>
              {lang[langSwitch].juniors /*Юніори*/} 
                </i></span>
            </label>
            <br />
            <label>
              <input
                type="checkbox"
                value="adults"
                checked={ageCategories.includes("adults")}
                onChange={() => handleCheckboxChange("adults")}
              />
              <span className={s.lable}>&nbsp;&nbsp;<i>
              {lang[langSwitch].adults /*Дорослі*/} 
                </i></span>
            </label>  
            <br />
            <label>
              <input
                type="checkbox"
                value="veterans"
                checked={ageCategories.includes("veterans")}
                onChange={() => handleCheckboxChange("veterans")}
              />
              <span className={s.lable}>&nbsp;&nbsp;<i>
              {lang[langSwitch].veterans /*Дорослі*/} 
                </i></span>
            </label>
          </div>


        </form>

      </div>


      {/* Правий блок */}
      <div className={s.Item3}>
        <form className={s.form}>


          {/*  логотип */}
          <p>
            <label htmlFor="photo"><span className={s.lable}><b>
            {lang[langSwitch].setCompetitionLogo /*Встановіть логотип змаганнь*/} 
              </b><br />
            {lang[langSwitch].sizeForCompetitionLogo /*Для коректного виводу зображення співвідношення сторін у файлі має бути 4/3*/}  
            </span></label>
            <div>
              {compressedFileURL && (
                <img src={compressedFileURL} alt="Стигнутий файл" />
              )}<br />
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
              /><br />
            </div>
          </p>


          {/*   регламент з печатками */}
          <p>
            <label htmlFor="pdf"><span className={s.lable}><b>
            {lang[langSwitch].downloadRegulationsWithStamps /*Завантажте регламент з печатками (PDF)*/} 
              </b></span></label><br />

            <input type="file" accept=".pdf" onChange={handlePDFChange} />

          </p>

          <br />
          <label>
            <p className={s.lable}><b>
              {isFood ? 
               lang[langSwitch].specifyParametersForCatering /*Вкажіть параметри для організації харчування*/ 
              : 
              lang[langSwitch].foodNotOrganized /*Харчування не організовується*/}
            </b> </p>
            <div className={s.switchBox}>
              <input
                type='checkbox'
                checked={isFood}
                onChange={handleFoodChange}

              />
            </div>
          </label>
          <br />
          {isFood ? (<>

            <div className={sAdmin.containerAdmin}>

              <div className={sAdmin.itemAdmin1}>
                <p className={s.lable}><b>
                {lang[langSwitch].priceOfBreakfast /*Ціна сніданку*/} 
                  </b> </p>
                <input style={{ width: isSmallScreen ? '80px' : '120px' }} type='number' onChange={handleBreakfastPrise} />
              </div>
              <div className={sAdmin.itemAdmin2}>
                <p className={s.lable}><b>
                {lang[langSwitch].priceOfLunch /*Ціна обіду*/}
                  </b></p>
                <input style={{ width: isSmallScreen ? '80px' : '120px' }} type='number' onChange={handleDinnerPrise} />
              </div>
              <div className={sAdmin.itemAdmin3}>
                <p className={s.lable}><b> 
                {lang[langSwitch].dinnerPrice /*Ціна вечері*/}
                  </b></p>
                <input style={{ width: isSmallScreen ? '80px' : '120px' }} type='number' onChange={handleSupperPrise} />
              </div>

            </div>

            {formattedDates.map((item, index) => {

              return (<>
                <MakeFoodOptionsCell
                  date={item}
                  key={index}
                  setResult={setResult}
                  updateFoodResult={updateFoodResult}
                  langSwitch={langSwitch} />


              </>)
            })}


          </>)
            : null}
        </form>



      </div>


      {/* футер */}
      <div className={s.Item4}>

        <form className={s.form}>


          <p className={s.lable}>
          {lang[langSwitch].enterTextOfRegulation /*Введіть текст регламенту*/}:
            </p>



          {needData ? (<><br /><br /><div className={s.errorMessage} onClick={() => { setNeedData('') }}>{needData}</div><br /></>) : ''}
            <TextEditor 
              isSmallScreen={isSmallScreen} 
              langSwitch={langSwitch} 
              setResult={setReglament}
              textInformation={lang[langSwitch].informationInField /*<p>Після вводу тексту натисніть кнопку <b>'Додати Регламент до решти даних'</b>, після незабудьте натиснути <b>'Внести до БД'</b> щоб зберегти результати!</p>*/}
              textButton={lang[langSwitch].addRegulationtoRestData /*Додати Регламент до решти даних*/}
               textInstruction={lang[langSwitch].clickButtonToCreatingTournament /*<h1>Тепер натисніть кнопку "Внести до БД" для завершення створення Турніру!</h1>*/}
              />


        </form>
        {successMessage ?
          (<><br /><br /><div className={s.successMessage} onClick={() => { setSuccessMessage(null) }}>
            {successMessage}</div><br /></>) :
          errorMessage ? (<><br /><br /><div className={s.errorMessage} onClick={() => { setErrorMessage(null) }}>
            {errorMessage}</div><br /></>) : ''}

        <div style={{ textAlign: isSmallScreen ? 'center' : 'right', wilth: '100%' }}>
          <button
            className={isSmallScreen ? s.buttonForSmall : s.textEditorButton1}
            type='button'
            onClick={() => {
              foodDataForDB()
              editResultConfirm(
                lang[langSwitch].сreateТewЕournament /*Створити новий турнир?*/ 
                )
            }}>

{lang[langSwitch].saveInDB /*Зберегти до БД*/}
            </button>
          <br /><br /><br />&nbsp;
        </div>

      </div>


      {/* модальне вікно кнопок підтвердження */}
      <ConfirmButtons
        isOpen={isConfirmButtonsModalOpen}
        onClose={closeConfirmButtonsModal}
        confirmFunction={confirmFunction}
        messageText={messageText}
        langSwitch={langSwitch}
      />
    </div>

  )
}

export default AddCompetition