import s from './documents.module.css'
import BackButton from './backButton';
import lang from '../../../state/language';


function Mouthguard({isSmallScreen,langSwitch}){
    const contentData = [
        { link: "#PrinciplesMouthguard", text: 'Принципи використання Капи, Тейпінгу та Брекетів' },
        { link: "#Piercing", text: 'Пірсинг' },
        { link: "#Mouthguard", text: 'Капа' },
        { link: "#athleteWithoutBraces", text: 'Спортсмен без брекетів' },
        { link: "#AthleteWithBraces", text: 'Спортсмен з брекетами' },
        { link: "#CareOfMouthguards", text: 'Догляд за капою' },
        { link: "#Taping", text: 'Тейпи' },
        { link: "#Bracing", text: 'Бандажи' },
        { link: "#Padding", text: 'Накладки' }
    ]
    const middleIndex = Math.ceil(contentData.length / 2);
    const firstHalf = contentData.slice(0, middleIndex);
    const secondHalf = contentData.slice(middleIndex);
    return(<>    
<BackButton langSwitch={langSwitch}/>
<div className={s.rulesWriper}>
 <>   
<div className={s.rulesTitle}>Вимоги щодо використання капи, тейпінгу, брекетів та пірсінгу до спортсменів WT під час змаганнь. 
       <br />
                <a href="http://www.worldtaekwondo.org/rules-wt/rules.html"
                    target='blank'
                    className={s.rulesSourseLink} >
                        {lang[langSwitch]. source /*Джерело*/} 
                        </a>
            </div>
 {/* Зміст */}
 <div className={s.rulesContentBox}>
                <div id='Content'>Зміст</div><br />
                <div className={!isSmallScreen ? s.contentContaner : null}>
                    <div className={s.rulesContentBoxItem1}>
                        {firstHalf.map((item, index) => {

                            return (<>
                                <div key={'first' + index}>
                                    <span className={s.num}>{index + 1}. </span>
                                    <a href={item.link} className={s.rulesContentLink1}>
                                        {item.text}
                                    </a>
                                </div>
                            </>)
                        })}
                    </div>
                    <div className={s.rulesContentBoxItem2}>
                        {secondHalf.map((item, index) => {

                            return (<>
                                <div key={'second' + index}>
                                    <span className={s.num}>{firstHalf.length + (index + 1)}. </span>
                                    <a href={item.link} className={s.rulesContentLink1}>
                                        {item.text}
                                    </a>
                                </div>

                            </>)
                        })}
                    </div>
                </div>
            </div>
{/*Принципи використання Капи, Тейпінгу та Брекетів  */}
<div className={s.rulesBlock}>
    <div className={s.rulesArticle} id='PrinciplesMouthguard'>Стаття 1. Принципи використання Капи, Тейпінгу та Брекетів.
        {isSmallScreen ? (<><br /></>) : null}
        <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
    </div>

    <div className={s.rulesOl}><span className={s.num}>1. </span>Забезпечення безпеки спортсменів та профілактики травм.</div>
    <div className={s.rulesOl}><span className={s.num}>2. </span>Не повинно шкодити ні спортсмену, ні супернику.</div>
    <div className={s.rulesOl}><span className={s.num}>3. </span>Не повинно впливати на спортивні результати або перебіг матчу.</div>

    <div >
    </div>
</div> 
{/*Пірсинг  */}
<div className={s.rulesBlock}>
    <div className={s.rulesArticle} id='Piercing'>Стаття 2. Пірсинг.
        {isSmallScreen ? (<><br /></>) : null}
        <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
    </div>
    <div className={s.rulesOl}><span className={s.num}>1. </span>Жоден спортсмен не повинен мати пірсинг на обличчі або будь-якій іншій частині тіла перед входом до зони перевірки. В іншому випадку, він/вона не буде допущений до змагань.</div>
    <div >
    </div>
</div> 
{/*Капа  */}
<div className={s.rulesBlock}>
    <div className={s.rulesArticle} id='Mouthguard'>Стаття 3. Капа.
        {isSmallScreen ? (<><br /></>) : null}
        <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
    </div>
    <div className={s.rulesNote}><b>* </b>Правило щодо захисту рота (капи) WT було переглянуте та змінене Всесвітньою стоматологічною федерацією (FDI) та експертами зі спортивної стоматології 10 лютого 2020 року</div>

    <div className={s.rulesOl}><span className={s.num}>1. </span>Спортсмени МАЮТЬ звернутися до стоматолога для виготовлення індивідуального захисту рота перед участю в змаганнях.</div>
    <div className={s.rulesOl}><span className={s.num}>2. </span>Спортсмени ПОВИННІ відвідувати свого стоматолога кожні 6 місяців для перевірки захисту рота та стоматологічного обстеження. Спортсмени більш схильні до зубних проблем, таких як карієс.</div>
    <div className={s.rulesOl}><span className={s.num}>3. </span>Колір капи: обмежений - лише "БІЛИЙ" або "ПРОЗОРИЙ".</div>
    <div className={s.rulesOl}><span className={s.num}>4. </span>Використання капи є обов'язковим. Винятків немає.</div>
    <div className={s.rulesOl}><span className={s.num}>5. </span>Товщина захисту рота повинна бути принаймні 4 мм спереду та 2 мм на жувальній поверхні </div>
    <div className={s.rulesOl}><span className={s.num}>6. </span>Захист рота повинен покривати всі верхні зуби (включаючи щонайменше перший моляр).</div>
    <div style={isSmallScreen ? {} : { textAlign: 'center', paddingTop: '15px' }}>

        <img  className={isSmallScreen?s.rulesImg:null}src='/img/kapa/teeth.jpg' />
    </div>

    <div className={s.rulesOl}><span className={s.num}>7. </span>Якщо спортсмен страждає на нудоту (рефлекс блювоти), капа може покривати 0,5 першого моляра.</div>
    <div className={s.rulesOl}><span className={s.num}>8. </span>Капа повинна бути гнучкою і виготовленою з етилен-вініл-ацетату (EVA).</div>
    <div className={s.rulesOl}><span className={s.num}>9. </span>Тільки індивідуально виготовлена капа, зроблена стоматологом, забезпечує найкращий захист спортсмена.</div>
    <div className={s.rulesOl}><span className={s.num}>10. </span>Спортсмени не повинні використовувати капи, розроблені для захисту від скреготу зубів (часто називається нічною капою або ортодонтичним сплинтом). Вона не розроблена для захисту спортсмена від спортивної травми.</div>
    <div className={s.rulesOl}><span className={s.num}>11. </span>Жоден спортсмен не буде допущений до змагань, якщо він/вона не дотримуватиметься вимог що до капи.</div>

    <div className={s.rulesOl}><b></b></div>
    
    <div >
    </div>
</div> 

{/*Спортсмен без брекетів  */}
<div className={s.rulesBlock}>
    <div className={s.rulesArticle} id='athleteWithoutBraces'>Стаття 4. Спортсмен без брекетів.
        {isSmallScreen ? (<><br /></>) : null}
        <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
    </div>

    <div className={s.rulesOl}><span className={s.num}>1. </span>Спортсмен без брекетів: Повинен носити принаймні повний верхній захист рота, який покриває всі верхні зуби (включаючи щонайменше перший моляр), або нижній захист рота, якщо у гравця є клас III прикусу (виступаюче підборіддя).</div>
    <div >
        <img className={s.rulesImg} src='/img/kapa/mouthguard1.png' /><br />
        <img  className={s.rulesImg}src='/img/kapa/mouthguard2.png' />
    </div>
    <div className={s.rulesOl}><b>Захист рота повинен покривати всі верхні зуби (включаючи щонайменше перший моляр), крім зубів мудрості. Товщина має бути щонайменше 4 мм у передній частині і 2 мм на жувальній поверхні.
</b></div>    
    <div >
    </div>
</div> 

{/*Спортсмен з брекетами  */}
<div className={s.rulesBlock}>
    <div className={s.rulesArticle} id='AthleteWithBraces'>Стаття 5. Спортсмен з брекетами.
        {isSmallScreen ? (<><br /></>) : null}
        <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
    </div>

    <div className={s.rulesOl}><span className={s.num}>1. </span>
        <div className={s.rulesOl}><span className={s.num}>1.1. </span> Атлети повинні мати індивідуальну капу, яку їхній стоматолог може регулярно переформовувати для адаптації до рухів зубів</div>
        <div className={s.rulesOl}><span className={s.num}>1.2. </span> Або виготовляти нову капу кожні два/три місяці.</div>
        <div className={s.rulesOl}><span className={s.num}>1.3. </span>Повинен носити тип капи, який відчувається найбільш комфортно з варіантів 1-3 нижче:</div>
    </div>

    <div className={s.rulesOl}><span className={s.num}>2. </span>Подвійний захист рота для брекетів (який покриває як верхні, так і нижні зуби і брекети). Це забезпечує найкращий захист.  <b>Наполегливо рекомендовано</b>.</div>
    
    <div >
        <img className={s.rulesImg} src='/img/kapa/mouthguard3.png' /><br />
        <img  className={s.rulesImg}src='/img/kapa/mouthguard4.png' />
    </div>

    <div className={s.rulesOl}><span className={s.num}>3. </span> Повнa верхня капа (який покриває до другого моляра) для брекетів і часткова нижня капа. Спортсмени можуть використовувати наступний варіант , якщо у них є серйозні проблеми з диханням.</div>
    <div >
        <img className={s.rulesImg} src='/img/kapa/mouthguard5.png' /><br />
        <img  className={s.rulesImg}src='/img/kapa/mouthguard6.png' />
    </div>
    
    <div className={s.rulesOl}><span className={s.num}>4. </span>Повний верхній захист рота для верхніх зубів та брекетів і тубус/протектор для нижніх брекетів: тимчасово покриває оголені брекети, щоб вони принаймні не зашкодили супернику. Однак він забезпечує мінімальний захист спортсмена з брекетами. Це найменш бажаний варіант.</div>

    <div >
        <img className={s.rulesImg} src='/img/kapa/mouthguard7.png' />
    </div>

    <div className={s.rulesOl}><b></b></div>
    
    <div >
    </div>
</div> 

{/*Догляд за капою  */}
<div className={s.rulesBlock}>
    <div className={s.rulesArticle} id='CareOfMouthguards'>Стаття 6. Догляд за капою.
        {isSmallScreen ? (<><br /></>) : null}
        <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
    </div>
    <div className={s.rulesOl}>Дуже важливо правильно доглядати за захистом рота. Неправильний догляд може призвести до його псування і втрати захисних властивостей.</div>

    <div className={s.rulesOl}><span className={s.num}>1. </span>Правила догляду за капою.
        <div className={s.rulesOl}><span className={s.num}>1.1. </span>Почистіть і промийте зуби/ротову порожнину перед і після використання.</div>
        <div className={s.rulesOl}><span className={s.num}>1.2. </span>Не залишайте  в гарячому автомобілі.</div>
        <div className={s.rulesOl}><span className={s.num}>1.3. </span>Очищуйте  після кожного матчу або тренування.</div>
        <div className={s.rulesOl}><span className={s.num}>1.4. </span>Зберігайте  в надійній коробці.</div>
        <div className={s.rulesOl}><span className={s.num}>1.5. </span>Використовуйте антибактеріальний розчин. Навіть вода краще, ніж нічого, але просто швидкого промивання не буде достатньо.</div>
        <div className={s.rulesOl}><span className={s.num}>1.6. </span>Ретельно очищуйте капупринаймні раз на місяць. </div>
        <div className={s.rulesOl}><span className={s.num}>1.7. </span>Чистіть кейс.</div>
        <div className={s.rulesOl}><span className={s.num}>1.8. </span>Змінюйте капу за потреби.</div>
    </div>
    
    <div className={s.rulesOl}><span className={s.num}>2. </span>Як підтримувати капуа чистою
        <div className={s.rulesOl}><span className={s.num}>2.1. </span>Зубна щітка. Використовуючи неабразивну зубну пасту і м'яку зубну щітку, добре почистіть вашу капу, а потім промийте.</div>
        <div className={s.rulesOl}><span className={s.num}>2.2. </span>Перекис водню. Повністю продезінфікуйте, використовуючи 3% перекис водню.</div>
        <div className={s.rulesOl}><span className={s.num}>2.3. </span>Мило та вода. Просто промивання захисту рота антибактеріальним милом допоможе нейтралізувати частину бактерій.</div>
    </div>
    <div className={s.rulesOl}><span className={s.num}>3. </span>Як позбутися запаху від капи?
        <div className={s.rulesOl}><span className={s.num}>3.1. </span>Ретельно почистіть капу зубною содою або зубною пастою.</div>
        <div className={s.rulesOl}><span className={s.num}>3.2. </span>Протріть м'якою ганчіркою.</div>
        <div className={s.rulesOl}><span className={s.num}>3.3. </span>Промийте кейс.</div>
        <div className={s.rulesOl}><span className={s.num}>3.4. </span>Після чищення капи, замочіть її на десять хвилин у склянці з антисептичною рідиною для рота.</div>
        <div className={s.rulesOl}><span className={s.num}>3.5. </span>Протріть м'якою ганчіркою.</div>
    </div>
    <div className={s.rulesOl}><span className={s.num}>4. </span>Зберігання капи

    <div className={s.rulesOl}>Капа повинна зберігатися в чистому, сухому контейнері з вентиляційними отворами. Регулярне очищення контейнера допомагає запобігти поширенню мікробів. Мийте контейнер за допомогою нетоксичного мийного засобу і теплої води та переконайтеся, що він повністю висох, перш ніж поміщати всередину захист рота.</div>
    </div>
    <div >
    </div>
</div> 
</>
{/*Тейпи та Бандажи  */}
<div className={s.rulesBlock}>
    <div className={s.rulesArticle} id='Taping'>Стаття 7. Тейпи.
        {isSmallScreen ? (<><br /></>) : null}
        <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
    </div>

    <div className={s.rulesOl}><span className={s.num}>1. </span>Тейп та бандажи будуть ретельно перевірені під час процесу огляду спортсмена.</div>
    <div className={s.rulesOl}><span className={s.num}>2. </span>Спортсмен повинен отримати схвалення медкомісією  тейпування і отримати підпис на тейпуванні від лікаря комісії перед тим, як буде перевірений інспекційним рефері.</div>
    <div className={s.rulesOl}><span className={s.num}>3. </span>Після того, як лікар комісії підпише тейпування, його не можна розкривати до перевірки підпису інспекційним рефері, і до того, як спортсмен завершить змагання.</div>
    <div className={s.rulesOl}><span className={s.num}>4. </span>Якщо є пошкодження підпису або немає підпису, спортсмен не допускається до змаганнь.</div>
    <div className={s.rulesOl}><span className={s.num}>5. </span>Для захисту та підтримки пошкодженого суглоба має використовуватися мінімальна кількість тейпу.</div>
    <div className={s.rulesOl}><span className={s.num}>6. </span>Допускається до 2 шарів тонкої піни під підкладкою, яку слід покривати.</div>
    <div className={s.rulesOl}><span className={s.num}>7. </span>Допускається до 4 повних шарів тейпування (2 мм) для будь-яких травм і 2 повних шари (1 мм) для підтримки. Один додатковий анкерний шар на проксимальному та дистальному краях і один додатковий бічний шар петлі дозволяється і не враховується до 4 повних шарів для конкретних типів тейпування, якщо це необхідно.</div>
    <div className={s.rulesOl}><span className={s.num}>8. </span>Колір тейпування: обмежений "білим кольором" або "світло-коричневим (або бежевим) кольором".</div>
    <div className={s.rulesOl}><span className={s.num}>9. </span>Не допускається відкритого використання абразивного тейпу. Еластичний або Еластикон тейп повинен бути покритий одним-двома шарами білого або бежевого спортивного тейпу, щоб тейп можна було підписати лікарем комісії.</div>
    <div className={s.rulesOl}><span className={s.num}>10. </span>Не дозволяється тейпування суглобів кулака.</div>
    <div >
        <img className={s.rulesImg} src='/img/kapa/taping1.png' /><br />
         <img className={s.rulesImg} src='/img/kapa/taping2.png' />
    </div>
    <div >
    </div>
</div> 

{/*  */}
<div className={s.rulesBlock}>
    <div className={s.rulesArticle} id='Bracing'>Стаття 8. Бандажи.
        {isSmallScreen ? (<><br /></>) : null}
        <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
    </div>

    <div className={s.rulesOl}><span className={s.num}>1. </span>Заборонено використовувати тверді матеріали (метал, пластик, липучку тощо) для бандажів.</div>
    <div >
        <img className={s.rulesImg} src='/img/kapa/taping3.png' />
    </div>
 <div className={s.rulesOl}><span className={s.num}>2. </span> Дозволений лише м'який неопреновий матеріал.</div>
 
 <div >
        <img className={s.rulesImg} src='/img/kapa/taping4.png' />
    </div>
 <div className={s.rulesOl}><span className={s.num}>3. </span>Силіконова (або інша м'яка гелева) прокладка навколо колінної чашечки може бути дозволена за умови, що це м'який матеріал і його товщина менше ніж 6 мм (або 0,25 дюйма).</div>
 <div >
        <img className={s.rulesImg} src='/img/kapa/taping5.png' />
    </div>
 
</div> 

{/*  */}
<div className={s.rulesBlock}>
    <div className={s.rulesArticle} id='Padding'>Стаття 9. Накладки.
        {isSmallScreen ? (<><br /></>) : null}
        <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
    </div>

    <div className={s.rulesOl}><span className={s.num}>1. </span>Допускається накладання м'якого спіненого матеріалу на тильну частину стопи або руки лише у випадку, якщо спортсмен має контузію (синці) або гематому на тильній частині стопи.</div>
    <div className={s.rulesOl}><span className={s.num}>2. </span>Матеріал: можна використовувати м'яку накладку спіненого поліуретану або марлевий тампон.</div>
    <div className={s.rulesOl}><span className={s.num}>3. </span>Товщина: максимальна товщина 1/8 дюйма (= 4 мм) поліуретанової прокладки або 4 шари марлевих тампонів.</div>
    <div className={s.rulesOl}><span className={s.num}>4. </span>Він має залишатися м'яким, коли обгортається мінімальною кількістю стрічки (до 2 шарів).</div>
    <div className={s.rulesOl}><span className={s.num}>5. </span>На передній частині стопи/склепінні спортсмена допускається використання лише двох шарів білої або бежевої стрічки.</div>
 <div >
        <img className={s.rulesImg} src='/img/kapa/padding.png' />
    </div>
    <div className={s.rulesOl}><b></b></div>
    
    <div >
    </div>
</div> 

{/*  */}
{/* <div className={s.rulesBlock}>
    <div className={s.rulesArticle} id='#'>Стаття .
        {isSmallScreen ? (<><br /></>) : null}
        <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
    </div>

    <div className={s.rulesOl}><span className={s.num}>1. </span></div>

    <div className={s.rulesOl}><b></b></div>
    
    <div >
    </div>
</div>  */}

<div className={s.rulesOl}><b>Вище наведена стаття носить інформативний характер та не є офіційним документом!</b></div>
<br/>&nbsp;

</div>

<BackButton langSwitch={langSwitch}/><br/>&nbsp;

    </>)
}
export default Mouthguard