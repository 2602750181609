import React, {  useState } from "react";
import s from '../adminCompetition/adminCompetition.module.css';
import ConfirmButtons from "../adminCompetition/confirmButtons";
import state from "../../../state/state.js";
import lang from './../../../state/language';





function SportClubChoice({ isOpen, onClose,setSportClubData,isSmallScreen,langSwitch}) {

  // Створіть стан для зберігання списку вибраних спортсменів
  const [selectedAthletes, setSelectedAthletes] = useState([]);// обрані атлети
  const [errorMessage, setErrorMessage] = useState(''); //повідомлення про помилки та успіх
  const [successMessage, setSuccessMessage] = useState('');
  const [data, setData] = useState('');
  const [messageText, setMessageText] = useState('');
  const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState(false);


  function saveResultConfirm() {
    if(selectedAthletes.length>0){
    setMessageText(
      lang[langSwitch].confirmYourChoice //Підтвердіть свій вибір
    )
    openConfirmButtonsModal()
  }else{setErrorMessage(
     lang[langSwitch].notChosenCandidate //Ви не обрали жодного кандидата!
  )}
  }
  const confirmFunction = (messageText) => {
    if (messageText === 
      lang[langSwitch].confirmYourChoice //Підтвердіть свій вибір
    ) {
      setSportClubData(selectedAthletes)
      setSelectedAthletes(null)
      onClose()
    }

  }
  const openConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(true); }
  const closeConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(false); }



  const handleTitleChange = (e) => { setData(e.target.value) };

let filterClub

if(state.userProfile.region){
  filterClub=state.sportClubs.filter((club)=>club.region===state.userProfile.region)
  
}else{filterClub=state.sportClubs}
  //   форма пошуку

  const sportClubs = filterClub.filter(club => {
    const fullName = club.name .toLowerCase();
    const searchData = data.toLowerCase();

    return fullName.includes(searchData);
  });


  return (<>
   {isOpen?(<>
    <div className={s.modalWriper}>
    <div appElement={document.getElementById('root')} 
          className={s.modal} 
          contentLabel="Regulation Modal">

      <div className={s.h1}><b>
      {lang[langSwitch].chooseSportsClub}{/*Оберіть спортклуб*/}
        </b></div>

      {successMessage ?
        (<><br /><br /><div className={s.successMessage} onClick={() => { setSuccessMessage('') }}>
          {successMessage}</div><br /></>) :
        errorMessage ? (<><br /><br /><div className={s.errorMessage} onClick={() => { setErrorMessage('') }}>
          {errorMessage}</div><br /></>) : ''}

          <div className={!isSmallScreen?s.contentTitleItem1:s.td1}>
        <div className={s.title1}>
          <label htmlFor="title"><span><b>
             {lang[langSwitch].startTypingClubName}{/*Почніть вводити назву клубу*/}
            
            </b></span> </label><br /><br />
          <input onChange={handleTitleChange} type="text" id="title" name="title" />

        </div>
        
        <div className={s.title7}>
        <div className={!isSmallScreen?s.switchers:s.switchersSmall}>

            <button className={!isSmallScreen?s.switch1L:s.switch1LSmall} type='button' onClick={() => { saveResultConfirm(); }}>
              {lang[langSwitch].choose}{/*Обрати*/} 
            </button>

            <button className={s.switch6L} type='button' onClick={()=>{onClose()}}>
               {lang[langSwitch].close}{/*Закрити*/} 
              </button>
          </div>
        </div>
      </div><br />

      {/* "плитка спортсменів" */}
      <div className={s.gridContainerForClubChoise}>
        {sportClubs.map((athlete) => (        
          <div key={athlete.id} 
          className={selectedAthletes=== athlete.name? `${s.clubBlock} ${s.clubBlock1}` : s.clubBlock}
          style={{ cursor: 'pointer' }} onClick={() =>  setSelectedAthletes(athlete.name)}
          >
            <div className={s.clubLogo}>
            <img src={athlete.logo?athlete.logo:state.noLogoPhoto} 
            alt={athlete.name}/>
</div>
            <div className={s.clubData}>
              <div className={s.clubCreator}>
              <b>{athlete.name} </b>
            </div>  
         <div className={s.clubRegion}>
         <i> {state.regionShort(athlete.region)} </i>
        </div>
         <div className={s.clubCoachTitle}><br/>
         {lang[langSwitch].seniorCoach}{/*Старший тренер*/}:</div> 
    <div className={s.clubCoach}> <b>{state.myCoach(athlete.creator)}</b></div> 
        
        </div>
  <div className={s.clubFooter}> 
  
         
      </div>
      </div> 
        
       ))}
      </div>
    

      <ConfirmButtons
        isOpen={isConfirmButtonsModalOpen}
        onClose={closeConfirmButtonsModal}
        confirmFunction={confirmFunction}
        messageText={messageText}
        langSwitch={langSwitch}
      />


    </div>
    </div>
    </>):null}
  </>)
}


export default SportClubChoice

