import { useEffect, useState } from "react"
import lang from "../../../state/language";
import s from '../adminCompetition/food/food.module.css'

function FoodEntryCell({item, prise,setFoodData,start,langSwitch}){
    const [foodCellData, setFoodCellData] = useState({ date: item.date });
    const [breakfastPrise, setBreakfastPrise] = useState(null);
    const [dinnerPrise, setDinnerPrise] = useState(null);
    const [supperPrise, setSupperPrise] = useState(null);
    const [stop, setStop] = useState(false);


    useEffect(() => {
        if (start && !stop) {
            setFoodData((prev) => ({ ...prev, data: prev.data.concat(foodCellData) }));

            setStop(true);
        }
    }, [foodCellData, start, stop, setFoodData]);
 

    function handleBreakfast(e) { 
        const price = e.target.value * prise.breakfast;
        setBreakfastPrise(price);
        setFoodCellData(prev => ({ ...prev, breakfast: e.target.value }));       
    }

    function handleDinner(e) {
        const price = e.target.value * prise.dinner;       
        setDinnerPrise(price);
        setFoodCellData(prev => ({ ...prev, dinner: e.target.value }));
    }

    function handleSupper(e) {
        const price = e.target.value * prise.supper;       
        setSupperPrise(price);
        setFoodCellData(prev => ({ ...prev, supper: e.target.value }));
    }

  

    return(<>
    <tr style={{textAlign:'center'}} key={item.date}>
  <td className={s.priseSize}> <b>{item.date}</b> </td>
  <td> {/* сніданок */}
   {item.breakfast?(<>
   <input style={{width:'60px'}}type="number" onChange={handleBreakfast}/>
   </>):null}
  </td>
  <td className={s.priseSize}>
  <span className={s.priseSize}>
    {breakfastPrise?breakfastPrise+' '+
  lang[langSwitch].UAH /*грн.*/
  :null}
  </span>
  </td>
  <td>{item.dinner?(<>
    <input style={{width:'60px'}}type="number" onChange={handleDinner}/>
  </>):null}</td>
  <td >
  <span className={s.priseSize}>
    {dinnerPrise?dinnerPrise+' '+
  lang[langSwitch].UAH /*грн.*/
  :null}
   </span>
  </td>
  <td>{item.supper?(<>
    <input style={{width:'60px'}}type="number" onChange={handleSupper}/>
  </>):null}</td>
  <td >
    <span className={s.priseSize}>
    {supperPrise?supperPrise+' '+
  lang[langSwitch].UAH /*грн.*/
  :null}
  </span>
  </td>
</tr>
    </>)
}

export default FoodEntryCell