import state from "../../../state/state.js"
import s from '../mySportClubs/SportClubs.module.css'
import lang from './../../../state/language';




function CommunityRegions({isSmallScreen,langSwitch}) {

  

  return (<><br/>
    {
      state.region.map((region) => {

      const regionLeaderArray = state.athletes.filter((item) => item.regionLeader === region)
      const regionLeader= regionLeaderArray[0]
      const regionAthletes=state.athletes.filter((item) => item.region === region)
      const regionCoaches = regionAthletes.filter((item) => item.status ==='Тренер')
      const regionSrortClubs = state.sportClubs.filter((item) => item.region === region)
          const regionCoachesIds = regionCoaches.map(coach => coach.id);
      const regionCompetition = state.competition.filter(competition => {
        return regionCoachesIds.includes(competition.creator);    });
      const regionJudge = regionAthletes.filter((item) => item.judge == 1)
      const regionDans = regionAthletes.filter((item) => item.dan===('1 дан'||'2 дан'||'3 дан'||'4 дан'|| '5 дан'))
        const KMS = regionAthletes.filter((item) => item.rank ==='КМС')
        const MS = regionAthletes.filter((item) => item.rank ==='МС')
        const MSMK = regionAthletes.filter((item) => item.rank ==='МСМК')
        const ZMS = regionAthletes.filter((item) => item.rank ==='ЗМС')

      return (<div key={region}>
        {regionLeaderArray.length > 0 ? (

          <div className={s.comunityBoxTitile} >
            <div className={s.invisiblePrint}>
              <div className={s.h1}><div className={s.center}><b>
                {langSwitch==='ukr'?region:
                langSwitch==='eng'?lang.regionsEng(region):null}
                </b></div>
              </div>
              <div className={!isSmallScreen?s.communityContaner:null}>


                <div className={s.item1}>
                <div className={s.communityCell}>{/* блок с фото тренера */}
                    <div className={s.communityImg}>
                      <img src={regionLeader.photo?regionLeader.photo:state.noAvatarPhoto} 
                      alt={state.myCoach(regionLeader.id)} />
                    </div>

                    <div className={s.categoryData}>
                      <div className={s.coachName}>{`${regionLeader.secondName} 
                                            ${regionLeader.personName} 
                                             ${regionLeader.fatherName}`}</div>
                      <div className={s.region}>
                        {lang.regionLeders(langSwitch,region)}
                     
                        </div><br/>
                      <div >
                      {lang[langSwitch].qualificationShort /*Кваліф.: */} 
                        <b> {langSwitch==='ukr'?regionLeader.dan:
                          langSwitch==='eng'?lang.danEng(regionLeader.dan):null}
                           &nbsp;/ &nbsp;
                          {langSwitch==='ukr'?regionLeader.rank:
                          langSwitch==='eng'?lang.rankEng(regionLeader.rank):null} 
                           </b></div>
                      <div >
                      {lang[langSwitch].PCSS /*ФСТ */}: 
                        <b> {regionLeader.fst}</b></div>
                      <div >
                       {lang[langSwitch].seniorCoach /* Старший тренер*/}: 
                        <b> {regionLeader.sportClub}</b></div>
                       </div>

                  </div>
                  
                </div>
                <div className={s.communiyItem2}>


                <div className={s.regStatTitle}>
                {lang[langSwitch].statisticsByRegion /*Статистика по осередку*/}:
                  </div>
 <div className={s.contaner3}> 
<div className={s.item3}>
                <div className={s.regStatText}>
                 {isSmallScreen?
                 lang[langSwitch].competitionInYearShort /*Турнирів:*/  
                 :
                 lang[langSwitch].competitionInYear /*Щорічних турнирів:*/
                 }  
                  <b>{regionCompetition.length}</b>
                  </div>

                <div className={s.regStatText}>
                {lang[langSwitch].judgesInGen /* Суддів*/}: 
                  <b> {regionJudge.length} </b>
                  {lang[langSwitch].ppl /*чол.*/}
                  </div>

                <div className={s.regStatText}>
                  {lang[langSwitch].coachesInGen /* Тренерів*/}: 
                  <b> {regionCoaches.length} </b>
                  {lang[langSwitch].ppl /*чол.*/}
                  </div>

                <div className={s.regStatText}>
                  {lang[langSwitch].sportsClubsInGen /*Спортклубів*/}: 
                  <b> {regionSrortClubs.length}</b>
                  </div>

                <div className={s.regStatText}>
                 {lang[langSwitch].athletesInGen /*Спортсменів*/}: 
                  <b> {regionAthletes.length} </b>
                  {lang[langSwitch].ppl /*чол.*/}
                  </div>

</div>
<div >

<div className={s.regStatText}>
  {isSmallScreen||state.isMediumScreen?
 lang[langSwitch].statisticDanShort /*1-5 дан:*/
  :
  lang[langSwitch].statisticDan /*Володарів 1-5 данів:*/
  }
  <b>{regionDans.length} </b>
  {lang[langSwitch].ppl /*чол.*/}
  </div>

                <div className={s.regStatText}>
                {isSmallScreen||state.isMediumScreen?
                lang[langSwitch].CMSShort /*КМС:*/
                :
                lang[langSwitch].CMS/*Канд. в майстри спорту: */
                }                   
                  <b>{KMS.length} </b>
                  {lang[langSwitch].ppl /*чол.*/}
                  </div>

                <div className={s.regStatText}>
                {isSmallScreen||state.isMediumScreen?
              lang[langSwitch].MSShort/*МС:*/
                :
                lang[langSwitch].MS/*Майстри спорту:*/
                }
                  <b>{MS.length} </b> 
                  {lang[langSwitch].ppl /*чол.*/}
                  </div>

                <div className={s.regStatText}>
                {isSmallScreen||state.isMediumScreen?
               lang[langSwitch].IMSShort/*МСМК:*/
                :
               lang[langSwitch].IMS/*Майстрів спорту міжн. класу:*/ 
                }                
                   <b>{MSMK.length} </b>
                   {lang[langSwitch].ppl /*чол.*/}
                   </div>

                <div className={s.regStatText}>
                {isSmallScreen||state.isMediumScreen?
               lang[langSwitch].HMSShort/*ЗМC:*/
                :
               lang[langSwitch].HMS/*Заслужених майстрів спорту:*/ 
                }
                <b>{ZMS.length } </b>
                {lang[langSwitch].ppl /*чол.*/}
                </div>

  
</div>

</div>{/*закриття contaner3 */}
                </div>
              </div>
            </div>
          </div>
        ) : null}

      </div>)
    })
  }
  <br/></>)
}

export default CommunityRegions