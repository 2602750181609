import React, { useState } from 'react';
import axios from 'axios';
import sAdmin from '../adminCompetition/adminCompetition.module.css'
import s from './SportClubs.module.css';
import state from '../../../state/state.js';
import compressImage from '../photo/compressImage';
import { NavLink } from 'react-router-dom';
import ConfirmButtons from '../adminCompetition/confirmButtons';
import lang from '../../../state/language.js';
import indexedDBConnection from '../../../state/indexedDBConnection.js';


function CreateSportClub({isSmallScreen,langSwitch}) {
  const [sportClubName, setSportClubName] = useState('');
  const [sportClubCity, setSportClubCity] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [compressedFileURL, setCompressedFileURL] = useState(null);
  const [compressedFile, setCompressedFile] = useState(null);


  const handleSportClubNameChange = (e) => {setSportClubName(e.target.value);};
  const handleSportClubCityChange = (e) => {setSportClubCity(e.target.value.trim())};
////////////////////////////////////////////////////
const handleFileChange = (event) => {//обробка фото 
  const selectedFile = event.target.files[0];

  state.tempNewSportClub.logo = state.imgAdress.sportClubLogo + selectedFile.name;
  
    // Викликати функцію стиснення зображення та отримати стиснутий файл
    compressImage(selectedFile, {
      maxSizeMB: 0.3,
      maxWidth: 400,
      maxHeight: 400,
    })
      .then((compressedFile) => {
        // console.log('Стигнутий файл:', compressedFile);
        setCompressedFile(compressedFile)
        // Створити URL для стиснутого файлу
        const compressedFileURL = URL.createObjectURL(compressedFile);

        // Встановити URL стиснутого файлу у стані компонента
        setCompressedFileURL(compressedFileURL);
      })
      .catch((error) => {
        // console.error('Помилка стиснення файлу:', error);
      });
  };

  // отримуємо назву файлу, для видалення старого фото 
  //завантаження на сервер

  function update() {
    if (compressedFileURL) {
      const formData = new FormData();
      formData.append('image', compressedFile);
      axios.post(state.api.uploadSportClubLogo, formData)
        .then(response => {
          // Отримано відповідь від сервера після завантаження зображення
          // console.log('Відповідь від сервера:', response.data);
         
          // Після завантаження зображення ви можете виконати PUT-запит для оновлення профілю
          createSportClub();
        })
        .catch(error => {
          // console.error('Помилка під час відправлення зображення:', error);
        });
    } else {
      // Якщо `compressedFileURL` не існує, виконуємо тільки PUT-запит для оновлення профілю
 
      createSportClub();
    }
  }

  const createSportClub = () => { 
    
    if (!sportClubName.trim()) {
      setErrorMessage(
        lang[langSwitch].clubNameCannotBeEmpty //Назва клубу не може бути порожньою.
      );
      return;
    }
    if (!state.userProfile.region) {
      setErrorMessage(
        lang[langSwitch].mustChooseRegionForCreateClub //Для створення С\К ви повинні вказати вашу приналежність до обласного осередку на вкладці "Редагувати профіль".
      );
      return;     
    }
     if (!sportClubCity) {
      setErrorMessage(
        lang[langSwitch].mustChooseCityWhereIsSportsClub //Для створення С\К ви повинні вказати назву міста в якому функціонує спортклуб!
      );
      return;     
    }
    if (!state.cityNamePattern.test(sportClubCity)){
      setErrorMessage(<>
      {lang[langSwitch].wrongCityNameInputFormat}{/*Неправильний формат вводу назви міста!*/}
       <ul style={{textAlign:'left'}}>
        <li> {lang[langSwitch].dotsNotFllowed}{/*Недопускається ввод крапок;*/}</li> 
        <li>{lang[langSwitch].firstLetterMustBeCapitalized}{/*Перша літера назви повинна бути великою;*/}</li>
       <li> {lang[langSwitch].capitalLetterAfterDash}{/*Після тире обо пробілу повинна стояти велика літера;*/}       </li>
       <li> {lang[langSwitch].smallLetterBeforeApostrophe}{/*Після апострофу повинна стояти маленька літера;*/} </li>
        <li>{lang[langSwitch].notSpacesAroundHyphen}{/*Навколо дефісу недопускаються пробіли!*/}        </li>
        </ul> 
        </>);
    
     return;
    }
 

axios
.post(state.api.addSportClub, {
  region:state.userProfile.region,
  name: sportClubName,
  creator:state.userProfile.id,
  logo: state.tempNewSportClub.logo,
  city:sportClubCity,
    headers: {'Content-Type': 'application/json'}
})
.then((response) => {
  // console.log(response.data)
/////////////////Вносимо назву спортклубу в профіль тренера/////////////
 setSuccessMessage(
  lang[langSwitch].successfullyCreatedClub //Спортклуб успішно створено.
 ); 
       
axios.get(state.api.getSportClub)
.then((response) => {
    if (response.data.sportclubs) {
        indexedDBConnection.saveData ('sportClubs', response.data.sportclubs)       
        state.sportClubs = response.data.sportclubs
        window.location.href = '/MySportClubs'
      }
})
.catch((error) => {
    // console.error('Помилка запиту до сервера:', error);
});


            
})
.catch((error) => {
  setErrorMessage(lang[langSwitch].serverRequestError //Помилка запиту до сервера:
     + error);
  // console.log('error: ',error)
});

};



  ////////////////////////////////////////////////////////////////////////////////////////////
  const [messageText, setMessageText] = useState('');
 const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState(false);
  function createResultConfirm() {

      setMessageText(
        lang[langSwitch].createClubRequest//Створити новий спортивній клуб?*/
      )
      openConfirmButtonsModal()

  }
  const confirmFunction = (messageText) => {
      if (messageText === 
       lang[langSwitch].createClubRequest//Створити новий спортивній клуб?*/
      ) {
        update()
      }

  }
  const openConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(true); }
  const closeConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(false); }
  ///////////////////////////////////////////////////////////////////////////////////////////////////
  return (<>
 
<div className={s.headTitle}>
{!isSmallScreen?(<>
  <table ><tr><td>
      <h1  className={s.h1}>
      {lang[langSwitch].newSportsClub}{/*Новий спортклуб*/}
      </h1>
      </td>
      <td className={s.right}>
      <NavLink to="/MySportClubs">
        <button type='button'>
           {lang[langSwitch].sportsClub}{/*Спортклуб*/}
          </button>
      </NavLink>
      </td></tr></table>
      </>):(<>
 <h1  className={s.h1}>
 {lang[langSwitch].newSportsClub}{/*Новий спортклуб*/}
 </h1>
  <NavLink to="/MySportClubs">
        <button type='button'>
        {lang[langSwitch].sportsClub}{/*Спортклуб*/}
        </button>
      </NavLink>
        </>)}
</div>

  {successMessage?
        (<div className={sAdmin.successMessage} onClick={() => { setSuccessMessage('') }}>{successMessage} </div>) :
        errorMessage?(<div className={sAdmin.errorMessage}onClick={() => { setErrorMessage('') }}>{errorMessage} </div>):''}
        
        


        <div className={!isSmallScreen?s.contaner:s.editClubSmall}>
   
    <div className={s.item1}> {/* Ліва колонка */}
    <p>
          <label htmlFor="photo">
            <span className={s.clubLogoDescription}><i><b>
            {lang[langSwitch].installSportsClubLogo}{/*Встановіть логотип Спортклубу*/}</b><br/>
            {lang[langSwitch].sizeForClubLogo}{/* Для коректного виводу зображення співвідношення сторін у файлі має бути 1/1*/}

         </i>
          </span>
          </label><br/>
         
            {compressedFileURL && (
              <img src={compressedFileURL} alt="Стигнутий файл" />
            )}<br />
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
            /><br />
          
        </p>

    </div>{/* Закриття Ліва колонки */}



    <div className={s.item2}> {/* Права колонка */}

    <form >
       <p><b> <label htmlFor="sportClubName">
          <span className={s.createClubText}>
          {lang[langSwitch].enterNameOfClub}{/* Введіть назву клубу:*/}
          </span>
        </label>   <br/> 
       
        <input 
          onChange={handleSportClubNameChange}
          type="text"
          name="sportClubName"
          value={sportClubName}
        />
         </b> </p>
       
         <p><b> <label htmlFor="sportClubCity">
          <span className={s.createClubText}>
          {lang[langSwitch].enterCityOfClub}{/* Вкажіть місто роботи клубу*/}
            <i>
              {lang[langSwitch].notEnterLetterM}{/*літеру "м. " прописувати не потрібно*/}
              </i>:
            </span>
        </label>   <br/> 
       
        <input 
          onChange={handleSportClubCityChange}
          type="text"
          name="sportClubCity"
          value={sportClubCity}
        />
         </b> </p>

       
      </form>


    </div>{/* Закриття Правої колонки */}

 <button type='button'   onClick={createResultConfirm}>
  {lang[langSwitch].createSportsClub}{/*Створити спортклуб*/}
             
  </button>

    </div>{/* Закриття контейнера */}

    <ConfirmButtons
            isOpen={isConfirmButtonsModalOpen}
            onClose={closeConfirmButtonsModal}
            confirmFunction={confirmFunction}
            messageText={messageText}
            langSwitch={langSwitch}
        />
    
   </> );
}

export default CreateSportClub;
