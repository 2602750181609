import React from 'react';
import LanguagePanel from './languagePanel.js';
import s from './navBar.module.css'
import lang from '../../state/language.js';
import { NavLink } from 'react-router-dom';

function NavBarForAllUsers({ setLoginStart,
  setRegistrationStart,
  langSwitch,
  setLangSwitch }) {

  return (
    <div className={s.navBarMainDiv}>
      <LanguagePanel langSwitch={langSwitch} setLangSwitch={setLangSwitch} />
      <div >
      
        <span className={s.logo} 
        onClick={() => {          
          setLoginStart(true); 
          setRegistrationStart(false);
        }}>
          {lang[langSwitch].logIn} {/*Вхід*/}
        </span>
      
        <span className={s.logoSlash}> / </span>
       
        <span className={s.logo} 
        onClick={() => { 
          setLoginStart(false); 
          setRegistrationStart(true);
        }}
        >
          {lang[langSwitch].signUp} {/*Реєстрація*/}
        </span>
   
      </div>
      <nav>
        <ul>
          <li><a >
            {lang[langSwitch].home} {/*Головна*/}
          </a></li>
          <li>
             <NavLink to="/News">
            {lang[langSwitch].news} {/*Новини*/}
          </NavLink>
          </li> 
          <li>
            <NavLink to="/CalendarForAllUsers">
              {lang[langSwitch].calendar} {/* Календар*/}
            </NavLink>
          </li>
          <li><a >
            {lang[langSwitch].documents} {/*Документи*/}
          </a>
            <ul>
              <li><a >
                {lang[langSwitch].protocolsOfPresidium} {/*Протоколи Президії*/}
              </a></li>
              <li><a >
                {lang[langSwitch].rulesOfCompetition} {/*Правила змагань*/}
              </a>
                <ul>
                  <li><a >
                    {lang[langSwitch].fightShoot} {/*Двобої*/}
                  </a></li>
                  <li><a >
                    {lang[langSwitch].shooting} {/*Стрільба*/}
                  </a></li>
                  <li><a >
                    {lang[langSwitch].heptathlon} {/*Семиборство*/}
                  </a></li>
                </ul>
              </li>
              <li><a >
                {lang[langSwitch].requirements} {/*'Вимоги до учасників*/}
              </a></li>
            </ul>
          </li>
          <li>
            <a >
              {lang[langSwitch].media} {/* Медіа*/}
            </a>
            <ul>    
                  <li>
                    <NavLink to="/PhotoContent">
                      {lang[langSwitch].photos} {/*Світлини*/}
                    </NavLink>
                </li>
                  <li>
                    <NavLink to="/VideoContent">
                      {lang[langSwitch].video} {/*Відео*/}
                    </NavLink>
                    </li> 
              </ul>
          </li>
          <li>
            <NavLink to="/leaders">
            {lang[langSwitch].leadership} {/*Керівництво*/}
            </NavLink>
          </li>
          <li>
            <NavLink to="/SportsHalls">
            {lang[langSwitch].sportsClubs} {/*Спортклуби*/}
            </NavLink>
           
            </li>
        </ul>
      </nav>
    </div>
  );
};

export default NavBarForAllUsers;