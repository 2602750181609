import { NavLink, useParams } from "react-router-dom";
import state from '../../../state/state.js';
import s from './adminCompetition.module.css'
import st from '../results/result.module.css'
import { useState } from "react";
import axios from "axios";
import lang from "../../../state/language.js";
import CompetitionHeder from "./competitionHeder.js";



function RandomWeigh({isSmallScreen,langSwitch}) {

    const { id } = useParams();//отримуємо id
    const competitionArray = state.competition.filter((tourn) => tourn.id == id);
    const competition = competitionArray[0]

    const [jrChildren, setJrChildren] = useState(false);
    const [children, setChildren] = useState(false);
    const [jrYounkers, setJrYounkers] = useState(false);
    const [younkers, setYounkers] = useState(false);
    const [cadets, setCadets] = useState(false);
    const [juniors, setJuniors] = useState(false);
    const [adults, setAdults] = useState(false);
    const [veterans, setVeterans] = useState(false);

    const [start, setStart] = useState(false);
    
    const [jrChildrenCount, setJrChildrenCount] = useState(null);
    const [childrenCount, setChildrenCount] = useState(null);
    const [jrYounkersCount, setJrYounkersCount] = useState(null);
    const [younkersCount, setYounkersCount] = useState(null);
    const [cadetsCount, setCadetsCount] = useState(null);
    const [juniorsCount, setJuniorsCount] = useState(null);
    const [adultsCount, setAdultsCount] = useState(null);
    const [veteransCount, setVeteransCount] = useState(null);
  
    const handleSwitchJrChildren = () => { setJrChildren(true) }
    const handleSwitchChildren = () => { setChildren(true) }
    const handleSwitchJrYounkers = () => { setJrYounkers(true) }
    const handleSwitchYounkers = () => { setYounkers(true) }
    const handleSwitchCadets = () => { setCadets(true) }
    const handleSwitchJuniors = () => { setJuniors(true) }
    const handleSwitchAdults = () => { setAdults(true) }
    const handleSwitchVeterans = () => { setVeterans(true) }
    
    const handleJrChildrenCount = (e) => { setJrChildrenCount( e.target.value) };
    const handleChildrenCount = (e) => { setChildrenCount( e.target.value) };
    const handleJrYounkersCount = (e) => { setJrYounkersCount( e.target.value) };
    const handleYounkersCount = (e) => { setYounkersCount( e.target.value) };
    const handleCadetsCount = (e) => { setCadetsCount (e.target.value) };
    const handleJuniorsCount = (e) => {  setJuniorsCount(e.target.value) };
    const handleAdultsCount = (e) => {  setAdultsCount(e.target.value) };
    const handleVeteransCount = (e) => {  setVeteransCount(e.target.value) };
    
    const data=[]; let jrChildrenMale, jrChildrenFemale, childrenMale, childrenFemale, jrYounkersMale, jrYounkersFemale, younkersMale, younkersFemale, cadetsMale, cadetsFemale, juniorsMale, juniorsFemale, adultsMale, adultsFemale, veteransMale, veteransFemale;

    if(!competition.grids){return(<div>
         <CompetitionHeder 
    logo={competition.logo} 
    title={competition.title}
    notes={lang[langSwitch].randomWeigh /*  Вибіркове зважування*/}
    />

<h3 className={s.h3}>{lang[langSwitch].randomWeighingAvailableAfterDraw /*Вибіркове зважування доступне тільки після завершення жеребкування. При наявності збережених турнирних сіток.*/}

</h3>
        <div className={s.itemResultsFooter2}>
        <NavLink to={`/AdminCompetition/${competition.id}`}>
          <button type='button' >
            {lang[langSwitch].comeBack/*Повернутись*/}
            </button>
        </NavLink>
      </div>
      </div> )}

    function random(arr, count) {
        const shuffledArray = arr.slice().sort(() => Math.random() - 0.5);
        return shuffledArray.slice(0, count);
    }

    if ((competition.grids&&competition.grids.length>0||state.grids>0||state.gridsDB>0)) {// умова наявності competition.grids
        let grids=[]
        try { grids = JSON.parse(competition.grids) } catch (error) { grids = competition.grids }
        if (grids.length===0){grids=state.grids}
        if (grids.length===0){grids=state.gridsDB}





        const athletsForRandom = grids.flatMap((cat) => cat.athleteList);

        jrChildrenMale = athletsForRandom.filter((at) => {
            return (
                at.sex === 'male' && state.age(at.birthday) >= 6 && state.age(at.birthday) <= 7)
        })
        jrChildrenFemale = athletsForRandom.filter((at) => {
            return (
                at.sex === 'female' && state.age(at.birthday) >= 6 && state.age(at.birthday) <= 7)
        })
         childrenMale = athletsForRandom.filter((at) => {
            return (
                at.sex === 'male' && state.age(at.birthday) >= 8 && state.age(at.birthday) <= 9)
        })
         childrenFemale = athletsForRandom.filter((at) => {
            return (
                at.sex === 'female' && state.age(at.birthday) >= 8 && state.age(at.birthday) <= 9)
        })
        jrYounkersMale = athletsForRandom.filter((at) => {
            return (
                at.sex === 'male' && state.age(at.birthday) >= 10 && state.age(at.birthday) <= 11)
        })
        jrYounkersFemale = athletsForRandom.filter((at) => {
            return (
                at.sex === 'female' && state.age(at.birthday) >= 10 && state.age(at.birthday) <= 11)
        })
        younkersMale = athletsForRandom.filter((at) => {
            return (
                at.sex === 'male' && state.age(at.birthday) >= 12 && state.age(at.birthday) <= 13)
        })
        younkersFemale = athletsForRandom.filter((at) => {
            return (
                at.sex === 'female' && state.age(at.birthday) >= 12 && state.age(at.birthday) <= 13)
        })
        cadetsMale = athletsForRandom.filter((at) => {
            return (
                at.sex === 'male' && state.age(at.birthday) >= 14 && state.age(at.birthday) <= 15)
        })
       cadetsFemale = athletsForRandom.filter((at) => {
            return (
                at.sex === 'female' && state.age(at.birthday) >= 14 && state.age(at.birthday) <= 15)
        })
        juniorsMale = athletsForRandom.filter((at) => {
            return (
                at.sex === 'male' && state.age(at.birthday) >= 16 && state.age(at.birthday) <= 17)
        })
        juniorsFemale = athletsForRandom.filter((at) => {
            return (
                at.sex === 'female' && state.age(at.birthday) >= 16 && state.age(at.birthday) <= 17)
        })
        adultsMale = athletsForRandom.filter((at) => {
            return (
                at.sex === 'male' && state.age(at.birthday) >= 18 && state.age(at.birthday) <= 39)
        })
        adultsFemale = athletsForRandom.filter((at) => {
            return (
                at.sex === 'female' && state.age(at.birthday) >= 18 && state.age(at.birthday) <= 39)
        }) 
        veteransMale = athletsForRandom.filter((at) => {
            return (
                at.sex === 'male' && state.age(at.birthday) >= 40 && state.age(at.birthday) <= 150)
        })
        veteransFemale = athletsForRandom.filter((at) => {
            return (
                at.sex === 'female' && state.age(at.birthday) >= 40 && state.age(at.birthday) <= 150)
        }) 
    }// кінець умови наявності competition.grids

        if(jrChildren&&jrChildrenCount!==null){
                const catM=random(jrChildrenMale,Math.ceil(jrChildrenCount/2))
            if(catM.length>0)  {data.push(catM)}
                const catF=random(jrChildrenFemale,Math.ceil(jrChildrenCount/2))
                if(catF.length>0)  {data.push(catF)}} 

        if(children&&childrenCount!==null){
            const catM=random(childrenMale,Math.ceil(childrenCount/2))
          if(catM.length>0)  {data.push(catM)}
            const catF=random(childrenFemale,Math.ceil(childrenCount/2))
            if(catF.length>0)  {data.push(catF)}}  
   
        if(jrYounkers&&jrYounkersCount!==null){
            const catM=random(jrYounkersMale,Math.ceil(jrYounkersCount/2))
            if(catM.length>0)  {data.push(catM)}
            const catF=random(jrYounkersFemale,Math.ceil(jrYounkersCount/2))
            if(catF.length>0)  {data.push(catF)}}  
    
         if(younkers&&younkersCount!==null){
            const catM=random(younkersMale,Math.ceil(younkersCount/2))
            if(catM.length>0)  {data.push(catM)}
            const catF=random(younkersFemale,Math.ceil(younkersCount/2))
            if(catF.length>0)  {data.push(catF)}}  
    
        if(cadets&&cadetsCount!==null){
            const catM=random(cadetsMale,Math.ceil(cadetsCount/2))
            if(catM.length>0)  {data.push(catM)}
            const catF=random(cadetsFemale,Math.ceil(cadetsCount/2))
            if(catF.length>0)  {data.push(catF)}} 
    
        if(juniors&&juniorsCount!==null){
            const catM=random(juniorsMale,Math.ceil(juniorsCount/2))
            if(catM.length>0)  {data.push(catM)}
            const catF=random(juniorsFemale,Math.ceil(juniorsCount/2))
            if(catF.length>0)  {data.push(catF)}} 
    
        if(adults&&adultsCount!==null){
            const catM=random(adultsMale,Math.ceil(adultsCount/2))
            if(catM.length>0)  {data.push(catM)}
            const catF=random(adultsFemale,Math.ceil(adultsCount/2))
            if(catF.length>0)  {data.push(catF)}} 

        if(veterans&&veteransCount!==null){
            const catM=random(veteransMale,Math.ceil(veteransCount/2))
            if(catM.length>0)  {data.push(catM)}
            const catF=random(veteransFemale,Math.ceil(veteransCount/2))
            if(catF.length>0)  {data.push(catF)}}    
           
    const dataForMailToCoach=data.flatMap(item=>item)
    
//////////////////////////////////////////////////////////////////////////
const mailData=[] // вихідний масив для відправлення поштою 
function listCoaches(){
    //  пустий об'єкт Set для відстеження унікальних ідентифікаторів тренерів
    const uniqueCoachIds = new Set();    
   
      // Перебираємо всіх тренерів для кожного спортсмена
      for (let i = 0; i < dataForMailToCoach.length; i++) {
        // Отримуємо ідентифікатори тренерів для поточного спортсмена
        const coach = dataForMailToCoach[i].coach;   
        const coach2 = dataForMailToCoach[i].coach2;
        // Додаємо ідентифікатори тренерів до об'єкту Set, щоб зберігати унікальні значення
   
  if (coach !== undefined && coach !== null) {
    uniqueCoachIds.add(coach);  
  }
  
  if (coach2 !== undefined && coach2 !== null) {
    uniqueCoachIds.add(coach2);  
  }  
      }
    
    
    // Перетворюємо Set на масив, щоб отримати список унікальних ідентифікаторів тренерів
    const uniqueCoaches = Array.from(uniqueCoachIds);
    
    // Перебираємо унікальні ідентифікатори тренерів для формування mailData
    uniqueCoaches.forEach(coachId => {
      // Отримуємо дані тренера за його ідентифікатором
      const coachData = state.athletes.find(athlete => athlete.id == coachId);
      console.log(coachData)
    let text=`${coachData.sex==='male'?
        'Шановний'
        :
        'Шановнa'
    } ${coachData.personName} ${coachData.fatherName}, 
   повідомляємо Вам, що  Ваші спортсмени заявлені на турнир,
    <br/> <b>${competition.title}</b>,<br/>
    попали під вибіркове зважування:
    <br/><br/>`
      if (coachData) {
const dataForTextFilter=dataForMailToCoach.filter(item=>item.coach==coachId||item.coach2==coachId)

for (let i = 0; i < dataForTextFilter.length; i++) {
    const item = dataForTextFilter[i];
    text += `<b>${i + 1}.</b> ${item.secondName} ${item.personName}<br/>`;
  }
  

        // Створюємо об'єкт з інформацією про тренера та його поштовою адресою
        const coachObject = {
          id: coachId,
        //   userEmail: 'Sergeyburak79@gmail.com',
          userEmail: coachData.eMail,
          subject:`Повідомлення про рандомне зважування`,
          text:text,
          gridsPdfForMale:null
        };
    
        // Додаємо об'єкт до масиву mailData
        mailData.push(coachObject);
      }
    });
 // console.log('Розпочато розсилку')
    // Отримайте адресу вашого сервера
    const serverURL = state.api.emailSend;

    // Відправте POST-запит з даними
      axios.post(serverURL, {mailData} )
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.error('Помилка при відправленні POST-запиту:', error);
      });
    }
    
/////////////////////////////////////////////////////////////////////////
    return (<>
<div className={st.noScrol}>
        <div className={s.noPrint}> {/* зона невидима для друку */}
        

        <CompetitionHeder 
    logo={competition.logo} 
    title={competition.title}
    notes={lang[langSwitch].randomWeigh /*  Вибіркове зважування*/}
    />



            <div className={!isSmallScreen?s.switchers:s.paddingLeft}>

                <div className={s.switch0L}>
                    <form>
                        <label>
                            <div className={s.switchBox}>
                                <span className={s.ageCategorySwitcher1}>
                                {lang[langSwitch].jrChildren /*Малюки*/}
                                </span>
                                <br/> <br/>
                                <input
                                    type='checkbox'
                                    //   checked={activCompetition == 1||activCompetition === true}
                                    onChange={handleSwitchJrChildren}
                                />
                                     
                                    
                            </div>
                        </label>
                    </form>
                </div>

                <div className={s.switch0R}>

                    <br />
                    <input onChange={handleJrChildrenCount}
                        style={{ width: '50px' }}
                        id="numberOfAthletes"
                        name="numberOfAthletes" />
                    <label htmlFor="numberOfAthletes"> <br /><span className={s.ageCategoryDescription}><i>{lang[langSwitch].numberOfAthletes /*Кіл-ть спортсменів*/}</i></span> </label>

                </div>  

          

                <div className={s.switch1L}>
                    <form>
                        <label>
                            <div className={s.switchBox}>
                            <span className={s.ageCategorySwitcher1}>
                                     {lang[langSwitch].children /*Діти*/}
                                    </span>
                                    <br/> <br/>
                                <input
                                    type='checkbox'
                                    //   checked={activCompetition == 1||activCompetition === true}
                                    onChange={handleSwitchChildren}
                                />
                            </div>
                        </label>
                    </form>
                </div>

                <div className={s.switch1R}>

                    <br />
                    <input onChange={handleChildrenCount}
                        style={{ width: '50px' }}
                        id="numberOfAthletes"
                        name="numberOfAthletes" />
                    <label htmlFor="numberOfAthletes"> <br /><span className={s.ageCategoryDescription}><i>{lang[langSwitch].numberOfAthletes /*Кіл-ть спортсменів*/}</i></span> </label>

                </div>

                <div className={s.switch2L}>
                    <form>
                        <label>
                            <div className={s.switchBox}>
                                <span className={s.ageCategorySwitcher1}>
                                    {lang[langSwitch].jrYounkers /*Мол. юнаки*/}
                                    </span> <br/> <br/>
                                <input
                                    type='checkbox'
                                    //   checked={activCompetition == 1||activCompetition === true}
                                    onChange={handleSwitchJrYounkers}
                                />
                            </div>
                        </label>
                    </form>
                </div>

                <div className={s.switch2R}>

                    <br />
                    <input onChange={handleJrYounkersCount}
                        style={{ width: '50px' }}
                        id="numberOfAthletes"
                        name="numberOfAthletes" />
                    <label htmlFor="numberOfAthletes"><br /> <span className={s.ageCategoryDescription}><i>{lang[langSwitch].numberOfAthletes /*Кіл-ть спортсменів*/}</i></span> </label>

                </div>
                <div className={s.switch3L}>
                    <form>
                        <label>
                            <div className={s.switchBox}>
                                <span className={s.ageCategorySwitcher1}>
                                   {lang[langSwitch].younkers /* юнаки*/}
                                    </span> <br/> <br/>
                                <input
                                    type='checkbox'
                                    //   checked={activCompetition == 1||activCompetition === true}
                                    onChange={handleSwitchYounkers}
                                />
                            </div>
                        </label>
                    </form>
                </div>

                <div className={s.switch3R}>

                    <br />
                    <input onChange={handleYounkersCount}
                        style={{ width: '50px' }}
                        id="numberOfAthletes"
                        name="numberOfAthletes" />
                    <label htmlFor="numberOfAthletes"> <br /><span className={s.ageCategoryDescription}><i>{lang[langSwitch].numberOfAthletes /*Кіл-ть спортсменів*/}</i></span> </label>

                </div>

                <div className={s.switch4L}>
                    <form>
                        <label>
                            <div className={s.switchBox}>
                                <span className={s.ageCategorySwitcher1}>
                                      {lang[langSwitch].cadets /* Кадети*/}
                                    </span> <br/> <br/>
                                <input
                                    type='checkbox'
                                    //   checked={activCompetition == 1||activCompetition === true}
                                    onChange={handleSwitchCadets}
                                />
                            </div>
                        </label>
                    </form>
                </div>

                <div className={s.switch4R}>

                    <br />
                    <input onChange={handleCadetsCount}
                        style={{ width: '50px' }}
                        id="numberOfAthletes"
                        name="numberOfAthletes" />
                    <label htmlFor="numberOfAthletes"><br /> <span className={s.ageCategoryDescription}><i>{lang[langSwitch].numberOfAthletes /*Кіл-ть спортсменів*/}</i></span> </label>

                </div>

                <div className={s.switch5L}>
                    <form>
                        <label>
                            <div className={s.switchBox}>
                                <span className={s.ageCategorySwitcher1}>
                                   {lang[langSwitch].juniors /* Юніори*/}
                                    </span> <br/> <br/>
                                <input
                                    type='checkbox'
                                    //   checked={activCompetition == 1||activCompetition === true}
                                    onChange={handleSwitchJuniors}
                                />
                            </div>
                        </label>
                    </form>
                </div>

                <div className={s.switch5R}>

                    <br />
                    <input onChange={handleJuniorsCount}
                        style={{ width: '50px' }}
                        id="numberOfAthletes"
                        name="numberOfAthletes" />
                    <label htmlFor="numberOfAthletes"><br /> <span className={s.ageCategoryDescription}><i>{lang[langSwitch].numberOfAthletes /*Кіл-ть спортсменів*/}</i></span> </label>

                </div>

                <div className={s.switch6L}>
                    <form>
                        <label>
                            <div className={s.switchBox}>
                                <span className={s.ageCategorySwitcher1}>
                                    {lang[langSwitch].adults /* Дорослі*/}
                                    </span> <br/> <br/>
                                <input
                                    type='checkbox'
                                    //   checked={activCompetition == 1||activCompetition === true}
                                    onChange={handleSwitchAdults}
                                />
                            </div>
                        </label>
                    </form>
                </div>

                <div className={s.switch6R}>

                    <br />
                    <input onChange={handleAdultsCount}
                        style={{ width: '50px' }}
                        id="numberOfAthletes"
                        name="numberOfAthletes" />
                    <label htmlFor="numberOfAthletes"><br /> <span className={s.ageCategoryDescription}><i>{lang[langSwitch].numberOfAthletes /*Кіл-ть спортсменів*/}</i></span> </label>

                </div>

 <div className={s.switch7L}>
                    <form>
                        <label>
                            <div className={s.switchBox}>
                                <span className={s.ageCategorySwitcher1}>
                                    {lang[langSwitch].veterans /* Ветерани*/}
                                    </span> <br/> <br/>
                                <input
                                    type='checkbox'
                                    //   checked={activCompetition == 1||activCompetition === true}
                                    onChange={handleSwitchVeterans}
                                />
                            </div>
                        </label>
                    </form>
                </div>

                <div className={s.switch7R}>

                    <br />
                    <input onChange={handleVeteransCount}
                        style={{ width: '50px' }}
                        id="numberOfAthletes"
                        name="numberOfAthletes" />
                    <label htmlFor="numberOfAthletes"><br /> <span className={s.ageCategoryDescription}><i>{lang[langSwitch].numberOfAthletes /*Кіл-ть спортсменів*/}</i></span> </label>

                </div>



                
</div>
<br/>
<div className={!isSmallScreen?s.swichButtContaner:s.td1}>
                <div className={s.switchButL}>
                
  <button type="button" className={s.adminButton} onClick={()=>{setStart(true)}}>
  {lang[langSwitch].generate /*Згенерувати*/}
    </button>
               
                    </div>
                <div className={s.switchButR}>
                    <NavLink to={`/AdminCompetition/${competition.id}`}>
                        <button className={s.exitButton2} type='button' >
                        {lang[langSwitch].exit /*Вийти*/} 
                            </button>
                    </NavLink>
                </div>

            </div>
        </div>{/*Кінець  зони невидимої для друку */}


 

{data.length>0&&start?(<>

{data.map((item)=>{ return (<>
      
        <div className={st.mainDivForPrint}>
    <div className={st.headerTitle}>
<div className={st.textTitle}>Протокол вибіркового зважування спортсменів</div>
<div className={st.competitionTitle}>{competition.title}</div>
<div ><u><b>{state.formatDate(competition.dateStart).slice(0, 2)}</b>- 
        <b>{state.formatDate(competition.dateFinish)} р.</b></u> <br/><br/></div>



 
    </div>{/* початок зовнішньої таблиці */}
 <table className={s.mailTableResultByAthlets} style={{width:'97%'}}>
 <thead>
      <tr className={s.headerTable}>
        <td style={{width:'3%'}}>№</td>
        <td>Учасник</td>
        <td style={{width:'25%'}}>Категорія</td>
        <td style={{width:'10%'}}>Фактична вага</td>
         
        <td>Команда</td>
       
        <td style={{width:'10%'}}>Підпис</td>
        
             </tr>
      </thead>
           
        {item.map((a,count)=>{return (<>
   
        <tbody key={a.id}>
      <tr >
        <td>{count+1}</td>
        <td>{a.secondName} {a.personName}</td>
        <td>{state.weightCatUkr (a.competitionCategory, langSwitch)}</td>
        <td></td>
        <td>{a.team}</td>        
        
         <td></td>       
       
      </tr>
      </tbody>
      </>)})}
 
</table> {/* кінець зовнішньої таблиці */}


<table >
    <tbody>
        <tr>
            <td style={{border:'none'}}>
<div className={st.foterOfDocument}>
     Гол.суддя  &nbsp;&nbsp; 
     <b>{state.myCoach(competition.mainJudge)}</b>
 &nbsp;&nbsp; <span className={s.infoDescription}>
   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
     (підпис)   
   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>  &nbsp;&nbsp; <b>  {state.dateForMessage()}</b>
&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;м.п.
     </div>

            </td>
            <td style={{border:'none'}} >

            <div className={st.foterOfDocument}>
      Cекретар &nbsp;&nbsp;  
     <b>{state.myCoach(competition.secretary)}</b>
 &nbsp;&nbsp; <span className={s.infoDescription}>
   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
     (підпис)   
   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>  &nbsp;&nbsp; <b>  {state.dateForMessage()} </b>
 &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;м.п.
          
     </div>
            </td>
        </tr>
    </tbody>
</table>



   

</div>
  <div style={{ pageBreakAfter: 'always' }}> &nbsp;</div>



  </>)})}
 <div className={s.printButton}>
  <button type="button" className={s.adminButton} onClick={()=>{window.print(); listCoaches() }}>
  {lang[langSwitch].printAndReportToCoaches /*Друкувати та повідомити тренерам*/} 
                    </button> 
                    </div>
  </>):null}
  
   </div> </>)
}


export default RandomWeigh