

import state from '../../../state/state.js';
import res from '../results/result.module.css'
import { useState } from 'react';
import AppointCoach from './appointCoach';
import DeleteCoach from './deleteCoach';
import AppointJudge from './appointJudge';
import DeleteJudge from './deleteJudge';
import AppointModerator from './appointModerator';
import DeleteModerator from './deleteModerator';
import ActivFTU from './activFTU';
import AppointRegionLeader from './appointRegionLeader';
import DeleteRegionLeader from './deleteRegionLeader';
import ActivSite from './activSite';
import lang from '../../../state/language.js';



function ControlPanel({isSmallScreen, langSwitch}) {
  
  const [appointOpen, setAppointOpen] = useState(0);
const [handleID, setHandleID] = useState(0);
const [resultData, setResultData] = useState(null);
const [athleteForPass, setAthleteForPass] = useState(null);

function handleDataChange(e) {
  if (e.target.value) {
 let data;
    if (typeof e.target.value === 'object') {
      data = JSON.stringify(data); // перетворюємо об'єкт на рядок JSON
    }
   

    try {
      data = JSON.parse(e.target.value); // якщо це рядок JSON
    } catch {
      data = e.target.value; // якщо це не JSON, беремо оригінальне значення
    }

   


    // Створюємо Blob тільки з рядком або масивом
    const sizeInBytes = new Blob([data]).size;
    const sizeInMB = sizeInBytes / (1024 * 1024); // Перетворення в мегабайти
    setResultData(sizeInMB.toFixed(6));
  } else {
    setResultData(null);
  }
}


function handleIDChange(e){
  const input = e.target.value; // Отримуємо значення з інпуту
  const cleanedArray = input
  .split(',')                // Розділяємо по комі
  .map(item => parseInt(item.trim(), 10))   // Перетворюємо на числа
  .filter(item => !isNaN(item));     
  setHandleID(cleanedArray); 
}
function findPass (){
  setAthleteForPass(state.athletes.filter((item) => handleID.includes(Number(item.id))));
 }
// console.log('Пароль: ',user.login, atob(user.pass))







  return (<>

   <div><br/>
  {appointOpen===0?(<>   
      <div className={res.resultTitle}>
        <div><h1 className={res.h1}> {lang[langSwitch].controlPanel}{/*Панель керування*/} </h1> <br /></div>
      </div>

      {/* ///////////////////////////////////////////////////////////////////////////////////////////// */}
      <div className={!isSmallScreen?res.containerResults:res.resultTitle}>
        {state.userProfile.regionLeader ? (<>
          <div className={res.itemResults1}>
            <button 
            type='button' 
            className={res.button} 
            onClick={()=>{setAppointOpen(1)}}>
              {lang[langSwitch].appointCoach}{/*Призначити тренером*/}
            </button>
          </div>

          <div className={res.itemResults2}>
            &nbsp;
          </div>

          <div className={res.itemResults3}>
            &nbsp;
          </div>

          <div className={res.itemResults4}>
            <button 
              type='button' 
              className={res.button} 
              onClick={()=>{setAppointOpen(2)}}>
               {lang[langSwitch].removeCoach}{/*Видалити тренера*/}
                </button>
          </div>
        </>) : null}

        {/* /////////////////////////////////////////////////////////////////////////////////////////// */}

        {state.userProfile.moderator==1 && state.userProfile.id != 1 ? (<>
          <div className={res.itemResults1}>

            <button type='button' className={res.buttonForControlPanel} 
             style={{width:'200px', height:'52px'}}
             onClick={()=>{setAppointOpen(3)}}>
              {lang[langSwitch].appointRegionLeaderButt}{/*Призначити головою осередку*/} 
              </button>

            <button type='button' className={res.buttonForControlPanel} 
             style={{width:'200px', height:'52px'}}
             onClick={()=>{setAppointOpen(4)}}>
             {lang[langSwitch].removeRegionLeaderButt}{/*Видалити голову осередку*/}
              </button>
          </div>

          <div className={res.itemResults2}>

            <button type='button' className={res.buttonForControlPanel} 
             style={{width:'200px', height:'52px'}}
             onClick={()=>{setAppointOpen(5)}}>
              {lang[langSwitch].appointJudge}{/*Призначити суддею*/}
               </button>
            <button type='button' className={res.buttonForControlPanel} 
             style={{width:'200px', height:'52px'}}
             onClick={()=>{setAppointOpen(6)}}>
              {lang[langSwitch].removeJudge}{/*Видалити суддю*/} 
              </button>
          </div>

          <div className={res.itemResults3}>
            <button type='button' className={res.buttonForControlPanel} 
             style={{width:'200px', height:'52px'}}
             onClick={()=>{setAppointOpen(7)}}>
             {lang[langSwitch].appointModerator}{/*Призначити  модеретаром*/}
              </button>
            <button type='button' className={res.buttonForControlPanel} 
             style={{width:'200px', height:'52px'}}
             onClick={()=>{setAppointOpen(8)}}> 
            {lang[langSwitch].removeModerator}{/*Видалити модератора*/}
             </button><br /><br />
            <button type='button' className={res.buttonForControlPanel} 
             style={{width:'200px', height:'52px'}}
             onClick={()=>{setAppointOpen(9)}}>
              {lang[langSwitch].activateFTU}{/*Активувати у ФТУ*/} 
              </button>
          </div>
        </>) : null}

        {state.userProfile.id ==1 ? (<>
          <div className={res.itemResults1}>
            <button type='button' className={res.buttonForControlPanel} 
            style={{width:'200px', height:'52px'}}
            onClick={()=>{setAppointOpen(1)}}>
              {lang[langSwitch].appointCoach}{/*Призначити тренером*/}
              </button>
            <button type='button' className={res.buttonForControlPanel} 
             style={{width:'200px', height:'52px'}}
          onClick={()=>{setAppointOpen(2)}} >
            {lang[langSwitch].removeCoach}{/*Видалити тренера*/}
            </button>

          </div>

          <div className={res.itemResults2}>
            <button type='button' className={res.buttonForControlPanel} 
             style={{width:'200px', height:'52px'}}
             onClick={()=>{setAppointOpen(3)}}>
             {lang[langSwitch].appointRegionLeaderButt}{/*Призначити головою осередку*/}
              </button>

            <button type='button' className={res.buttonForControlPanel} 
             style={{width:'200px', height:'52px'}}
             onClick={()=>{setAppointOpen(4)}}>
              {lang[langSwitch].removeRegionLeaderButt}{/*Видалити голову осередку*/}
              </button>
          </div>

          <div className={res.itemResults3}>
            <button type='button' className={res.buttonForControlPanel} 
             style={{width:'200px', height:'52px'}}
             onClick={()=>{setAppointOpen(5)}}>
             {lang[langSwitch].appointJudge}{/*Призначити суддею*/}
              </button>
            <button type='button' className={res.buttonForControlPanel} 
             style={{width:'200px', height:'52px'}}
             onClick={()=>{setAppointOpen(6)}}>
              {lang[langSwitch].removeJudge}{/*Видалити суддю*/}
              </button>

          </div>

          <div className={res.itemResults4}>

            <button type='button' className={res.buttonForControlPanel} 
             style={{width:'200px', height:'52px'}}
             onClick={()=>{setAppointOpen(7)}}>
            {lang[langSwitch].appointModerator}{/*Призначити  модеретаром*/}
              </button>
            <button type='button' className={res.buttonForControlPanel} 
             style={{width:'200px', height:'52px'}}
             onClick={()=>{setAppointOpen(8)}}>
               {lang[langSwitch].removeModerator}{/*Видалити модератора*/}
               </button><br /><br />
            <button type='button' className={res.buttonForControlPanel} 
             style={{width:'200px', height:'52px'}}
             onClick={()=>{setAppointOpen(9)}}>
             {lang[langSwitch].activateFTU}{/*Активувати у ФТУ*/}
              </button>
             <button type='button' className={res.buttonForControlPanel} 
              style={{width:'200px', height:'52px'}}
              onClick={()=>{setAppointOpen(10)}}>
                {lang[langSwitch].activateSite}{/*Активувати на сайті*/}
                </button>
         
          </div>

        </>) : null}
      </div>
      </>):null} 






      <div>  {/* вікно з наданням статусу тренера */}
       {appointOpen===1?(<AppointCoach  setAppointOpen={setAppointOpen} isSmallScreen={isSmallScreen} langSwitch={langSwitch}/>):null}  
      </div>
      <div>  {/* вікно з відкликанням статусу тренера */}
      {appointOpen===2?( <DeleteCoach setAppointOpen={setAppointOpen}  isSmallScreen={isSmallScreen} langSwitch={langSwitch}/>):null}  
      </div>
      <div>  {/*вікно з наданням статусу судді */}
        {appointOpen===5?( <AppointJudge setAppointOpen={setAppointOpen}  isSmallScreen={isSmallScreen} langSwitch={langSwitch}/>):null}  
      </div>
      <div>  {/*вікно з відкликанням статусу судді */}
       {appointOpen===6?(  <DeleteJudge setAppointOpen={setAppointOpen}  isSmallScreen={isSmallScreen} langSwitch={langSwitch}/>):null}  
      </div>
      <div>  {/* вікно з  наданням статусу модератора*/}
      {appointOpen===7?( <AppointModerator setAppointOpen={setAppointOpen}  isSmallScreen={isSmallScreen} langSwitch={langSwitch}/>):null}  
      </div>
      <div>  {/* вікно з відкликанням статусу модератора */}
       {appointOpen===8?(  <DeleteModerator setAppointOpen={setAppointOpen}  isSmallScreen={isSmallScreen} langSwitch={langSwitch}/>):null}  
      </div>
      <div>  {/*Модальне вікно з  активацією профілю ФТУ*/}
      {appointOpen===9?(  <ActivFTU setAppointOpen={setAppointOpen}  isSmallScreen={isSmallScreen} langSwitch={langSwitch}/>):null}  
      </div>
<div>  {/*Модальне вікно з  активацією профілю ФТУ*/}
         {appointOpen===10?( <ActivSite setAppointOpen={setAppointOpen}  isSmallScreen={isSmallScreen} langSwitch={langSwitch}/>):null}  
      </div>
      
      <div>  {/*вікно з  наданням статусу голови обласного осередка*/}
      {appointOpen===3?(  <AppointRegionLeader setAppointOpen={setAppointOpen}isSmallScreen={isSmallScreen} langSwitch={langSwitch}/>):null}  
      </div>
      <div>  {/* вікно з  наданням статусу голови обласного осередка*/}
       {appointOpen===4?(   <DeleteRegionLeader  setAppointOpen={setAppointOpen}isSmallScreen={isSmallScreen} langSwitch={langSwitch}/>):null}  
      </div>










    </div>
   
                {/* Особисті налаштування */}
                {state.userProfile.id == 1 ? (<>

<div className={res.h4}>
<div className={res.resultTitle}>  Особисті налаштування</div>
</div>

<div className={!isSmallScreen ? res.containerPass : null}>

  <div className={res.itemResults1}>
    <div className={res.resultTitle}>

      <label htmlFor="title"><span><b>
        <div className={res.passTitle}>  Введіть ID</div>
      </b></span> </label><br />
      <input onChange={handleIDChange} type="text" id="title" name="title" /><br />

      <button type='button'         
        onClick={() => { findPass() }}>
        Показати пароль
      </button>
    </div>

    {athleteForPass ?(<>
    <div className={res.containerPassData}>
     { athleteForPass.map((item) => {
        return (<div className={res.passText} key={item.id}>
          ID: <b>{item.id}</b><br />
          <b>{state.myCoachFull(item.id)}</b> <br />
          Login:<br />
          <b> {item.login}</b> <br />
          Password:<br />
          <b>{atob(item.pass)}</b> <br />

        </div>)
      })}
      </div>
      <div className={res.containerPass}>
<div className={res.itemResults1}>
      
       <button type='button' className={res.buttonPass}         
        onClick={() => { window.print()}}>
        Друкувати
      </button>
      </div>
<div className={res.itemResults2}>       
      <button type='button' className={res.buttonPass}         
        onClick={() => { setAthleteForPass(null)}}>
        Закрити
      </button>
      </div> 
      </div>
      </>): null}
  </div>

          <div className={`${res.itemResults2} ${res.noPrint}`}>
            {resultData ?(<><div className={res.passTitle}>{resultData + 'Mb'}</div><br/> </>)  : (<><br/><br/><br/>&nbsp;</>)}
            <select onChange={handleDataChange}  >
              <option value="">
                Оберіть дані для перевірки
              </option>
              <option value={state.athletes}>{'state.athletes'}</option>
              <option value={state.competition}>{'state.competition'}</option>
              <option value={state.archive}> {'state.archive'}</option>
              <option value={state.mail}>{'state.mail'} </option>
              <option value={state.sportclubs}>{'state.sportclubs'}</option>
            </select>


          </div>
</div>

</>):null} 
<br/><br/>

  </>)
}
export default ControlPanel
