import { NavLink, useParams } from "react-router-dom";
import s from './result.module.css'
import state from '../../../state/state.js';
import { useState } from "react";
import lang from "../../../state/language.js";
import CompetitionHeder from "../adminCompetition/competitionHeder.js";




function Protocols({langSwitch}) {

  const { id } = useParams();//отримуємо id трурниру
  const [data, setData] = useState('');
  const competitionArray = state.competition.filter((tourn) => tourn.id == id); //турнир в массиві
  const competition = competitionArray[0]//отримуємо турнир з БД

  const sortedClasses = state.tournConstructor.sortClasses(competition.athletes)
  
  const sortedClassesData = sortedClasses.map((item) => {
      let category = state.tournConstructor.sortAthletesByCategories(item.athletes);
        
      Object.keys(category).forEach(key => {
        const newKey = `${key}_${item.clas}`; // Додаємо клас до назви ключа
        
        category[newKey] = category[key]; // Створюємо новий ключ
        delete category[key]; // Видаляємо старий ключ
        
            });
      return category;
  });

  const  sortedCategories =  Object.assign({}, ...sortedClassesData);

const arrayCat= Object.entries(sortedCategories)
 const arrayForWork = arrayCat.map((item) => {
  return item[1].map((at) => ({ ...at, competitionCategory: item[0] }));
});
 //"вирівнюємо" масив родлячі з вкладених масивів "рівний масив"
const flattenedArray = [].concat(...arrayForWork);

  const males = flattenedArray.filter((athlete) => athlete.sex === 'male');
  const females = flattenedArray.filter((athlete) => athlete.sex === 'female');

  const groupByTeam = (array) =>
    array.reduce((result, athlete) => {
      const teamKey = athlete.team || 'Unknown';
      const existingTeam = result.find((team) => team.team === teamKey);

      if (existingTeam) {
        existingTeam.athletes.push(athlete);
      } else {
        result.push({ team: teamKey, athletes: [athlete] });
      }

      return result;
    }, []);

  const groupedMales = groupByTeam(males);
  const groupedFemales = groupByTeam(females);


  const handleTitleChange = (e) => { setData(e.target.value) };
  
  const titleOptions = [
    lang[langSwitch].byTeams /*По командах*/
    , 
    lang[langSwitch].byCategories /*По категоріях*/
  ];

function table (t,category,sex){
  return(<>
   <table className={s.protocols}>
            <thead>
<tr>
  <td colSpan='8'>
  <div className={s.headerTitle}>
            
            <div className={s.competitionTitle}>{competition.title}</div>
              <div >
              {competition.dateStart && competition.dateFinish ? (<>
                <u><b>{state.formatDate(competition.dateStart).slice(0, 2)}</b>-
                  <b>{state.formatDate(competition.dateFinish)} р.</b></u>
              </>) : null}
              <br />
            </div>
            <div className={s.textTitle}>Протоколи зважування<br /> <b>
              {category?state.weightCatUkr (category, langSwitch):t[0].team + sex}
              </b></div>
           
          </div>
  </td>
</tr >
              <tr className={s.headerTable}>
                <td>№</td>
                <td>Учасник</td>
                <td>Команда/область</td>
                <td>Категорія</td>
                <td>Дата</td>
                <td>Вага</td>
                <td>Інше</td>
                <td>Підпис</td>
              </tr>
            </thead>
<tbody>
            {t.map((at,count) => {
              return (<>

                <tr >
                  <td>{count+1}</td>
                  <td>{at.secondName} {at.personName}</td>
                  <td>{at.team} / {state.regionShort(at.region)}</td>
                  <td>{state.weightCatUkr (category?category:at.competitionCategory, langSwitch)}</td>
                  <td>{state.dateForMessage()}</td>
                  <td> </td>
                  <td></td>
                  <td></td>
                </tr> 
              </>)
            })}
</tbody>
<tfoot>
<tr><td colSpan='3' style={{width:'50%'}}>
            <div className={s.foterOfDocument}>
              Гол. суддя  &nbsp;&nbsp;<b> {state.myCoach(competition.mainJudge)} </b>
              &nbsp;&nbsp; <span className={s.infoDescription}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                (підпис)
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>  &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
              {/* <b>  {state.dateForMessage()}</b> */}
              &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;м.п.
            </div>
          </td>
            <td colSpan='5'>
              <div className={s.foterOfDocument}>
                Гол. секретар &nbsp;&nbsp; <b> {state.myCoach(competition.secretary)}</b>
                &nbsp;&nbsp; <span className={s.infoDescription}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  (підпис)
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>  &nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

              </div>
            </td></tr>
</tfoot>
          </table>
  </>)
}

  return (<>

  
<div className={s.resultTitle}>
  
<CompetitionHeder 
    logo={competition.logo} 
    title={competition.title}
    notes={lang[langSwitch].weighingProtocols /*Протоколи зважування*/}
    />

        
    </div>
 <div className={s.containerResultsFooter}>
      <div className={s.itemResultsFooter1}>
        <button type='button' onClick={() => { window.print() }}>
        {lang[langSwitch].print /*Друкувати*/}
          </button>
      </div>
  <div className={s.itemResultsFooterCenter}>
  <label htmlFor="title"><b>
  {lang[langSwitch].selectProtocolType /* Оберіть тип протоколів:*/}
    </b></label><br/>
            <select
            id="title" value={data} onChange={handleTitleChange}>
                <option value=""></option>
                {titleOptions.map((option, index) => (
                    <option key={index}
                     value={option}>
                        {option}
                    </option>
                ))}
            </select>

  </div>
      <div className={s.itemResultsFooter2}>
        <NavLink to={`/AdminCompetition/${competition.id}`}>
          <button type='button' >
          {lang[langSwitch].comeBack /*Повернутись*/}
            </button>
        </NavLink>
      </div>
    </div>

    { data===lang[langSwitch].byCategories /*По категоріях*/?(<>  

    {arrayCat.map((t) => {
    
      return (<>
        <div className={s.mainDivForPrint}>        
          {table(t[1],t[0])}        
        </div>
      </>)
    })}


    

    <div className={s.containerResultsFooter}>
      <div className={s.itemResultsFooter1}>
        <button type='button' onClick={() => { window.print() }}>
        {lang[langSwitch].print /*Друкувати*/}
          </button>
      </div>

      <div className={s.itemResultsFooter2}>
        <NavLink to={`/AdminCompetition/${competition.id}`}>
          <button type='button' >
          {lang[langSwitch].comeBack /*Повернутись*/}
            </button>
        </NavLink>
      </div>
    </div>
    </>):null}

{ data=== lang[langSwitch].byTeams /*По командах*/?(<>
    {/* Протоколи хлопців */}
   
    {groupedMales.map((t) => {
   

      return (<>
        <div className={s.mainDivForPrint}>
        {table(t.athletes,null,' (чоловіки)')}
        </div>

      </>)
    })}


    {/* Протоколи дівчат */}

    {groupedFemales.map((t) => {
    
      return (<>
        <div className={s.mainDivForPrint}>
        {table(t.athletes,null,' (жінки)')}         
        </div>
      </>)
    })}


    <div className={s.containerResultsFooter}>
      <div className={s.itemResultsFooter1}>
        <button type='button' onClick={() => { window.print() }}>
        {lang[langSwitch].print /*Друкувати*/}
          </button>
      </div>

      <div className={s.itemResultsFooter2}>
        <NavLink to={`/AdminCompetition/${competition.id}`}>
          <button type='button' >
          {lang[langSwitch].comeBack /*Повернутись*/}
            </button>
        </NavLink>
      </div>
    </div>
    </>):null}


  </>)
}


export default Protocols