
import s from '../adminCompetition/adminCompetition.module.css';
import lang from "../../../state/language";

function Reglament ({ isOpen, onClose, regulationText,langSwitch }){
    const createMarkup = (html) => {
        return { __html: html };
      };
return( <>
  {isOpen?(<>
    <div className={s.modalWriper}>

    <div className={s.modal}   
    contentLabel="Regulation Modal"
  >
    <h2>
    {lang[langSwitch].tournamentRegulations /*Регламент турніру*/}
      </h2>
    <div dangerouslySetInnerHTML={createMarkup(regulationText)} />
    <button type='button' onClick={onClose}>
    {lang[langSwitch].close /*Закрити*/}
      </button>
  </div>
  </div>
    </>):null}
  </>)
   
}

export default Reglament