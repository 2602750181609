
import state from '../../../../state/state.js'
import s from './grids.module.css'
import GridFooter from './GridFooter.js';
import lang from '../../../../state/language.js';


function GridForAthlets2(props) {
	// console.log(props)
	const poomse=props.competition.poomse==1?true:false 
	const athleteList = props.grid.athleteList	
	const {	score1=null	} = props.grid.battleScores||{}
	const {winner=null} = props.grid.winners||{}

	const winnerAthletes = props.grid.winnerAthletes?props.grid.winnerAthletes:null


return (<>
		<div className={s.mainDiv}>
			<div className={s.title}>
				<h3>{props.competition.title}</h3>
				<h3 >
  {props.competition.dateStart&&props.competition.dateFinish?(<>
  <u><b>{state.formatDate(props.competition.dateStart).slice(0, 2)}</b>- 
        <b>{state.formatDate(props.competition.dateFinish)} р.</b></u>         
      </>):(<>
    <u><b>  {props.competition.dateOfCompetition}р.</b></u> 
      </>)}
        <br/></h3>
				<h4 id={props.id}>
				{poomse?lang.poomsaeCatUkr(props.langSwitch,props.grid.category):
	state.weightCatUkr (props.grid.category,props.langSwitch, true)}
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				Корт: {props.cort}</h4>
			</div>
			<table>
				<tbody>
					<tr>
					<td className={s.blueLeft}>
						{athleteList[0]?(<>
								
								<div className={s.divAthleteInGrid}>
									{ athleteList[0].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[0].teamPoomsae)}<i>{athleteList[0]['team']}</i></>) : (<>{state.athleteName(athleteList[0]['id'])}<br /><i>{athleteList[0]['team']}</i></>) }
								</div>	
										</>):(<>
								<div className={s.divAthleteInGrid}>
								</div></>)}

									
								<div className={s.figthLeft} >
									{props.cort}.{props.battleNumbers[0]}</div>
							
					</td>


					<td className={s.nextRed}>

  <div className={s.sportCenter}>
	<div className={s.divAthleteInGrid} >
    {winner !== null ? (<>
	<div><img className={s.goldCup} alt='фото' src = '/img/main/goldCup1.png'/></div>
	{winner.teamPoomsae?state.poomsaeTeam(winner.teamPoomsae):state.athleteNameShort(winner.id)}
<br />
	</>) :
	(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
	<div className={s.battleScore}>{score1?score1:null}</div>
  </div></div>
</td>

</tr>
					<tr>
						<td></td><td className={s.nextBlue}></td>
					</tr>
					<tr>
						<td className={s.redLeft}>
							
						<div className={s.divAthleteInGrid}>
									{athleteList[1]?(<>
								
								<div className={s.divAthleteInGrid}>
									{ athleteList[1].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[1].teamPoomsae)}<i>{athleteList[1]['team']}</i></>) : (<>{state.athleteName(athleteList[1]['id'])}<br /><i>{athleteList[1]['team']}</i></>) }
								</div>	
										</>):(<>
									<div className={s.divAthleteInGrid}>	
								</div></>)}
						</div>						
							</td>
						<td >							
							</td>
					</tr>
					<tr>
						<td></td>
						<td>							
						</td>
						</tr>
				</tbody>
			
			</table>
{/* ////////////////////////////////////////////////////// */}
<GridFooter competition={props.competition} winnerAthletes={winnerAthletes} />
	
		</div> 
	</>)
}

export default GridForAthlets2