import React, { useEffect, useState } from 'react';
import s from './calendar.module.css'
import lang from '../../../state/language';
import state from '../../../state/state';
import indexedDBConnection from '../../../state/indexedDBConnection';
import axios from 'axios';

function CalendarForAllUsers ({isSmallScreen,langSwitch}) {

const [competition, setCompetition] = useState(state.competition)
const [competitions, setCompetitions] = useState(null)

useEffect(()=>{
if(!competition||competition.length===0){
     indexedDBConnection.loadData('competition').then((competitionData) => {
                if (competitionData.length === 0) {
                    axios.get(state.api.getCompetition)
                        .then((response) => {  
                             if(response.data?.competition)   {                    
                            indexedDBConnection.saveData('competition', response.data.competition);
                            state.competition = response.data.competition; // Оновлення стану 
                            setCompetition (response.data.competition)
                         }                    
                        })
                        .catch((error) => {
                            console.error('Помилка запиту до сервера:', error);
                        });
                } else {
                    setCompetition(competitionData)
                    state.competition = competitionData; // Завантаження з IndexedDB
                }
            });
} else{

const currentDate = new Date(); // Поточна дата 
currentDate.setHours(0, 0, 0, 0)//дата без урахування часу

const competitionsFilter = competition.filter((comp) => {
// Перетворення дати закінчення з рядка в об'єкт Date
const compEndDate = new Date(comp.dateFinish)
compEndDate.setHours(0, 0, 0, 0);//дата без урахування часу

// Порівняння дати закінчення з поточною датою
return (compEndDate >= currentDate);
});

setCompetitions(competitionsFilter.sort((b, a) => new Date(a.dateStart) - new Date(b.dateStart))) 
} 
},[competition])


  return (<div>
     <div className={s.calendarWriper}> 
     <h1 className={s.h1} 
     style={{textAlign:'center', marginBottom:'40px'}}>
        {lang[langSwitch].calendar /*Календар*/}
        </h1>
     </div>
     <div>
        {competition&&
        competition
        // competitions
        .map((tourn)=>{
            return(<div key={tourn.id} style={{marginBottom:'20px'}}>
                <div className={s.competitionTitle}>{tourn.title}</div>


                {/* <div className={s.competitionLocation}>{tourn.location}</div> */}
                 <div className={s.competitionLocation}>
                    Після припинення обстрілів цивільної інфраструктури тут буде адреса проведення заходу.
                 </div>


                {/* <div className={s.competitionDate}>
                    {state.formatDate(tourn.dateStart).slice(0, 2)} - {state.formatDate(tourn.dateFinish)}
                    {lang[langSwitch].yearShort/*p.*/}
                {/* </div> */}

                <div className={s.competitionDate}>
                Після припинення обстрілів цивільної інфраструктури тут буде дата проведення заходу.
                </div>
            </div>)
        })}
     </div>

  </div>);
};

export default CalendarForAllUsers;