// file, maxSizeInMB, maxWidth, maxHeight



function compressImage(file, { maxSizeMB = 1, maxWidth, maxHeight } = {}) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const image = new Image();
      image.src = event.target.result;

      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        let width = image.width;
        let height = image.height;

        // Розрахунок коефіцієнта стиснення за вагою
        const maxSize = maxSizeMB * 1024 * 1024;
        const compressionRatio = Math.sqrt(maxSize / file.size);

        // Визначаємо фінальні розміри
        if (maxWidth && maxHeight) {
          // Якщо вказані обидва розміри – змінюємо пропорції
          width = maxWidth;
          height = maxHeight;
        } else if (maxWidth) {
          // Якщо вказана тільки ширина – висоту змінюємо пропорційно
          height = (image.height / image.width) * maxWidth;
          width = maxWidth;
        } else if (maxHeight) {
          // Якщо вказана тільки висота – ширину змінюємо пропорційно
          width = (image.width / image.height) * maxHeight;
          height = maxHeight;
        } else {
          // Якщо розміри не вказані – змінюємо пропорційно до стиснення файлу
          width *= compressionRatio;
          height *= compressionRatio;
        }

        // Малюємо нове зображення на канвасі
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(image, 0, 0, width, height);

        // Стиснення якості
        let quality = 0.9;

        const compress = (blob) => {
          if (blob.size <= maxSize || quality < 0.1) {
            resolve(new File([blob], file.name, { type: file.type }));
          } else {
            quality *= 0.9;
            canvas.toBlob(compress, file.type, quality);
          }
        };

        canvas.toBlob(compress, file.type, quality);
      };

      image.onerror = reject;
    };

    reader.readAsDataURL(file);
  });
}





//стискає без змін пропорцій (обрізає)
// function compressImage(file, { maxSizeMB = 1, maxWidth = 800, maxHeight = 800 } = {}) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();

//     reader.onload = (event) => {
//       const image = new Image();
//       image.src = event.target.result;

//       image.onload = () => {
//         const canvas = document.createElement('canvas');
//         const ctx = canvas.getContext('2d');

//         let width = image.width;
//         let height = image.height;

//         // Масштабування зображення
//         if (width > maxWidth || height > maxHeight) {
//           const ratio = Math.min(maxWidth / width, maxHeight / height);
//           width *= ratio;
//           height *= ratio;
//         }

//         canvas.width = width;
//         canvas.height = height;
//         ctx.drawImage(image, 0, 0, width, height);

//         // Отримання максимального розміру файлу
//         const maxSize = maxSizeMB * 1024 * 1024;
//         let quality = 0.9;

//         const compress = (blob) => {
//           if (blob.size <= maxSize || quality < 0.1) {
//             resolve(new File([blob], file.name, { type: file.type }));
//           } else {
//             quality *= 0.9;
//             canvas.toBlob(compress, file.type, quality);
//           }
//         };

//         canvas.toBlob(compress, file.type, quality);
//       };

//       image.onerror = reject;
//     };

//     reader.readAsDataURL(file);
//   });
// }






export default compressImage;
