import { useEffect, useState } from 'react';
import s from '../adminCompetition.module.css'
import lang from '../../../../state/language';
import ConfirmButtons from './../confirmButtons';
import BlockGrid from './blockGrid';
import state from '../../../../state/state';
import indexedDBConnection from '../../../../state/indexedDBConnection';
import axios from 'axios';

function TableGrid({ onClose, isOpen, grids, gridsDB, langSwitch, competition, isSmallScreen,controlDesk }) {

  const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [gridForRestore, setGridForRestore] = useState(null);
 const [poomsaeGrids, setPoomsaeGrids] = useState(gridsDB && Array.isArray(gridsDB) && gridsDB.length > 0 && (controlDesk==1||controlDesk==2) ? gridsDB : grids);
const [ indexDbData, setIndexDbData] = useState(false);
const [saveSwitcher, setSaveSwitcher] = useState(0);
const [cortSecretary, setCortSecretary] = useState(null);
  



function handleSelect (e){setCortSecretary(e.target.value)}

  let competitionAthletes
  if (competition.athletes) {
    try { competitionAthletes = JSON.parse(competition.athletes) }
    catch { competitionAthletes = competition.athletes }    
  }

  let judgeData, poomsaeOptions, judgeOfCort, judgesList
  if(competition.poomsaeOptions){
      try{ poomsaeOptions = JSON.parse(competition.poomsaeOptions) }catch{ poomsaeOptions = competition.poomsaeOptions }    
      if(poomsaeOptions.judge){ judgeData = poomsaeOptions.judge }
   }

   if(judgeData&&Array.isArray(judgeData) && judgeData.length > 0 ){
    judgeData.forEach((item)=>{      
      if(item.judges.some((test)=>Number(test)===Number(state.userProfile.id))){
        judgeOfCort=item.cort
      }
    })
   }
    judgeData.forEach((item)=>{ 
      if(item.cort==judgeOfCort){judgesList=item.judges}
    })

  ///////////////////////////////////////////////////////////////////////////////////////
  // Модальне вікно з кнопками погодження.

  function deleteGridsConfirm() {
    setMessageText(
      lang[langSwitch].deleteGridsFromDB/*Видалити сітки з Бази даних?*/
    )
    openConfirmButtonsModal()
  }

  function saveGridsConfirm() {
    setMessageText(
      lang[langSwitch].saveGridsToDB/* Зберегти сітки в Базі даних?*/
    )
    openConfirmButtonsModal()
  }

  function saveResultConfirm() {
    setMessageText(
      lang[langSwitch].saveCompetitionResultsInDB/* Зберегти результати змаганнь в Базі даних?*/
    )
    openConfirmButtonsModal()
  }
  const confirmFunction = (messageText) => {
    if (messageText ===
      lang[langSwitch].deleteGridsFromDB/*Видалити сітки з Бази даних?*/
    ) { deleteGridsFromDB() }
    if (messageText ===
      lang[langSwitch].saveGridsToDB/* Зберегти сітки в Базі даних?*/
    ) { saveGrids(poomsaeGrids) }
    if (messageText ===
      lang[langSwitch].saveCompetitionResultsInDB/* Зберегти результати змаганнь в Базі даних?*/
    ) { controlDesk===1? saveCortsResults() : saveCompetitionResult(poomsaeGrids) }
  }
  const openConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(true); }
  const closeConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(false); }
  ////////////////////////////////////////////////////////////////////////////////////////////
  function saveGrids(poomsaeGrids) {
    axios
      .post(state.api.gridsCompetition, {
        grids: JSON.stringify(poomsaeGrids),
        id: competition.id
      })
      .then((response) => {
        setSuccessMessage(lang[langSwitch].successfullSavedInDB/* Внесені зміни успішно збережено в Базі Даних*/);

        const index = state.competition.findIndex((comp) => comp.id == competition.id);
        state.competition[index].grids = poomsaeGrids
        indexedDBConnection.saveData('competition', state.competition)
      })
      .catch((error) => {
        setErrorMessage(
          lang[langSwitch].serverRequestError/*Помилка запиту до сервера: */ + error);
      });
  }
  //////////////////////////////////////////////////////////////////////////////

  function deleteGridsFromDB() {
    axios
    .post(state.api.gridsCompetition, {
      grids:null,
      id: competition.id
    })
    .then((response) => {
      setSuccessMessage(lang[langSwitch].successfullSavedInDB/* Внесені зміни успішно збережено в Базі Даних*/);

      const index = state.competition.findIndex((comp) => comp.id == competition.id);
      state.competition[index].grids = null
      indexedDBConnection.saveData('competition', state.competition)
    })
    .catch((error) => {
      setErrorMessage(
        lang[langSwitch].serverRequestError/*Помилка запиту до сервера: */ + error);
    });

  }
//збереження результатів роботи корту в БД
 function saveCortsResults(){

const filteredGrids = poomsaeGrids.filter((item)=>item.cort==judgeOfCort)// отримуємо дані поточного корту

let dataForDB=[], gridList=[], confirmedGrids=[]
//перевіряємо чи всі категорії відпрацювали та виводимо перелік незанінчених категорій
filteredGrids.forEach((grid)=>{
  if (grid.completed){confirmedGrids.push(grid)}
  else(gridList.push(<div key={grid.category} style={{ textAlign: 'left' }}>- {lang.poomsaeCatUkr(langSwitch, grid.category)}</div>))
})
if (confirmedGrids.length!==filteredGrids.length){
  setErrorMessage(<>
  {lang[langSwitch].matchesNotCompletedOnYourCourt/*На вашому корті ще не завершені поєдинки в наступних категоріях:*/}
  <br /><br />{gridList}</>)
  return
}


//отримуємо дані збережені з інших кортів
   axios.post(state.api.poomsaeCortResultRead, { id: competition.id })
     .then((response) => {
      const poomsaeCortResult = response.data.poomsaeCortResult
 

if (poomsaeCortResult&&Array.isArray(poomsaeCortResult)&&poomsaeCortResult.length>0){ //є записи з інших кортів
  const filteredResult = poomsaeCortResult.filter((item)=>item.cort!==judgeOfCort) //видаляємо якщо є записи поточного корту
dataForDB=[...filteredResult, ...confirmedGrids]
}else{  // немає записів з інших кортів
dataForDB=confirmedGrids
}
if(dataForDB.length>0&&dataForDB.length<poomsaeGrids.length){//зберегаємо проміжний результат


              axios.post(state.api.poomsaeCortResultToDB, {
                poomsaeCortResult: JSON.stringify(dataForDB),
                id: competition.id,
                headers: { 'Content-Type': 'application/json' }
              })

                .then((response) => {
                  console.log('response: ', response)
                  setSuccessMessage(lang[langSwitch].resultsBeenSavedForCourt/*Збережено результати турниру по корту № */+judgeOfCort)
                })
                .catch((error) => {
                  console.log('error: ', error)
                  setErrorMessage(lang[langSwitch].serverRequestError/*Помилка запиту до сервера:*/ + error);
                })

}else if(dataForDB.length>0&&dataForDB.length===poomsaeGrids.length){//зберегаємо остаточний результат

              saveCompetitionResult(dataForDB)
}

     })
     .catch((error) => {
       console.log('error: ', error)
       setErrorMessage(lang[langSwitch].serverRequestError/*Помилка запиту до сервера:*/ + error);
     })

 }
  
  function saveCompetitionResult(poomsaeGridsForDB) {
    if (!competition.athletes || (Array.isArray(competition.athletes) && competition.athletes.length === 0)) { return }
   
    function resultForAthletes() {//формуємо результат в профіль спортсмена
     

      const updateDataForString = competitionAthletes.map((compAthl) => {//мапимо спортсменів
        
        const currentCompetition = { } //об'єкт поточних змаганнь
        currentCompetition.title=competition.title
        currentCompetition.poomse=true
        currentCompetition.date=competition.dateStart
        currentCompetition.place=[] //категорія, місце
       
       
        let compAthlMyCompetition = []//отримуємо попередні результати спортсмена
        if (compAthl.myCompetition) {
          try { compAthlMyCompetition = JSON.parse(compAthl.myCompetition) }
          catch { compAthlMyCompetition = compAthl.myCompetition }
        }
        const result = {// формуємо пакет для БД        
          id: compAthl.id,
          battles: compAthl.battles,
          rating: compAthl.rating,
          victory: compAthl.victory,
          myCompetition:[...compAthlMyCompetition,currentCompetition ],
          lastChangesDate:state.lastChangesDateFormat()//Дата останьої зміни профіля
        }
        
        poomsaeGridsForDB.forEach((grid)=>{  //мапимо турнірні сітки
              const placeResult={//формуємо категорію та місце
                category:grid.category
              } 
              grid.athletes.forEach((athlete)=>{
                
                if (athlete.teamPoomsae){ //якщо зареєстрована команда
                  athlete.teamPoomsae.forEach((teamMember)=>{

                    if(teamMember.id==compAthl.id){
                      placeResult.place=athlete.place                     
                      currentCompetition.place.push(placeResult) //повертаємо категорію та місце
                   }
                  })

                }else{ //якщо зареєстрований спортсмен
                  if(athlete.id==compAthl.id){
                    placeResult.place=athlete.place                   
                    currentCompetition.place.push(placeResult) //повертаємо категорію та місце
                 }
                }
              })

            })

        return result // повертаємо пакет для БД
      })
      const updateData = updateDataForString.map((item)=>{
        return{...item, myCompetition:JSON.stringify(item.myCompetition)}
      })
       
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function updateDB(){
 // вносимо результати в профіль турниру
 axios 
 .post(state.api.updateCompetitionResult, {
   results:JSON.stringify(poomsaeGridsForDB),
   id:competition.id,
   headers: {'Content-Type': 'application/json'}
 })
 .then((response) => {

///////////////////////заносимо результати в архів///////////////////////////////////////
const dateForArchive=`${state.formatDate(competition.dateStart).slice(0, 2)} - ${state.formatDate(competition.dateFinish)}`


axios 
.post(state.api.saveResultInArchive, {
title: competition.title,
dateOfCompetition: dateForArchive,
results: JSON.stringify(poomsaeGridsForDB),
location: competition.location,
secretary:competition.secretary,
mainJudge:competition.mainJudge,
poomse:1,
athletes:competition.athletes,
 headers: {'Content-Type': 'application/json'}
})
.then((response) => {
setSuccessMessage(
 lang[langSwitch].successfullSavedResultsTournamentInDB/*Результати змагань занесено в Базу Даних. Ваш архів буде оновлено після повторної авторизації.*/
 );
})
.catch((error) => {
console.log(error);
setErrorMessage(
 lang[langSwitch].serverRequestError/*Помилка запиту до сервера:*/ 
  + error);
});


//////////////////////////////////////////////////////////////////

   setSuccessMessage(
     lang[langSwitch].successfullSavedResultsTournamentInDB/*Результати змагань занесено в Базу Даних. Ваш архів буде оновлено після повторної авторизації.*/
   );
   const index = state.competition.findIndex((item)=>{return item.id==competition.id})
   state.competition[index].results = JSON.stringify(poomsaeGridsForDB);
   indexedDBConnection.saveData ('competition', state.competition)
  
/////////////////////////////////////////////////////////////////////////////////  
//чистимо поле промшжних результатів для збереження через пульти
   axios.post(state.api.poomsaeCortResultToDB, {
    poomsaeCortResult: null,
     id: competition.id,
     headers: { 'Content-Type': 'application/json' }
   })
     .then((response) => {})
     .catch((error) => {})



//Заносимо результати в профіль спортсмена

   axios.post(state.api.postCompetitionResultToAthletProfile, { updateData: updateData })
   .then(response => { })
   .catch(error => {
     console.error('Помилка Axios-запиту, невдалось зберегти результати турнирув профілі атлетів:', error);
   });



  reservCopy()//видаляємо резервну копію результатів
        })
 .catch((error) => {
   const reservResult={
     competition:competition,
     results:poomsaeGridsForDB,
     updateData:updateData
   }
   localStorage.setItem('reservResult',JSON.stringify(reservResult))
   setErrorMessage(
     lang[langSwitch].serverRequestError/*Помилка запиту до сервера: */
      + error);
      setSuccessMessage("Ваш запит буде виконано у фоновому режимі під час наступного підключення до мережі.")
 });
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
     updateDB() 
    }
  
    resultForAthletes()

  }

//відновлення результатів у випадку збою чи перезавантаження сторінки
  function restoreGrids(){
   
    axios.post(state.api.poomsaeReservCopyRead, { id: competition.id })
    .then((response) => {

      // console.log('Отримані дані:', response.data.poomsaeReservCopy);
      if(response.data.poomsaeReservCopy){
      setPoomsaeGrids(response.data.poomsaeReservCopy)
      setSuccessMessage( lang[langSwitch].restoredAutomaticallySavedResults/*Відновлено автоматично збережені результати*/)
      setIndexDbData(true)
    }else{setErrorMessage(lang[langSwitch].noBackup/*Відсутня резервна копія*/)}
  })
  .catch((error) => {
      console.error('Помилка при завантаженні даних:', error);
  });
  }
  
  function reservCopy(array){//зберігаємо в БД копію промізних результатів
    axios.post(state.api.poomsaeReservCopyToDB, {
      poomsaeReservCopy:array? JSON.stringify(array):null,
      id: competition.id,
      headers: { 'Content-Type': 'application/json' }
    })
      .then((response) => { })
      .catch((error) => {
        console.log('Помилка збереження копії результатів категорії: ', error)      
      })
  }

 //резервна копія результатів категорії
useEffect(()=>{
  if(gridForRestore) { 
    // console.log('gridForRestore: ', gridForRestore)
  const gridsTemptIndex = poomsaeGrids.findIndex((item)=>item.category===gridForRestore.category)
  poomsaeGrids[gridsTemptIndex] =  gridForRestore
  // indexedDBConnection.saveData('grids',  {id:competition.id, grids:poomsaeGrids});

  reservCopy(poomsaeGrids)
  }
},[gridForRestore])


//резервна копія результатів виступу спортсмена
useEffect(()=>{
  if(saveSwitcher!==0){
  //  indexedDBConnection.saveData('grids',  {id:competition.id, grids:poomsaeGrids});

  reservCopy(poomsaeGrids)
  }
  },[saveSwitcher])
 


let cutedPoomsaeGrids
if(Number(state.userProfile.id)!==Number(competition.secretary)&&Number(state.userProfile.id)!==Number(competition.mainJudge)){
cutedPoomsaeGrids=poomsaeGrids.filter((grid)=>grid.cort==judgeOfCort)
}else{
  cutedPoomsaeGrids=poomsaeGrids
}

  return (<>
  {isOpen?(<>
    <div className={sAdmin.modalWriper}>
    <div className={s.modalGrids}>
      <div className={isOpen ? s.modalButtons : s.modalButtonsNone}><br />

        <div className={s.containerAdmin}>
          <div className={s.itemAdmin1}>
            <button type='button' className={s.button} onClick={() => { saveGridsConfirm(); }}>
              {lang[langSwitch].saveGridsInDB /*Зберегти сітки в БД*/}
            </button>
            <button type='button' className={s.button} onClick={() => {
              deleteGridsConfirm();
            }}>
              {lang[langSwitch].deleteFromDB /* Видалити з БД*/}
            </button>
          
 </div>  
       <div className={s.itemAdmin2}>
      {judgeOfCort?(<>  
          <div style={{fontSize:'12px',fontWeight:'bold',paddingTop:'8px'}}>
             {lang[langSwitch].chooseSecretary/*Оберіть секретаря*/}</div>


        <select  onChange={handleSelect} style={{ marginBottom: '10px', width: '100px'}}>
            <option value=""></option>
            {judgesList.map((number,index) => (             
              <option key={number+index} value={number}>
                {state.myCoach(number)}
              </option>
            ))}
          </select> 
               
</>):null}

 </div>


            <div className={s.itemAdmin3}>
              {/* {controlDesk===1?(<> */}
              <button type='button' className={s.button} onClick={() => { restoreGrids() }}>
              {lang[langSwitch].restoreResults /*Відновити результати*/}
            </button> 
              {/* </>):null} */}
                     
            </div>

          <div className={s.itemAdmin4}>
            <button type='button' className={s.button} onClick={() => { saveResultConfirm(); }}>
              {lang[langSwitch].saveResults /*Зберегти результати*/}
            </button>
            <button type='button' className={s.redButton}
              onClick={() => { onClose() }}>
              {lang[langSwitch].exit /*Вийти*/}
            </button>
          </div>
        </div>

      </div>

      {errorMessage ? (<><div className={s.errorMessage} onClick={() => { setErrorMessage(null) }}
        style={{ cursor: 'pointer' }}>
        {errorMessage}
      </div>
      </>) : null}
      {successMessage ? (<><div className={s.successMessage} onClick={() => { setSuccessMessage(null) }}
        style={{ cursor: 'pointer' }}>
        {successMessage}
      </div>
      </>) : null}
      {/*Виклик Вікна погодження */}
      <ConfirmButtons
        isOpen={isConfirmButtonsModalOpen}
        onClose={closeConfirmButtonsModal}
        confirmFunction={confirmFunction}
        messageText={messageText}
        langSwitch={langSwitch}
       
      />
      {cutedPoomsaeGrids.map((item,index) => {
       
        return (
          <BlockGrid
          key={item.category+index}
            competition={competition}
            grid={item}
            langSwitch={langSwitch}
            controlDesk={controlDesk}
            setGridForRestore={setGridForRestore}
            setPoomsaeGrids={setPoomsaeGrids}
            indexDbData={indexDbData}
             setSaveSwitcher={setSaveSwitcher}
             judgeData={judgeData}
             cortSecretary={cortSecretary}
            />
       )
      })}

    </div>
    </div>
    </>):null}
  </>)
}

export default TableGrid