import React, { useState } from "react";
import s from './userProfile.module.css'
import lang from "../../../state/language";

function ChangePasswordForm({ updatePassword,setPass,langSwitch }) {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState(""); // Додано стан для зберігання повідомлення

  const handleCurrentPasswordChange = (e) => { 
    setCurrentPassword(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    
    setShowPassword(!showPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
   
    if (newPassword === confirmPassword) {
      if (newPassword.length >= 5) {
        if (currentPassword === atob(updatePassword)) {
          setPass(btoa(newPassword));
         
          setMessage(<div className={s.smallGreen}>
            {lang[langSwitch].successfulPasswordChangeMessage}{/*Пароль успішно змінено. Незабудьте зберегти результати!!! Кнопка знизу ліворуч.*/}
            </div>); // Встановлюємо повідомлення
        } else {
          setMessage(<div className={s.smallRed}>
            {lang[langSwitch].wrongCurrentPass}{/*Поточний пароль невірний.*/}
          </div>);
        }
      } else {
        setMessage(<div className={s.smallRed}>
          {lang[langSwitch].passwordSymbolsWarnin}{/*Пароль повинен містити щонайменше 5 символів.*/}
          </div>);
      }
    } else {
      setMessage(<div className={s.smallRed}>
       {lang[langSwitch].notConfirmPass}{/*Новий пароль не співпадає з підтвердженням.*/}
        </div>);
    }
  };

  return (
    <div>
      {message && 
      <div className={s.message} onClick={()=>{setMessage('')}}>
        {message}
        </div>} {/* Відображення повідомлення на екрані */}
      <div>
        <label htmlFor="currentPassword"><br/>
          <span className={s.span}>
          {lang[langSwitch].сurrentPass}{/*Поточний пароль*/}:
            </span><br/>
          <input
         
         autoComplete="off"
            type={showPassword ? "text" : "password"}
            id="currentPassword"
            name="currentPassword"
           
            onChange={handleCurrentPasswordChange}
          />
        </label>
      </div><br/>

      <div>
        <label htmlFor="newPassword">
          <span className={s.span}>
          {lang[langSwitch].newPass}{/*Новий пароль*/}:
          </span><br/>
          <input
          autoComplete="off"
            type={showPassword ? "text" : "password"}
            id="newPassword"
            name="newPassword"
           
            onChange={handleNewPasswordChange}
          />
        </label>
      </div><br/>

      <div>
        <label htmlFor="confirmPassword">
          <span className={s.span}>
          {lang[langSwitch].confirmNewPass}{/*Підтвердіть новий пароль*/}:
          </span><br/>
          <input
          autoComplete="off"
            type={showPassword ? "text" : "password"}
            id="confirmPassword"
            name="confirmPassword"
           
            onChange={handleConfirmPasswordChange}
          />
        </label>
      </div><br/>
      <div>
        <label>
          <input
            type="checkbox"
            checked={showPassword}
            onChange={toggleShowPassword}
          />
          <span className={s.span}>
          {lang[langSwitch].showPass}{/*Показати паролі*/}
          </span>
        </label>
      </div>
      <button type="button" onClick={handleSubmit}>
      {lang[langSwitch].changePass}{/*Змінити пароль*/}
      </button>
    </div>
  );
}

export default ChangePasswordForm;
