// Вдкриває  турнір з календаря федерації.
// містить загальну інформацію про турнир, та кнопки  реєстрації спортсменів для участі.

import { NavLink, useParams, } from 'react-router-dom';
import s from '../adminCompetition/adminCompetition.module.css';
import state from '../../../state/state.js';
import { useState } from 'react';
import Reglament from './reglament';
import Entry from './entry';
import Withdraw from './withdraw';
import CategoriesList from './categoriesList';
import TeamsList from './teamsList';
import CoachesList from './coachesList';
import FoodEntry from './foodEntry';
import CommentBlock from './commentBlock.js';
import lang from '../../../state/language.js';
import EntryPoomsae from './entryPoomsae.js';
import WithdrawPoomsae from './withdrawPoomsae.js';

function Competition(props) {
  const langSwitch = props.langSwitch
  state.messageCount()//лічильник непрочитаних повідомлень
  const { id } = useParams();//отримуємо id

  const competitionArray = state.competition.filter((tourn) => tourn.id == id);
  const competition = competitionArray[0]
  const poomse = competition.poomse == 1 ? true : false

  const [isRegulationModalOpen, setIsRegulationModalOpen] = useState(false);
  const [isCategoriesListModalOpen, setIsCategoriesListModalOpen] = useState(false);
  const [isFoodEntryModalOpen, setIsFoodEntryModalOpen] = useState(false);
  const [teamsListModalOpen, setTeamsListModalOpen] = useState(false);
  const [coachesListModalOpen, setCoachesListModalOpen] = useState(false);
  const [entryModalOpen, setEntryModalOpen] = useState(false);
  const [entryPoomsaeModalOpen, setEntryPoomsaeModalOpen] = useState(false);
  const [withdrawModalOpen, setWithdrawModalOpen] = useState(false);
  const [withdrawPoomsaeModalOpen, setWithdrawPoomsaeModalOpen] = useState(false);


  let poomsaeCategories, poomsaeOptions, cortJudge=0, classB1, classB2, classB3, classB4

  if(competition.poomsaeOptions){ try{poomsaeOptions=JSON.parse(competition.poomsaeOptions)} catch{poomsaeOptions=competition.poomsaeOptions} }
  
  if(poomsaeOptions){
    poomsaeOptions.judge.some((item) => {
      if(item.judges.some((j) => j == state.userProfile.id)){
        cortJudge = item.cort;
        return true; // Зупиняє цикл
      }
      return false;
    });
  }
  
  if (competition.poomsaeCategories !== null) { try { poomsaeCategories = JSON.parse(competition.poomsaeCategories) } catch { poomsaeCategories = competition.poomsaeCategories } }

  if (competition.athletes === '[]' ||
    Array.isArray(competition.athletes) && competition.athletes.length === 0
  ) {
    competition.athletes = null
  }



  const uniqueTeams = [];
  let athletes, numberOfCategories, sortedCategories, grids,  athletesFullArray
  try { athletes = JSON.parse(competition.athletes) } catch { athletes = competition.athletes }
if(competition.grids){ try { grids = JSON.parse(competition.grids) } catch { grids = competition.grids }}
  const uniqueCoach = [];
  const uniqueRegions = [];
 

  if (competition.athletes !== null) {

    const test = poomse ? athletes : state.tournConstructor.entryAthletesFind(athletes)

    test.map((athlete) => {
      if (!uniqueTeams.includes(athlete.team)) {
        uniqueTeams.push(athlete.team); // Додати "team" до унікальних значень, якщо воно відсутнє
      }
    })



    if (athletes&&Array.isArray(athletes)&&athletes.length > 0) {

      athletesFullArray = state.tournConstructor.sortClasses(athletes)

    athletesFullArray.map((item)=>{          
  if(item.clas==='B1'){classB1=state.tournConstructor.sortAthletesByCategories(item.athletes) }
  if(item.clas==='B2'){classB2=state.tournConstructor.sortAthletesByCategories(item.athletes) }  
  if(item.clas==='B3'){classB3=state.tournConstructor.sortAthletesByCategories(item.athletes) }       
  if(item.clas==='B4'){classB4=state.tournConstructor.sortAthletesByCategories(item.athletes) }
 
               

      }) 
     

      sortedCategories = state.tournConstructor.sortAthletesByCategories(athletes)  
    }


    if (sortedCategories) {
      if (poomse) {
        numberOfCategories = poomsaeCategories ? poomsaeCategories.length : 0
      } else {// кількісь категорій
       if(grids && Array.isArray(grids) && grids.length>0) {
        numberOfCategories=grids.length
       }else{
   
        numberOfCategories = 
        (classB1? Object.keys(classB1).length:null)+
        (classB2? Object.keys(classB2).length:null)+
        (classB3? Object.keys(classB3).length:null)+
        (classB4? Object.keys(classB4).length:null)
       } 
      }
    }
    // Отримати унікальні значення "region"

    // Обійти всі ключі у головному об'єкті
    for (const categoryKey in sortedCategories) {
      // Перевірити, чи це масив
      if (Array.isArray(sortedCategories[categoryKey])) {
        // Обійти всі об'єкти в масиві
        for (const athlete of sortedCategories[categoryKey]) {
          // Додати "region" до унікальних значень, якщо воно відсутнє
          if (!uniqueRegions.includes(athlete.region)) {
            uniqueRegions.push(athlete.region);
          }
        }
      }

      ////////////////////////////////////////////////////////
      // Отримати унікальні значення "coach"

      // Обійти всі ключі у головному об'єкті
      for (const categoryKey in sortedCategories) {
        // Перевірити, чи це масив
        if (Array.isArray(sortedCategories[categoryKey])) {
          // Обійти всі об'єкти в масиві
          for (const athlete of sortedCategories[categoryKey]) {

            // Додати "team" до унікальних значень, якщо воно відсутнє
            if (!uniqueCoach.includes(athlete.coach) && athlete.coach !== null && athlete.coach !== undefined) {
              uniqueCoach.push(athlete.coach);
            }

          }
        }
      }
    }
  }


  // модальні вікна

  // Отримати регламент турніру, наприклад, з props
  const regulationText = competition.reglament
  // Модальне вікно з регламентом
  const openRegulationModal = () => { setIsRegulationModalOpen(true); }
  const closeRegulationModal = () => { setIsRegulationModalOpen(false); }
  // Модальне вікно з заявкую на їжу
  const openFoodEntryModal = () => { setIsFoodEntryModalOpen(true); }
  const closeFoodEntryModal = () => { setIsFoodEntryModalOpen(false); }
  // Модальне вікно з заявкою спортсменів
  const openEntryModal = () => { setEntryModalOpen(true); }
  const closeEntryModal = () => { setEntryModalOpen(false); }
  // Модальне вікно з заявкою Пхумсе
  const openEntryPoomsaeModal = () => { setEntryPoomsaeModalOpen(true); }
  const closeEntryPoomsaeModal = () => { setEntryPoomsaeModalOpen(false); }


  // Модальне вікно з заявкою спортсменів
  const openTeamsListModal = () => { setTeamsListModalOpen(true); }
  const closeTeamsListModal = () => { setTeamsListModalOpen(false); }

  // Модальне вікно з Тренерами
  const openCoachesListModal = () => { setCoachesListModalOpen(true); }
  const closeCoachesListModal = () => { setCoachesListModalOpen(false); }

  // Модальне вікно з відкликанням заявки спортсменів
  const openWithdrawModal = () => { setWithdrawModalOpen(true); }
  const closeWithdrawModal = () => { setWithdrawModalOpen(false); }

  // Модальне вікно з відкликанням Пхумсе заявки спортсменів
  const openWithdrawPoomsaeModal = () => { setWithdrawPoomsaeModalOpen(true); }
  const closeWithdrawPoomsaeModal = () => { setWithdrawPoomsaeModalOpen(false); }

  // Модальне вікно з регламентом
  const openCategoriesListModal = () => { setIsCategoriesListModalOpen(true); }
  const closeCategoriesListModal = () => { setIsCategoriesListModalOpen(false); }



  return (
    <div>
      <div><br />  {/* Шапка турниру: Назва, Дати, місце проведення */}
        <div className={s.title}>{competition.title}
          <p><u><b>{state.formatDate(competition.dateStart).slice(0, 2)}</b>-
            <b>{
              state.formatDate(competition.dateFinish) + ' ' +
              lang[langSwitch].yearShort /*р.*/
            } </b></u> <br /></p>
          <p><span>{competition.location}</span></p>
          {!+competition.active && (<>
          <div className={s.h3} style={{color:'#ff0000'}}>{ lang[langSwitch].registrationIsClosed /*Реєстрацію закрито*/}</div>
        </>)}
        </div> {/* Закриття шапки */}

        {/* Статистика змагань */}
        <div className={s.contentTitleItem1}>
          <div className={s.title1}><span className={s.statistic}><p>
            {!props.isSmallScreen ? (<>{lang[langSwitch].declared /*Заявлено*/}:</>) : null}
            <b> {athletes === null || athletes === undefined ? '0' : athletes.length} </b>
            {lang[langSwitch].of /*з*/}
            <b> {competition.numberOfAthletes} </b>
            {!props.isSmallScreen ?
              lang[langSwitch].participantsShort /*уч.*/
              : (
                <><br />
                  {lang[langSwitch].participants /*учасників*/}
                </>)}
          </p></span></div>

          <div className={s.title2}><span className={s.statistic}><p><b>
            {uniqueTeams ? uniqueTeams.length : null} </b>
            {props.isSmallScreen ? (<><br />&nbsp;</>) : null}
            {uniqueTeams ? uniqueTeams.length % 10 === 0 ?
              lang[langSwitch].teams /*команд*/
              : '' : null}
            {uniqueTeams ? uniqueTeams.length % 10 === 1 ?
              lang[langSwitch].team /*команда */
              : '' : null}
            {uniqueTeams ? uniqueTeams.length % 10 >= 2 && uniqueTeams.length % 10 <= 4 ?
              lang[langSwitch].teams2 /*команд*/
              : '' : null}
            {uniqueTeams ? uniqueTeams.length % 10 >= 5 && uniqueTeams.length % 10 <= 9 ?
              lang[langSwitch].teams /*команд*/
              : '' : null}

          </p></span></div>

          <div className={s.title3}><span className={s.statistic}><p>
            {lang[langSwitch].from /*з*/}&nbsp;
            <b>
              {uniqueRegions ? uniqueRegions.length : null} </b>
            {props.isSmallScreen ? (<><br />&nbsp;</>) : null}
            {uniqueRegions ? uniqueRegions.length % 10 === 0 ?
              ' ' + lang[langSwitch].regionPlural /*регіонів*/
              : '' : null}
            {uniqueRegions ? uniqueRegions.length % 10 === 1 && uniqueRegions.length % 10 !== 11 ?
              ' ' + lang[langSwitch].regionSingular /*регіону*/
              : '' : null}
            {uniqueRegions ? uniqueRegions.length % 10 > 1 && uniqueRegions.length % 10 !== 11 ?
              ' ' + lang[langSwitch].regionPlural /*регіонів*/
              : '' : null}
          </p></span></div>


          <div className={s.title6}><span className={s.statistic}>
            <p ><b>{numberOfCategories ? numberOfCategories : null} </b>
              {props.isSmallScreen ? (<><br />&nbsp;</>) : null}
              {numberOfCategories ? numberOfCategories % 10 === 0 ?
                lang[langSwitch].categories2 /*категорій*/
                : '' : null}
              {numberOfCategories ? numberOfCategories % 10 === 1 && numberOfCategories !== 11 ?
                lang[langSwitch].category /*категорія*/
                : '' : null}
              {numberOfCategories ? numberOfCategories % 10 === 2 && numberOfCategories !== 12 ?
                lang[langSwitch].categories /*категорії*/
                : '' : null}
              {numberOfCategories ? numberOfCategories % 10 === 3 && numberOfCategories !== 13 ?
                lang[langSwitch].categories /*категорії*/
                : '' : null}
              {numberOfCategories ? numberOfCategories % 10 === 4 && numberOfCategories !== 14 ?
                lang[langSwitch].categories /*категорії*/
                : '' : null}
              {numberOfCategories ? numberOfCategories % 10 >= 5 && numberOfCategories % 10 <= 9 ?
                lang[langSwitch].categories2 /*категорій*/
                : '' : null}
              {numberOfCategories ? numberOfCategories >= 11 && numberOfCategories <= 14 ?
                lang[langSwitch].categories2 /*категорій*/
                : '' : null}

            </p></span></div>


          <div className={s.title7}><span className={s.statistic}><p><b>
            {uniqueCoach ? uniqueCoach.length : null} </b>
            {props.isSmallScreen ? (<><br />&nbsp;</>) : null}
            {uniqueCoach ? uniqueCoach.length % 10 === 0 ?
              lang[langSwitch].coaches1 /*тренерів*/
              : '' : null}
            {uniqueCoach ? uniqueCoach.length % 10 === 1 && uniqueCoach.length !== 11 ?
              lang[langSwitch].coach /*тренер*/
              : '' : null}
            {uniqueCoach ? uniqueCoach.length % 10 === 2 && uniqueCoach.length !== 12 ?
              lang[langSwitch].coaches2 /*тренера*/
              : '' : null}
            {uniqueCoach ? uniqueCoach.length % 10 === 3 && uniqueCoach.length !== 13 ?
              lang[langSwitch].coaches2 /*тренера*/
              : '' : null}
            {uniqueCoach ? uniqueCoach.length % 10 === 4 && uniqueCoach.length !== 14 ?
              lang[langSwitch].coaches2 /*тренера*/
              : '' : null}
            {uniqueCoach ? uniqueCoach.length % 10 >= 5 && uniqueCoach.length % 10 <= 9 ?
              lang[langSwitch].coaches1 /*тренерів*/
              : '' : null}
            {uniqueCoach ? uniqueCoach.length >= 11 && uniqueCoach.length <= 14 ?
              lang[langSwitch].coaches1 /*тренерів*/
              : '' : null}
          </p></span></div>

        </div>{/* Закриття статистики */}

      </div>


      <div className={!props.isSmallScreen ? s.container : s.td1}>
        <div className={s.item1}>
          <div>
            <img src={competition.logo ? competition.logo : state.noLogoPhoto}
              alt={`${competition.title}`} style={{ borderRadius: "5px" }} />  {/*Лого турниру */}
          </div>
        </div>
        <div className={!props.isSmallScreen ? s.item2 : s.td1}>


          {/* початок умови для приховання від спортсменів кнопок з заявкою на турнир */}
 <div className={s.competitionButtonsL}>
          {state.userProfile.status === 'Тренер' ? (<>
           
              <a href={competition.reglamentFhoto} target='_blank' download>
                <button type='button' className={s.button}>
                  {lang[langSwitch].regulationsPDF /*Регламент PDF*/}
                </button>
              </a><br />

              <button className={s.button} type='button' onClick={openFoodEntryModal}>
                {lang[langSwitch].applicationForFood /*Заявка на харчування*/}
              </button> {/* Кнопка у файлі */}<br />



              {poomse ? (<>
                <button className={s.button} type='button' onClick={openEntryPoomsaeModal}>
                  {lang[langSwitch].submitAnApplication /*Подати заявку*/}
                </button> {/* Кнопка у файлі */}<br />

                <button className={s.button} type='button' onClick={openWithdrawPoomsaeModal}>
                  {lang[langSwitch].withdrawApplication /* Відкликати заявку*/}
                </button>  {/* Кнопка у файлі */}<br />
              </>)
                :
                (<>
                  <button className={s.button} type='button' onClick={openEntryModal}>
                    {lang[langSwitch].submitAnApplication /*Подати заявку*/}
                  </button> {/* Кнопка у файлі */}<br />



                  <button className={s.button} type='button' onClick={openWithdrawModal}>
                    {lang[langSwitch].withdrawApplication /* Відкликати заявку*/}
                  </button>  {/* Кнопка у файлі */}<br />
                </>)}

              {poomse && competition.athletes && competition.poomsaeCategories ? (<>
                <NavLink to={`/PoomsaeEntryPrint/${id}`}>
                  <button className={s.button} type='button'>
                    {lang[langSwitch].printApplication /*Друкувати заявку*/}
                  </button>
                </NavLink>
                <br />
              </>) : null}
              {sortedCategories && competition.athletes !== null && !poomse ?
                (<>
                  <NavLink to={`/entryPrint/${id}`}>
                    <button className={s.button} type='button' >
                      {lang[langSwitch].printApplication /*Друкувати заявку*/}
                    </button>
                  </NavLink> <br />
                </>) : null}

          </>) : null}
          {/* Умова для роздачі пультів суддям  */}
          {Number(state.userProfile.judge)==1&&cortJudge!=0&&(competition.grids||(Array.isArray(competition.grids)&&competition.gridslength>0))?(<>

            <NavLink to={`/AdminCompetition/${competition.id}`}>
            <button className={s.button} type='button'>
            {lang[langSwitch].toAdminister /*Адмініструвати*/} 
            </button>
          </NavLink>

           <button className={s.button} type='button' onClick={()=>{props.remoteControl({competition:competition,cortJudge:cortJudge}, true)}}>
            {lang[langSwitch].remoteControl /*Пульт*/}
           </button>

            <button className={s.button} type='button'  onClick={()=>{props.remotePoomsaeMonitor({competitionTitle:competition.title, cort:cortJudge,langSwitch:langSwitch}, true)}}>
            {lang[langSwitch].scoreboard/*Монітор*/}
           </button>


          </>):null}
 </div>

          {/* кінець умови для приховання від спортсменів кнопок з заявкою на турнир */}

          <div className={s.competitionButtonsR}>
            <button className={s.button} type='button' onClick={openRegulationModal}>
              {lang[langSwitch].showRegulations /*Показати регламент*/}
            </button> {/* Кнопка у файлі */}<br />
            <button className={s.button} type='button' onClick={openTeamsListModal}>
              {lang[langSwitch].announcedTeams /*Заявлені команди*/}
            </button> {/* Кнопка у файлі */}<br />

            <button className={s.button} type='button' onClick={openCoachesListModal}>
              {lang[langSwitch].coaches /*Тренери*/}
            </button> {/* Кнопка у файлі */}<br />

            <button className={s.button} type='button' onClick={openCategoriesListModal}>
              {lang[langSwitch].showCategories /*Показати категорії*/}
            </button> {/* Кнопка у файлі */}




            {competition.results ? (<>
              <div className={s.itemAdmin4}>
                <NavLink to={`/results/${id}`}>
                  <button type='button' className={s.button} >
                    {lang[langSwitch].results /*Результати*/}
                  </button>
                </NavLink>
              </div>
            </>) : null}
          </div>
        </div>
      </div>

      <div>{/*блок з коментарями*/}
        <CommentBlock
          id={competition.id}
          information={competition.information}
          isSmallScreen={props.isSmallScreen}
          langSwitch={langSwitch}
        />

      </div>
      <br />


      <div>
        {/*Модальне вікно з регламентом */}
        <Reglament
          isOpen={isRegulationModalOpen}
          onClose={closeRegulationModal}
          regulationText={regulationText}
          langSwitch={langSwitch}
        />

        {/*Модальне вікно з заявкою на харчування */}
        {isFoodEntryModalOpen?(<>
        <FoodEntry
          isSmallScreen={props.isSmallScreen}        
          onClose={closeFoodEntryModal}
          competition={competition}
          langSwitch={langSwitch} />

          </>):null}
        {/*Модальне вікно з відкликанням заявки спортсменів */}

        {poomse && competition.athletes !== null && Array.isArray(poomsaeCategories) && poomsaeCategories.length > 0 ?
          <WithdrawPoomsae
            isSmallScreen={props.isSmallScreen}
            tournId={id}
            isOpen={withdrawPoomsaeModalOpen}
            onClose={closeWithdrawPoomsaeModal}
            langSwitch={langSwitch}
          /> : null}

        {poomse ? (<>
          {/*Модальне вікно з заявкою Пхумсе */}

          <EntryPoomsae
            isSmallScreen={props.isSmallScreen}
            tournId={id}
            isOpen={entryPoomsaeModalOpen}
            onClose={closeEntryPoomsaeModal}
            langSwitch={langSwitch}
          />
        </>) : (<>
          {/*Модальне вікно з заявкою спортсменів */}
         
          <Entry
            isSmallScreen={props.isSmallScreen}
            tournId={id}
            isOpen={entryModalOpen}
            onClose={closeEntryModal}
            langSwitch={langSwitch}
          />
    
        </>)}
        {/*Модальне вікно з відкликанням заявки спортсменів */}

        {sortedCategories && competition.athletes !== null ?
          <Withdraw
            isSmallScreen={props.isSmallScreen}
            tournId={id}
            isOpen={withdrawModalOpen}
            onClose={closeWithdrawModal}
            langSwitch={langSwitch}
          /> : null}

        {/*Модальне вікно з списком команд */}
        {sortedCategories && competition.athletes !== null ?
          <TeamsList
            isSmallScreen={props.isSmallScreen}
            competition={competition}
            isOpen={teamsListModalOpen}
            onClose={closeTeamsListModal}
            langSwitch={langSwitch}
          />
          : null}

        {/*Модальне вікно з списком тренерів */}
        {sortedCategories && competition.athletes !== null ?
          <CoachesList
            isSmallScreen={props.isSmallScreen}
            competition={competition}
            isOpen={coachesListModalOpen}
            onClose={closeCoachesListModal}
            langSwitch={langSwitch}

          />
          : null}


        {/*Модальне вікно з категоріями */}
        {poomse && Array.isArray(poomsaeCategories) && poomsaeCategories.length > 0 || !poomse && sortedCategories && competition.athletes !== null ?
          <CategoriesList
            isSmallScreen={props.isSmallScreen}
            isOpen={isCategoriesListModalOpen}
            onClose={closeCategoriesListModal}
            classB1={classB1}
            classB2={classB2}
            classB3={classB3}
            classB4={classB4}
            competition={competition}
            langSwitch={langSwitch}
            poomsaeCategories={poomsaeCategories}
            poomse={poomse}
          />
          : null}
      </div>
    </div>
  );
}

export default Competition;
