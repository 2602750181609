import lang from '../../../../state/language'
import s from './food.module.css'
import FooterButtons from './footerButtons'

function OrderForProduction({isSmallScreen, title,isOpen,foodData,foodOptions,langSwitch}){
const data =[]

foodData.map((item)=>{
item.data.map((item2)=>{data.push(item2)})
})
let count=0;

    return(<>
       
<div className={s.printContent}>
<div className={s.printHeader}>
{lang[langSwitch].orderForFoodProduction /* Замовлення на виробництво харчування */}      
 <div className={s.printTitle}>{title} </div>
</div>

<table className={s.foodPrintTable} style={{textAlign:'center'}}>
<thead>
<tr>
    <td rowSpan={2}>{lang[langSwitch].date /*Дата*/}</td>
    <td colSpan={2}>{lang[langSwitch].breakfast /*Сніданок*/}</td>
    <td colSpan={2}>{lang[langSwitch].lunch /*Обід*/}</td>
    <td colSpan={2}>{lang[langSwitch].dinner /*Вечеря*/}</td>
    <td rowSpan={2}>
       {lang[langSwitch].costDailyOrder /*Вартість добового замовлення*/} 
        </td>
</tr>
<tr>
    <td>{lang[langSwitch].portions /*Порції*/}</td>
    <td>{lang[langSwitch].cost /*Вартість*/}</td>
    <td>{lang[langSwitch].portions /*Порції*/}</td>
    <td>{lang[langSwitch].cost /*Вартість*/}</td>
    <td>{lang[langSwitch].portions /*Порції*/}</td>
    <td>{lang[langSwitch].cost /*Вартість*/}</td>
</tr>
</thead>
{foodOptions.times.map((item,index)=>{
  let b=0;let d=0;let s=0; let count2=0;
  data.map((item2)=>{
    if(item.date===item2.date){
        if (item2.breakfast) { 
            b += parseInt(item2.breakfast); 
            count +=(item2.breakfast*foodOptions.prise.breakfast)
             count2 +=(item2.breakfast*foodOptions.prise.breakfast)
        }
        if (item2.dinner) {
             d += parseInt(item2.dinner); 
             count +=(item2.dinner*foodOptions.prise.dinner)
               count2 +=(item2.dinner*foodOptions.prise.dinner)
            }
        if (item2.supper) { 
            s += parseInt(item2.supper); 
            count +=(item2.supper*foodOptions.prise.supper)
              count2 +=(item2.supper*foodOptions.prise.supper)
        }
    }
  })
    return(<>
    <tbody style={{ backgroundColor: index % 2 === 0 ? '#ebebeb' : null }}>
<tr>
<td>{item.date}</td>
<td>{item.breakfast?b+' шт.':null}</td>
<td>{b?b*foodOptions.prise.breakfast+' грн.':null} </td>
<td>{item.dinner?d+' шт.':null}</td>
<td>{d?d*foodOptions.prise.dinner+' грн.':null} </td>
<td>{item.supper?s+' шт.':null}</td>
<td>{s?s*foodOptions.prise.supper+' грн.':null} </td>
<td><b>{count2}</b> грн.</td>
</tr>
{foodOptions.times.length===index+1?(<>
<tr style={{ backgroundColor: '#ffffff'} }>
    <td colSpan={8}>
    {lang[langSwitch].totalCostOfOrder /*Загальна вартість замовлення*/}: 
    <b> {count} </b>
    {lang[langSwitch].UAH /* грн.*/}
         </td>
</tr>
</>)
:null}
</tbody>

</>)})}

</table>

</div>
    <FooterButtons 
        isOpen={isOpen} 
        isSmallScreen={isSmallScreen}
        langSwitch={langSwitch}/>
    </>)
}

export default OrderForProduction