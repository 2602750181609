import lang from '../../../../state/language'
import s from '../adminCompetition.module.css'






function EntyInputFoterData({
    isSmallScreen,
    FtuRegionLeaderHandle,
    OlfdLeaderHandle,
    StateSportDepartmentLeaderHandle,
    isFtuRegionLeader,
    isOlfdLeader,
    isStateSportDepartmentLeader,
    langSwitch
}){

return(<>
<label>
    <span  className={s.teamChangeMenuSpan}>
    {lang[langSwitch].positionOfHeadOfRegionalOffice /*Посада Голови обласного осередку (називний відмінок)*/}
      </span>
  </label><br/>
  <input
  style={{width:isSmallScreen?'300px':'400px'}} 
  type='text' 
  placeholder='Керівник ВП ГО «Федерація тхеквондо ВТФ України»'
  value={isFtuRegionLeader}
  onChange={FtuRegionLeaderHandle}
  /><br/><br/>


<label>
    <span  className={s.teamChangeMenuSpan}>
    {lang[langSwitch].positionOfChairmanOLFD /*Посада Голови ОЛФД (називний відмінок)*/}
      </span>
  </label><br/>
  <input
   style={{width:isSmallScreen?'300px':'400px'}} 
  type='text' 
  placeholder='Керівник ОЛФД'
  value={isOlfdLeader}
  onChange={OlfdLeaderHandle}
  /><br/><br/>
  
<label>
    <span  className={s.teamChangeMenuSpan}>
    {lang[langSwitch].positionHeadOfSportsDepartment /*Посада Голови управління спорту (називний відмінок)*/}
      </span>
  </label><br/>
  <input
   style={{width:isSmallScreen?'300px':'400px'}}  
  type='text' 
  placeholder='Начальник управління з питань фізичної культури і спорту'
  value={isStateSportDepartmentLeader}
  onChange={StateSportDepartmentLeaderHandle}
  /><br/><br/>
</>)}

export default EntyInputFoterData