import { useEffect, useState } from "react";
import state from "../../../state/state.js"
import s from '../mySportClubs/SportClubs.module.css'
import sAdmin from '../adminCompetition/adminCompetition.module.css'
import CommunitySportClubsItem from "./communitySportClubsItem";
import lang from "../../../state/language.js";




function CommunitySportClubs({isSmallScreen,langSwitch}) {

  const [data, setData] = useState('');
  const [visibleAthletes, setVisibleAthletes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const athletesPerPage = 24;
  const [fetching, setFetching] = useState(true);


  function scrollHandler(e) {
    const scrollHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );

    if (scrollHeight - (window.scrollY + window.innerHeight) < 100) {
      setFetching(true);
    }
  }

  useEffect(() => {
    if (fetching&&data.length == 0) {
      const endIndex = currentPage * athletesPerPage;
      const newVisibleAthletes = result.slice(0, endIndex);
      setVisibleAthletes((prevVisibleAthletes) => [ ...newVisibleAthletes]);
    
     
      setCurrentPage(currentPage + 1);
      setFetching(false);
    }
  }, [fetching]);

  useEffect(() => {
    if(data.length == 0){
    document.addEventListener('scroll', scrollHandler);
    document.addEventListener('touchmove', scrollHandler);
  }
    return function () {
      document.removeEventListener('scroll', scrollHandler);
      document.removeEventListener('touchmove', scrollHandler);
    }
  }, []);



  const handleTitleChange = (e) => {
    setData(e.target.value);
    // Якщо дані введені, викликаємо фільтрацію і вивід результатів
    if (e.target.value.trim() !== '') {
       const filteredResults = state.sportClubs.filter(club => {
        const fullName = `${club.name}`.toLowerCase();
        const searchData = data.toLowerCase();
        return fullName.includes(searchData);
      });
      setVisibleAthletes(filteredResults);
    } else {
      // Якщо дані не введені, використовуємо весь масив notCoahes
      const endIndex = currentPage * athletesPerPage;
      const newVisibleAthletes = state.sportClubs.slice(0, endIndex);
      setVisibleAthletes(newVisibleAthletes);
      setCurrentPage(currentPage + 1);
    }
  };

//   форма пошуку
let result
data.length>0?
   result = state.sportClubs.filter(club => {
    const fullName = `${club.name}`.toLowerCase();
    const searchData = data.toLowerCase();
    return fullName.includes(searchData);
  }):result=state.sportClubs


//////////////////////////////////////////////////////////////////////////////



    // Модальне вікно з переглядом спортсменів
const [athletesCellModalOpen, setAthletesCellModalOpen] = useState (false);
const openAthletesCellModal = () => { setAthletesCellModalOpen(true); }
    const closeAthletesCellModal = () => {setAthletesCellModalOpen(false);}
  return (<>


        <div className={sAdmin.communityFind}>
          <label htmlFor="title"><span><b>
          {lang[langSwitch].startTypingClubName /* Почніть вводити назву клубу*/}
            </b></span> </label><br /><br />
          <input onChange={handleTitleChange} type="text" id="title" name="title" />
         </div>






         <div className={!isSmallScreen?s.contaner4:null}>
    {visibleAthletes.map((club) => {console.log
const creator = state.athletes.filter(item=>item.id==club.creator)

const myTeamArray=state.athletes.filter( athlete => athlete.sportClub == club.name && athlete.status === 'Спортсмен')
const myCoachArray=state.athletes.filter(athlete => athlete.status === 'Тренер' && athlete.sportClub == club.name)
return(<>
  <div>
<CommunitySportClubsItem 
key={club.id} 
club={club} 
creator={creator} 
myTeamArray={myTeamArray} 
myCoachArray={myCoachArray}
isSmallScreen={isSmallScreen}
langSwitch={langSwitch}/>
{isSmallScreen?(<><br/></>):null}
</div>
    </>)})}
  </div>


  {/* модальне вікно з спортсменами для загального огляду */}
     

  </>)
}

export default CommunitySportClubs