import { useEffect, useState } from "react";
import state from "../../../state/state.js";
import s from './SportClubs.module.css'
import lang from "../../../state/language.js";



function CoachRequest ({myFullTeam, clubName, setProfileTest,langSwitch}){

  const [errorAthletesMain, setErrorAthletesMain] = useState (null);
  const [test, setTest] = useState (true);
  const [date, setDate] = useState (null);
  const [numberAthletes, setNumberAthletes] = useState (null);

    const  handleBirthdayChange = (e) => {setDate( e.target.value)  };
    useEffect(() => {
      if (test && date) {
        filterForAthletes2(myFullTeam, date);
        setTest(false);
      }
    }, [test, date, myFullTeam]);

    function filterForAthletes2(filter1, date) {
       
         const errorMessages = [];
        const filteredAthletes = filter1.filter((sportsman2) => {
          const athleteErrors = []; 
  
          if (sportsman2.activFtu!=1) { athleteErrors.push(lang[langSwitch].errorAthletes.activFtu); }
          if (sportsman2.activSite!=1) { athleteErrors.push(lang[langSwitch].errorAthletes.activSite);}
          if (sportsman2.fatherName === null || sportsman2.fatherName === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.fatherName); }
          if (sportsman2.birthday === null || sportsman2.birthday === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.birthday); }
          if (sportsman2.sex === null || sportsman2.sex === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.sex); }
          if (sportsman2.eMail === null || sportsman2.eMail === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.eMail);}
          if (sportsman2.photo === null || sportsman2.photo === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.photo); }
          if (sportsman2.weiht === null || sportsman2.weiht === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.weiht); }
          if (sportsman2.height === null || sportsman2.height === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.height); }
          if (sportsman2.coach === null || sportsman2.coach === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.coach); }
          if (sportsman2.sportClub === null || sportsman2.sportClub === undefined||sportsman2.sportClub===' ') { athleteErrors.push(lang[langSwitch].errorAthletes.sportClub); }
          if (sportsman2.region === null || sportsman2.region === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.region);}
          if (sportsman2.dan === null || sportsman2.dan === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.dan);}
          if (sportsman2.rank === null || sportsman2.rank === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.rank);}
          if (sportsman2.fst === null || sportsman2.fst === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.fst); }
          if (sportsman2.insuranceComp === null || sportsman2.insuranceComp === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.insuranceComp); }
          if (sportsman2.insuranceNum === null || sportsman2.insuranceNum === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.insuranceNum);}
          if (sportsman2.insuranceDate === null || sportsman2.insuranceDate === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.insuranceDate); }
          if (new Date(sportsman2.insuranceDate) < new Date(date)) { athleteErrors.push(lang[langSwitch].errorAthletes.insuranceActive);}
          if (sportsman2.medicalCertificateNum === null || sportsman2.medicalCertificateNum === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.medicalCertificateNum);}
          if (sportsman2.medicalCertificateDate === null || sportsman2.medicalCertificateDate === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.medicalCertificateDate);}
          if (new Date(sportsman2.medicalCertificateDate) < new Date(date)) { athleteErrors.push(lang[langSwitch].errorAthletes.medicalCertificateActive);}
          if (sportsman2.status === null || sportsman2.status === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.status);}
  
  
          if (athleteErrors.length > 0) {           
            errorMessages.push({
              name: `${sportsman2.secondName} ${sportsman2.personName}`,
              errors: athleteErrors            
            });  
          }
          return athleteErrors.length === 0;
        });
        if (errorMessages.length > 0) {
           const errorReports = errorMessages.map((athleteError) => {
            return `<div><br/>${lang[langSwitch].аthleteInCoachRequest /*Спортсмену*/} <u><b>${athleteError.name}</b></u>, 
            ${lang[langSwitch].refusedRegistration /*відмовлено в реєстрації на турнир по наступних причинах:*/}</div>${athleteError.errors.join('')}`;
          });           
          setErrorAthletesMain(errorReports);
                }
                setNumberAthletes(filteredAthletes.length)
        // return filteredAthletes;
      }

      const createMarkup = (html) => { return { __html: html }; };
    return(<>
    
   <div className={s.requestDate}>
          <label htmlFor="birthday"><i>
          {lang[langSwitch].dateForCheckProfileOfAthlete /*Введіть дату на яку ви плануєте реєструватись на турнир:*/}
            </i></label><br />
          <input onChange={handleBirthdayChange} type="date" id="birthday" name="birthday" />
    </div> 

    {!test && date?(<>
      
    <div className={s.requestTitle}>
    {lang[langSwitch].resultOfTeamVerification /*Результати перевірки команди*/}
      <b> {clubName}</b> </div>
    <div className={s.requestTitle2}> 
    {lang[langSwitch].byInCoachRequest /*до*/}
      <b> {state.formatDate(date)} 
       {langSwitch==='ukr'&&' p.'} 
        </b></div>
<div className={s.request}> 
    
 <div dangerouslySetInnerHTML={createMarkup(errorAthletesMain)}/><br/>

  {numberAthletes&&numberAthletes>0?(
  <div>
  {lang[langSwitch].remainingInCoachRequest /*Решта*/}
  <b>{numberAthletes} 
  {lang[langSwitch].sportsInCoachRequest /*спорт.*/}
    </b> 
    {lang[langSwitch].noCommentsInCoachRequest /*немає зауважень та допускається до реєстрацшї на змагання*/}
    </div>
  ):null}

  </div>  
</>):null}
  
  <div className={s.swichButtContaner}>

<div className={s.switchButL}>
<button  type='button' onClick={() => { window.print() }}>
{lang[langSwitch].print /*Друкувати*/}
</button>
</div>

<div className={s.switchButR}>
<button  type='button' onClick={() => {
  setDate(null);setTest(true);setErrorAthletesMain(null);setErrorAthletesMain(null);setProfileTest(false)
}}>
  {lang[langSwitch].close /*Закрити*/}
  </button>
</div>
</div>

    </>)
}

export default CoachRequest