import { NavLink } from 'react-router-dom'
import s from './documents.module.css'
import lang from '../../../state/language'



function Documents({langSwitch}) {



 return(<>

    <div className={s.mainPageTitle}>
    {lang[langSwitch]. documents /*Документи*/}
        </div>
    <ol className={s.mainList}>
        <NavLink to='/RulesKeirugue'>
        <li >
        Правила змагань в розділі Керугі з поясненнями (діючі з 14 червня 2024р.)
        </li>
        </NavLink>

<NavLink to='/RulesPoomsae'>
        <li >
        Правила змагань в розділі Пхумсе з поясненнями (діючі з 14 червня 2024р.)
        </li>
        </NavLink>
        
        <NavLink to='/Mouthguard'>
        <li >
       Вимоги щодо капи, тейпінгу, використання брекетів та пірсінгу до спортсменів WT під час змаганнь. 
        </li>
        </NavLink>
        
        <NavLink to='/programWT'>
        <li >Атестаційна програма Тхеквондо WT. 
            
        </li>
        </NavLink> 
         
         <NavLink to='/Poomse'>
        <li >Пхумсе.
        </li>
        </NavLink>    
    </ol>

    </>)
}

export default Documents