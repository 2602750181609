import lang from '../../../../state/language'
import s from '../adminCompetition.module.css'

function EntryClubAndDateHandle({clubData,handleTitleChange,titleOptions,dateSelect,langSwitch}){

    return(<>
    <label htmlFor="status"><span  className={s.teamChangeMenuSpan}>
    {lang[langSwitch].chooseSportsClub /* Оберіть спортклуб*/}:
        </span> </label><br />
           <select
           id="club" value={clubData} onChange={handleTitleChange}>
               <option value=""></option>
               {titleOptions.map((option, index) => (
                   <option key={index} value={option}>
                       {option}
                   </option>
               ))}
           </select>
           <br/><br/>
           <label htmlFor="status"><span  className={s.teamChangeMenuSpan}>
           {lang[langSwitch].setApplicationPrintingDate /*Встановіть дату друку заявки*/}:
            </span> </label><br />
           <input onChange={dateSelect} 
           type="date" id="birthday" name="birthday" /><br />
             <br/>
    </>)
}

export default EntryClubAndDateHandle