import { NavLink, useParams } from 'react-router-dom';
import s from './result.module.css'
import state from '../../../state/state.js';
import { useState } from 'react';
import ResultByAthlets from './resultByAthlets';
import ResultByTeams from './resultByTeams';
import ResultByCoaches from './resultByCoaches';
import ResultByClubs from './resultByClubs';
import ResultByRegions from './resultByRegions';
import ResultByRating from './resultByRating';
import GridsResult from './gridsResult.js';
import lang from '../../../state/language.js';
import ResultByAthletsPoomsae from './resultByAthletsPoomsae.js';
import ResultByCategoryesPoomsae from './resultByCategoryesPoomsae.js';
import ResultByTeamsPoomsae from './resultByTeamsPoomsae.js';
import ResultByCoachesPoomsae from './resultByCoachesPoomsae.js';
import ResultByClubsPoomsae from './resultByClubsPoomsae.js';
import ResultByRegionsPoomsae from './resultByRegionsPoomsae.js';
import ResultByAthletsCutOffSystem from './cutOffSystem/ResultByAthletsCutOffSystem.js';
import ResultByTeamsCutOffSystem from './cutOffSystem/ResultByTeamsCutOffSystem';
import ResultByCoachesCutOffSystem from './cutOffSystem/ResultByCoachesCutOffSystem';
import ResultByClubsCutOffSystem from './cutOffSystem/ResultByClubsCutOffSystem';
import ResultByRegionsCutOffSystem from './cutOffSystem/ResultByRegionsCutOffSystem';
import ResultByCategoryesCutOffSystem from './cutOffSystem/ResultByCategoryesCutOffSystem';
import CompetitionHeder from '../adminCompetition/competitionHeder.js';
import ShotingResultByAthlets from './resultByShooting/shotingResultByAthlets.js';
import ShootingResultByTeams from './resultByShooting/ShootingResultByTeams.js';
import ShootingResultByCoaches from './resultByShooting/shootingResultByCoaches.js';
import ShootingResultByClubs from './resultByShooting/shootingResultByClubs.js';
import ShootingResultByRegions from './resultByShooting/shootingResultByRegions.js';
import OverallResultByAthletes from './resultByShooting/overallResultByAthletes.js';
import OveralResultByTeams from './resultByShooting/overalResultByTeams.js';
import OveralResultByCoaches from './resultByShooting/OveralResultByCoaches.js';
import OveralResultByClubs from './resultByShooting/OveralResultByClubs.js';
import OveralResultByRegions from './resultByShooting/OveralResultByRegions.js';


function Results({ isSmallScreen, langSwitch }) {
  const { id } = useParams();//отримуємо id трурниру
  const competitionArray = state.competition.filter((tourn) => tourn.id == id); //турнир в массиві
  const competition = competitionArray[0]//отримуємо турнир з БД
  const poomse = competition.poomse == 1 ? true : false
  const [ComponentStart, setComponentStart] = useState(null);
  const cutOFFSystem = Array.isArray(JSON.parse(competition.results))
  return (<>
    <div>
      <CompetitionHeder
        logo={competition.logo}
        title={competition.title}
        notes={lang[langSwitch].competitionProtocols /* Протоколи турниру*/}
      />

{!ComponentStart&&(<>
      <div className={s.h4}>
       {lang[langSwitch].fightResultOfCompetition /* Результати змаганнь у програмі "двобої"*/}
    </div>
      <div className={s.containerResults}>

        <div className={s.itemResults1}>

          <button type='button' className={s.button} onClick={() => { setComponentStart(1) }}>
            {lang[langSwitch].individual /*Індивідуальні*/}
          </button>
          <button type='button' className={s.button} onClick={() => { setComponentStart(2) }}>
            {lang[langSwitch].byTeam /*Командні*/}
          </button>
        </div>
        <div className={s.itemResults2}>

          <button type='button' className={s.button} onClick={() => { setComponentStart(3) }}>
            {lang[langSwitch].byCoach /*По тренерах*/}
          </button>
          <button type='button' className={s.button} onClick={() => { setComponentStart(4) }}>
            {lang[langSwitch].byClubs /*По клубах*/}
          </button>
        </div>
        <div className={s.itemResults3}>

          <button type='button' className={s.button} onClick={() => { setComponentStart(5) }}>
            {lang[langSwitch].byRegions /*По осередках' */}
          </button>
          <button type='button' className={s.button} onClick={() => { setComponentStart(6) }}>
            {poomse ? lang[langSwitch].byCategories/* По категоріях*/ :
              lang[langSwitch].byRating /*По рейтингу */}
          </button>

        </div>

        <div className={s.itemResults4}>

          {!cutOFFSystem ? (<>
            <button type='button' className={s.button} onClick={() => { setComponentStart(7) }}>
              {lang[langSwitch].gridPrinting /*Друк сіток */}
            </button>
          </>) : null}

          {state.userProfile.id == competition.secretary ||
            state.userProfile.id == competition.mainJudge ||
            state.userProfile.id == competition.creator ?
            (<>
              <NavLink to={`/AdminCompetition/${competition.id}`}>
                <button className={s.exitButton} type='button' >
                  {lang[langSwitch].comeBack /*Повернутись*/}
                </button>
              </NavLink>
            </>) : (<>
              <NavLink to={`/competition/${competition.id}`}>
                <button className={s.exitButton} type='button' >
                  {lang[langSwitch].comeBack /*Повернутись*/}
                </button>
              </NavLink>
            </>)
          }


        </div>

      </div>

      <br/>
  <div className={s.h4}>
       {lang[langSwitch].shootingResultOfCompetition /* Результати змаганнь у програмі "Стрільба"*/}
    </div>


    <div className={s.containerResults}>
        
        <div className={s.itemResults1}>
        
            <button type='button' className={s.button} onClick={()=>{setComponentStart(8)}}>
            {lang[langSwitch].individual /*Індивідуальні*/} 
              </button>
            <button type='button' className={s.button} onClick={()=>{setComponentStart(9)}}> 
            {lang[langSwitch].byTeam /*Командні*/} 
              </button>
        {/* </div> */}
        {/* <div className={s.itemResults2}> */}
        
            <button type='button' className={s.button} onClick={()=>{setComponentStart(10)}}>
            {lang[langSwitch].byCoach /*По тренерах*/} 
               </button>
            <button type='button' className={s.button} onClick={()=>{setComponentStart(11)}}>
            {lang[langSwitch].byClubs /*По клубах*/}  
              </button>
     
        {/* </div> */}
        {/* <div className={s.itemResults3}> */}
        
            <button type='button' className={s.button} onClick={()=>{setComponentStart(12)}}> 
            {lang[langSwitch].byRegions /*По осередках' */}
              </button>
             
       
        </div>

       
      </div>











    <br/>
  <div className={s.h4}>
       {lang[langSwitch].overallCompetitionResults /* Загальні результати змаганнь*/}
    </div>
    <div className={s.containerResults}>
        
        <div className={s.itemResults1}>
        
            <button type='button' className={s.button} onClick={()=>{setComponentStart(13)}}>
            {lang[langSwitch].individual /*Індивідуальні*/} 
              </button>
            <button type='button' className={s.button} onClick={()=>{setComponentStart(14)}}> 
            {lang[langSwitch].byTeam /*Командні*/} 
              </button>
        {/* </div> */}
        {/* <div className={s.itemResults2}> */}
        
            <button type='button' className={s.button} onClick={()=>{setComponentStart(15)}}>
            {lang[langSwitch].byCoach /*По тренерах*/} 
               </button>
            <button type='button' className={s.button} onClick={()=>{setComponentStart(16)}}>
            {lang[langSwitch].byClubs /*По клубах*/}  
              </button>
     
        {/* </div> */}
        {/* <div className={s.itemResults3}> */}
        
            <button type='button' className={s.button} onClick={()=>{setComponentStart(17)}}> 
            {lang[langSwitch].byRegions /*По осередках' */}
              </button>
             
       
        </div>

       
      </div>

    </>)}

      {/* Зона друку */}

      {ComponentStart === 1 && (
        poomse ? (
          competition.results && !cutOFFSystem ? (
            <ResultByAthletsPoomsae competition={competition} langSwitch={langSwitch} />
          ) : (
            competition.results && cutOFFSystem ? (
              <ResultByAthletsCutOffSystem competition={competition} langSwitch={langSwitch} />
            ) : null
          )
        ) : (
          competition.results ? (
            <ResultByAthlets competition={competition} langSwitch={langSwitch} />
          ) : null
        )
      )}

      {ComponentStart === 2 && (
        poomse ? (
          competition.results && !cutOFFSystem ? (
            (<ResultByTeamsPoomsae competition={competition} langSwitch={langSwitch} />)
          ) : (
            competition.results && cutOFFSystem ? (
              <ResultByTeamsCutOffSystem competition={competition} langSwitch={langSwitch} />
            ) : null
          )
        ) : (
          competition.results ? (
            <ResultByTeams competition={competition} langSwitch={langSwitch} />
          ) : null
        )
      )}


      {ComponentStart === 3 && (
        poomse ? (
          competition.results && !cutOFFSystem ? (
            (<ResultByCoachesPoomsae competition={competition} langSwitch={langSwitch} />)
          ) : (
            competition.results && cutOFFSystem ? (
              <ResultByCoachesCutOffSystem competition={competition} langSwitch={langSwitch} />
            ) : null
          )
        ) : (
          competition.results ? (
            <ResultByCoaches competition={competition} langSwitch={langSwitch} />
          ) : null
        )
      )}

      {ComponentStart === 4 && (
        poomse ? (
          competition.results && !cutOFFSystem ? (
            (<ResultByClubsPoomsae competition={competition} langSwitch={langSwitch} />)
          ) : (
            competition.results && cutOFFSystem ? (
              <ResultByClubsCutOffSystem competition={competition} langSwitch={langSwitch} />
            ) : null
          )
        ) : (
          competition.results ? (
            <ResultByClubs competition={competition} langSwitch={langSwitch} />
          ) : null
        )
      )}

      {ComponentStart === 5 && (
        poomse ? (
          competition.results && !cutOFFSystem ? (
            (<ResultByRegionsPoomsae competition={competition} langSwitch={langSwitch} />)
          ) : (
            competition.results && cutOFFSystem ? (
              <ResultByRegionsCutOffSystem competition={competition} langSwitch={langSwitch} />
            ) : null
          )
        ) : (
          competition.results ? (
            <ResultByRegions competition={competition} langSwitch={langSwitch} />
          ) : null
        )
      )}


      {ComponentStart === 6 && (
        poomse ? (
          competition.results && !cutOFFSystem ? (
            (<ResultByCategoryesPoomsae competition={competition} langSwitch={langSwitch} />)
          ) : (
            competition.results && cutOFFSystem ? (
              <ResultByCategoryesCutOffSystem competition={competition} langSwitch={langSwitch} />
            ) : null
          )
        ) : (
          competition.results ? (
            <ResultByRating competition={competition} langSwitch={langSwitch} />
          ) : null
        )
      )}



      {ComponentStart === 7 && competition.results ? (<GridsResult competition={competition} langSwitch={langSwitch} />) : null}

      {ComponentStart === 8 && competition.results&&(
      <ShotingResultByAthlets competition={competition}                        
                          langSwitch={langSwitch} />
)}

 {ComponentStart === 9 && competition.results&&(
      <ShootingResultByTeams competition={competition}                        
                          langSwitch={langSwitch} />
)}

 {ComponentStart === 10 && competition.results&&(
      <ShootingResultByCoaches competition={competition}                        
                          langSwitch={langSwitch} />
)}

 {ComponentStart === 11 && competition.results&&(
      <ShootingResultByClubs competition={competition}                        
                          langSwitch={langSwitch} />
)}

 {ComponentStart === 12 && competition.results&&(
      <ShootingResultByRegions competition={competition}                        
                          langSwitch={langSwitch} />
)}

 {ComponentStart === 13 && competition.results&&(
      <OverallResultByAthletes competition={competition}                        
                          langSwitch={langSwitch} />
)}

 {ComponentStart === 14 && competition.results&&(
      <OveralResultByTeams competition={competition}                        
                          langSwitch={langSwitch} />
)}

{ComponentStart === 15 && competition.results&&(
      <OveralResultByCoaches competition={competition}                        
                          langSwitch={langSwitch} />
)}


{ComponentStart === 16 && competition.results&&(
      <OveralResultByClubs competition={competition}                        
                          langSwitch={langSwitch} />
)}


{ComponentStart === 17 && competition.results&&(
      <OveralResultByRegions competition={competition}                        
                          langSwitch={langSwitch} />
)}




      {ComponentStart && competition.results ? (
        <div className={s.containerResultsFooter}>
          <div className={s.itemResultsFooter1}>
            <button type='button' className={s.redButton1} onClick={() => { setComponentStart('') }}>
              {lang[langSwitch].close /*Закрити*/}
            </button>
          </div>

          <div className={s.itemResultsFooter2}>
            <button type='button' className={s.button} onClick={() => { window.print() }}>
              {lang[langSwitch].print /*Друкувати*/}
            </button>
          </div>
        </div>
      ) : null}


    </div>




  </>)
}

export default Results