import s from '../result.module.css'
import state from '../../../../state/state.js';


function ShootingResultByRegions({competition}){

const resultData=JSON.parse(competition.results).competitionResult

const groupedByRegion = resultData.reduce((acc, item) => {
    const coachId = item.region || ''; // Використовуємо порожній рядок, якщо coach не визначено

  
    // Перевіряємо, чи не порожні або не визначені поля coach та coach2
    if (coachId.trim() !== '') {
      // Перевіряємо, чи існує група для тренера, якщо ні, створюємо
      const coachGroup = acc.find(group => group.coach == coachId);
      if (!coachGroup) {
        acc.push({
          coach: coachId,
          athletes: [],
        });
      }      
      // Перевіряємо, чи атлета ще немає в групі для цього тренера
      const coachAthlete = acc.find(group => group.coach == coachId && group.athletes.some(athlete => athlete.gridId == item.gridId));
      if (!coachAthlete) {
        const groupIndex = acc.findIndex(group => group.coach == coachId);
        acc[groupIndex].athletes.push(item);
      }
    }      
    // Перевіряємо, чи не порожні або не визначені поля coach2
       
    return acc;
  }, []);

 
  const updatedGroupedByRegion = groupedByRegion.map((item) => ({
    ...item, // Копіюємо всі властивості з поточного об'єкта
    region:item.coach,
    gold: item.athletes.filter((a) => a.shootingPlace == 1).length, // Додаємо нову властивість
    silver:item.athletes.filter((a) => a.shootingPlace == 2).length,
    bronze:item.athletes.filter((a) => a.shootingPlace == 3).length,
    points:(item.athletes.filter((a) => a.shootingPlace == 1).length*12)+
    (item.athletes.filter((a) => a.shootingPlace == 2).length*5)+
    (item.athletes.filter((a) => a.shootingPlace == 3).length*2)
  }));
  
  const data = updatedGroupedByRegion.sort((a, b) => b.points - a.points);


let count=1;

    return(<>
 
<div className={s.mainDivForPrint}>
<table className={s.mailTableResultByAthlets}>{/* початок зовнішньої таблиці */}
 <thead>
 <tr>
  <td colSpan='8'>
    <div className={s.headerTitle}>
<div className={s.competitionTitle}>{competition.title}</div>
<div className={s.competitionLocation}>{competition.location}</div>
<div >
  {competition.dateStart&&competition.dateFinish?(<>
  <u><b>{state.formatDate(competition.dateStart).slice(0, 2)}</b>- 
        <b>{state.formatDate(competition.dateFinish)} р.</b></u>         
      </>):(<>
    <u><b>  {competition.dateOfCompetition}р.</b></u> 
      </>)}
        <br/><br/></div>
        <div className={s.textTitle}>Результати індивідуальних виступів спортсменів в заліку по областних осередках у програмі "Стрільба"</div>
        <br/>
    </div>
    </td>
</tr>
      <tr className={s.headerTable}>
        <td>№</td>
        <td>Обласний осередок</td>
        <td>Золото</td>
        <td>Срібло</td>
        <td>Бронза</td>
        <td>Бали</td>        
      </tr>
      </thead>
      <tbody>
   
  {data.map((item)=>(<>
   
      <tr key={item.id}>
        <td>{count}</td>
        <td>{item.region} </td>
        <td>{item.gold}</td>
        <td>{item.silver}</td>
        <td>{item.bronze}</td>
        <td>{item.points}</td>
       
      </tr>
     <div className={s.invisible}> {count++}</div>
 
  </>))}
  </tbody>
</table> {/* кінець зовнішньої таблиці */}
<br/><br/>


<table className={s.footerTable}> 
  <tbody><tr><td>
<div className={s.foterOfDocument}>
     Гол. суддя  &nbsp;&nbsp;<b> {state.myCoach(competition.mainJudge)} </b>
 &nbsp;&nbsp; <span className={s.infoDescription}>
   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
     (підпис)   
   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>  &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;

&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;м.п.
     </div>
</td>     
<td>
     <div className={s.foterOfDocument}>
     Гол. секретар &nbsp;&nbsp; <b> {state.myCoach(competition.secretary)}</b>  
 &nbsp;&nbsp; <span className={s.infoDescription}>
   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
     (підпис)   
   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>  &nbsp;&nbsp; 
 &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          
     </div>
</td></tr> </tbody></table>
</div>
    
    </>)
}

export default ShootingResultByRegions