import state from "../../../state/state.js";
import s from "./adminCompetition.module.css"


function ReportGrids(){
    const createMarkup = (html) => { return { __html: html }; };
    return(<>
     <div id='ReportGrids' className={s.divReport}>
        {state.gridsReport.map((report, index) => (
          <div key={index} dangerouslySetInnerHTML={createMarkup(report)} />
        ))
        }
      </div><br/>
    </>)
}

export default ReportGrids


//  className={state.gridsReport.length > 0 ? s.errorMessage1 : null}