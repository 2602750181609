
import s from '../results/result.module.css'
import state from '../../../state/state.js';
import { useState } from 'react';
import ResultByAthlets from './../results/resultByAthlets';
import ResultByCoaches from './../results/resultByCoaches';
import ResultByClubs from './../results/resultByClubs';
import ResultByRegions from './../results/resultByRegions';
import ResultByRating from './../results/resultByRating';
import ResultByTeams from './../results/resultByTeams';
import GridsResult from '../results/gridsResult.js';
import ConfirmButtons from './../adminCompetition/confirmButtons';
import axios from 'axios';
import lang from './../../../state/language';
import ResultByAthletsPoomsae from '../results/resultByAthletsPoomsae.js';
import ResultByTeamsPoomsae from '../results/resultByTeamsPoomsae.js';
import ResultByCoachesPoomsae from '../results/resultByCoachesPoomsae.js';
import ResultByClubsPoomsae from '../results/resultByClubsPoomsae.js';
import ResultByRegionsPoomsae from '../results/resultByRegionsPoomsae.js';
import ResultByCategoryesPoomsae from '../results/resultByCategoryesPoomsae.js';
import ResultByAthletsCutOffSystem from '../results/cutOffSystem/ResultByAthletsCutOffSystem.js';
import ResultByTeamsCutOffSystem from '../results/cutOffSystem/ResultByTeamsCutOffSystem.js';
import ResultByCoachesCutOffSystem from '../results/cutOffSystem/ResultByCoachesCutOffSystem.js';
import ResultByClubsCutOffSystem from '../results/cutOffSystem/ResultByClubsCutOffSystem.js';
import ResultByRegionsCutOffSystem from '../results/cutOffSystem/ResultByRegionsCutOffSystem.js';
import ResultByCategoryesCutOffSystem from '../results/cutOffSystem/ResultByCategoryesCutOffSystem.js';
import ShotingResultByAthlets from '../results/resultByShooting/shotingResultByAthlets.js';
import ShootingResultByTeams from '../results/resultByShooting/ShootingResultByTeams.js';
import ShootingResultByCoaches from '../results/resultByShooting/shootingResultByCoaches.js';
import ShootingResultByClubs from '../results/resultByShooting/shootingResultByClubs.js';
import ShootingResultByRegions from '../results/resultByShooting/shootingResultByRegions.js';
import OverallResultByAthletes from '../results/resultByShooting/overallResultByAthletes.js';
import OveralResultByTeams from '../results/resultByShooting/overalResultByTeams.js';
import OveralResultByCoaches from '../results/resultByShooting/OveralResultByCoaches.js';
import OveralResultByClubs from '../results/resultByShooting/OveralResultByClubs.js';
import OveralResultByRegions from '../results/resultByShooting/OveralResultByRegions.js';




function ArchiveResults({ 
          isSmallScreen,
          setWiew,
          setErrorMessage,
          competition,
          setSuccessMessage,
          langSwitch}){

         
  //  const competition= state.competition[1]
  //  const dateOfCompetition=`${state.formatDate(competition.dateStart).slice(0, 2)} - ${state.formatDate(competition.dateFinish)}`
const poomse=competition.poomse==1?true:false

const cutOFFSystem=Array.isArray(JSON.parse(competition.results))
  const [ComponentStart, setComponentStart] = useState(null);
  const [moderator,setModerator]=useState(false)
  const [dataForDB,setDataForDB]=useState(null)
if ( 
  moderator===false&& state.userProfile.id==1||
  moderator===false&&state.userProfile.id==competition.mainJudge||
  moderator===false&&state.userProfile.id==competition.secretary 
 ){
  setModerator(true)
}
////////////////////////////////ПРОГРАМНА ЧАСТИНА КНОПОК ПІДТВЕРДЖЕННЯ////////////////////////////////////////////////////////////
const [messageText, setMessageText] = useState('');
const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState(false);

 function editResultConfirm(text) {// ФУНКЦІЯ ЯКА ЗАПУСКАЄ ОПИТУВАННЯ
//    встановлений текст над кнопками є трігером, 
//якщо він повертається з компоненти - спрацьовує функція
     setMessageText(text)  
     openConfirmButtonsModal()
    
 }
 const confirmFunction = (messageText) => {
     if (messageText === 
      lang[langSwitch]. deleteResultsFromArchiveQuery /*Видалити результати турниру з архіву?*/
    ) {
      deleteTournResultsFromArchive() //ФУНКЦІЯ ЯКА ВИКОНУЄТЬСЯ В РАЗІ ПІДТВЕРДЖЕННЯ
     }
     if (messageText === 
      lang[langSwitch].saveChangesInArchive/*Зберегти в архів зміни внесені в протокол змаганнь?*/
    ) {
      updateArchive(competition) //ФУНКЦІЯ ЯКА ВИКОНУЄТЬСЯ В РАЗІ ПІДТВЕРДЖЕННЯ
     }
 }
 const openConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(true); }
 const closeConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(false); }
//////////////////////////////////////////////////////////////////////////////////
function deleteTournResultsFromArchive(){
 
  axios
  .delete(state.api.deleteArchiveData, {
      params: {
          id:competition.id,
          // Додано передачу імені файлу
      },
      headers: { 'Content-Type': 'application/json' },
  })
  .then((response) => {
  //  console.log(response)
  setSuccessMessage(
    lang[langSwitch].successfulDeletedFromArchive/*Результати Турниру успішно видалено з архіву.*/
  )
  state.archive=state.archive.filter(item=>item.id!=competition.id)
  setWiew(null)

  })
  .catch((error) => {
      setErrorMessage(
        lang[langSwitch].serverRequestError/*Помилка запиту до сервера:*/ + error);
  });
 
}


function updateArchive(){

axios 
.post(state.api.updateArchive, {
  
  title: competition.title,
  dateOfCompetition:competition.dateOfCompetition,
  results:JSON.stringify(dataForDB),
  location: competition.location,
  secretary:competition.secretary,
  mainJudge:competition.mainJudge,
  id: competition.id,
    headers: {'Content-Type': 'application/json'}
})
.then((response) => {
  console.log(response)
  setSuccessMessage(
    lang[langSwitch].resultsOfTournamentSaveInDB/*Результати Турниру занесено в Базу Даних. Ваш архів буде оновлено після повторної авторизації.*/
  );
  setWiew(null)
})
.catch((error) => {
   console.log(error);
  setErrorMessage( lang[langSwitch].serverRequestError/*Помилка запиту до сервера:*/ + error);
});
}
console.log('competition: ',competition)
    return(<>
 
   <div>
{!ComponentStart&&(<>

    <div className={s.resultTitle}>
      <br/>
        <div><h1 className={s.h1}> {competition.title} </h1></div>
         <div><h2 className={s.h2}>
         {lang[langSwitch].competitionProtocols /* Протоколи турниру*/}
          </h2></div>
    </div>

    <div className={s.h4}>
       {lang[langSwitch].fightResultOfCompetition /* Результати змаганнь у програмі "двобої"*/}
    </div>
    
      <div className={s.containerResults}>
        
        <div className={s.itemResults1}>
        
            <button type='button' className={s.button} onClick={()=>{setComponentStart(1)}}>
            {lang[langSwitch].individual /*Індивідуальні*/} 
              </button>
            <button type='button' className={s.button} onClick={()=>{setComponentStart(2)}}> 
            {lang[langSwitch].byTeam /*Командні*/} 
              </button>
        </div>
        <div className={s.itemResults2}>
        
            <button type='button' className={s.button} onClick={()=>{setComponentStart(3)}}>
            {lang[langSwitch].byCoach /*По тренерах*/} 
               </button>
            <button type='button' className={s.button} onClick={()=>{setComponentStart(4)}}>
            {lang[langSwitch].byClubs /*По клубах*/}  
              </button>
     
        </div>
        <div className={s.itemResults3}>
        
            <button type='button' className={s.button} onClick={()=>{setComponentStart(5)}}> 
            {lang[langSwitch].byRegions /*По осередках' */}
              </button>
              <button type='button' className={s.button} onClick={()=>{setComponentStart(6)}}>
               {poomse? lang[langSwitch].byCategories/* По категоріях*/:
             lang[langSwitch].byRating /*По рейтингу */}
                </button>
       
        </div>

        <div className={s.itemResults4}>
     {!cutOFFSystem?(<>     
      <button type='button' className={s.button} onClick={()=>{setComponentStart(7)}}>
      {lang[langSwitch].gridPrinting /*Друк сіток */} 
        </button>
      </>):null}
     
            <button 
            className={s.exitButton} 
            onClick={()=>{setWiew(null)}}
            type='button'>
              {lang[langSwitch].close /*Закрити*/} 
              </button>
       

        </div>

      </div>
      
      <br/>
  <div className={s.h4}>
       {lang[langSwitch].shootingResultOfCompetition /* Результати змаганнь у програмі "Стрільба"*/}
    </div>


    <div className={s.containerResults}>
        
        <div className={s.itemResults1}>
        
            <button type='button' className={s.button} onClick={()=>{setComponentStart(8)}}>
            {lang[langSwitch].individual /*Індивідуальні*/} 
              </button>
            <button type='button' className={s.button} onClick={()=>{setComponentStart(9)}}> 
            {lang[langSwitch].byTeam /*Командні*/} 
              </button>
        {/* </div> */}
        {/* <div className={s.itemResults2}> */}
        
            <button type='button' className={s.button} onClick={()=>{setComponentStart(10)}}>
            {lang[langSwitch].byCoach /*По тренерах*/} 
               </button>
            <button type='button' className={s.button} onClick={()=>{setComponentStart(11)}}>
            {lang[langSwitch].byClubs /*По клубах*/}  
              </button>
     
        {/* </div> */}
        {/* <div className={s.itemResults3}> */}
        
            <button type='button' className={s.button} onClick={()=>{setComponentStart(12)}}> 
            {lang[langSwitch].byRegions /*По осередках' */}
              </button>
             
       
        </div>

       
      </div>









      <br/>
  <div className={s.h4}>
       {lang[langSwitch].overallCompetitionResults /* Загальні результати змаганнь*/}
    </div>

    <div className={s.containerResults}>
        
        <div className={s.itemResults1}>
        
            <button type='button' className={s.button} onClick={()=>{setComponentStart(13)}}>
            {lang[langSwitch].individual /*Індивідуальні*/} 
              </button>
            <button type='button' className={s.button} onClick={()=>{setComponentStart(14)}}> 
            {lang[langSwitch].byTeam /*Командні*/} 
              </button>
        {/* </div> */}
        {/* <div className={s.itemResults2}> */}
        
            <button type='button' className={s.button} onClick={()=>{setComponentStart(15)}}>
            {lang[langSwitch].byCoach /*По тренерах*/} 
               </button>
            <button type='button' className={s.button} onClick={()=>{setComponentStart(16)}}>
            {lang[langSwitch].byClubs /*По клубах*/}  
              </button>
     
        {/* </div> */}
        {/* <div className={s.itemResults3}> */}
        
            <button type='button' className={s.button} onClick={()=>{setComponentStart(17)}}> 
            {lang[langSwitch].byRegions /*По осередках' */}
              </button>
             
              {moderator?(<><br/><br/><br/><br/><br/>
        
        <button 
        className={s.exitButton}
        onClick={()=>( 
         editResultConfirm(
           lang[langSwitch]. deleteResultsFromArchiveQuery /*Видалити результати турниру з архіву?*/
         ) 
        
         )} 
        type='button'>
         {lang[langSwitch]. deleteFromArchive /*Видалити з Архіву*/}
         </button>
      </>):null}
        </div>

       
      </div>

    </>)}

      {/* Зона друку */}

      {ComponentStart === 1 && (
  poomse ? (
    competition.results && !cutOFFSystem ? (
      <ResultByAthletsPoomsae competition={competition} />
    ) : (
      competition.results && cutOFFSystem ? (
        <ResultByAthletsCutOffSystem competition={competition} />
      ) : null
    )
  ) : (
    competition.results ? (
      <ResultByAthlets competition={competition}
                          updateArchive={editResultConfirm}
                          setDataForDB={setDataForDB}
                          langSwitch={langSwitch} />
    ) : null
  )
)}



{ComponentStart === 2 && (
  poomse ? (
    competition.results && !cutOFFSystem ? (
      (<ResultByTeamsPoomsae competition={competition} /> )
    ) : (
      competition.results && cutOFFSystem ? (
        <ResultByTeamsCutOffSystem competition={competition} />
      ) : null
    )
  ) : (
    competition.results ? (
      <ResultByTeams competition={competition} />
    ) : null
  )
)}

{ComponentStart === 3 && (
  poomse ? (
    competition.results && !cutOFFSystem ? (
      (<ResultByCoachesPoomsae competition={competition} /> )
    ) : (
      competition.results && cutOFFSystem ? (
        <ResultByCoachesCutOffSystem competition={competition} />
      ) : null
    )
  ) : (
    competition.results ? (
      <ResultByCoaches competition={competition} />
    ) : null
  )
)}

{ComponentStart === 4 && (
  poomse ? (
    competition.results && !cutOFFSystem ? (
      (<ResultByClubsPoomsae competition={competition} /> )
    ) : (
      competition.results && cutOFFSystem ? (
        <ResultByClubsCutOffSystem competition={competition} />
      ) : null
    )
  ) : (
    competition.results ? (
      <ResultByClubs competition={competition} />
    ) : null
  )
)}

{ComponentStart === 5 && (
  poomse ? (
    competition.results && !cutOFFSystem ? (
      (<ResultByRegionsPoomsae competition={competition} /> )
    ) : (
      competition.results && cutOFFSystem ? (
        <ResultByRegionsCutOffSystem competition={competition} />
      ) : null
    )
  ) : (
    competition.results ? (
      <ResultByRegions competition={competition} />
    ) : null
  )
)}


{ComponentStart === 6 && (
  poomse ? (
    competition.results && !cutOFFSystem ? (
      (<ResultByCategoryesPoomsae competition={competition} /> )
    ) : (
      competition.results && cutOFFSystem ? (
        <ResultByCategoryesCutOffSystem competition={competition} />
      ) : null
    )
  ) : (
    competition.results ? (
      <ResultByRating competition={competition} />
    ) : null
  )
)}

{ComponentStart===7&&competition.results ?( <GridsResult competition={competition}  langSwitch={langSwitch}/> ):null }

 
{ComponentStart === 8 && competition.results&&(
      <ShotingResultByAthlets competition={competition}                        
                          langSwitch={langSwitch} />
)}

{ComponentStart === 9 && competition.results&&(
      <ShootingResultByTeams competition={competition}                        
                          langSwitch={langSwitch} />
)}

{ComponentStart === 10 && competition.results&&(
      <ShootingResultByCoaches competition={competition}                        
                          langSwitch={langSwitch} />
)}

{ComponentStart === 11 && competition.results&&(
      <ShootingResultByClubs competition={competition}                        
                          langSwitch={langSwitch} />
)}

{ComponentStart === 12 && competition.results&&(
      <ShootingResultByRegions competition={competition}                        
                          langSwitch={langSwitch} />
)}


{ComponentStart === 13 && competition.results&&(
      <OverallResultByAthletes competition={competition}                        
                          langSwitch={langSwitch} />
)}

{ComponentStart === 14 && competition.results&&(
      <OveralResultByTeams competition={competition}                        
                          langSwitch={langSwitch} />
)}

{ComponentStart === 15 && competition.results&&(
      <OveralResultByCoaches competition={competition}                        
                          langSwitch={langSwitch} />
)}

{ComponentStart === 16 && competition.results&&(
      <OveralResultByClubs competition={competition}                        
                          langSwitch={langSwitch} />
)}


{ComponentStart === 17 && competition.results&&(
      <OveralResultByRegions competition={competition}                        
                          langSwitch={langSwitch} />
)}




{ComponentStart?(
<div className={s.containerResultsFooter}>
<div className={s.itemResultsFooter1}>
<button 
  type='button' 
  className={s.redButton1} 
  onClick={()=>{setComponentStart(null)}}>
    {lang[langSwitch].close /*Закрити*/} 
    </button>
</div>

<div className={s.itemResultsFooter2}>
<button 
  type='button' 
  className={s.button} 
  onClick={()=>{window.print()}}>
    {lang[langSwitch].print /*Друкувати*/} 
    </button>
</div>
</div>
):null}
 {/* модальне вікно кнопок підтвердження */}
 <ConfirmButtons
            isOpen={isConfirmButtonsModalOpen}
            onClose={closeConfirmButtonsModal}
            confirmFunction={confirmFunction}
            messageText={messageText}
            langSwitch={langSwitch}
        />


    </div>


  
    </>)
}

export default ArchiveResults