import lang from '../../../../state/language'
import s from '../adminCompetition.module.css'

function FooterButtons({isOpen,isSmallScreen,langSwitch}){


    return(<>
      <div className={s.noPrint}>
    <div className={!isSmallScreen?s.swichButtContaner:s.td1}>
        <div className={s.switchButL}>
                
            <button 
                type="button" 
              
                onClick={()=>{window.print()}}>
                   {lang[langSwitch].print /*Друкувати*/}
                    </button> 
               
                    </div>
                  
                <div className={s.switchButR}>
                   
            <button 
                className={s.redButton2} 
                onClick={()=>{isOpen(null)}}>
                    {lang[langSwitch].close /*Закрити*/}
                    </button>
                 
                </div>
                <br/><br/><br/>
            </div>

            </div>
    </>)
}

export default FooterButtons