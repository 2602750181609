
import state from '../../../../state/state.js'
import s from './grids.module.css'
import GridFooter from './GridFooter.js';
import lang from '../../../../state/language.js';

function GridForAthlets33(props){
	const poomse=props.competition.poomse==1?true:false 
	
	const athleteList = props.grid.athleteList
	const {
		score1 = null, score2 = null, score3 = null, score4 = null, score5 = null,
		score6 = null, score7 = null, score8 = null, score9 = null, score10 = null,
		score11 = null, score12 = null, score13 = null, score14 = null, score15 = null,
		score16 = null, score17 = null, score18 = null,score19 = null,score20 = null,
		score21 = null,score22 = null,score23 = null,score24 = null,score25 = null,
		score26 = null,score27 = null,score28 = null,score29 = null,score30 = null,
		score31 = null,score32 = null
	} = props.grid.battleScores || {}
	const {
		winner = null, winner1 = null, winner2 = null, winner3 = null, winner4 = null,
		winner5 = null, winner6 = null, winner7 = null, winner8 = null, winner9 = null,
		winner10 = null, winner11 = null, winner12 = null, winner13 = null, winner14 = null,
		winner15 = null, winner16 = null, winner17 = null,winner18 = null,winner19 = null,
		winner20 = null,winner21 = null,winner22 = null,winner23 = null,winner24 = null,
		winner25 = null,winner26 = null,winner27 = null,winner28 = null,winner29 = null,
		winner30 = null,winner31 = null,
	} = props.grid.winners || {}
	const winnerAthletes = props.grid.winnerAthletes ? props.grid.winnerAthletes : null
 


    return(<>
    <div className={s.mainDiv}>
			<div className={s.title}>
				<h3>{props.competition.title}</h3>
				<h3 >
  {props.competition.dateStart&&props.competition.dateFinish?(<>
  <u><b>{state.formatDate(props.competition.dateStart).slice(0, 2)}</b>- 
        <b>{state.formatDate(props.competition.dateFinish)} р.</b></u>         
      </>):(<>
    <u><b>  {props.competition.dateOfCompetition}р.</b></u> 
      </>)}
        <br/></h3>
				<h4 id={props.id}>
				{poomse?lang.poomsaeCatUkr(props.langSwitch,props.grid.category):
	state.weightCatUkr (props.grid.category,props.langSwitch, true)}
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				Корт: {props.cort}</h4>
			</div>
{/* /////////////////////////////////////////////////////////////////////////////// */}


		
<table width="600" >
  <tbody>
	  
	  <tr>
		  <td className={s.singleLeftBlue} >   
        <div 	 className={s.divAthleteInGrid}>
				 					
				{ athleteList[32].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[32].teamPoomsae)}<i>{athleteList[32]['team']}</i></>) : (<>{state.athleteName(athleteList[32]['id'])}<br /><i>{athleteList[32]['team']}</i></>) }

				</div> 
		   </td>
	     <td className={s.blueLeft} >
            <div className={s.figthLeft} >
                {props.cort}.{props.battleNumbers[16]}
                </div>
                </td>
	     <td  >&nbsp;</td>
	     <td  >&nbsp;</td>
	     <td ></td>
	     <td ></td>
	     <td ></td>
	     <td ></td>
	     <td  >&nbsp;</td>
	     <td ></td>
		  <td ></td>
    </tr>
	   
	   <tr>
		   
	     <td className={s.blueLeft} ><table><tbody>
	       <tr>
	         <td>
                   
        <div 	 	className={s.divAthleteInGrid}>
				 						
				{ athleteList[31].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[31].teamPoomsae)}<i>{athleteList[31]['team']}</i></>) : (<>{state.athleteName(athleteList[31]['id'])}<br /><i>{athleteList[31]['team']}</i></>) }

				</div> 

             </td>
	         <td className={s.figthLeft} >{props.cort}.{props.battleNumbers[0]}</td>
            </tr>
			</tbody></table>
	     </td>
	     
		    <td className={s.redLeft} >{/* Бої , просування по сітці */}
		{winner31 !==null?(<>		
					<div className={s.divAthleteInGrid} >
					{winner31.teamPoomsae?state.poomsaeTeam(winner31.teamPoomsae):state.athleteNameShort(winner31.id)}
 </div>
					</>):
					(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
							<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
								{  score32 ? score32 : null  }
							</div>{/*КІНЕЦЬ рахунок з полем вводу*/}	
							
							 </td>
		   <td className={s.blueLeft} ><table><tbody>
	       <tr>
	         <td>
			 {winner15 !==null?(<>		
					<div className={s.divAthleteInGrid} >
					{winner15.teamPoomsae?state.poomsaeTeam(winner15.teamPoomsae):state.athleteNameShort(winner15.id)}
 </div>
					</>):
					(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
							<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
								{ score16 ? score16 : null  }
							</div>{/*КІНЕЦЬ рахунок з полем вводу*/}	
				
			 </td>
	         
            </tr>
			</tbody></table></td>
	     <td  >&nbsp;</td>
	     <td ></td>
	     <td ></td>
	     <td ></td>
	     <td ></td>
	     <td  >&nbsp;</td>
	     <td className={s.blueRight} ><table><tbody>
	       <tr>
	         <td className={s.figthRight} >{props.cort}.{props.battleNumbers[8]}</td>
	         <td className={s.sportRight} >
                   
        <div 	 className={s.divAthleteInGrid}>
				 					
				{ athleteList[15].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[15].teamPoomsae)}<i>{athleteList[15]['team']}</i></>) : (<>{state.athleteName(athleteList[15]['id'])}<br /><i>{athleteList[15]['team']}</i></>) }

				</div> 
             </td>
            </tr>
			</tbody> </table>
	     </td>
		   <td ></td>
    </tr>

	  
	  
	  
	  
	  
	  
	   <tr>		   
	     <td className={s.redLeft} >   
        <div 	 className={s.divAthleteInGrid}>
				 					
				{ athleteList[30].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[30].teamPoomsae)}<i>{athleteList[30]['team']}</i></>) : (<>{state.athleteName(athleteList[30]['id'])}<br /><i>{athleteList[30]['team']}</i></>) }

				</div> 
</td>
		   <td ></td>
	     <td className={s.rightBordBlue} >
		 <div className={s.figthLeft} >{props.cort}.{props.battleNumbers[21]}</div>
		 </td>
	     <td  >&nbsp;</td>
	     <td ></td>
	     <td ></td>
	     <td ></td>
	     <td ></td>
	     <td className={s.blueRight} ><table><tbody>
	       <tr>
	         <td className={s.figthRight} >{props.cort}.{props.battleNumbers[17]}</td>
	         <td className={s.sportRight} >
				
			 {winner23 !==null?(<>		
					<div className={s.divAthleteInGrid}>
					{winner23.teamPoomsae?state.poomsaeTeam(winner23.teamPoomsae):state.athleteNameShort(winner23.id)}
 </div>
					</>):
					(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
							<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
								{ score24 ? score24 : null  }
							</div>{/*КІНЕЦЬ рахунок з полем вводу*/}	

			 </td>
            </tr>
			</tbody></table></td>
	     <td className={s.redRight} >
               
        <div 	 className={s.divAthleteInGrid}>
				 					
				{ athleteList[14].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[14].teamPoomsae)}<i>{athleteList[14]['team']}</i></>) : (<>{state.athleteName(athleteList[14]['id'])}<br /><i>{athleteList[14]['team']}</i></>) }

				</div> 

         </td>
		   <td ></td>
    </tr>
	  
	  
	  
	  
	  
	  <tr>
		  <td ></td>
	     <td  ></td>
	     <td className={s.rightBordBlue} ></td>
	     <td  ></td>
	     <td ></td>
	     <td ></td>
	     <td ></td>
	     <td ></td>
	     <td className={s.leftBordBlue} ></td>
	     <td  ></td>
		  <td ></td>
    </tr>
	  
	  
	  
	  
	  
	  
	   <tr>
		  
	     <td className={s.blueLeft} >
			 <table><tbody>
	       		<tr>
	         		<td>
                           
        <div 	 className={s.divAthleteInGrid}>
										
 				 { athleteList[29].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[29].teamPoomsae)}<i>{athleteList[29]['team']}</i></>) : (<>{state.athleteName(athleteList[29]['id'])}<br /><i>{athleteList[29]['team']}</i></>) }

				</div> 
                    </td>
	        		 <td className={s.figthLeft} >{props.cort}.{props.battleNumbers[1]}</td>
            	</tr>
				</tbody></table>
		  </td>
		   
	    <td></td>
	
		   <td className={s.redLeft} >
			{winner16 !==null?(<>		
					<div className={s.divAthleteInGrid} >
					{winner16.teamPoomsae?state.poomsaeTeam(winner16.teamPoomsae):state.athleteNameShort(winner16.id)}
 </div>
					</>):
					(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
							<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
								{ score17 ? score17 : null  }
							</div>{/*КІНЕЦЬ рахунок з полем вводу*/}	</td>
	     <td className={s.blueLeft} ><table><tbody>
	       <tr>
	         <td>
				
			 {winner7 !==null?(<>		
					<div className={s.divAthleteInGrid} >
					{winner7.teamPoomsae?state.poomsaeTeam(winner7.teamPoomsae):state.athleteNameShort(winner7.id)}
 </div>
					</>):
					(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
							<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
								{ score8 ? score8 : null  }
							</div>{/*КІНЕЦЬ рахунок з полем вводу*/}

			 </td>
	         
            </tr>
			</tbody></table></td>
	     <td ></td>
	     <td ></td>
	     <td ></td>
	     <td className={s.blueRight} ><table><tbody>
	       <tr>
	         
	         <td className={s.sportRight} >
				
			 {winner11 !==null?(<>		
					<div className={s.divAthleteInGrid} >
					{winner11.teamPoomsae?state.poomsaeTeam(winner11.teamPoomsae):state.athleteNameShort(winner11.id)}
 </div>
					</>):
					(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
							<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
								{ score12 ? score12 : null  }
							</div>{/*КІНЕЦЬ рахунок з полем вводу*/}

			 </td>
            </tr>
			</tbody> </table></td>
	     <td className={s.redRight} >
			
		 {winner24 !==null?(<>		
					<div className={s.divAthleteInGrid} >
					{winner24.teamPoomsae?state.poomsaeTeam(winner24.teamPoomsae):state.athleteNameShort(winner24.id)}
 </div>
					</>):
					(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
							<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
								{ score25 ? score25 : null  }
							</div>{/*КІНЕЦЬ рахунок з полем вводу*/}	

		 </td>
	     <td className={s.blueRight} ><table><tbody>
	       <tr>
	         <td className={s.figthRight} >{props.cort}.{props.battleNumbers[9]}</td>
	         <td className={s.sportRight} >
                   
        <div 	 className={s.divAthleteInGrid}>
				 					
				{ athleteList[13].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[13].teamPoomsae)}<i>{athleteList[13]['team']}</i></>) : (<>{state.athleteName(athleteList[13]['id'])}<br /><i>{athleteList[13]['team']}</i></>) }

				</div> 
             </td>
            </tr>
			</tbody> </table></td>
		   <td ></td>
    </tr>
	
	  
	  
	  
	  
	  
	  <tr>		 
	     <td className={s.redLeft} >
               
        <div 	 className={s.divAthleteInGrid}>
				 { athleteList[28].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[28].teamPoomsae)}<i>{athleteList[28]['team']}</i></>) : (<>{state.athleteName(athleteList[28]['id'])}<br /><i>{athleteList[28]['team']}</i></>) }

				</div> 
         </td>
		<td className={s.next} >&nbsp;</td>
	     <td  >&nbsp;</td>
	     <td className={s.rightBordBlue} >
		 <div className={s.figthLeft} >{props.cort}.{props.battleNumbers[27]}</div>
		 </td>
	     <td ></td>
	     <td ></td>
	     <td ></td>
	     <td className={s.leftBordBlue} >
		 <div className={s.figthRight} >{props.cort}.{props.battleNumbers[25]}</div>
		 </td>
	     <td  >&nbsp;</td>
	     <td className={s.redRight} >   
        <div  className={s.divAthleteInGrid}>
				 { athleteList[12].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[12].teamPoomsae)}<i>{athleteList[12]['team']}</i></>) : (<>{state.athleteName(athleteList[12]['id'])}<br /><i>{athleteList[12]['team']}</i></>) }

				</div> 
</td>
		  <td ></td>
    </tr>
	   
	   <tr>
		   <td ></td>
	     <td  ></td>
	     <td  ></td>
	     <td className={s.rightBordBlue} ></td>
	     <td ></td>
	     <td ></td>
	     <td ></td>
	     <td className={s.leftBordBlue} ></td>
	     <td  ></td>
	     <td ></td>
		   <td ></td>
    </tr>
	   
	   <tr>		  
        <td className={s.blueLeft} ><table><tbody>
          <tr>
            <td>
                   
        <div 	 className={s.divAthleteInGrid}>
				 	{ athleteList[27].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[27].teamPoomsae)}<i>{athleteList[27]['team']}</i></>) : (<>{state.athleteName(athleteList[27]['id'])}<br /><i>{athleteList[27]['team']}</i></>) }

				</div> 

            </td>
            <td className={s.figthLeft} >{props.cort}.{props.battleNumbers[2]}</td>
          </tr>
		  </tbody> </table></td>
        <td ></td>
		   <td  >&nbsp;</td>
        <td className={s.rightBordRed} >&nbsp;</td>
        <td className={s.blueLeft}  ><table><tbody>
          <tr>
            <td>
				
			{winner3 !==null?(<>		
					<div className={s.divAthleteInGrid} >
					{winner3.teamPoomsae?state.poomsaeTeam(winner3.teamPoomsae):state.athleteNameShort(winner3.id)}
 </div>
					</>):
					(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
							<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
								{ score4 ? score4 : null  }
							</div>{/*КІНЕЦЬ рахунок з полем вводу*/}

			</td>
            
          </tr>
		  </tbody> </table></td>
        <td ></td>
        <td className={s.blueRight} ><table><tbody>
          <tr>
            
            <td className={s.sportRight} >
				
			{winner5 !==null?(<>		
					<div className={s.divAthleteInGrid} >
					{winner5.teamPoomsae?state.poomsaeTeam(winner5.teamPoomsae):state.athleteNameShort(winner5.id)}
 </div>
					</>):
					(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
							<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
								{ score6 ? score6 : null  }
							</div>{/*КІНЕЦЬ рахунок з полем вводу*/}

			</td>
          </tr>
		  </tbody> </table></td>
        <td className={s.leftBordRed} >&nbsp;</td>
        <td  >&nbsp;</td>
        <td className={s.blueRight} ><table><tbody>
          <tr>
            <td className={s.figthRight} >{props.cort}.{props.battleNumbers[10]}</td>
            <td className={s.sportRight} >
                   
        <div 	 className={s.divAthleteInGrid}>
				 { athleteList[11].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[11].teamPoomsae)}<i>{athleteList[11]['team']}</i></>) : (<>{state.athleteName(athleteList[11]['id'])}<br /><i>{athleteList[11]['team']}</i></>) }

				</div> 
            </td>
          </tr>
		  </tbody></table></td>
		   <td ></td>
    </tr>
	 
	  <tr>		 
		  <td className={s.redLeft} >
               
        <div 	 className={s.divAthleteInGrid}>
				 { athleteList[26].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[26].teamPoomsae)}<i>{athleteList[26]['team']}</i></>) : (<>{state.athleteName(athleteList[26]['id'])}<br /><i>{athleteList[26]['team']}</i></>) }

				</div> 

          </td>
		  <td className={s.singleLeftBlue} >&nbsp;</td>
	    <td className={s.blueLeft} ><table><tbody>
		    <tr>
		      <td>
			  {winner17 !==null?(<>		
					<div className={s.divAthleteInGrid} >
					{winner17.teamPoomsae?state.poomsaeTeam(winner17.teamPoomsae):state.athleteNameShort(winner17.id)}
 </div>
					</>):
					(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
							<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
								{ score18 ? score18 : null  }
							</div>{/*КІНЕЦЬ рахунок з полем вводу*/}	
			  </td>
		      <td className={s.figthLeft} >{props.cort}.{props.battleNumbers[22]}</td>
	        </tr>
			</tbody></table></td>
	    <td className={s.redLeft} >
			
		{winner8 !==null?(<>		
					<div className={s.divAthleteInGrid} >
					{winner8.teamPoomsae?state.poomsaeTeam(winner8.teamPoomsae):state.athleteNameShort(winner8.id)}
 </div>
					</>):
					(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
							<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
								{ score9 ? score9 : null  }
							</div>{/*КІНЕЦЬ рахунок з полем вводу*/}

		</td>
		  <td className={s.rightBordBlue} >&nbsp;</td>
		  <td ></td> 
		 <td className={s.leftBordBlue} >&nbsp;</td><td className={s.redRight} >
			
		 {winner12 !==null?(<>		
					<div className={s.divAthleteInGrid} >
					{winner12.teamPoomsae?state.poomsaeTeam(winner12.teamPoomsae):state.athleteNameShort(winner12.id)}
 </div>
					</>):
					(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
							<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
								{ score13 ? score13 : null  }
							</div>{/*КІНЕЦЬ рахунок з полем вводу*/}
			
			</td><td className={s.blueRight} ><table><tbody>
		   <tr>
		     <td className={s.figthRight} >{props.cort}.{props.battleNumbers[18]}</td>
		     <td className={s.sportRight} >
				
			 {winner25 !==null?(<>		
					<div className={s.divAthleteInGrid} >
					{winner25.teamPoomsae?state.poomsaeTeam(winner25.teamPoomsae):state.athleteNameShort(winner25.id)}
 </div>
					</>):
					(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
							<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
								{ score26 ? score26 : null  }
							</div>{/*КІНЕЦЬ рахунок з полем вводу*/}	

			 </td>
	        </tr>
			</tbody> </table></td>
	    <td className={s.redRight} >   
        <div 	 className={s.divAthleteInGrid}>
				 { athleteList[10].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[10].teamPoomsae)}<i>{athleteList[10]['team']}</i></>) : (<>{state.athleteName(athleteList[10]['id'])}<br /><i>{athleteList[10]['team']}</i></>) }

				</div> 
</td>
		  <td ></td>
	  </tr>
	   
	  <tr>
		  <td ></td>
      <td  ></td>
      <td className={s.rightBordRed} ></td>
	    <td  ></td>
	    <td className={s.rightBordBlue} ></td>
	    <td ></td>
		<td className={s.leftBordBlue} ></td>
	    <td  ></td>
		  <td className={s.leftBordRed} ></td>
		<td  ></td>
		  <td ></td>
    </tr>
 	  
    <tr>
		
      <td className={s.blueLeft} ><table><tbody>
        <tr>
          <td>
               
        <div 	 className={s.divAthleteInGrid}>
				 { athleteList[25].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[25].teamPoomsae)}<i>{athleteList[25]['team']}</i></>) : (<>{state.athleteName(athleteList[25]['id'])}<br /><i>{athleteList[25]['team']}</i></>) }

				</div> 
          </td>
          <td className={s.figthLeft} >{props.cort}.{props.battleNumbers[3]}</td>
        </tr>
		</tbody></table></td>
      <td ></td>
		<td className={s.redLeft} >
			
		{winner18 !==null?(<>		
					<div className={s.divAthleteInGrid} >
					{winner18.teamPoomsae?state.poomsaeTeam(winner18.teamPoomsae):state.athleteNameShort(winner18.id)}
 </div>
					</>):
					(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
							<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
								{ score19 ? score19 : null  }
							</div>{/*КІНЕЦЬ рахунок з полем вводу*/}	

		</td>
		<td  >&nbsp;</td>
		<td className={s.rightBordBlue} >&nbsp;</td>
		<td >
            
			{/* Переможець  */}
{winner !== null ? (<>
<div className={s.sportCenter}><div className={s.divAthleteInGrid} >
    
	<div><img className={s.goldCup} alt='фото' src = '/img/main/goldCup1.png'/></div>
	{winner.teamPoomsae?state.poomsaeTeam(winner.teamPoomsae):state.athleteNameShort(winner.id)}
<br />
	<div className={s.battleScore}>{score1 ? score1 : null}</div>{/*рахунок поєдинка*/}
	</div> </div>
	</>) :
			<div className={s.figthCenter}><b>{props.cort}.{props.battleNumbers[31]} </b></div>
			}
								 	
            </td>
		 
		<td className={s.leftBordBlue} >&nbsp;</td>
		 <td  >&nbsp;</td>
	  <td className={s.redRight} >
		
	  {winner26 !==null?(<>		
					<div className={s.divAthleteInGrid} >
					{winner26.teamPoomsae?state.poomsaeTeam(winner26.teamPoomsae):state.athleteNameShort(winner26.id)}
 </div>
					</>):
					(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
							<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
								{ score27 ? score27 : null  }
							</div>{/*КІНЕЦЬ рахунок з полем вводу*/}	

	  </td>
		<td className={s.blueRight} ><table><tbody>
		  <tr>
		    <td className={s.figthRight} >{props.cort}.{props.battleNumbers[11]}</td>
		    <td className={s.sportRight} >
                   
        <div 	 className={s.divAthleteInGrid}>
										
 				 { athleteList[9].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[9].teamPoomsae)}<i>{athleteList[9]['team']}</i></>) : (<>{state.athleteName(athleteList[9]['id'])}<br /><i>{athleteList[9]['team']}</i></>) }

				</div> 
            </td>
		    </tr>
			</tbody> </table></td>
		
    </tr>
	 
    <tr>		
      <td className={s.redLeft} >
           
      <div 	 className={s.divAthleteInGrid}>
				 { athleteList[24].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[24].teamPoomsae)}<i>{athleteList[24]['team']}</i></>) : (<>{state.athleteName(athleteList[24]['id'])}<br /><i>{athleteList[24]['team']}</i></>) }

				</div> 
      </td>
		<td className={s.next} >&nbsp;</td>
      <td  >&nbsp;</td>
		<td  >&nbsp;</td>
		<td className={s.rightBordBlue} >
		<div className={s.figthLeft} >{props.cort}.{props.battleNumbers[30]}</div>
		</td>

		<td className={s.nextRed} ><div className={s.sportCenter} >
			
		{winner1 !==null?(<>		
					<div className={s.divAthleteInGrid} >
					{winner1.teamPoomsae?state.poomsaeTeam(winner1.teamPoomsae):state.athleteNameShort(winner1.id)}
 </div>
					</>):
					(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
							<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
								{ score2 ? score2 : null  }
							</div>{/*КІНЕЦЬ рахунок з полем вводу*/}
			
			</div></td>
		 
		 <td className={s.leftBordBlue} >
		 <div className={s.figthRight} >{props.cort}.{props.battleNumbers[29]}</div>
		 </td>
		<td  >&nbsp;</td>
	  <td  >&nbsp;</td>
		<td className={s.redRight} >
               
        <div 	 className={s.divAthleteInGrid}>
				 { athleteList[8].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[8].teamPoomsae)}<i>{athleteList[8]['team']}</i></>) : (<>{state.athleteName(athleteList[8]['id'])}<br /><i>{athleteList[8]['team']}</i></>) }

				</div> 
        </td>
		<td ></td>
    </tr>
	   
	  <tr>
		  <td ></td>
		  <td></td>
		  <td  ></td>
		  <td  ></td>
		  <td  ></td>
		  <td  ></td> 
		 <td  ></td>
		  <td ></td>
		  <td  ></td>
		  <td></td>
		  <td ></td>
	  </tr>
	   
	  <tr>
		  
      <td className={s.blueLeft} ><table><tbody>
        <tr>
          <td>
               
        <div 	 className={s.divAthleteInGrid}>
				 { athleteList[23].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[23].teamPoomsae)}<i>{athleteList[23]['team']}</i></>) : (<>{state.athleteName(athleteList[23]['id'])}<br /><i>{athleteList[23]['team']}</i></>) }

				</div>  

          </td>
          <td className={s.figthLeft} >{props.cort}.{props.battleNumbers[4]}</td>
        </tr>
		</tbody></table></td>
      <td ></td>
		  <td  >&nbsp;</td>
	    <td  >&nbsp;</td>
	    <td className={s.rightBordRed} >&nbsp;</td>
	    <td className={s.next} ><div className={s.sportCenter} >
			
		{winner2 !==null?(<>		
					<div className={s.divAthleteInGrid} >
					{winner2.teamPoomsae?state.poomsaeTeam(winner2.teamPoomsae):state.athleteNameShort(winner2.id)}
 </div>
					</>):
					(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
							<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
								{ score3 ? score3 : null  }
							</div>{/*КІНЕЦЬ рахунок з полем вводу*/}
			
			</div></td>
		   
		 <td className={s.leftBordRed} >&nbsp;</td>
	    <td  >&nbsp;</td>
		  <td  >&nbsp;</td>
		<td className={s.blueRight} ><table><tbody>
		  <tr>
		    <td className={s.figthRight} >{props.cort}.{props.battleNumbers[12]}</td>
		    <td className={s.sportRight} >
                   
        <div 	 className={s.divAthleteInGrid}>
				 { athleteList[7].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[7].teamPoomsae)}<i>{athleteList[7]['team']}</i></>) : (<>{state.athleteName(athleteList[7]['id'])}<br /><i>{athleteList[7]['team']}</i></>) }

				</div> 
            </td>
		    </tr>
			</tbody> </table></td>
		  <td ></td>
    </tr>
     
	  <tr>		
      <td className={s.redLeft} >
           
      <div 	 className={s.divAthleteInGrid}>
				 { athleteList[22].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[22].teamPoomsae)}<i>{athleteList[22]['team']}</i></>) : (<>{state.athleteName(athleteList[22]['id'])}<br /><i>{athleteList[22]['team']}</i></>) }

				</div> 
</td>  
		 <td className={s.singleLeftBlue} >&nbsp;</td>
      <td className={s.blueLeft} ><table><tbody>
        <tr>
          <td>
			
		  {winner19 !==null?(<>		
					<div className={s.divAthleteInGrid} >
					{winner19.teamPoomsae?state.poomsaeTeam(winner19.teamPoomsae):state.athleteNameShort(winner19.id)}
 </div>
					</>):
					(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
							<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
								{ score20 ? score20 : null  }
							</div>{/*КІНЕЦЬ рахунок з полем вводу*/}	

		  </td>
          <td className={s.figthLeft} >{props.cort}.{props.battleNumbers[23]}</td>
        </tr>
		</tbody> </table></td>
	  <td  >&nbsp;</td>
	  <td className={s.rightBordRed} >
	      <div className={s.colorLeft} >Син.</div>
        </td>
	  <td ></td>
		 
		 <td className={s.leftBordRed} >
		     <div className={s.colorRight} >Чер.</div>
	     </td>
	  <td  >&nbsp;</td>
	  <td className={s.blueRight} ><table><tbody>
	    <tr>
	      <td className={s.figthRight} >{props.cort}.{props.battleNumbers[19]}</td>
	      <td className={s.sportRight} >
			
		  {winner27 !==null?(<>		
					<div className={s.divAthleteInGrid} >
					{winner27.teamPoomsae?state.poomsaeTeam(winner27.teamPoomsae):state.athleteNameShort(winner27.id)}
 </div>
					</>):
					(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
							<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
								{ score28 ? score28 : null  }
							</div>{/*КІНЕЦЬ рахунок з полем вводу*/}	

		  </td>
	      </tr>
		  </tbody></table></td>
		<td className={s.redRight} >
               
        <div 	 className={s.divAthleteInGrid}>
				 { athleteList[6].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[6].teamPoomsae)}<i>{athleteList[6]['team']}</i></>) : (<>{state.athleteName(athleteList[6]['id'])}<br /><i>{athleteList[6]['team']}</i></>) }

				</div> 
        </td>
		  <td ></td>
    </tr>
	  
	    <tr>
			<td ></td>
		  <td></td>
			<td className={s.rightBordBlue} ></td>
			<td  ></td>
		  <td className={s.rightBordRed} ></td>
		  <td ></td> 
		 <td className={s.leftBordRed} ></td>
			<td  ></td>
			<td className={s.leftBordBlue} ></td>
			<td  ></td>
			<td ></td>
	  </tr>
	   
	  <tr>
		  
      <td className={s.blueLeft} ><table><tbody>
        <tr>
          <td>
               
        <div 	 className={s.divAthleteInGrid}>
				 { athleteList[21].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[21].teamPoomsae)}<i>{athleteList[21]['team']}</i></>) : (<>{state.athleteName(athleteList[21]['id'])}<br /><i>{athleteList[21]['team']}</i></>) }

				</div> 
          </td>
          <td className={s.figthLeft} >{props.cort}.{props.battleNumbers[5]}</td>
        </tr>
		</tbody> </table></td>
      <td ></td>
		  <td className={s.redLeft} > 

		   {winner20 !==null?(<>		
					<div className={s.divAthleteInGrid} >
					{winner20.teamPoomsae?state.poomsaeTeam(winner20.teamPoomsae):state.athleteNameShort(winner20.id)}
 </div>
					</>):
					(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
							<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
								{ score21 ? score21 : null  }
							</div>{/*КІНЕЦЬ рахунок з полем вводу*/}	
							
							</td>
		<td className={s.blueLeft} ><table><tbody>
		  <tr>
		    <td>
				
			{winner9 !==null?(<>		
					<div className={s.divAthleteInGrid} >
					{winner9.teamPoomsae?state.poomsaeTeam(winner9.teamPoomsae):state.athleteNameShort(winner9.id)}
 </div>
					</>):
					(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
							<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
								{ score10 ? score10 : null  }
							</div>{/*КІНЕЦЬ рахунок з полем вводу*/}

			</td>
		    
		    </tr>
			</tbody> </table></td>
		<td className={s.rightBordRed} >&nbsp;</td>
		<td ></td>
		   
		 <td className={s.leftBordRed} >&nbsp;</td>
	    <td className={s.blueRight} ><table><tbody>
	      <tr>
	       
	        <td className={s.sportRight} >
				
			{winner13 !==null?(<>		
					<div className={s.divAthleteInGrid} >
					{winner13.teamPoomsae?state.poomsaeTeam(winner13.teamPoomsae):state.athleteNameShort(winner13.id)}
 </div>
					</>):
					(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
							<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
								{ score14 ? score14 : null  }
							</div>{/*КІНЕЦЬ рахунок з полем вводу*/}

			</td>
	        </tr>
			</tbody> </table></td>
	  <td className={s.redRight} >
		
	  {winner28 !==null?(<>		
					<div className={s.divAthleteInGrid} >
					{winner28.teamPoomsae?state.poomsaeTeam(winner28.teamPoomsae):state.athleteNameShort(winner28.id)}
 </div>
					</>):
					(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
							<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
								{ score29 ? score29 : null  }
							</div>{/*КІНЕЦЬ рахунок з полем вводу*/}	

	  </td>
		<td className={s.blueRight} ><table><tbody>
		  <tr>
		    <td className={s.figthRight} >{props.cort}.{props.battleNumbers[13]}</td>
		    <td className={s.sportRight} >
                   
        <div 	 className={s.divAthleteInGrid}>
										
 				 { athleteList[5].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[5].teamPoomsae)}<i>{athleteList[5]['team']}</i></>) : (<>{state.athleteName(athleteList[5]['id'])}<br /><i>{athleteList[5]['team']}</i></>) }

				</div> 
            </td>
		    </tr>
			</tbody></table></td>
		  <td ></td>
    </tr>
   
	  <tr>		 
        <td className={s.redLeft} >
               
        <div 	 	className={s.divAthleteInGrid}>
				 { athleteList[20].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[20].teamPoomsae)}<i>{athleteList[20]['team']}</i></>) : (<>{state.athleteName(athleteList[20]['id'])}<br /><i>{athleteList[20]['team']}</i></>) }

				</div> 
        </td>
		 <td className={s.next} >&nbsp;</td>
        <td  >&nbsp;</td>
        <td className={s.rightBordBlue} >
		<div className={s.figthLeft} >{props.cort}.{props.battleNumbers[28]}</div>
		</td>
        <td className={s.redLeft} >
			
		{winner4 !==null?(<>		
					<div className={s.divAthleteInGrid} >
					{winner4.teamPoomsae?state.poomsaeTeam(winner4.teamPoomsae):state.athleteNameShort(winner4.id)}
 </div>
					</>):
					(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
							<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
								{ score5 ? score5 : null  }
							</div>{/*КІНЕЦЬ рахунок з полем вводу*/}

		</td>
        <td ></td>
        <td className={s.redRight} >
			
		{winner6 !==null?(<>		
					<div className={s.divAthleteInGrid} >
					{winner6.teamPoomsae?state.poomsaeTeam(winner6.teamPoomsae):state.athleteNameShort(winner6.id)}
 </div>
					</>):
					(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
							<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
								{ score7 ? score7 : null  }
							</div>{/*КІНЕЦЬ рахунок з полем вводу*/}

		</td>
        <td className={s.leftBordBlue} >
		<div className={s.figthRight} >{props.cort}.{props.battleNumbers[26]}</div>
		</td>
        <td  >&nbsp;</td>
        <td className={s.redRight} >

   
        <div 	 className={s.divAthleteInGrid}>
				 { athleteList[4].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[4].teamPoomsae)}<i>{athleteList[4]['team']}</i></>) : (<>{state.athleteName(athleteList[4]['id'])}<br /><i>{athleteList[4]['team']}</i></>) }

				</div> 
        </td>
		  <td ></td>
    </tr>
	   
	   <tr>
		   <td ></td>
      <td  ></td>
      <td  ></td>	
	  <td className={s.rightBordRed} ></td>
	  <td ></td>
	  <td ></td>
		   <td ></td>
	  <td className={s.leftBordRed} ></td>
	  <td ></td>
		<td  ></td>
		   <td ></td>
    </tr>
	   
    <tr>		
      <td className={s.blueLeft} ><table><tbody>
        <tr>
          <td>
               
        <div 	 className={s.divAthleteInGrid}>
				 { athleteList[19].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[19].teamPoomsae)}<i>{athleteList[19]['team']}</i></>) : (<>{state.athleteName(athleteList[19]['id'])}<br /><i>{athleteList[19]['team']}</i></>) }

				</div> 

          </td>
          <td className={s.figthLeft} >{props.cort}.{props.battleNumbers[6]}</td>
        </tr>
		</tbody></table></td>
      <td ></td>
		<td  >&nbsp;</td>	
	  <td className={s.rightBordRed} >&nbsp;</td>
		<td ></td>
		<td ></td>
	  <td ></td>
	  <td className={s.leftBordRed} >&nbsp;</td>
	  <td  >&nbsp;</td>
		<td className={s.blueRight} ><table><tbody>
		  <tr>
		    <td className={s.figthRight} >{props.cort}.{props.battleNumbers[14]}</td>
		    <td className={s.sportRight} >
                
   
            <div 	 className={s.divAthleteInGrid}>
				 { athleteList[3].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[3].teamPoomsae)}<i>{athleteList[3]['team']}</i></>) : (<>{state.athleteName(athleteList[3]['id'])}<br /><i>{athleteList[3]['team']}</i></>) }

				</div> 

            </td>
		    </tr>
			</tbody></table></td>
		<td ></td>
    </tr>
	   
	  <tr>		 
         <td className={s.redLeft} >
               
        <div 	 className={s.divAthleteInGrid}>
				 { athleteList[18].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[18].teamPoomsae)}<i>{athleteList[18]['team']}</i></>) : (<>{state.athleteName(athleteList[18]['id'])}<br /><i>{athleteList[18]['team']}</i></>) }

				</div> 
         </td>
		  <td className={s.singleLeftBlue} >&nbsp;</td>
        <td className={s.blueLeft} ><table><tbody>
           <tr>
             <td>
				
			 {winner21 !==null?(<>		
					<div className={s.divAthleteInGrid} >
					{winner21.teamPoomsae?state.poomsaeTeam(winner21.teamPoomsae):state.athleteNameShort(winner21.id)}
 </div>
					</>):
					(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
							<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
								{ score22 ? score22 : null  }
							</div>{/*КІНЕЦЬ рахунок з полем вводу*/}	

			 </td>
             <td className={s.figthLeft} >{props.cort}.{props.battleNumbers[24]}</td>
           </tr>
		   </tbody></table></td>
        <td className={s.redLeft} >
			
		{winner10 !==null?(<>		
					<div className={s.divAthleteInGrid} >
					{winner10.teamPoomsae?state.poomsaeTeam(winner10.teamPoomsae):state.athleteNameShort(winner10.id)}
 </div>
					</>):
					(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
							<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
								{ score11 ? score11 : null  }
							</div>{/*КІНЕЦЬ рахунок з полем вводу*/}

		</td>
         <td ></td>
         <td ></td>
         <td ></td>
         <td className={s.redRight} >
			
		 {winner14 !==null?(<>		
					<div className={s.divAthleteInGrid} >
					{winner14.teamPoomsae?state.poomsaeTeam(winner14.teamPoomsae):state.athleteNameShort(winner14.id)}
 </div>
					</>):
					(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
							<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
								{ score15 ? score15 : null  }
							</div>{/*КІНЕЦЬ рахунок з полем вводу*/}

		 </td>
         <td className={s.blueRight} ><table><tbody>
           <tr>
             <td className={s.figthRight} >{props.cort}.{props.battleNumbers[20]}</td>
             <td className={s.sportRight} >
				
			 {winner29 !==null?(<>		
					<div className={s.divAthleteInGrid} >
					{winner29.teamPoomsae?state.poomsaeTeam(winner29.teamPoomsae):state.athleteNameShort(winner29.id)}
 </div>
					</>):
					(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
							<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
								{ score30 ? score30 : null  }
							</div>{/*КІНЕЦЬ рахунок з полем вводу*/}	

			 </td>
           </tr>
		   </tbody> </table></td>
         <td className={s.redRight} >
            
   
         <div 	 className={s.divAthleteInGrid}>
				 { athleteList[2].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[2].teamPoomsae)}<i>{athleteList[2]['team']}</i></>) : (<>{state.athleteName(athleteList[2]['id'])}<br /><i>{athleteList[2]['team']}</i></>) }

				</div> 
         </td>
		  <td ></td>
    </tr>
	   
	  <tr>
		  <td ></td>
	    <td  ></td>
	    <td ></td>
	    <td className={s.leftBordRed} ></td>
	    <td ></td>
	    <td ></td>
	    <td ></td>
	    <td className={s.rightBordRed} ></td>
	    <td ></td>
	    <td  ></td>
		  <td ></td>
    </tr>
	   
	  <tr>		  
	    <td className={s.blueLeft} ><table><tbody>
	      <tr>
	        <td>
                   
        <div 	 className={s.divAthleteInGrid}>
				 { athleteList[17].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[17].teamPoomsae)}<i>{athleteList[17]['team']}</i></>) : (<>{state.athleteName(athleteList[17]['id'])}<br /><i>{athleteList[17]['team']}</i></>) }

				</div> 
            </td>
	        <td className={s.figthLeft} >{props.cort}.{props.battleNumbers[7]}</td>
	        </tr>
			</tbody></table></td>
	   <td ></td>
		  <td className={s.redLeft} >
			
		  {winner22 !==null?(<>		
					<div className={s.divAthleteInGrid} >
					{winner22.teamPoomsae?state.poomsaeTeam(winner22.teamPoomsae):state.athleteNameShort(winner22.id)}
 </div>
					</>):
					(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
							<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
								{ score23 ? score23 : null  }
							</div>{/*КІНЕЦЬ рахунок з полем вводу*/}	

		  </td>
	    <td ></td>
	    <td ></td>
	    <td ></td>
	    <td ></td>
	    <td ></td>
	    <td className={s.redRight} >
			
		{winner30 !==null?(<>		
					<div className={s.divAthleteInGrid} >
					{winner30.teamPoomsae?state.poomsaeTeam(winner30.teamPoomsae):state.athleteNameShort(winner30.id)}
 </div>
					</>):
					(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
							<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
								{ score31 ? score31 : null  }
							</div>{/*КІНЕЦЬ рахунок з полем вводу*/}	

		</td>
	    <td className={s.blueRight} ><table><tbody>
	      <tr>
	        <td className={s.figthRight} >{props.cort}.{props.battleNumbers[15]}</td>
	        <td className={s.sportRight} >
                
            <div 	 className={s.divAthleteInGrid}>
				 { athleteList[1].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[1].teamPoomsae)}<i>{athleteList[1]['team']}</i></>) : (<>{state.athleteName(athleteList[1]['id'])}<br /><i>{athleteList[1]['team']}</i></>) }

				</div> 
            </td>
	        </tr>
			</tbody> </table></td>
		  <td ></td>
    </tr>
	  
	  <tr>		  
	    <td className={s.redLeft} >
               
        <div 	 className={s.divAthleteInGrid}>
				 { athleteList[16].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[16].teamPoomsae)}<i>{athleteList[16]['team']}</i></>) : (<>{state.athleteName(athleteList[16]['id'])}<br /><i>{athleteList[16]['team']}</i></>) }

				</div> 
        </td>
		<td className={s.next} >&nbsp;</td>
	    <td  >&nbsp;</td>
	    <td ></td>
	    <td ></td>
	    <td ></td>
	    <td ></td>
	    <td ></td>
	    <td  >&nbsp;</td>
	    <td className={s.redRight} >
            
        <div 	 className={s.divAthleteInGrid}>
				 { athleteList[0].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[0].teamPoomsae)}<i>{athleteList[0]['team']}</i></>) : (<>{state.athleteName(athleteList[0]['id'])}<br /><i>{athleteList[0]['team']}</i></>) }

				</div> 
        </td>
		  <td ></td>
    </tr>
	   <tr>
		   <td ></td>
	    <td  ></td>
	    <td  >&nbsp;</td>
	    <td ></td>
	    <td ></td>
	    <td ></td>
	    <td ></td>
	    <td ></td>
	    <td  >&nbsp;</td>
	    <td  ></td>
		  <td ></td>
    </tr>
	
  </tbody>
</table>
	
	

{/* ////////////////////////////////////////////////////// */}
<GridFooter competition={props.competition} winnerAthletes={winnerAthletes} winner={winner}/>

			
		</div> 

    </>)
}

export default GridForAthlets33