import React, { useState } from 'react';
import s from '../menu/menu.module.css'
import sAdmin from '../../adminCompetition/adminCompetition.module.css'
import lang from '../../../../state/language';
import state from '../../../../state/state';
import ConfirmButtons from '../../adminCompetition/confirmButtons';
import axios from 'axios';
import compressImage from '../../photo/compressImage';
import indexedDBConnection from '../../../../state/indexedDBConnection';



function AddLeader({ isSmallScreen, langSwitch }) {

  const [compressedFileURL, setCompressedFileURL] = useState(null);
  const [compressedFile, setCompressedFile] = useState(null);
  const [name, setName] = useState(null);
  const [jobTitle, setJobTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [messageText, setMessageText] = useState('');
  const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const openConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(true); }
  const closeConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(false); }

  function saveLeaderConfirm() {
    if (compressedFile && name && jobTitle && description) {
      setMessageText(lang[langSwitch].addLeaderTitle + '?' /*Додати керівника*/)
      openConfirmButtonsModal()

    } else { setErrorMessage(lang[langSwitch].addLeaderWarning /*Заповніть усі поля та встановіть фото.*/) }
  }
  const confirmFunction = (messageText) => {
    if (messageText === lang[langSwitch].addLeaderTitle + '?' /*Додати керівника*/
    ) {
      //ВИКЛИК ФУНКЦІЇ СТВОРЕННЯ СТАТТІ

      uploadLeader()
    }

  }
  function handleNameChange(e) { setName(e.target.value) }

  function handleJobTitleChange(e) { setJobTitle(e.target.value) }

  function handleDescriptionChange(e) { setDescription(e.target.value) }

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];


    // Викликати функцію стиснення зображення та отримати стиснутий файл
    compressImage(selectedFile, {
      maxSizeMB: 0.3,
      maxWidth: 310,
      maxHeight: 394,
    })
      .then((compressedFile) => {

        setCompressedFile(compressedFile)
        // Створити URL для стиснутого файлу
        const compressedFileURL = URL.createObjectURL(compressedFile);

        // Встановити URL стиснутого файлу у стані компонента
        setCompressedFileURL(compressedFileURL);
      })
      .catch((error) => {
        console.error('Помилка стиснення файлу:', error);
      });
  };

  function uploadLeader() {

    const formData = new FormData();
    formData.append("description", description);
    formData.append("name", name);
    formData.append("jobTitle", jobTitle);
    formData.append("uploadDir", "../public/img/leaderShip/"); // Передаємо шлях для завантаження
    formData.append("targetDirectory", state.imgAdress.leaderShip);

    // Додаємо фото у FormData

    formData.append(`photos[]`, compressedFile);
    // Вивід FormData у консоль

    // console.log("FormData Entries:");
    // for (let [key, value] of formData.entries()) {
    //   console.log(key, value);
    // }

    axios.post(state.api.addLeader, formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
        timeout: 20000 // 20 секунд (повільний інтернет)
      }
    )
      .then((response) => {
        if (response.data.leaders) {

          state.leaders = response.data.leaders.map((item) => {
            return {
              ...item, photos: JSON.parse(item.photos)
            }
          })

          indexedDBConnection.saveData('leaders', state.leaders)
        }
        setTimeout(() => {
          window.location.reload();  //при перезавантаженні дані беруться з кешу  
        }, 1000)

      })
      .catch((error) => {
        setErrorMessage(lang[langSwitch].errorOccurredWhileSavingImage  /*Помилка під час збереження зображення*/)
        console.log('error: ', error)
      })

  };


  return (<div>
    <div className={s.h1}>
      {lang[langSwitch].addLeaderTitle /*Додати керівника */}
    </div>

    <div>
      <div className={s.newsDate}>
        {lang[langSwitch].surname   /*Призвище*/},
        {' ' + lang[langSwitch].name   /*Ім'я*/},
        {' ' + lang[langSwitch].patronymic   /*По-батькові*/}:
      </div>

      <input
        type='text'
        className={s.inputLeader}
        onChange={(e) => { handleNameChange(e) }}
      />
    </div>



    <div className={s.newsDate}>

      {lang[langSwitch].jobTitle   /*Посада*/}:
    </div>
    {/* {langSwitch==='ukr'?state.userProfile.rank:
                langSwitch==='eng'?lang.rankEng(state.userProfile.rank):null} */}

    <select onChange={handleJobTitleChange}  >
      <option value=""></option>
      {state.leadersJobTitle.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ))}
    </select>


    <div>
      <div className={s.newsDate}><br />
        {lang[langSwitch].description   /*Опис*/}:

      </div>

      <textarea
        type='text'
        className={s.leaderDescription}
        onChange={(e) => { handleDescriptionChange(e) }}
      />
    </div>

    {errorMessage ? (<><br /><br /><div className={sAdmin.errorMessage} onClick={() => { setErrorMessage('') }}>
      {errorMessage}</div><br /></>) : ''}

    <div>

      <div>
        <div className={s.newsDate}>
          {lang[langSwitch].photo   /*Фото*/}:

        </div>

        <div>
          {compressedFileURL && (
            <img src={compressedFileURL} alt="Стигнутий файл" />
          )}<br />
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
          /><br /><br />
        </div>
      </div>

    </div>
    <div>


      <button type='button'
        onClick={() => { saveLeaderConfirm() }}>
        {lang[langSwitch].saveInDB   /*Зберегти в БД*/}
      </button>
    </div>

    <ConfirmButtons
      isOpen={isConfirmButtonsModalOpen}
      onClose={closeConfirmButtonsModal}
      confirmFunction={confirmFunction}
      messageText={messageText}
      langSwitch={langSwitch}
    />

  </div>);
};

export default AddLeader;