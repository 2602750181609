import { NavLink, useParams } from 'react-router-dom'
import s from './adminCompetition.module.css'
import state from '../../../state/state.js';
import { useEffect, useState } from 'react';
import Calculator from './calculator';
import AthleteScaleByJudge from './athleteScaleByJudge.js';
import EntryByJudge from './entryByJudge.js';
import WithdrawByJudge from './withdrawByJudge.js';
import lang from '../../../state/language.js';
import EntryPoomsae from '../competition/entryPoomsae.js';
import WithdrawPoomsae from './../competition/withdrawPoomsae';
import JudgeChoice from './adminPoomsae/judgeChoice';
import CompetitionHeder from './competitionHeder.js'
import Grids from './grids.js';
import indexedDBConnection from '../../../state/indexedDBConnection.js';
import ScaleAthletesCell from './scaleAthletesCell';
import ScaleCategoriesCell from './ScaleCategoriesCell.js';
import Shooting from './Shooting.js';

function AdminCompetition({ isSmallScreen, langSwitch }) {
    const { id } = useParams();//отримуємо id
    const [entryByJudgeOpen, setEntryByJudgeModalOpen] = useState(false);
    const [EntryPoomsaeOpen, setEntryPoomsaeModalOpen] = useState(false);
    const [judgeChoiceWiew, setJudgeChoiceWiew] = useState(false);
    const [withdrawModalOpen, setWithdrawModalOpen] = useState(false);
    const [withdrawPoomsaeModalOpen, setWithdrawPoomsaeModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const [shootingOpen, setShootingOpen] = useState(false);
    const [gridsOpen, setGridsOpen] = useState(false);
    const [isOpenScaleAthletesCell, setIsOpenScaleAthletesCell] = useState(false);
    const [categoryDataScaleAthletesCell, setCategoryDataScaleAthletesCell] = useState({});
    const [transferAthleteData, setTransferAthleteData] = useState('');
    const [transferOpen, setTransferOpen] = useState(false);
    const [athletesForGrids, setAthletesForGrids] = useState('');

    const openEntryPoomsaeModal = () => { setEntryPoomsaeModalOpen(true); }
    const closeEntryPoomsaeModal = () => { setEntryPoomsaeModalOpen(false); }

    //заявка суддею спортсменів
    const openEntryByJudgeModal = () => { setEntryByJudgeModalOpen(true); }
    const closeEntryByJudgeModal = () => { setEntryByJudgeModalOpen(false); }

    const openWithdrawModal = () => { setWithdrawModalOpen(true); }
    const closeWithdrawModal = () => { setWithdrawModalOpen(false); }


    const openWithdrawPoomsaeModal = () => { setWithdrawPoomsaeModalOpen(true); }
    const closeWithdrawPoomsaeModal = () => { setWithdrawPoomsaeModalOpen(false); }

    const competitionArray = state.competition.filter((tourn) => +tourn.id === +id);
    const competition = competitionArray[0]
    const poomse = +competition.poomse === 1 ? true : false

    let athletes, poomsaeOptions, competitionFoodData, sortedCategories, sortedClasses, competitionShootingResult, competitionGrids
    useEffect(() => {
        indexedDBConnection.loadData('athletesForGrids').then((athletesForGridsData) => {
            if (athletesForGridsData.length > 0) {
                const data = athletesForGridsData.filter((item) => Number(item.id) === Number(id))
                setAthletesForGrids(data[0].sortedCategories)
            }
        })
    }, [transferOpen])


    if (poomse) {
        try { poomsaeOptions = JSON.parse(competition.poomsaeOptions) }
        catch { poomsaeOptions = competition.poomsaeOptions }
    }


    if (!poomse&&(competition.grids)) {
        try { competitionGrids = JSON.parse(competition.grids) }
        catch { competitionGrids = competition.grids }
    }

if(competition.shootingResult){
    try { competitionShootingResult = JSON.parse(competition.shootingResult) }
    catch (error) { competitionShootingResult = competition.shootingResult }
}

    try { athletes = JSON.parse(competition.athletes) }
    catch (error) { athletes = competition.athletes }

    try { competitionFoodData = JSON.parse(competition.foodData) }
    catch (error) { competitionFoodData = competition.foodData }

    if (athletesForGrids === '') {
        sortedClasses = state.tournConstructor.sortClasses(competition.athletes)
        sortedCategories = state.tournConstructor.sortedCategoryes(sortedClasses)
    } else {
        sortedCategories = athletesForGrids
    }
    const sortedCategoryesData = Object.entries(sortedCategories).map(([key, value]) => ({
        category: key,
        athletes: value
    }))
    const [scaleTeam, setScaleTeam] = useState(false);
    const [calcOpen, setCalcOpen] = useState(null)

    function scaleUpdatePage() {//оновлює сторінку зважування після успішного запиту в БД
        setTimeout(() => setScaleTeam(true), 500);

    }



















    return (<>
        <div >

            <CompetitionHeder
                logo={competition.logo}
                title={competition.title}

                notes={gridsOpen ?
                    lang[langSwitch].tournamentGrids/* Турнірні сітки*/
                    :
                    lang[langSwitch].AdminCompetitionTitle /*Адміністрування турниру*/}
            />

   
   {errorMessage ? (<div className={s.errorMessage} onClick={() => { setErrorMessage(null) }}
           style={{ cursor: 'pointer' }}>
           {errorMessage}
         </div>
         ) : null} 

            {gridsOpen ? (<>
                <Grids
                    isSmallScreen={isSmallScreen}
                    langSwitch={langSwitch}
                    id={id}
                    athletesForGrids={athletesForGrids}
                    setGridsOpen={setGridsOpen} />
            </>) : (<>
                {Number(state.userProfile.id) === Number(competition.secretary) || Number(state.userProfile.id) === Number(competition.mainJudge) ? (<>

                    <div className={!isSmallScreen ? s.containerAdmin : s.td1}>

                        <div className={s.itemAdmin1}>
                            {athletes && competition.athletes.length > 0 ?
                                poomse && poomsaeOptions.competitionSystem === 'Система виключення' ?
                                    (<>
                                        <NavLink to={`/PoomsaeCutOffSystemGrids/${id}`}>
                                            <button type='button' className={s.adminButton} >
                                                {lang[langSwitch].grids /*Сітки*/}
                                            </button>
                                        </NavLink>
                                    </>) :
                                    (<>
                                        <button type='button' className={s.adminButton} onClick={() => { setGridsOpen(true) }}>
                                            {lang[langSwitch].grids /*Сітки*/}
                                        </button>

                                    </>)

                                : (<>
                                    <button type='button' className={s.adminButton} >
                                        {lang[langSwitch].grids /*Сітки*/}
                                    </button>
                                </>)}


                            {poomse ? (<>
                                {/*Модальне вікно з заявкою спортсменів */}
                                <button
                                    className={s.adminButton}
                                    type='button'
                                    onClick={openEntryPoomsaeModal}>
                                    {lang[langSwitch].apply /*Подати заявку*/}
                                </button> {/* Кнопка у файлі */}
                                {/*Виклик Вікна */}
                                <EntryPoomsae tournId={id}
                                    isSmallScreen={isSmallScreen}
                                    isOpen={EntryPoomsaeOpen}
                                    onClose={closeEntryPoomsaeModal}
                                    langSwitch={langSwitch}
                                />  </>)
                                :
                                athletes && competition.athletes.length > 0 ? (<>
                                    {/*Модальне вікно з заявкою спортсменів */}
                                    <button
                                        className={s.adminButton}
                                        type='button'
                                        onClick={openEntryByJudgeModal}>
                                        {lang[langSwitch].apply /*Подати заявку*/}
                                    </button> {/* Кнопка у файлі */}
                                    {/*Виклик Вікна */}
                                    <EntryByJudge tournId={id}
                                        isSmallScreen={isSmallScreen}
                                        isOpen={entryByJudgeOpen}
                                        onClose={closeEntryByJudgeModal}
                                        langSwitch={langSwitch}
                                    />
                                </>) : (<>
                                    <button type='button' className={s.adminButton} >
                                        {lang[langSwitch].addAthlete /*Додати спортсмена*/}
                                    </button>
                                </>)
                            }


                            {poomse ? (<>

                                {athletes ? (<>
                                    <button
                                        className={s.adminButton}
                                        type='button'
                                        onClick={openWithdrawPoomsaeModal}>
                                        {lang[langSwitch].withdrawApplication /*Відкликати заявку*/}
                                    </button>  {/* Кнопка у файлі */}

                                    {/*Модальне вікно з відкликанням заявки спортсменів */}
                                    <WithdrawPoomsae tournId={id} isSmallScreen={isSmallScreen}
                                        isOpen={withdrawPoomsaeModalOpen}
                                        onClose={closeWithdrawPoomsaeModal}
                                        langSwitch={langSwitch}
                                    />
                                </>) : (<>
                                    <button type='button' className={s.adminButton} >
                                        {lang[langSwitch].withdrawAthlete /*Зняти спортсмена*/} </button>
                                </>)}
                            </>) : (<>

                                {athletes ? (<>
                                    <button
                                        className={s.adminButton}
                                        type='button'
                                        onClick={openWithdrawModal}>
                                        {lang[langSwitch].withdrawApplication /*Відкликати заявку*/}
                                    </button>  {/* Кнопка у файлі */}

                                    {/*Модальне вікно з відкликанням заявки спортсменів */}
                                    <WithdrawByJudge tournId={id} isSmallScreen={isSmallScreen}
                                        isOpen={withdrawModalOpen}
                                        onClose={closeWithdrawModal}
                                        langSwitch={langSwitch}
                                    />
                                </>) : (<>
                                    <button type='button' className={s.adminButton} >
                                        {lang[langSwitch].withdrawAthlete /*Зняти спортсмена*/} </button>
                                </>)}

                            </>)}


                        </div>

                        <div className={s.itemAdmin2}>
                            {poomse ? (<>
                                <NavLink to={`/RandomPoomsae/${id}`}>
                                    <button type='button' className={s.adminButton}>Random poomsae</button>
                                </NavLink>
                            </>) : (<>
                                {athletes ? (<>
                                    <NavLink to={`/randomWeigh/${id}`}>
                                        <button type='button' className={s.adminButton}>Random</button>
                                    </NavLink>
                                </>) : (<>
                                    <button type='button' className={s.adminButton}>Random</button>
                                </>)}
                            </>)}


                            {!poomse ?
                                athletes ? (<>
                                    <NavLink to={`/protocols/${id}`}>
                                        <button type='button' className={s.adminButton} >
                                            {lang[langSwitch].weighingProtocols /*Протоколи зважування*/}
                                        </button>
                                    </NavLink>
                                </>) : (<>
                                    <button type='button' className={s.adminButton} >
                                        {lang[langSwitch].weighingProtocols /*Протоколи зважування*/}
                                    </button>
                                </>)
                                :
                                poomsaeOptions.competitionSystem === 'Система виключення' && competition.poomsaeOptions ? (<>

                                    <button type='button' className={s.adminButton} on onClick={() => { setJudgeChoiceWiew(true) }}>
                                        {lang[langSwitch].appointJudge /*Призначити суддю*/}
                                    </button>
                                </>) : null
                            }

                            {athletes && !poomse ? (<>
                                <button type='button' className={s.adminButton}
                                    onClick={() => { setScaleTeam(true) }}>
                                    {lang[langSwitch].changeCategory /* Змінити категорію*/}
                                </button>
                            </>) : (null)}

                        </div>

                        <div className={s.itemAdmin3}>

                            <div>
                                <button type='button' className={s.adminButton} onClick={() => { setShootingOpen(true) }}>
                                    {lang[langSwitch].shooting /*Стрільба*/}
                                </button>
                            </div>

                            {competition.results ? (<>
                                <NavLink to={`/results/${id}`}>
                                    <button type='button' className={s.adminButton} >
                                        {lang[langSwitch].results /*Результати*/}
                                    </button>
                                </NavLink>
                            </>) : (<>
                                <button type='button' className={s.adminButton} >
                                    {lang[langSwitch].results /*Результати*/}
                                </button>
                            </>)}




                            {athletes ? (<>

                                <NavLink to={`/Badge/${id}`}>
                                    <button type='button' className={s.adminButton} >
                                        {lang[langSwitch].badges /*Бейджи*/}
                                    </button>
                                </NavLink>
                            </>) : (<>
                                <button type='button' className={s.adminButton} >
                                    {lang[langSwitch].badges /*Бейджи*/}
                                </button>
                            </>)}

                        </div>
                        <div className={s.itemAdmin4}>

                            {athletes ? (<>

                                <button type='button'
                                    className={s.adminButton}
                                    onClick={() => { setCalcOpen(1) }}>
                                    {lang[langSwitch].calculator /*Калькулятор*/}
                                </button>

                            </>) : (<>
                                <button type='button' className={s.adminButton} >
                                    {lang[langSwitch].calculator /*Калькулятор*/}
                                </button>
                            </>)}

                            <NavLink to={`/EslePrint/${id}`}>
                                <button type='button' className={s.adminButton} >
                                    {lang[langSwitch].various /*Різне*/}
                                </button>
                            </NavLink>


                            {Array.isArray(competitionFoodData) && competitionFoodData.length > 0 ? (<>

                                <NavLink to={`/FoodTable/${id}`}>
                                    <button type='button' className={s.adminButton} >
                                        {lang[langSwitch].food /*Харчування*/}
                                    </button>
                                </NavLink>
                            </>) : (<>
                                <button type='button' className={s.adminButton} >
                                    {lang[langSwitch].food /*Харчування*/}
                                </button>
                            </>)}

                        </div>


                    </div>
                </>) : (<>
                    {/* <NavLink to={`/PoomsaeCutOffSystemGrids/${id}`}> */}
                    <button type='button' className={s.adminButton} onClick={() => { setGridsOpen(true) }}>
                        {lang[langSwitch].grids /*Сітки*/}
                    </button>
                    {/* </NavLink> */}
                </>)}
            </>)}

        </div>

        {shootingOpen ? (
            competitionGrids && Array.isArray(competitionGrids) && competitionGrids.length>0?(<>
                    <Shooting 
                        setShootingOpen={setShootingOpen} 
                        competitionTitle={competition.title}
                        competitionAthletes={athletes} 
                        id={id}
                        langSwitch={langSwitch}
                        competitionShootingResult={competitionShootingResult}
                        gridsData={competitionGrids}
                        />
          </>):setErrorMessage(
            lang[langSwitch].youHaveNoSavedGrids /*У вас немає збережених сіток. Перейдіть у вкладку "Генерувати сітки"*/
          )
        ) : null}

        {judgeChoiceWiew ?
            <JudgeChoice
                setJudgeChoiceWiew={setJudgeChoiceWiew}
                competition={competition}
                isSmallScreen={isSmallScreen}
                langSwitch={langSwitch}
            />
            : null}

        {calcOpen == 1 ?
            <Calculator
                competition={competition}
                setCalcOpen={setCalcOpen}
                isSmallScreen={isSmallScreen}
                langSwitch={langSwitch}
                poomse={poomse}
            />
            : null}
        <br />
        {scaleTeam ? (<>{/*Зважування спортсменів з спортсклубу*/}
            <div className={s.modalWriper}>
                <div className={s.modal}>


                    <AthleteScaleByJudge
                        setScaleTeam={setScaleTeam}
                        isSmallScreen={isSmallScreen}
                        langSwitch={langSwitch}
                        sortedCategoryesData={sortedCategoryesData}
                        setCategoryDataScaleAthletesCell={setCategoryDataScaleAthletesCell}
                        setIsOpenScaleAthletesCell={setIsOpenScaleAthletesCell}
                        transferAthleteData={transferAthleteData}

                    />
                </div>
            </div>
        </>) : null}
        {isOpenScaleAthletesCell ? (<>
            <ScaleAthletesCell
                langSwitch={langSwitch}
                cat={categoryDataScaleAthletesCell}
                setIsOpenScaleAthletesCell={setIsOpenScaleAthletesCell}
                setTransferAthleteData={setTransferAthleteData}
                setTransferOpen={setTransferOpen}
                setScaleTeam={setScaleTeam}
            />
        </>) : null}

        {transferOpen ? (<>
            <div className={s.modalWriper}>
                <div className={s.modal}>
                    <div className={s.h1}> <b>
                        {lang[langSwitch].selectCategoryToTransferAthlete/*Оберіть категорії до якої ви хочете перенести спортсмена*/}
                    </b>
                    </div>
                    <ScaleCategoriesCell
                        langSwitch={langSwitch}
                        sortedCategories={sortedCategories}
                        categoriesData={sortedCategoryesData}
                        scale={true}
                        transferAthleteData={transferAthleteData}
                        setTransferAthleteData={setTransferAthleteData}
                        setTransferOpen={setTransferOpen}
                        id={id}
                    />

                    <div className={!isSmallScreen ? s.switchers : s.td1}>
                        <button
                            className={s.switch6L}
                            type='button'
                            onClick={() => { setTransferOpen(false); setTransferAthleteData('') }}>
                            {lang[langSwitch].close /*Закрити*/}
                        </button>
                    </div>
                </div>
            </div>

        </>) : null}
    </>)
}


export default AdminCompetition