import React, { Suspense, useEffect, useState } from 'react';
import { MailProvider } from './mod/content/mail/MailContext.js'
import Registration from './state/registration';
import LoginForm from './state/loginForm';
import { Route, Outlet, Routes, } from 'react-router-dom';
import s from './App.module.css'
import state from './state/state.js';
import axios from 'axios';
import indexedDBConnection from './state/indexedDBConnection.js';
import Header from './mod/header/header.js';
import NavBar from './mod/navBar/navBar.js';
import Content from './mod/content/content.js';
import Footer from './mod/footer/fooret.js';
import ContentWriper from './mod/content/forAllUsers/contentWriper.js';


function App() {

    const [isLoading, setIsLoading] = useState(true); // Додаємо стан для відстеження завантаження
    const [menu, setMenu] = useState(false)
    const isSmallScreen = window.innerWidth >= 20 && window.innerWidth <= 767 ? true : false;
    const [langSwitch, setLangSwitch] = useState(localStorage.getItem('langSwitch')?localStorage.getItem('langSwitch'):state.language)
    const [loginStart, setLoginStart] = useState(false)
    const [registrationStart, setRegistrationStart] = useState(false)
    const [globalId, setGlobalId] = useState(null)
   



    useEffect(() => {

        const fetchData = async () => {
            try {
                if (!state.globalId) {
                    const userProfileData = await indexedDBConnection.loadData('userProfile');
                    if (userProfileData && userProfileData.length > 0) {
                        const userProfile = userProfileData[0];
                        state.tempUserProfile = userProfile;
                        state.globalId = userProfile.id;
                        state.userProfile = userProfile;
                        state.stateUpdate();
                    } else {
                        console.warn('Не знайдено даних у IndexedDB для userProfile');
                    }
                }
        
                // Використовуємо Promise.allSettled для отримання результатів навіть при помилці
                const results = await Promise.allSettled([
                    indexedDBConnection.loadData('athletes'),
                    indexedDBConnection.loadData('sportClubs'),
                    indexedDBConnection.loadData('competition'),
                    indexedDBConnection.loadData('mail'),
                    indexedDBConnection.loadData('archive'),
                    indexedDBConnection.loadData('news'),
                    indexedDBConnection.loadData('leaders'),
                    indexedDBConnection.loadData('mediaContent'),
                ]);
        
                const [
                    athletesResult,
                    sportClubsResult,
                    competitionResult,
                    mailResult,
                    archiveResult,
                    newsResult,
                    leadersResult,
                    mediaContentResult,
                ] = results;
        
                if (athletesResult.status === 'fulfilled') {
                    state.athletes = athletesResult.value;
                }
                if (sportClubsResult.status === 'fulfilled') {
                    state.sportClubs = sportClubsResult.value;
                }
                if (competitionResult.status === 'fulfilled') {
                    state.competition = competitionResult.value;
                }
                if (mailResult.status === 'fulfilled') {
                    state.mail = mailResult.value;
                }
                if (archiveResult.status === 'fulfilled') {
                    state.archive = archiveResult.value;
                }
                if (newsResult.status === 'fulfilled') {
                    state.news = newsResult.value.reverse();
                }
                if (leadersResult.status === 'fulfilled' && state.leaders.length === 0) {
                    state.leadersJobTitle.forEach((item) => {
                        leadersResult.value.forEach((leader) => {
                            if (leader.jobTitle === item) {
                                state.leaders.push({
                                    ...leader,
                                    photos: JSON.parse(leader.photos),
                                });
                            }
                        });
                    });
                }
                if (mediaContentResult.status === 'fulfilled') {
                    state.mediaContent = mediaContentResult.value;
                }
        
                // Лог помилок
                results.forEach((result, index) => {
                    if (result.status === 'rejected') {
                        console.warn(`Помилка при завантаженні даних з IndexedDB для сховища ${index + 1}:`, result.reason);
                    }
                });
        
            } catch (error) {
                console.log('Непередбачувана помилка при завантаженні даних з IndexedDB:', error);
            } finally {
                setIsLoading(false);
            }
        };
        

        fetchData();
    }, []); // Залежності пусті, щоб викликатися лише при монтуванні компонента

    //зберігаємо результати поєдинків  у випадку невдачного запиту

    if (localStorage.getItem('reservResult') !== null) {

        const reservResult = JSON.parse(localStorage.getItem('reservResult'))

        // вносимо результати в профіль турниру
        axios
            .post(state.api.updateCompetitionResult, {
                results: reservResult.results,
                id: reservResult.competition.id,
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {

                ///////////////////////заносимо результати в архів///////////////////////////////////////
                const dateForArchive = `${state.formatDate(reservResult.competition.dateStart).slice(0, 2)} - ${state.formatDate(reservResult.competition.dateFinish)}`

                axios
                    .post(state.api.saveResultInArchive, {
                        title: reservResult.competition.title,
                        dateOfCompetition: dateForArchive,
                        results: reservResult.results,
                        location: reservResult.competition.location,
                        secretary: reservResult.competition.secretary,
                        mainJudge: reservResult.competition.mainJudge,
                        headers: { 'Content-Type': 'application/json' }
                    })
                    .then((response) => {
                        // console.log(response);
                    })
                    .catch((error) => {
                        //  console.log(error);
                    });
                //////////////////////////////////////////////////////////////////
                const index = state.competition.findIndex((item) => { return item.id == reservResult.competition.id })
                state.competition[index].results = JSON.stringify(reservResult.results);
                indexedDBConnection.saveData('competition', state.competition)
                //Заносимо результати в профіль спортсмена    
                axios.post(state.api.postCompetitionResultToAthletProfile, { updateData: reservResult.updateData })
                    .then(response => {
                        localStorage.removeItem('reservResult')
                    })
                    .catch(error => {
                        console.error('Помилка Axios-запиту, невдалось зберегти результати турнирув профілі атлетів:', error);
                    });
            })
            .catch((error) => { });
    }
    /////////////////////////////////////////////////////////////////////////////
//зберігаємо результати стрільби  у випадку невдачного запиту
if (localStorage.getItem('shootingReservResult') !== null){
    const dataForSave = JSON.parse(localStorage.getItem('shootingReservResult'))
    
    axios.post(state.api.saveShootingResult,
        {
            id: dataForSave.id,
            shootingResult: JSON.stringify(dataForSave.resultForSaving),
            grids:JSON.stringify(dataForSave.grids),
            headers: { 'Content-Type': 'application/json' }
        })
        .then((response) => {           
            const index = state.competition.findIndex((item) => +item.id == +dataForSave.id)
            state.competition[index].shootingResult = dataForSave.resultForSaving
             state.competition[index].grids=dataForSave.grids
            indexedDBConnection.saveData('competition', state.competition)
            localStorage.removeItem('shootingReservResult')
        })
        .catch((error) => {})
}

    state.messageCount()

    console.log('competition: ',state.competition)
   
    const DelayedContent = React.lazy(() => import('./mod/content/delay.js'));

    return (
        <>
            <MailProvider>
                <Routes>
                  
                    <Route
                        path="*"
                        element={
                            isLoading ? ( // Перевіряємо, чи завершилося завантаження даних
                                <>
                                    <div className={s.flag}>
                                        Зачекайте, дані завантажуються...<br /><br />
                                        <img alt='фото' src='/img/main/ukrainian-waving-flag-30.gif' /><br /><br />
                                        Please wait, data is loading...
                                    </div>
                                </>
                            ) : (
                                state.userProfile.id === null|| !state.userProfile.id? (
                                    <>
                                        <div className={s.container}>
                                            <div className={s.header}>
                                                <Header setMenu={setMenu}
                                                        menu={menu}
                                                        isSmallScreen={isSmallScreen}                                                      
                                                       />
                                            </div>
                                            <NavBar setLoginStart={setLoginStart} 
                                                    setRegistrationStart={setRegistrationStart}
                                                    setLangSwitch={setLangSwitch}
                                                    langSwitch={langSwitch}   /> 

                                            {loginStart && (
                                                <LoginForm setRegistrationStart={setRegistrationStart} 
                                                            setGlobalId={setGlobalId} 
                                                            setLoginStart={setLoginStart}
                                                            langSwitch={langSwitch}/>)}
                                            {registrationStart && (
                                                <Registration setRegistrationStart={setRegistrationStart} 
                                                              setLoginStart={setLoginStart}
                                                              setGlobalId={setGlobalId}
                                                              langSwitch={langSwitch}/>)}

                                      
                                        <div className={s.content}>
                                            {isSmallScreen?(<>
                                            <Content isSmallScreen={isSmallScreen} langSwitch={langSwitch}   />
                                            </>):
                                            (
                                            <ContentWriper isSmallScreen={isSmallScreen} langSwitch={langSwitch}/>
                                            )}
                                                
                                         </div>       
                                                <Footer isSmallScreen={isSmallScreen} langSwitch={langSwitch}   />
                                      
                                       </div>


                                        {/* <LoginForm /> */}
                                        <Outlet />
                                    </>
                                ) : (<>
                                    <Suspense fallback={
                                        <>
                                        <div className={s.flag}>
                                        Зачекайте, дані завантажуються...<br /><br />
                                        <img alt='фото' src='/img/main/ukrainian-waving-flag-30.gif' /><br /><br />
                                        Please wait, data is loading...
                                    </div>
                                        </>
                                    }>
                                        <DelayedContent setMenu={setMenu}
                                            menu={menu}
                                            isSmallScreen={isSmallScreen} 
                                            langSwitch={langSwitch}
                                            setLangSwitch={setLangSwitch} 
                                            setLoginStart={setLoginStart} 
                                            setRegistrationStart={setRegistrationStart}  />
                                    </Suspense>
                               </> )
                            )
                        }
                    />
                </Routes>
            </MailProvider>
        </>
    );

}

export default App;
