import { NavLink } from 'react-router-dom'
import s from './documents.module.css'
import lang from '../../../state/language'

function BackButton({langSwitch}){
    return(<>
    <div className={s.backButton}>
<NavLink to='/Documents'>
<button>
{lang[langSwitch]. comeBack /*Повернутись*/}
    </button>
</NavLink>
</div>
    </>)
}
export default BackButton