import { useState } from 'react'
import state from '../../state/state.js'
import s from './adminCompetition/adminCompetition.module.css'
import lang from '../../state/language.js'

function FrameOfGlory({isSmallScreen,langSwitch,}){

    let raitingOfAthlete
 

    return(<>
    <div style={{textAlign:'center',paddingTop:'20px'}}>
        <img style={!isSmallScreen?{width:'400px'}:{width:'350px'}}src='/img/main/Crown.png'/>
    </div>



    
 <div className={s.gridContainerForCategories}>
{state.bestAthletes.map((athlete) => {
  let imageError=false
  return(


              <>
             <div className={s.listForCoach}> {raitingOfAthlete=state.ratingQuery(athlete.id,0)}</div>
            
                <div className={s.cell}>
                  <div className={s.categoryName}>
                    <div>  {state.athleteName(athlete.id)} </div>
                    <div className={s.region}>
                      <u>
                      {langSwitch==='ukr'?athlete.region:
                   langSwitch==='eng'? lang.regionsEng(athlete.region):null
                    }
                        </u>
                        </div>
                    <div className={s.region}>
                    {lang[langSwitch].coachShort /*трен.*/}:  <b> {state.myCoach(athlete.coach)}</b>; &nbsp;
                  {lang[langSwitch].coachShort /*трен.*/}2:  <b> {state.myCoach(athlete.coach2)}</b>
                   </div>
                  </div>

                  <div className={s.categoryImg}>
                    <img 
                     src={athlete.photo&&!imageError?athlete.photo:state.noAvatarPhoto} 
                     alt={state.myCoach(athlete.id)} 
                     onError={()=>{imageError=true}} />
                    </div>

                    <div className={s.categoryData}>

                    <div>
                   {lang[langSwitch].qualificationShort /*кваліф.:*/}
                 <b>  {langSwitch==='ukr'?athlete.dan:
                   langSwitch==='eng'? lang.danEng(athlete.dan):null
                    } 
                    </b>  </div>
                  <div> 
                   {lang[langSwitch].rank /*розряд*/}:
                   <b>  {langSwitch==='ukr'?athlete.rank:
                   langSwitch==='eng'? lang.rankEng(athlete.rank):null
                    } 
                    </b> 
</div>
                  <div> 
                  {lang[langSwitch].height /*зріст*/}:  
                    <b> {athlete.height} </b>
                    {lang[langSwitch].cm /* см.*/} </div>
                  <div> 
                   {lang[langSwitch].weight /*вага*/}: 
                    <b> {athlete.weiht} </b>
                    {lang[langSwitch].kg /*кг. */} 
                    </div>
                       

                    <div>
                    {lang[langSwitch].sex /*стать */}: 
                    <b> {athlete.sex === 'male' ? 
                    lang[langSwitch].sexM /*Чоловік*/
                     : null}
                      {athlete.sex === 'female' ?
                       lang[langSwitch].sexF /*Жінка*/
                       : null}
                      {athlete.sex === null || athlete.sex === undefined ? 
                     lang[langSwitch].notSpecified /*Не вказано*/ 
                      : null}
                    </b>
                  </div>

                      <div>
                   {lang[langSwitch].fights /*Бої*/}: 
                    <b> {athlete.battles}</b> 
                    </div>

                  <div>
                  {lang[langSwitch].victory /*Перемоги*/}: 
                    <b> {athlete.victory}</b>
                    </div>

                    <div> 
                   {lang[langSwitch].rating /*рейтинг*/}: 
                    <b> {athlete.rating}
                      </b> {lang[langSwitch].points /*балів*/} </div>
                     </div>

                      <div className={s.categoryRating}>
                      
                      <div> <b>{raitingOfAthlete[0]} </b>
                      {lang[langSwitch]. placeInOverallRanking /*місце загальному  рейтингу*/}
                      </div>
                      <div>  <b>{raitingOfAthlete[2]} </b>                      
                      {lang[langSwitch]. placeInCategory /*місце в категорії*/}: 
                      </div>
                      <div>
                       <b> {state.weightCatUkr (raitingOfAthlete[1], langSwitch)}</b></div>

                      </div>

                  </div>

  
              
              </>)})}  </div> 

            
    </>)
}

export default FrameOfGlory