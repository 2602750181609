import React from 'react';
import s from '../result.module.css';
import lang from '../../../../state/language';

function TraditionalNotes({setSwitcher,langSwitch}) {

function tdItems(){
   return(<>
            <td><b>2.0</b></td>
            <td><b>1.9</b></td>
            <td><b>1.8</b></td>
            <td><b>1.7</b></td>
            <td><b>1.6</b></td>
            <td><b>1.5</b></td>
            <td><b>1.4</b></td>
            <td><b>1.3</b></td>
            <td><b>1.2</b></td>
            <td><b>1.1</b></td>
            <td><b>1.0</b></td>
            <td><b>0.9</b></td>
            <td><b>0.8</b></td>
            <td><b>0.7</b></td>
            <td><b>0.6</b></td>
            <td><b>0.5</b></td>
         </>)
}

   function table(){
      return(<> <table className={s.tradNotesTable}>
         <thead>
         <tr>
            <td colSpan='2' rowSpan='3'>
               <img className={s.judgesNotesLogo} src='/img/judgesNotes/logoForJudgesNotes.jpg' />
            </td>
           
         </tr>
         <tr>
         <td rowSpan='2' colSpan='12'>
               <div className={s.tradNotesTitle}>Суддівський нотатник</div>  
               <div className={s.tradNotesType}> Традиційне Пумсе</div>
            </td>
            <td colSpan='4' style={{height:'30px'}}><b>Корт №</b></td>
            <td >&nbsp;</td>
         </tr>
         <tr>  
            <td colSpan='4' style={{height:'30px'}}><b>Виступ №</b></td>
            <td>&nbsp;</td> 
         </tr>

         </thead>
   
        <tbody>
        <tr  className={s.bottomBorder}>
            <td ><b>Категорія оцінки</b></td>
            <td ><b>Підкатегорія елементів</b></td>
            <td colSpan='16'><b>Розподіл балів</b> </td>
            <td> <b>Оцінка</b></td> 
         </tr>

         <tr>
            <td rowSpan='4'><b>Точність виконання техніки (4.0 бала)</b></td>
            <td colSpan='17'><b>4.0 (Відрахувати -0.1; -0.3)</b> </td>
            <td rowSpan='4'>&nbsp;</td> 
         </tr>
         <tr>
            <td><b>Техніка базових елементів</b></td>
            <td colSpan='16' rowSpan='3'>&nbsp;</td>
            
         </tr>
         <tr>
            <td><b>Правильність виконання окремих рухів</b></td>       
           
         </tr>
         <tr className={s.bottomBorder}>
            <td><b>Збереження балансу</b></td>       
            
         </tr>

         <tr>
            <td rowSpan='4'><b>Видовищність та презентація (6.0 бала)</b></td>
            <td rowSpan='2'><b>Потужність та швидкість</b></td>
             <td colSpan='4'><b>Відмінно</b></td>
             <td colSpan='4'><b>Добре</b></td>
             <td colSpan='4'><b>Посередньо</b></td>
             <td colSpan='4'><b>Погано</b></td>
             <td rowSpan='4'>&nbsp;</td>
         </tr>
         <tr>
            { tdItems()}
         </tr>

         <tr>
             <td ><b>Поєднання ритму, швидкості та сили</b></td>
             { tdItems()}
         </tr>
         <tr className={s.bottomBorder}>
             <td ><b>Енергійність</b></td>
             { tdItems()}
         </tr>
         <tr >
         <td colSpan='18' className={s.bottomInstructions}><b>Загальна оцінка за виступ (10.0 балів)</b></td>
         <td>&nbsp;</td>
         </tr>
        </tbody>
   
   
   
   
         <tfoot>
            <tr>
               <td colSpan='10'><b>Суддя: ________________________________</b></td>
               <td colSpan='9'><b>Підпис ________________________________</b></td>
            </tr>
         </tfoot>
      </table></>)
   }

    return (<>
   <div className={s.resultTitle}>
    <div className={s.h3}>
    {lang[langSwitch].poomsaeTraditionalRefereeNote/*Суддівський нотатник для традиційного Пумсе*/} </div>
   </div>


 <div className={s.mainDivForPrint}>
  
 {table()}<br/><br/><br/>{table()}<br/><br/><br/>{table()}



   </div> 

        <div className={s.resultTitle}>
        <div className={s.containerBadgeTop}>
<div className={s.itemResultsFooter1}>
<button type='button'  onClick={()=>{window.print()}}>
{lang[langSwitch].print /*Друкувати*/}
   </button>
</div>

<div className={s.itemResultsFooter2}>

    <button  
    type='button' 
    onClick={()=>{setSwitcher(0)}}
    className={s.redButton}
    >
    {lang[langSwitch].close /*Закрити*/}
      </button>
        
</div></div>
</div>

        
   </> );
}

export default TraditionalNotes;
