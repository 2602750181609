import React from 'react';
import { NavLink, Route, Routes } from 'react-router-dom';
import NavBarForAllUsers from './navBarForAllUsers.js';
import NavBarForCoaches from './navBarForCoaches.js';
import state from '../../state/state.js';
import { useMailContext } from '../content/mail/MailContext.js'


function NavBar({ setMenu, 
                  langSwitch, 
                  setLoginStart, 
                  setRegistrationStart, 
                  setLangSwitch, 
                  }) {
  const isSmallScreen = window.innerWidth >= 20 && window.innerWidth <=  767 ? true : false;

  let lastActivityTime = new Date();//виходимо з профілю при відмсутності активності протягом 6 годин

  function checkUserActivity() {
    const currentTime = new Date();
    const timeDifference = currentTime - lastActivityTime;

    if (timeDifference > 30 * 60 * 1000 && state.userProfile.judge == 0) {
      state.exit()
    }
    lastActivityTime = currentTime;// Оновлення часу останньої активності при кожній перевірці
  }
  setInterval(checkUserActivity, 60 * 1000);//період таймера для перевірки активності кожні 6 мвилин

  document.body.addEventListener("mousemove", function () {//слухаємо рух миші
    lastActivityTime = new Date();// Оновлення часу останньої активності при кожному русі миші
  });
/////////////////////////////////////////////
  let isPageReloading = false;

  window.addEventListener('beforeunload', function (e) {
      // Позначаємо, що сторінка буде перезавантажена
      isPageReloading = true;
  });
  
  window.addEventListener('unload', function (e) {
      // Перевіряємо, чи сторінка перезавантажується перед викликом функції exit
      if (!isPageReloading && state.userProfile.judge == 0) { 
           state.exit(); // Викликаємо функцію виходу тільки якщо сторінка не перезавантажується 
      }
  });
  


  const { unreadMailCount } = useMailContext();

  //блокуємо редагування профілю спортсменів до вказаної при друку заявкі дати
  const currentDate = new Date(); // Поточна дата
  const blockProfileDate = new Date(state.userProfile.dateForBlockProfile);


  return (<>{/* Аватар профілю */}

     { !state.userProfile.id? (<>    {/* Форма реєстрації для незареєстрованих користувачів*/}
       <NavBarForAllUsers 
          setLoginStart={setLoginStart} 
          setRegistrationStart={setRegistrationStart}
          langSwitch={langSwitch}
          setLangSwitch={setLangSwitch}
          />

    </>) : (<>{/*для зареєстрованих користувачів */}
       <NavBarForCoaches
        setLangSwitch={setLangSwitch}  
        langSwitch={langSwitch}
        />
    </>)}
  </>);
};

export default NavBar;