import React, { useState } from 'react';
import s from "./forAllUsers.module.css"
import sMenu from '../controlPanel/menu/menu.module.css'
import lang from '../../../state/language';
import state from '../../../state/state';
import axios from 'axios';
import ConfirmButtons from '../adminCompetition/confirmButtons';
import indexedDBConnection from '../../../state/indexedDBConnection';

function NewsPageItem({
  isSmallScreen,
  langSwitch,
  news,
  index,
  moderator,
  setEditNews,
  setEditStart }) {

  const [messageText, setMessageText] = useState('');
  const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState(false);
  const [fullText, setFullText] = useState(false)
  const [galeryOpen, setGaleryOpen] = useState(false)
  const [ind, setInd] = useState(0)
  const [fade, setFade] = useState(true);


 const openConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(true); }
  const closeConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(false); }

  function deleteNewsConfirm() {  
      setMessageText(lang[langSwitch].deleteNews /*Видалити новину?*/)
      openConfirmButtonsModal()
     }

  const confirmFunction = (messageText) => {
    if (messageText === lang[langSwitch].deleteNews /*Видалити новину?*/
    ) {
      //ВИКЛИК ФУНКЦІЇ 
      deleteNews()
    }

  }




  const changeImage = (newIndex) => {
    setFade(false); // Починаємо зникнення

    setTimeout(() => {
      setFade(true); // Плавно показуємо нове зображення
      setInd(newIndex); // Міняємо зображення після завершення анімації

    }, 300);
  };
  let photos = []

  if (news.photos && news.photos !== null) {
    try { photos = JSON.parse(news.photos) }
    catch { photos = news.photos }
  }

  ////////////////////////////////////////////////////
  // обрізка тексту для перегляду та обробка тегів 
  const removeBackgroundStyles = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    // Видаляємо inline-стилі background
    tempDiv.querySelectorAll('*').forEach(el => {
      el.style.background = 'transparent'; // Очистити стиль
      el.removeAttribute('bgcolor'); // Видалити атрибут bgcolor
      el.style.removeProperty('background'); // Видалити background зі стилів
    });

    return tempDiv.innerHTML;
  };

  const createMarkup = (html) => {
    const cleanedHtml = removeBackgroundStyles(html); // Очищаємо HTML від фону
    return { __html: cleanedHtml };
  };

  const truncateHtml = (html, wordLimit = 50) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    let wordCount = 0;
    const processNode = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const words = node.textContent.trim().split(/\s+/);
        if (wordCount + words.length > wordLimit) {
          node.textContent = words.slice(0, wordLimit - wordCount).join(" ") + "...";
          wordCount = wordLimit;
        } else {
          wordCount += words.length;
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        node.childNodes.forEach(processNode);
      }

      // Якщо досягли ліміту, видаляємо зайві вузли
      if (wordCount >= wordLimit) {
        while (node.nextSibling) {
          node.parentNode.removeChild(node.nextSibling);
        }
      }
    };

    tempDiv.childNodes.forEach(processNode);
    return removeBackgroundStyles(tempDiv.innerHTML); // Очистити фон і для preview
  };

  // Отримати обрізаний текст із збереженими стилями та без фону
  const previewHtml = truncateHtml(news.text, 50);



  function deleteArticle() {
    axios.delete(state.api.deleteNewsArticle, {
      params: {
        id: news.id,
        // Додано передачу імені файлу
      },
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => {
        const updateNews = state.news.filter((m) => +m.id !== +news.id)
        state.news = [...updateNews]
        indexedDBConnection.saveData('news',  state.news)
                            .then((responseDB)=>{ window.location.reload(true); } )
                            .catch((error) => {console.log('error: ', error); });
      })
      .catch((error) => {
        console.log('Помилка запиту до сервера: ' + error);
      });

  }

  function deleteNews() {

    if (photos && Array.isArray(photos) && photos.length > 0) {
      const photoForDelete = photos.map((item) => {
        const parts = item.split('/');
        return parts[parts.length - 1]
      })


      //видалення старого фото
      axios.delete(state.api.deleteNewsImg, {
        data: {
          filesName: photoForDelete
        }
      })
        .then(response => { // Обробка успішного видалення  

          //все видалено,  фото для завантаження немає
          if (response.data.deletedFiles.length === photoForDelete.length) {
            deleteArticle()
          }


        })
        .catch(error => {// Обробка помилок
          console.error("Помилка завантаження файлів:", error);
          if (error.response) {
            console.error("Деталі помилки:", error.response.data);
          }
        });


    } else {
      deleteArticle()
    }



  }




  return (


    <div className={s.newsItemWriper} key={news.id + index}>
      <div className={s.newsItemInnerWriper}>
        <div className={s.newsItemBackGround}>
        <div className={s.newsTitle}>{news.title}</div>
        <div className={s.newsDate}>
          {state.formatDate(news.date) +
            lang[langSwitch].yearShort
          }
        </div>
        <div className={s.galeryBlock}>
          {(photos && photos.length > 0) && (<>

            {galeryOpen ?
              (<div className={s.galeryWriper}>
                <div className={s.galeryBox}>
                  <div className={s.galeryLeft}>
                    <span
                      className={s.galeryCursor}
                      onClick={() => { ind > 0 && changeImage(ind - 1) }}
                    >
                      {'<'}
                    </span>
                  </div>
                  <div className={s.galeryCenter}>
                    <img
                      key={photos[ind]} // Щоб React ререндерив з анімацією               
                      className={`${s.galery} ${!fade ? s.visible : s.hidden}`}
                      src={photos[ind]}
                    />
                  </div>

                  <div className={s.galeryRight}>
                    <span
                      className={s.galeryCursor}
                      onClick={() => { ind < photos.length - 1 && changeImage(ind + 1) }}
                    >
                      {'>'}
                    </span>
                  </div>

                </div>

                <div className={s.galeryClose}>
                  <span
                    className={s.galeryCursor}
                    onClick={() => { setGaleryOpen(false) }}
                  >{'x'}</span>
                </div>

              </div>)
              :
              photos.map((photo, index) => {
                return (
                  <img
                    key={index}
                    className={s.galeryPreview}
                    onClick={() => { setInd(index); setGaleryOpen(true) }}
                    src={photo} />
                )
              })

            }

          </>)}
        </div>
        {fullText ? (<>
          <div className={s.textSwitcher} onClick={() => { setFullText(!fullText) }}>
            {lang[langSwitch].collapse /*Collapse*/}...
          </div>
          <div dangerouslySetInnerHTML={createMarkup(news.text)} />
          <div className={s.textSwitcher} onClick={() => { setFullText(!fullText) }}>
            {lang[langSwitch].collapse /*Collapse*/}...
          </div>
        </>) : (<>
          <div dangerouslySetInnerHTML={{ __html: previewHtml }} />
          <div className={s.textSwitcher}
            onClick={() => { setFullText(!fullText) }}>
            {lang[langSwitch].expand /*Розгорнути*/}...
          </div>
        </>)}

        {moderator && (
          <div className={sMenu.editButtonsBox}>
            <div className={sMenu.editButtonItem}>
              <button
                type='button'
                onClick={() => {
                  setEditNews(news)
                  setEditStart(true)
                }}>
                {lang[langSwitch].edit /*Редагувати*/}
              </button>
            </div>
            <div className={sMenu.deleteButtonItem}>
              <button
                type='button'
                onClick={() => { deleteNewsConfirm() }}>
                {lang[langSwitch].deleteFromDB /*Видалити з БД*/}
              </button>
            </div>
          </div>
         
        )}


      </div> </div>
      <ConfirmButtons
      isOpen={isConfirmButtonsModalOpen}
      onClose={closeConfirmButtonsModal}
      confirmFunction={confirmFunction}
      messageText={messageText}
      langSwitch={langSwitch}
    />
    </div>

  );
};

export default NewsPageItem;