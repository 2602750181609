import React, {  useState } from 'react';
import s from '../menu/menu.module.css'
import sAdmin from '../../adminCompetition/adminCompetition.module.css'
import sApp from '../../../../App.module.css'
import lang from '../../../../state/language';

import state from '../../../../state/state';
import ConfirmButtons from '../../adminCompetition/confirmButtons';
import axios from 'axios';
import indexedDBConnection from '../../../../state/indexedDBConnection';



function EditVideoGalery({ langSwitch, isSmallScreen, editVideoGalery, setEditStart }) {



 const [videoTitle, setVideoTitle] = useState('');
  const [frameCode, setFrameCode] = useState('');
  const [newsTitle, setNewsTitle] = useState(editVideoGalery.title);
  const [type, setType] = useState(editVideoGalery.type);
  const [currentVideo, setCurrentVideo] = useState(()=>{
    if(editVideoGalery.links && editVideoGalery.links !== 'null' )  {
       let result = []
      try{
        result = JSON.parse(editVideoGalery.links) 
      }  catch{ 
        result = editVideoGalery.links
      }
        return result
    }else{ return [] }
 }
  
  );

  const [messageText, setMessageText] = useState('');
  const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [uploading, setUploading] = useState(false);


  const openConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(true); }
  const closeConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(false); }

  function saveGalleryConfirm() {  
      setMessageText(lang[langSwitch].saveChangesToGallery   /*Зберегти зміни в галереї?*/)
      openConfirmButtonsModal()
      }
  const confirmFunction = (messageText) => {
    if (messageText === lang[langSwitch].saveChangesToGallery   /*Зберегти зміни в галереї?*/
    ) {
      //ВИКЛИК ФУНКЦІЇ Оновлення СТАТТІ
      updateGallery()

    }
  }

  function handleNewsTitle(e) { setNewsTitle(e.target.value) }
  function handleGalleryTypeChange(e) { setType(e.target.value) }
  function handleVideoTitleChange(e) { setVideoTitle(e.target.value) }
  function handleFrameCodeChange(e) {
    const match = e.target.value.match(/src="([^"]+)"/);
    if (match) {
      setFrameCode(match[1]); // Беремо другий елемент масиву
    }
  }
  function addVideoToArray(){
    if(videoTitle&&frameCode){
      setCurrentVideo((prev)=>{
        return[...prev,{
          videoURL:frameCode,
          videoTitle:videoTitle
        }]
      })
      setVideoTitle('')
      setFrameCode('')
    }else{
      setErrorMessage(
        lang[langSwitch].addVideoToGalleryInstruction /*Щоб додати відео до галереї потрібно вказати його назву, та вставити посилання з YouTube (для цього на сайті YouTube під відео написніть "Поделитися"  / "Вставити" після чого скопіюйте  код та вставте у відповідне поле). */ 
    ) 
    }
  }

  function updateGallery(videoData) {
    setUploading(true)
    axios
      .post(state.api.editGallery, {
        title: newsTitle,
        type: 	type,
        links: videoData?JSON.stringify(videoData):JSON.stringify(currentVideo),
        id: editVideoGalery.id,
        headers: { 'Content-Type': 'application/json' }
      })
      .then((response) => {
   
        const index = state.mediaContent.findIndex((item) => +item.id === +editVideoGalery.id)
        state.mediaContent[index] = {   
          id: editVideoGalery.id,     
          title: newsTitle,
          type: 	type,
          links:  videoData?videoData:currentVideo,
          uploadDir:editVideoGalery.uploadDir,
          typeContent:editVideoGalery.typeContent,
          globalUrl:editVideoGalery.globalUrl
        }
        indexedDBConnection.saveData('mediaContent', [...state.mediaContent])

        setSuccessMessage(
          lang[langSwitch].successfullSavedInDB /*Внесені зміни успішно збережено в Базі Даних*/
        );

        window.location.href = '/ControlPanel'
      })
      .catch((error) => {
        setUploading(false)
        setErrorMessage(
          lang[langSwitch].serverRequestError/*Помилка запиту до сервера:*/);
        console.error("Помилка завантаження файлів:", error);
        if (error.response) {
          console.error("Деталі помилки:", error.response.data);
        }
      });


  };




  return (<div>

<div className={s.titleBox}>
    <div className={s.editButtonItem}>
      <div className={s.h3}>
        {lang[langSwitch].galeryTitle   /*Назва галереї*/}
      </div>   
     
      <input
        type='text'
        className={s.inputNews}
        value={newsTitle}
        onChange={(e) => { handleNewsTitle(e) }}
      />
    </div>
<div className={s.deleteButtonItem} 
      style={{textAlign:'right', paddingRight:'20px'}}>
<button     
    type='button'
    onClick={()=>{setEditStart(false)}}>
  {lang[langSwitch].close /*Закрити*/}
  </button>
  
</div>

</div>
<div>
      <div className={s.h3}>
        {lang[langSwitch].typeOfGallery   /*Тип галереї*/}
      </div>
      <select onChange={handleGalleryTypeChange}  >
      <option value={type}>{type}</option>
      {state.galleryType.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ))}
    </select>
       </div>
           <div className={s.videoWriper}>
             <div className={s.videoInnerWriper}>
    <div>
      <div className={s.h3}><br/>
        {lang[langSwitch].photos   /*Світлини*/}
      </div>
      <div className={s.typeOfPhotos}>
      {lang[langSwitch].currents /*Поточні*/}
        </div>   

        {currentVideo.map((file, index) => {

          return (

                <div className={s.videoPrewBox} key={file.videoURL + index}>
                    <div className={s.videoPrewPlayer}>
                    <img
                  className={s.deleteImg}
                  src={state.deleteImg}
                  onClick={
                    () => {
                      setCurrentVideo((prev) => {
                        const result = prev.filter((item) => item !== file)
                        return result
                      })
                    

                    }}
                />
                      {state.youtubePlayer(file.videoURL, isSmallScreen)}
                    </div>
                    <div className={s.videoPrewTitle}>
                      {file.videoTitle}
                    </div>
                  </div>

          )
        }
        )}
   
      <div className={s.typeOfPhotos}>
       {lang[langSwitch].new /*Нові*/}
        </div>   
      {successMessage&&(<>
        <br/><br/><div className={sAdmin.successMessage} onClick={()=>{setSuccessMessage('')}}>
                 {successMessage}
        </div><br/>
        </>) }
              {errorMessage ? (<><br /><br /><div className={sAdmin.errorMessage} onClick={() => { setErrorMessage('') }}>
              {errorMessage}</div><br /></>) : ''}
     

 <div className={s.newsDate}>
 { lang[langSwitch].videoTitle /*Назва відео*/ }
      </div>
      
      <input
        type='text'
        className={s.inputNews}
        value={videoTitle}
        onChange={(e) => { handleVideoTitleChange(e) }}
      />

 <div className={s.newsDate}>
 { lang[langSwitch].frameCode /*Код фрейму*/ }
      </div>    
<input
        type='text'
        className={s.inputNews}
      value={frameCode}
        onChange={(e) => { handleFrameCodeChange(e) }}
      />

      <button 
        type='button'
        onClick={()=>{addVideoToArray()}}>
 { lang[langSwitch].addVideo /*Додати відео*/ }
       </button>   <br/>&nbsp;
    </div> 
    </div>
  </div>



      <button 
        type='button'
        onClick={()=>{saveGalleryConfirm()}}>
          { lang[langSwitch].editGallery /*Редагувати галерею*/ }
        </button>
  
    
    {  uploading&&(
        <div className={sAdmin.modalWriper}>
            <div className={sApp.flag}>
            { lang[langSwitch].pleaseWaitDataLoading /*Зачекайте, дані завантажуються...*/ }<br /><br />
                <img alt='фото' src='/img/main/ukrainian-waving-flag-30.gif' />
               </div>
          </div>
      )}
   

    <ConfirmButtons
      isOpen={isConfirmButtonsModalOpen}
      onClose={closeConfirmButtonsModal}
      confirmFunction={confirmFunction}
      messageText={messageText}
      langSwitch={langSwitch}
    />

  </div>);
};

export default EditVideoGalery;




