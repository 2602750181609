
import state from '../../../../state/state'
import s from './adminPoomsae.module.css'
import lang from '../../../../state/language'
import PoomsaeGridFooter from './poomsaeGridFooter'



function AthletesTableForAthetes({  grid, langSwitch,competition}) {
const battleNumbers=grid.battleNumbers
const battleNumbersForPrint=[]
const cort=grid.cort
let  count = 0;
const athletesListData = grid.athletes
let roundForPrint

// console.log('grid: ',grid)
// console.log('athletesListData: ',athletesListData)
 
    const athletesList = athletesListData?athletesListData:[]
 
    const semiFinalAtletes = athletesListData.length > 8 && athletesListData.length < 20 ? athletesListData : grid.semiFinalAtletes&&Array.isArray( grid.semiFinalAtletes)&& grid.semiFinalAtletes.length>0?grid.semiFinalAtletes: []
 
    const finalAtletes = athletesListData.length < 9 ? athletesListData : grid.finalAtletes&&Array.isArray(grid.finalAtletes)&&grid.finalAtletes.length>0?grid.finalAtletes:[]


       ////////////////////////////////////////////////////////////////////////////////

return (<>
 <div className={s.mainDiv} key={grid.category}>
            <div  className={s.gridHeader}>
            <div className={s.title}>{competition.title}</div>
                <div className={s.date}>
                    {competition.dateStart && competition.dateFinish ? (<>
                        {state.formatDate(competition.dateStart).slice(0, 2)}-
                           {state.formatDate(competition.dateFinish)} {lang[langSwitch].yearShort/*p.*/}
                    </>) : (<>
                          {competition.dateOfCompetition}{lang[langSwitch].yearShort/*p.*/}
                    </>)}
                </div>
                <div className={s.category}>
                    {lang.poomsaeCatUkr(langSwitch, grid.category)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {lang[langSwitch].court/*Корт*/}: {grid.cort} </div>
            </div>

            <table className={s.gridTable}>
              <thead className={s.header}>
                    <tr >
                        {athletesList.length > 19 ? (<><td style={{ width: '33%' }}>
                            {lang[langSwitch].qualifyingRound/*Відбірковий Тур*/}
                        </td></>) : null}
                        {athletesList.length > 8 ? (<><td style={{ width: athletesList.length > 19 ? '33%' : '50%' }}>
                            {lang[langSwitch].semifinal/*Півфінал*/}
                        </td></>) : null}
                        <td>
                            {lang[langSwitch].final/*Фінал*/}
                        </td>
                    </tr>
             </thead>

<tbody className={s.tBody} >
        
        <tr>
        {/* відбірковий тур */}
        {athletesListData.length > 19 ? (<>
            <td style={{ width: '33%' }}><table><tr><td>
                {athletesList.map((item, index) => {
                
                    if (Array.isArray(item.teamPoomsae)) {
                        return (
                            <div className={index === 0 ? s.tableFirstItem : s.tableItem} key={item.gridId}>
                                <div className={s.battleNumForJudge}>{cort}.{battleNumbers[count]}</div>   
                                 <div className={s.invisible}> {count++}</div>
                                <div>
                                    <div className={s.athleteDivForAthletes} >
                                        {state.poomsaeTeam(item.teamPoomsae)}
                                    </div>
                                    <div className={s.teamDivForAthletes}>{state.regionShort(item.team)}</div>
                                </div>

                              
                            </div>
                        )
                    } else {
                        return (
                            <div className={index === 0 ? s.tableFirstItem : s.tableItem} key={item.gridId}>
                                <div className={s.battleNumForJudge}>{cort}.{battleNumbers[count]}</div>
                                <div className={s.invisible}> {count++}</div>
                                <div className={s.athleteDivBox}>
                                    <div className={s.athleteDivForAthletes} > {state.athleteName(item.id)}</div>
                                    <div className={s.teamDivForAthletes}>{state.regionShort(item.team)}</div>

                                </div>

                            </div>
                        )
                    }

                })}
              
            
                </td></tr></table>
            </td>
        </>) : null}

        {/* півфінал */}
      
            {semiFinalAtletes.length > 0 ? (<>
 <td style={{ width: athletesList.length > 19 ? '33%' : '50%' }}> 
 <table><tr><td>
                {semiFinalAtletes.map((item, index) => {              
                    if (Array.isArray(item.teamPoomsae)) {
                        return (
                            <div className={index === 0 ? s.tableFirstItem : s.tableItem} key={item.gridId}>
                                <div className={s.battleNumForJudge}>{cort}.{battleNumbers[count]}</div>
                                <div className={s.invisible}>{roundForPrint='Півфінал'}{battleNumbersForPrint.push(battleNumbers[count])}  {count++}</div>
                                <div>
                                    <div className={s.athleteDivForAthletes} >
                                        {state.poomsaeTeam(item.teamPoomsae)}
                                    </div>

                                    <div className={s.teamDivForAthletes}>{state.regionShort(item.team)}</div>

                                </div>
                                
                            </div>
                            )
                    } else {
                        return (
                            <div className={index === 0 ? s.tableFirstItem : s.tableItem} key={item.gridId}>
                                <div className={s.battleNumForJudge}>{cort}.{battleNumbers[count]}</div>
                                <div className={s.invisible}>{roundForPrint='Півфінал'}{battleNumbersForPrint.push(battleNumbers[count])}  {count++}</div>
                                <div className={s.athleteDivBox}>
                                    <div className={s.athleteDivForAthletes}> {state.athleteName(item.id)}</div>

                                    <div className={s.teamDivForAthletes}>{state.regionShort(item.team)}</div>

                                </div>

                            </div>
                        )
                    }

                })}

         </td></tr></table>
        </td>
            </>) : (<>
            {/* порожній масив півфіналу */}
                {athletesList.length > 19 ? (<>
                    <td style={{ width: athletesList.length > 19 ? '33%' : '50%' }}> 
                    <table><tr><td>
                    {Array(19).fill(null).map((item, index) => {                       
                        return (
                            <div className={index === 0 ? s.tableFirstItem : s.tableItem} key={index}>
                                <div className={s.battleNumForJudge}>{cort}.{battleNumbers[count]}</div>
                                <div className={s.invisible}> {count++}</div>
                                <div>
                                    <div className={s.athleteDivForAthletes} > &nbsp;</div>

                                    <div className={s.teamDivForAthletes}>&nbsp;</div>

                                </div>
                            </div>
                        )
                    })}            
         </td></tr></table>
         </td>
                </>) : null}
    
            </>)}
           

        {/* фінал */}
       
            
            {finalAtletes.length>0?(<>
                <td style={{ width: athletesList.length > 19 ? '33%' : '50%' }}> 
                <table><tr><td>
                {finalAtletes.map((item, index) => {                 
                    if (Array.isArray(item.teamPoomsae)) {
                        return (
                            <div className={index === 0 ? s.tableFirstItem : s.tableItem} key={item.gridId}>
                                <div className={s.battleNumForJudge}>{cort}.{battleNumbers[count]}</div>
                                <div className={s.invisible}>{roundForPrint='Фінал'}{battleNumbersForPrint.push(battleNumbers[count])}  {count++}</div>
                                <div>
                                    <div className={s.athleteDivForAthletes} >
                                        {state.poomsaeTeam(item.teamPoomsae)}
                                    </div>
                                    <div className={s.teamDivForAthletes}>{state.regionShort(item.team)}</div>
                                </div>

                            </div>                      
                       )
                    } else {
                        return (
                            <div className={index === 0 ? s.tableFirstItem : s.tableItem} key={item.gridId}>
                                <div className={s.battleNumForJudge}>{cort}.{battleNumbers[count]}</div>
                                <div className={s.invisible}>{roundForPrint='Фінал'}{battleNumbersForPrint.push(battleNumbers[count])}  {count++}</div>
                                <div className={s.athleteDivBox}>
                                    <div className={s.athleteDivForAthletes} > {state.athleteName(item.id)}</div>
                                    <div className={s.teamDivForAthletes}>{state.regionShort(item.team)}</div>
                                </div>
                               
                            </div>
                        )
                    }
                })}
               
              
                </td></tr></table>
                 </td>
                </>):(<>
                {/* Порожній масив фіналу */}
                  
                    <td style={{ width: athletesList.length > 19 ? '33%' : '50%' }}> 
                    <table><tr><td>
                    {Array(8).fill(null).map((item, index) => {
                     
                        return (<>
                        
                            <div className={index === 0 ? s.tableFirstItem : s.tableItem} key={index}>
                                <div className={s.battleNumForJudge}>{cort}.{battleNumbers[count]}</div>
                                <div className={s.invisible}> {count++}</div>
                                <div>
                                    <div className={s.athleteDivForAthletes} >&nbsp; </div>

                                    <div className={s.teamDivForAthletes}>&nbsp;</div>

                                </div>
                            </div>
                        </>)
                    })}
              
         </td></tr></table>
         </td>
              
                </>)}
            
                </tr>

</tbody>
        </table>
            <PoomsaeGridFooter competition={competition} winnerAthletes={null} winner={null} />
            </div>
    </>)
}

export default AthletesTableForAthetes