import s from './grids.module.css'
import state from '../../../../state/state.js'
import { useState } from 'react';
import GridFooter from './GridFooter';
import lang from '../../../../state/language.js';

function Grid3(props) {
	// const athlete = state.tournConstructor.sortAthletesInGrid(props.athletes)
	const poomse=props.competition.poomse==1?true:false 
const [check, setCheck] = useState(0);  
	  
	let athleteInput
	if (props.gridDB===null || props.gridDB===undefined){
		const data = state.tournConstructor.sortAthletesInGrid(props.athletes)
	
	//перевіряємо співпадіння команд в першому бою

if(check===0) { 
	state.tournConstructor.checkForDuplicates(data,props.category,poomse, props.langSwitch)
setCheck(1)
}
		athleteInput = data.flatMap(item => [item[1], item[2]]);//робимо простий масив з масив з об'ектів
		athleteInput = athleteInput.filter(item => item !== null); //обрізаємо порожні єлементи масиву
		athleteInput.forEach((athlete, index) => { // створюємо ордер для сотрування
			if (athlete) { athlete.order = index }
		});
	}
	else{ athleteInput = props.gridDB.athleteList}

	const[athleteList1,setAthleteList1]=useState(athleteInput)
		const [currentAthlete, setCurrentAthlete] = useState(null)
	
	
		function dragStartHandler(e, athlete) { //беремо об'єкт
			e.target.className = s.StartHandler
			setCurrentAthlete(athlete)
		};
		function dragEndHandler(e) { //об'єкт "сходить" з іншого об'єкта
			e.target.className = s.EndHandler
		};
	
		function dragOverHandler(e) {//об'єкт "портиває" інший об'єкт
			e.target.className = s.OverHandler
			e.preventDefault()
		};

		function dropHandler(e, droppedAthlete) {
			e.target.className = s.divAthleteInGrid;
			e.preventDefault();
		
			if (currentAthlete) {
				setAthleteList1((prevList) => {
					return prevList.map((a) => {
		
						if (a.gridId === droppedAthlete.gridId) {
							const index = state.competitionResult.findIndex(athl => athl.gridId === a.gridId);
							state.competitionResult[index].competitionPlace = currentAthlete.competitionPlace;
							return { ...a, order: currentAthlete.order, competitionPlace: currentAthlete.competitionPlace };
						}
						if (a.gridId === currentAthlete.gridId) {
							const index = state.competitionResult.findIndex(athl => athl.gridId === a.gridId);
							state.competitionResult[index].competitionPlace = droppedAthlete.competitionPlace;
							return { ...a, order: droppedAthlete.order, competitionPlace: droppedAthlete.competitionPlace };
						}
						// }
						return a;
					});
				});
			}
		}
		
		function sortAthletes(a, b) {
			const getOrder = (item) => item.order
			if (getOrder(a) > getOrder(b)) {
				return 1;
			} else {
				return -1;
			}
		}
	
		let athleteList //вкладаємо масив у змінну для виводу
	
		if (currentAthlete === null) { athleteList = athleteList1 }
		else { athleteList = athleteList1.sort(sortAthletes) }
	
	
		//////////////////////////////////////////////////////////////////////////////
	
		// проводимо поєдинки
		const [winner, setWinner] = useState(null); 
		const [winner1, setWinner1] = useState(null); 
		const [winner2, setWinner2] = useState(null); 
		

	
		athleteList1.forEach((a) => { //завантажуємо спортсменів в масив результатів змагань
	
			// Перевірка, чи спортсмен вже є в state.competitionResult
			const athleteExists = state.competitionResult.some((existingAthlete) => existingAthlete.gridId === a.gridId);
	
			if (!athleteExists) {
				a.competitionCategory = props.category;//катег. в якій виступає спортсмен
				a.ratingOnComp = 0 //рейтинг зароблений в поточному турнірі
				a.competitionPlace = '3'  //змінити в кожній сітці
				a.battlesOnComp = 1 //кількість поєдинків на турнирі
			a.victoryOnComp = 0 //кількість перемог на турнирі
				state.competitionResult.push(a);
			}
		});
		
	function winnerAthlete(athlete, setWinner, rankComp, rankCount, place) {
		const athleteIndex = state.competitionResult.findIndex(athl => athl.gridId === athlete.gridId)
		

		rankComp = parseInt(rankComp)

		let points //бали які спортсмен заробив за поєдинок
		if (rankCount !== null) { points = rankComp * rankCount }
		if (rankCount === null) { points = rankCount }

		state.competitionResult[athleteIndex].ratingOnComp = //редагуємо рейтинг
			state.competitionResult[athleteIndex].ratingOnComp + points			
			
		state.competitionResult[athleteIndex].competitionPlace = place//просуваємся по місцях
		
		state.competitionResult[athleteIndex].battlesOnComp++ //редагуємо кількість поєдинків
			
		//редагуємо кількість перемог, перемог на 1 менше ніж поєдинків (крім 1 місця)
		state.competitionResult[athleteIndex].victoryOnComp = state.competitionResult[athleteIndex].battlesOnComp - 1
				
		if (points / 4 === rankComp) { //за перше місце повертаемо вилучену перемогу
			state.competitionResult[athleteIndex].battlesOnComp--			
		}
		setWinner(athlete)
	}


	function handleCancel(athlete, setWinner, rankComp, rankCount) {


		rankComp = parseInt(rankComp)
		const athleteIndex = state.competitionResult.findIndex(athl => athl.gridId === athlete.gridId)
		
		//редагуємо поєдинки та перемоги		
		state.competitionResult[athleteIndex].battlesOnComp--
				
		state.competitionResult[athleteIndex].victoryOnComp--
	
		state.competitionResult[athleteIndex].ratingOnComp =
			state.competitionResult[athleteIndex].ratingOnComp - (rankCount !== null ? rankComp * rankCount : rankComp)
			
		const i = parseInt(state.competitionResult[athleteIndex].competitionPlace) + 1 //з 1 місця спускаємся на 2, тому +1
		if (i === 2) { state.competitionResult[athleteIndex].competitionPlace = '2' }
		else if (i === 3) { state.competitionResult[athleteIndex].competitionPlace = '3' }
		else if (i >= 4 && i <= 8) { state.competitionResult[athleteIndex].competitionPlace = '5 - 8' }
		else if (i > 8 && i <= 16) { state.competitionResult[athleteIndex].competitionPlace = '9 - 16' }
		else if (i > 16 && i <= 32) { state.competitionResult[athleteIndex].competitionPlace = '17 - 32' }
		else { }

		setWinner(null)

	}

		if (state.saveGridsFunctionStart) {
			props.saveGrids(
				athleteList.length, athleteList, props.competition.title, props.category, props.cort, props.battleNumbers)
		}
		/////////////////////////////////////////////////////////////////////////////////////
		const selectedIds=athleteList.map((item=>(item.gridId)))
		const winnerAthletesData = state.competitionResult.filter(athlete => selectedIds.includes(athlete.gridId));
		
		 const winnerAthletes=state.sortWinnerAthletes(winnerAthletesData)
	///////////////////////////////////////////////////////////////////////////////////
	const [wiew, setWiew] = useState(false)
	const [score1, setScore1] = useState(null)
	const [score2, setScore2] = useState(null)

	const handleScoreChange = (e) => { setScore1(e.target.value) };
	const handleScoreChange2 = (e) => { setScore2(e.target.value) };
	
	function gridsResultMaker(){
		setWiew(true);
		const battleScores={}
			for(let i=1;i<=2;i++){
				battleScores[`score${i}`] = eval('score' + i);  
			}

			const winners={}
			for(let i=0;i<=2;i++){
				winners[`winner${i === 0 ? '' : i}`] = eval(`winner${i === 0 ? '' : i}`); 
			}
		
			const result = {}
			result.numberOfAthletes = athleteList.length;
			result.athleteList = athleteList
			result.competitionTitle = props.competition.title
			result.category = props.category
			result.cort = props.cort
			result.battleNumbers = props.battleNumbers
			result.battleScores = battleScores
			result.winners=winners
			result.winnerAthletes=winnerAthletes 
			state.gridsResult.push(result)
	}

	function deleteGridsResult(){
		state.gridsResult=state.gridsResult.filter(item=>item.category!==props.category)
		setWiew(false);setScore1(null);setScore2(null);
	}
// console.log(state.gridsResult)
	//////////////////////////////////////////////////////////////////////////////
	
	return (<>
		<div className={s.mainDiv}>
			<div className={s.title}>
				<h3>{props.competition.title}</h3>
				<h3 >
  {props.competition.dateStart&&props.competition.dateFinish?(<>
  <u><b>{state.formatDate(props.competition.dateStart).slice(0, 2)}</b>- 
        <b>{state.formatDate(props.competition.dateFinish)} р.</b></u>         
      </>):(<>
    <u><b>  {props.competition.dateOfCompetition}р.</b></u> 
      </>)}
        <br/></h3>
				<h4 id={props.id}>
				{poomse?lang.poomsaeCatUkr(props.langSwitch,props.category):
					state.weightCatUkr (props.category,props.langSwitch, true)}
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				Корт: {props.cort}</h4>
			</div>
<table width="600" >
  <tbody>
	  <tr>
	  	<td></td>
		<td>
		{winner !== null ? (<>
<div className={s.sportCenter}><div className={s.divAthleteInGrid} onContextMenu={(e) => {e.preventDefault(); handleCancel(winner,setWinner,props.competition.rankComp,4)}}>
    
	<div><img className={s.goldCup} alt='фото' src = '/img/main/goldCup1.png'/></div>
	{winner.teamPoomsae?state.poomsaeTeam(winner.teamPoomsae):state.athleteNameShort(winner.id)}<br />
	<div className={s.battleScore}>{score1&&wiew?score1:null}</div>{/*рахунок поєдинка*/}
<br/></div> </div>
	</>) :
			<div className={s.figthCenter}>{props.cort}.{props.battleNumbers[1]} </div>}

{/*ввод рахунку поєдинка*/}
	{wiew? null:
	<div className={s.noPrint} style={{textAlign:'center'}}>
	<input onChange={handleScoreChange} type="text" id="title" name="title" />
		</div>}
	
			
			</td>
		<td></td>
	  </tr>
    <tr>
      <td className={s.blueLeft}>
		<div 	onDragStart={(e)=>dragStartHandler(e,athleteList[0])}
									onDragLeave={(e)=>dragEndHandler(e)}
									onDragEnd={(e)=>dragEndHandler(e)}
									onDragOver={(e)=>dragOverHandler(e)}
									onDrop={(e)=>dropHandler(e,athleteList[0])}
									draggable ='true'
									className={s.divAthleteInGrid}>
								 <div onClick={winner1===null?()=>{winnerAthlete(athleteList[0],setWinner1,props.competition.rankComp,3,2)}:null}>  {/*	4-бали для рейтегну за місце, 1 місце*/}
							
								{ athleteList[0].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[0].teamPoomsae)}<i>{athleteList[0]['team']}</i></>) : (<>{state.athleteName(athleteList[0]['id'])}<br /><i>{athleteList[0]['team']}</i></>) }

							</div></div>

	<div className={s.figthLeft} >
		{props.cort}.{props.battleNumbers[0]} 
		</div>
							
		</td>
      <td className={s.nextRed}><div className={s.sportCenter}>
		
		{/* Напівфінал та фінал */}

	
	{winner1 !==null?(<>
		
    <div className={s.divAthleteInGrid} onContextMenu={(e) => {e.preventDefault(); 
	 if (winner === null) { handleCancel(winner1, setWinner1, props.competition.rankComp, 3); }}}
        onClick={winner===null?()=>{winnerAthlete(winner1,setWinner,props.competition.rankComp,4,1)}:null}>
    
	{winner1.teamPoomsae?state.poomsaeTeam(winner1.teamPoomsae):state.athleteNameShort(winner1.id)}<br /> </div>
    </>):
	(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
		
		 <div className={s.battleScore}> {/*рахунок з полем вводу*/}
	{wiew?
	score2&&wiew?score2:null:
	<div className={s.noPrint} style={{textAlign:'center'}}>
	<input onChange={handleScoreChange2} type="text" id="title" name="title" />
		</div>}
	</div>

	
		</div></td>
		<td ></td>
    </tr>
	  
	  <tr>
		  <td></td><td className={s.next}></td><td ></td>
	  </tr>
	  
    <tr>
      <td className={s.redLeft}>
		
	  {/* <div className={s.divAthleteInGrid}>{state.athleteName(athlete[0][2]['id'])}<br/><span>{athlete[0][2]['team']}</span></div> */}
		<table>
		<tbody>
			<tr><td>
	  <div 	onDragStart={(e)=>dragStartHandler(e,athleteList[1])}
									onDragLeave={(e)=>dragEndHandler(e)}
									onDragEnd={(e)=>dragEndHandler(e)}
									onDragOver={(e)=>dragOverHandler(e)}
									onDrop={(e)=>dropHandler(e,athleteList[1])}
									draggable ='true'
									className={s.divAthleteInGrid}>
								 <div onClick={winner1===null?()=>{winnerAthlete(athleteList[1],setWinner1,props.competition.rankComp,3,2)}:null}>  {/*	4-бали для рейтегну за місце, 1 місце*/}
						
								{ athleteList[1].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[1].teamPoomsae)}<i>{athleteList[1]['team']}</i></>) : (<>{state.athleteName(athleteList[1]['id'])}<br /><i>{athleteList[1]['team']}</i></>) }

							</div></div></td>


							<td className={s.colorLeft}>Син.</td></tr>
							</tbody>
							</table>
		</td>
      <td className={s.next}><div className={s.sportCenter}>
		
		
		{/* Напівфінал та фінал */}
{winner2 !==null?(<>
   
    <div className={s.divAthleteInGrid} onContextMenu={(e) => {e.preventDefault();
	 if (winner === null) { handleCancel(winner2, setWinner2, props.competition.rankComp, 3); }}}
        onClick={winner===null?()=>{winnerAthlete(winner2,setWinner,props.competition.rankComp,4,1)}:null}>
    
	{winner2.teamPoomsae?state.poomsaeTeam(winner2.teamPoomsae):state.athleteNameShort(winner2.id)}<br /> </div>
    </>):
	(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }
		
		
		</div></td>
		<td className={s.redRight}>

			<table>
			<tbody>
				<tr>
			<td className={s.colorRight}>Чер.</td>
				<td>
								
							{/* <div className={s.divAthleteInGrid}>{state.athleteName(athlete[1][1]['id'])}<br/><span>{athlete[1][1]['team']}</span></div> */}
								
							<div 	onDragStart={(e)=>dragStartHandler(e,athleteList[2])}
									onDragLeave={(e)=>dragEndHandler(e)}
									onDragEnd={(e)=>dragEndHandler(e)}
									onDragOver={(e)=>dragOverHandler(e)}
									onDrop={(e)=>dropHandler(e,athleteList[2])}
									draggable ='true'
									className={s.divAthleteInGrid}>
								<div onClick={winner===null?()=>{winnerAthlete(athleteList[2],setWinner2,props.competition.rankComp,3,2)}:null}>  {/*	4-бали для рейтегну за місце, 1 місце*/}
								
								{ athleteList[2].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[2].teamPoomsae)}<i>{athleteList[2]['team']}</i></>) : (<>{state.athleteName(athleteList[2]['id'])}<br /><i>{athleteList[2]['team']}</i></>) }

							</div></div>
</td>
</tr>
</tbody>
</table>
								</td>
    </tr>
	
  </tbody>
</table>

{/* ////////////////////////////////////////////////////// */}
	
<GridFooter 
	competition={props.competition} 
	wiew={wiew} 
	deleteGridsResult={deleteGridsResult}
	gridsResultMaker={gridsResultMaker} 
	winnerAthletes={winnerAthletes} 
	winner={winner}/>


		</div>
	</>)
}

export default Grid3