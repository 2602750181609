import React from 'react';
import state from '../../../state/state.js';
import axios from 'axios';
import lang from '../../../state/language.js';

function MailForCoach(props) {

  const langSwitch = props.langSwitch.langSwitch
  const poomse = props.poomse
  const gridsDB = props.gridsDB

  const mailData = [] // вихідний масив для відправлення поштою 

  ////////////////////////////////////////////////////////////
  function listCoaches() {

    // Пустий об'єкт Set для відстеження унікальних ідентифікаторів тренерів
    const uniqueCoachIds = new Set();

    // Перебираємо всі об'єкти спортсменів
    for (let i = 0; i < gridsDB.length; i++) {
      // Перебираємо всі елементи athleteList
      for (let j = 0; j < gridsDB[i].athleteList.length; j++) {
        const athlete = gridsDB[i].athleteList[j];

        // Перевірка, чи є елемент масивом (командою)
        if (Array.isArray(athlete)) {
          // Якщо це команда, перебираємо всіх спортсменів у команді
          for (let k = 0; k < athlete.length; k++) {
            const teamAthlete = athlete[k];
            const coach = teamAthlete.coach;
            const coach2 = teamAthlete.coach2;
            uniqueCoachIds.add(coach);
            uniqueCoachIds.add(coach2);
          }
        } else {
          // Якщо це індивідуальний спортсмен, обробляємо його як зазвичай
          const coach = athlete.coach;
          const coach2 = athlete.coach2;
          uniqueCoachIds.add(coach);
          uniqueCoachIds.add(coach2);
        }
      }
    }

    // Перетворюємо Set на масив, щоб отримати список унікальних ідентифікаторів тренерів
    const uniqueCoaches = Array.from(uniqueCoachIds);

    // Перебираємо унікальні ідентифікатори тренерів для формування mailData
    uniqueCoaches.forEach(coachId => {
      // Отримуємо дані тренера за його ідентифікатором
      const coachData = state.athletes.find(athlete => athlete.id == coachId);

      if (coachData) {
        // Створюємо об'єкт з інформацією про тренера та його поштовою адресою
        const coachObject = {
          id: coachId,
          // userEmail: 'Sergeyburak79@gmail.com',
          userEmail: coachData.eMail,
          subject: `Розклад виступу спортсменів`,
          text: `${coachData.sex === 'male' ? 'Шановний ' : 'Шановнa '} ${coachData.personName} ${coachData.fatherName}, розклад виступу Ваших спортсменів на турнирі:<br/><b>${props.competition.title}</b><br/>`,
          gridsPdfForMale: null
        };

        // Додаємо об'єкт до масиву mailData
        mailData.push(coachObject);
      }
    });
  }
  listCoaches()
  ///////////////////////////////////////////////////////////////////////////////
  for (let k = 0; k < mailData.length; k++) { // цикл тренерів
    for (let i = 0; i < gridsDB.length; i++) { // цикл категорій

      const athleteList = gridsDB[i].athleteList
      //формування графіку поєдинків для відправлення тренеру по  пошті

      let battles = state.tournConstructor.battlesData(
        gridsDB[i].numberOfAthletes, athleteList, gridsDB[i])


      let textData1 = `<br/><b><u>${poomse ? lang.poomsaeCatUkr(langSwitch, gridsDB[i].category) : state.weightCatUkr (gridsDB[i].category, props.langSwitch)
        }</u></b><br/>`
      let textData = `<br/><b><u>${poomse ? lang.poomsaeCatUkr(langSwitch, gridsDB[i].category) : state.weightCatUkr (gridsDB[i].category, props.langSwitch)
        }</u></b><br/>`


      let notSortedData = []
      for (let j = 0; j < athleteList.length; j++) { // цикл спорстменів
        ///////////////////////////////////////////////////////////////////// 

        if (athleteList[j].teamPoomsae) {//формування команди
          let testSwitcher = false
          athleteList[j].teamPoomsae.forEach((item) => {
            if (item.coach == mailData[k].id || item.coach2 == mailData[k].id) { testSwitcher = true }
          })

          if (testSwitcher) { notSortedData.push(battles[j]) }
        } else {
        
          if (athleteList[j].coach == mailData[k].id || athleteList[j].coach2 == mailData[k].id) {
          
            if (battles !== undefined) {
              notSortedData.push(battles[j])               
            }
          }
        }

        notSortedData.sort((a, b) => {
          // Використовуємо регулярний вираз для знаходження числа між першими тегами <b></b>
          const numberA = parseInt(a.match(/<b>(\d+)<\/b>/)[1]);
          const numberB = parseInt(b.match(/<b>(\d+)<\/b>/)[1]);
          return numberA - numberB;
        });
      } // закриття циклу спортсменів

      notSortedData.map((item) => { textData += item })

      if (textData !== textData1) {

        mailData[k].text += textData
      }
    }//закінчення циклу категорій
  }// закінчення циклу тренерів



  //   //////////////////////////////////////////////////////////////////////////

  function mail(mailData) {
    // console.log('Розпочато розсилку')
    // Отримайте адресу вашого сервера
    const serverURL = state.api.emailSend;

    // Відправте POST-запит з даними
    axios.post(serverURL, { mailData })
      .then(response => {
        // console.log(response.data);
      })
      .catch(error => {
        console.error('Помилка при відправленні POST-запиту:', error);
      });

  }
  mail(mailData)
  // console.log(mailData)

  // ////////////////////////////////////////////////////////////////////////////////
  const createMarkup = (html) => { return { __html: html }; };

  return (
    <>
      <div dangerouslySetInnerHTML={createMarkup(mailData[0].text)} />


    </>
  );
}

export default MailForCoach;
