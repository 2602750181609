
import React from 'react';
import s from './adminCompetition.module.css';
import lang from '../../../state/language';
import state from '../../../state/state';

function ScaleAthletesCell ({
                  langSwitch, 
                  setIsOpenScaleAthletesCell, 
                  cat, 
                  setTransferAthleteData, 
                  setTransferOpen,
                  setScaleTeam}) {

let raitingOfAthlete=[]

function transferAthlete(data){
  setScaleTeam(false)
  setTransferAthleteData({category:cat.category, athlete:data})
  setTransferOpen(true)
  setIsOpenScaleAthletesCell(false)
}
  return (
    <div>
  <div className={s.modalWriper} >
     <div className={s.modal} >
      <div className={s.categoryBlock}>
       <div className={s.h1}><b>
        {lang[langSwitch].selectAthleteYouWishRemoveFromCategory /*Оберіть спортсмена якого ви бажаєте вилучити з поточної категорії*/}
      </b> </div>
      
        {/* ///////////////////////////////////////////////////////////////////////////    */}
        <div className={s.boxTitile1}>
          <div className={s.categoryBlockTitle} >
            {state.weightCatUkr(cat.category, langSwitch)}</div>
          <div className={s.region}>
            {cat.athletes.length % 10 === 1 ?
              lang[langSwitch].declared1 /*Заявлений*/ + ':' + ' '
              :
              lang[langSwitch].declared /*Заявлено*/ + ':' + ' '
            }
            <b>{cat.athletes.length}</b>
            {cat.athletes.length % 10 === 0 ?
              ' ' + lang[langSwitch].participants /*учасників*/
              : ''}
            {cat.athletes.length % 10 === 1 && cat.athletes.length !== 11 ?
              ' ' + lang[langSwitch].participant /*учасник*/
              : ''}
            {(cat.athletes.length % 10 >= 2 && cat.athletes.length % 10 <= 4) && (cat.athletes.length % 100 < 10 || cat.athletes.length % 100 >= 20) ?
              ' ' + lang[langSwitch].participants1 /*учасникa*/
              : ''}

            {cat.athletes.length % 10 > 4 || cat.athletes.length === 11 ?
              ' ' + lang[langSwitch].participants /*учасників*/
              : ''}
            <br />&nbsp;</div>
        </div>

        <div className={s.gridContainerForCategories}>
          {cat.athletes.map((athlete, index) => {
            

            return(
       
             <div key={athlete.id+index}
                  style={{cursor:'pointer'}}
                  onClick={()=>{transferAthlete(athlete)}}>
               
                <div className={s.cell}>
                  <div className={s.categoryName}>
                    <div>  {state.athleteName(athlete.id)} </div>
                     <div className={s.categoryData}>  
                      <b>{state.formatDate(athlete.birthday)} </b> 
                       {athlete.birthday?lang[langSwitch].yearShort /*р.*/:null}
                      </div>
                    <div className={s.region}>
                      <u>
                        {langSwitch === 'ukr' ? athlete.region :
                          langSwitch === 'eng' ? lang.regionsEng(athlete.region) : null
                        }
                      </u>
                    </div>
                    <div className={s.region}>
                      {lang[langSwitch].coachShort /*трен.*/}:  <b> {state.myCoach(athlete.coach)}</b>; &nbsp;
                      {lang[langSwitch].coachShort /*трен.*/}2:  <b> {state.myCoach(athlete.coach2)}</b>

                    </div>
                  </div>



             
                    <div className={s.categoryImg}>
                    <img src={athlete.photo ? athlete.photo : state.noAvatarPhoto}
                      alt={state.myCoach(athlete.id)} />
                  </div>
                
                  <div className={s.categoryData}>

                    <div>
                      {lang[langSwitch].qualificationShort /*кваліф.*/}:
                      <b> {athlete.dan}</b>;  </div>
                    <div>
                      {lang[langSwitch].rank /* розряд*/}:
                      <b> {langSwitch === 'ukr' ? athlete.rank :
                        langSwitch === 'eng' ? lang.rankEng(athlete.rank) : null
                      }
                      </b></div>

                    <div>
                      {lang[langSwitch].height /*зріст*/}:
                      <b> {athlete.height}</b>
                      {lang[langSwitch].cm /*см.*/}
                    </div>
                    <div>
                      {lang[langSwitch].weight /*вага*/}:
                      <b> {athlete.weiht}</b>
                      {lang[langSwitch].kg /*кг.*/}
                    </div>

                    <div>
                      {lang[langSwitch].sex /*стать*/}:
                      <b> {athlete.sex === 'male' ?
                        lang[langSwitch].sexM /*Чоловік*/
                        : null}
                        {athlete.sex === 'female' ?
                          lang[langSwitch].sexF /*Жінка*/
                          : null}
                        {athlete.sex === null || athlete.sex === undefined ? 'athlete.sex' : null}

                      </b>

                    </div>

                    <div>
                      {lang[langSwitch].fights /*поєдинків*/}:
                      <b> {athlete.battles}</b>
                    </div>

                    <div>
                      {lang[langSwitch].victory /*перемог*/}:
                      <b> {athlete.victory}</b>
                    </div>
                    <div>
                      {lang[langSwitch].rating /*рейтинг*/}:
                      <b> {athlete.rating} </b>
                      {lang[langSwitch].points /*балів*/}
                    </div>
                  </div>

                  <div className={s.categoryRating}>

           
                <div key={athlete.id} className={s.listForCoach}> {raitingOfAthlete = state.ratingQuery(athlete.id )}</div>

                    <div> <b>{raitingOfAthlete[0]} </b>
                      {lang[langSwitch].placeInRating /*місце загального рейтингу*/}
                    </div>                    
                    <div>  <b>{raitingOfAthlete[2]} </b>
                      {lang[langSwitch].placeInCategory /*місце в категорії*/}
                      <b> {state.weightCatUkr(raitingOfAthlete[1])}</b></div>
         

                  

                 
                  </div>

                </div>

              </div>
          

        )})} 
            
           </div>

        {/*  ///////////////////////////////////////////////////////////////////////////////////// */}



      </div>



      <button type='button' onClick={()=>{setIsOpenScaleAthletesCell(false)}}>
        {lang[langSwitch].close /*Закрити*/}
      </button>
   
    </div>
    </div>
    </div>
  );
};

export default ScaleAthletesCell;


