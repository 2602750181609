import { NavLink } from 'react-router-dom';
import state from '../../state/state'
import s from './header.module.css'
import { useEffect, useState } from 'react';




function Header({}) {
  


  let athletes=[] //масив спортсменів для галереї а хедері
    athletes = JSON.parse(JSON.stringify(state.athletes))     
    athletes.sort((a, b) => b.rating - a.rating)
    athletes=athletes.slice(0, 20)
    state.bestAthletes=athletes


///////////////////////////  Галерея Спортсменів   ///////////////////////////////////////
    const [direction, setDirection] = useState("forward"); // Напрямок руху
    const [offset, setOffset] = useState(0); // Зсув у пікселях
  
    const containerWidth = window.innerWidth-40  // Ширина контейнера (можна динамічно)
    const itemWidth = 85; // Ширина одного елемента (включаючи margin)
  
    useEffect(() => {
      if (athletes&&Array.isArray(athletes)&&athletes.length>0){
      const interval = setInterval(() => {
        setOffset((prevOffset) => {
          const maxOffset = athletes.length * itemWidth - containerWidth;



          const newOffset =
            direction === "forward"
              ? prevOffset + 1 // Рухаємося вперед
              : prevOffset - 1; // Рухаємося назад
  
          if (newOffset >= maxOffset) {
            // Якщо останнє фото досягає правого краю
            setDirection("backward");
          } else if (newOffset <= 0) {
            // Якщо перше фото досягає лівого краю
            setDirection("forward");
          }
  
          return newOffset;
        });
      }, 50); // Оновлення кожні 50 мс
  
      return () => clearInterval(interval);}
    }, [direction, athletes]);
/////////////////////////////////////////////////////////////////////////
 
const [currentIndex, setCurrentIndex] = useState(0); // Для вкладеного зображення
  const [bgIndex, setBgIndex] = useState(0); // Для фону

  useEffect(() => {
    // Інтервал для оновлення вкладеного зображення
    const imageInterval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex < state.headerGalery.length - 1 ? prevIndex + 1 : 0
      );
    }, 10000); // 10 секунд

    return () => clearInterval(imageInterval); // Очищення інтервалу при розмонтуванні
  }, [currentIndex]);

  useEffect(() => {
    // Таймер для оновлення фону
    const bgTimeout = setTimeout(() => {
      setBgIndex((prevIndex) =>
        prevIndex < state.headerGalery.length - 1 ? prevIndex + 1 : 0
      );
    }, 4500); // 4.5 секунди після зміни вкладеного зображення

    return () => clearTimeout(bgTimeout); // Очищення таймера при повторному виклику
  }, [currentIndex]); // Залежність: оновлення при зміні вкладеного зображення

    return (<>

    <div className={s.header}>
      {/* Вкладене зображення */}
      <div
        className={s.headerBgImage}
        style={{
          backgroundImage: `url(${state.headerGalery[bgIndex]})`,
          transitionDuration: `1500ms`,
        }}
      >
        <img
          className={`${s.headerPhoto} ${s.show}`}
          src={state.headerGalery[currentIndex]}
          alt="Фото"
        />
      </div>
      <div className={s.headerBgColor}>
        <div className={s.headerBgLeftItem}>
          <img className={s.headerLogo} src="/img/main/logoMSAC.png" alt="logoMSAC Logo" />
        </div>
 
{athletes && Array.isArray(athletes) && athletes.length>1?(<>
<NavLink to={`/FrameOfGlory`}>
        <div className={s.imgBox}>
        <div className={s.galeryWriper}>
        <div
          className={s.carousel}
          style={{
            transform: `translateX(-${offset}px)`, // Рух у межах видимого контейнера
          }}
        >
          { athletes.map((athlete, index) =>{
             let imageError=false
            return (            
            <div  key={index}  className={s.carouselItem}>  
             <img className={s.athleteImg} 
              src={athlete.photo&&!imageError?athlete.photo:state.noAvatarPhoto}
              onError={()=>{imageError=true}}
               alt={state.myCoach(athlete.id)}  />           
            </div>
          )})}
        </div>
      </div>

        
        </div>
        </NavLink>
  </>):null}
      </div>
    </div>
        
        
    </>); 
}

export default Header;
