import React from 'react';
import Content from '../content';
import OurPartners from './ourPartners';
import s from './forAllUsers.module.css'

function ContentWriper ({isSmallScreen, langSwitch}) {
  return (<div className={s.contentWriperBox}>
    <div>
     <Content isSmallScreen={isSmallScreen} langSwitch={langSwitch}   />
     </div>
     <div >
         <OurPartners isSmallScreen={isSmallScreen} langSwitch={langSwitch}   />
     </div>
  </div>);
};

export default ContentWriper;