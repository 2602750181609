import React, { useState } from "react";
import axios from 'axios';
import s from '../userProfile/userProfile.module.css'
import sAdmin from '../adminCompetition/adminCompetition.module.css'
import state from "../../../state/state";
import lang from "../../../state/language";
import ConfirmButtons from "../adminCompetition/confirmButtons";
import PasswordForm from "./PasswordForm";
import indexedDBConnection from "../../../state/indexedDBConnection";
import compressImage from "../photo/compressImage";


function AddAthlete(props) {
  const langSwitch = props.langSwitch
  state.messageCount()//лічильник непрочитаних повідомлень

  const [errorMessage, setErrorMessage] = useState('');


  const [personName, setPersonName] = useState(null);
  const [secondName, setSecondName] = useState(null);
  const [fatherName, setFatherName] = useState(null);
  const [birthday, setBirthday] = useState(null);
  const [sex, setSex] = useState(null);
  const [weiht, setWeiht] = useState(null);
  const [height, setHeight] = useState(null);
  const [dan, setDan] = useState(null);
  const [rank, setRank] = useState(null);
  const [fst, setFst] = useState(null);
  const [insuranceComp, setInsuranceComp] = useState(null);
  const [insuranceNum, setInsuranceNum] = useState(null);
  const [insuranceDate, setInsuranceDate] = useState(null);
  const [medicalCertificateNum, setMedicalCertificateNum] = useState(null);
  const [medicalCertificateDate, setMedicalCertificateDate] = useState(null);
  const [eMail, setEMail] = useState(state.userProfile.eMail);
  const [login, setLogin] = useState(null);
  const [pass, setPass] = useState(null);
  const [photo, setPhoto] = useState(null);


  const handleSecondNameChange = (e) => { setSecondName(e.target.value.trim()) }
  const handlePersonNameChange = (e) => { setPersonName(e.target.value.trim()) }
  const handleFatherNameChange = (e) => { setFatherName(e.target.value.trim()) }
  const handleBirthdayChange = (e) => { setBirthday(e.target.value.trim()) }

  const sexData = [
    lang[langSwitch].sexM, //Чоловік
    lang[langSwitch].sexF //Жінка
  ]
  const handleSexChange = (e) => {
    let value = e.target.value;
    if (value === 'Чоловік' || value === 'Male') { value = 'male' }
    else if (value === 'Жінка' || value === 'Female') { value = 'female' }
    setSex(value.trim())
  }
  const handleWeihtChange = (e) => { setWeiht(parseFloat(e.target.value.replace(',', '.'))) }
  const handleHeightChange = (e) => { setHeight(parseFloat(e.target.value.replace(',', '.'))) }
  const handledDanChange = (e) => { setDan(e.target.value.trim()) }
  const handleRankChange = (e) => { setRank(e.target.value.trim()) }
  const handleFstChange = (e) => { setFst(e.target.value.trim()) }
  const handleInsuranceCompChange = (e) => { setInsuranceComp(e.target.value.trim()) }
  const handleInsuranceNumChange = (e) => { setInsuranceNum(e.target.value.trim()) }
  const handleInsuranceDateChange = (e) => { setInsuranceDate(e.target.value.trim()) }
  const handleeEMailNumChange = (e) => {
    if (e.target.value === '') {
      setEMail(state.userProfile.eMail)
    } else {
      const value = e.target.value;
      const checkEMail = state.athletes.filter(item => item.eMail === value.trim())
      const newEMail = value.trim()
      if (checkEMail.length === 0 || state.userProfile.eMail === newEMail) {
        setEMail(newEMail)
        setErrorMessage('')
      }
      else {
        setErrorMessage(
          lang[langSwitch].usedMail //Вказаний вами e-Mail вже використовується іншим користувачем
        )
      }
    }
  };
  const handleMedicalCertificateDateChange = (e) => { setMedicalCertificateDate(e.target.value.trim()) }
  const handleMedicalCertificateNumChange = (e) => { setMedicalCertificateNum(e.target.value.trim()) }
  const handleLoginDateChange = (e) => {
    const value = e.target.value.trim();

    const loginTest = state.athletes.some((item) => item.login === value)
    if (loginTest && value !== state.userProfile.login) {
      setErrorMessage(lang[langSwitch].usedLoginByAnotherUser/*Обраний вами логін вже використовується іншим користувачем*/)
    } else {
      setErrorMessage(null)
      setLogin(value)
    }
  };

  //обробка фото 
  const [compressedFileURL, setCompressedFileURL] = useState(null);
  const [compressedFile, setCompressedFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    setPhoto(state.imgAdress.userProfilePhoto + selectedFile.name)



    // Викликати функцію стиснення зображення та отримати стиснутий файл
    compressImage(selectedFile, {
      maxSizeMB: 0.3,
      maxWidth: 310,
      maxHeight: 394,
    })
      .then((compressedFile) => {

        setCompressedFile(compressedFile)
        // Створити URL для стиснутого файлу
        const compressedFileURL = URL.createObjectURL(compressedFile);

        // Встановити URL стиснутого файлу у стані компонента
        setCompressedFileURL(compressedFileURL);
      })
      .catch((error) => {
        console.error('Помилка стиснення файлу:', error);
      });
  };

  // отримуємо назву файлу, для видалення старого фото 

  //завантаження на сервер

  function update() {

    if (compressedFileURL) {
      const formData = new FormData();
      formData.append('image', compressedFile);

      axios.post(state.api.uploadAvatar, formData)
        .then(response => {
          // Отримано відповідь від сервера після завантаження зображення

          if (response.status === 200 && response.data.message === 'Файл успішно збережено') {

            // Після завантаження зображення ви можете виконати PUT-запит для оновлення профілю
            updateProfile();
          } else {
            setErrorMessage(lang[langSwitch].errorOccurredWhileSavingImage + ': ' /*Помилка під час збереження зображення*/, response.data.message)
            throw new Error('Сервер повернув відповідь без успішного статусу');
          }

        })
        .catch(error => {
          console.error('Помилка під час збереження зображення:', error);
          setErrorMessage(lang[langSwitch].errorOccurredWhileSavingImage + ': ' /*Помилка під час збереження зображення*/, error)
        });
    } else {
      // Якщо `compressedFileURL` не існує, виконуємо тільки PUT-запит для оновлення профілю   
      updateProfile();
    }
  }

  function updateProfile() {
    if (!personName || !secondName || !fatherName || !birthday || !sex || !weiht || !height || !dan || !rank || 
        !fst || !login || !pass || !photo) {
      setErrorMessage(
        lang[langSwitch].addAthleteWarning/*Ви заповнили не усі обовязкові поля. Також не забудьте натиснути кнопку 'Встановити пароль'*/
      )
      return
    }



      axios.post(state.api.addAthleteByCoach, {

        personName: personName,
        secondName: secondName,
        fatherName:fatherName,
        birthday:birthday,
        sex:sex,
        weiht:weiht,
        height:height,
        coach:state.userProfile.id,
        coach2:state.userProfile.id,
        region:state.userProfile.region,
        sportClub:props.sportClub,
        dan:dan,
        rank:rank,
        fst:fst,
        insuranceComp:insuranceComp,
        insuranceNum:insuranceNum,
        insuranceDate:insuranceDate,
        medicalCertificateNum:medicalCertificateNum,
        medicalCertificateDate:medicalCertificateDate,
        eMail:eMail,
        photo:photo,
        login: login,
        pass: pass,
        lastChangesDate:state.lastChangesDateFormat()
                 })
        .then(response => {
          let profile
          try {
            profile = JSON.parse(response.data.userProfile)
          } catch {
            profile = response.data.userProfile
          }
       state.athletes.push(profile)
      indexedDBConnection.saveData('athletes',  state.athletes)

 
          window.location.reload(true); // при перезавантаженні дані беруться з кешу        
        })
        .catch(error => {
          console.error(lang[langSwitch].serverRequestError/*Помилка запиту до сервера: */, error);
          setErrorMessage(lang[langSwitch].serverRequestError/*Помилка запиту до сервера: */, error)

        });
    

  }
  function deleteProfile() {


    axios
      .delete(state.api.deleteUserProfile, {
        params: {
          id: state.userProfile.id,
          fileName: state.userProfile.photo, // Додано передачу імені файлу
        },
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {

        state.exit()
        window.location.reload();// Перезавантаження сторінки
      })
      .catch((error) => {
        setErrorMessage(
          lang[langSwitch].serverRequestError/*Помилка запиту до сервера: */ + error);
      });

  }
  ////////////////////////////////ПРОГРАМНА ЧАСТИНА КНОПОК ПІДТВЕРДЖЕННЯ////////////////////////////////////////////////////////////
  const [messageText, setMessageText] = useState('');
  const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState(false);

  function editResultConfirm(query) {// ФУНКЦІЯ ЯКА ЗАПУСКАЄ ОПИТУВАННЯ
    //    встановлений текст над кнопками є трігером, 
    //якщо він повертається з компоненти - спрацьовує функція
    if (query === 'update profile') {
      setMessageText(
        lang[langSwitch].createNewAthleteProfile //Створити новий профіль спортсмена?
      )
      openConfirmButtonsModal()
    }
    if (query === 'delete profile') {
      setMessageText(
        lang[langSwitch].deleteProfileQuery //УВАГА ВИДАЛЕННЯ ПРОФІЛЮ Є НЕЗВОРОТНИМ ПРОЦЕСОМ! Ви погоджуєте дію?
      )
      openConfirmButtonsModal()
    }

  }
  const confirmFunction = (messageText) => {
    if (messageText ===
      lang[langSwitch].createNewAthleteProfile //Створити новий профіль спортсмена?
    ) {
      update() //ФУНКЦІЯ ЯКА ВИКОНУЄТЬСЯ В РАЗІ ПІДТВЕРДЖЕННЯ
    }
    if (messageText ===
      lang[langSwitch].deleteProfileQuery //УВАГА ВИДАЛЕННЯ ПРОФІЛЮ Є НЕЗВОРОТНИМ ПРОЦЕСОМ! Ви погоджуєте дію?
    ) {
      deleteProfile() //ФУНКЦІЯ ЯКА ВИКОНУЄТЬСЯ В РАЗІ ПІДТВЕРДЖЕННЯ
    }
  }
  const openConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(true); }
  const closeConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(false); }
  ///////////////////////////////////////////////////////////////////////////////////////////////////



















  return (<>
    <div className={sAdmin.modalGrids}>
      <div className={!props.isSmallScreen ? s.container : s.containerSmall}>
        <div className={s.header}>


          <div className={sAdmin.h3}><br />
            {lang[langSwitch].addAthlete}{/*Додати спортсмена*/} </div>

          <div className={s.addAthleteInstruction}>{lang[langSwitch].addAthleteInstruction}{/*Для реєстрації спортсмена достатньо заповнити червоні поля, для заявки спортсмена на змагання потрібно заповнити усі поля без виключення.*/}</div>
        
        </div>

        <div className={s.leftCol}>

          <form className={s.form}>
            <p>
              <label htmlFor="secondName">
                <span className={s.span}>{lang[langSwitch].lastName}{/*Прізвище*/}
                  <span className={!secondName && s.redStar}>*</span>
                  </span>
              </label><br />
              <input className={!secondName && s.redInput} onChange={handleSecondNameChange} type="text" id="secondName" name="secondName" />
            </p>
            <p>
              <label htmlFor="personName">
                <span className={s.span}>{lang[langSwitch].firstName}{/*Ім'я*/}
                  <span className={!personName && s.redStar}>*</span>
                  </span>
              </label><br />
              <input className={!personName && s.redInput} onChange={handlePersonNameChange} type="text" id="personName" name="personName" />
            </p>
            <p>
              <label htmlFor="fatherName">
                <span className={s.span}>{lang[langSwitch].patronymic}{/*По-батькові*/}
                  <span className={!fatherName && s.redStar}>*</span>
                  </span>
              </label><br />
              <input className={!fatherName && s.redInput} onChange={handleFatherNameChange} type="text" id="fatherName" name="fatherName" />
            </p>
            <p>
              <label htmlFor="birthday">
                <span className={s.span}>{lang[langSwitch].dateOfBirth}{/*Дата народження*/}
                  <span className={!birthday && s.redStar}>*</span>
                  </span>
              </label><br />
              <input className={!birthday && s.redInput} onChange={handleBirthdayChange} type="date" id="birthday" name="birthday" />
            </p>

            <div>
              <div className={s.smallRed}>
                {lang[langSwitch].warningSex}{/*Будьте уважні! Подальші зміни в полі "Стать" робитимуться тількі службою технічної підтримки по заявці вашого тренера!*/}
              </div><br />
              <label htmlFor="sex"><span className={s.span}>
                {lang[langSwitch].indicateAthleteGender}{/*Вкажіть стать спортсмена*/}
                <span className={!sex && s.redStar}>*</span>
              </span></label><br />
              <select className={!sex && s.redInput} onChange={handleSexChange} id="sex" name="sex" >
                <option value=""></option>
                {sexData.map((sex) => (
                  <option key={sex} value={sex}>
                    {sex}
                  </option>
                ))}
              </select>
            </div><br />
            <div>
              <p>
                <label htmlFor="weiht">
                  <span className={s.span}>
                    {lang[langSwitch].weight}{/*вага*/}
                    <span className={!weiht && s.redStar}>*</span> 
                    </span>
                </label><br />
                <input className={!weiht && s.redInput} type="text" id="weiht" name="weiht" onChange={handleWeihtChange} />
              </p>
            </div>
            <div>
              <p>
                <label htmlFor="height"><span className={s.span}>{lang[langSwitch].height}{/*Зріст*/}
                  <span className={!height && s.redStar}>*</span> 
                  </span>
                </label><br />
                <input
                   className={!height && s.redInput}
                  type="text"
                  id="height"
                  name="height"
                  onChange={handleHeightChange}
                />
              </p>
            </div>

            <p>
              <label htmlFor="dan"><span className={s.span}>
                {lang[langSwitch].qualification}{/*кваліфікація*/}
                <span className={!dan && s.redStar}>*</span>
              </span>
              </label><br />
              <select className={!dan && s.redInput} onChange={handledDanChange} id="dan" name="dan" >
                <option value=""></option>
                {state.dan.map((dan) => (
                  <option key={dan} value={dan}>
                    {dan}
                  </option>
                ))}
              </select>
            </p>

            <p>
              <label htmlFor="rank"><span className={s.span}>
                {lang[langSwitch].rank}{/*Розряд*/}
                <span className={!rank && s.redStar}>*</span>
              </span>
              </label><br />
              <select className={!rank && s.redInput} onChange={handleRankChange} id="rank" name="rank" >
                <option value=""></option>
                {state.rank.map((rank) => (
                  <option key={rank} value={rank}>
                    {rank}
                  </option>
                ))}
              </select>
            </p>

            <p>
              <label htmlFor="fst">
                <span className={s.span}>{lang[langSwitch].PCSS}{/*ФСТ*/}
                  <span className={!fst && s.redStar}>*</span>
                  </span>
              </label><br />
              <select className={!fst && s.redInput} onChange={handleFstChange} id="fst" name="fst" >
                <option value=""></option>
                {state.fst.map((fst) => (
                  <option key={fst} value={fst}>
                    {fst}
                  </option>
                ))}
              </select>
            </p>

          </form >

        </div>


        <div className={s.rigthCol}>
          <form className={s.form} >

            <p>
              <label htmlFor="insuranceComp">
                <span className={s.span}>{lang[langSwitch].insuranceCompany}{/*Страх.комп.*/}</span>
              </label><br />
              <input onChange={handleInsuranceCompChange} type="text" id="insuranceComp" name="insuranceComp" />
            </p>

            <p>
              <label htmlFor="insuranceNum">
                <span className={s.span}>{lang[langSwitch].numberOfInsurancePolicy}{/*Сер./ном. полісу*/} </span>
              </label><br />
              <input onChange={handleInsuranceNumChange} type="text" id="insuranceNum" name="insuranceNum" />
            </p>

            <p>
              <label htmlFor="insuranceDate">
                <span className={s.span}> {lang[langSwitch].insuranceDate}{/*Термін дії полісу*/}</span>
              </label><br />
              <input onChange={handleInsuranceDateChange} type="date" id="insuranceDate" name="insuranceDate" />
            </p>

            <p>
              <label htmlFor="medicalCertificateNum">
                <span className={s.span}>{lang[langSwitch].numberOfMedicalCertificate}{/*Сер./ном. мед. сертиф.*/}</span>
              </label><br />
              <input onChange={handleMedicalCertificateNumChange} type="text" id="medicalCertificateNum" name="medicalCertificateNum" />
            </p>

            <p>
              <label htmlFor="medicalCertificateDate">
                <span className={s.span}>
                  {lang[langSwitch].medicalCertificateDate}{/*Термін дії мед. сертиф.*/}</span>
              </label><br />
              <input onChange={handleMedicalCertificateDateChange} type="date" id="medicalCertificateDate" name="medicalCertificateDate" />
            </p>

            <div className={s.smallGreen}>
              {lang[langSwitch].eMailUsed}{/*e-Mail використовується для розсилкі турнірних сіток та відновлення паролю!*/}
            </div><br />

            <p>
              <label htmlFor="eMail">
                <span className={s.span}>e-Mail</span>
              </label><br />
              <input onChange={handleeEMailNumChange} 
                type="text" id="eMail" name="eMail" placeholder={state.userProfile.eMail} />
            </p>
            <p>
              <label htmlFor="log">
                <span className={s.span}>{lang[langSwitch].login}{/*Логін*/}
                  <span className={!login && s.redStar}>*</span>
                </span>
              </label>
              <br />
              <input className={!login && s.redInput} onChange={handleLoginDateChange} type="text" id="log" name="log" autoComplete='off' />
            </p>
          </form>
          <form className={s.form}>


            <div>
              <label htmlFor="pass"><div className={s.smallGreen}>
                {lang[langSwitch].passwordaAddWarning}{/*При встановленні паролю зважте, що система не приймає символи кирилиці!*/}
              </div></label><br />
              <PasswordForm
                setPass={setPass}
                updatePassword={state.userProfile.pass}
                langSwitch={langSwitch}
                pass={pass}
              /><br />
            </div>


            <div>
              <label htmlFor="photo"><span className={s.span}>
                {lang[langSwitch].photo}{/*Фото*/}
                <span className={!photo && s.redStar}>*</span>
              </span></label><br />
              <div>
                {compressedFileURL && (
                  <img src={compressedFileURL} alt="Стигнутий файл" />
                )}<br />
                <input className={!photo && s.redPhoto}
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                /><br />
              </div>
            </div>

          </form>
          <br/>
        </div>
       
        <div>

        </div>


      </div>
       {errorMessage && <><br /> <p className={s.errorMessage} onClick={() => { setErrorMessage('') }}>
        {errorMessage}</p></>}
        <div className={s.footer}>

          <div className={!props.isSmallScreen ? s.footerL : null}>
            <button type='button' onClick={() => { editResultConfirm('update profile') }} style={{ cursor: 'pointer' }}>
              {lang[langSwitch].save}{/*Зберегти*/}
            </button></div>

          <div className={!props.isSmallScreen ? s.footerCenter : null}>
            <button type='button' onClick={() => { props.setAddAthletteOpen(false) }} style={{ cursor: 'pointer' }}>
              {lang[langSwitch].comeBack}{/*Повернутись*/}
            </button></div>

          <div className={!props.isSmallScreen ? s.footerR : null}>
            {props.isSmallScreen ? (<><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /></>) : null}
            <button type='button' className={s.exitButton} onClick={() => { editResultConfirm('delete profile') }} style={{ cursor: 'pointer' }}>
              {lang[langSwitch].deleteProfile}{/*Видалити профіль*/}
            </button>
          </div>
          </div>
          <br/>&nbsp;
    </div>
    <ConfirmButtons
            isOpen={isConfirmButtonsModalOpen}
            onClose={closeConfirmButtonsModal}
            confirmFunction={confirmFunction}
            messageText={messageText}
            langSwitch={langSwitch}
          />
  </>)
}
export default AddAthlete