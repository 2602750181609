import React, { useState } from 'react';
import lang from '../../../state/language';
import state from '../../../state/state';
import indexedDBConnection from '../../../state/indexedDBConnection';
import axios from 'axios';
import s from './forAllUsers.module.css'
import LeadersItem from './leadersItem';
import EditLeader from '../controlPanel/leadership/editLeader';

function Leaders({ isSmallScreen, langSwitch, moderator }) {

  const [editStart, setEditStart] = useState(false);
  const [editLeader, setEditLeader] = useState(null);     
  const [leaders, setLeaders] = useState(state.leaders);  

  if (leaders.length === 0||!leaders||leaders==='null') {
    axios.get(state.api.getLeaders)
      .then((response) => {
        if (response.data.leaders && Array.isArray(response.data.leaders) && response.data.leaders.length > 0) {
          state.leadersJobTitle.forEach((item) => {
            response.data.leaders.forEach((leader) => {
              if (leader.jobTitle === item) {
              
                setLeaders((prev)=>{
                 return [...prev, {...leader, photos:leader.photos}] 
               })

              }
            })
          });
          state.leaders = leaders
          indexedDBConnection.saveData('leaders',[...leaders]);
        }
      })
      .catch((error) => {
        console.error('Помилка запиту до сервера:', error);
      });
  }
console.log('leaders: ',leaders)
  return (<div>
    {moderator?(<br/>):(
      
      <h1 className={s.h1} 
         style={{textAlign:'center', marginBottom:'40px'}}>
    {lang[langSwitch].leadership   /*Керівництво*/}
    </h1>
)}
 {
 editStart?(
          <EditLeader 
            isSmallScreen={isSmallScreen} 
            langSwitch={langSwitch} 
            setEditStart={setEditStart}
            editLeader={editLeader}/>
       ):   
(leaders&&Array.isArray(leaders)&&leaders.length>0)
&&  
leaders.map((leader, index) => { 
       
      return(<div key={index}>
        <LeadersItem 
          index={index}
          isSmallScreen={isSmallScreen}
          langSwitch={langSwitch}
          leader={leader}
          moderator={moderator}
          setEditStart={setEditStart}
          setEditLeader={setEditLeader}
          />
      </div>)}
    )
}

  </div>);
};

export default Leaders;