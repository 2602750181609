import React, { useEffect, useState } from 'react';
import s from './adminCompetition.module.css'
import lang from '../../../state/language';
import state from '../../../state/state';
import ShootingCell from './ShootingCell';
import ConfirmButtons from './confirmButtons';
import axios from 'axios';
import indexedDBConnection from '../../../state/indexedDBConnection';
import ShootingPlaces from './shootingPlaces';

function Shooting({ 
            setShootingOpen, 
            competitionTitle, 
            langSwitch, 
            competitionAthletes, 
            id, 
            competitionShootingResult,
            gridsData }) {

    const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState(false);
    const [messageText, setMessageText] = useState('');
    const [grids, setGrids] = useState(gridsData);
    const [pageSwitcher, setPageSwitcher] = useState(true);

    const [resultForSaving, setResultForSaving] = useState([]);
    const [errorMessage, setErrorMessage] = useState(''); //повідомлення про помилки та успіх
    const [successMessage, setSuccessMessage] = useState('');




    const openConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(true); }
    const closeConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(false); }

    const confirmFunction = (messageText) => {
        if (messageText ===
            lang[langSwitch].saveCompetitionResultsInDB/*Зберегти результати змаганнь в Базі даних?*/
        ) { saveReults() }
        if (messageText ===  lang[langSwitch].deleteResults/*Видалити результати*/+'?'){
            deleteResults()
        }       
    }

    function saveResultConfirm() {        
        openConfirmButtonsModal()
    }

let athletesForShootingData, athletesForShooting

if(competitionShootingResult&&Array.isArray(competitionShootingResult)&&competitionShootingResult.length>0){
  athletesForShootingData = state.tournConstructor.entryAthletesFind(competitionAthletes)
    athletesForShooting = athletesForShootingData.map((itemAthlete)=>{
            let  result 
             competitionShootingResult.map((itemResult)=>{
            if(itemResult.id==itemAthlete.id){
            result = {...itemAthlete, shootingResult:itemResult}
            }            
        })
        return result
    })
}else{
    athletesForShooting = state.tournConstructor.entryAthletesFind(competitionAthletes) 
}
    athletesForShooting.sort((a, b) => {
        // Спочатку сортуємо за командою
        const teamComparison = a.team.localeCompare(b.team);
        if (teamComparison !== 0) return teamComparison;

        // Якщо команди однакові - сортуємо за прізвищем
        const lastNameComparison = a.secondName.localeCompare(b.secondName);
        if (lastNameComparison !== 0) return lastNameComparison;

        // Якщо прізвища однакові - сортуємо за ім'ям
        return a.personName.localeCompare(b.personName);
    });

function deleteResults(){
    axios.post(state.api.saveShootingResult,
        {
            id: id,
            shootingResult: null,
            headers: { 'Content-Type': 'application/json' }
        })
        .then((response) => {
            setSuccessMessage(
                lang[langSwitch].successfullSavedInDB /*Внесені зміни успішно збережено в Базі Даних*/
            );
            const index = state.competition.findIndex((item) => item.id == id)
            state.competition[index].shootingResult = null
            indexedDBConnection.saveData('competition', state.competition)


            setTimeout(function () {
                window.location.reload()
            }, 3000);
        })
        .catch((error) => {
            setErrorMessage(
                lang[langSwitch].serverRequestError /*Помилка запиту до сервера: */
                + error);
        })
}



function saveReults(){  
            axios.post(state.api.saveShootingResult,
                {
                    id: id,
                    shootingResult: JSON.stringify(resultForSaving),
                    grids:JSON.stringify(grids),
                    headers: { 'Content-Type': 'application/json' }
                })
                .then((response) => {
                    setSuccessMessage(
                        lang[langSwitch].successfullSavedInDB /*Внесені зміни успішно збережено в Базі Даних*/
                    );
                    const index = state.competition.findIndex((item) => item.id == id)
                    state.competition[index].shootingResult = resultForSaving
                     state.competition[index].grids=grids
                    indexedDBConnection.saveData('competition', state.competition)


                    setTimeout(function () {
                        window.location.reload()
                    }, 2000);
                })
                .catch((error) => {
                    const dataForSave = {
                        id: id,
                        resultForSaving: resultForSaving,
                        grids:grids,
                    }
                    localStorage.setItem('shootingReservResult',JSON.stringify(dataForSave))
                    setErrorMessage(
                        lang[langSwitch].serverRequestError /*Помилка запиту до сервера: */
                       );
                        setSuccessMessage(
                                  lang[langSwitch].RequestWillBeExecutedInTimeConnectNetwork/*Ваш запит буде виконано у фоновому режимі під час наступного підключення до мережі.*/
                                 )
                })
} 
        
 

        //прибираємо порожні елементи масиву
    athletesForShooting=athletesForShooting.filter((item)=>item)


  
    return (<>
        <div className={s.modalWriper}>
            <div className={s.modal}>

                {pageSwitcher?(<>
                <div>
                    <div>
                        <h1 className={s.h1}> {competitionTitle} </h1>
                    </div>
                    <div >
                        <h2 className={s.h2}>{lang[langSwitch].shooting /*Стрільба*/}</h2>
                    </div>
                    <div className={s.handleGridsWarning}>
                        {lang[langSwitch].shootingResultInstruction /*Введіть результати кожного пострілу в поле "Очки" відокремлюючи комою результати кожного пострілу. Наприклад: "0, 8, 3".*/}
                    </div>
                </div>

                <div>
                    <table className={s.shootTable}>
                        <thead>
                            <tr>
                                <td>№</td>
                                <td>Призвище, Ім'я</td>
                                <td>Очки</td>
                                <td>Результат</td>
                                <td>Розряд /<br />Кваліф.</td>
                                <td>Команда</td>
                                <td>Обл. осередок</td>
                                <td>ФСТ</td>
                                <td>Тренер /<br />Тренер2</td>

                            </tr>
                        </thead>
                        <tbody>
                            {athletesForShooting && Array.isArray(athletesForShooting) && athletesForShooting.length > 0 ?
                                athletesForShooting.map((athlete, index) => {
                                 
                               return (
                                        <tr key={athlete.id+index}>
                                            <ShootingCell
                                                athlete={athlete}
                                                numder={index + 1}                                                
                                                setResultForSaving={setResultForSaving} />
                                        </tr>
                                    )
                                }) : null}

                        </tbody>
                    </table>

                </div>

                </>):(<>




                <div className={s.h3}>&nbsp;<br/><br/>
  {lang[langSwitch].allocationOfPlacesOnShootingResults /*Розподілення місць в категоріях за результатами стрільби*/}
   </div>





   {resultForSaving.length>0 && (<>
<div>
    <table className={s.shootTable}>
        <thead>
            <tr>
                <td>№</td>
                <td>Призвище, Ім'я</td>
                <td>Результат</td>
                <td>Місце</td>
                <td>Розряд /<br />Кваліф.</td>
                <td>Команда</td>
                <td>Обл. осередок</td>
                <td>ФСТ</td>
                <td>Тренер /<br />Тренер2</td>
            </tr>
        </thead>
        <tbody>


        {grids.map((grid)=>{
            return(<React.Fragment key={grid.category}>
            <tr>
    <td colSpan='9' style={{border:'2px  #000000 solid'}}>
       <b> {state.weightCatUkr(grid.category, langSwitch)}</b>
    </td>
    </tr> 
                <ShootingPlaces 
                    keyData={grid.category}
                    resultForSaving={resultForSaving}
                    grid={grid} 
                    langSwitch={langSwitch}
                    setGrids={setGrids}      
                />
              
            </React.Fragment>)
               })} 
              

        </tbody>
    </table>
</div>
 </> )}
           
 </>)}

                {successMessage ?
                    (<><br /><br /><div className={s.successMessage} onClick={() => { setSuccessMessage('') }}>
                        {successMessage}</div><br /></>) :
                    errorMessage ? (<><br /><br /><div className={s.errorMessage} onClick={() => { setErrorMessage('') }}>
                        {errorMessage}</div><br /></>) : ''}


                <div className={s.shootingBox}>
                    <div >
                    {pageSwitcher?(<>
                        <button
                            style={{ width: '170px' }}
                            type='button'
                            onClick={() => { 
                                setPageSwitcher(false)
                                }}>
                            {lang[langSwitch].assignPlaces/* Призначити місця*/}
                        </button>
                    </>):(<>
                        <button
                            style={{ width: '170px' }}
                            type='button'
                            onClick={() => { 
                                setMessageText(
                                    lang[langSwitch].saveCompetitionResultsInDB/* Зберегти результати змаганнь в Базі даних?*/
                                    )
                                saveResultConfirm(); 
                                }}>
                            {lang[langSwitch].saveResults/*Зберегти результати*/}
                        </button>

                        </>)}
                        
                    </div>

                     <div style={{ textAlign: 'center' }}>
                     {!pageSwitcher&&(<>
                        <button className={s.redButton2}
                            style={{ width: '170px' }}
                            type='button'
                            onClick={() => {
                                setMessageText(
                                    lang[langSwitch].deleteResults/*Видалити результати*/+'?'
                                     ) 
                                saveResultConfirm(); 
                                }}>
                            {lang[langSwitch].deleteResults/*Видалити результати*/}
                        </button>
                    </>)}
                    </div>
                    
                    <div style={{ textAlign: 'right' }}>
                    {!pageSwitcher&&(<>
                        <button type='button' onClick={() => {  setPageSwitcher(true) }}>
                            {lang[langSwitch].comeBack /*Повернутись*/}
                        </button>
                        </>)}
                        <button type='button' onClick={() => { setShootingOpen(false) }}>
                            {lang[langSwitch].close /*Закрити*/}
                        </button>

                    </div>
                </div>




            </div>
        </div>
        {/*Виклик Вікна погодження */}
        <ConfirmButtons
            isOpen={isConfirmButtonsModalOpen}
            onClose={closeConfirmButtonsModal}
            confirmFunction={confirmFunction}
            messageText={messageText}
            langSwitch={langSwitch}
        />
    </>);
};

export default Shooting;