import { NavLink, useParams } from 'react-router-dom';
import s from '../../adminCompetition.module.css'
import state from '../../../../../state/state';
import React, { useState } from 'react';
import lang from '../../../../../state/language';
import ConfirmButtons from '../../confirmButtons';
import EntryClubAndDateHandle from '../entryClubAndDateHandle';
import EntyInputFoterData from '../entyInputFoterData';
import EntryPrintFooter from '../entryPrintFooter';
import axios from 'axios';

function PoomsaeEntryPrint({isSmallScreen,langSwitch}){
    const { id } = useParams();//отримуємо id турниру
    const competition = state.competition.filter((tourn) => tourn.id == id)[0];// турнир в массиві
   
    const [date,setDate]=useState(null)
    const [dateEntry, setDateEntry] = useState(null);   
    const [isFtuRegionLeader, setFtuRegionLeader] = useState(null);
    const [isOlfdLeader, setOlfdLeader] = useState(null);
    const [isStateSportDepartmentLeader, setStateSportDepartmentLeader] = useState(null);
    const [clubData, setClubData] = useState('');
    const [team, setTeam] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
const [errorMessage, setErrorMessage] = useState(null);

     const handledateForBlockProfileChange = (e) => { const value = e.target.value; setDate(value.trim()) };
    function FtuRegionLeaderHandle(e){setFtuRegionLeader(e.target.value)}
    function OlfdLeaderHandle(e){setOlfdLeader(e.target.value)}
    function StateSportDepartmentLeaderHandle(e){setStateSportDepartmentLeader(e.target.value)}
    const handleTitleChange = (e) => { setClubData(e.target.value) };
    const handleTeamChange = (e) => { const value = e.target.value; setTeam(value) };
   
    const titleOptionsData = state.sportClubs.filter(item=>item.creator==state.userProfile.id)
    const titleOptions=titleOptionsData.map((item)=>{return(item.name)})
    
    function dateSelect(e){setDateEntry(state.formatDate(e.target.value))}

    let myAthletes=[]; let poomsaeCategories; let count=0;
try{poomsaeCategories=JSON.parse(competition.poomsaeCategories)}
catch{poomsaeCategories=competition.poomsaeCategories}

//////////////////////////////////////////////
//////////////////////////////////////////////////
/////////////////////////////////////////////////
////////////////////////////////////////////////////
//////////////////////////////////////////////////

//зробити myAthletes прямим та з унікальними спортсменами

function postDateForBlockProfile(){
  if(!date){
    setErrorMessage( 
      lang[langSwitch].notSetDateToBlockAthleteProfiles/*Ви не встановили дату для блокування профілів спортсменів.*/
    )
    return}
 let competitionAthletes
try{competitionAthletes=JSON.parse(competition.athletes)}
catch{competitionAthletes=competition.athletes}

const athletesForBlock = competitionAthletes.filter((item)=>item.coach==state.userProfile.id||item.coach2==state.userProfile.id)
   
    if(athletesForBlock.length===0||!athletesForBlock){
      setErrorMessage(
lang[langSwitch].yourAthletesApplicationsNotBeReceived/*Невданось отримати заявки ваших спортсменів. Якщо ви заявляли ваших спортсменів, будь ласка зверніться в службу тех.підтримки.*/
      )
      return}
    const updateData=athletesForBlock.map((item)=>{
      return({
        id:item.id,
        dateForBlockProfile:date
      })
    })

    
    axios.post(state.api.postDateForBlockProfile, { updateData: updateData })
    .then(response => {  
        
          setSuccessMessage(lang[langSwitch].dateForBlockingAthletesProfilesSuccessfullySet/*Дату для блокування профілів спортсменів успішно встановлено.*/)
  })
    .catch(error => {setErrorMessage(
      lang[langSwitch].failedToSetDateToBlockAthleteProfile/*Невдалось встановити дату для блокування профілю спортсменів. Спробуйте пізніше.*/
    )  
      // console.error('Помилка Axios-запиту, невдалось зберегти результати турниру в профілі атлетів:', error);
    });
        
  }


    if(state.userProfile.regionLeader!==null&&team==="region"){//формуємо команду від Обласного осередка
        poomsaeCategories.forEach((item)=>{
         const result={category:null,athletes:[]}
         result.category=item.category
            item.athletes.forEach((itemAthletes)=>{
                if(Array.isArray(itemAthletes)){                    
                     if(itemAthletes[0].region===state.userProfile.regionLeader){
                            result.athletes.push(itemAthletes)                                                
                        }
                }else{
                    if(itemAthletes.region===state.userProfile.regionLeader){
                         result.athletes.push(itemAthletes) 
                    }   
                }

            })
           
           if(result.athletes.length>0) {myAthletes.push(result)}
        })
      }
      else{//формуємо команду від клубу
        if(clubData){
            poomsaeCategories.forEach((item)=>{
                const result={category:null,athletes:[]}
                result.category=item.category
                   item.athletes.forEach((itemAthletes)=>{
                       if(Array.isArray(itemAthletes)){                      
                            if(itemAthletes[0].sportClub===clubData){
                                   result.athletes.push(itemAthletes)                                                
                               }
                       }else{
                           if(itemAthletes.sportClub===clubData){
                                result.athletes.push(itemAthletes) 
                           }   
                       }
       
                   })
                  
                  if(result.athletes.length>0) {myAthletes.push(result)}
               })
         
      }}




////////////////////////////////ПРОГРАМНА ЧАСТИНА КНОПОК ПІДТВЕРДЖЕННЯ////////////////////////////////////////////////////////////
const [messageText, setMessageText] = useState('');
const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState(false);

 function editResultConfirm() {// ФУНКЦІЯ ЯКА ЗАПУСКАЄ ОПИТУВАННЯ
//    встановлений текст над кнопками є трігером, 
//якщо він повертається з компоненти - спрацьовує функція
     setMessageText(
      lang[langSwitch].blockAthletesProfiles /*Встановити блокування профілів спортсменів до вказаної дати?*/
    )  
     openConfirmButtonsModal()
 }
 const confirmFunction = (messageText) => {
     if (messageText === 
      lang[langSwitch].blockAthletesProfiles /*Встановити блокування профілів спортсменів до вказаної дати?*/
     ) {
      postDateForBlockProfile() //ФУНКЦІЯ ЯКА ВИКОНУЄТЬСЯ В РАЗІ ПІДТВЕРДЖЕННЯ
     }
 }
 const openConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(true); }
 const closeConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(false); }


///////////////////////////////////////////////////////////////////////////////////////////////////
    return(<>
    {
 state.userProfile.regionLeader!==null? /*тернарний вираз  */
  (<div className={s.teamContaner}>
<div className={s.teamChange}><p><b>
{lang[langSwitch].enterDataForApplication /*Вкажіть дані для формування заявки.*/}
  </b></p>
{/* встановлюємо дані для футера заявки:Голова ФТУ, Фіздиспансера та управління */}  
<EntyInputFoterData 
 isSmallScreen={isSmallScreen}
  isFtuRegionLeader={isFtuRegionLeader}
  isOlfdLeader={isOlfdLeader}
  isStateSportDepartmentLeader={isStateSportDepartmentLeader}
  FtuRegionLeaderHandle={FtuRegionLeaderHandle}
  OlfdLeaderHandle={OlfdLeaderHandle}
  StateSportDepartmentLeaderHandle={StateSportDepartmentLeaderHandle}
  langSwitch={langSwitch}
  />
  </div>

{/* Обираем команду від якої заявляєм спортсменів */}
<div className={s.entryPrintChangeMenu}>
{!isSmallScreen?(<><br/><br/><br/></>):null}
      <label htmlFor="status"><span  className={s.teamChangeMenuSpan}>
      {lang[langSwitch].selectTeam /*Оберіть команду*/}:
        </span> </label><br />
      <select onChange={handleTeamChange} id="status" name="status">
       <option value=""> </option> 
        <option value="region">
        {lang[langSwitch].regionalOffice /*Обласний осередок*/}  
          </option>
        <option value="club">
          {lang[langSwitch].sportsClub /*Спортклуб*/}   
          </option>
      </select><br/><br/>
    
       {/* Обираємо спортклуб та дату */}   
       <EntryClubAndDateHandle
    clubData={clubData}
    handleTitleChange={handleTitleChange}
    titleOptions={titleOptions}
    dateSelect={dateSelect}
    langSwitch={langSwitch}
    />  
   </div>
   </div> 

   ):(
  <div className={s.teamContaner}>    
  <div className={s.teamChange}><p><b>
  {lang[langSwitch].enterDataForApplication /*Вкажіть дані для формування заявки.*/} 
    </b></p>
   {/* встановлюємо дані для футера заявки:Голова ФТУ, Фіздиспансера та управління */}  
  <EntyInputFoterData 
  isSmallScreen={isSmallScreen}
  isFtuRegionLeader={isFtuRegionLeader}
  isOlfdLeader={isOlfdLeader}
  isStateSportDepartmentLeader={isStateSportDepartmentLeader}
  FtuRegionLeaderHandle={FtuRegionLeaderHandle}
  OlfdLeaderHandle={OlfdLeaderHandle}
  StateSportDepartmentLeaderHandle={StateSportDepartmentLeaderHandle}
  langSwitch={langSwitch}
  />
  </div>    
  <div className={s.entryPrintChangeMenu}>
    {!isSmallScreen?(<><br/><br/><br/></>):null}
     {/* Обираємо спортклуб та дату */}  
    <EntryClubAndDateHandle
    clubData={clubData}
    handleTitleChange={handleTitleChange}
    titleOptions={titleOptions}
    dateSelect={dateSelect}
    langSwitch={langSwitch}
    /> 
    </div> 
  </div>
 )}



{/* Початок зони друку */}
    <div  className={s.printContent}  >
  <table className={s.printTable} >
<thead>
  <tr>
    <td colSpan='9'>
    <div className={s.printHeader}>
        Поіменна заявка учасників на 
        
        <div className={s.printTitle}>{competition.title} </div>
        <div>{competition.location}</div>
        <div> {state.formatDate(competition.dateStart).slice(0, 2)}- 
        {state.formatDate(competition.dateFinish)} р.</div>
        <div>Від команди: <b> 
          {team==='region'?state.regionUkr(state.userProfile.region):
         team==='club'&&state.userProfile.regionLeader!==null||team===null&&state.userProfile.regionLeader===null?clubData:null}
</b></div> 
    </div>
</td></tr>
    <tr className={s.printTableHeader}>
        <td className={s.headerTd1}>№ <br/>з/п</td>
        <td className={s.headerTd2}>П.І.Б.</td>
        <td className={s.headerTd3}>Дата <br/>народження</td>
        <td className={s.headerTd5}>Спортивна<br/>кваліфікація</td>
        <td className={s.headerTd6}>ФСТ/ДЮСШ, УОР, ШВСМ,<br/>УФК,СДЮСШОР,СК</td>
        <td className={s.headerTd7}>П.І.Б. тренера<br/>(перший/<br/>особистий)</td>
        <td className={s.headerTd8}>Страх. поліс <br/>(серія, номер, термін дії)<br/>страх. комп.</td>
        {/* <td className={s.headerTd9}>Форма<br/> № 061/о<br/> реєст.номер та термін дії</td>         */}
        <td className={s.headerTd10}>Дата, печатка та підпис лікаря</td>
    </tr>
    </thead>
<tbody>

{myAthletes.map((item, index) => {
    return (
        <React.Fragment key={item.category + index}>
            <tr className={s.printTableHeader}>
                <td colSpan='9'>{lang.poomsaeCatUkr(langSwitch, item.category)}</td>
            </tr>
            {item.athletes.map((itemAthletes, index2) => {
                if (Array.isArray(itemAthletes)) {
                    return itemAthletes.map((categoryItem, index3) => { // Повертаємо значення тут
                        count++;
                        const clubOfAthlete=state.sportClubs.filter(club=>club.name===categoryItem.sportClub)
                        return (
                            <tr key={item.category + index + 'g' + index2 + index3}>
                                <td className={s.td1}>{count}</td>
                                <td className={s.td2} style={{paddingLeft:'10px'}}>{state.athleteName(categoryItem.id)}</td>
                                <td className={s.td3}>{state.formatDate(categoryItem.birthday)}</td>
                                <td className={s.td5}>{categoryItem.rank}/<br/>{categoryItem.dan}</td>
                                <td className={s.td6}>{categoryItem.fst +' / м.'+ clubOfAthlete[0].city} /<br/>{categoryItem.sportClub}</td>
                                <td className={s.td7} style={{textAlign:'center'}}>{state.myCoach(categoryItem.coach)} /<br/>{state.myCoach(categoryItem.coach2)}</td>
                                <td className={s.td8}>{categoryItem.insuranceNum}  &nbsp;/ &nbsp;<b>{state.formatDate(categoryItem.insuranceDate)}р.</b><br/>{categoryItem.insuranceComp} </td>
                                <td className={s.td10}>&nbsp;<b>{dateEntry}p. </b> &nbsp;
         М.П.
            <br/>Підпис_________ </td>
         
                            </tr>
                        );
                    });
                } else {                  
                       
                            count++;
                            const clubOfAthlete=state.sportClubs.filter(club=>club.name===itemAthletes.sportClub)
                            return (
                                <tr key={item.category + index + 'g' + index2 + index2}>
                                    <td className={s.td1}>{count}</td>
                                    <td className={s.td2} style={{paddingLeft:'10px'}}>{state.athleteName(itemAthletes.id)}</td>
                                    <td className={s.td3}>{state.formatDate(itemAthletes.birthday)}</td>
                                    <td className={s.td5}>{itemAthletes.rank}/<br/>{itemAthletes.dan}</td>
                                    <td className={s.td6}>{itemAthletes.fst +' / м.'+ clubOfAthlete[0].city} /<br/>{itemAthletes.sportClub}</td>
                                    <td className={s.td7} style={{textAlign:'center'}}>{state.myCoach(itemAthletes.coach)} /<br/>{state.myCoach(itemAthletes.coach2)}</td>
                                    <td className={s.td8}>{itemAthletes.insuranceNum}  &nbsp;/ &nbsp;<b>{state.formatDate(itemAthletes.insuranceDate)}р.</b><br/>{itemAthletes.insuranceComp} </td>
                                    <td className={s.td10}>&nbsp;<b>{dateEntry}p. </b> &nbsp;
             М.П.
                <br/>Підпис_________ </td>
             
                                </tr>
                            );
                       
                    
                }
            })}
        </React.Fragment>
    );
})}


</tbody>



    <EntryPrintFooter
isFtuRegionLeader={isFtuRegionLeader}
isOlfdLeader={isOlfdLeader}
isStateSportDepartmentLeader={isStateSportDepartmentLeader}
dateEntry={dateEntry}
mainJudge={competition.mainJudge}
secretary={competition.secretary}
/>

     </table> <br/><br/><br/><br/>
    </div>

{/* Кінець зони друку */}




    <div className={s.noPrint}>
    {errorMessage ? (<><br/><div className={s.errorMessage} onClick={() => { setErrorMessage(null) }}
          style={{ cursor: 'pointer' }}>
          {errorMessage}
        </div>
      </> ) : null} 
        {successMessage ? (<><br/><div className={s.successMessage} onClick={() => { setSuccessMessage(null) }}
          style={{ cursor: 'pointer',}}>
          {successMessage}
        </div>
       </>  ) : null}

<div className={!isSmallScreen?s.boxForDate:s.td1}>
    <div className={s.title1}>
    <button  type='button' onClick={() => {window.print();}}>
    {lang[langSwitch].print /*Друкувати*/}
      </button>
    </div>    
    <div className={s.title2}>
    <div className={s.boxForDateInput}>
          <label htmlFor="birthday">
            <span className={s.span}>
            {lang[langSwitch].setDateToBlockAthletesProfile /*Встановіть дату для блокування профілю спортсменів*/}:
              </span> 
         <br /> <b>{date?state.formatDate(date)+' '+
          lang[langSwitch].yearShort /*p.*/
         :null} </b>
         </label><br />
          <input onChange={handledateForBlockProfileChange} type="date" id="birthday" name="birthday" /><br />
          <button  type='button' onClick={() => {editResultConfirm()}}>
          {lang[langSwitch].setDate /*Встановити дату*/}
            </button>
</div>
</div>   
    <div className={s.title3ForEnrtyPrint} >      
    <NavLink to={`/competition/${id}`}> 
    <button type='button'>
    {lang[langSwitch].close /*Закрити*/} 
      </button>
    </NavLink>  
</div>
</div>
<ConfirmButtons
            isOpen={isConfirmButtonsModalOpen}
            onClose={closeConfirmButtonsModal}
            confirmFunction={confirmFunction}
            messageText={messageText}
            langSwitch={langSwitch}
        />
</div>
    </>)
}

export default PoomsaeEntryPrint