
import { useEffect, useState } from 'react';
import ConfirmButtons from './../adminCompetition/confirmButtons.js';
import st from './../adminCompetition/adminCompetition.module.css'
import s from  '../adminCompetition/food/food.module.css'
import FoodEntryCell from './foodEntryCell.js';
import state from '../../../state/state.js';
import axios from 'axios';
import lang from '../../../state/language.js';
import indexedDBConnection from '../../../state/indexedDBConnection.js';


function FoodEntry({isSmallScreen,competition,isOpen, onClose,langSwitch}) {
 

const [foodData,setFoodData]=useState({ id:state.userProfile.id,phone:null,data:[]})
const [inputValue,setInputValue]=useState('') //номер телефону
const [sum,setSum]=useState(null)
const [start,setStart]=useState(false)
const [errorMessage, setErrorMessage] = useState(''); //повідомлення про помилки та успіх
const [successMessage, setSuccessMessage] = useState('');


 ////////////////////////////////ПРОГРАМНА ЧАСТИНА КНОПОК ПІДТВЕРДЖЕННЯ////////////////////////////////////////////////////////////
 const [messageText, setMessageText] = useState('');
 const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState(false);
 
  function editResultConfirm(text) {// ФУНКЦІЯ ЯКА ЗАПУСКАЄ ОПИТУВАННЯ
//    встановлений текст над кнопками є трігером, 
//якщо він повертається з компоненти - спрацьовує функція
 
      setMessageText(text)  
      openConfirmButtonsModal()

  }
  const confirmFunction = (messageText) => {
      if (messageText === 
        lang[langSwitch].confirmSendingFoodEntry /* Ви підтверджуєте відправку заявки на харчування команди?*/
      ) {
        saveResult()
      }

  }
  const openConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(true); }
  const closeConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(false); }
///////////////////////////////////////////////////////////////////////////////////////////////////


useEffect(()=>{
  if(start){
  let result=0; let count=0;
 
   foodData.data.forEach((item)=>{
if (item.breakfast&&item.breakfast>0){
  count=item.breakfast*foodOptions.prise.breakfast
result=result+count
}
if (item.dinner&&item.dinner>0){
  count=item.dinner*foodOptions.prise.dinner
result=result+count
}
if (item.supper&&item.supper>0){
  count=item.supper*foodOptions.prise.supper
result=result+count
}
   })
    setSum(result)
  }
},[ foodData.data]) 


if (competition.foodOptions==null||competition.foodOptions==='null'){return}
let foodOptions 

 try{foodOptions=JSON.parse(competition.foodOptions)}
catch(error){foodOptions=competition.foodOptions}

foodOptions.times.sort((a, b) => new Date(a.date) - new Date(b.date));


 let competitionFoodData=[]

 try{ 
  const a = JSON.parse(competition.foodData)
  if(Array.isArray(a)){
    competitionFoodData=a
  } }
catch(error){
  if (Array.isArray(competition.foodData)){
    competitionFoodData=competition.foodData
  }
}

if(inputValue.length===14&&foodData.phone===null){setFoodData((prev)=>{return{...prev,phone:inputValue}})}

const handleInput=e=>{//валідація номеру телефону
  const formattedPhoneNumber=formatPhoneNumber(e.target.value)
  setInputValue(formattedPhoneNumber)

} 
function formatPhoneNumber(value){
if(!value) return value
const phoneNumber =value.replace(/[^\d]/g,'')
if(phoneNumber.length<4){return phoneNumber}
if(phoneNumber.length<7){
  return `(${phoneNumber.slice(0,3)}) ${phoneNumber.slice(3)}`
}
return `(${phoneNumber.slice(0,3)}) ${phoneNumber.slice(3,6)}-${phoneNumber.slice(6,10)}`
}
  function priceCount(){
    if(inputValue.length!==14){
      setErrorMessage(
        lang[langSwitch].needToYourPhoneNumber /*'Для оформлення замовлення потрібно вказати ваш номер телефону!*/
        )
    }else{
      setStart(true)
    } }

 function saveResult(){

let dataForDB=[]
if(competitionFoodData.length===0){
  dataForDB.push(foodData)
}else{

const filterData=competitionFoodData.filter((item)=>item.id!==state.userProfile.id)
  dataForDB=[...filterData,foodData]

}


 axios.post(state.api.foodEnrty,
    {
      id:competition.id,
      foodData:JSON.stringify(dataForDB),
      headers: {'Content-Type': 'application/json'}
    })
  .then((response)=>{
 setSuccessMessage(
  lang[langSwitch].successfullSavedInDB /*Внесені зміни успішно збережено в Базі Даних*/
);
 const index=state.competition.findIndex((item)=>item.id==competition.id)
 state.competition[index].foodData=dataForDB
 indexedDBConnection.saveData ('competition', state.competition)


 setTimeout(function() {
  window.location.href = '/competition/'+competition.id
}, 3000);
  })
  .catch((error) => {
    setErrorMessage(
      lang[langSwitch].serverRequestError /*Помилка запиту до сервера: */
       + error);    
  })
 }
return(
  <div className={st.modalWriper}>
      <div className={st.modal} >
  <>
    <h2 style={{textAlign:'center'}}>
    {lang[langSwitch].applicationForFood /*Заявка на харчування*/}
      </h2>

    {successMessage ?
        (<><br /><br /><div className={st.successMessage} onClick={() => { setSuccessMessage('') }}>
          {successMessage}</div><br /></>) :
        errorMessage ? (<><br /><br /><div className={st.errorMessage} onClick={() => { setErrorMessage('') }}>
          {errorMessage}</div><br /></>) : ''}

    <div>
<p style={{textIndent:'30px'}}>
{lang[langSwitch].enterNumberOfServings /*Вкажіть кількість порцій на кожен прийом їжі.*/}
  </p>
<p style={{textIndent:'30px'}}> 
{lang[langSwitch].priceOfPortions /*Ціна порцій становить:*/} 
{lang[langSwitch].breakfast /*Сніданок*/} - <b>{foodOptions.prise.breakfast}
{lang[langSwitch].UAH /*грн.*/}
  </b>, 
{lang[langSwitch].lunch /*Обід*/} - <b>{foodOptions.prise.dinner}
{lang[langSwitch].UAH /*грн.*/}
  </b>, 
{lang[langSwitch].dinner /*Вечеря*/} - <b>{foodOptions.prise.supper}
{lang[langSwitch].UAH /*грн.*/}
  </b>. 
{sum?(<> {lang[langSwitch].totalCostOfOrder /*Загальна вартість замовлення*/} - <b>{sum}грн.</b> </>):null}
</p>
<p style={{textIndent:'30px'}}>
{lang[langSwitch].enterYourPhoneNumber /*Вкажіть номер вашого телефону*/}
   <i>({lang[langSwitch].necessarily /*обов'язково*/})</i></p> 


    <input style={{textAlign:'center',marginLeft:'30px'}}
      value={inputValue}
      onChange={e=>handleInput(e)} 
      placeholder='(000) 000-0000'
        />

</div>
<table className={s.printTable}>
  <thead className={s.foodEntryThead} >
<tr>
  <td rowSpan={2} >{lang[langSwitch].date /*Дата*/}</td>
  <td colSpan='2'>{lang[langSwitch].breakfast /*Сніданок*/}</td>
  <td colSpan='2'>{lang[langSwitch].lunch /*Обід*/}</td>
  <td colSpan='2'>{lang[langSwitch].dinner /*Вечеря*/}</td>
</tr>
<tr>  
  <td>{isSmallScreen?
  lang[langSwitch].numberShort /*Кіл-ть*/
  :
  lang[langSwitch].number /*Кількість*/
  }</td>
  <td>{lang[langSwitch].cost /*Вартість*/}</td>
  <td>{isSmallScreen?
  lang[langSwitch].numberShort /*Кіл-ть*/
  :
  lang[langSwitch].number /*Кількість*/
  }</td>
  <td>{lang[langSwitch].cost /*Вартість*/}</td>
  <td>{isSmallScreen?
  lang[langSwitch].numberShort /*Кіл-ть*/
  :
  lang[langSwitch].number /*Кількість*/
  }</td>
  <td>{lang[langSwitch].cost /*Вартість*/}</td>
</tr>
  </thead>


  <tbody className={s.foodEntryTBody}>
    {foodOptions.times.map((item, index)=>{
 return(
 <FoodEntryCell 
 key={item+index}
 item={item} 
 prise={foodOptions.prise} 
 setFoodData={setFoodData}
 start={start}
 langSwitch={langSwitch}
 />
 )
    })}
 
  </tbody>
</table>


    <div className={isSmallScreen?st.td1:null}> 
      <button type='button' 
      onClick={() => { onClose(); }}>
        {lang[langSwitch].close /*Закрити*/}
        </button>
       
      {start?(<>
        <button className={!isSmallScreen?st.buttonR:null} 
      type='button' 
      onClick={() => {editResultConfirm(
        lang[langSwitch].confirmSendingFoodEntry /* Ви підтверджуєте відправку заявки на харчування команди?*/
      )  }}>
       {lang[langSwitch].send /*Відправити*/}  
        </button>
    </>):(<> 

      <button className={!isSmallScreen?st.buttonR:null} 
      type='button' 
      onClick={() => { priceCount() }}>        
       {lang[langSwitch].calculate /*Розрахувати*/}  
       </button></>)} 

        </div>

      {/* модальне вікно кнопок підтвердження */}
      {isConfirmButtonsModalOpen?(<>
      <ConfirmButtons  
            isOpen  = {isConfirmButtonsModalOpen}      
            onClose={closeConfirmButtonsModal}
            confirmFunction={confirmFunction}
            messageText={messageText}
            langSwitch={langSwitch}
        />
        </>):null}
        </>
       </div> </div>
   )

}

export default FoodEntry


    





