import React, {  useState } from "react";

import s from '../adminCompetition/adminCompetition.module.css';
import ConfirmButtons from "../adminCompetition/confirmButtons";
import lang from './../../../state/language';




function CoachChoice({ isOpen, onClose, notCoach, putCoach,isSmallScreen,langSwitch}) {

  // Створіть стан для зберігання списку вибраних спортсменів
  const [selectedAthletes, setSelectedAthletes] = useState([]);// обрані атлети
  const [errorMessage, setErrorMessage] = useState(''); //повідомлення про помилки та успіх
  const [successMessage, setSuccessMessage] = useState('');
  const [data, setData] = useState('');
  const [messageText, setMessageText] = useState('');
  const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState(false);

  function saveResultConfirm() {
    if(selectedAthletes.length>0){
    setMessageText(
      lang[langSwitch].confirmYourChoice //Підтвердіть свій вибір
    )
    openConfirmButtonsModal()
  }else{setErrorMessage(
     lang[langSwitch].notChosenCandidate //Ви не обрали жодного кандидата!
  )}
  }
  const confirmFunction = (messageText) => {
    if (messageText === 
      lang[langSwitch].confirmYourChoice //Підтвердіть свій вибір
    ) {
      putCoach(selectedAthletes)
      setSelectedAthletes(null)
      onClose()
    }

  }
  const openConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(true); }
  const closeConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(false); }



  const handleTitleChange = (e) => { setData(e.target.value) };


  //   форма пошуку
  const athletes = notCoach.filter(athlete => {
    const fullName = `${athlete.secondName} ${athlete.personName}`.toLowerCase();
    const searchData = data.toLowerCase();

    return fullName.includes(searchData);
  });


  return (<>
   {isOpen?(<>
    <div className={s.modalWriper}>
    <div appElement={document.getElementById('root')} 
          className={s.modal} 
          contentLabel="Regulation Modal">

      <div className={s.h1}><b>
      {lang[langSwitch].chooseCoach}{/*Оберіть тренера*/}
        </b></div>

      {successMessage ?
        (<><br /><br /><div className={s.successMessage} onClick={() => { setSuccessMessage('') }}>
          {successMessage}</div><br /></>) :
        errorMessage ? (<><br /><br /><div className={s.errorMessage} onClick={() => { setErrorMessage('') }}>
          {errorMessage}</div><br /></>) : ''}

          <div className={!isSmallScreen?s.contentTitleItem1:s.td1}>
        <div className={s.title1}>
          <label htmlFor="title"><span><b>
          {lang[langSwitch].startTypingName}{/*Почніть вводити призвище та ім'я*/} 
            </b></span> </label><br /><br />
          <input onChange={handleTitleChange} type="text" id="title" name="title" />

        </div>
        
        <div className={s.title7}>
        <div className={!isSmallScreen?s.switchers:s.switchersSmall}>


            <button className={!isSmallScreen?s.switch1L:s.switch1LSmall} type='button' onClick={() => { saveResultConfirm(); }}>
            {lang[langSwitch].choose}{/*Обрати*/} 
            </button>


            <button className={s.switch6L} type='button' onClick={() => {
              onClose();
              setErrorMessage('');
              setSuccessMessage('');
              setSelectedAthletes([])
            }}>
              {lang[langSwitch].close}{/*Закрити*/} 
              </button>

          </div>
        </div>

      </div><br />



      {/* "плитка спортсменів" */}
      <div className={s.gridContainer}>
        {athletes.map((athlete) => (
          <div key={athlete.id} className={selectedAthletes== athlete.id? `${s.athleteBlock} ${s.athleteBlock1}` : s.athleteBlock}>
            <img
              src={athlete.photo}
              alt={`${athlete.secondName} ${athlete.personName}`}
              style={{ cursor: 'pointer' }}
              onClick={() =>  setSelectedAthletes(athlete.id)}
            />
            <div className={s.name}><b>{`${athlete.secondName} ${athlete.personName}`}<br/>
            {athlete.fatherName}</b></div>
          </div>
        ))}
      </div>
    

      <ConfirmButtons
        isOpen={isConfirmButtonsModalOpen}
        onClose={closeConfirmButtonsModal}
        confirmFunction={confirmFunction}
        messageText={messageText}
        langSwitch={langSwitch}
      />


    </div>
    </div>
    </>):null}
  </>)
}


export default CoachChoice