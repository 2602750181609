import state from "../../../state/state.js";
import s from './adminCompetition.module.css'
import { React,  useState } from 'react';
import GridsModalWindow from "./GridsModalWindow";
import axios from "axios";
import PrintGrids from "./printGrids";
import MailForCoach from "./mailForCoach";
import ConfirmButtons from "./confirmButtons";
import HandleGridsMake from "./handleGridsMake.js";
import Grid2 from "./grids/Grid2";
import Grid3 from "./grids/Grid3";
import Grid4 from "./grids/Grid4";
import Grid5 from "./grids/Grid5";
import Grid6 from "./grids/Grid6";
import Grid7 from "./grids/Grid7";
import Grid8 from "./grids/Grid8";
import Grid9 from "./grids/Grid9";
import Grid10 from "./grids/Grid10";
import Grid11 from "./grids/Grid11";
import Grid12 from "./grids/Grid12";
import Grid13 from "./grids/Grid13";
import Grid14 from "./grids/Grid14";
import Grid15 from "./grids/Grid15";
import Grid16 from "./grids/Grid16";
import Grid17 from "./grids/Grid17";
import Grid18 from "./grids/Grid18";
import Grid19 from "./grids/Grid19";
import Grid20 from "./grids/Grid20";
import Grid21 from "./grids/Grid21";
import Grid22 from "./grids/Grid22";
import Grid23 from "./grids/Grid23";
import Grid24 from "./grids/Grid24";
import Grid25 from "./grids/Grid25";
import Grid26 from "./grids/Grid26";
import Grid27 from "./grids/Grid27";
import Grid28 from "./grids/Grid28";
import Grid29 from "./grids/Grid29";
import Grid30 from "./grids/Grid30";
import Grid31 from "./grids/Grid31";
import Grid32 from "./grids/Grid32";
import Grid33 from "./grids/Grid33";
import Grid34 from "./grids/Grid34";
import Grid35 from "./grids/Grid35";
import Grid36 from "./grids/Grid36.js";
import Grid37 from "./grids/Grid37.js";
import Grid38 from "./grids/Grid38.js";
import Grid39 from "./grids/Grid39.js";
import lang from "../../../state/language.js";
import indexedDBConnection from "../../../state/indexedDBConnection.js";



function Grids({isSmallScreen, langSwitch, id, setGridsOpen, athletesForGrids}) {


  const competitionArray = state.competition.filter((tourn) => tourn.id == id); //турнир в массиві
  const competition = competitionArray[0]//отримуємо турнир з БД
if(competition.grids){
  try{ state.gridsDB=JSON.parse(competition.grids)}
  catch(error){state.gridsDB=competition.grids}
}else(state.gridsDB=[])
let ageCategories=null
if(competition.ageCategories){
try{ageCategories=JSON.parse(competition.ageCategories)}
catch(error){ageCategories=competition.ageCategories}
}
const poomse=competition.poomse==1?true:false
  //сортуємо спортсменів по категоріях


let sortedCategories, sortedClasses



  if (poomse) {
    if (competition.poomsaeCategories) {
      try { sortedCategories = JSON.parse(competition.poomsaeCategories) }
      catch { sortedCategories = competition.poomsaeCategories }
    
      sortedCategories = sortedCategories.map((item) => {
        if (Array.isArray(item.athletes[0])) {
          return {
            category: item.category,
            athletes: item.athletes.map((item3, index) => {
              const result = {
                gridId: item.category + index,
                order: 0,
                team: item3[0].team,
                teamPoomsae: item3
              }
              return result
            })
          }
        } else {
          return {
            category: item.category,
            athletes: item.athletes.map((item2, index2) => {
              return {
                ...item2,
                gridId: item.category + item2.id,
                order:0
              }
            })
          }
        }
      })
    }
  } else {
    if (athletesForGrids!==''){sortedCategories=athletesForGrids}
      else{
    sortedClasses = state.tournConstructor.sortClasses( competition.athletes)
    sortedCategories =  state.tournConstructor.sortedCategoryes(sortedClasses)

}
  }



 let corts = 1
 if (competition.corts) {
  corts = isNaN(Number(competition.corts)) ? competition.corts : Number(competition.corts);
}

  //визначаємо кількість поєдинків
  let athletesArray
  try { athletesArray = JSON.parse(competition.athletes) }
  catch (error) { athletesArray = competition.athletes }



  const componentMap = {
    1: Grid2, 2: Grid2, 3: Grid3, 4: Grid4, 5: Grid5, 6: Grid6, 7: Grid7, 8: Grid8, 9: Grid9,
    10: Grid10, 11: Grid11, 12: Grid12, 13: Grid13, 14: Grid14, 15: Grid15, 16: Grid16, 17: Grid17, 18: Grid18,
    19: Grid19, 20: Grid20, 21: Grid21, 22: Grid22, 23: Grid23, 24: Grid24, 25: Grid25, 26: Grid26, 27: Grid27,
    28: Grid28, 29: Grid29, 30: Grid30, 31: Grid31, 32: Grid32, 33: Grid33,34: Grid34,35: Grid35,36: Grid36,
    37: Grid37,38: Grid38,39: Grid39,
  }; 

  ///////////////////////////////////////////////////////////////////////////////////
  let parsedCompetition =[]
 if(Array.isArray(competition.grids)&&competition.grids.length>0){
  parsedCompetition=competition.grids
 }
if(!Array.isArray(competition.grids)){
 try {parsedCompetition=JSON.parse(competition.grids)}catch(erorr){}
}
 


const [grids, setGrids]=useState([])
const [isGridsModalOpen, setIsGridsModalOpen] = useState (false);

const [printGrids, setPrintGrids] = useState(false);
const [successMessage, setSuccessMessage] = useState(null);
const [errorMessage, setErrorMessage] = useState(null);
const [savedGrids, setsavedGrids] = useState(false);
const [handleGridsMakeWeiw, setHandleGridsMakeWeiw] = useState(false);

function gridsMaker(){
  if(!sortedCategories){return}
  if(poomse){             //cтворюємо grids для Пхумсе
    const athletsArreyPoomsae=[]
    const gridsPoomsae=[]

    sortedCategories.forEach((category)=>{
      const result ={}
       result.category=category.category
       result.athletes=category.athletes
        result.numberOfAthletes=category.athletes.length
    
       category.athletes.forEach((team)=>{       
       
        if(Array.isArray(team)){         
          team.forEach((athlete)=>{athletsArreyPoomsae.push( athlete.id)})
        }else{
          athletsArreyPoomsae.push( team.id)
        }
     })
    
     gridsPoomsae.push(result) 
    })
   
    setGrids(state.tournConstructor.cortsAndBattleNumber(gridsPoomsae,athletsArreyPoomsae,competition,poomse))
  }else{
setGrids(state.tournConstructor.gridsMaker(sortedCategories,athletesArray,competition,langSwitch))

}}

// Модальне вікно з сітками
const openGridsModal = () => {setIsGridsModalOpen(true);}
const closeGridsModal = () => {setIsGridsModalOpen(false);}
///////////////////////////////////////////////////////////////////////////////////////
// Модальне вікно з кнопками погодження eMail розсилки.
const messageText=
lang[langSwitch].startSendingGridsByEMail /*Розпочати роззсилку турнірних сіток по eMail?*/

const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState (false);
const confirmFunction = () => { 

if(Array.isArray(state.gridsDB)&&state.gridsDB.length>0|| 
Array.isArray(parsedCompetition)&&parsedCompetition.length>0){


   MailForCoach({competition:competition,
    poomse:poomse, 
    langSwitch:{langSwitch},
    gridsDB:
    Array.isArray(state.gridsDB)&&state.gridsDB.length>0?state.gridsDB:parsedCompetition
  }) 

  setSuccessMessage(
    lang[langSwitch].eMailDistributionGridsStarted/*Розпочато eMail розсилку турнірних сіток.*/
    )
  }
else{
    setErrorMessage(
      lang[langSwitch].youNeedSaveGridsInDB/* Для розпочатку розсилки потрібно зберегти сітки в Бази Даних!*/
    )
} 
}
const openConfirmButtonsModal = () => {setIsConfirmButtonsModalOpen(true);}
const closeConfirmButtonsModal = () => {setIsConfirmButtonsModalOpen(false);}
/////////////////////////////////////////////////////////////////////////////////////
 

function saveGrids(numberOfAthletes, athleteList, competitionTitle, category, cort, battleNumbers) {
    
  //збираємо данні з сіток 
      const result = {}
      result.numberOfAthletes = numberOfAthletes;
      result.athleteList = athleteList
      result.competitionTitle = competitionTitle
      result.category = category
      result.cort = cort
      result.battleNumbers = battleNumbers
      state.gridsForDB.push(result)   

  }


//Зберігаємо сітки
if(!handleGridsMakeWeiw){
      if ((grids.length>0 && grids.length === state.gridsForDB.length)||
      (state.grids.length>0 && state.grids.length===state.gridsForDB.length)||
      (state.gridsDB.length>0 && state.gridsDB.length===state.gridsForDB.length))
        {
           //завантажуємо в БД
       const gridsForAxios= JSON.stringify(state.gridsForDB)
        axios
          .post(state.api.gridsCompetition, {    
            grids: gridsForAxios,
            id: competition.id    
          })
          .then((response) => {
           setHandleGridsMakeWeiw(false)
            setSuccessMessage(lang[langSwitch].successfullSavedInDB/* Внесені зміни успішно збережено в Базі Даних*/);
           
            state.saveGridsFunctionStart = false
            
            const index = state.competition.findIndex((comp) => comp.id == id);
            state.competition[index].grids = gridsForAxios 
            
          
            indexedDBConnection.saveData ('competition', state.competition)
            state.gridsForDB = []  
           
            setTimeout(()=>{
              window.location.reload();// Перезавантаження сторінки
            },2000)
            
          })
          .catch((error) => {
            setErrorMessage(
              lang[langSwitch].serverRequestError/*Помилка запиту до сервера: */+error);              
          }); 
        }
}

 

function errorPrint(text){setErrorMessage(text)}

function savedGridsFunction (){//функція для виводу у модальне вікно саме збережених, а не згенерованих

if(Array.isArray(state.gridsDB)&&state.gridsDB.length>0|| 
Array.isArray(parsedCompetition)&&parsedCompetition.length>0)
{
  state.grids=[]
  setsavedGrids(true)
  openGridsModal();
}else( setsavedGrids(false)) 
 }

 function printFunction(){
  if(Array.isArray(state.gridsDB)&&state.gridsDB.length>0|| 
Array.isArray(parsedCompetition)&&parsedCompetition.length>0)
{
  setPrintGrids(!printGrids)
}
 }
 const emptyGrids=[]



///////////////////////////////////////////////////////////////////////////////////

return (
  <>
  <div className={s.noPrint} >

  {errorMessage ? (<div className={s.errorMessage} onClick={() => { setErrorMessage(null) }}
          style={{ cursor: 'pointer' }}>
          {errorMessage}
        </div>
        ) : null} 
        {successMessage ? (<div className={s.successMessage} onClick={() => { setSuccessMessage(null) }}
          style={{ cursor: 'pointer',}}>
          {successMessage}
        </div>
        ) : null}

 {/*Виклик Вікна погодження */}
 <ConfirmButtons
          isOpen={isConfirmButtonsModalOpen}
          onClose={closeConfirmButtonsModal}
          confirmFunction={confirmFunction}
          messageText={messageText}
          langSwitch={langSwitch}
        /> 



{Array.isArray(ageCategories)&&ageCategories.length<corts?(<>

    <div className={!isSmallScreen?s.contentTitleItem1:s.td1}>
      <div className={s.title1}>
        <button className={s.butt} type="button" onClick={() => {setsavedGrids(false); gridsMaker(); openGridsModal(); }}>
        {lang[langSwitch].generateGrids /* Генерувати сітки*/}
        </button>
      </div>
      <div className={s.title2}>

        <button className={s.butt} type='button' onClick={()=>{ 
          state.gridsDB.length>0||state.grids.length>0?
          savedGridsFunction():
          setErrorMessage(
            lang[langSwitch].youHaveNoSavedGrids/* У вас немає збережених сіток. перейдіть у вкладку "Генерувати сітки"*/
            );}}>
         
         {lang[langSwitch].savedGrids /* Збережені сітки*/}
         </button>
      </div>
      <div className={s.title3}>
        <button className={s.butt} type='button' onClick={() => { 
          state.gridsDB.length>0||state.grids.length>0?
          printFunction() :
          setErrorMessage(
            lang[langSwitch].youHaveNoSavedGrids/* У вас немає збережених сіток. перейдіть у вкладку "Генерувати сітки"*/
            );}}>
         
          {!printGrids?
          lang[langSwitch].print /*Друкувати*/
          :
           lang[langSwitch].closePrint /*Закрити друк*/
          }
          </button>
      </div>
      </div>

      <div className={!isSmallScreen?s.contentTitleItem2:s.td1}>
      <div className={s.title4}>
        <button className={s.butt} type='button'  onClick={()=>{ openConfirmButtonsModal();}}>
        {lang[langSwitch].eMailMailingGrids /* eMail розсилка сіток*/}
          </button>
      </div>
      <div className={s.title5}>
      
        <button className={s.exitButton} type='button' onClick={()=>{setGridsOpen(false)}}>
        {lang[langSwitch].comeBack /* Повернутись*/}
          </button>
  
      </div>
      </div>
       </>):(<>
{/* генерування сіток вручну */}

        <div className={!isSmallScreen?s.contentTitleItem1:s.td1}>
      <div className={s.title1}>
        <button className={s.butt} type="button" onClick={() => {setsavedGrids(false); gridsMaker(); openGridsModal(); }}>
        {lang[langSwitch].automaticallyGenerateGrids /*Автоматично генерувати сітки*/}
        </button>

{!poomse?(<>
        <button className={s.butt} type="button" 
        onClick={()=>{setHandleGridsMakeWeiw(true)}}> 
       {lang[langSwitch].manuallyGenerateGrids /* Вручну генерувати сітки*/}
        </button>      
</>):null}

      </div>
      <div className={s.title2}>

        <button className={s.butt} type='button' onClick={()=>{ 
          state.gridsDB.length>0||state.grids.length>0?
          savedGridsFunction():
          setErrorMessage(
            lang[langSwitch].youHaveNoSavedGrids/*У вас немає збережених сіток. перейдіть у вкладку "Генерувати сітки"*/
            );}}>
          {lang[langSwitch].savedGrids /* Збережені сітки*/}
          </button>

          <button className={s.butt} type='button'  onClick={()=>{ openConfirmButtonsModal();}}>
         {lang[langSwitch].eMailMailingGrids /*eMail розсилка сіток*/}
         </button>
      </div>
      <div className={s.title3}>
        <button className={s.butt} type='button' onClick={() => { 
          state.gridsDB.length>0||state.grids.length>0?
          printFunction() :
          setErrorMessage(
            lang[langSwitch].youHaveNoSavedGrids/*У вас немає збережених сіток. перейдіть у вкладку "Генерувати сітки"*/
                     );}}>
         
          {!printGrids?
           lang[langSwitch].print /*Друкувати*/
           :
            lang[langSwitch].closePrint /*Закрити друк*/
          }</button>

        
        <button className={s.exitButton} type='button' onClick={()=>{setGridsOpen(false)}}>
        {lang[langSwitch].comeBack /* Повернутись*/}
          </button>
     
      </div>
      </div>
      </>)}
{handleGridsMakeWeiw?(

     <div  className={s.modalGrids}><br/><br/><br/>
  <HandleGridsMake 
sortedCategories={sortedCategories}
athletesArray={athletesArray}
competition ={competition}
saveGrids={saveGrids}
setHandleGridsMakeWeiw={setHandleGridsMakeWeiw}
langSwitch={langSwitch}
/>
</div>

):null}
      
      <div> {/*Модальне вікно з сітками */}
        {/*Виклик Вікна */}
        {/* згенеровані сітки */}
       {grids.length>0||state.grids.length>0&&!savedGrids?
    
       <GridsModalWindow
       isSmallScreen={isSmallScreen}
          grids={grids}
          gridsDB={emptyGrids}
          setHandleGridsMakeWeiw={setHandleGridsMakeWeiw}
          competition={competition}
          isOpen={isGridsModalOpen}
          saveGrids={saveGrids}
          onClose={closeGridsModal}
          langSwitch={langSwitch}
        />
      :null}
      {/* збережені сітки */}

      {Array.isArray(state.gridsDB)&&state.gridsDB.length>0&&savedGrids|| 
  Array.isArray(parsedCompetition)&&parsedCompetition.length>0
  &&savedGrids?
        <GridsModalWindow
        isSmallScreen={isSmallScreen}
           grids={emptyGrids}
           gridsDB={state.gridsDB.length>0?state.gridsDB:parsedCompetition}
           setHandleGridsMakeWeiw={setHandleGridsMakeWeiw}
           competition={competition}
           isOpen={isGridsModalOpen}
           saveGrids={saveGrids}
           onClose={closeGridsModal}
           langSwitch={langSwitch}
         />:null}
      </div>

    </div>

     {printGrids?(<PrintGrids  
     gridsDB={state.gridsDB.length>0?state.gridsDB:parsedCompetition} 
     componentMap={componentMap} 
     competition={competition} 
     saveGrids={saveGrids} 
     errorPrint={errorPrint}
     langSwitch={langSwitch}
        />):null}
  </>
);



}


export default Grids