import React, { useEffect, useState } from 'react';
import s from '../menu/menu.module.css'
import sAdmin from '../../adminCompetition/adminCompetition.module.css'
import sApp from '../../../../App.module.css'
import lang from '../../../../state/language';
import state from '../../../../state/state';
import ConfirmButtons from '../../adminCompetition/confirmButtons';
import axios from 'axios';
import indexedDBConnection from '../../../../state/indexedDBConnection';
import MultiImageUploader from '../news/multiImageUploader';



function AddPhotoGalery({ langSwitch, isSmallScreen }) {

  const [compressedFile, setCompressedFile] = useState([]);
  const [title, setTitle] = useState(null);
 const [type, setType] = useState(null);
  const [messageText, setMessageText] = useState('');
  const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [uploading, setUploading] = useState(false);

  const imgAdress = []

  const openConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(true); }
  const closeConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(false); }

   function saveNewsConfirm() {  
      if(title&&compressedFile.length>0&&type){
              setMessageText(lang[langSwitch].createNewGalery+'?' /*Створити нову галерею?*/)
                openConfirmButtonsModal()  
      }else{setErrorMessage(
        lang[langSwitch].createGaleryInstructions /*Для створення галереї потрібно вказати тип, назву, та завантажити світлини.*/ 
    )}   
   }

  const confirmFunction = (messageText) => {
    if (messageText === lang[langSwitch].createNewGalery+'?' /*Створити нову галерею?*/
      ) {
    //ВИКЛИК ФУНКЦІЇ СТВОРЕННЯ СТАТТІ
    uploadNews()

    }else{
        // console.log('Помилка ')
    }

  }

  function handleTitle(e) { setTitle(e.target.value) }
  function handleGalleryTypeChange(e) { setType(e.target.value) }

  //завантаження на фото сервер

      function uploadNews  ()  {
        setUploading(true)
        const formData = new FormData();
        formData.append("title", title);
        formData.append("type", type);  
        formData.append("typeContent", 'photo');      
        formData.append("uploadDir", "../public/img/mediaContentMSAC/photoGallery/" + state.lastChangesDateFormat() + "/");
        // Передаємо шлях для завантаження
        formData.append("targetDirectory", state.imgAdress.photoGalery + state.lastChangesDateFormat() + "/" ); 
    
        // Додаємо фото у FormData
        compressedFile.forEach((file) => {
          formData.append(`photos[]`, file);
        });
    
// Вивід FormData у консоль

    // console.log("FormData Entries:");
    // for (let [key, value] of formData.entries()) {
    //   console.log(key, value);
    // }

        axios.post(state.api.createGallery,formData,
                  {headers: { "Content-Type": "multipart/form-data" },
                    timeout: 60000 // 20 секунд (повільний інтернет)
                  }
                )
              .then((response)=>{
                    if(response.data.mediaContent){
                    state.mediaContent=[...response.data.mediaContent]
                    indexedDBConnection.saveData('mediaContent', state.mediaContent)
                  } 

                     window.location.reload(true);  //при перезавантаженні дані беруться з кешу                  

                })
              .catch((error)=>{  setUploading(false)
                setErrorMessage(lang[langSwitch].errorOccurredWhileSavingImage  /*Помилка під час збереження зображення*/)
                    console.log('error: ',error)
              })
            
        };
  


      
  return (<div>
    <div className={s.h1}>
      {lang[langSwitch].createNewGalery /*Створити нову галерею*/}
    </div>

    <div>
      <div className={s.h3}>  
        {lang[langSwitch].galeryTitle   /*Назва галереї*/}
      </div>


      <input
        type='text'
        className={s.inputNews}
        placeholder={title}
        onChange={(e) => { handleTitle(e) }}
      />
    </div>

    <div>
      <div className={s.h3}>
        {lang[langSwitch].typeOfGallery   /*Тип галереї*/}
      </div>
      <select onChange={handleGalleryTypeChange}  >
      <option value=""></option>
      {state.galleryType.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ))}
    </select>
       </div>

    
    <div><br/>
      <div className={s.h3}>
        {lang[langSwitch].photos   /*Світлини*/}
      </div>
      {errorMessage ? (<><br /><br /><div className={sAdmin.errorMessage} onClick={() => { setErrorMessage('') }}>
      {errorMessage}</div><br /></>) : ''}
      <MultiImageUploader
        setCompressedFile={setCompressedFile}
        compressedFile={compressedFile}
        numberOfPhoto={state.numberOfPhotoInGalery}
        langSwitch={langSwitch}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage} 

      />
    </div>
   
<button onClick={()=>{saveNewsConfirm()}}>
{lang[langSwitch].createNewGalery+'?' /*Створити нову галерею?*/}
</button>
  

 <ConfirmButtons
        isOpen={isConfirmButtonsModalOpen}
        onClose={closeConfirmButtonsModal}
        confirmFunction={confirmFunction}
        messageText={messageText}
        langSwitch={langSwitch}
      />
      {  uploading&&(
        <div className={sAdmin.modalWriper}>
            <div className={sApp.flag}>
            { lang[langSwitch].pleaseWaitDataLoading /*Зачекайте, дані завантажуються...*/ }<br /><br />
                <img alt='фото' src='/img/main/ukrainian-waving-flag-30.gif' />
               </div>
          </div>
      )}

  </div>);
};

export default AddPhotoGalery;