import React, { useEffect } from 'react';
import s from './adminPoomsae.module.css';
import BlockGrid from './blockGrid';

function PrintPoomsaeGrids(props) {

 

  ///////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        // Код для виклику друку
        window.print();
      }, []); // Пустий масив залежностей гарантує виклик ефекту лише після першого рендерингу компонента
    
     return( <>  
       

      {props.gridsDB?(   
        <div className={s.mainDiv}>
          {props.gridsDB.map((grid) => {           
         
              return (
                <BlockGrid
                  key={grid.category} 
                  competition={props.competition}                 
                  grid={grid}
                  langSwitch={props.langSwitch}
                />
              );
          
          
          })}
        </div>   ):  
(<h1 className={s.h1}>Для виведення сіток на друк їх потрібно попередньо згенерувати та зберегти в БД.</h1>)} 


</> )  
  }
  export default PrintPoomsaeGrids
  