import sAdmin from '../adminCompetition.module.css'
import s from './adminPoomsae.module.css'
import state from "../../../../state/state"
import lang from "../../../../state/language"




export function IntermediateResultsPrint({isOpen, onClose, athletesList, cort, battleNumbers, category,competition, langSwitch, roundForPrint}){
    // console.log('battleNumbers: ',battleNumbers)
let count=0


return(<>
{isOpen?(<>
<div className={sAdmin.modalWriper}>
 <div className={sAdmin.modalGrids}>
 <div className={s.mainDiv}>
            <div  className={s.gridHeader}>
            <div className={s.title}>{competition.title}</div>
                <div className={s.date}>
                    {competition.dateStart && competition.dateFinish ? (<>
                        {state.formatDate(competition.dateStart).slice(0, 2)}-
                           {state.formatDate(competition.dateFinish)} {lang[langSwitch].yearShort/*p.*/}
                    </>) : (<>
                          {competition.dateOfCompetition}{lang[langSwitch].yearShort/*p.*/}
                    </>)}
                </div>
                <div className={s.category}>
                    {lang.poomsaeCatUkr(langSwitch,category)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {lang[langSwitch].court/*Корт*/}: {cort} </div>
                <div className={s.category}>{roundForPrint}</div>
            </div>

            <table style={{width:'80%', marginLeft: 'auto', marginRight: 'auto' }}>
                <tr><td>
                {athletesList.map((item, index) => {
                
                    if (Array.isArray(item.teamPoomsae)) {
                        return (<>

                            <div className={index === 0 ? s.tableFirstItem : s.tableItem} key={item.gridId}>
                                <div className={s.battleNum}>{cort}.{battleNumbers[count]}</div>   
                                 <div className={s.invisible}> {count++}</div>
                                <div>
                                    <div className={s.athleteDiv} >
                                        {state.poomsaeTeam(item.teamPoomsae)}
                                    </div>
                                    <div className={s.teamDiv}>{state.regionShort(item.team)}</div>
                                </div>
                            </div>

                        </>)
                    } else {
                        return (<>
                            <div className={index === 0 ? s.tableFirstItem : s.tableItem} key={item.gridId}>
                                <div className={s.battleNum}>{cort}.{battleNumbers[count]}</div>
                                <div className={s.invisible}> {count++}</div>
                                <div className={s.athleteDivBox}>
                                    <div className={s.athleteDiv} > {state.athleteName(item.id)}</div>
                                    <div className={s.teamDiv}>{state.regionShort(item.team)}</div>

                                </div>
                            </div>

                        </>)
                    }

                })}            
               
                </td></tr>
            </table>


           </div>
          <div className={s.noPrint}> 
            <div className={s.buttonContaner}>
             <div className={s.buttonLeft}>   
<button onClick={() => {window.print()}}>
Друк
</button>            
   </div>   
    <div className={s.buttonRight}>   
 <button className={sAdmin.redButton2} onClick={() => {onClose()}}>
Закрити
</button>
</div>
</div>
</div>

 </div>
 </div>
 </>):null}
</>)
}