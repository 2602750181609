import React, { useState } from "react";
import s from '../adminCompetition/adminCompetition.module.css';
import lang from './../../../state/language';
import ScaleCategoriesCell from "./ScaleCategoriesCell.js";




function AthleteScaleByJudge({
                  setScaleTeam, 
                  isSmallScreen, 
                  langSwitch,
                  sortedCategoryesData, 
                  setCategoryDataScaleAthletesCell,
                  setIsOpenScaleAthletesCell,
                  transferAthleteData}) {

    const [categoryCellModalOpen, setCategoryCellModalOpen] = useState (false);
    const [catForModal, setCatForModal] = useState (null);
  
  
   // Модальне вікно з Категоріями спортсменів
   const openCategoryCellModal = (cat) => {
    setCatForModal(cat)
   
      setCategoryCellModalOpen(true);
    }
    const closeCategoryCellModal = () => {setCategoryCellModalOpen(false);}

  
    return (<>

          <div className={s.h1}> <b>
              {lang[langSwitch].selectCategoryCromRemoveAthlete/*Оберіть категорію з якої ви хочете вилучити спортсмена*/}
            </b>           
          </div>
         
          {/* "плитка спортсменів" */}
       
          {sortedCategoryesData ?(<>      

      <ScaleCategoriesCell 
        categoriesData={sortedCategoryesData} 
        langSwitch={langSwitch}
        openCategoryCellModal={openCategoryCellModal}
        scale={true}
        setCategoryDataScaleAthletesCell={setCategoryDataScaleAthletesCell}
        setIsOpenScaleAthletesCell={setIsOpenScaleAthletesCell}
        transferAthleteData={transferAthleteData}
        />
        </>):null} 


              <div className={!isSmallScreen ? s.switchers : s.td1}>
                <button 
                  className={s.switch6L} 
                  type='button' 
                  onClick={() => {setScaleTeam(false); }}>
                      {lang[langSwitch].close /*Закрити*/}
                </button>
              </div>
      
      
        </>)



}


export default AthleteScaleByJudge