

import state from '../../../state/state'
import s from '../results/result.module.css'

function Poomse({isSmallScreen}){
   
    return(<>
            <br/> <div className={s.h1}><b>Пхумсе</b></div>
             <div className={s.h3}>Повна збірка формальних комплексів Тхеквондо WT</div><br/>
     
     <div className={s.youtubeLink}>

{state.poomse.map((item)=>{

  return(<>
  <div className={s.youtubeItem}>
  <iframe
  key={item}
  width={isSmallScreen ? '350' : '560'}
  height={isSmallScreen ? '280' : '450'}
  src={`${item}?rel=0&controls=1&showinfo=0&modestbranding=1&enablejsapi=1&origin=${window.location.origin}&widgetid=1`}
  title="YouTube video player"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
  allowFullScreen
></iframe>

     <br/> <br/> 
     </div>
  </>)
})} &nbsp;
     
      </div>
      
        </>)
}
export default  Poomse
