//вибір та заявка спортсменів на змагання. 
//містить модальне вікно для видору спортсменів, прийом та обробку даних та 
// axios виклики для відправки даних в БД.

import React, { useEffect, useState } from "react";
import s from '../adminCompetition/adminCompetition.module.css';
import state from "../../../state/state.js";
import axios from "axios";
import lang from "../../../state/language.js";
import indexedDBConnection from "../../../state/indexedDBConnection.js";

function Entry({ isOpen, onClose, tournId, isSmallScreen, langSwitch }) {

  // Створіть стан для зберігання списку вибраних спортсменів
  const [selectedAthletes, setSelectedAthletes] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [team, setTeam] = useState('');
  const [bClasData, setBClasData] = useState(null);
  const [errorAthletes, setErrorAthletes] = useState([]);
  const [errorAthletes1, setErrorAthletes1] = useState([]);
  const [errorAthletesMain, setErrorAthletesMain] = useState([]);
  const [athletes, setAthletes] = useState(null);
   const [testArray, setTestArray] = useState([]);
  const [dbRequestSwitcher, setDbRequestSwitcher] = useState(false);

  state.messageCount()//лічильник непрочитаних повідомлень
  //надсилаємо тренеру перелік проблем у профілі спортсмену
  useEffect(() => {
    if (errorAthletesMain.length > 0) {
      state.errorMassageSend(errorAthletesMain, state.userProfile.id, langSwitch);
    }
  }, [errorAthletesMain]);


  //////////////////////////////////////////////////////////////////////////////////////////
  const handleTeamChange = (e) => { setTeam(e.target.value) };
  const handleClassChange = (e) => { setBClasData(e.target.value) }

  const competition = state.competition.filter((tourn) => tourn.id == tournId);
  const myAthletes = state.athletes.filter((athlete) => {// вибір моїх спортсменів  
    return athlete.coach == state.userProfile.id || athlete.coach2 == state.userProfile.id;
  });




  //отримуємо свіжий список заявлених спортсменів
  if (!dbRequestSwitcher && isOpen) {
    axios
      .post(state.api.getAthletesForComp, { id: tournId })
      .then((response) => {
      console.log('response: ',response)
        setDbRequestSwitcher(true)
        if (Array.isArray(response.data.athletes)) {
          setTestArray(response.data.athletes)
        }

      })
      .catch((error) => {
        setErrorMessage(lang[langSwitch].serverRequestError /*Помилка запиту до сервера: */, error);
      });
  }



  ///Фільтруємо умови участі в класах Б-3 та Б-4
  function testAthletesForClass(athlete, clas) {

    let result = true

    if (clas === 'B3') {
      if (athlete.sex === 'female') {
        result = false
      }
      if (state.age(athlete.birthday) < 14) {
        result = false
      }
    }
    if (clas === 'B4') {
      if (athlete.sex === 'female') {
        result = false
      }
      if (state.age(athlete.birthday) < 18) {
        result = false
      }
    }
    return result
  }

  //////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    //відфільтровуєм умови Б-3 та Б-4
    let  athletes1
    if(bClasData){
    if (testArray.length > 0) {

      athletes1 = myAthletes.filter((myAthlete) => {
        if (testAthletesForClass(myAthlete, bClasData)) {
          return myAthlete
        }
      })

      //відфільтровуєм заявлених в класі спортсменів
      console.log('athletes1: ',athletes1)
      const athletesData = athletes1.filter(
        (athlete) => !testArray.some(test => test.id === athlete.id && test.clasB === bClasData)
      );

      setAthletes(athletesData)

    } else {
      const athletesData = myAthletes.filter(myAthlete =>
        testAthletesForClass(myAthlete, bClasData)
      );


      setAthletes(athletesData);

    }
}
  }, [bClasData])



  // Функція для додавання або видалення спортсмена зі списку (плитки) під час заявки
  const toggleAthleteSelection = (athlete) => {
    if (selectedAthletes.includes(athlete.id)) {
      // Спортсмен вже вибраний, видаляємо його зі списку
      setSelectedAthletes(selectedAthletes.filter((id) => id !== athlete.id));
    } else {
      // Спортсмен ще не вибраний, додаємо його до списку
      setSelectedAthletes([...selectedAthletes, athlete.id]);
    }
  };

  //Фільтруемо спортсменів
  function filterForAthletes(competition, notEntrySportsmans) {

    // отримуємо перелік вікових категорів відповідного турниру
    const ageCategoriesString = competition[0].ageCategories; // Отримуємо рядок


    let ageCategories

    try {
      ageCategories = JSON.parse(ageCategoriesString); // Перетворюємо у масив
    } catch (error) {
      ageCategories = ageCategoriesString
    }
    //замінюємо перелік категорій на категорії з функціями
    const ageCatOfCompetition = {};

    if (!ageCategories || Array.isArray(ageCategories) && ageCategories.length === 0) {
      setErrorAthletes(
        '<div>' +
        lang[langSwitch].notHaveCategories /* Нажаль організатор турніру не вказав вікових категорій по яких проводитимуться змагання.*/
        + '</div>'
      )
    }
    else {
      ageCategories.forEach((category) => {
        if (state.tournConstructor.ageCat[category]) {
          ageCatOfCompetition[category] = state.tournConstructor.ageCat[category];
        }
      });
    }

    //замінюємо id на профіль користувача
    const athletesProfile = state.athletes.filter((sportsman) => {
      return notEntrySportsmans.includes(sportsman.id);
    });


    // ПЕРША ПЕРЕВІРКА: чи попадає спортсмен у вікову категорію.
    function filterForAthletes1(athletesProfile, ageCategories) {
      const filter1ErrorMessage = [];

      const currentYear = new Date().getFullYear(); // поточний рік

      const filterResult = athletesProfile.filter((athlete) => {
        let passedCategoryCheck = false; // флаг для перевірки вікової категорії

        for (const category in ageCategories) {
          const { from, to } = ageCategories[category];
          const age = currentYear - new Date(athlete.birthday).getFullYear(); // вік спортсмену

          if (age >= from && age <= to) {
            passedCategoryCheck = true;
            break; // якщо спортсмен підходить до однієї категорії, виходимо з циклу
          }
        }

        if (!passedCategoryCheck) {
          filter1ErrorMessage.push(
            `<div>
            ${lang[langSwitch].anAthlete /*Спортсмену*/}: 
            <u><b> ${athlete.secondName + ' ' + athlete.personName}</b></u>, 
            ${lang[langSwitch].refusedRegistration /*відмовлено в реєстрації на турнир по наступних причинах:*/}
            </div> ${lang[langSwitch].errorAthletes.category}`);
        }

        return passedCategoryCheck;
      });

      setErrorAthletes1(filter1ErrorMessage);

      return filterResult;
    }


    const filter1 = filterForAthletes1(athletesProfile, ageCatOfCompetition);


    //  ДРУГА ПЕРЕВІРКА: чи усі поля заповнені в профілі
    function filterForAthletes2(filter1, date) {
      const errorMessages = [];
      const filteredAthletes = filter1.filter((sportsman2) => {
        const athleteErrors = [];

        if (sportsman2.activFtu != 1) { athleteErrors.push(lang[langSwitch].errorAthletes.activFtu); }
        if (sportsman2.activSite != 1) { athleteErrors.push(lang[langSwitch].errorAthletes.activSite); }
        if (sportsman2.fatherName === null || sportsman2.fatherName === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.fatherName); }
        if (sportsman2.birthday === null || sportsman2.birthday === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.birthday); }
        if (sportsman2.sex === null || sportsman2.sex === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.sex); }
        if (sportsman2.eMail === null || sportsman2.eMail === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.eMail); }
        if (sportsman2.photo === null || sportsman2.photo === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.photo); }
        if (sportsman2.weiht === null || sportsman2.weiht === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.weiht); }
        if (sportsman2.height === null || sportsman2.height === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.height); }
        if (sportsman2.coach === null || sportsman2.coach === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.coach); }
        if (sportsman2.sportClub === null || sportsman2.sportClub === undefined || sportsman2.sportClub === ' ') { athleteErrors.push(lang[langSwitch].errorAthletes.sportClub); }
        if (sportsman2.region === null || sportsman2.region === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.region); }
        if (sportsman2.dan === null || sportsman2.dan === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.dan); }
        if (sportsman2.rank === null || sportsman2.rank === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.rank); }
        if (sportsman2.fst === null || sportsman2.fst === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.fst); }
        if (sportsman2.insuranceComp === null || sportsman2.insuranceComp === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.insuranceComp); }
        if (sportsman2.insuranceNum === null || sportsman2.insuranceNum === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.insuranceNum); }
        if (sportsman2.insuranceDate === null || sportsman2.insuranceDate === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.insuranceDate); }
        if (new Date(sportsman2.insuranceDate) < new Date(date)) { athleteErrors.push(lang[langSwitch].errorAthletes.insuranceActive); }
        if (sportsman2.medicalCertificateNum === null || sportsman2.medicalCertificateNum === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.medicalCertificateNum); }
        if (sportsman2.medicalCertificateDate === null || sportsman2.medicalCertificateDate === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.medicalCertificateDate); }
        if (new Date(sportsman2.medicalCertificateDate) < new Date(date)) { athleteErrors.push(lang[langSwitch].errorAthletes.medicalCertificateActive); }
        if (sportsman2.status === null || sportsman2.status === undefined) { athleteErrors.push(lang[langSwitch].errorAthletes.status); }


        if (athleteErrors.length > 0) {
          errorMessages.push({
            name: `${sportsman2.secondName} ${sportsman2.personName}`,
            errors: athleteErrors
          });
        }
        return athleteErrors.length === 0;
      });
      if (errorMessages.length > 0) {
        const errorReports = errorMessages.map((athleteError) => {
          return `<div><br/>
           ${lang[langSwitch].anAthlete /*Спортсмену*/}: 
          <u><b> ${athleteError.name}</b></u>, 
          ${lang[langSwitch].refusedRegistration /*відмовлено в реєстрації на турнир по наступних причинах:*/}
            </div>${athleteError.errors.join('')}`;
        });

        setErrorAthletesMain(errorReports);

      }

      return filteredAthletes;
    }
    const filter2 = filterForAthletes2(filter1, competition[0].dateStart)


    //вкладаємо в массив команду та ід спортсменів
    function teamFunction(filter2) {

      if (team === 'region') {
        return filter2.map((athlete) => ({
          id: athlete.id,
          entryCoach: state.userProfile.id,
          team: 'region',
          clasB: bClasData
        }));
      } else if (team === 'club') {
        return filter2.map((athlete) => ({
          id: athlete.id,
          entryCoach: state.userProfile.id,
          team: 'sportClub',
          clasB: bClasData
        }));
      }
    }

    const athleteIds = teamFunction(filter2)
    return athleteIds
  }
  /////////////////////////////////////////////////////////////////////////////////////////

  //Викликі в БД
  function test(selectedAthletes, tournId) {//перевіряємо чи не закрита реєстрація


    if (competition[0].active == 1) {

      updateAthletesOnCompetition(selectedAthletes, tournId)

    }
    else {
      setErrorMessage(
        lang[langSwitch].registrationForTournamentCompleted /*Нажаль реєстрацію на цей турнир завершено.*/
      )
    }
  }

  function updateAthletesOnCompetition(selectedAthletes, tournId) {

    if (selectedAthletes.length > 0) {    

          const athletesForEntry = filterForAthletes(competition, selectedAthletes)//фільтруємо спортсменів


          let arrayAthletes = []
          if (athletesForEntry.length === 0) {

          } else {
            arrayAthletes = Array.isArray(testArray)
              ? [...testArray, ...athletesForEntry]
              : athletesForEntry;
          }

          if (arrayAthletes.length >= competition[0].numberOfAthletes) {
            setErrorMessage(
              lang[langSwitch].haveNoPlases /* Нажаль кількість обраних вами спорстменів перевищує кількість доступних для реєстрації місць.*/
            )
            return
          } else {

            if (arrayAthletes.length > 0) {
              axios
                .post(state.api.addNewAthletes, {
                  id: tournId,
                  newAthletes: arrayAthletes,
                })
                .then((response) => {

                  const index = state.competition.findIndex(item => item.id == tournId);
                  state.competition[index].athletes = JSON.stringify(arrayAthletes);
                  indexedDBConnection.saveData('competition', state.competition)

                  setSuccessMessage(
                    lang[langSwitch].entrySuccessfulleSubmitted /*Заявку успішно подано.*/
                  );
                  setSelectedAthletes([]);
                  setTimeout(()=>{
                    window.location.reload(true); // при перезавантаженні дані беруться з кешу
                    setSuccessMessage('')}, 3000);


                  const updateData = {
                    id: state.userProfile.id,
                    lastChangesDate: state.lastChangesDateFormat()//Дата останьої зміни профіля
                  }
                  axios.post(state.api.postLastChangesDate, { updateData: updateData })
                    .then(response => { })
                    .catch(error => {
                      // console.error('Помилка Axios-запиту, невдалось зберегти результати турниру в профілі атлетів:', error);
                    });



                })
                .catch((error) => {
                  setErrorMessage(lang[langSwitch].serverRequestError /*Помилка запиту до сервера: */ + error);
                });
            }
          }
      
    }

    else {
      setErrorAthletes(
        `<div>${lang[langSwitch].notSelectedAnyAthlete /*Ви не обрали жодного спортсмена!*/}</div>`
      )
    }
  }
  const createMarkup = (html) => {
    return { __html: html };
  };

  return (<>
    {isOpen ? (<>
      <div className={s.modalWriper}>
        <div appElement={document.getElementById('root')} className={s.modal} contentLabel="Regulation Modal">
          <div className={errorAthletes1.length === 0 ? '' : `${s.errorMessage1}`} dangerouslySetInnerHTML={createMarkup(errorAthletes1)}
            onClick={() => { setErrorAthletes1([]) }} /><br />
          <div className={errorAthletesMain.length === 0 ? '' : `${s.errorMessage1}`} dangerouslySetInnerHTML={createMarkup(errorAthletesMain)}
            onClick={() => { setErrorAthletesMain([]) }} /><br />

          <div className={errorAthletes.length === 0 ? '' : `${s.errorMessage1}`} dangerouslySetInnerHTML={createMarkup(errorAthletes)}
            onClick={() => { setErrorAthletes([]) }} />


          <div className={s.teamContaner}>
            <div className={s.teamChange}>
              <h2>
                {lang[langSwitch].myTeam /*Моя команда*/}
              </h2>
            </div>
            <div className={s.competitionClassChoise}>
              <label htmlFor="ClassChoise"><span className={s.teamChangeMenuSpan}>
                {lang[langSwitch].chooseClass /*Оберить клас*/}:
              </span> </label><br />
              <select onChange={handleClassChange} id="ClassChoise" name="ClassChoise">
                <option value=""> </option>
                <option value="B1">
                  {lang[langSwitch].bClass1 /*Б-1*/}
                </option>
                <option value="B2">
                  {lang[langSwitch].bClass2 /*Б-2*/}
                </option>
                <option value="B3">
                  {lang[langSwitch].bClass3 /*Б-3*/}
                </option>
                <option value="B4">
                  {lang[langSwitch].bClass4 /*Б-4*/}
                </option>

              </select>

            </div>

            {/* Обираем команду від якої заявляєм спортсменів */}
            <div className={s.teamChangeMenu}>
              <label htmlFor="status"><span className={s.teamChangeMenuSpan}>
                {lang[langSwitch].selectTeam /*Оберіть команду*/}:
              </span> </label><br />
              <select onChange={handleTeamChange} id="status" name="status">
                <option value=""> </option>
                <option value="region">
                  {lang[langSwitch].regionalOffice /* Обласний осередок*/}
                </option>
                <option value="club">
                  {lang[langSwitch].sportsClub /* Спортклуб*/}
                </option>
              </select>
            </div>
          </div>
          {/* "плитка спортсменів" */}

          <div className={s.gridContainer}>
            {athletes?(<>
            
           { athletes.map((athlete) => (
              <div key={athlete.id} className={selectedAthletes.includes(athlete.id) ? `${s.athleteBlock} ${s.athleteBlock1}` : s.athleteBlock}>
                <img

                  src={athlete.photo ? athlete.photo : state.noAvatarPhoto}
                  alt={`${athlete.secondName} ${athlete.personName}`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => toggleAthleteSelection(athlete)}
                />
                <div className={s.name}>{`${athlete.secondName} ${athlete.personName}`}</div>
              </div>
            ))}
            
          </>):null  }

          </div>

          {successMessage ?
            (<><br /><br /><div className={s.successMessage} onClick={() => { setSuccessMessage('') }}>
              {successMessage}</div><br /></>) :
            errorMessage ? (<><br /><br /><div className={s.errorMessage} onClick={() => { setErrorMessage('') }}>
              {errorMessage}</div><br /></>) : ''}


          <div className={isSmallScreen ? s.td1 : null}>
            <button type='button' onClick={() => { onClose(); setErrorMessage(''); setSelectedAthletes([]); setSuccessMessage(''); setErrorAthletesMain([]); setErrorAthletes([]); setErrorAthletes1([]); }}>
              {lang[langSwitch].close /*Закрити*/}
            </button>
            <button className={!isSmallScreen ? s.buttonR : null}
              type='button'
              onClick={() => {
                if (team !== '') {
                  setErrorAthletes([])
                  setSuccessMessage('')
                  setErrorAthletesMain([])
                  test(selectedAthletes, tournId)
                } else { setErrorAthletes(`<div>${lang[langSwitch].selectTeamForCompetetition /*Оберіть команду, за яку виступатимуть спортсмени!*/}</div>`) }
              }}>
              {lang[langSwitch].declare /*Заявити*/}
            </button>
          </div>
        </div>
      </div>
    </>) : null}
  </>);
}

export default Entry;
