import React, {  useState } from "react";
import s from '../adminCompetition/adminCompetition.module.css';
import state from "../../../state/state.js";
import axios from "axios";
import ConfirmButtons from "../adminCompetition/confirmButtons";
import AthleteItem from "../controlPanel/athleteItem";
import lang from "../../../state/language.js";



function RemoveAthleteFromClub({clubName,setRemoveAthlete,myTeamArray,deleteSwitcher,isSmallScreen,langSwitch}) {

  // const myTeamArray = state.athletes.filter(
  //   athlete => athlete.sportClub == clubName && athlete.status === 'Спортсмен')

  // Створіть стан для зберігання списку вибраних спортсменів
  const [selectedAthletes, setSelectedAthletes] = useState([]);// обрані атлети
  const [errorMessage, setErrorMessage] = useState(''); //повідомлення про помилки та успіх
  const [successMessage, setSuccessMessage] = useState('');
  const [data, setData] = useState('');
  const [messageText, setMessageText] = useState('');
  const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState(false);

  function saveResultConfirm() {
    if(selectedAthletes.length>0){
    setMessageText(
     lang[langSwitch].deleteSsersFromSportClub /*Видалити обраних користувачів з спортклубу?*/ 
    )
    openConfirmButtonsModal()
  }else{setErrorMessage(
  lang[langSwitch].notChosenCandidate /*Ви не обрали жодного кандидата!*/  
    )}
  }
  const confirmFunction = (messageText) => {
    if (messageText === 
      lang[langSwitch].deleteSsersFromSportClub /*Видалити обраних користувачів з спортклубу?*/
    ) {
      setCoach(selectedAthletes)
    }

  }
  const openConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(true); }
  const closeConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(false); }


  const handleTitleChange = (e) => { setData(e.target.value) };


  //   форма пошуку
  const athletes = myTeamArray.filter(athlete => {
    const fullName = `${athlete.secondName} ${athlete.personName}`.toLowerCase();
    const searchData = data.toLowerCase();

    return fullName.includes(searchData);
  });



  // Функція для додавання або видалення спортсмена зі списку (плитки) під час заявки
  const toggleAthleteSelection = (athlete) => {
    if (selectedAthletes.includes(athlete.id)) {
      // Спортсмен вже вибраний, видаляємо його зі списку
      setSelectedAthletes(selectedAthletes.filter((id) => id !== athlete.id));
    } else {
      // Спортсмен ще не вибраний, додаємо його до списку
      setSelectedAthletes([...selectedAthletes, athlete.id]);
    }
  };

  function setCoach(selectedAthletes) {
    let updateData = []
    for (let i = 0; i < selectedAthletes.length; i++) {
      updateData.push({
        id: selectedAthletes[i],
        sportClub: null
      })
    }

    axios.post(state.api.removeAthletesFromSportClub, {
      updateData: updateData
    })
      .then(response => {
        // console.log('response: ', response);
        // console.log('response.data: ', response.data);
        setSuccessMessage(
          lang[langSwitch].athletesExcludedFromClub /*Обрані спортсмени виключені з складу спортивного клубу*/
        )


        const updatedAthletes = state.athletes.map((athlete) => {
          // Перевірте, чи id співпадає з id в масиві updateData
          if (updateData.some((updateItem) => updateItem.id == athlete.id)) {
            // Якщо співпадає, оновіть поле status на 'Спортсмен'
            return { ...athlete, sportClub: null };
          }
          // Якщо id не співпадає, поверніть оригінальний об'єкт
          return athlete;
        });
        
        state.athletes =updatedAthletes
        setData('')
      })
      .catch(error => {
        // console.error('Помилка Axios-запиту, невдалось оновити статус тренера:', error);
        setErrorMessage(
          lang[langSwitch].serverRequestError /*Помилка запиту до сервера: */+error
               )
      });


  }

  return (<>
  {isSmallScreen?(<><br/><br/></>):null}
          <div className={s.h3}><b>
          {deleteSwitcher===1?
          lang[langSwitch].deleteAthleteFromClub /*Видалити спортсмена з клубу*/
          :
          lang[langSwitch].deleteCoachFromClub /*Видалити тренера з клубу*/
          }  
            </b></div>

      {successMessage ?
        (<><br /><br /><div className={s.successMessage} onClick={() => { setSuccessMessage('') }}>
          {successMessage}</div><br /></>) :
        errorMessage ? (<><br /><br /><div className={s.errorMessage} onClick={() => { setErrorMessage('') }}>
          {errorMessage}</div><br /></>) : ''}

          <div className={!isSmallScreen?s.contentTitleItem1:s.td1}>
        <div className={s.title1}>
          <label htmlFor="title"><span><b>
          {lang[langSwitch].startTypingName /*Почніть вводити призвище та ім'я*/} 
            </b></span> </label><br /><br />
          <input onChange={handleTitleChange} type="text" id="title" name="title" />

        </div>
        <div className={s.title3}><br /><br /><i>
         {lang[langSwitch].chosen /*Обрано*/}: 
          </i><b> {selectedAthletes.length} </b><i>
            {lang[langSwitch].ppl /* чол.*/} 
             </i></div>

        <div className={s.title7}>
        <div className={!isSmallScreen?s.switchers:s.switchersSmall}>


            <button className={!isSmallScreen?s.switch1L:s.switch1LSmall} type='button' onClick={() => { saveResultConfirm(); }}>
            {deleteSwitcher===1?
            lang[langSwitch].deleteAthleteFromClubShort /*Видалити спортсмена з клубу*/
            :
            lang[langSwitch].deleteCoachFromClubShort /*Видалити тренера з клубу*/
            } 
            </button>


            <button className={s.switch6L} type='button' onClick={() => {
              setRemoveAthlete(false);
              setErrorMessage('');
              setSuccessMessage('');
              setSelectedAthletes([])
            }}>
            {lang[langSwitch].close /*Закрити*/}
            </button>

          </div>
        </div>

      </div><br />



      {/* "плитка спортсменів" */}
    
<AthleteItem athletes={athletes} 
        toggleAthleteSelection={toggleAthleteSelection} 
        selectedAthletes={selectedAthletes} 
        langSwitch={langSwitch}/>

      <ConfirmButtons
        isOpen={isConfirmButtonsModalOpen}
        onClose={closeConfirmButtonsModal}
        confirmFunction={confirmFunction}
        messageText={messageText}
        langSwitch={langSwitch}
      />


  </>)
}


export default RemoveAthleteFromClub