import React, { useState } from "react";
import s from '../adminCompetition/adminCompetition.module.css';
import state from "../../../state/state.js";
import axios from "axios";
import ConfirmButtons from "../adminCompetition/confirmButtons";
import AthleteItemForScale from "./athleteItemForScale";
import lang from "../../../state/language.js";
import indexedDBConnection from "../../../state/indexedDBConnection.js";




function AthleteScale({ setScaleTeam,clubName, competetionAthlets,isSmallScreen,langSwitch}) {
 let athletesData
 if(!competetionAthlets){
 athletesData = state.athletes.filter(athlete =>athlete.sportClub ===clubName);
 }  
 else{athletesData=competetionAthlets} 


  // Створіть стан для зберігання списку вибраних спортсменів
  
  const [errorMessage, setErrorMessage] = useState(''); //повідомлення про помилки та успіх
  const [successMessage, setSuccessMessage] = useState('');
  const [updateData, setUpdateData] = useState([]);
  const [messageText, setMessageText] = useState('');
  const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState(false);

  function saveResultConfirm() {
   
    setMessageText(
      lang[langSwitch].changeWeightData //Змінити дані ваги та зросту спортсменів?
    )
    openConfirmButtonsModal()
  
  }
  const confirmFunction = (messageText) => {
    if (messageText === 
      lang[langSwitch].changeWeightData //Змінити дані ваги та зросту спортсменів?
    ) {
      update(updateData)
    }

  }
  const openConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(true); }
  const closeConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(false); }


 const athletes = athletesData
 
  function update(updateData) {
  
    axios.post(state.api.setScaleAthletes, {
      updateData: updateData
    })
      .then(response => {
      
        setSuccessMessage(
          lang[langSwitch].successfullyUpdatedAnthropometrics //Антропометрику ваших спортсменів оновлено
        )


        const updatedAthletes = state.athletes.map((athlete) => {
          // Перевірте, чи id співпадає з id в масиві updateData
          if (updateData.some((updateItem) => updateItem.id == athlete.id)) {
            // Визначте updateItem тут перед використанням
            const updateItem = updateData.find((item) => item.id == athlete.id);
        
            // Якщо співпадає, оновіть поле weiht і height зі значень updateItem
            return { ...athlete, weiht: updateItem.weiht, height: updateItem.height };
          }
          // Якщо id не співпадає, поверніть оригінальний об'єкт
          return athlete;
        });
      
        state.athletes = updatedAthletes;
        indexedDBConnection.saveData ('athletes', state.athletes)
      
        setUpdateData([])


      })
      .catch(error => {
        // console.error('Помилка Axios-запиту, невдалось оновити статус тренера:', error);
        setErrorMessage(
          lang[langSwitch].serverRequestError, //Помилка запиту до сервера:
          error
        )
      });
  }
  athletes.sort((a, b) => {
    const lastNameComparison = a.secondName.localeCompare(b.secondName);
  
    if (lastNameComparison !== 0) {
      return lastNameComparison;  // Якщо прізвища відрізняються, сортуємо за прізвищем.
    } 
  
    return a.personName.localeCompare(b.personName);  // Якщо прізвища однакові, сортуємо за ім'ям.
  });
  
  return (<>
    
      <div className={s.h1}> <br/>
        {clubName&&!competetionAthlets? <b>
          {lang[langSwitch].weighTeam}{/*Зважити команду*/}          
          </b>:<b>
          {lang[langSwitch].titleChangeAnthropometrics}{/*Змініть антропометрику для переносу спортсменів в іншу категорію*/}
            </b>}
        <br/>&nbsp;
     </div>

      {successMessage ?
        (<><br /><br /><div className={s.successMessage} onClick={() => { setSuccessMessage('') }}>
          {successMessage}</div><br /></>) :
        errorMessage ? (<><br /><br /><div className={s.errorMessage} onClick={() => { setErrorMessage('') }}>
          {errorMessage}</div><br /></>) : ''}

         
        <div className={!isSmallScreen?s.switchers:s.switchersSmall}>


            <button className={s.switch1L} type='button' onClick={() => { saveResultConfirm(); }}>
            {lang[langSwitch].saveInDB}{/* Зберегти в БД*/}
            </button>


            <button className={s.switch4L} type='button' onClick={() => {
              setScaleTeam(false);
              setErrorMessage('');
              setSuccessMessage('');
            }}>
              {lang[langSwitch].close}{/*Закрити*/}
              </button>

          </div>
       
      <br />



      {/* "плитка спортсменів" */}
      <div className={s.gridContainerForScaile} key='AthleteItemForScale'>
{athletes.map((item)=>{

  return(<div key={item.id}>
  <AthleteItemForScale athlete={item} updateData={updateData}
     setUpdateData={setUpdateData} langSwitch={langSwitch} />
    </div>)
})}
      
</div>

      <ConfirmButtons
        isOpen={isConfirmButtonsModalOpen}
        onClose={closeConfirmButtonsModal}
        confirmFunction={confirmFunction}
        messageText={messageText}
        langSwitch={langSwitch}
      />


  </>)
}


export default AthleteScale