import s from './grids.module.css'
import state from '../../../../state/state.js'
import GridFooter from './GridFooter.js';
import lang from '../../../../state/language.js';

function GridForAthlets16(props) {
	const poomse=props.competition.poomse==1?true:false
	const athleteList = props.grid.athleteList
	const {
		score1 = null, score2 = null, score3 = null, score4 = null, score5 = null,
		score6 = null, score7 = null, score8 = null, score9 = null, score10 = null,
		score11 = null, score12 = null, score13 = null, score14 = null, score15 = null
	} = props.grid.battleScores || {}
	const {
		winner = null, winner1 = null, winner2 = null, winner3 = null, winner4 = null,
		winner5 = null, winner6 = null, winner7 = null, winner8 = null, winner9 = null,
		winner10 = null, winner11 = null, winner12 = null, winner13 = null, winner14 = null
	} = props.grid.winners || {}
	const winnerAthletes = props.grid.winnerAthletes ? props.grid.winnerAthletes : null

	return (<>
		<div className={s.mainDiv}>
			<div className={s.title}>
				<h3>{props.competition.title}</h3>
				<h3 >
  {props.competition.dateStart&&props.competition.dateFinish?(<>
  <u><b>{state.formatDate(props.competition.dateStart).slice(0, 2)}</b>- 
        <b>{state.formatDate(props.competition.dateFinish)} р.</b></u>         
      </>):(<>
    <u><b>  {props.competition.dateOfCompetition}р.</b></u> 
      </>)}
        <br/></h3>
				<h4 id={props.id}>
				{poomse?lang.poomsaeCatUkr(props.langSwitch,props.grid.category):
	state.weightCatUkr (props.grid.category,props.langSwitch, true)}
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					Корт: {props.cort}</h4>
			</div>
			<table width="600" >
				<tbody>

					<tr>
						<td className={s.blueLeft}><table><tbody>
							<tr>
								<td>
									{/* <div className={s.divAthleteInGrid}>{state.athleteName(athlete[0][1]['id'])}<br/><span>{athlete[0][1]['team']}</span></div> */}

									{/* Перший поєдинок спортсменів */}
									<div className={s.divAthleteInGrid}>
										{ athleteList[0].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[0].teamPoomsae)}<i>{athleteList[0]['team']}</i></>) : (<>{state.athleteName(athleteList[0]['id'])}<br /><i>{athleteList[0]['team']}</i></>) }


									</div>
								</td>
								<td className={s.figthLeft}>{props.cort}.{props.battleNumbers[0]}</td>
							</tr>
							</tbody></table></td>
						<td></td>
						<td >&nbsp;</td>
						<td ></td>
						<td ></td>
						<td></td>
						<td className={s.blueRight}><table><tbody>
							<tr>
								<td className={s.figthRight}>{props.cort}.{props.battleNumbers[1]}</td>
								<td className={s.sportRight}>
									{/* <div className={s.divAthleteInGrid}>{state.athleteName(athlete[4][1]['id'])}<br/><span>{athlete[4][1]['team']}</span></div> */}

									{/* Перший поєдинок спортсменів */}
									<div className={s.divAthleteInGrid}>
										{ athleteList[8].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[8].teamPoomsae)}<i>{athleteList[8]['team']}</i></>) : (<>{state.athleteName(athleteList[8]['id'])}<br /><i>{athleteList[8]['team']}</i></>) }


									</div>

								</td>
							</tr>
							</tbody></table></td>
					</tr>

					<tr>
						<td className={s.redLeft}>
							{/* <div className={s.divAthleteInGrid}>{state.athleteName(athlete[0][2]['id'])}<br/><span>{athlete[0][2]['team']}</span></div> */}

							{/* Перший поєдинок спортсменів */}
							<div className={s.divAthleteInGrid}>
								{ athleteList[1].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[1].teamPoomsae)}<i>{athleteList[1]['team']}</i></>) : (<>{state.athleteName(athleteList[1]['id'])}<br /><i>{athleteList[1]['team']}</i></>) }


							</div>
						</td>
						<td className={s.blueLeft}><table><tbody>
							<tr>
								<td>{/* Бої , просування по сітці */}
									{winner7 !== null ? (<>
										<div className={s.divAthleteInGrid} >
											{winner7.teamPoomsae?state.poomsaeTeam(winner7.teamPoomsae):state.athleteNameShort(winner7.id)}
 </div>
									</>) :
										(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>)}
									<div className={s.battleScore}> {/* ПОЧАТОК рахунок з полем вводу*/}
										{score8 ? score8 : null}
									</div> {/* КІНЕЦЬ рахунок з полем вводу*/}
								</td>
								<td className={s.figthLeft}>{props.cort}.{props.battleNumbers[8]}</td>
							</tr>
							</tbody></table></td><td >&nbsp;</td>
						<td >
							{/* Переможець  */}
							{winner !== null ? (<>
								<div className={s.sportCenter}>
									<div className={s.divAthleteInGrid} >

										<div><img className={s.goldCup} alt='фото' src='/img/main/goldCup1.png' /></div>
										{winner.teamPoomsae?state.poomsaeTeam(winner.teamPoomsae):state.athleteNameShort(winner.id)}

										<div className={s.battleScore}>{score1 ? score1 : null}</div>{/*рахунок поєдинка*/}
									</div> </div>
							</>) :

								<div className={s.figthCenter}>{props.cort}.{props.battleNumbers[14]}</div>
							}
						</td><td ></td><td className={s.blueRight}><table><tbody>
							<tr>
								<td className={s.figthRight}>{props.cort}.{props.battleNumbers[9]}</td>
								<td className={s.sportRight}>
									{/* Бої , просування по сітці */}
									{winner11 !== null ? (<>
										<div className={s.divAthleteInGrid} >
											{winner11.teamPoomsae?state.poomsaeTeam(winner11.teamPoomsae):state.athleteNameShort(winner11.id)}
 </div>
									</>) :
										(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>)}
									<div className={s.battleScore}> {/* ПОЧАТОК рахунок з полем вводу*/}
										{score12 ? score12 : null}
									</div> {/* КІНЕЦЬ рахунок з полем вводу*/}
								</td>
							</tr>
							</tbody></table></td>
						<td className={s.redRight}>
							{/* <div className={s.divAthleteInGrid}>{state.athleteName(athlete[4][2]['id'])}<br/><span>{athlete[4][2]['team']}</span></div> */}

							{/* Перший поєдинок спортсменів */}
							<div className={s.divAthleteInGrid}>
								{ athleteList[9].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[9].teamPoomsae)}<i>{athleteList[9]['team']}</i></>) : (<>{state.athleteName(athleteList[9]['id'])}<br /><i>{athleteList[9]['team']}</i></>) }


							</div>
						</td>
					</tr>

					<tr>
						<td></td>
						<td className={s.rightBordBlue}></td>
						<td></td>

						<td ></td>
						<td ></td>
						<td className={s.leftBordBlue}></td>
						<td></td>
					</tr>

					<tr>
						<td className={s.blueLeft}><table><tbody>
							<tr>
								<td>
									{/* <div className={s.divAthleteInGrid}>{state.athleteName(athlete[1][1]['id'])}<br/><span>{athlete[1][1]['team']}</span></div> */}

									{/* Перший поєдинок спортсменів */}
									<div className={s.divAthleteInGrid}>
										{ athleteList[2].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[2].teamPoomsae)}<i>{athleteList[2]['team']}</i></>) : (<>{state.athleteName(athleteList[2]['id'])}<br /><i>{athleteList[2]['team']}</i></>) }


									</div>
								</td>
								<td className={s.figthLeft}>{props.cort}.{props.battleNumbers[2]}</td>
							</tr>
							</tbody></table></td>
						<td className={s.redLeft}>
							{/* Бої , просування по сітці */}
							{winner8 !== null ? (<>
								<div className={s.divAthleteInGrid} >
									{winner8.teamPoomsae?state.poomsaeTeam(winner8.teamPoomsae):state.athleteNameShort(winner8.id)}
 </div>
							</>) :
								(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>)}
							<div className={s.battleScore}> {/* ПОЧАТОК рахунок з полем вводу*/}
								{score9 ? score9 : null}
							</div> {/* КІНЕЦЬ рахунок з полем вводу*/}
						</td>
						<td className={s.blueLeft}><table><tbody>
							<tr>
								<td>
									{/* Бої , просування по сітці */}
									{winner3 !== null ? (<>
										<div className={s.divAthleteInGrid} >
											{winner3.teamPoomsae?state.poomsaeTeam(winner3.teamPoomsae):state.athleteNameShort(winner3.id)}
 </div>
									</>) :
										(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>)}
									<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
										{score4 ? score4 : null}
									</div>{/*КІНЕЦЬ рахунок з полем вводу*/}
								</td>
								
							</tr>
							</tbody></table></td>

						<td ></td>
						<td className={s.blueRight}><table><tbody>
							<tr>
								
								<td className={s.sportRight}>
									{/* Бої , просування по сітці */}
									{winner5 !== null ? (<>
										<div className={s.divAthleteInGrid} >
											{winner5.teamPoomsae?state.poomsaeTeam(winner5.teamPoomsae):state.athleteNameShort(winner5.id)}
 </div>
									</>) :
										(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>)}
									<div className={s.battleScore}> {/* ПОЧАТОК рахунок з полем вводу*/}
										{score6 ? score6 : null}
									</div> {/* КІНЕЦЬ рахунок з полем вводу*/}
								</td>
							</tr>
							</tbody></table></td>
						<td className={s.redRight}><span className={s.sportRight}>
							{/* Бої , просування по сітці */}
							{winner12 !== null ? (<>
								<div className={s.divAthleteInGrid} >
									{winner12.teamPoomsae?state.poomsaeTeam(winner12.teamPoomsae):state.athleteNameShort(winner12.id)}
 </div>
							</>) :
								(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>)}
						</span>
							<div className={s.battleScore}> {/* ПОЧАТОК рахунок з полем вводу*/}
								{score13 ? score13 : null}
							</div> {/* КІНЕЦЬ рахунок з полем вводу*/}
						</td>
						<td className={s.blueRight}><table><tbody>
							<tr>
								<td className={s.figthRight}>{props.cort}.{props.battleNumbers[3]}</td>
								<td className={s.sportRight}>
									{/* <div className={s.divAthleteInGrid}>{state.athleteName(athlete[5][1]['id'])}<br/><span>{athlete[5][1]['team']}</span></div> */}

									{/* Перший поєдинок спортсменів */}
									<div className={s.divAthleteInGrid}>
										{ athleteList[10].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[10].teamPoomsae)}<i>{athleteList[10]['team']}</i></>) : (<>{state.athleteName(athleteList[10]['id'])}<br /><i>{athleteList[10]['team']}</i></>) }


									</div>
								</td>
							</tr>
							</tbody></table></td>
					</tr>
					<tr>
						<td className={s.redLeft}>
							{/* <div className={s.divAthleteInGrid}>{state.athleteName(athlete[1][2]['id'])}<br/><span>{athlete[1][2]['team']}</span></div> */}

							{/* Перший поєдинок спортсменів */}
							<div className={s.divAthleteInGrid}>
								{ athleteList[3].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[3].teamPoomsae)}<i>{athleteList[3]['team']}</i></>) : (<>{state.athleteName(athleteList[3]['id'])}<br /><i>{athleteList[3]['team']}</i></>) }


							</div>
						</td>
						<td>&nbsp;</td>
						<td className={s.rightBordBlue}>
						<div className={s.figthLeft}>{props.cort}.{props.battleNumbers[12]}</div>
						</td>

						<td className={s.nextRed}><div className={s.sportCenter}>
							{/* Бої , просування по сітці */}
							{winner1 !== null ? (<>
								<div className={s.divAthleteInGrid} >
									{winner1.teamPoomsae?state.poomsaeTeam(winner1.teamPoomsae):state.athleteNameShort(winner1.id)}
 </div>
							</>) :
								(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>)}
							<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
								{score2 ? score2 : null}
							</div>{/*КІНЕЦЬ рахунок з полем вводу*/}
						</div></td>
						<td className={s.leftBordBlue}>
						<div className={s.figthRight}>{props.cort}.{props.battleNumbers[13]}</div>
						</td>
						<td >&nbsp;</td>
						<td className={s.redRight}>
							{/* <div className={s.divAthleteInGrid}>{state.athleteName(athlete[5][2]['id'])}<br/><span>{athlete[5][2]['team']}</span></div> */}

							{/* Перший поєдинок спортсменів */}
							<div className={s.divAthleteInGrid}>
								{ athleteList[11].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[11].teamPoomsae)}<i>{athleteList[11]['team']}</i></>) : (<>{state.athleteName(athleteList[11]['id'])}<br /><i>{athleteList[11]['team']}</i></>) }


							</div>
						</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td ></td>
						<td></td>
						<td></td>
					</tr>

					<tr>
						<td className={s.blueLeft}><table><tbody>
							<tr>
								<td>
									{/* <div className={s.divAthleteInGrid}>{state.athleteName(athlete[2][1]['id'])}<br/><span>{athlete[2][1]['team']}</span></div> */}

									{/* Перший поєдинок спортсменів */}
									<div className={s.divAthleteInGrid}>
										{ athleteList[4].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[4].teamPoomsae)}<i>{athleteList[4]['team']}</i></>) : (<>{state.athleteName(athleteList[4]['id'])}<br /><i>{athleteList[4]['team']}</i></>) }


									</div>
								</td>
								<td className={s.figthLeft}>{props.cort}.{props.battleNumbers[4]}</td>
							</tr>
							</tbody></table></td>
						<td>&nbsp;</td>
						<td className={s.rightBordRed}>&nbsp;</td>

						<td className={s.next}><div className={s.sportCenter}>
							{/* Бої , просування по сітці */}
							{winner2 !== null ? (<>
								<div className={s.divAthleteInGrid} >
									{winner2.teamPoomsae?state.poomsaeTeam(winner2.teamPoomsae):state.athleteNameShort(winner2.id)}
 </div>
							</>) :
								(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>)}
							<div className={s.battleScore}> {/* ПОЧАТОК рахунок з полем вводу*/}
								{score3 ? score3 : null}
							</div> {/* КІНЕЦЬ рахунок з полем вводу*/}
						</div></td>
						<td className={s.leftBordRed}>&nbsp;</td>
						<td>&nbsp;</td>
						<td className={s.blueRight}><table><tbody>
							<tr>
								<td className={s.figthRight}>{props.cort}.{props.battleNumbers[5]}</td>
								<td className={s.sportRight}>
									{/* <div className={s.divAthleteInGrid}>{state.athleteName(athlete[6][1]['id'])}<br/><span>{athlete[6][1]['team']}</span></div> */}

									{/* Перший поєдинок спортсменів */}
									<div className={s.divAthleteInGrid}>
										{ athleteList[12].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[12].teamPoomsae)}<i>{athleteList[12]['team']}</i></>) : (<>{state.athleteName(athleteList[12]['id'])}<br /><i>{athleteList[12]['team']}</i></>) }


									</div>
								</td>
							</tr>
							</tbody></table></td>
					</tr>
					<tr>
						<td className={s.redLeft}>
							{/* <div className={s.divAthleteInGrid}>{state.athleteName(athlete[2][2]['id'])}<br/><span>{athlete[2][2]['team']}</span></div> */}

							{/* Перший поєдинок спортсменів */}
							<div className={s.divAthleteInGrid}>
								{ athleteList[5].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[5].teamPoomsae)}<i>{athleteList[5]['team']}</i></>) : (<>{state.athleteName(athleteList[5]['id'])}<br /><i>{athleteList[5]['team']}</i></>) }


							</div>
						</td>
						<td className={s.blueLeft}><table><tbody>
							<tr>
								<td>
									{/* Бої , просування по сітці */}
									{winner9 !== null ? (<>
										<div className={s.divAthleteInGrid} >
											{winner9.teamPoomsae?state.poomsaeTeam(winner9.teamPoomsae):state.athleteNameShort(winner9.id)}
 </div>
									</>) :
										(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>)}
									<div className={s.battleScore}> {/* ПОЧАТОК рахунок з полем вводу*/}
										{score10 ? score10 : null}
									</div> {/* КІНЕЦЬ рахунок з полем вводу*/}
								</td>
								<td className={s.figthLeft}>{props.cort}.{props.battleNumbers[10]}</td>
							</tr>
							</tbody></table></td>
						<td className={s.redLeft}><table><tbody>
							<tr>
								<td>
									{/* Бої , просування по сітці */}
									{winner4 !== null ? (<>
										<div className={s.divAthleteInGrid} >
											{winner4.teamPoomsae?state.poomsaeTeam(winner4.teamPoomsae):state.athleteNameShort(winner4.id)}
 </div>
									</>) :
										(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>)}
									<div className={s.battleScore}> {/*ПОЧАТОК рахунок з полем вводу*/}
										{score5 ? score5 : null}
									</div>{/*КІНЕЦЬ рахунок з полем вводу*/}
								</td>
								<td className={s.colorLeft}>Син.</td>
							</tr>
							</tbody></table></td>

						<td ></td>
						<td className={s.redRight}><table><tbody>
							<tr>
								<td className={s.colorRight}>Чер.</td>
								<td className={s.sportRight}>
									{/* Бої , просування по сітці */}
									{winner6 !== null ? (<>
										<div className={s.divAthleteInGrid} >
											{winner6.teamPoomsae?state.poomsaeTeam(winner6.teamPoomsae):state.athleteNameShort(winner6.id)}
 </div>
									</>) :
										(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>)}
									<div className={s.battleScore}> {/* ПОЧАТОК рахунок з полем вводу*/}
										{score7 ? score7 : null}
									</div> {/* КІНЕЦЬ рахунок з полем вводу*/}
								</td>
							</tr>
							</tbody></table></td>
						<td className={s.blueRight}><table><tbody>
							<tr>
								<td className={s.figthRight}>{props.cort}.{props.battleNumbers[11]}</td>
								<td className={s.sportRight}>
									{/* Бої , просування по сітці */}
									{winner13 !== null ? (<>
										<div className={s.divAthleteInGrid} >
											{winner13.teamPoomsae?state.poomsaeTeam(winner13.teamPoomsae):state.athleteNameShort(winner13.id)}
 </div>
									</>) :
										(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>)}
									<div className={s.battleScore}> {/* ПОЧАТОК рахунок з полем вводу*/}
										{score14 ? score14 : null}
									</div> {/* КІНЕЦЬ рахунок з полем вводу*/}
								</td>
							</tr>
							</tbody></table></td>
						<td className={s.redRight}>
							{/* <div className={s.divAthleteInGrid}>{state.athleteName(athlete[6][2]['id'])}<br/><span>{athlete[6][2]['team']}</span></div> */}

							{/* Перший поєдинок спортсменів */}
							<div className={s.divAthleteInGrid}>

								{ athleteList[13].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[13].teamPoomsae)}<i>{athleteList[13]['team']}</i></>) : (<>{state.athleteName(athleteList[13]['id'])}<br /><i>{athleteList[13]['team']}</i></>) }


							</div>
						</td>
					</tr>


					<tr>
						<td></td><td className={s.rightBordRed}></td><td></td>
						<td ></td><td></td><td className={s.leftBordRed}></td><td></td>
					</tr>

					<tr>
						<td className={s.blueLeft}><table><tbody>
							<tr>
								<td>
									{/* <div className={s.divAthleteInGrid}>{state.athleteName(athlete[3][1]['id'])}<br/><span>{athlete[3][1]['team']}</span></div> */}

									{/* Перший поєдинок спортсменів */}
									<div className={s.divAthleteInGrid}>
										{ athleteList[6].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[6].teamPoomsae)}<i>{athleteList[6]['team']}</i></>) : (<>{state.athleteName(athleteList[6]['id'])}<br /><i>{athleteList[6]['team']}</i></>) }


									</div>
								</td>
								<td className={s.figthLeft}>{props.cort}.{props.battleNumbers[6]}</td>
							</tr>
							</tbody></table></td>
						<td className={s.redLeft}>
							{/* Бої , просування по сітці */}
							{winner10 !== null ? (<>
								<div className={s.divAthleteInGrid} >
									{winner10.teamPoomsae?state.poomsaeTeam(winner10.teamPoomsae):state.athleteNameShort(winner10.id)}
 </div>
							</>) :
								(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>)}
							<div className={s.battleScore}> {/* ПОЧАТОК рахунок з полем вводу*/}
								{score11 ? score11 : null}
							</div> {/* КІНЕЦЬ рахунок з полем вводу*/}
						</td>
						<td>&nbsp;</td>

						<td ></td>
						<td>&nbsp;</td>
						<td className={s.redRight}><span className={s.sportRight}>
							{/* Бої , просування по сітці */}
							{winner14 !== null ? (<>
								<div className={s.divAthleteInGrid} >
									{winner14.teamPoomsae?state.poomsaeTeam(winner14.teamPoomsae):state.athleteNameShort(winner14.id)}
</div>
							</>) :
								(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>)}
						</span>
							<div className={s.battleScore}> {/* ПОЧАТОК рахунок з полем вводу*/}
								{score15 ? score15 : null}
							</div> {/* КІНЕЦЬ рахунок з полем вводу*/}
						</td>
						<td className={s.blueRight}><table><tbody>
							<tr>
								<td className={s.figthRight}>{props.cort}.{props.battleNumbers[7]}</td>
								<td className={s.sportRight}>
									{/* <div className={s.divAthleteInGrid}>{state.athleteName(athlete[7][1]['id'])}<br/><span>{athlete[7][1]['team']}</span></div> */}

									{/* Перший поєдинок спортсменів */}
									<div className={s.divAthleteInGrid}>
										{ athleteList[14].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[14].teamPoomsae)}<i>{athleteList[14]['team']}</i></>) : (<>{state.athleteName(athleteList[14]['id'])}<br /><i>{athleteList[14]['team']}</i></>) }


									</div>
								</td>
							</tr>
							</tbody></table></td>
					</tr>
					<tr>
						<td className={s.redLeft}>
							{/* <div className={s.divAthleteInGrid}>{state.athleteName(athlete[3][2]['id'])}<br/><span>{athlete[3][2]['team']}</span></div> */}

							{/* Перший поєдинок спортсменів */}
							<div className={s.divAthleteInGrid}>
								{ athleteList[7].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[7].teamPoomsae)}<i>{athleteList[7]['team']}</i></>) : (<>{state.athleteName(athleteList[7]['id'])}<br /><i>{athleteList[7]['team']}</i></>) }


							</div>

						</td>
						<td></td>
						<td >&nbsp;</td>
						<td ></td>
						<td ></td>
						<td></td>
						<td className={s.redRight}>
							{/* <div className={s.divAthleteInGrid}>{state.athleteName(athlete[7][2]['id'])}<br/><span>{athlete[7][2]['team']}</span></div> */}

							{/* Перший поєдинок спортсменів */}
							<div className={s.divAthleteInGrid}>
								{ athleteList[15].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[15].teamPoomsae)}<i>{athleteList[15]['team']}</i></>) : (<>{state.athleteName(athleteList[15]['id'])}<br /><i>{athleteList[15]['team']}</i></>) }


							</div>
						</td>
					</tr>

					<tr>
						<td>&nbsp;</td>
						<td ></td>
						<td ></td>
						<td ></td>
						<td></td>
					</tr>


				</tbody>
			</table>

			{/* ////////////////////////////////////////////////////// */}
			<GridFooter competition={props.competition} winnerAthletes={winnerAthletes} />
		</div>
	</>)
}

export default GridForAthlets16