import React, { useState } from 'react';
import s from '../result.module.css';
import lang from '../../../../state/language';

function FoodCarts({competition,setSwitcher,langSwitch}) {

    const handleTitleChange = (e) => { setData(e.target.value) };
    const [data, setData] = useState('');
    const titleOptions = [
        lang[langSwitch].breakfast /*Сніданок*/, 
        lang[langSwitch].lunch /*Обід*/, 
        lang[langSwitch].dinner /*Вечеря*/, 
        lang[langSwitch].drinks /*Напої*/
    ];

    function dataForTable() {
        const rows = [];

        for (let i = 0; i < 8; i++) {
            const cells = [];

            for (let j = 0; j < 4; j++) {
                cells.push(<td key={j}>
<div className={s.permitTitle}>{competition.title}</div>

<div className={s.containerFood}>
    <div className={s.itemResults1}>
    <div className={s.foodTitile}>  {data}</div>    
    </div>
     <div className={s.itemResults2}>
     <span className={s.infoDescription }>
   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
   {lang[langSwitch].numberOfPortions /*кіл-ть порцій*/}
   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
     </div>
  
    
    </div>
<div >
    <span className={s.infoDescription }>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
   {lang[langSwitch].surname /*Призвище*/}
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
    &nbsp;&nbsp;&nbsp;&nbsp;
        <span className={s.infoDescription }>
   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
   {lang[langSwitch].signature /*Підпис*/}     
   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
    </div>

                </td>);
            }

            rows.push(<tr key={i}>{cells}</tr>);
        }

        return rows;
    }


    return (<>
   <div className={s.resultTitle}>
    <div className={s.h3}> 
        {lang[langSwitch].foodStampsFull /*  Талони на харчування*/} 
        </div>


   <div style={{paddingLeft:'150px'}}>
            <label htmlFor="title"><b>
             {lang[langSwitch].сhooseTypeOfCoupons/*Оберіть тип талонів*/}:
                </b></label><br/>
            <select style={{marginLeft:'40px'}} 
            id="title" value={data} onChange={handleTitleChange}>
                <option value=""></option>
                {titleOptions.map((option, index) => (
                    <option key={index} value={option}>
                        {option}
                    </option>
                ))}
            </select>
 </div>    </div>
 

        <div className={s.mainDivForPrint}>
            <table className={s.permitTable}>
                <tbody>
                    {dataForTable()}
                </tbody>
            </table>
        </div>
        <br/>

       


        <div className={s.resultTitle}>
        <div className={s.containerBadgeTop}>
<div className={s.itemResultsFooter1}>
<button type='button'  onClick={()=>{window.print()}}>
{lang[langSwitch].print /*Друкувати*/}
</button>
</div>

<div className={s.itemResultsFooter2}>

    <button  type='button' onClick={()=>{setSwitcher(0)}}>
    {lang[langSwitch].close /*Закрити*/}
    </button>
        
</div></div>
</div>

        
   </> );
}

export default FoodCarts;
