import React from 'react';
import s from '../adminCompetition/adminCompetition.module.css'
import lang from '../../../state/language';
import state from '../../../state/state';

function CategoriesClassesCell ({categoriesData, langSwitch, openCategoryCellModal, poomse,scale}) {

  return (
    <div className={s.gridContainerForCategories}>
  
 
      
        
    {categoriesData.map((cat,index) => (
      <div key={cat.category+index} className={s.categoryBlock}>
        <div className={s.boxTitile}  onClick={()=>{openCategoryCellModal(cat)}}
         style={{ cursor: 'pointer' }}>
        <div className={s.categoryBlockTitle}>
          {poomse?lang.poomsaeCatUkr(langSwitch,cat.category):
          scale?
            state.weightCatUkr(cat.category, langSwitch, true)
          :
            state.weightCatUkr(cat.category)            
          }

          </div>
<div className={s.region}>
{cat.athletes.length%10===1?
lang[langSwitch].declared1 /*Заявлений*/+': '
:
lang[langSwitch].declared /*Заявлено*/+': '
}
<b>{cat.athletes.length}</b>
{cat.athletes.length%10===0?
' '+lang[langSwitch].participants /*учасників*/
:''}
{cat.athletes.length%10===1&&cat.athletes.length!==11?
' '+lang[langSwitch].participant /*учасник*/
:''}
{(cat.athletes.length % 10 >= 2 && cat.athletes.length % 10 <= 4) && (cat.athletes.length % 100 < 10 || cat.athletes.length % 100 >= 20) ? 
' '+lang[langSwitch].participants1 /*учасникa*/
: ''}

{cat.athletes.length%10>4||cat.athletes.length===11?
' '+lang[langSwitch].participants /*учасників*/
:''}
<br/>&nbsp;</div>
      </div>

      </div>
    ))}
  </div>
  );
};

export default CategoriesClassesCell;