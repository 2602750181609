import s from '../adminCompetition/adminCompetition.module.css';
import lang from "../../../state/language.js";
import state from '../../../state/state.js';

function CoupleSell({ athleteData, isSmallScreen, langSwitch }) {
   
    return (<>
        {athleteData.map((athlete) => {
            return (
                    <div className={s.coupelCellImg} key={athlete.id}>
                        <img src={athlete.photo ? athlete.photo : state.noAvatarPhoto}
                            alt={state.myCoach(athlete.id)} />
                    <div className={s.coupelCellName}>{state.athleteName(athlete.id)}</div>
                    <div className={s.coupelCellName}>{athlete.dan}</div>
                    </div>                
            )
        })}
    </>)
}

export default CoupleSell