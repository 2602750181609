import React from 'react';
import s from '../result.module.css';
import lang from '../../../../state/language';

function FreestalNotes({setSwitcher,langSwitch}) {

   function table(){
      return(<> <table className={s.judgesNotesTable}>
         <thead>
         <tr>
            <td colSpan='3' rowSpan='3'>
               <img className={s.judgesNotesLogo} src='/img/judgesNotes/logoForJudgesNotes.jpg' />
            </td>
            <td colSpan='12'>
               <div className={s.judgesNotesTitle}>Суддівський нотатник</div>  
               <div className={s.judgesNotesType}> Фрістайл Пумсе</div>
            </td>
         </tr>
         <tr>
            <td colSpan='6' style={{borderBottom:'none'}}>
               <b>Обов'язкові стійки</b>
            </td>
            <td colSpan='4'><b>Корт №</b></td>
            <td colSpan='2'>&nbsp;</td>
         </tr>
         <tr>
            <td colSpan='2' style={{borderTop:'none', borderRight:'none'}}>
               Хакдарі Согі<br/> 
               <div className={s.checkmarkBox}>&nbsp;</div>
            </td>
            <td colSpan='2' style={{borderTop:'none', borderRight:'none', borderLeft:'none'}}>
               Бом Согі<br/> 
               <div className={s.checkmarkBox}>&nbsp;</div>
            </td>
            <td colSpan='2'style={{borderTop:'none', borderLeft:'none'}}>
               Двіткубі Согі<br/> 
               <div className={s.checkmarkBox}>&nbsp;</div>
            </td>
            <td colSpan='4'><b>Виступ №</b></td>
            <td colSpan='2'>&nbsp;</td>
         </tr>
         </thead>
   
         <tbody>
            <tr>
               <td rowSpan='4' className={s.verticalText}>Категорія оцінки</td>
               <td rowSpan='4'  colSpan='2'><b>Підкатегорія елементів</b></td>
               <td colSpan='11'><b>Розподіл балів</b></td>
               <td rowSpan='4' className={s.verticalText} style={{width:'50px'}}>Оцінка</td>
            </tr>
            <tr>
               <td ><b>Дуже<br/>погано</b></td>
               <td  colSpan='2'><b>Погано</b></td>
               <td  colSpan='3'><b>Добре</b></td>
               <td  colSpan='2'><b>Дуже<br/>добре</b></td>
               <td  colSpan='2'><b>Відмінно</b></td>
               <td ><b>Бездоганно</b></td>
            </tr>
            <tr>
               <td>0.0</td>
               <td>0.1</td>
               <td>0.2</td>
               <td>0.3</td>
               <td>0.4</td>
               <td>0.5</td>
               <td>0.6</td>
               <td>0.7</td>
               <td>0.8</td>
               <td>0.9</td>
               <td>1.0</td>
            </tr>
            <tr className={s.bottomBorder}>
               <td></td>
               <td colSpan='6' className={s.notification}>Базова оцінка за виконання обов'язкових техничних дій ногами вар'юється між 0.1 та 0.6 за критеріями: баланс та точність виконання (майстерність виконання)</td>
               <td colSpan='3' className={s.notification}>Додайте до основної оцінки 0.1, 0.2 або 0.3 бала за наступними критеріями</td>
               <td></td>
            </tr>
            <tr>
               <td rowSpan='11' className={s.verticalText}>Технічні навички (6 балів)</td>
               <td rowSpan='10' className={s.verticalText}>Рівень технічної складності виконання<br/>(5 балів)</td>
               <td rowSpan='2'><b>Висота стрибка Йоп Чагі <br/> (1.0 балів)</b></td>
               <td className={s.notification}>Нижче пояса</td>
               <td colSpan='6'  className={s.notification}>Середня базова оцінка за виконання удару в стрибку становить 0.3 - 0.4 бала</td>
               <td  className={s.notification}>Корпус</td>
               <td  className={s.notification}>Обличчя</td>
               <td  className={s.notification}>Вище обличчя</td>
               <td></td>
            </tr>
            <tr>
               <td>0.0</td>
               <td>0.1</td>
               <td>0.2</td>
               <td>0.3</td>
               <td>0.4</td>
               <td>0.5</td>
               <td>0.6</td>
               <td>0.1</td>
               <td>0.2</td>
               <td>0.3</td>
               <td>1.0</td>
            </tr>
            <tr>           
               <td rowSpan='2'><b>Кількість фронтальних ударів у стрибку <br/> (1.0 балів)</b></td>
               <td className={s.notification}>{'< 3 ударів'}</td>
               <td colSpan='6'  className={s.notification}>Середня базова оцінка за виконання 3 - 5 ударів  0.3 - 0.4 бала</td>
               <td  className={s.notification}>3 удари</td>
               <td  className={s.notification}>4 удари</td>
               <td  className={s.notification}>5 ударів</td>
               <td></td>
            </tr>
            <tr>
               <td>0.0</td>
               <td>0.1</td>
               <td>0.2</td>
               <td>0.3</td>
               <td>0.4</td>
               <td>0.5</td>
               <td>0.6</td>
               <td>0.1</td>
               <td>0.2</td>
               <td>0.3</td>
               <td>1.0</td>
            </tr>
            <tr>           
               <td rowSpan='2'><b>Кут оберту при виконанні ударів з розворотом <br/> (1.0 балів)</b></td>
               <td className={s.notification}>{"< 360'"}</td>
               <td colSpan='6'  className={s.notification}>Середня базова оцінка за виконання ударів з розворотом на 360', 720' та більше 0.3 - 0.4 бала</td>
               <td  className={s.notification}>від 360' <br/>до 540'</td>
               <td  className={s.notification}>від 540' <br/>до 720'</td>
               <td  className={s.notification}>понад 720'</td>
               <td></td>
            </tr>
            <tr>
               <td>0.0</td>
               <td>0.1</td>
               <td>0.2</td>
               <td>0.3</td>
               <td>0.4</td>
               <td>0.5</td>
               <td>0.6</td>
               <td>0.1</td>
               <td>0.2</td>
               <td>0.3</td>
               <td>1.0</td>
            </tr>
   
            <tr>           
               <td rowSpan='2'><b>Виконання послідовних спарингових ударів <br/> (1.0 балів)</b></td>
               <td className={s.notification}>{'< 3 ударів'}</td>
               <td colSpan='6'  className={s.notification}>Середня базова оцінка за виконання послідовних спарингових ударів 0.3 - 0.4 бала</td>
               <td  className={s.notification}>Нижній рівень</td>
               <td  className={s.notification}>Сердній рівень</td>
               <td  className={s.notification}>Високий рівень</td>
               <td></td>
            </tr>
            <tr>
               <td>0.0</td>
               <td>0.1</td>
               <td>0.2</td>
               <td>0.3</td>
               <td>0.4</td>
               <td>0.5</td>
               <td>0.6</td>
               <td>0.1</td>
               <td>0.2</td>
               <td>0.3</td>
               <td>1.0</td>
            </tr>
            <tr>           
               <td rowSpan='2'><b>Акробатичні дії <br/> (1.0 балів)</b></td>
               <td className={s.notification}>Відсутні удари ТКД</td>
               <td colSpan='6'  className={s.notification}>Середня базова оцінка за виконання Акробатичних дій 0.3 - 0.4 бала</td>
               <td  className={s.notification}>Нижній рівень</td>
               <td  className={s.notification}>Сердній рівень</td>
               <td  className={s.notification}>Високий рівень</td>
               <td></td>
            </tr>
            <tr>
               <td>0.0</td>
               <td>0.1</td>
               <td>0.2</td>
               <td>0.3</td>
               <td>0.4</td>
               <td>0.5</td>
               <td>0.6</td>
               <td>0.1</td>
               <td>0.2</td>
               <td>0.3</td>
               <td>1.0</td>
            </tr>
             <tr className={s.bottomBorder}>
               <td colSpan='2'><b>Базові рухі та практичність <br/> (1.0 балів)</b></td>
               <td>0.0</td>
               <td>0.1</td>
               <td>0.2</td>
               <td>0.3</td>
               <td>0.4</td>
               <td>0.5</td>
               <td>0.6</td>
               <td>0.7</td>
               <td>0.8</td>
               <td>0.9</td>
               <td>1.0</td>
             </tr>
   
             <tr>
             <td rowSpan='4' className={s.verticalText}>Презентація (4 бала)</td>
             <td colSpan='2'><b>Креативність<br/> (1.0 балів)</b></td>
               <td>0.0</td>
               <td>0.1</td>
               <td>0.2</td>
               <td>0.3</td>
               <td>0.4</td>
               <td>0.5</td>
               <td>0.6</td>
               <td>0.7</td>
               <td>0.8</td>
               <td>0.9</td>
               <td>1.0</td>
               <td>&nbsp;</td>
             </tr>
   
             <tr>         
             <td colSpan='2'><b>Гармонія<br/> (1.0 балів)</b></td>
               <td>0.0</td>
               <td>0.1</td>
               <td>0.2</td>
               <td>0.3</td>
               <td>0.4</td>
               <td>0.5</td>
               <td>0.6</td>
               <td>0.7</td>
               <td>0.8</td>
               <td>0.9</td>
               <td>1.0</td>
               <td rowSpan='2'>&nbsp;</td>
             </tr>
             <tr>         
             <td colSpan='2'><b>Енергійність<br/> (1.0 балів)</b></td>
               <td>0.0</td>
               <td>0.1</td>
               <td>0.2</td>
               <td>0.3</td>
               <td>0.4</td>
               <td>0.5</td>
               <td>0.6</td>
               <td>0.7</td>
               <td>0.8</td>
               <td>0.9</td>
               <td>1.0</td>           
             </tr>
             <tr className={s.bottomBorder}>         
             <td colSpan='2'><b>Музичність та хореографія<br/> (1.0 балів)</b></td>
               <td>0.0</td>
               <td>0.1</td>
               <td>0.2</td>
               <td>0.3</td>
               <td>0.4</td>
               <td>0.5</td>
               <td>0.6</td>
               <td>0.7</td>
               <td>0.8</td>
               <td>0.9</td>
               <td>1.0</td>
               <td>&nbsp;</td>
             </tr>
            <tr>
               <td colSpan='14' className={s.bottomInstructions}>Підсумковий бал = Технічнні навички + Презентація</td>
               <td>&nbsp;</td>
            </tr>
               <tr>
                  <td colSpan='14' className={s.bottomInstructions}>Відрахування</td>
                  <td>&nbsp;</td>
               </tr>
               <tr>
                  <td colSpan='14' className={s.bottomInstructions}>Загальний рахунок = Підсумковий бал - відрахування</td>
                  <td>&nbsp;</td>
               </tr>
   
         </tbody>
   
   
   
   
   
         <tfoot>
            <tr>
               <td colSpan='8'><b>Суддя: ________________________________</b></td>
               <td colSpan='7'><b>Підпис ________________________________</b></td>
            </tr>
         </tfoot>
      </table></>)
   }

    return (<>
   <div className={s.resultTitle}>
    <div className={s.h3}>
    {lang[langSwitch].poomsaeFreestyleRefereeNote/*Суддівський нотатник для фрістал Пумсе*/} </div>
   </div>


 <div className={s.mainDivForPrint}>
  
 {table()}<br/><br/>{table()}



   </div> 

        <div className={s.resultTitle}>
        <div className={s.containerBadgeTop}>
<div className={s.itemResultsFooter1}>
<button type='button'  onClick={()=>{window.print()}}>
{lang[langSwitch].print /*Друкувати*/}
   </button>
</div>

<div className={s.itemResultsFooter2}>

    <button  
    type='button' 
    onClick={()=>{setSwitcher(0)}}
    className={s.redButton}
    >
    {lang[langSwitch].close /*Закрити*/}
      </button>
        
</div></div>
</div>

        
   </> );
}

export default FreestalNotes;
