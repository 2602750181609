//Виводимо на друк заявку на змагання

import { NavLink, useParams } from 'react-router-dom';
import s from './../adminCompetition.module.css'
import state from '../../../../state/state.js';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ConfirmButtons from './../confirmButtons';
import EntyInputFoterData from './entyInputFoterData.js';
import EntryPrintFooter from './entryPrintFooter.js';
import EntryBody from './entryBody.js';
import EntryClubAndDateHandle from './entryClubAndDateHandle.js';
import lang from '../../../../state/language.js';


function EntryPrint({isSmallScreen,langSwitch}){

const [team, setTeam] = useState(null);
const [dateEntry, setDateEntry] = useState('');
const [isFtuRegionLeader, setFtuRegionLeader] = useState('');
const [isOlfdLeader, setOlfdLeader] = useState('');
const [isStateSportDepartmentLeader, setStateSportDepartmentLeader] = useState('');
const [successMessage, setSuccessMessage] = useState(null);
const [errorMessage, setErrorMessage] = useState(null);

function FtuRegionLeaderHandle(e){setFtuRegionLeader(e.target.value)}
function OlfdLeaderHandle(e){setOlfdLeader(e.target.value)}
function StateSportDepartmentLeaderHandle(e){setStateSportDepartmentLeader(e.target.value)}

function dateSelect(e){setDateEntry(state.formatDate(e.target.value))}

const { id } = useParams();//отримуємо id турниру
const comp = state.competition.filter((tourn) => tourn.id == id);// турнир в массиві
const competition=comp[0]// виводимо турнир з БД 
let athletesIds, sortedCategories, myAthletes, athletesData


////////////////////////////////ПРОГРАМНА ЧАСТИНА КНОПОК ПІДТВЕРДЖЕННЯ////////////////////////////////////////////////////////////
const [messageText, setMessageText] = useState('');
const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState(false);

 function editResultConfirm() {// ФУНКЦІЯ ЯКА ЗАПУСКАЄ ОПИТУВАННЯ
//    встановлений текст над кнопками є трігером, 
//якщо він повертається з компоненти - спрацьовує функція
     setMessageText(
      lang[langSwitch].blockAthletesProfiles /*Встановити блокування профілів спортсменів до вказаної дати?*/
    )  
     openConfirmButtonsModal()
 }
 const confirmFunction = (messageText) => {
     if (messageText === 
      lang[langSwitch].blockAthletesProfiles /*Встановити блокування профілів спортсменів до вказаної дати?*/
     ) {
      postDateForBlockProfile() //ФУНКЦІЯ ЯКА ВИКОНУЄТЬСЯ В РАЗІ ПІДТВЕРДЖЕННЯ
     }

 }
 const openConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(true); }
 const closeConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(false); }
///////////////////////////////////////////////////////////////////////////////////////////////////

const [date,setDate]=useState(null)

const handleTeamChange = (e) => { const value = e.target.value; setTeam(value) };
const handledateForBlockProfileChange = (e) => { const value = e.target.value; setDate(value.trim()) };

const [clubData, setClubData] = useState(null);
const handleTitleChange = (e) => { setClubData(e.target.value) };
const titleOptionsData = state.sportClubs.filter(item=>item.creator==state.userProfile.id)
const titleOptions=titleOptionsData.map((item)=>{return(item.name)})



        function postDateForBlockProfile(){
          if(!date){
            setErrorMessage( 
              lang[langSwitch].notSetDateToBlockAthleteProfiles/*Ви не встановили дату для блокування профілів спортсменів.*/
            )
            return}

          if(!myAthletes){
            setErrorMessage(
              lang[langSwitch].yourAthletesApplicationsNotBeReceived/*Невданось отримати заявки ваших спортсменів. Якщо ви заявляли ваших спортсменів, будь ласка зверніться в службу тех.підтримки.*/
                    )
            return
          }
          const updateData=myAthletes.map((item)=>{
            return({
              id:item.id,
              dateForBlockProfile:date
            })
          })
          axios.post(state.api.postDateForBlockProfile, { updateData: updateData })
          .then(response => {   
            setSuccessMessage(lang[langSwitch].dateForBlockingAthletesProfilesSuccessfullySet/*Дату для блокування профілів спортсменів успішно встановлено.*/)     
        })
          .catch(error => {
            setErrorMessage(
              lang[langSwitch].failedToSetDateToBlockAthleteProfile/*Невдалось встановити дату для блокування профілю спортсменів. Спробуйте пізніше.*/
            )  
            // console.error('Помилка Axios-запиту, невдалось зберегти результати турниру в профілі атлетів:', error);
          });
              
        }

if (competition.athletes===null){}
else{
 
  let comptAthletes 
  if(competition.athletes){
   try{comptAthletes=JSON.parse(competition.athletes)}
   catch{comptAthletes=competition.athletes}
  }

  
if (competition.athletes===null){}
else{
 athletesData = comptAthletes.map((item)=>{
  let  result
JSON.parse(JSON.stringify(state.athletes)).map((stAthl)=>{
       if(Number(item.id)===Number(stAthl.id)){
         stAthl.clasB=item.clasB,
         stAthl.team=item.team
         result = stAthl
       }
     })
return result
   })
  
  }


if(state.userProfile.regionLeader!==null&&team==="region"){//формуємо команду від Обласного осередка

  athletesIds = athletesData.filter(athlete => athlete.team === "region");   
  myAthletes = athletesIds.filter(athlete => athlete.region==state.userProfile.regionLeader)
  sortedCategories=sortAthletesByClas(myAthletes)  
}
else{//формуємо команду від клубу
  if(clubData){
   
        athletesIds = athletesData.filter(athlete => athlete.team === "sportClub");
        myAthletes = athletesIds.filter(athlete => athlete.sportClub===clubData)  
        sortedCategories=sortAthletesByClas(myAthletes)   
      }    
}

//сортуємо моїх спортсменів по категоріях

//Сортуємо спортсменів по КЛАСАМ
function sortAthletesByClas  (myAthletes){  

  const groupedByTeam = myAthletes.reduce((acc, athlete) => {
    const clas = athlete.clasB;
  
    // Додати новий об'єкт для КЛАСА, якщо її ще немає в результаті
    if (!acc[clas]) {
      acc[clas] = { clas, athletes: [] };
    }
  
    // Додати поточного атлета до КЛАСА
    acc[clas].athletes.push(athlete);
  
    return acc;
  }, {});
 
  // Перетворити об'єкт згрупованих КЛАСІВ у масив
  const resultArray = Object.values(groupedByTeam);
  
  return resultArray
  }



}
if(sortedCategories){
sortedCategories.sort((a, b) => {
  const numA = parseInt(a.clas.match(/\d+/) || 0); // Витягує число з рядка
  const numB = parseInt(b.clas.match(/\d+/) || 0);
  return numA - numB;
});
}

return(<>
{
 state.userProfile.regionLeader!==null? /*тернарний вираз  */
  (<div className={s.teamContaner}>
<div className={s.teamChange}><p><b>
{lang[langSwitch].enterDataForApplication /*Вкажіть дані для формування заявки.*/}
  </b></p>
{/* встановлюємо дані для футера заявки:Голова ФТУ, Фіздиспансера та управління */}  
<EntyInputFoterData 
 isSmallScreen={isSmallScreen}
  isFtuRegionLeader={isFtuRegionLeader}
  isOlfdLeader={isOlfdLeader}
  isStateSportDepartmentLeader={isStateSportDepartmentLeader}
  FtuRegionLeaderHandle={FtuRegionLeaderHandle}
  OlfdLeaderHandle={OlfdLeaderHandle}
  StateSportDepartmentLeaderHandle={StateSportDepartmentLeaderHandle}
  langSwitch={langSwitch}
  />
  </div>

{/* Обираем команду від якої заявляєм спортсменів */}
<div className={s.entryPrintChangeMenu}>
{!isSmallScreen?(<><br/><br/><br/></>):null}
      <label htmlFor="status"><span  className={s.teamChangeMenuSpan}>
      {lang[langSwitch].selectTeam /*Оберіть команду*/}:
        </span> </label><br />
      <select onChange={handleTeamChange} id="status" name="status">
       <option value=""> </option> 
        <option value="region">
        {lang[langSwitch].regionalOffice /*Обласний осередок*/}  
          </option>
        <option value="club">
          {lang[langSwitch].sportsClub /*Спортклуб*/}   
          </option>
      </select><br/><br/>
    
       {/* Обираємо спортклуб та дату */}   
       <EntryClubAndDateHandle
    clubData={clubData}
    handleTitleChange={handleTitleChange}
    titleOptions={titleOptions}
    dateSelect={dateSelect}
    langSwitch={langSwitch}
    />  
   </div>
   </div> 
   ):(
  <div className={s.teamContaner}>    
  <div className={s.teamChange}><p><b>
  {lang[langSwitch].enterDataForApplication /*Вкажіть дані для формування заявки.*/} 
    </b></p>
   {/* встановлюємо дані для футера заявки:Голова ФТУ, Фіздиспансера та управління */}  
  <EntyInputFoterData 
  isSmallScreen={isSmallScreen}
  isFtuRegionLeader={isFtuRegionLeader}
  isOlfdLeader={isOlfdLeader}
  isStateSportDepartmentLeader={isStateSportDepartmentLeader}
  FtuRegionLeaderHandle={FtuRegionLeaderHandle}
  OlfdLeaderHandle={OlfdLeaderHandle}
  StateSportDepartmentLeaderHandle={StateSportDepartmentLeaderHandle}
  langSwitch={langSwitch}
  />
  </div>    
  <div className={s.entryPrintChangeMenu}>
    {!isSmallScreen?(<><br/><br/><br/></>):null}
     {/* Обираємо спортклуб та дату */}  
    <EntryClubAndDateHandle
    clubData={clubData}
    handleTitleChange={handleTitleChange}
    titleOptions={titleOptions}
    dateSelect={dateSelect}
    langSwitch={langSwitch}
    /> 
    </div> 
  </div>
 )}


{/* Початок зони друку */}

    <div  className={s.printContent}  >
  <table className={s.printTable} >
<thead>
  <tr>
    <td colSpan='9'>
    <div className={s.printHeader}>
        Поіменна заявка учасників на 
        
        <div className={s.printTitle}>{competition.title} </div>
        <div>{competition.location}</div>
        <div> {state.formatDate(competition.dateStart).slice(0, 2)}- 
        {state.formatDate(competition.dateFinish)} р.</div>
        <div>Від команди: <b> 
          {team==='region'?state.regionUkr(state.userProfile.region):
         team==='club'&&state.userProfile.regionLeader!==null||team===null&&state.userProfile.regionLeader===null?clubData:null}
</b></div> 
    </div>
</td></tr>


    <tr className={s.printTableHeader}>
        <td className={s.headerTd1}>№ <br/>з/п</td>
        <td className={s.headerTd2}>П.І.Б.</td>
        <td className={s.headerTd3}>Дата <br/>народження</td>
        <td className={s.headerTd4}>Категорія <br/>учасника</td>
        <td className={s.headerTd5}>Спортивна кваліфікація</td>
        <td className={s.headerTd6}>ФСТ/ДЮСШ, УОР, ШВСМ,<br/>УФК,СДЮСШОР,СК</td>
        <td className={s.headerTd7}>П.І.Б. тренера<br/>(перший/<br/>особистий)</td>
        <td className={s.headerTd8}>Страх. поліс <br/>(серія, номер, термін дії)<br/>страх. комп.</td>
        {/* <td className={s.headerTd9}>Форма<br/> № 061/о<br/> реєст.номер та термін дії</td>         */}
        <td className={s.headerTd10}>Дата, печатка та підпис лікаря</td>
    </tr>
    </thead>
    <tbody>
     
{ team!==null&&state.userProfile.regionLeader!==null||state.userProfile.regionLeader===null?
(<>
{  
sortedCategories?sortedCategories.map((item)=>{console.log(item)
  return(<>
 
   <tr className={s.printTableHeader} key={item.clas}>
        {/* ВИПРАВИТИ ПРИ ДОДАВАННІ ЧИ ВІДНІМАННІ СТОЛБЦІВ З ЗАЯВКИ */}
        <td colSpan={'9'}>Клас: <b>{lang[langSwitch][item.clas]}</b></td>
      </tr>
  <EntryBody 
athletes={item.athletes}
dateEntry={dateEntry}
/>

</>)}):null

}


</>):null}
</tbody>

<EntryPrintFooter
isFtuRegionLeader={isFtuRegionLeader}
isOlfdLeader={isOlfdLeader}
isStateSportDepartmentLeader={isStateSportDepartmentLeader}
dateEntry={dateEntry}
mainJudge={competition.mainJudge}
secretary={competition.secretary}
/>

     </table> <br/><br/><br/><br/>
    </div>

{/* Кінець зони друку */}

<div className={s.noPrint}>

{errorMessage ? (<><br/><div className={s.errorMessage} onClick={() => { setErrorMessage(null) }}
          style={{ cursor: 'pointer' }}>
          {errorMessage}
        </div>
      </> ) : null} 
        {successMessage ? (<><br/><div className={s.successMessage} onClick={() => { setSuccessMessage(null) }}
          style={{ cursor: 'pointer',}}>
          {successMessage}
        </div>
       </>  ) : null}

<div className={!isSmallScreen?s.boxForDate:s.td1}>
    <div className={s.title1}>
    <button  type='button' onClick={() => {window.print();}}>
    {lang[langSwitch].print /*Друкувати*/}
      </button>

    </div>

    
    <div className={s.title2}>
    <div className={s.boxForDateInput}>
          <label htmlFor="birthday">
            <span className={s.span}>
            {lang[langSwitch].setDateToBlockAthletesProfile /*Встановіть дату для блокування профілю спортсменів*/}:
              </span> 
         <br /> <b>{date?state.formatDate(date)+' '+
          lang[langSwitch].yearShort /*p.*/
         :null} </b>
         </label><br />
          <input onChange={handledateForBlockProfileChange} type="date" id="birthday" name="birthday" /><br />
          <button  type='button' onClick={() => {editResultConfirm()}}>
          {lang[langSwitch].setDate /*Встановити дату*/}
            </button>
</div>
</div>    

    <div className={s.title3ForEnrtyPrint} >
      
    <NavLink to={`/competition/${id}`}> 
    <button type='button'>
    {lang[langSwitch].close /*Закрити*/} 
      </button>
    </NavLink> 
 
</div>
</div>
<ConfirmButtons
            isOpen={isConfirmButtonsModalOpen}
            onClose={closeConfirmButtonsModal}
            confirmFunction={confirmFunction}
            messageText={messageText}
            langSwitch={langSwitch}
        />
</div>
 </>)  
}


export default EntryPrint