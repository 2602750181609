import React from 'react';
import s from '../menu/menu.module.css'
import lang from '../../../../state/language';
import PhotoContent from '../../forAllUsers/mediaContent/photoContent';

function GalleryForEdit ({langSwitch, isSmallScreen}) {
  
  return (<div>
      <div className={s.h1}>
      {lang[langSwitch].editGallery   /*Редагувати галерею*/}
    </div>    
   <PhotoContent isSmallScreen={isSmallScreen} langSwitch={langSwitch} moderator={true}/>

  </div>);
};

export default GalleryForEdit;