import React from 'react';
import s from './forAllUsers.module.css'

function OurPartners ({isSmallScreen, langSwitch}) {
  return (<div className={s.ourPartnersBox}>

   <div className={s.ourPartnersWriper}>
    <div className={s.ourPartnersTitle}>Наші партнери</div> 
    <div>
        <a href="https://mms.gov.ua" target="_blank" rel="noopener noreferrer">
            <img
                className={s.ourPartnersImg}
                onClick={() => { }}
                src='/img/ourPartners/Ministry_of_Youth_and_Sports_of_Ukraine.jpg' />
        </a>

        <a href="https://mms.gov.ua" target="_blank" rel="noopener noreferrer">
            <img
                className={s.ourPartnersImg}
                onClick={() => { }}
                src='/img/ourPartners/Ministry_of_Youth_and_Sports_of_Ukraine.jpg' />
        </a>

        <a href="https://mms.gov.ua" target="_blank" rel="noopener noreferrer">
            <img
                className={s.ourPartnersImg}
                onClick={() => { }}
                src='/img/ourPartners/Ministry_of_Youth_and_Sports_of_Ukraine.jpg' />
        </a>

        <a href="https://mms.gov.ua" target="_blank" rel="noopener noreferrer">
            <img
                className={s.ourPartnersImg}
                onClick={() => { }}
                src='/img/ourPartners/Ministry_of_Youth_and_Sports_of_Ukraine.jpg' />
        </a>

        <a href="https://mms.gov.ua" target="_blank" rel="noopener noreferrer">
            <img
                className={s.ourPartnersImg}
                onClick={() => { }}
                src='/img/ourPartners/Ministry_of_Youth_and_Sports_of_Ukraine.jpg' />
        </a>

        <a href="https://mms.gov.ua" target="_blank" rel="noopener noreferrer">
            <img
                className={s.ourPartnersImg}
                onClick={() => { }}
                src='/img/ourPartners/Ministry_of_Youth_and_Sports_of_Ukraine.jpg' />
        </a>

        <a href="https://mms.gov.ua" target="_blank" rel="noopener noreferrer">
            <img
                className={s.ourPartnersImg}
                onClick={() => { }}
                src='/img/ourPartners/Ministry_of_Youth_and_Sports_of_Ukraine.jpg' />
        </a>
            
    </div>
    </div> 
  </div>);
};

export default OurPartners;