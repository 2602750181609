//сторінка що виводить на єкран повідомлення

import React from 'react';
import state from '../../../state/state.js';
import PreviewWindow from './PreviewWindow';
import sAdmin from '../adminCompetition/adminCompetition.module.css'
import lang from '../../../state/language.js';


function MailItem({langSwitch}) {



 //фільтруємо та сортуємо повідомлення
  const filteredAndSortedArray=state.mail.filter((item) => item.respondent == state.userProfile.id)
 


  filteredAndSortedArray.sort((a, b) => {
    if (a.isRead === '0' && b.isRead === '0') {
      return 0; // якщо обидва непрочитані, не змінювати порядок
    } else if (a.isRead === '0') {
      return -1; // якщо тільки a непрочитане, a буде першим
    } else if (b.isRead === '0') {
      return 1; // якщо тільки b непрочитане, b буде першим
    } else {
      return 0; // якщо обидва прочитані, не змінювати порядок
    }
  });

  state.messageCount()  



  const letters = filteredAndSortedArray.map((item) => (
    <PreviewWindow 
      key={item.id} 
      id={item.id} 
      text={item.text}  
      title={item.title} 
      date={item.date} 
      isRead={item.isRead}
      langSwitch={langSwitch} />
  ));

  return <div>
    {letters.length>0?(
      <div className={sAdmin.title}><br/>
      {lang[langSwitch].yourMessages/*Ваші повідомлення*/}
      <br/>&nbsp;</div>
      ):(
      <div className={sAdmin.title}><br/>
       {lang[langSwitch].youHaveNoUnreadMessages/*Ви не маєте непрочитаних повідомлень*/}
      <br/>&nbsp;</div>
      )}
    {letters}
    <br/>&nbsp; </div>;
}

function Mail({langSwitch}) {
  return <MailItem langSwitch={langSwitch} />;
}

export default Mail;
