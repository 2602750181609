import s from './documents.module.css'
import BackButton from './backButton';

function ProgramWT({isSmallScreen,langSwitch}){
    const contentData = [
        { link: "#intro", text: 'Вступ' },
        { link: "#metod", text: 'Методика проведення атестації і оцінка рівня підготовленості тхеквондістів' },
        { link: "#commandments", text: 'Заповіді Тхеквондо' },
        { link: "#10kup", text: 'Білий пояс. 10 куп' },
        { link: "#9kup", text: 'Жовтий пояс. 9 куп' },
        { link: "#8kup", text: 'Помаранчевий пояс. 8 куп' },
        { link: "#7kup", text: 'Зелений пояс. 7 куп' },
        { link: "#6kup", text: 'Пурпурний пояс. 6 куп' },
        { link: "#5kup", text: 'Блакитний пояс. 5 куп' },
        { link: "#4kup", text: 'Синій пояс. 4 куп' },
        { link: "#3kup", text: 'Червоний пояс. 3 куп' },
        { link: "#2kup", text: 'Світло-коричневий пояс. 2 куп' },
        { link: "#1kup", text: 'Темно-коричневий пояс. 1 куп' },
        { link: "#1dan", text: 'Чорний пояс. 1 дан' },
        
    ]

    const middleIndex = Math.ceil(contentData.length / 2);
    const firstHalf = contentData.slice(0, middleIndex);
    const secondHalf = contentData.slice(middleIndex);

return(<>

<BackButton langSwitch={langSwitch}/>
<div className={s.rulesWriper}>
<>    
<div className={s.rulesTitle}>Атестаційна програма Тхеквондо WT.<br />
       <a href='https://www.gridstkd.com.ua/img/certificationProgram/certificationProgramWT.pdf' target='_blank' download className={s.rulesSourseLink}>
               Атестаційна програма Тхеквондо WT. PDF
       </a>
</div>
{/* Зміст */}
<div className={s.rulesContentBox}>
                <div id='Content'>Зміст</div><br />
                <div className={!isSmallScreen ? s.contentContaner : null}>
                    <div className={s.rulesContentBoxItem1}>
                        {firstHalf.map((item, index) => {

                            return (<>
                                <div key={'first' + index}>
                                    <span className={s.num}>{index + 1}. </span>
                                    <a href={item.link} className={s.rulesContentLink1}>
                                        {item.text}
                                    </a>
                                </div>
                            </>)
                        })}
                    </div>
                    <div className={s.rulesContentBoxItem2}>
                        {secondHalf.map((item, index) => {

                            return (<>
                                <div key={'second' + index}>
                                    <span className={s.num}>{firstHalf.length + (index + 1)}. </span>
                                    <a href={item.link} className={s.rulesContentLink1}>
                                        {item.text}
                                    </a>
                                </div>

                            </>)
                        })}
                    </div>
                </div>
</div>
{/* Вступ */}
<div className={s.rulesBlock}>
    <div className={s.programArticle} id='intro'>Вступ.
        {isSmallScreen ? (<><br /></>) : null}
        <br/><a href="#Content" className={s.programContentLink}> <b> Зміст</b></a>
    </div>    
    <div className={s.programOl}>Розробка програми зумовлена потребою удосконалення навчально-тренувального процесу у зв’язку із зростанням вимог до підготовки спортсменів-тхеквондистів.</div>
    <div className={s.programOl}>Програма складена на засадах нормативних документів з підготовки спортсменів і проведення атестації та змагань з тхеквондо ВТФ.</div>
    <div className={s.programOl}>При її розробці був врахований передовий досвід навчання і тренування у тхеквондо, результати досліджень з питань підготовки спортсменів, теорії та методики спорту вищих досягнень та інших суміжних напрямків спорту.</div>
    <div className={s.programOl}>Програма визначає теорію і методику проведення атестаційних екзаменів, а також оцінку рівня підготовки тхеквондистів у відповідності з їх кваліфікаційним рівнем.</div>
    <div className={s.programOl}>Програма розроблена із врахуванням вимог, представлених ЕТU і WTF, а також ФТУ. В програмі використана відповідна корейська термінологія, прийнята WTF і ЕТU.</div>
    <div >
    </div>
</div> 
{/*Методика проведення  */}
<div className={s.rulesBlock}>
    <div className={s.programArticle} id='metod'>Методика проведення атестації і оцінка рівня підготовленості тхеквондістів. 
        {isSmallScreen ? (<><br /></>) : null}
       <br/> <a href="#Content" className={s.programContentLink}> <b> Зміст</b></a>
    </div>
    <div className={s.programOl}>Атестація на пояси проводиться кваліфікованими спеціалістами, які володіють достатніми знаннями і досвідом в тхеквондо ВТФ, а також входять до складу атестаційної комісії і мають кваліфікацію не нижче 1 Дану (чорний пояс) в тхеквондо ВТФ.</div>
    <div className={s.programOl}>Атестація проводиться згідно графіку затвердженого атестаційною комісією федерації тхеквондо ВТФ України.</div>
    <div className={s.programOl}>При поданні списку претендентів на проведення екзамену при поясній градації, тренерам необхідно заповнити «Заявку на участь в атестації» (Додаток 2) із зазначенням всіх необхідних даних спортсменів. </div>
    <div className={s.programOl}>Екзамен складається з двох частин (теоретичної і практичної), які необхідно виконати спортсмену.</div>
    <div className={s.programOl}>Теоретична частина складається з корейської термінології, історії тхеквондо, інформації про спортивну школу чи клуб, правил змагань тощо.</div>
    <div className={s.programOl}>Практична частина включає знання стійок, переміщень в них, блоків руками,  ударів руками і ногами, володіння елементами самозахисту (ХОСІНСУЛЬ), технік умовного бою (ХАНБОН-КЬОРУГІ), вільний  поєдинок за правилами змагань тхеквондо ВТФ (КЬОРУГІ), навичок суддівської практики, техніки формального комплексу (ПХУМСЕ),  а також  тестування загальної  і спеціальної фізичної підготовки.</div>
    <div className={s.programOl}>Екзаменатори заповнюють «Протокол оцінки технічної, фізичної і теоретичної підготовки атестуючих» (Додаток 3) з оцінкою кожного виду завдання з визначенням середньої оцінки за екзамен, після чого оголошують результат атестації кожного спортсмена окремо.</div>
    <div className={s.programOl}>При іспиті на 10 куп (білий пояс) найменший показник підготовленості тхеквондистів повинен складати 3 бали по 5-ти бальній шкалі оцінювання.</div>
    <div className={s.programOl}>На інші пояси найменший показник підготовленості тхеквондистів повинен складати 4 бали. Спортсмен, який набрав за екзамен не менше вказаного мінімуму вважається не атестованим, також, як і у випадку не виконання завдання. Терміни переатестації таких спортсменів визначаються екзаменаторами. Повторна атестація не допускається!</div>
    <div className={s.programOl}>Спортсмени, які беруть участь в екзамені, повинні обов’язково бути одягнуті у спеціальну уніформу (добок). Не допускається присутність на тілі або одязі сторонніх предметів (колечка, сережок, годинника, амулетів і т. д.) з метою уникнення травм. На ногах допускається спеціальне взуття для занять тхеквондо тільки з дозволу екзаменатора. На іспиті не дозволяється носити пояс, що не відповідає кваліфікації спортсмена. 
    <div className={s.programOl}>Загальні рекомендації  організаційного розділу під час проведення іспиту:
                   <ol className={s.programList}>
                <li> Необхідно  ретельно дотримуватись етикету і ритуалу початку та закінчення під час здачі іспиту.</li>
                <li>Вихід у позицію, розворот і кожна остання дія переміщення виконується з кіхап (бойовой вигук).</li>
                <li>Демонстрацію ПХУМСЕ необхідно починати командами САБУМа: «Чарьот! Кьоне!» ("Увага, поклін!"). Потім САБУМ оголошує задане ПХУМСЕ, виконуючий повторює озвучене, і дається команда "Чжунбі!" ("Приготуватися!") і "Січжак!" ("Розпочати!"), після чого виконується вся форма. На останньому елементі ПХУМСЕ необхідно затриматися, доки САБУМ не дасть команду "Баро!" ("Закінчити!"). Далі ритуал той же, що на початку демонстрації ПХУМСЕ.</li>
            </ol>
        </div>
    </div>
     <div className={s.programOl}><b><i><u>Програму взято за основу для складання іспитів, але може бути доповнена  або  змінена на розгляд  атестаційної комісії.</u></i></b></div>
    <div >
    </div>
</div> 
{/*Заповіді Тхеквондо  */}
<div className={s.rulesBlock}>
    <div className={s.programArticle} id='commandments'>Заповіді Тхеквондо.
        {isSmallScreen ? (<><br /></>) : null}
       <br/> <a href="#Content" className={s.programContentLink}> <b> Зміст</b></a>
    </div>
<ol className={s.programList}>
    <li>Бути вірним своїй країні.</li>
    <li>Бути хорошим сином чи дочкою своїх батьків.</li>
    <li>Бути в хороших відносинах з своїми друзями.</li>
    <li>Поважати старших.</li>
    <li>Поважати і вірити своїм вчителям.</li>
    <li>Ніколи не відмовлятися від бою.</li>
    <li>Завжди закінчувати розпочате.</li>
</ol>
    
    <div >
    </div>
</div> 

{/*10 куп (БІЛИЙ ПОЯС)  */}
<div className={s.rulesBlock}>
    <div className={s.programArticle} id='10kup'>10 куп (БІЛИЙ ПОЯС)
        {isSmallScreen ? (<><br /></>) : null}
      <br/>  <a href="#Content" className={s.programContentLink}> <b> Зміст</b></a>
    </div>
    <div className={s.programOl}><b>Согі суль - Техніка стійок :</b> <br/>Моа согі (чарьот согі)<br/>Пьонхі согі (чжунбі согі)</div>
    <div className={s.programOl}><b>Чжіругі суль – техніка ударів рукою: </b><br/>Чжунбі согі – ап чжіругі</div>
    <div className={s.programOl}><b>Маккі суль – техніка блокуючих дій: </b><br/>Чжунбі согі – ольгуль маккі, момтон ан маккі, момтон баккат маккі, аре маккі</div>
    <div className={s.programOl}><b>Чагі суль – техніка ударів ногами: </b><br/>Ап согі муруп ап чігі (прямий удар коліном);<br/>Ап согі  с момтон ап чагі (прямий удар ногою);<br/>Ап согі с нерьо чагі (удар ногою зверху донизу);</div>
    <div className={s.programOl}><b>Чачжінбаль - переміщення в бойовій стійці (варіанти).</b></div>
    <div className={s.programOl}><b>Тестування фізичної підготовленості: </b><br/>Згинання і розгинання рук в упорі лежачи; <br/>Підйом у сід з положення лежачи. ( кількість раз відповідно до віку і статі спортсмена).<br/></div>
    <div className={s.programOl}><i>Примітка: </i>
        <ol className={s.programList}>
            <li>Тестування проводиться з врахуванням віку учня (учениці). </li>
            <li>Жінки та мужчини після 35 років можуть бути звільнені від тестування.</li>
        </ol>
    </div>
    <div className={s.programOl}><b>Теоретичні питання:</b>
     <ol className={s.programList}>
   <li><b>Питання: </b>Що ти вивчаєш у своїй школі?<br/><i>Відповідь: </i>ТХЕКВОНДО.</li>
   <li><b>Питання: </b>Що таке ТХЕКВОНДО?<br/><i>Відповідь: </i>Корейське бойове мистецтво та олімпійський вид спорту.</li>
   <li><b>Питання: </b>Що означає термін «ТХЕКВОНДО» в перекладі з корейської мови?<br/><i>Відповідь: </i>«ТХЕ» - нога; «КВОН» - кулак; «ДО» - шлях. Разом - «Шлях ноги і кулака».</li>
   <li><b>Питання: </b>Як ти називаєш свого вчителя?<br/><i>Відповідь: </i>«САБУМ».</li>
   <li><b>Питання: </b>Для чого виконується бойовий вигук  (кіхап)?<br/><i>Відповідь: </i>Щоб розвинути духовну силу, концентрацію і впевненість. Бойовий вигук також є формою психологічної  атаки і контролю дихання. </li>
   <li><b>Питання: </b>Що означає поклон в ТХЕКВОНДО?<br/><i>Відповідь: </i>Уклін — це  форма привітання і  вияв вдячності. Залу – за те, що об’єднує однодумців. Наставнику – за поради, настанови, терпіння. Партнеру – за розуміння, чесність і допомогу в навчанні.</li>
   <li><b>Питання: </b>Чому ви робите поклон?<br/><i>Відповідь: </i>Щоб виразити  сердечну повагу.</li>
   <li><b>Питання: </b>Чому ти практикуєш ТХЕКВОНДО?<br/><i>Відповідь: </i>Свій варіант відповіді.</li>
   <li><b>Питання: </b>Чому ти здаєш екзамен з ТХЕКВОНДО?<br/><i>Відповідь: </i>Я здаю екзамен для того, щоб додати собі впевненості, продемонструвати свої знання і вміння в напруженій ситуації, а також дозволити екзаменатору визначити мій рівень майстерності  як спортсмена та бійця. </li>
   <li><b>Питання: </b>Які слова і команди на корейській мові повинен знати кожний хто практикує ТХЕКВОНДО.<br/><i>Відповідь: </i><br/>
   <div className={!isSmallScreen ? s.contentContaner : null}>
    <div>
        <b>ЧАРЬОТ </b>– увага;<br/>
        <b>КЬОНЕ </b>– поклін;<br/>
        <b>ЧЖУНБІ </b>– приготуватися;<br/>
        <b>СІЧЖАК </b>– розпочати;<br/>
        <b>КИМАН </b>– закінчити;<br/>
        <b>БАРО </b>– повернутися у вихідне положення;<br/>
        <b>СЬО </b>– відпочинок, вільно;<br/>
        <b>ТВІРО-ТОРА </b>– розвернутись назад на 180о;<br/>
    </div>
    <div>
    <b>ДОБОК </b>– одяг для занять  з тхеквондо;<br/>
    <b>ТІ </b>– пояс;<br/>
    <b>КЬОКПА </b>– тест на розбивання;<br/>
    <b>ПХУМСЕ </b>– формальний комплекс;<br/>
    <b>КАМ СА ХАМ НІ ДА </b>– дякую;<br/>
    <b>САБУМ </b>– тренер, вчитель;<br/>
    <b>ДОЧЖАН </b>– зал для тренувань ТХЕКВОНДО<br/>
    </div>
    </div>
    </li>
   <li><b>Питання: </b>Що означає відсутність поясу?<br/><i>Відповідь: </i>Це означає ціломудрість,  пустоту та  чистоту помислів.</li>
   <li><b>Питання: </b>Що символізує білий колір поясу?<br/><i>Відповідь: </i>Білий пояс це більш вагоме значення чим відсутність поясу, символізує готовність отримувати знання.</li>
   <li><b>Питання: </b>Як правильно зав’язувати пояс?<br/><i>Відповідь: </i>Пояс у тхеквондо зав’язується в один обхват талії. Після цього як пояс зав’язаний, його кінці повинні мати однакову довжину.  Зав’язуючи пояс, необхідно слідкувати за тим, щоб він не перекрутився на талії, а вузол точно знаходився по центру. Якщо пояс іменний, то кінець поясу з написом прізвища та імені,  після зав’язування, повинен розташовуватися зліва. Якщо пояс червоно-чорний то пов’язувати  необхідно чорною стороною зверху. При зав’язуванні поясу не потрібно поспішати, адже процедура допомагає налаштуватися на тренування, а правильно зав’язаний пояс вказує на добросовісність учня (учениці).</li>
     
    </ol>
    </div>
    <div >
    </div>
</div> 
{/* 9 куп (ЖОВТИЙ ПОЯС) */}
<div className={s.rulesBlock}>
    <div className={s.programArticle} id='9kup'>9 куп (ЖОВТИЙ ПОЯС).
        {isSmallScreen ? (<><br /></>) : null}
        <br/><a href="#Content" className={s.programContentLink}> <b> Зміст</b></a>
    </div>
 <div className={s.programOl}><b>Чжіругі суль і маккі суль в переміщенні:</b>
 <ol className={s.programList}>
    <li>Ап согі - момтон панде чжіругі.</li>
    <li>Ап согі - аре маккі</li>
    <li>Ап согі - момтон ан маккі</li>
    <li>Ап согі - момтон баккат маккі</li>
    <li>Ап согі - ольгуль маккі</li>
 </ol>
 </div>
 <div className={s.programOl}><b>Чагі суль в переміщенні:</b>
 <ol className={s.programList}>
    <li>Ап согі з ап чагі (або ольгуль, або момтон); </li>
    <li>Ап согі з нерьо чагі;</li>
    <li>Ап согі з пандаль чагі (удар ногою під кутом 45° знизу);</li>
    <li>Ап согі з момтон дольо чагі (круговий удар ногою в середній рівень);</li>
    <li>Ап согі з бітуро чагі (круговий удар з середини-назовні);</li>
 </ol>
 </div>
 <div className={s.programOl}><b>Чачжінбаль - переміщення в бойовій стійці (варіанти).</b></div>
 <div className={s.programOl}><b>Чжаю кьоругі (безконтактний вільний спарринг, використовуючи тільки техніку ніг).</b></div>
 <div className={s.programOl}><b>Бат да чагі суль – техніка контратакуючих дій: </b><br/>Не менше п’яти контратакуючих  дій проти ап чагі. </div>
 <div className={s.programOl}><b>Тестування фізичної підготовленості:</b><br/>Згинання і розгинання рук в упорі лежачи; <br/>Підйом у сід з положення лежачи. ( кількість раз відповідно до віку і статі спортсмена).</div>
 <div className={s.programOl}><b>Теоретичні питання:</b>
 <ol className={s.programList}>
    <li><b>Питання: </b>Які рівні людського тіла ви використовуєте для атаки і захисту? <br/><i>Відповідь: </i>Верхній (ольгуль), середній (момтон) і нижній (аре).</li>
    <li><b>Питання: </b>Чим створюється сила удару?<br/><i>Відповідь: </i>Сила удару створюється вагою і швидкістю у сполученні з релаксацією і впевненістю. </li>
    <li><b>Питання: </b>Що показує колір поясу учня (учениці)?<br/><i>Відповідь: </i>Колір поясу учня – це показник його кваліфікації. </li>
    <li><b>Питання: </b>Що символізує жовтий колір поясу? <br/><i>Відповідь: </i>Схід сонця і готовність отримувати знання. Символізує початок шляху, який необхідно пройти учневі. </li>
    <li><b>Питання: </b>Чому необхідно контролювати дихання?<br/><i>Відповідь: </i>Щоб розвинути духовну силу, витривалість, терпіння  і зберегти добре самопочуття. </li>
    <li><b>Питання: </b>Які три базових принципи ТХЕКВОНДО необхідно пам’ятати?<br/><i>Відповідь: </i>Правильний погляд; хороший баланс; гучний, сильний бойовий вигук (кіхап). </li>
 </ol>
 </div>
    
    <div >
    </div>
</div> 
{/*8 куп (ПОМАРАНЧЕВИЙ ПОЯС)  */}
<div className={s.rulesBlock}>
    <div className={s.programArticle} id='8kup'>8 куп (ПОМАРАНЧЕВИЙ ПОЯС) .
        {isSmallScreen ? (<><br /></>) : null}
        <br/><a href="#Content" className={s.programContentLink}> <b> Зміст</b></a>
    </div>
    <div className={s.programOl}><b>Чжіругі суль і маккі суль в переміщенні:</b>
    <ol className={s.programList}>
        <li>Ап кубі з момтон панде чжіругі.</li>
        <li>Ап кубі з аре маккі</li>
        <li>Ап кубі з момтон ан маккі</li>
        <li>Ап кубі з момтон баккат маккі</li>
        <li>Ап кубі з ольгуль маккі.</li>
    </ol>
    </div>
    <div className={s.programOl}><b>Чагі суль з ап согі: </b>
    <ol className={s.programList}>
        <li>Ольгуль сево ан чагі (круговий вертикальний удар ногою в голову всередину);</li>
        <li>Ольгуль сево баккат чагі (круговий вертикальний удар ногою в голову назовні);</li>
        <li>Ольгуль пандаль чагі (удар ногою в обличчя під кутом 45° знизу);</li>
        <li>Ап паль монтон йоп чагі (боковий удар ногою на середньому рівні передньою ногою);</li>
        <li>Ап паль ольгуль йоп чагі (боковий удар ногою на верхньому рівні передньою ногою);</li>
        <li>Ольгуль долльо чагі (круговий удар в голову);</li>
        <li>Твіт чагі (прямий удар п’яткою назад з поворотом, нога ставиться після удару вперед).</li>
    </ol>
    </div>
    <div className={s.programOl}><b>Чачжінбаль – переміщення в бойовій стійці.</b></div>
    <div className={s.programOl}><b>Чжаю кьоругі (безконтактний вільний спаринг).</b></div>
    <div className={s.programOl}><b> Бат да чаги суль – техніка контратакуючих дій:</b><br/>Не менше п’яти контратакуючих  дій проти нерьо чагі. </div>
    <div className={s.programOl}><b>Хосінсуль – техніка самозахисту. </b></div>
    <div className={s.programOl}><b>Прості способи звільнення від захвату за зап'ясток.</b>
    <ol className={s.programList}>
        <li>Звільнення від прямого захвату  назовні.</li>
        <li>Звільнення від прямого захвату  всередину.</li>
        <li>Звільнення від перехресного захвату  всередину. </li>
        <li>Звільнення від перехресного захвату  назовні.</li>
        <li>Звільнення від захвату за дві руки назовні.</li>
        <li>Звільнення від захвату зап’ястка за дві руки всередину.</li>
        <li>Звільнення від захвату  стоячи лицем до противника,  коли противник тягне руку на себе. </li>
        <li>Звільнення від захвату стоячи боком до противника, коли противник тягне руку на себе. </li>
        <li>Звільнення від захвату стоячи спиною до противника, коли він тягне руку на себе.</li>
    </ol>
    </div>
    <div className={s.programOl}><b>Акробатичні вправи:</b><br/>Страховки;<br/>Падіння на чотири боки.</div>
    <div className={s.programOl}><b>Тестування фізичної підготовленості:</b><br/>Згинання і розгинання рук в упорі лежачи; <br/>Підйом у сід з положення лежачи. ( кількість раз відповідно до віку і статі спортсмена).<br/>Гнучкість (розтяжка в шпагаті). </div>
    <div className={s.programOl}><b>Теоретичні питання:</b>
    <ol className={s.programList}>
    <li><b>Питання: </b>Як порахувати по-корейськи до 10? <br/> <i>Відповідь:</i>
    <div className={!isSmallScreen ? s.contentContaner : null}>
        <div>
            <div className={s.countName}>Корейський рахунок:</div>
        <ol className={s.programList}>
    <li>хана</li>
    <li>туль </li>
    <li>сет </li>
    <li>нет </li>
    <li>тасот </li>
    <li>йосот</li>
    <li>ільхоп </li>
    <li>йодоль</li>
    <li>ахоп</li>
    <li>йоль</li>
    </ol>
        </div>
        <div>
        <div className={s.countName}>Старокитайський рахунок:</div>
        <ol className={s.programList}>
    <li>іль</li>
    <li>і</li>
    <li>сам</li>
    <li>са</li>
    <li>o</li>
    <li>юк</li>
    <li>чіль</li>
    <li>паль</li>
    <li>ку</li>
    <li>сіп</li>
    </ol>
        </div>
    </div>
    
    </li>
    <li><b>Питання: </b> Які принципи необхідно пам’ятати, виконуючи ПХУМСЕ?<br/> <i>Відповідь:</i>
    <ul>
        <li>Дві руки діють одночасно: одна рука, обертаючись рухається вперед, одночасно друга рука обертаючись назад;</li>
        <li>Пряма лінія: рука рухається до цілі по прямій лінії.</li>
        <li>Сила стегон і тіла: кожний блок і удар повинен бути зроблений, використовуючи силу обертання стегон і тіла. </li>
    </ul>
    </li>
    <li><b>Питання: </b>Чому потрібно контролювати себе під час учбового спарингу та не завдавати контактних ударів? <br/> <i>Відповідь:</i>Це потрібно робити, щоб продемонструвати ментальну дисципліну і  свій фізичний самоконтроль. Це набагато складніше, ніж нанести контактний удар. У навчальному спарингу такий контроль необхідний, щоб не завдати травми партнеру, а  тільки показати йому його слабкі місця, при цьому розвивати свою реакцію. </li>
    <li><b>Питання: </b> Що символізує помаранчевий колір поясу?<br/> <i>Відповідь:</i>Також як і жовтий колір, оранжевий колір  символізує  схід сонця, готовність учня йти шляхом  отримання знань про тхеквондо. </li>
    </ol>    
    </div>    
    <div >
    </div>
</div> 

{/*7 куп (ЗЕЛЕНИЙ ПОЯС)  */}
<div className={s.rulesBlock}>
    <div className={s.programArticle} id='7kup'>7 куп (ЗЕЛЕНИЙ ПОЯС).
        {isSmallScreen ? (<><br /></>) : null}
        <br/><a href="#Content" className={s.programContentLink}> <b> Зміст</b></a>
    </div>
    <div className={s.programOl}><b>Пхумсе:</b><br/>Тегук іль чжан (№ 1). Термінологія. ( Р.S. Показати будь-який елемент з пхумсе)
Форма тегук іль чжан відповідає терміну «кеон» НЕБО, ТВОРЧІСТЬ (у східній філософії).
</div>
    <div className={s.programOl}><b>Чагі суль з ап согі:</b><br/>Будь-який удар ногою на вибір атестаційної комісії з попередніх купів.<br/>Ольгуль баккат дольо чагі (йоп хурьо чагі) (круговий удар п’ятою  в голову). Додатково  ударна техніка ніг перевіряється по мішені.</div>
    <div className={s.programOl}><b>Чжаю кьоругі (вільний спаринг).</b></div>
    <div className={s.programOl}><b>Бат да чагі суль - техніка контратакуючих дій:</b><br/>Не менше п’ять контратакуючих  дій проти пандаль чагі.</div>
    <div className={s.programOl}><b>Хосінсуль – техніка самозахисту.</b></div>
    <div className={s.programOl}><b>Звільнення від захвату за зап'ясток за допомогою ударних технік.</b>
   <ol className={s.programList}>
   <li>Звільнення  з вертикальним рухом руки, удар ребром долоні зверху в голову, прямий удар ногою.</li>
   <li>Звільнення  з вертикальним рухом руки, удар ліктем знизу, удар ліктем збоку.</li>
   <li>Звільнення з діагональним рухом руки, удар ребром долоні в шию, круговий удар ногою.</li>
   <li>Звільнення з горизонтальним  рухом руки вниз, удар ліктем в сонячне сплетіння, удар ногою в бік.</li>
   <li>Звільнення діагональним рухом руки, удар ребром долоні в шию, удар ногою назад.</li>
   <li>Удар ребром долоні вільної руки в ліктьовий згин  противника,  удар ребром долоні в шию, підсічка.</li>
   </ol>
    </div>
    <div className={s.programOl}><b>Акробатичні вправи: </b><br/>Кувирок вперед і назад.</div>
    <div className={s.programOl}><b>Тестування фізичної підготовленості:</b>Згинання і розгинання рук в упорі лежачи; <br/>Підйом у сід з положення лежачи. ( кількість раз відповідно до віку і статі спортсмена).<br/>Гнучкість (сидіти в шпагаті: поперечний, повздовжний). </div>
    <div className={s.programOl}><b>Знання основних положень правил змагань. </b></div>
    <div className={s.programOl}><b>Теоретичні питання:</b>
    <ol className={s.programList}>
    <li><b>Питання: </b>Які п’ять духовних принципів існують в ТХЕКВОНДО?<br/> <i>Відповідь: </i>
    <ul>
        <li>Чемність; </li>
        <li>Чесність;</li>
        <li>Наполегливість; </li>
        <li>Завзятість; </li>
        <li>Самоконтроль;</li>
    </ul>
    </li>
    <li><b>Питання: </b>Чому необхідно практикувати базову техніку і ПХУМСЕ?<br/> <i>Відповідь: </i>ПХУМСЕ і базову техніку необхідно практикувати для розвитку координації, спритності і майстерності в ТХЕКВОНДО. Відпрацювання цих розділів ТХЕКВОНДО формує впевненість і концентрацію.</li>
    <li><b>Питання: </b>Чому необхідно практикувати  мачіо-кьоругі (умовний поєдинок)?<br/> <i>Відповідь: </i>Цей вид спарингу потрібно відпрацьовувати для того, щоб розвинути навички ефективної атаки і захисту, контролю дихання, дистанції, точності, швидкості і релаксації.</li>
    <li><b>Питання: </b>Чому необхідна розтяжка в ТХЕКВОНДО?<br/> <i>Відповідь: </i>Розтяжка створює гнучкість, сприяє появі спритності і гарному самопочуттю. В ТХЕКВОНДО гнучкість дозволяє розвинути велику силу і швидкість при виконанні технік, а також розширити діапазон ділянок  тіла супротивника, на які спрямована атака.  </li>
    <li><b>Питання: </b>Що символізує зелений колір поясу?<br/> <i>Відповідь: </i>Символізує весну, квітучу природу, початок росту і можливостей.</li>
    </ol>
    </div>
     <div >
    </div>
</div> 

{/*6 куп (ПУРПУРНИЙ ПОЯС)  */}
<div className={s.rulesBlock}>
    <div className={s.programArticle} id='6kup'> 6 куп (ПУРПУРНИЙ ПОЯС).
        {isSmallScreen ? (<><br /></>) : null}
        <br/><a href="#Content" className={s.programContentLink}> <b> Зміст</b></a>
    </div>
    <div className={s.programOl}><b>Пхумсе:</b><br/>Тегук і чжан (№ 2). Термінологія.<br/>Форма тегук і чжан відповідає терміну  «тхе» РАДІСТЬ  (у східній філософії)</div>
    <div className={s.programOl}><b>Чагі суль з ап согі:</b>Будь-який удар ногою на вибір атестаційної комісії з попередніх купів.<br/>Тубон долльо чагі (момтон, ольгуль) <br/>Момдольо хурьо чагі (круговий удар ногою назад з розворотом корпуса) <i>Ударна техніка ніг перевіряється по мішені на вибір комісії.</i></div>
    <div className={s.programOl}><b>Чжаю кьоругі (вільний спаринг).</b></div>
    <div className={s.programOl}><b>Бат да чагі суль - техніка контратакуючих дій:</b><br/>Не менше п’яти контратакуючих дій проти долльо чагі.</div>
    <div className={s.programOl}><b>Хосінсуль – техніка самозахисту.</b></div>
    <div className={s.programOl}><b>Хосінсуль  від захвату за зап’ястя з допомогою больового контролю і кидкової техніки.</b>
    <ol className={s.programList}>
        <li>Блок зап’ястя  від прямого захвату. </li>
        <li>Блок зап’ястя  від перехресного  захвату.</li>
        <li>Викручування зап’ястя від прямого захвату. </li>
        <li>Викручування зап’ястя від перехресного захвату.</li>
        <li>«Рука-ніж»  від прямого захвату. </li>
        <li>«Рука-ніж» від перехресного захвату.</li>
    </ol>
    </div>
    <div className={s.programOl}><b>Акробатичні вправи: </b><br/>Кувирок вперед через плече;<br/>Стійка на руках + кувирок.<br/></div>
    <div className={s.programOl}><b>Тестування фізичної підготовленості: </b><br/>Згинання і розгинання рук в упорі лежачи; <br/>Підйом у сід з положення лежачи. ( кількість раз відповідно до віку і статі спортсмена).<br/>Гнучкість (сидіти в шпагаті: поперечний, повздовжний).</div>
    <div className={s.programOl}><b>Знання основних положень правил змагань.</b></div>
    <div className={s.programOl}><b>Теоретичні  питання:</b>
    <ol className={s.programList}>
        <li><b>Питання: </b>Що символізують кольори українського прапора?<br/><i>Відповідь: </i>Синій колір означає небо, жовтий — пшеничне поле, символізуючи прагнення України до миру і праці.</li>
        <li><b>Питання: </b>Який кодекс честі учнів в ТХЕКВОНДО ви знаєте?<br/><i>Відповідь: </i>
        <div className={!isSmallScreen ? s.contentContaner : null}>
            <div>
                <ul>
                    <li>Поважати один одного.</li>
                    <li>Бути чесним.</li>
                    <li>Бути терплячим до оточуючих.</li>
                    <li>Допомагати один одному.</li>
                </ul>
            </div>
             <div>
             <ul>
                    <li>Завжди захищати слабшого.</li>
                    <li>Бути вірним самому собі, і свої сім’ї.</li>
                    <li>Виконувати вказівки САБУМа і вірити йому.</li>
                    <li>Бути вірним своєму залу.</li>
                </ul>
             </div>
        </div>
        </li>
        <li><b>Питання: </b>Що необхідно пам’ятати, працюючи в парі?<br/><i>Відповідь: </i>
        <ul>
                    <li>Зійти з лінії атаки, або випередити супротивника своєю  контратакою.</li>
                    <li>Не підставляти супротивнику спину це - небезпечно, і така поведінка не суперечить принципам ТХЕКВОНДО.</li>
                    <li>Не напружуватися, але і не послаблювати увагу під час спарингу – це може призвести до травми або поразки.   </li>                   
                </ul>
        </li>
        <li><b>Питання: </b>Що символізує пурпуровий колір поясу?<br/><i>Відповідь: </i>Символізує весну, квітучу природу, початок росту, можливостей та силу позитивного мислення.</li>
    </ol>
    </div>
    <div >
    </div>
</div> 

{/*5 куп (БЛАКИТНИЙ ПОЯС)   */}
<div className={s.rulesBlock}>
    <div className={s.programArticle} id='5kup'>5 куп (БЛАКИТНИЙ ПОЯС).
        {isSmallScreen ? (<><br /></>) : null}
        <br/><a href="#Content" className={s.programContentLink}> <b> Зміст</b></a>
    </div>
    <div className={s.programOl}><b>Пхумсе: </b>Тэгук сам чжан (№ 3). <br/>Термінологія.<br/>Форма тэгук сам чжан відповідає поняттю «і» ВОГОНЬ, ВИЯВ (у східній філософії).</div>
    <div className={s.programOl}><b>Чагі суль.</b>
    <ol className={s.programList}>
        <li><i>Ап согі:</i><br/>Будь-який удар ногою на вибір атестаційної комісії з попередніх купів.<br/>Тубон йоп чагі (момтон, ольгуль) </li>
        <li>
        <div className={!isSmallScreen ? s.contentContaner : null}>
            <div>
                <ul>
                    <li>Йоп чагі,</li>
                    <li>Пандаль чагі, </li>
                    <li>Твіт чагі, </li>
                    <li>Дольо чагі, </li>
                    <li>Нерьо чагі, </li>
                    <li>Міро чагі, </li>
                </ul>
            </div>
            <div>
                <ul>
                    <li>Баккат дольо-чагі, </li>
                    <li>Момдольо хурьо чагі, </li>
                    <li>Момтон тольге пандаль чагі («торнадо» в середній рівень). </li>
                    <li>Наре-чагі (двійка, реверси) </li>
                   
                </ul>
                </div>
        </div>
        </li>
    </ol>
    </div>
    <div className={s.programOl}><b>Чжаю кьоругі (вільний спаринг).</b></div>
    <div className={s.programOl}><b>Бат да чагі суль - техніка контратакуючих дій:</b> <br/>Не менше п’ять контратакуючих дій проти йоп чагі.</div>
    <div className={s.programOl}><b>Хосінсуль – техніка самозахисту: </b><br/>Хосінсуль від захватів за одяг спереду з допомогою ударних технік і больового контролю.
    <ol className={s.programList}>
        <li>Удар ліктем збоку при захваті за плече.</li>
        <li>Удар тильною стороною ребра долоні при захваті за комір на грудях .</li>
        <li>Удар коліном при звільненні від захвату за комір на грудях двома руками.  </li>
        <li>Блок зап’ястя  від захвату за рукав біля ліктя. </li>
        <li>Блок зап’ястя при  захваті  за плече. </li>
        <li>Блок зап’ястя при захваті за комір на  грудях. </li>
        <li>Блок зап’ястя при захваті за комір на грудях двома руками. </li>
    </ol>
    </div>
    <div className={s.programOl}><b>Тестування фізичної підготовленості:</b><br/>Згинання і розгинання рук в упорі лежачи; <br/>Підйом у сід з положення лежачи. ( кількість раз відповідно до віку і статі спортсмена).<br/>Гнучкість (в поперчному шпагаті лежати на животі, в повздовжному – лежати на нозі яка знаходиться попереду. </div>
    <div className={s.programOl}><b>Акробатичні вправи: </b><br/>Колесо;<br/>Політ кувирок.</div>
    <div className={s.programOl}><b>Знання правил змагань.</b></div>
    <div className={s.programOl}><b>Теоретичні  питання:</b>
    <ol className={s.programList}>
        <li><b>Питання: </b>Який розміри майданчика, на якому проходять змагання?<br/><i>Відповідь: </i>Для проведення поєдинку на місці змагань виділяється  майданчик  із сторонами 8x8м.</li>
        <li><b>Питання: </b>Скільки бокових суддів обслуговують  змагання на корті (додянзі)?<br/><i>Відповідь: </i>Двоє або троє бокових суддів.</li>
        <li><b>Питання: </b>: Які вимоги пред’являються до костюмів учасників змагань?<br/><i>Відповідь: </i>Костюм ТХЭКВОНДО (добок), що складається з штанів і куртки білого кольору та поясу,  відповідної кваліфікації спортсмена, повинен відповідати наступним вимогам:
        <ul>
            <li>рукави куртки сягають до зап’ястя і повністю закривають накладки на передпліччях;</li>
            <li>довжина куртки повинна мати розмір, щоб повністю закривати пах;</li>
            <li>холоші штанів сягають п’ят і повність закривають накладки на гомілках. </li>
        </ul>
        Учасники змагань проводять поєдинок на босоніж. 
        </li>
        <li><b>Питання: </b>Що символізує блакитний колір поясу?<br/><i>Відповідь: </i>Колір неба, колір води. Учень прагне відчути себе частиною природи, частиною всесвіту. Пробуджує в собі бажання творити. Вивчає як техніку так і філософію тхеквондо.</li>
    </ol>
    </div>
    
    <div >
    </div>
</div> 

{/* 4 куп (СИНІЙ ПОЯС)  */}
<div className={s.rulesBlock}>
    <div className={s.programArticle} id='4kup'>4 куп (СИНІЙ ПОЯС) 
        {isSmallScreen ? (<><br /></>) : null}
        <br/><a href="#Content" className={s.programContentLink}> <b> Зміст</b></a>
    </div>
    <div className={s.programOl}><b>Пхумсе:</b>Тэгук-са-чжан (№ 4). <br/>Термінологія.<br/>Форма тэгук-са-чжан відповідає поняттю  «чжін» ГРІМ (у східній філософії ).<br/>Пхумсе на вибір атестаційної комісії  з 1 по 3.</div>
    <div className={s.programOl}><b>Чагі суль. </b>
        <div><i>Ап согі:</i> <br/>Бакат долльо чагі /долльо чагі  не опускаючи ноги (фехтування). </div>
        <div><i>Переміщення в стійках з ударами ногами:</i>
        <ol className={s.programList}>
            <li>З комбінацією з двох ударів різними ногами.</li>
             <li>З комбінацією з трьох ударів різними ногами:
             <div className={!isSmallScreen ? s.contentContaner : null}>
                <div>
                    <ul>
                        <li>Ап чагі;</li>
                        <li>Йоп чагі; </li>
                        <li>Момтон долльо чагі;</li>
                        <li>Твіт чагі; </li>
                        <li>Ольгуль  долльо чагі;  </li>
                    </ul>
                </div>
                <div>
                <ul>
                        <li>Нерьо чагі;</li>
                        <li>Міро чагі;</li>
                        <li>Ольгуль баккат долльо чагі;</li>
                        <li>Момдолльо хурьо чагі.</li>                       
                    </ul>
                </div>
             </div>

             </li>
        </ol>
        </div>
    </div>
    <div className={s.programOl}><b>Ханбон кьоругі:</b><br/>Від  момтон чжіругі (не менше п’яти варіантів контратаки з використанням  технік рук і ніг)</div>
    <div className={s.programOl}><b>Чжаю кьоругі  (вільний спаринг).</b></div>
    <div className={s.programOl}><b>Бат да чаги суль - техніка контратакуючих дій:</b><br/>Не менше  п’яти контратакуючих дій проти твід чагі.</div>
    <div className={s.programOl}><b>Хосінсуль – техніка самозахисту:</b><br/><i>Хосінсуль  від захватів за одяг ззаду:</i>.
        <ol className={s.programList}>
                <li>Удар ногою назад при захваті за комір;</li>
                <li>Закидання  підпорідка при захваті за комір;</li>
                <li>Викручування зап’ястка при захваті за комір; </li>
                <li>«Рука-ніж»  при захваті за комір;</li>
                <li>Удар в стопу, удар ліктем при обхваті під руками;</li>
                <li>Удар в стопу, «рука-ніж» при обхваті під руками;</li>
                <li>Викручування пальців при обхваті під руками;</li>
                <li>Удар в стопу, зрив захвату,  удар ліктем при обхваті зверху рук;</li>
                <li>Удар в стопу, поліцейский захват при обхваті зверху рук. </li>
                </ol>  
        </div>
    <div className={s.programOl}><b>Акробатика: </b><br/>Переворот вперед, <br/>Підйом розгином.</div>
    <div className={s.programOl}><b>Тестування фізичної підготовленості:</b><br/>Згинання і розгинання рук в упорі лежачи; <br/>Підйом у сід з положення лежачи. ( кількість раз відповідно до віку і статі спортсмена).<br/>Гнучкість (від’ємний  шпагат: поперечний, повздовжний).</div>
    <div className={s.programOl}><b>Знання правил змагань.</b></div>
     <div className={s.programOl}><b>Теоретичні питання:</b>
     <ol className={s.programList}>
        <li><b>Питання: </b>Які обов’язки і права спортсмена на змаганнях?<br/><i>Відповідь: </i>Основні з них це:
            <ul>
                <li>знати і суворо дотримуватись Правил змагань;</li>
                <li>бути ввічливим, коректним по відношенню до усіх учасників, суддів та осіб, що проводять та обслуговують змагання;</li>
                <li>виконувати всі вимоги суддів та представника (капітана) команди;</li>
                <li>суворо дотримуватись церемонії  початку та закінчення поєдинку (виступу).</li>
            </ul>
        </li>
        <li><b>Питання: </b>Які  5 складових фізичного розвитку ТХЕКВОНДО ви знаєте?<br/><i>Відповідь: </i>
            <ul>
                <li>зв'язок з навколишньою природою;</li>
                <li>випробовування себе у різних фізичних умовах;</li>
                <li>виконання необхідних для себе вправ;</li>
                <li>споживання здорової  їжі;</li>
                <li>необхідний відпочинок.</li>
            </ul>
        </li>
        <li><b>Питання: </b>Що символізує синій колір поясу?<br/><i>Відповідь: </i>Це колір неба, колір води. Учень прагне відчути себе частиною природи, частиною всесвіту. Пробуджує у собі бажання творити. Вивчає як техніку так і філософію тхеквондо. </li>
     </ol>
     </div>
    <div >
    </div>
</div> 

{/* 3 куп (ЧЕРВОНИЙ ПОЯС) */}
<div className={s.rulesBlock}>
    <div className={s.programArticle} id='3kup'>3 куп (ЧЕРВОНИЙ ПОЯС)
        {isSmallScreen ? (<><br /></>) : null}
        <br/><a href="#Content" className={s.programContentLink}> <b> Зміст</b></a>
    </div>
 <div className={s.programOl}><b>Пхумсе:</b><br/>Тэгук-о-джан (№ 5).<br/> Термінологія.<br/>Форма тэгук-о-чжан відповідає поняттю «сон» ВІТЕР (у східній філософії).<br/>Пхумсе на вибір атестаційної комісії з 1 по 4.</div>
 <div className={s.programOl}><b>Чагі суль. </b><br/>Будь-яка базова техніка ударів ногами.</div>
 <div className={s.programOl}><i>З  ап согі:</i><br/>Баккат долльо чагі/долльо чагі /йоп чагі не опускаючи ноги (фехтування).<br/>З  кьоругі  ча со спарингові удари в ціль.</div>
 <div className={s.programOl}><b>Ханбон кьоругі: </b><br/>Від момтон чжіругі (десять варіантів контратаки з використанням технік рук, ніг і ударів ногами в стрибку).</div>
 <div className={s.programOl}><b>Чжаю кьоругі (вільний спаринг).</b></div>
 <div className={s.programOl}><b>Бат да чагі суль - техніка контратакуючих дій:</b><br/>Не менше п’яти контратакуюxих дій проти мом долльо хурьо чагі.</div>
 <div className={s.programOl}><b>Хосінсуль – техніка самозахисту.</b><br/><i>Хосінсуль від ап чжіругі (прямого удару кулаком). Контр прийоми від прямого удару кулаком</i>
    <ol className={s.programList}>
        <li>Блокування руки, удар ліктем;</li>
        <li>Блокування руки, удар тильною стороною ребра долоні;</li>
        <li>Зустрічний удар ногою в бік (йоп чагі), удар ногою назад (твіт чагі);  </li>
        <li>Зустрічний удар ногою в бік (йоп чагі), круговий удар ногою (дольо чагі); </li>
        <li>Зустрічний удар ногою  назад (твіт чагі), удар ногою назад (твіт чагі); </li>
        <li>Зустрічний удар ногою назад (твіт чагі), круговий удар (дольо чагі); </li>
        <li>Викручування зап’ястка.</li>
        <li>«Рука-ніж».</li>
    </ol>
 </div>
 <div className={s.programOl}><b>Кьок па (розбивання): </b><br/>Ап-чагі.</div>
 <div className={s.programOl}><b>Акробатика:</b><br/>Вертушка на ноги із положення лежачи. <br/> Рондат.</div>
 <div className={s.programOl}><b>Тестування фізичної підготовленості:</b><br/>Згинання і розгинання рук в упорі лежачи; <br/>Підйом у сід з положення лежачи. ( кількість раз відповідно до віку і статі спортсмена).<br/>Гнучкість (вертикальний шпагат в стійці ).</div>
 <div className={s.programOl}><b>Знання  правил змагань.</b></div>
 <div className={s.programOl}><b>Теоретичні питання:</b>
    <ol className={s.programList}>
        <li><b>Питання: </b>Яка структура  WTF (Всесвітня Федерація Тхеквондо)?<br/><i>Відповіді: </i>WTF підпорядковані континентальні союзи, до  яких  входять національні федерації. </li>
        <li><b>Питання: </b>Коли  засновано WTF?<br/><i>Відповіді: </i>28 травня 1973 року.</li>
        <li><b>Питання: </b>Коли засновано  ETU (Європейський союз ТХЭКВОНДО)?<br/><i>Відповіді: </i>2 травня 1976 року.</li>
        <li><b>Питання: </b>В якому році Україна стала членом WTF і ETU ?<br/><i>Відповіді: </i>В 1993 році.</li>
        <li><b>Питання: </b>На які види поділяються  змагання з ТХЕКВОНДО ( WTF )?<br/><i>Відповіді: </i>Змагання розподілені наступним чином:
            <div className={s.programOl}><span className={s.num}>1. </span>Особисті змагання. Повинні проводитися , як правило, між учасниками однієї  вагової категорії.  У разі  необхідності дві суміжні категорії можуть бути об’єднанні для створення однієї вагової категорії).</div>
            <div className={s.programOl}><span className={s.num}>2. </span>Командні: системи командних змагань по:
            <div className={s.programOl}><span className={s.num}>2.1. </span>п’ять (5) учасників у відповідності до вагових категорій;</div>
            <div className={s.programOl}><span className={s.num}>2.2. </span>вісім (8) учасників, розділених за ваговими категоріями;</div>
            <div className={s.programOl}><span className={s.num}>2.3. </span>чотири (4) учасника, розділених за ваговими категоріями ( перетвореними з восьми вагових категорій в чотири категорії шляхом об’єднання двох  суміжних в одну).</div>
            </div>
            <div className={s.programOl}><span className={s.num}>3. </span>Особисто-командні.</div>
            <div className={s.programOl}><span className={s.num}>4. </span>Кваліфікаційні.</div>
            <div className={s.programOl}><span className={s.num}>5. </span>Змагання по ПХУМСЕ.</div>
        </li>
        <li><b>Питання: </b>Назвіть способи організації змагань?<br/><i>Відповіді: </i>Способи проведення змагань розподіляються  наступним чином:
            <ul>
                <li>змагання за індивідуальною системою;</li>
                <li>змагання за коловою системою;</li>
            </ul>
        </li>
        <li><b>Питання: </b>Які чотири групи порушень входять в шкалу штрафних санкцій  (КЬОН-ГО і ГАМ-ЧЖЬОН)?<br/><i>Відповіді: </i>
        <ul>
                <li>Атакуючі дії;  </li>
                <li>Дії на дотик;  </li>
                <li>Негативні дії;  </li>
                <li>Небажані дії. </li>
            </ul>
        </li>
        <li><b>Питання: </b>Що символізує червоний колір поясу?<br/><i>Відповіді: </i>Колір стиглості плодів  і зрілості знань. Володар червоного поясу повинен  подивитися на пройдений шлях і визначити для себе нові  цілі  та нові завдання. Також червоний колір символізує розвиток міцної основи тіла і характеру бійця.</li>
    </ol>
 </div>
    <div >
    </div>
</div> 

{/* 2 куп (СВІТЛО-КОРИЧНЕВИЙ ПОЯС)  */}
<div className={s.rulesBlock}>
    <div className={s.programArticle} id='2kup'>2 куп (СВІТЛО-КОРИЧНЕВИЙ ПОЯС) 
        {isSmallScreen ? (<><br /></>) : null}
        <br/><a href="#Content" className={s.programContentLink}> <b> Зміст</b></a>
    </div>
 <div className={s.programOl}><b>Пхумсе:</b><br/>Тэгук-юк-джан (№ 6).<br/> Термінологія.<br/>Форма тэгук-юк-джан відповідає поняттю «кам» ВОДА (у східній філософії).<br/>Пхумсе на вибір атестаційної комісії з 1 по 5.</div>
 <div className={s.programOl}><b>Чагі суль:</b><br/>Будь-яка  базова техніка ударами ногами.<br/>Будь-яка базова спарингова техніка ударами ногами, перевіряється на лапах.</div>
 <div className={s.programOl}><b>Ханбон кьоругі:</b><br/>Від момтон чжіругі  ( не менше десяти варіантів контратак з виконанням техніки  рук, ніг, ударами ногами в стрибку, а також кількох ударів ногами без постановки ударної ноги).</div>
 <div className={s.programOl}><b>Чжаю кьоругі (вільний спаринг).</b></div>
 <div className={s.programOl}><b>Бат да чагі суль - техніка контратакуючих дій:</b><br/>Не менше п’яти контратакуючих дій проти тольге чагі («торнадо»).</div>
 <div className={s.programOl}><b>Хосінсуль – техніка самозахисту.</b><br/><i>Хосінсуль від ударів ногами. Контр прийоми від ударів ногами </i>
    <ol className={s.programList}>
        <li>Підсічка зі сходом з лінії атаки всередину від прямого удару; </li>
        <li>Підсічка зі сходом з лінії атаки назовні від прямого удару; </li>
        <li>Підсічка зі сходом з лінії атаки всередину і прихватом двома руками від прямого удару; </li>
        <li>Підсічка зі сходом з лінії атаки назовні і прихватом двома руками  від прямого удару; </li>
        <li>Підсічка зі сходом з лінії атаки всередину від кругового удару; </li>
        <li>Підсічка зі сходом з лінії атаки назовні від кругового удару; </li>
        <li>Удар в підколінний згин зі сходом з лінії атаки назовні від удару ногою вбік;</li>
        <li>Підсічка зі сходом з лінії атаки всередину від удару ногою в бік;</li>
        <li>Підсічка зі сходом з лінії атаки назовні від удару ногою вбік;</li>
    </ol>
 </div>
 <div className={s.programOl}><b>Кьок па (розбивання): </b><br/>Паро-чжіругі;<br/> Долльо-чагі.</div>
 <div className={s.programOl}><b>Акробатика: </b><br/>Політ кувирок в довжину і висоту.</div>
 <div className={s.programOl}><b>Тестування фізичної підготовленості:</b><br/>Згинання і розгинання рук в упорі лежачи;<br/>Підйом у сід з положення лежачи. ( кількість раз відповідно до віку і статі спортсмена).</div>
 <div className={s.programOl}><b>Знання правил змагань.</b></div>
 <div className={s.programOl}><b>Теоретичні питання.</b>
        <ol className={s.programList}>
            <li><b>Питання: </b>Яка структура Української федерації ТХЕКВОНДО ( WTF )?<br/><i>Відповідь: </i>Українська федерація ТХЕКВОНДО поділяється на обласні та міські федерації, які діють по узгодженому плану роботи з керівництвом Національної федерації ТХЕКВОНДО ( WTF ).</li>
            <li><b>Питання: </b>На якій Олімпіаді  ТХЕКВОНДО було представлене  як вид спорту?<br/><i>Відповідь: </i>Як показовий вид спорту ТХЕКВОНДО було представлене  в програмі 24-х   Олімпійських ігор в Сеулі  (17-20 вересня  1988 року, Південна Корея).</li>
            <li><b>Питання: </b>Які змагання проводяться в рамках рамках WTF і ETU?<br/><i>Відповідь: </i>Чемпіонат світу, чемпіонати Європи, різноманітні міжнародні турніри і національні першості. </li>
            <li><b>Питання: </b>Чому використовується техніки розбивання предметів в підготовці  спортсмена в ТХЕКВОНДО?<br/><i>Відповідь: </i>Техніка розбивання предметів використовується, щоб розвинути концентрацію, силу і точність удару, а також для того, щоб дієвим способом перевірити ефективність різної техніки  ТХЕКВОНДО. </li>
            <li><b>Питання: </b>Коли МОК  ухвалив рішення про включення  ТХЕКВОНДО ( WTF ) як офіційного виду спорту  в програму  Олімпійських ігор ?<br/><i>Відповідь: </i>4 вересня 1994 року  на 103й сесії  МОК  в Парижі (Франція). </li>
            <li><b>Питання: </b>Що символізує коричневий колір поясу?<br/><i>Відповідь: </i>Означає землю та гори, розвиток потужної основи в техніці і характері. Це дерево, яке росте із землі не дивлячись на будь-які перепони. </li>
        </ol>
 </div>   
    <div >
    </div>
</div> 

{/*1 куп (ТЕМНО-КОРИЧНЕВИЙ ПОЯС)   */}
<div className={s.rulesBlock}>
    <div className={s.programArticle} id='1kup'>1 куп (ТЕМНО-КОРИЧНЕВИЙ ПОЯС) 
        {isSmallScreen ? (<><br /></>) : null}
        <br/><a href="#Content" className={s.programContentLink}> <b> Зміст</b></a>
    </div>
 <div className={s.programOl}><b>Пхумсе:</b><br/>Тегук чіль чжан (№ 7). <br/>Термінологія.<br/>Форма тэгук чіль чжан відповідає поняттю «кан» ГОРА (у східній філософії).<br/>Пхумсе на вибір атестаційної комісії з 1 по 6.</div>
 <div className={s.programOl}><b>Чагі суль:</b><br/>Будь-яка базова техніка ударами ногами.<br/>Будя-яка спарингова  комбінаційна техніка ударами ногами,перевіряється на лапах.</div>
 <div className={s.programOl}><b>Твіо чагі суль (техніка ударів ногами в стрибку):</b><br/>Твіо ап чагі; <br/>Твіо дольо чагі; <br/>Твіо йоп чагі;<br/>Твіо твіт чагі; <br/>Твіо мом дольо хурьо чагі</div>
 <div className={s.programOl}><b>Ханбон кьоругі: </b><br/>Від момтон чжіругі (не менше п’ятнадцяти варіантів контратак з використанням  технік рук, ніг, ударів ногами в стрибку, кількох ударів ногами ногами без постановки ударної ноги, а також  комбінацій перекислених технік.</div>
 <div className={s.programOl}><b>Чжаю кьоругі  (вільний спаринг).</b></div>
 <div className={s.programOl}><b>Бат да чагі суль - техніка контратакуючих дій:</b><br/>Не менше десяти контратакуючих дій  проти одиночної атаки ногою (будь-який удар).</div>
 <div className={s.programOl}><b>Хосінсуль – техніка самозахисту.</b><br/><i>Хосінсуль від  ударів ножем. Контр прийоми проти нападника з ножем</i>
            <ol className={s.programList}>
                <li>Викручування зап’ястка від загрози ножем зблизька.</li>
                <li>Блок ліктя всередину від загрози ножем зблизька.</li>
                <li>Кручений удар, круговий удар від загрози ножем з  далека. </li>
                <li>Круговий удар, обернений круговий удар назад від загрози ножем з  далека.</li>
                <li>Схід з лінії атаки, круговий удар від удару ножем зверху.</li>
                <li>Х-блок, «рука-ніж» від удару ножем зверху.</li>
                <li>Схід з лінії атаки, круговий удар від удару ножем знизу.</li>
                <li>«Рука-ніж» від удару ножем знизу.</li>
                <li>Зустрічний удар ногою назад від удару ножем збоку.</li>
                <li>«Рука-ніж» від удару ножем на відмаш.</li>
            </ol> 
 </div>
 <div className={s.programOl}><b>Кьок па (розбивання): </b><br/>Будь-яка техніка рукою або ногою з попередньо освоєних.</div>
 <div className={s.programOl}><b>Акробатика: </b><br/>На вибір  атестаційної комісії.</div>
 <div className={s.programOl}><b>Тестування фізичної підготовленості:</b><br/>Згинання і розгинання рук в упорі лежачи; <br/>Підйом у сід з положення лежачи ( кількість раз відповідно до віку і статі спортсмена).</div>
 <div className={s.programOl}><b>Знання правил змагань і надання першої медичної допомоги.</b></div>
  <div className={s.programOl}><b>Теоретичні питання:</b><br/>
        <ol className={s.programList}>
        <li><b>Питання: </b>Чому послідовники ТХЕКВОНДО практикують медитацію?<br/><i>Відповідь: </i>Послідовники ТХЕКВОНДО практикують медитацію для отримання духовної енергії (Кі). Медитація допомагає досягнути  глибшого внутрішнього бачення і глибшого пізнання себе, відчуття спокою і розслабленості. Ця внутрішня рівновага може стати звичкою навіть під час великого стресу. В цьому стані розум чистий від емоцій, відчаю; підтримується самоконтроль і власні дії стають більш чіткими. Медитація допомагає  сконцентрувати дії, коли це необхідно. </li>       
        <li><b>Питання: </b>Що символізує корейський прапор?<br/><i>Відповідь: </i>Корейський прапор символізує глибину думки, філософію і мудрість Сходу. Прапор іменується  ТЕГИК КІ. ТЕГИК – означає  «джерела усього сущого у  Всесвіті».  Круг в центрі  прапора пропорційно діляться  на червону  (Ян) і блакитну (Юм) частину горизонтальною буквою « S » . Ці дві протилежні частини символізують дуалізм (подвійність) космосу: вогонь і воду, день і ніч, темряву і світло, небо і землю, буття і небуття і т.д.  Це баланс сил природи і гармонії. Тут відображено вчення про дві природні сутності людини —  фізичної і духовної.</li>
        <li><b>Питання: </b>Яка історія створення ТХЕКВОНДО?<br/><i>Відповідь: </i><br/>
        Історія тхеквондо починається близько двох тисяч років назад, що підтверджується древніми малюнками і зображеннями.  У кінці I століття до н. е. на території сучасної Кореї виникли три держави: Когурьо, Пекче і Сила. Передбачається, що вже в цей період почали формуватися різні системи бою, що послужили праобразами сучасного тхеквондо. Зокрема, такі системи бою як: субак, тхеккен, чхарьок, оренквон, юсуль та ін.<br/><br/>
        Найбільший внесок у розвиток у розвиток тхеквондо внесли елітні офіцерські загони -   ХВА РАН («Квітуча молодь»).  Це була військова-соціальна організація молоді і знатних кіл. Шляхом ретельних тренувань ХВА РАН готував хоробрих командирів. Їх навчали бойовому мистецтву, вчили бути зразком культурного, орденського воїна  — вони були надією нації.  ТХЕКВОНДО — це  духовне продовження  ХВА РАН. <br/><br/>
        Основним історичним документом, що відбиває знання древніх корейців про бойові мистецтва, являється трактат «МУЕ  ДОБО ТОНЧЖІ.», який було створено в 1790 році видатним корейським вченим і державним діячем Лі Донму. Трактат містить опис різних систем бою із зброєю, а також окремі методи кулачного бою.<br/><br/>
        У XVII – XIX ст. бойові мистецтва Кореї практично не розвивалися: деякі з них були забуті, якісний рівень інших знизився.<br/><br/>
        Після Корейської війни 1950-1953 рр. в суспільстві виникла ідея створення єдиного національного бойового мистецтва, яке стало б символом духу нації. Реалізував цю ідею генерал Чхве (Чой) Хон Хі в 1955 році. Нове бойове мистецтво було створено на базі  традиційних корейських видів боротьби субак і тхеккен з використанням елементів карате, яке творець стилю вивчав в Японії. Корейське національне бойове мистецтво стало уніфікованим варіантом, що об'єднало техніку дев'яти післявоєнних шкіл, і дістало назву «тхеквондо» – «шлях руки і ноги», або «шлях ноги і кулака».<br/><br/>
        У листопаді 1972 року було відкрито центр дослідження і популяризації тхеквондо «Кукківон» в Сеулі. Він здійснює офіційне привласнення данів і видачу сертифікатів, підготовку інструкторів, веде хроніку рекордів тхеквондо, видає навчальні посібники і випускає навчально-методичні відеофільми.<br/><br/>
        В вересні 1988 р. на Олімпіаді в Сеулі ТХЕКВОНДО  було представлене  показовим виступом. У вересні 1994 року в Парижі на ювілейній сесії Міжнародного олімпійського комітету виноситься одноголосна ухвала про включення тхеквондо ВТФ в офіційну программу 27-х  Олімпійських ігор в Сіднеї (27-30 вересня 2000 року, Австралія).<br/><br/>
        Сьогодні тхеквондо – сучасний вид спорту і бойове мистецтво, що має духовно-філософське коріння і адаптоване для масового фізичного виховання і зараз, його практикують більше як  у 120 країнах світу.
        </li>
        <li><b>Питання: </b>Що символізує коричневий колір поясу?<br/><i>Відповідь: </i>Коричневий колір поясу символізує землю та гори, розвиток потужної основи в техніці і сформований характер. Це дерево, яке росте із землі не дивлячись на будь-які перепони. </li>
        <li><b>Питання: </b>Що символізує чорний колір поясу?<br/><i>Відповідь: </i>Чорний колір об’єднує всі кольори. Символізує глибокі  і міцні знання, підсумовує всі попередні рівні підготовки. Це мудрість і майстерність.</li>       
         </ol>
  </div>
    <div >
    </div>
</div> 

{/* 1 дан (ЧОРНИЙ ПОЯС)  */}
<div className={s.rulesBlock}>
    <div className={s.programArticle} id='1dan'>1 дан (ЧОРНИЙ ПОЯС) 
        {isSmallScreen ? (<><br /></>) : null}
        <br/><a href="#Content" className={s.programContentLink}> <b> Зміст</b></a>
    </div>
 <div className={s.programOl}><b>Пхумсе:</b><br/>Тэгук-паль-джан (№ 8).<br/> Термінологія.<br/>Пхумсе за вибором учня з 4 по 7.<br/>Додатково будь-яке пхумсе на вибір атестаційної комісії.</div>
 <div className={s.programOl}><b>Твіо чагі суль (техніка ударів ногами в стрибку):</b>
    <div className={!isSmallScreen ? s.contentContaner : null}>
        <div>
            <ul>
                <li>твіо момтон ап чагі;</li>
                <li>твіо ольгуль ап чагі;</li>
                <li>твіо ап паль олігі;</li>
                <li>твіо нерьо чагі;</li>
                <li>твіо міро чагі;</li>
                <li>твіо пандаль чагі;</li>
                <li>твіо момтон дольо чагі;</li>
                <li>твіо бітуро чагі;</li>
                <li>твіо ольгуль сево ан чагі;</li>
                <li>твіо ольгуль сево баккат чагі;</li>
            </ul>
        </div>
        <div>
        <ul>
                <li>твіо ольгуль пандаль чагі;</li>
                <li>твіо ольгуль дольо чагі;</li>
                <li>твіо ольгуль баккат дольо чагі;</li>
                <li>твіо момтон йоп чагі;</li>
                <li>твіо ольгуль йоп чагі;</li>
                <li>твіо твіт чагі;</li>
                <li>твіо момдольо хурьо чагі;</li>
                <li>твіо ольгуль момдольо сево баккат чагі;</li>
                <li>твіо ольгуль тольге чагі </li>
            </ul>
        </div>
    </div>
 </div>
 <div className={s.programOl}><b>Ханбон кьоругі</b><br/>Із  завдання атестаційної комісії.</div>
 <div className={s.programOl}><b>Чжаю кьоругі (вільний спаринг).</b></div>
 <div className={s.programOl}><b>Бат да чагі суль - техніка контратакуючих дій:</b><br/>Не менше десяти контратакуючих дій протии здвоєної  атаки ногами (будь-які удари).</div>
 <div className={s.programOl}><b> 2 хвилинний спаринг по правилам ВТФ.</b></div>
 <div className={s.programOl}><b>Хосінсуль – техніка самозахисту.</b><br/>На вибір атестаційної комісії.</div>
 <div className={s.programOl}><b>Кьок па (розбивання): </b><br/>1 техніка ногою і 1 рукою на вибір комісії і  учня.</div>
 <div className={s.programOl}><b>Акробатика:</b><br/> На вибір атестаційної комісії.</div>
 <div className={s.programOl}><b>Тестування фізичної підготовленості:</b><br/>Згинання і розгинання рук в упорі лежачи;<br/>Підйом у сід з положення лежачи. ( кількість раз відповідно до віку і статі спортсмена).</div>
 <div className={s.programOl}><b>Повне знання правил змагань ВТФ.  Фаховість в наданні першої медичної допомоги. </b></div> 
 <div className={s.programOl}><b>Теоретичні питання.</b><br/>Будь-які з даної програми або додатково згідно вимог атестаційної комісії.  </div> 
    <div >
    </div>
</div> 
</>


{/*  */}
{/* <div className={s.rulesBlock}>
    <div className={s.programArticle} id='#'>Стаття .
        {isSmallScreen ? (<><br /></>) : null}
        <br/><a href="#Content" className={s.programContentLink}> <b> Зміст</b></a>
    </div>

    <div className={s.programOl}><span className={s.num}>1. </span></div>

    <div className={s.programOl}><b></b></div>
    
    <div >
    </div>
</div>  */}
</div>
<BackButton langSwitch={langSwitch}/><br/>&nbsp;
</>)
}

export default ProgramWT