
import lang from '../../state/language';
import state from '../../state/state';
import s from '../content/results/result.module.css'


function InformationForUsers({isSmallScreen,langSwitch}){
   
    return(<>
           
              <div className={s.informationForUsersWriper}>
            <div className={s.informationForUsersTitle}>
              Умови користування платформою GridsTkd.
            </div>
           <div className={s.informationForUsers}>GridsTkd є платформою для організації та адміністрування змагань та iнших спортивних заходів.</div>
              <div className={s.informationForUsers}>Основною метою створення платформи, є полегшення роботи Суддів, Секретарів, Адміністрації змаганнь та частково Тренерам. 
                 </div>
              <div className={s.informationForUsers}>
                Основним засобом досягнення вище означеної мети є максимальна автоматизація усіх робочих процесів. У зв'язку з чим у нашої платформи підвищені вимоги що до якості оформлення профілю користувача.
             Спортсменам, що не дотримуватимуться вимог що до оформлення персонального профілю буде відмовлено в реєстрації на Турнир, у зв'язку з відcутністтю, або не актуальністтю наданої інформації для організації якісного адміністрування заходу. 
              </div>
              <div className={s.informationForUsers}>
                Усім суддям, секретерям, адміністраторам змагань, а також усім тренерам надається необмежений безкоштовний доступ до платформи з повною технічною підтримкою.
              </div>
              <div className={s.informationForUsers}><b>Будь які питання з приводу технічної підтримки спортсменів розглядаються виключно після звернення тренера!</b></div>
              <div className={s.informationForUsers}>
                Усім спортсменам надається необмежений доступ до інформаційних ресурсів платформи. Проте зареєструватись на змагання спортсмен може протягом <b>30 днів активного періоду</b>, вартість якого становить <b>150&nbsp;грн.</b>
              </div>
              <div className={s.informationForUsers}>
                На даний момент платформа знаходиться на фінальному етапі розробки та тестування. У зв'язку з чим усім користувачам (із спортсменами включно) надається необмежений доступ до усіх ресурсів платформи до закінчення періоду тестування та відлагодження
              </div>
              
              <div className={s.informationForUsers}></div>

              </div>



      
        </>)
}
export default  InformationForUsers
