import React from 'react';
import s from './adminCompetition.module.css'
import state from '../../../state/state';


function CompetitionHeder ({logo, title, notes}) {
  return (
    <div className={s.noPrint}>
    <div className={s.titleWriper}>
    <div className={s.titleWriperItem1}>
    <img src={logo?logo:state.noLogoPhoto} 
           alt={title} />
    </div>           
        <div className={s.titleWriperItem2}>
           <h1 className={s.h1}> {title} </h1></div>
          <div className={s.titleWriperItem3}>
           <h2 className={s.h2}>{notes}</h2>        
          </div>
   </div></div>
  );
};

export default CompetitionHeder;