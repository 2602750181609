import { useState } from "react";
import state from "../../../state/state"


function UpdateArchiveTR({item,resultData}){
    const [wiewForm, setWiewForm] = useState(false)
    const [place, setPlace] = useState(null)

    const handlePlaceChange = (e) => { const value = e.target.value; setPlace(value.trim()); 
    const index=resultData.findIndex((i)=>i.id==item.id)
    resultData[index].competitionPlace=value.trim()
    };
   
    if(place){item.competitionPlace=place}
    return(<>
    <tr  key={item.id}>
        {wiewForm?(<>
            <td >
            <label htmlFor="place">
               <b>Місце</b> </label><br />
                <input style={{ width: '50px', textAlign: 'center' }}
               onChange={handlePlaceChange}
                 type="text"
                 id="place"
                   name="place" />
                 </td>

        </>):(<>
        <td>{item.competitionPlace}</td>
        </>)}
       
        <td style={{cursor:'pointer'}} onClick={()=>{setWiewForm(!wiewForm)}}>
            {item.secondName} {item.personName}
            </td>
        <td>{state.formatDate(item.birthday)}</td>
        <td>{item.rank}</td>
        <td>{item.dan}</td>
        <td>{item.fst}</td>
        <td> {state.myCoach(item.coach)}<br/>{state.myCoach(item.coach2)}</td>
        <td >{state.regionShort(item.region)}<br/>{item.sportClub}</td>
  </tr>
    </>)
}

export default UpdateArchiveTR