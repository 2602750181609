
import s from './documents.module.css'
import BackButton from './backButton';
import lang from '../../../state/language';


function RulesKeirugue({ isSmallScreen,langSwitch }) {
    const contentData = [
        { link: "#Purpose", text: 'Мета проведення' },
        { link: "#Application", text: 'Заявка' },
        { link: "#CompetitionArea", text: 'Зона проведення змагань' },
        { link: "#Contestant", text: 'Учасники змагань' },
        { link: "#WeightCategory", text: 'Вагова категорія' },
        { link: "#Classification", text: 'Класифікація та методи організації змагань' },
        { link: "#Duration", text: 'Тривалість змагання' },
        { link: "#Drawing", text: 'Жеребкування' },
        { link: "#Weigh-in", text: 'Зважування' },
        { link: "#ProcedureOfTheContest", text: 'Порядок проведення поєдинку' },
        { link: "#PermittedTechniquesAndAreas", text: 'Дозволені техніки та зони' },
        { link: "#ValidPoints", text: 'Зарахування балів' },
        { link: "#Scoring", text: 'Зарахування балів (PSS та судді)' },
        { link: "#ProhibitedActsAndPenalties", text: 'Заборонені дії та штрафні санкції' },
        { link: "#GoldenPoints", text: 'Золотий бал та рішення переваги' },
        { link: "#Decisions", text: 'Рішення' },
        { link: "#KnockDown", text: 'Нокдаун' },
        { link: "#KnockDownProcedure", text: 'Порядок дій у разі нокдауну' },
        { link: "#SuspendingMatch", text: 'Процедури призупинення матчу' },
        { link: "#TechnicalOfficials", text: 'Технічні делегати' },
        { link: "#IVR", text: 'Відеоповтор' },
        { link: "#DeafTaekwondo", text: 'Тхеквондо для глухих' },
        { link: "#Sanctions", text: 'Санкції' },
        { link: "#Other", text: ' Інші питання, не передбачені Правилами Змагань' }
    ]
    const middleIndex = Math.ceil(contentData.length / 2);
    const firstHalf = contentData.slice(0, middleIndex);
    const secondHalf = contentData.slice(middleIndex);

    return (<>
    <BackButton langSwitch={langSwitch}/>
        <div className={s.rulesWriper}>
           
            <div className={s.rulesTitle}>Правила змагань в розділі Керугі з поясненнями<br />
                (діючі з 14 червня 2024р.)<br />

                <a href="https://www.worldtaekwondo.org/rules-wt/rules.html"
                    target='blank'
                    className={s.rulesSourseLink}>
                   {lang[langSwitch].source /*Джерело*/} 
                    </a>
            </div>
 {/* Зміст */}
            <div className={s.rulesContentBox}>
                <div id='Content'>Зміст</div><br />
                <div className={!isSmallScreen ? s.contentContaner : null}>
                    <div className={s.rulesContentBoxItem1}>
                        {firstHalf.map((item, index) => {

                            return (<>
                                <div key={'first' + index}>
                                    <span className={s.num}>{index + 1}. </span>
                                    <a href={item.link} className={s.rulesContentLink1}>
                                        {item.text}
                                    </a>
                                </div>
                            </>)
                        })}
                    </div>
                    <div className={s.rulesContentBoxItem2}>
                        {secondHalf.map((item, index) => {

                            return (<>
                                <div key={'second' + index}>
                                    <span className={s.num}>{firstHalf.length + (index + 1)}. </span>
                                    <a href={item.link} className={s.rulesContentLink1}>
                                        {item.text}
                                    </a>
                                </div>

                            </>)
                        })}
                    </div>
                </div>
            </div>

            {/* Мета */}
            <div className={s.rulesBlock}>
                <div className={s.rulesArticle} id='Purpose'>
                    Стаття 1. Мета проведення.  {isSmallScreen ? (<><br /></>) : null}
                    <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
                </div>

                <div className={s.rulesOl}>
                    <span className={s.num}>1. </span>Метою Правил змагань є надання стандартизованих правил для всіх рівнів
                    Чемпіонатів, що проводяться та/або визнаються Всесвітньою федерацією
                    тхеквондо (далі WT), Континентальними союзами WT (далі CU) та/або
                    національними асоціаціями-членами WTM (далі MNA). Правила змагань
                    спрямовані на те, щоб усі питання, пов’язані із змаганнями, проходили
                    чесно та впорядковано.
                </div>

                <div className={s.rulesOl}>  <b>Пояснення. </b>Метою статті 1 є забезпечення стандартизації всіх змагань з тхеквондо в усьому світі. Будь-які змагання, які не відповідають основним принципам цих правил, не можуть бути визнані змаганнями з тхеквондо.</div>
            </div>

            {/* Заявка */}
            <div className={s.rulesBlock}>
                <div className={s.rulesArticle} id='Application'>Стаття 2. Заявка.
                    {isSmallScreen ? (<><br /></>) : null}
                    <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
                </div>

                <div className={s.rulesOl}>
                    <span className={s.num}>1. </span>Правила змагань застосовуються до всіх змагань, які проводяться та/або визнаються WT, кожним CU та MNA. Однак будь-який MNA, який бажає змінити деякі або будь-які частини Правил змагань, повинен спочатку отримати попереднє схвалення WT. У випадку, якщо CU та/або MNA порушує Правила змагань без попереднього схвалення WT, WT в односторонньому порядку, відхилити або відкликати своє схвалення відповідного міжнародного турніру. Крім того, WT може вжити подальших дисциплінарних заходів до відповідного CU або MNA.
                </div>
                <div className={s.rulesOl}>
                    <span className={s.num}>2. </span>Усі змагання, які проводяться або визнаються WT та/або кожним CU та/або MNA, повинні дотримуватися Статуту WT, Положення про вирішення суперечок і дисциплінарних дій, а також усіх інших відповідних правил і положень.
                </div>
                <div className={s.rulesOl}>
                    <span className={s.num}>3. </span>Усі змагання, які проводяться або визнаються WT та/або кожен CU та/або MNA повинен дотримуватися Медичного кодексу WT та антидопінгових правил WT.
                </div>

                <div className={s.rulesOl}><b> Пояснення №1</b> Первинне схвалення: будь-яка організація, яка бажає внести зміни в будь-яку частину існуючих правил, повинна подати WT зміст бажаної поправки разом із причинами бажаних змін. Схвалення будь-яких змін у цих правилах має бути отримано від WT принаймні за місяць до запланованого змагання. WT може застосовувати Правила змагань зі змінами в своїх Чемпіонатах за рішенням Технічного делегата після схвалення Президента.</div>
            </div>

            {/* Зона проведення змагань. */}
            <div className={s.rulesBlock}>
                <div className={s.rulesArticle} id='CompetitionArea'>Стаття 3. Зона проведення змагань.
                    {isSmallScreen ? (<><br /></>) : null}
                    <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
                </div>

                <div className={s.rulesOl}>
                    <span className={s.num}>1. </span>Зона для змагань повинна мати рівну поверхню без будь-яких виступів, що заважають, і мати еластичне і не слизьке покриття. Зона змагань також може бути встановлена на платформі висотою 0,6-1 м від основи, якщо це необхідно. Зовнішня частина зовнішньої лінії має бути нахилена під кутом менше 30 градусів для безпеки учасників. Одну з наведених нижче форм можна використовувати для зони змагань.


                    <div className={s.rulesOl}>
                        <span className={s.num}>1.1. </span>Корт квадратної форми складається з зони змагань і зони безпеки. Зона для змагань квадратної форми має бути 8 х 8 м, включаючи 60 см обмежувальну лінію. Зона безпеки оточує зону змагань приблизно на однаковій відстані з усіх боків. Розмір зони змагань (яка охоплює зону змагань і зону безпеки) має бути не меншим за 10 м x 10 м і не більшим за 12 м x 12 м. Якщо зона змагань знаходиться на платформі, зона безпеки може бути збільшена за потреби для забезпечення безпеки учасників. Зона змагань і зона безпеки можуть мати різні кольори, як зазначено в Керівництві з проведення відповідних змагань.
                    </div>
                    <div className={s.rulesOl}>
                        <span className={s.num}>1.2. </span>Корт восьмикутної форми складається з зони змагань і зони безпеки. Майданчик має бути квадратної форми, а розміри не менше ніж 10 м x 10 м і не більше ніж 12 м x 12 м. У центрі зон змагань має бути зона змагань восьмикутної форми. Зони змагань мають мати приблизно 8 м у діаметрі, а кожна сторона восьмикутника повинна мати довжину приблизно 3,3 м. Між зовнішньою лінією зони змагань і обмежувальною лінією зони змагань знаходиться зона безпеки. Зона змагань і зона безпеки можуть мати різні кольори, як зазначено в Керівництві з проведення відповідних змагань.
                    </div>
                </div>
                <div className={s.rulesOl}>
                    <span className={s.num}>2. </span>Розташування посад


                    <div className={s.rulesOl}><span className={s.num}>2.1. </span>Зовнішня лінія зони змагань має називатися обмежувальною(ими) лінією(ями), а зовнішня лінія зони змагань має називатися зовнішньою лінією(ями).</div>
                    <div className={s.rulesOl}><span className={s.num}>2.2. </span>Передня зовнішня лінія, що примикає до столу судді, має називатися зовнішньою лінією №1, а за годинниковою стрілкою від зовнішньої лінії №1 інші лінії мають називатися зовнішніми лініями №2, №3 та №4. Обмежувальна лінія, що примикає до зовнішньої лінії №1, має називатися розмежувальною лінією №1 і за годинниковою стрілкою від розмежувальної лінії №1, інші лінії мають називатися розмежувальними лініями №2, №3 та №4. У випадку восьмикутної форми зони змагань обмежувальна лінія, що примикає до зовнішньої лінії №1, має називатися обмежувальною лінією №1 і за годинниковою стрілкою від обмежувальної лінії №1, інші лінії мають називатися обмежувальними лініями №2, №3, №4, №5, №6, №7 та №8.</div>
                    <div className={s.rulesOl}><span className={s.num}>2.3. </span>Позиції рефері та учасників на початку та в кінці поєдинку: Позиції учасників повинні знаходитись у двох протилежних точках, на відстані 1 м від центральної точки зони змагань, паралельно зовнішній лінії №1. Рефері повинен розташуватися в точці на відстані 1,5 м від центру зони змагань у бік зовнішньої лінії №3.</div>
                    <div className={s.rulesOl}><span className={s.num}>2.4. </span>Позиції суддів: Позиція 1-го судді повинна бути розташована в точці щонайменше 2 м від кута обмежувальної лінії №2. Позиція 2-го судді повинна бути розташована в точці щонайменше на 2 м назовні від центру обмежувальної лінії №5. Позиція 3-го судді повинна бути розташована в точці щонайменше 2 м від кута обмежувальної лінії №8. У разі встановлення двома суддями позиція 1-го судді повинна бути розташована в точці щонайменше 3 м від зовнішньої лінії №1 ліворуч від корта, а позиція 2-го судді має бути розташована в точці щонайменше 3 м праворуч від зовнішньої лінії №3. Посади суддів можуть бути змінені для полегшення медіа, трансляції та/або спортивної презентації.</div>
                    <div className={s.rulesOl}><span className={s.num}>2.5. </span>Позиція реєстратора та IVR: позиція реєстратора та IVR має бути розташована в точці на відстані 2 м від зовнішньої лінії №1. Розташування диктофона може бути змінено відповідно до навколишнього середовища та вимог медіа-трансляцій та/або спортивних презентацій.</div>
                    <div className={s.rulesOl}><span className={s.num}>2.6. </span>Позиції тренерів: Позиції тренерів мають бути позначені в точці, що розташована щонайменше на 2 м або більше від центральної точки зовнішньої лінії з боку кожного учасника. Розташування тренерів може бути змінено відповідно до навколишнього середовища місця проведення змагань і вимог щодо трансляції ЗМІ та/або спортивних презентацій.</div>
                    <div className={s.rulesOl}><span className={s.num}>2.6. </span>Розташування столу інспекції: інспекційний стіл має бути розташований біля входу в зону змагань для перевірки захисного спорядження учасників.</div>
                </div>

                <div className={s.rulesOl}><b>Пояснення №1</b> Корт: Ступінь еластичності та ковзкості корту має бути затверджений WT перед змаганнями.</div>
                <div className={s.rulesOl}><b>Пояснення №2</b> Колір: колірна схема поверхні килимка не повинна створювати різких відблисків або втомлювати зір учасника або глядача. Кольорова схема також має відповідати екіпіровці учасника, формі та поверхні зони змагань.</div>
                <div className={s.rulesOl}><b>Пояснення №3</b> Стіл перевірки: на столі перевірки інспектор перевіряє, чи всі речі, які носить учасник, схвалені WT і чи належним чином підходять учаснику. У разі, якщо вони визнаються невідповідними, учаснику пропонується змінити захисне спорядження.</div>



                <div>
                    <img className={s.rulesImg} src='/img/rules/2judje.jpg' />
                    <img className={s.rulesImg} src='/img/rules/3judje.jpg' />
                    <img className={s.rulesImg} src='/img/rules/colorCorts.jpg' />
                </div>
            </div>

            {/* Учасники */}
            <div className={s.rulesBlock}>
                <div className={s.rulesArticle} id='Contestant'>Стаття 4. Учасники змаганнь.

                    {isSmallScreen ? (<><br /></>) : null}
                    <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
                </div>

                <div className={s.rulesOl}><span className={s.num}>1. </span>Кваліфікація учасника

                    <div className={s.rulesOl}><span className={s.num}>1.1. </span>Учасник повинен мати громадянство країни яку представляє команда учасник.</div>
                    <div className={s.rulesOl}><span className={s.num}>1.2. </span>Учасник повинен бути рекомендованим WT MNA</div>
                    <div className={s.rulesOl}><span className={s.num}>1.3. </span>Учасник повинен мати сертифікат Дан/Пум тхеквондо, виданий Kukkiwon</div>
                    <div className={s.rulesOl}><span className={s.num}>1.4. </span>Учасник повинен мати ліцензію спортсмена WT (GAL)</div>
                    <div className={s.rulesOl}><span className={s.num}>1.5. </span>Учасники у віці не менше 17 років для старших у рік проведення відповідного турніру (15-17 років для юніорських чемпіонатів і 12-14 років для кадетських чемпіонатів). Вік для Юнацьких Олімпійських ігор може бути іншим залежно від рішення МОК.</div>
                </div>

                <div className={s.rulesOl}><b>Пояснення.</b> Вікове обмеження залежить від року, а не від дати. Наприклад, якщо Чемпіонат серед юніорів проводиться 11 червня 2013 року, право на участь мають учасники, які народилися в період з 1 січня 1996 року по 31 грудня 1998 року.
                </div>
                <div className={s.rulesOl}><b>Пояснення.</b> Стаття 1. Кваліфікація учасника: застосовується на чемпіонатах WT, чемпіонатах CU, мультиспортивних іграх і затверджених подіях визнаних WT міжнародних відкритих турнірів з тхеквондо.</div>
                <div className={s.rulesOl}><b>Пояснення.</b> Статті 1.1 і 1.2 не застосовуються до визнаних WT міжнародних відкритих турнірів з тхеквондо.
                </div>

                <div className={s.rulesOl}><span className={s.num}>2. </span>Уніформа учасника та змагальне спорядження
                    <div className={s.rulesOl}><span className={s.num}>2.1. </span>На змаганнях, зазначених у Календарі змагань WT, добок або змагальна форма та все змагальне обладнання, таке як PSS, IVR та захисне спорядження, мають бути схваленими WT.
                        <div className={s.rulesOl}><span className={s.num}>2.1.1. </span>Специфікації добоку або спортивної форми, захисного спорядження та іншого спорядження повинні бути викладені окремо.</div>
                    </div>
                    <div className={s.rulesOl}><span className={s.num}>2.2. </span>Учасник повинен носити схвалену WT добок або спортивну форму, PSS для тулуба, PSS для голови, щиток для паху, щитки для передпліччя, щитки для гомілок, рукавички, сенсорні шкарпетки (у разі використання PSS) і мати капу перед входом на корт. Під час входу в зону змагань шолом повинен бути міцно затиснутим під лівою рукою спортсмена. Шолом повинен бути одягнений на голову за вказівками рефері перед початком поєдинку.</div>
                    <div className={s.rulesOl}><span className={s.num}>2.3. </span>У разі носіння добка, щитки на передпліччя і гомілки повинні бути одягнені під одягом. У разі носіння спортивної форми щитки на передпліччя та гомілку повинні бути одягнені в одязі або під одягом. В обох випадках під одягом слід одягати пахові бандажи.</div>
                    <div className={s.rulesOl}><span className={s.num}>2.4. </span>Учасник повинен взяти з собою захисне спорядження, схвалене WT, а також рукавички та капу для свого особистого використання. Носити будь-який предмет на голові, окрім шолома, не дозволяється. Шолом для кадетів може комплектуватися забралом. Будь-яка релігійна атрибутика повинна розтошовуватись  під шоломом, добком або змагальною формою і не повинен становити загрозу або заважати учаснику суперника. </div>
                    <div className={s.rulesOl}><span className={s.num}>2.5. </span>Обов'язки оргкомітету щодо обладнання змагань

                        <div className={s.rulesOl}><span className={s.num}>2.5.1. </span>Організаційний комітет чемпіонатів WT, несе відповідальність за підготовку наступного обладнання, визнаного WT, для використання на чемпіонатах за власні кошти для всіх відповідних матеріалів, обладнання та відповідних технічних спеціалістів для встановлення та експлуатації.
                            <div className={s.rulesOl}><span className={s.num}>2.5.1.1. </span>Предмети та спорядження, PSS тулуба та голови PSS – вибір компанії PSS вирішує WT (для Чемпіонатів світу з тхеквондо серед кадетів має використовуватися звичайний захисний наголовник із щитком для обличчя)</div>
                            <div className={s.rulesOl}><span className={s.num}>2.5.1.2. </span>Корти</div>
                            <div className={s.rulesOl}><span className={s.num}>2.5.1.3. </span>Інше захисне обладнання в якості резерву (сенсорні шкарпетки, рукавички, щитки для гомілок, передпліччя, пахові щитки та добок або спортивна форма)</div>
                            <div className={s.rulesOl}><span className={s.num}>2.5.1.4. </span>Система миттєвого відеоповтору (IVR) і пов’язане з нею обладнання, включаючи, але не обмежуючись камерами (мінімум 3 камери на корт і мінімум 4 камери, включаючи одну верхню камеру для півфіналу та фіналу); Якщо трансляція доступна, канал трансляції має бути доступним на стійці відеоповторів для перегляду. Система 4D відтворення може використовуватися для всіх чемпіонатів рівня G-12 і вище для IVR.</div>
                            <div className={s.rulesOl}><span className={s.num}>2.5.1.5. </span>Великий екран (для відображення турнирних таблиці та  прогресу змагань, профілю спортсмена тощо) всередині ігрового поля (FOP)</div>
                            <div className={s.rulesOl}><span className={s.num}>2.5.1.6. </span>Табло для глядачів (для відображення екрана миттєвого відеоповтору; мінімум 12)</div>
                            <div className={s.rulesOl}><span className={s.num}>2.5.1.7. </span>Табло на корті (для відображення підрахунку очок; мінімум 4 на корт) </div>
                            <div className={s.rulesOl}><span className={s.num}>2.5.1.8. </span>Система відображення в реальному часі (RTDS) у зоні виклику спортсмена та зоні розминки</div>
                            <div className={s.rulesOl}><span className={s.num}>2.5.1.9. </span>Система виклику суддів у реальному часі (RTRCS) у кімнаті для суддів або зоні очікування.</div>
                            <div className={s.rulesOl}><span className={s.num}>2.5.1.10. </span>Телевізійний екран для показу змагань у прямому ефірі в суддівській кімнаті</div>
                            <div className={s.rulesOl}><span className={s.num}>2.5.1.11. </span>Металошукач на стійці огляду (мінімум 2)</div>
                            <div className={s.rulesOl}><span className={s.num}>2.5.1.12. </span>Інше обладнання для змагань, не описане в цій статті, якщо таке є, має бути описано в регламенті змагань WT.</div>
                        </div>
                        <div className={s.rulesOl}><span className={s.num}>2.5.2. </span>Організаційний комітет чемпіонатів  WT, несе відповідальність за підготовку наступного обладнання та матеріалів тощо на місці проведення тренувань за власні кошти.
                            <div className={s.rulesOl}><span className={s.num}>2.5.2.1. </span> Об’єкти та обладнання, пов’язані з системою PSS </div>
                            <div className={s.rulesOl}><span className={s.num}>2.5.2.2. </span> Корти</div>
                            <div className={s.rulesOl}><span className={s.num}>2.5.2.3. </span> Велотренажер</div>
                            <div className={s.rulesOl}><span className={s.num}>2.5.2.4. </span> Бігову доріжку</div>
                            <div className={s.rulesOl}><span className={s.num}>2.5.2.5. </span> Обладнання екстреної допомоги (детальнішу інформацію див. у медичному кодексі)</div>
                            <div className={s.rulesOl}><span className={s.num}>2.5.2.6. </span> Лід у кошиках</div>
                            <div className={s.rulesOl}><span className={s.num}>2.5.2.7. </span> Холодильники</div>
                            <div className={s.rulesOl}><span className={s.num}>2.5.2.8. </span> Вода в пляшках</div>

                        </div>
                        <div className={s.rulesOl}><span className={s.num}>2.5.3. </span> Оргкомітет відповідає за отримання схвалення WT щодо кількості обладнання, яке буде підготовлено.</div>
                    </div>
                </div>

                <div className={s.rulesOl}><span className={s.num}>3. </span> Антидопінговий тест
                    <div className={s.rulesOl}><span className={s.num}>3.1. </span> На змаганнях з тхеквондо, які пропагуються або визнаються WT, будь-яке використання або введення наркотиків або хімічних речовин, описаних у списку заборонених WADA, заборонено. Антидопінговий кодекс WADA повинен застосовуватися до змагань з тхеквондо під час Олімпійських ігор та інших змагань. Антидопінгові правила WT застосовуються до організованих та/або визнаних чемпіонатів WT.</div>
                    <div className={s.rulesOl}><span className={s.num}>3.2. </span>WT може провести будь-які допінг-тести, які вважаються необхідними, щоб переконатися, що учасник не порушив це правило, і будь-який учасник, який відмовляється пройти це тестування або який доведе, що вчинив таке порушення, повинен бути вилучений з фінального заліку, і результат буде передано учаснику, наступному за чергою в турнірній таблиці.</div>
                    <div className={s.rulesOl}><span className={s.num}>3.3. </span>Організаційний комітет несе відповідальність за проведення всієї необхідної підготовки до проведення допінг-тестів.</div>
                    <div className={s.rulesOl}><span className={s.num}>3.4. </span>  Деталі антидопінгових правил WT повинні бути прийняті як частина статуту.</div>
                </div>
                <div className={s.rulesOl}><b>Пояснення №1 </b>Громадянин команди-учасниці: Якщо учасник є представником національної збірної, його/її національність визначається громадянством країни, яку він/вона представляє, до подання заявки на участь. Перевірка громадянства здійснюється шляхом перевірки паспорта. Учасник, який є громадянином двох або більше країн одночасно, може представляти будь-яку з них на свій вибір. Проте, у разі зміни громадянства, йому/їй дозволяється представляти іншу країну лише за умови, що минуло тридцять шість (36) місяців з моменту, коли учасник представляв країну в таких змаганнях:
                    <ul>
                        <li> Олімпійські ігри</li>
                        <li>  Кваліфікаційні турніри для Олімпійських ігор </li>
                        <li> Континентальні мультиспортивні ігри 4-річного циклу</li>
                        <li>Континентальні чемпіонати 2-річного циклу</li>
                        <li>Чемпіонати світу, які підтримує WT</li>
                    </ul>
                    Цей період може бути скорочений або навіть скасований за згодою НОКів та WT. WT може в будь-який час вжити дисциплінарних заходів проти спортсмена та його/її MNA, які порушують цю статтю, включаючи, але не обмежуючись, позбавлення досягнень.
                </div>

                <div className={s.rulesOl}><b>Пояснення №2 </b>Один, рекомендований WT MNA: кожен MNA несе відповідальність за контроль відсутності вагітності та стать, а також гарантує, що всі члени команди пройшли медичне обстеження, яке підтверджує їх відповідне здоров’я та придатність для участі. Крім того, кожен MNA бере на себе повну відповідальність за страхування від нещасних випадків та медичне страхування, а також цивільну відповідальність для своїх учасників та офіційних осіб під час чемпіонатів, які сприяють WT.</div>
                <div className={s.rulesOl}><b>Пояснення №3 </b> Капа: Колір капи обмежений білим або прозорим. Він повинен бути товщиною не менше 3 мм і покривати всі верхні зуби. Спортсмени з зубними брекетами повинні носити спеціальну капу для брекетів, яка охоплює як верхні, так і нижні зуби, рекомендовану їх стоматологом, і надати лист від свого стоматолога про те, що спортсмену безпечно змагатися з капою, яку рекомендував стоматолог. (Див. WT Капа, тейпінг, брекети, правила пірсингу для отримання детальної інформації.)</div>
                <div className={s.rulesOl}><b>Пояснення №4 </b>PSS голови: PSS іншого кольору, ніж синій або червоний, не допускається до змагань.</div>
                <div className={s.rulesOl}><b>Пояснення №5 </b>Система миттєвого відеоповтору: Оргкомітет зобов’язаний забезпечити надання каналу трансляції для миттєвого відеоповтору для матчів WT. </div>
                <div className={s.rulesOl}><b>Пояснення №6 </b> Тейпінг: тейпінг стоп і рук суворо перевірятиметься під час процесу огляду спортсмена. Інспектор може вимагати схвалення лікаря комісії WT щодо надмірної стрічки. Учасники повинні зняти тейп під час загального зважування, щоб побачити, чи немає відкритої рани, порізу чи кровотечі. (Детальнішу інформацію див. у розділі WT Капа, тейпінг, брекети, правила пірсингу.)</div>
                <div className={s.rulesOl}><b>Пояснення №7 </b>Будь-який спортсмен, який не вдягнув належне захисне спорядження та обладнання або не видалив потенційно шкідливий матеріал зі свого тіла під час перевірки, як зазначено нижче 1 ~ 3, не буде допущений до участі в змаганнях. (Додаткову інформацію див. у WT Капа, тейпінг, брекети, правила пірсингу та Додаток III до Медичного кодексу WT.)
                    <div className={s.rulesOl}><span className={s.num}>1. </span>Будь-який спортсмен, чиє захисне спорядження (наприклад, захисне спорядження для голови, тіла, паху, рук, ніг і капи) має або недостатнє покриття частини тіла спорядженням, невідповідний розмір або значний дефект форми (або матеріалу).</div>
                    <div className={s.rulesOl}><span className={s.num}>2. </span>Будь-який спортсмен, який не має належної капи відповідно до правил WT під час огляду та не має належної капи в будь-який час під час матчу, або не носить належну капу, незважаючи на те, що отримав попередження від рефері з інспекції, центрального рефері або лікаря WT.</div>
                    <div className={s.rulesOl}><span className={s.num}>3. </span>Будь-який спортсмен, який має пірсинг, вуха або будь-який твердий матеріал на обличчі або будь-якій частині тіла під час перевірки або під час матчу, незважаючи на те, що отримав попередження судді з огляду, центрального судді або уповноваженого лікаря WT.</div>
                </div>
                <div className={s.rulesOl}><b>Пояснення №8 </b>Будь-який спортсмен, який бере участь у змаганнях, повинен мати дійсну щорічну періодичну оцінку стану здоров'я (або медичну довідку). Без дійсної річної медичної довідки не допускається до участі в змаганнях. (Додаткову інформацію див. у медичному коді WT 8.3. Періодична оцінка здоров’я та Додатку III A. Медична довідка)</div>
                <div className={s.rulesOl}><b>Пояснення №9 </b>Будь-який спортсмен повинен бути дискваліфікований для участі в змаганнях, якщо він не дотримується правил щодо захисного спорядження, має будь-який стан здоров’я, який може поставити під загрозу безпеку за юрисдикцією, або має умови дискваліфікації в медичній довідці. (додаткову інформацію див. у Додатку III B. Дискваліфікація до медичного коду WT)</div>
                <div className={s.rulesOl}><b>Пояснення №10 </b>Щоб брати участь у змаганнях, будь-який спортсмен повинен мати дійсну туристичну медичну страховку та надати копію довідки про медичне страхування до GMS під час реєстрації.</div>
            </div>

            {/* Вагова категорія */}
            <div className={s.rulesBlock}>
                <div className={s.rulesArticle} id='WeightCategory'>Стаття 5. Вагова категорія.
                    {isSmallScreen ? (<><br /></>) : null}
                    <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
                </div>

                <div className={s.rulesOl}><span className={s.num}>1. </span>Світові вагові категорії поділяються на чоловічі та жіночі дивізіони та класифікуються наступним чином.</div>
                <br />
                <table className={s.rulesWeightTable}>
                    <thead>
                        <tr>
                            <td colSpan='2'>Чоловічий дивізіон</td>
                            <td colSpan='2'>Жіночий дивізіон</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>До 54кг.</td>
                            <td>Не більше 54кг.</td>
                            <td>До 46кг.</td>
                            <td>Не більше 46кг.</td>
                        </tr>
                        <tr>
                            <td>До 58кг.</td>
                            <td>Понад 54кг. не більше 58кг.</td>
                            <td>До 49кг.</td>
                            <td>Понад 46кг. не більше 49кг.</td>
                        </tr>
                        <tr>
                            <td>До 63кг.</td>
                            <td>Понад 58кг. не більше 63кг.</td>
                            <td>До 53кг.</td>
                            <td>Понад 49кг. не більше 53кг.</td>
                        </tr>
                        <tr>
                            <td>До 68кг.</td>
                            <td>Понад 63кг. не більше 68кг.</td>
                            <td>До 57кг.</td>
                            <td>Понад 53кг. не більше 57кг.</td>
                        </tr>
                        <tr>
                            <td>До 74кг.</td>
                            <td>Понад 68кг. не більше 74кг.</td>
                            <td>До 62кг.</td>
                            <td>Понад 53кг. не більше 62кг.</td>
                        </tr>
                        <tr>
                            <td>До 80кг.</td>
                            <td>Понад 74кг. не більше 80кг.</td>
                            <td>До 67кг.</td>
                            <td>Понад 62кг. не більше 67кг.</td>
                        </tr>
                        <tr>
                            <td>До 87кг.</td>
                            <td>Понад 80кг. не більше 87кг.</td>
                            <td>До 73кг.</td>
                            <td>Понад 67кг. не більше 73кг.</td>
                        </tr>
                        <tr>
                            <td>Понад 87кг.</td>
                            <td>Понад 87кг.</td>
                            <td>Понад 73кг.</td>
                            <td>Понад 73кг.</td>
                        </tr>
                    </tbody>
                </table>

                <div className={s.rulesOl}><span className={s.num}>2. </span>Олімпійські вагові категорії поділяються на чоловічі та жіночі дивізіони та класифікуються наступним чином.</div>
                <br />
                <table className={s.rulesWeightTable}>
                    <thead>
                        <tr>
                            <td colSpan='2'>Чоловічий дивізіон</td>
                            <td colSpan='2'>Жіночий дивізіон</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>До 58кг.</td>
                            <td>Не більше 58кг.</td>
                            <td>До 49кг.</td>
                            <td>Не більше 49г.</td>
                        </tr>
                        <tr>
                            <td>До 68кг.</td>
                            <td>Понад 58кг. не більше 68кг.</td>
                            <td>До 57кг.</td>
                            <td>Понад 49кг. не більше 57кг.</td>
                        </tr>
                        <tr>
                            <td>До 80кг.</td>
                            <td>Понад 68кг. не більше 80кг.</td>
                            <td>До 67кг.</td>
                            <td>Понад 57кг. не більше 67кг.</td>
                        </tr>
                        <tr>
                            <td>Понад 80кг.</td>
                            <td>Понад 80кг.</td>
                            <td>Понад 67кг.</td>
                            <td>Понад 67кг.</td>
                        </tr>
                    </tbody>
                </table>

                <div className={s.rulesOl}><span className={s.num}>3. </span>Світові вагові категорії серед юніорів поділяються на чоловічі та жіночі дивізіони та класифікуються наступним чином.</div>
                <br />
                <table className={s.rulesWeightTable}>
                    <thead>
                        <tr>
                            <td colSpan='2'>Чоловічий дивізіон</td>
                            <td colSpan='2'>Жіночий дивізіон</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>До 45кг.</td>
                            <td>Не більше 45кг.</td>
                            <td>До 42кг.</td>
                            <td>Не більше 42кг.</td>
                        </tr>
                        <tr>
                            <td>До 48кг.</td>
                            <td>Понад 45кг. не більше 48кг.</td>
                            <td>До 44кг.</td>
                            <td>Понад 42кг. не більше 44кг.</td>
                        </tr>
                        <tr>
                            <td>До 51кг.</td>
                            <td>Понад 48кг. не більше 51кг.</td>
                            <td>До 46кг.</td>
                            <td>Понад 44кг. не більше 46кг.</td>
                        </tr>
                        <tr>
                            <td>До 55кг.</td>
                            <td>Понад 51кг. не більше 55кг.</td>
                            <td>До 49кг.</td>
                            <td>Понад 46кг. не більше 49кг.</td>
                        </tr>
                        <tr>
                            <td>До 59кг.</td>
                            <td>Понад 55кг. не більше 59кг.</td>
                            <td>До 52кг.</td>
                            <td>Понад 49кг. не більше 52кг.</td>
                        </tr>
                        <tr>
                            <td>До 63кг.</td>
                            <td>Понад 59кг. не більше 63кг.</td>
                            <td>До 55кг.</td>
                            <td>Понад 52кг. не більше 55кг.</td>
                        </tr>
                        <tr>
                            <td>До 68кг.</td>
                            <td>Понад 63кг. не більше 68кг.</td>
                            <td>До 59кг.</td>
                            <td>Понад 55кг. не більше 59кг.</td>
                        </tr>
                        <tr>
                            <td>До 73кг.</td>
                            <td>Понад 68кг. не більше 73кг.</td>
                            <td>До 63кг.</td>
                            <td>Понад 59кг. не більше 63кг.</td>
                        </tr>
                        <tr>
                            <td>До 78кг.</td>
                            <td>Понад 73кг. не більше 78кг.</td>
                            <td>До 68кг.</td>
                            <td>Понад 63кг. не більше 68кг.</td>
                        </tr>

                        <tr>
                            <td>Понад 78кг.</td>
                            <td>Понад 78кг.</td>
                            <td>Понад 68кг.</td>
                            <td>Понад 68кг.</td>
                        </tr>
                    </tbody>
                </table>

                <div className={s.rulesOl}><span className={s.num}>4. </span>Юнацькі олімпійські вагові категорії класифікуються наступним чином</div>
                <br />
                <table className={s.rulesWeightTable}>
                    <thead>
                        <tr>
                            <td colSpan='2'>Чоловічий дивізіон</td>
                            <td colSpan='2'>Жіночий дивізіон</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>До 48кг.</td>
                            <td>Не більше 48кг.</td>
                            <td>До 44кг.</td>
                            <td>Не більше 44кг.</td>
                        </tr>
                        <tr>
                            <td>До 55кг.</td>
                            <td>Понад 48кг. не більше 55кг.</td>
                            <td>До 49кг.</td>
                            <td>Понад 44кг. не більше 49кг.</td>
                        </tr>
                        <tr>
                            <td>До 63кг.</td>
                            <td>Понад 55кг. не більше 63кг.</td>
                            <td>До 55кг.</td>
                            <td>Понад 49кг. не більше 55кг.</td>
                        </tr>
                        <tr>
                            <td>До 73кг.</td>
                            <td>Понад 63кг. не більше 73кг.</td>
                            <td>До 63кг.</td>
                            <td>Понад 55кг. не більше 63кг.</td>
                        </tr>

                        <tr>
                            <td>Понад 73кг.</td>
                            <td>Понад 73кг.</td>
                            <td>Понад 63кг.</td>
                            <td>Понад 63кг.</td>
                        </tr>
                    </tbody>
                </table>

                <div className={s.rulesOl}><span className={s.num}>5. </span>Вагові категорії серед кадетів поділяються на чоловічі та жіночі дивізіони та класифікуються наступним чином.</div>
                <br />
                <table className={s.rulesWeightTable}>
                    <thead>
                        <tr>
                            <td colSpan='2'>Чоловічий дивізіон</td>
                            <td colSpan='2'>Жіночий дивізіон</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>До 33кг.</td>
                            <td>Не більше 33кг.</td>
                            <td>До 29кг.</td>
                            <td>Не більше 29кг.</td>
                        </tr>
                        <tr>
                            <td>До 37кг.</td>
                            <td>Понад 33кг. не більше 37кг.</td>
                            <td>До 33кг.</td>
                            <td>Понад 29кг. не більше 33кг.</td>
                        </tr>
                        <tr>
                            <td>До 41кг.</td>
                            <td>Понад 37кг. не більше 41кг.</td>
                            <td>До 37кг.</td>
                            <td>Понад 33кг. не більше 37кг.</td>
                        </tr>
                        <tr>
                            <td>До 45кг.</td>
                            <td>Понад 41кг. не більше 45кг.</td>
                            <td>До 41кг.</td>
                            <td>Понад 37кг. не більше 41кг.</td>
                        </tr>
                        <tr>
                            <td>До 49кг.</td>
                            <td>Понад 45кг. не більше 49кг.</td>
                            <td>До 44кг.</td>
                            <td>Понад 41кг. не більше 44кг.</td>
                        </tr>
                        <tr>
                            <td>До 53кг.</td>
                            <td>Понад 49кг. не більше 53кг.</td>
                            <td>До 47кг.</td>
                            <td>Понад 44кг. не більше 47кг.</td>
                        </tr>
                        <tr>
                            <td>До 57кг.</td>
                            <td>Понад 53кг. не більше 57кг.</td>
                            <td>До 51кг.</td>
                            <td>Понад 47кг. не більше 51кг.</td>
                        </tr>
                        <tr>
                            <td>До 61кг.</td>
                            <td>Понад 57кг. не більше 61кг.</td>
                            <td>До 55кг.</td>
                            <td>Понад 51кг. не більше 55кг.</td>
                        </tr>
                        <tr>
                            <td>До 65кг.</td>
                            <td>Понад 61кг. не більше 65кг.</td>
                            <td>До 59кг.</td>
                            <td>Понад 55кг. не більше 59кг.</td>
                        </tr>


                        <tr>
                            <td>Понад 65кг.</td>
                            <td>Понад 65кг.</td>
                            <td>Понад 59кг.</td>
                            <td>Понад 59кг.</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/*  Класифікація та методи організації змагань */}
            <div className={s.rulesBlock}>
                <div className={s.rulesArticle} id='Classification'>Стаття 6. Класифікація та методи організації змагань.
                    {isSmallScreen ? (<><br /></>) : null}
                    <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
                </div>

                <div className={s.rulesOl}><span className={s.num}>1. </span>Змагання класифікуються наступним чином.
                    <div className={s.rulesOl}><span className={s.num}>1.1. </span>Індивідуальні змагання, як правило, проводяться між учасниками в одній ваговій категорії. При необхідності суміжні вагові категорії можуть бути об’єднані для створення єдиної класифікації. Жодному учаснику не дозволяється брати участь у більш ніж одній ваговій категоріях в одному змаганні. </div>
                    <div className={s.rulesOl}><span className={s.num}>1.2. </span>Командні змагання: Метод і вагові категорії командних змагань мають бути визначені в Регламенті командних чемпіонатів Світового кубку світу з тхеквондо. </div>
                </div>
                <div className={s.rulesOl}><span className={s.num}>2. </span>Системи змагань розділені наступним чином.
                    <div className={s.rulesOl}><span className={s.num}>2.1. </span>Олімпійська система</div>
                    <div className={s.rulesOl}><span className={s.num}>2.2. </span>Колова система</div>
                </div>
                <div className={s.rulesOl}><span className={s.num}>3. </span>Змагання з тхеквондо на Олімпійських іграх і континентальні турнири 4-річного циклу можуть використовувати як олімпійську так і комбіновану системи турніру з одним вибуванням і фіналом за коловою системою.</div>
                <div className={s.rulesOl}><span className={s.num}>4. </span>Усі змагання міжнародного рівня, визнані WT, повинні проводитися за участю щонайменше чотирьох країн у турнірі, у якому змагатимуться не менше чотирьох учасників у кожній ваговій категорії. Будь-який турнір із менш ніж чотирма країнами учасниками, або ваговими категоріями з менш ніж чотирма учасниками, не можуть бути визнані в офіційних результатах. </div>
                <div className={s.rulesOl}><span className={s.num}>5. </span>Всесвітня серія Гран-прі з тхеквондо буде організована на основі останньої процедури прийнятій на Всесвітній серії Гран-прі з тхеквондо. </div>
                <div className={s.rulesOl}><b>Тлумачення. </b></div>
                <div className={s.rulesOl}><span className={s.num}>1. </span>У олімпійській системі змагання засновується в індивідуальному порядку. Однак командний результат також можна визначити за сумою індивідуальних заліків відповідно до загальної процедури підрахунку.
                Командний результат визначається за загальною кількістю балів на основі наступних критеріїв:
                <ul>
                    <li>Один бал за кожного учасника, який увійшов до зони змагань після проходження загального зважування </li>
                    <li>Один бал за кожну перемогу (включно з перемогою за неявку) </li>
                    <li>Додаткові сто двадцять балів за золото медаль </li>
                    <li>Додаткові п'ятдесят очок за срібну медаль </li>
                    <li>Додаткові двадцять очок за бронзову медаль</li>
                </ul>
                Якщо більше ніж дві команди мають однакову кількість балів, рейтинг визначається 
                 <ul>
                    <li>Кількістю золотих, срібних і бронзових медалей виграла команда за порядком</li>
                    <li>Кількістю учасників змагань </li>
                    <li>Більшістю балів у важких вагових категоріях</li>                   
                </ul>
                </div>
                <div className={s.rulesOl}><span className={s.num}>2. </span>У системі командних змагань результат кожної команди  визначається індивідуальними  результатами. </div>
                <div className={s.rulesOl}><b>Пояснення №1  </b>Зведені вагові категорії: Метод зведення має відповідати олімпійським ваговим категоріям</div>
                <div ></div>
            </div>

            {/* Тривалість змагання */}
            <div className={s.rulesBlock}>
                <div className={s.rulesArticle} id='Duration'>Стаття 7. Тривалість змагання.
                    {isSmallScreen ? (<><br /></>) : null}
                    <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
                </div>

                <div className={s.rulesOl}><span className={s.num}>1. </span>Тривалість змагання класифікується наступним чином. 
                    <div className={s.rulesOl}><span className={s.num}>1.1 </span>Тривалість змагання має становити три раунди по дві хвилини кожен з однохвилинною перервою між раундами. У разі рівного рахунку після завершення 3-го раунду, 4-й раунд тривалістю одну хвилину буде проведено як Золотий раунд після однохвилинної перерви після 3-го раунду. </div>
                    <div className={s.rulesOl}><span className={s.num}>1.2. </span>У системі кращого з трьох, тривалість поєдинку має становити три раунди по дві хвилини кожен з однохвилинною перервою між раундами. Однак 4-й раунд тривалістю в одну хвилину не буде проводитися як Золотий раунд. У разі рівного рахунку у відповідному раунді, переможець раунду визначається згідно зі статтею 15.</div>
                    <div className={s.rulesOl}><span className={s.num}>1.3. </span>На командних чемпіонатах Кубка світу тривалість змагання має становити три раунди по чотири  хвилини, 1-й раунд, п’ять хвилин 2-го та 3-го раундів з однією  хвилиною перерви між раундами. 1-й раунд проводиться за традиційним форматом командного матчу протягом однієї хвилини на змагання, а 2-й і 3-й раунди проводяться протягом п’яти хвилин відповідно до формату командного матчу.</div>
                </div>
                <div className={s.rulesOl}><span className={s.num}>2. </span>Тривалість кожного раунду може бути змінено на 1 хвилину х 3 раунди, 1 хвилину 30 секунд х 3 раунди, 2 хвилини х 2 раунди або 5 хвилин х 1 раунд (з 1 тайм-аутом на 30 секунд для кожного учасника) за відповідним рішенням технічного делегата чемпіонату.</div>
                <div ></div>
            </div>

              {/* Жеребкування */}
            <div className={s.rulesBlock}>
                <div className={s.rulesArticle} id='Drawing'>Стаття 8. Жеребкування.
                    {isSmallScreen ? (<><br /></>) : null}
                    <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
                </div>

                <div className={s.rulesOl}><span className={s.num}>1. </span>Дата жеребкування вказується в плані змагань. Принаймні один представник від кожної команди повинен бути присутнім на жеребкуванні, а команди-учасниці відповідають за підтвердження своїх заявок перед жеребкуванням. Якщо жоден представник не може бути присутнім на жеребкуванні, команда повинна призначити довірену особу та повідомити про це Технічного делегата перед жеребкуванням. </div>
                <div className={s.rulesOl}><span className={s.num}>2. </span>Жеребкування може проводитися випадковим комп’ютеризованим жеребкуванням або випадковим ручним жеребкуванням. Метод і порядок жеребкування визначаються Технічним делегатом.</div>
                <div className={s.rulesOl}><span className={s.num}>3. </span>Усі спортсмени відсортовуються за рейтингом на всіх турнірах, які просуваються і визнаються WT, якщо інше не передбачено відповідними постійними процедурами або загальними положеннями подій.</div>
                <div ></div>
            </div>

            {/*Зважування  */}
            <div className={s.rulesBlock}>
                <div className={s.rulesArticle} id='Weigh-in'>Стаття 9. Зважування.
                    {isSmallScreen ? (<><br /></>) : null}
                    <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
                </div>

                <div className={s.rulesOl}><span className={s.num}>1. </span>Загальне зважування спортсменів організовується за один день до змагань. Час загального зважування визначається Організаційним комітетом і повідомляється на зустрічі керівників команд. Тривалість загального зважування становитиме максимум дві  години.</div>
                <div className={s.rulesOl}><span className={s.num}>2. </span>Випадкове зважування відбудеться в місці проведення змагань вранці в день змагань. Усі спортсмени, які пройшли загальне зважування, повинні бути присутніми для випадкового зважування за максимум дві  години до початку змагань. Якщо спортсмен не з'явиться на випадкове зважування, він/вона буде дискваліфікований/а. Випадкове зважування повинно бути завершене за максимум тридцять хвилин до початку змагань кожного дня.
                    <div className={s.rulesOl}><span className={s.num}>2.1. </span>Рівень відбору для випадкового зважування визначається кількістю спортсменів у ваговій категорії згідно з критеріями, і суб'єкти обиратимуться випадково за допомогою комп'ютерної системи за максимум дві  години до початку змагань. 
                    <div className={s.rulesOl}><span className={s.num}>2.1.1. </span>  Більше 32 спортсменів: 20% від загальної кількості.</div>
                    <div className={s.rulesOl}><span className={s.num}>2.1.2.</span> 17-32 спортсменів: 6 спортсменів.</div>
                    <div className={s.rulesOl}><span className={s.num}>2.1.3. </span> 9-16 спортсменів: 4 спортсмени.</div>
                    <div className={s.rulesOl}><span className={s.num}>2.1.4. </span> 4-8 спортсменів: 2 спортсмени.</div>
                    <div className={s.rulesOl}><span className={s.num}>2.1.5. </span> Менше 4 спортсменів: жодного.</div>
                    </div>
                    <div className={s.rulesOl}><span className={s.num}>2.2. </span> Випадкове зважування буде проводитися з допустимою похибкою плюс 5% від вагової категорії спортсмена. До низької ваги похибка не застосовується.</div>    
                </div>
                <div className={s.rulesOl}><span className={s.num}>3. </span>Під час зважування чоловіки зважуються в трусах, а жінки — у трусах і бюстгальтері. Однак, зважування може бути проведено в оголеному вигляді, якщо спортсмен цього бажає.
               <div className={s.rulesOl}><span className={s.num}>3.1. </span>всі категорії крім дорослих мають зважуватися в нижній білизні, з компенсацією 100 грам.</div>
                </div>
                <div className={s.rulesOl}><span className={s.num}>4. </span>Загальне зважування проводиться один раз, проте, один раз зважування може бути надано протягом встановленого часу будь-якому спортсмену, який не відповідав вимогам під час першого разу. Випадкове зважування проводиться тільки один раз на кожного спортсмена, і не буде другого зважування.</div>
                <div className={s.rulesOl}><span className={s.num}>5. </span>З метою уникнення дискваліфікації під час зважування, вага, ідентична офіційній, має бути забезпечена в місці проживання спортсменів або на місці проведення змагань для попереднього зважування.</div>



<div className={s.rulesOl}><b>Пояснення №1</b> Спортсмени в день змагань:мається на увазі ті спортсмени, в яких випадають поєдинки на запланований день Організаційним комітетом або WT.</div>
<div className={s.rulesOl}><b>Пояснення №2</b> Для зважування чоловіків і жінок мають бути встановлені окремі місця. Суддів на зважування повинні залучатись одної статі з спортсменами.</div>
<div className={s.rulesOl}><b>Пояснення №3</b> Дискваліфікація під час зважування: коли спортсмен дискваліфікується під час зважування, спортсмен не отримає жодних рейтингових очок.</div>
<div className={s.rulesOl}><b>Пояснення №4</b> Терези, ідентичні офіційним:  ваги для попереднього зважування повинні бути того ж типу та з тими ж калібруваннями, що й ваги на офіційному зважуванні, і ці факти мають бути перевірені Організаційним комітетом перед змаганнями.</div>

                <div >
                </div>
            </div>

            {/*Порядок проведення поєдинку  */}
            <div className={s.rulesBlock}>
                <div className={s.rulesArticle} id='ProcedureOfTheContest'>Стаття 10. Порядок проведення поєдинку.
                    {isSmallScreen ? (<><br /></>) : null}
                    <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
                </div>

                <div className={s.rulesOl}><span className={s.num}>1. </span>Виклик учасників: Імена учасників будуть оприлюднені табло реєстрації тричі, починаючи за тридцять (30) хвилин до запланованого початку змагання. Якщо учасник не повідомить про своє прибуття після третього оголошення, він буде дискваліфікований, і про це буде оголошено.</div>
                <div className={s.rulesOl}><span className={s.num}>2. </span>Перевірка тіла, форми та обладнання: Після виклику учасники повинні пройти огляд тіла, форми та обладнання на призначеному пункті перевірки інспекторами, призначеними WT, і учасник не повинен проявляти жодних ознак неприязні, а також не повинен носити матеріали, які можуть завдати шкоди іншому учаснику.</div>
                <div className={s.rulesOl}><span className={s.num}>3. </span>Вхід в зону змагань: Після перевірки учасник повинен перейти до зони тренера разом з одним тренером та одним лікарем команди або медичним персоналом (таким як фізіотерапевт, спортивний тренер чи хіропрактик, якщо є).</div>
                <div className={s.rulesOl}><span className={s.num}>4. </span>Процедура  початку та  завершення поєдинку:
                    <div className={s.rulesOl}><span className={s.num}>4.1. </span>Перед початком поєдинку: Центральний суддя виголосить “Чунг, Хонг.” Обидва учасники входять  в зону змагань з шоломами під лівими руками. Якщо якийсь із учасників не присутній або присутній без повного спорядження, включаючи весь захисний одяг, форму тощо, в зоні тренера до того моменту, коли суддя виголошує “Чунг, Хонг”, він/вона вважається таким, що вибув із змагання, і суддя оголосить опонента переможцем.</div> 
                    <div className={s.rulesOl}><span className={s.num}>4.2. </span>Ритуал початку: Учасники повинні стати лицем до лиця та виконати стоячий уклін за командою судді “Ча-рьот (увага)” і “Кьон-рьо (уклін)”. Стоячий уклін виконується з природної стоячої позиції “Ча-рьот” шляхом нахилу талії на кут понад 30 градусів із нахилом голови на кут понад 45 градусів. Після уклону учасники повинні надіти головні захисники.</div>
                    <div className={s.rulesOl}><span className={s.num}>4.3. </span>Початок поєдинку: Суддя почне змагання, виголошуючи "Чунг бі" та "Сі-чак"</div>
                    <div className={s.rulesOl}><span className={s.num}>4.4. </span>Змагання в кожному раунді починається з виголошення “Сі-чак” суддею.</div>
                    <div className={s.rulesOl}><span className={s.num}>4.5. </span>Закінчення раунду: Змагання в кожному раунді закінчується з виголошення “Кеу-ман (стоп)” суддею. Навіть якщо суддя не оголосить “Кеу-ман”, змагання вважається закінченим, коли закінчується час матчу, однак “Гам-джьом” може бути присуджено і зареєстровано в рахунок навіть після закінчення часу матчу.</div>
                    <div className={s.rulesOl}><span className={s.num}>4.6. </span>Призупинення змагань: Суддя може призупинити змагання, виголошуючи “Каль-йо” (перерва) і відновити змагання за командою “Кьє-сок” (продовжити). Коли суддя оголошує “Каль-йо”, секретар повинен негайно зупинити час матчу; коли суддя оголошує “Кьє-сок”, секретар повинен негайно поновити час матчу.</div>
                    <div className={s.rulesOl}><span className={s.num}>4.7. </span>Після закінчення останнього раунду: Суддя оголошує переможця, піднявши руку в бік переможця.
                        <div className={s.rulesOl}><span className={s.num}>4.7.1. </span> У системі “найкращих із трьох”, суддя оголошує переможця наприкінці кожного раунду.</div>
                    </div>
                    <div className={s.rulesOl}><span className={s.num}>4.8. </span>Відмова від участі у змаганнях.</div>
                </div>
                <div className={s.rulesOl}><span className={s.num}>5. </span>Процедура поєдинків у командних змаганнях
                    <div className={s.rulesOl}><span className={s.num}>5.1. </span>Обидві команди стають один навпроти одного в лінії, згідно із запропонованим порядком виступу, ближче до першої лінії кордону від міток учасників</div>
                    <div className={s.rulesOl}><span className={s.num}>5.2. </span>Процедура перед початком та після закінчення змагань буде проводитися, як зазначено в пункті 4 цієї статті.</div>
                    <div className={s.rulesOl}><span className={s.num}>5.3. </span>Обидві команди залишають зону змагань та стають у призначеному місці перед кожним матчем учасника.</div>
                    <div className={s.rulesOl}><span className={s.num}>5.4. </span> Обидві команди шикуються в зоні змагань одразу після закінчення останнього матчу, стоячи один напроти одного.</div>
                    <div className={s.rulesOl}><span className={s.num}>5.5. </span>Суддя оголошує переможну команду, піднявши свою руку у бік переможної команди.</div>
                </div>

                <div className={s.rulesOl}><b>Пояснення №1 </b> Лікар команди, хіропрактик, спортивний тренер чи фізіотерапевт: Під час подачі заявки на участь команди необхідно додати копії відповідних ліцензій лікаря команди, хіропрактика, спортивного тренера чи фізіотерапевта англійською мовою. Після перевірки будуть видані спеціальні акредитаційні картки для цих фахівців. Лише ті, хто отримав належну акредитацію, можуть пройти в зону змагань разом із тренером. Тільки лікар з дійсною медичною ліцензією, який закінчив визнаний медичний університет, може отримати акредитаційну картку лікаря команди. Інші медичні працівники (командний хіропрактик, спортивний тренер, фізіотерапевт чи інші працівники охорони здоров’я) не можуть називати себе лікарем команди, це вважається неправильною акредитацією.</div>
                 <div className={s.rulesOl}><b>Керівництво щодо суддівства. </b>  У разі використання PSS суддя повинен перевірити, чи система PSS і сенсорні шкарпетки, які носять обидва спортсмени, працюють належним чином. Цей процес, однак, може бути видалений для швидкого проведення змагань.</div>
                <div >
                </div>
            </div>

{/*Дозволені техніки та зони.*/}
<div className={s.rulesBlock}>
    <div className={s.rulesArticle} id='PermittedTechniquesAndAreas'>Стаття 11. Дозволені техніки та зони.
        {isSmallScreen ? (<><br /></>) : null}
        <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
    </div>

    <div className={s.rulesOl}><span className={s.num}>1. </span>Дозволені техніки
        <div className={s.rulesOl}><span className={s.num}>1.1. </span>Техніка кулаком: Пряма техніка удару кулаком, що використовує фронтальну частину суглоба стислого кулака.</div>
        <div className={s.rulesOl}><span className={s.num}>1.2. </span>Техніка ногою: Виконання технік, використовуючи будь-яку частину ноги нижче кістки щиколотки.</div>
    </div>
    <div className={s.rulesOl}><span className={s.num}>2. </span>Дозволені області
        <div className={s.rulesOl}><span className={s.num}>2.1. </span>Тулуб: Атака кулаком і техніками ніг в області, покритій захистом тулуба, дозволена. Однак такі атаки не повинні бути на частині хребта.</div>
        <div className={s.rulesOl}><span className={s.num}>2.2. </span>Голова: Область над ключицею. Дозволені лише техніки ногою.</div>
    </div>

    <div className={s.rulesOl}><b></b></div>
    
    <div >
    </div>
</div>

{/*Зарахування балів  */}
<div className={s.rulesBlock}>
    <div className={s.rulesArticle} id='ValidPoints'>Стаття 12. Зарахування балів.
        {isSmallScreen ? (<><br /></>) : null}
        <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
    </div>

    <div className={s.rulesOl}><span className={s.num}>1. </span>Залікові області.
        <div className={s.rulesOl}><span className={s.num}>1.1. </span>Тулуб: Синя або червона зона на захисті тулуба.</div>
        <div className={s.rulesOl}><span className={s.num}>1.2. </span>Голова: Вся голова вище нижньої лінії захисту голови.</div>
    </div>
    <div className={s.rulesOl}><span className={s.num}>2. </span>Критерії для нарахування балів:
        <div className={s.rulesOl}><span className={s.num}>2.1. </span> Бали будуть нараховані, коли дозволена техніка виконана по областям нарахування балів тулуба з належним рівнем удару.</div>
        <div className={s.rulesOl}><span className={s.num}>2.2. </span>Бали будуть нараховані, коли дозволена техніка виконана по областям нарахування балів голови.</div>
        <div className={s.rulesOl}><span className={s.num}>2.3. </span>Визначення дійсності техніки, рівня удару та/або дійсного контакту з областю для нарахування балів буде зроблено електронною системою нарахування балів, крім технік кулаком. Ці визначення PSS не підлягають миттєвому відео-огляду, окрім ударів ногою в голову.</div>
        <div className={s.rulesOl}><span className={s.num}>2.4. </span>Технічний комітет WT визначає потрібний рівень удару та чутливість PSS, використовуючи різні шкали з урахуванням вагової категорії, статі та вікових груп. У певних обставинах, які вважаються необхідними, технічний делегат може переналаштувати дійсний рівень удару.</div>
    </div>
    <div className={s.rulesOl}><span className={s.num}>3. </span>Дійсні бали:
        <div className={s.rulesOl}><span className={s.num}>3.1. </span>Один (1) бал за заліковий удар кулаком по захисту тулуба.</div>
        <div className={s.rulesOl}><span className={s.num}>3.2. </span>Два (2) бали за заліковий удар ногою по захисту тулуба.</div>
        <div className={s.rulesOl}><span className={s.num}>3.3. </span>Чотири (4) бали за заліковий обертовий удар ногою по захисту тулуба.</div>
        <div className={s.rulesOl}><span className={s.num}>3.4. </span>Три (3) бали за заліковий удар ногою в голову.</div>
        <div className={s.rulesOl}><span className={s.num}>3.5. </span> П'ять (5) балів за заліковий обертовий удар ногою в голову.</div>
        <div className={s.rulesOl}><span className={s.num}>3.6. </span>Один (1) бал нараховується за кожен "Gam-jeom" (штраф) даний супернику.</div>
    </div>
    <div className={s.rulesOl}><span className={s.num}>4. </span>Результат поєдинку визначається сумою балів трьох раундів.
        <div className={s.rulesOl}><span className={s.num}>4.1. </span>У системі "кращий з трьох", результат поєдинку визначається сумою раундів з трьох раундів.</div>
    </div>
    <div className={s.rulesOl}><span className={s.num}>5. </span>Анулювання балу(ів): Коли учасник заробляє бали після вчинення забороненої дії:
        <div className={s.rulesOl}><span className={s.num}>5.1. </span>Якщо після забороненої дії було нараховано бали, суддя повинен оголосити покарання за заборонену дію та анулювати бали.</div>
    </div>
    <div className={s.rulesOl}><b>Пояснення №1 </b>Зворотний удар ногою (Dwichagi) є одним із видів технік обертання, при якому має відбутися поворот голови та плеча, щоб вважатися  ударом з розвороту (Dwichagi) та нараховувати технічні бали. Коли учасники завдають удару супернику зворотним ударом (Dwichagi), без одночасного обертання голови та плеча учасника, 'зворотний удар' (Dwichagi) не буде вважатися обертовим ударом.</div>
    
    <div >
    </div>
</div>

{/*Зарахування балів(PSS та судді)  */}
<div className={s.rulesBlock}>
    <div className={s.rulesArticle} id='Scoring'>Стаття 13. Зарахування балів(PSS та судді)
        {isSmallScreen ? (<><br /></>) : null}
        <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
    </div>

    <div className={s.rulesOl}><span className={s.num}>1. </span>Оцінка дійсних балів здійснюється переважно за допомогою електронної системи нарахування балів, встановленої в системах захисту та нарахування балів (PSS). Бали за техніки удару кулаком та додаткові бали за обертові удари визначаються суддями за допомогою ручних пристроїв для нарахування балів. Якщо PSS (Система захисту та нарахування балів) не використовується, всі бали визначаються суддями за допомогою ручних пристроїв для нарахування балів.</div>
    <div className={s.rulesOl}><span className={s.num}>2. </span>Якщо система PSS для голови не використовується з системою PSS для тулуба, оцінка технік удару в голову буде виконуватися суддями за допомогою ручних пристроїв для нарахування балів.</div>
    <div className={s.rulesOl}><span className={s.num}>3. </span>Додатковий бал, наданий за обертовий удар, буде анульовано, якщо обертовий удар не був зарахований як дійсний бал PSS.</div>
    <div className={s.rulesOl}><span className={s.num}>4. </span>При суддівстві трьома суддями, необхідно, щоб двоє або більше суддів підтвердили дійсне нарахування балів.</div>
    <div className={s.rulesOl}><span className={s.num}>5. </span>При суддівстві двома  суддями, необхідно, щоб обидва судді підтвердили дійсне нарахування балів.</div>

    <div >
    </div>
</div>

{/*Заборонені дії та штрафні санкції  */}
<div className={s.rulesBlock}>
    <div className={s.rulesArticle} id='ProhibitedActsAndPenalties'>Стаття 14. Заборонені дії та штрафні санкції
        {isSmallScreen ? (<><br /></>) : null}
        <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
    </div>

    <div className={s.rulesOl}><span className={s.num}>1. </span>Покарання оголошує рефері.</div>
    <div className={s.rulesOl}><span className={s.num}>2. </span>Заборонені дії, описані в статті 14, караються “Gam-jeom” (штраф) від рефері.</div>
    <div className={s.rulesOl}><span className={s.num}>3. </span>“Gam-jeom” (штраф) рахується як один (1) бал для суперника.</div>
    <div className={s.rulesOl}><span className={s.num}>4. </span>Заборонені дії
        <div className={s.rulesOl}><span className={s.num}>4.1. </span>Наступні дії класифікуються як заборонені, і рефері оголошує “Gam-jeom” (штраф):
            <div className={s.rulesOl}><span className={s.num}>4.1.1. </span>Перетин межі корту</div>
            <div className={s.rulesOl}><span className={s.num}>4.1.2. </span>Падіння</div>
            <div className={s.rulesOl}><span className={s.num}>4.1.3. </span>Уникнення або затримка поєдинку</div>
            <div className={s.rulesOl}><span className={s.num}>4.1.4. </span>Захоплення або штовхання суперника</div>
            <div className={s.rulesOl}><span className={s.num}>4.1.5. </span>Наступні дії вважаються забороненими:
                <div className={s.rulesOl}><span className={s.num}>4.1.5.1. </span> Підняття ноги для блокування</div>
                <div className={s.rulesOl}><span className={s.num}>4.1.5.2. </span>Удар по нозі суперника для перешкоджання його атаці</div>
                <div className={s.rulesOl}><span className={s.num}>4.1.5.3. </span>Удар нижче пояса</div>
                <div className={s.rulesOl}><span className={s.num}>4.1.5.4. </span>Підняття ноги вище пояса для удару в повітрі чотири (4) рази чи більше</div>
                <div className={s.rulesOl}><span className={s.num}>4.1.5.5. </span>Підняття ноги або удар в повітрі більше трьох (3) секунд для перешкоджання потенційним атакуючим рухам суперника</div>
            </div>
            <div className={s.rulesOl}><span className={s.num}>4.1.6. </span>Удар нижче пояса</div>
            <div className={s.rulesOl}><span className={s.num}>4.1.7. </span>Атака суперника після "Kal-yeo"</div>
            <div className={s.rulesOl}><span className={s.num}>4.1.8. </span>Удар голови суперника рукою</div>
            <div className={s.rulesOl}><span className={s.num}>4.1.9. </span>Удар головою або атака коліном</div>
            <div className={s.rulesOl}><span className={s.num}>4.1.10. </span>Атака на впалого суперника</div>
            <div className={s.rulesOl}><span className={s.num}>4.1.11. </span>Атака на тулуб PSS боком або низом ступні в клінчовій позиції</div>
            <div className={s.rulesOl}><span className={s.num}>4.1.12. </span> Атака на потилицю голови PSS в клінчовій позиції</div>
            <div className={s.rulesOl}><span className={s.num}>4.1.13. </span>Наступні порушення з боку учасника або тренера:
                <div className={s.rulesOl}><span className={s.num}>4.1.13.1. </span>Невиконання команди або рішення рефері</div>
                <div className={s.rulesOl}><span className={s.num}>4.1.13.2. </span> Невідповідна протестна поведінка щодо рішень суддів</div>
                <div className={s.rulesOl}><span className={s.num}>4.1.13.3. </span>Невідповідні спроби заважати або впливати на результат поєдинку</div>
                <div className={s.rulesOl}><span className={s.num}>4.1.13.4. </span> Провокації або образи на адресу суперника або тренера</div>
                <div className={s.rulesOl}><span className={s.num}>4.1.13.5. </span> Наявність неакредитованого лікаря/фахівців в лікарській зоні</div>
                <div className={s.rulesOl}><span className={s.num}>4.1.13.6. </span>Інші серйозні порушення або несумлінна поведінка з боку учасника або тренера</div>
                <div className={s.rulesOl}><span className={s.num}>4.1.13.7. </span>Коли учасник вчиняє заборонену дію, за якою слідує атака після “Kal-yeo” (згідно зі статтею 14.4.1.7) або інша несумлінна поведінка (згідно зі статтею 14.4.1.13), рефері може надати другий “Gam-jeom” за 'Атаку після “Kal-yeo”' або 'Несумлінну поведінку'.</div>
            </div>
        </div>
        <div className={s.rulesOl}><span className={s.num}>4.2. </span> Коли тренер або учасник чинять надмірні порушення і не слідують командам рефері, рефері може оголосити запит на санкцію, піднявши жовту картку. У цьому випадку наглядова комісія змагань розслідує поведінку учасника та/або тренера і вирішить, чи є санкція доцільною.</div>
    </div>
    
    <div className={s.rulesOl}><span className={s.num}>5. </span>Якщо учасник навмисно та повторно відмовляється дотримуватися правил змагань або наказів рефері, рефері може завершити поєдинок, піднявши жовту картку, і оголосити суперника переможцем.</div>
    <div className={s.rulesOl}><span className={s.num}>6. </span>Якщо рефері на столі інспекції або офіційні особи на полі бою, в консультації з техніком PSS, за необхідності, визначають, що учасник або тренер намагалися маніпулювати чутливістю сенсорів PSS та/або неналежним чином змінити PSS, щоб вплинути на його продуктивність, учасник буде дискваліфікований.</div>
    <div className={s.rulesOl}><span className={s.num}>7. </span>Коли учасник отримує десять (10) “Gam-jeom”, рефері оголошує учасника переможеним завдяки покаранню рефері (PUN).
        <div className={s.rulesOl}><span className={s.num}>7.1. </span>У системі "кращий з трьох (3)", коли учасник отримує п’ять (5) “Gam-jeom” у раунді, суперника буде оголошено переможцем цього раунду.</div>
    </div>
    <div className={s.rulesOl}><span className={s.num}>8. </span>У статті 14.7 “Gam-jeom” враховується у загальному рахунку трьох раундів.</div>
    
    <div className={s.rulesOl}><b>Тлумачення.</b> Цілі встановлення заборонених дій та покарань:
        <div className={s.rulesOl}><span className={s.num}>1. </span>Забезпечення безпеки учасника;</div>
        <div className={s.rulesOl}><span className={s.num}>2. </span>Забезпечення справедливих змагань; </div>
        <div className={s.rulesOl}><span className={s.num}>3. </span>Заохочення належних технік.</div>
    </div>

    <div className={s.rulesOl}><b>Пояснення #1</b> “Gam-jeom”
    
    <div className={s.rulesOl}><span className={s.num}>1. </span>Перетин лінії кордону: “Gam-jeom” оголошується, коли одна нога учасника перетинає лінію кордону. “Gam-jeom” не буде оголошено, якщо учасник перетинає лінію кордону в результаті забороненої дії суперника.</div>
    <div className={s.rulesOl}><span className={s.num}>2. </span>Падіння: “Gam-jeom” оголошується за падіння. Однак, якщо учасник падає внаслідок заборонених дій суперника, штраф “Gam-jeom” не буде нарахований впалому учаснику, тоді як супернику буде нараховано штраф. Якщо обидва учасники впали в результаті випадкового зіткнення або в разі, коли учасник, який отримав бал за обертовий удар, падає, жодного штрафу не буде нараховано.</div>
    <div className={s.rulesOl}><span className={s.num}>3. </span>Уникнення або затримка поєдинку:
        <div className={s.rulesOl}><span className={s.num}>3.1. </span>Ця дія передбачає рух в стійці без наміру атакувати. Учаснику, який постійно демонструє пасивний стиль, буде нараховано “Gam-jeom”. Якщо обидва учасники залишаються неактивними після трьох   секунд, головний рефері дає сигнал команди “Gong-gyeok”. “Gam-jeom” буде оголошено: обом учасникам, якщо вони не виявлять активності через три секунди після команди; або учаснику, який відходить назад від початкової позиції через три секунди після команди.</div>
        <div className={s.rulesOl}><span className={s.num}>3.2. </span>Повернення спиною та відхід для уникнення атаки суперника повинні бути покарані, оскільки це виражає відсутність спортивного духу та може призвести до серйозних травм. Те ж покарання має бути застосоване за уникнення атаки суперника, згинаючись нижче рівня пояса або пригинаючись.</div>
        <div className={s.rulesOl}><span className={s.num}>3.3. </span>Відступ від технічного зіткнення лише для уникнення атаки суперника та з метою витрачання часу, “Gam-jeom” нараховуватиметься пасивному учаснику.</div>
        <div className={s.rulesOl}><span className={s.num}>3.4. </span>Симуляція травми означає перебільшення травми або вказівку на біль у частині тіла, яка не піддавалася удару, з метою показати дії суперника як порушення, а також перебільшення болю з метою затягування часу поєдинку.</div>
        <div className={s.rulesOl}><span className={s.num}>3.5. </span>“Gam-jeom” також буде нараховуватися спортсмену, який просить рефері зупинити поєдинок, щоб відрегулювати положення/пристосування захисного спорядження.</div>

    </div>
    <div className={s.rulesOl}><span className={s.num}>4. </span>Захоплення або штовхання суперника:
        <div className={s.rulesOl}><span className={s.num}>4.1. </span> Це включає захоплення будь-якої частини тіла суперника, форми або захисного спорядження руками. Також включає акт захоплення ноги або стопи або зачеплення ноги передпліччям. Для поштовху це дозволено як швидкий удар, і учасник повинен від'єднатися від суперника після одного поштовху. Наступні дії підлягають покаранню.
            <div className={s.rulesOl}><span className={s.num}>4.1.1 </span> Штовхання суперника з тривалим або постійним контактом.  </div>
            <div className={s.rulesOl}><span className={s.num}>4.1.2 </span>  Штовхання суперника за межі лінії кордону</div>
            <div className={s.rulesOl}><span className={s.num}>4.1.3 </span>  Штовхання суперника таким чином, щоб запобігти руху для удару або будь-якому нормальному виконанню атакуючого руху</div>
        </div>
    </div>
    <div className={s.rulesOl}><span className={s.num}>5. </span>Підняття ноги або рух “cut kick” не підлягає покаранню, якщо воно супроводжується виконанням техніки удару кулаком або ногою в комбінації.</div>
    <div className={s.rulesOl}><span className={s.num}>6. </span>Удар нижче пояса: ця дія стосується атаки на будь-яку частину нижче пояса. Коли атака нижче пояса викликається отримувачем у процесі обміну техніками, жодного покарання не буде нараховано. Ця стаття також стосується сильних ударів або натискання на будь-яку частину стегна, коліна або гомілки з метою перешкоджати техніці суперника.</div>
    <div className={s.rulesOl}><span className={s.num}>7. </span>Атака суперника після “Kal-yeo”:
        <div className={s.rulesOl}><span className={s.num}>7.1. </span> Атака після Kal-yeo вимагає, щоб атака призвела до реального контакту з тілом суперника.</div>
        <div className={s.rulesOl}><span className={s.num}>7.2. </span>Якщо атакуючий рух розпочався до “Kal-yeo”, атака не підлягає покаранню.</div>
        <div className={s.rulesOl}><span className={s.num}>7.3. </span> У миттєвому відеоповторі, час “Kal-yeo” визначається як момент, коли сигнал руки рефері “Kal-yeo” був завершений (повністю витягнута рука); і початок атаки визначається як момент, коли атакуюча нога повністю відривається від підлоги.</div>
        <div className={s.rulesOl}><span className={s.num}>7.4. </span>Якщо атака після “Kal-yeo” не влучила в тіло суперника, але здалося, що це було зроблено навмисно та зловмисно, рефері може покарати таку поведінку “Gam-jeom”.</div>
    </div>
    <div className={s.rulesOl}><span className={s.num}>8. </span> Удар голови суперника рукою: ця стаття включає удар по голові суперника рукою (кулаком), зап’ястком, рукою або ліктем. Однак, неминучі дії внаслідок недбалості суперника, такі як надмірне нахилення голови або необережне повертання тіла, не можуть бути покарані цією статтею.</div>
    <div className={s.rulesOl}><span className={s.num}>9. </span>Удар головою або атака коліном: ця стаття стосується навмисного удару або атаки коліном при близькій відстані до суперника. Однак, контакт з коліном, що відбувається в наступних ситуаціях, не може бути покараний цією статтею:
        <div className={s.rulesOl}><span className={s.num}>9.1. </span>Коли суперник різко кидається на вас у момент виконання удару</div>
        <div className={s.rulesOl}><span className={s.num}>9.2. </span>Ненавмисно, або як результат різниці в дистанції при атаці.</div>
    </div>

    <div className={s.rulesOl}><span className={s.num}>10. </span>Атака на впалого суперника: ця дія надзвичайно небезпечна через високу ймовірність травми суперника. Небезпека виникає через наступне:
    <div className={s.rulesOl}><span className={s.num}>10.1. </span> Впавший суперник перебуває у негайно беззахисному стані</div>
    <div className={s.rulesOl}><span className={s.num}>10.2. </span>Сила будь-якої техніки, яка влучає у впалого суперника, буде більшою через позицію суперника. Ці види агресивних дій щодо впалого суперника не відповідають духу тхеквондо і, отже, не є відповідними для змагань з тхеквондо. У цьому контексті покарання повинні бути призначені за навмисну атаку на впалого суперника, незалежно від ступеня удару.</div>
    </div>

    <div className={s.rulesOl}>Коли порушення вчиняється учасником або тренером під час періоду відпочинку, через п'ять секунд після закінчення раунду, рефері може негайно оголосити “Gam-jeom”, і цей штраф буде записаний у наступному раунді. Однак, “Gam-jeom” буде записано в попередній раунд, якщо дія сталася протягом п’яти секунд після закінчення раунду.</div>
    </div>
    
    <div >
    </div>
</div>


{/*Золотий бал та рішення переваги  */}
<div className={s.rulesBlock}>
    <div className={s.rulesArticle} id='GoldenPoints'>Стаття 15. Золотий бал та рішення переваги
        {isSmallScreen ? (<><br /></>) : null}
        <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
    </div>

    <div className={s.rulesOl}><span className={s.num}>1. </span>У разі, якщо переможця не вдасться визначити після 3 раундів, проводиться 4-й раунд (золотий раунд), який триває одну хвилину.</div>
    <div className={s.rulesOl}><span className={s.num}>2. </span>У випадку, якщо змагання переходить до золотого раунду, всі бали, отримані під час перших трьох раундів, стають недійсними.</div>
    <div className={s.rulesOl}><span className={s.num}>3. </span>Переможцем буде визнаний той учасник, який першим набере два (2) або більше балів або суперник якого отримає два "Гам-чжом" у золотому раунді.</div>
    <div className={s.rulesOl}><span className={s.num}>4. </span>У разі, якщо жоден з учасників не набрав два (2) бали після завершення золотого раунду, переможець визначається за критерієм переваги на основі наступних пунктів:
        <div className={s.rulesOl}><span className={s.num}>4.1. </span>Учасник, який отримав бал за удар кулаком у золотому раунді</div>
        <div className={s.rulesOl}><span className={s.num}>4.2. </span>Якщо жоден з учасників не отримав бал за удар кулаком або обидва учасники отримали по балу за удар кулаком у золотому раунді, учасник, який досяг більшої кількості влучень, зареєстрованих системою PSS під час золотого раунду.</div>
        <div className={s.rulesOl}><span className={s.num}>4.3. </span>Якщо кількість влучень, зареєстрованих системою PSS, однакова, учасник, який виграв більше раундів у перших трьох раундах.</div>
        <div className={s.rulesOl}><span className={s.num}>4.4. </span>Якщо кількість виграних раундів однакова, учасник, який отримав меншу кількість "Гам-чжом" протягом усіх чотирьох раундів.</div>
        <div className={s.rulesOl}><span className={s.num}>4.5. </span>Якщо три попередні критерії однакові, рефері та судді визначають перевагу на основі змісту золотого раунду. Якщо рішення щодо переваги між рефері та суддями є рівним, переможця визначає рефері.</div>

    </div>

    <div className={s.rulesOl}><span className={s.num}>5. </span>У системі "кращий з трьох" у разі рівного рахунку в відповідному раунді, переможець раунду визначається за перевагою на основі наступних критеріїв:
        <div className={s.rulesOl}><span className={s.num}>5.1. </span> Більшість балів за удар з розворотом.</div>
        <div className={s.rulesOl}><span className={s.num}>5.2. </span>Якщо технічний рахунок однаковий, учасник, який отримав більше балів в порядку технік більш високої цінності (удар по голові, тулуб, кулак, Гам-чжом).</div>
        <div className={s.rulesOl}><span className={s.num}>5.3. </span>Якщо бали за техніки з високою цінністю однакові, учасник, який отримав більше влучень, зареєстрованих системою PSS.</div>
        <div className={s.rulesOl}><span className={s.num}>5.4. </span> Якщо три попередні критерії однакові, рефері та судді визначають перевагу.
            <div className={s.rulesOl}><span className={s.num}>5.4.1. </span>У разі двох кутових суддів переможець визначається рефері та двома суддями.</div>
            <div className={s.rulesOl}><span className={s.num}>5.4.2. </span>У разі трьох кутових суддів переможець визначається трьома суддями, крім рефері.</div>
        </div>

    </div>
<div className={s.rulesOl}><b>Пояснення №1 </b> Рішення про перевагу суддів має базуватися на технічному домінуванні суперника шляхом агресивного управління поєдинком, більшої кількості виконаних технік, використання більш досконалих і складних технік, а також демонстрації кращих змагальних манер.</div>
<div className={s.rulesOl}><b>Пояснення №2 </b></div>
<div className={s.rulesOl}>У разі, якщо рефері веде рахунок за виконання успішного удару по голові, який спричинив падіння суперника, перед ударом по тулубу суперника, але було зареєстровано лише останній удар по тулубу, тренер спортсмена, який виконав удар по голові, може попросити відеоповтор. Якщо журі перегляду визначить, що удар по голові був дійсним і виконаний раніше, ніж удар по тулубу, рефері повинен скасувати бали, набрані ударом по тулубу, потім зарахувати бали за удар по голові і оголосити переможцем того, хто виконав удар по голові.</div>
<div className={s.rulesOl}>У разі, якщо один спортсмен, який уже має один (1) бал, виконує успішний удар кулаком перед ударом по тулубу суперника, але було зареєстровано лише останній удар по тулубу, тренер спортсмена, який виконав удар кулаком, може попросити відеоповтор. Якщо журі перегляду визначить, що удар кулаком був виконаний раніше, ніж удар по тулубу, рефері повинен запитати, чи судді записали удар кулаком. Якщо двоє або більше кутових суддів оцінили удар кулаком, рефері повинен скасувати бали, набрані ударом по тулубу, потім зарахувати бали за удар кулаком і оголосити переможцем того, хто виконав удар кулаком.</div>
<div className={s.rulesOl}><b>Керівництво для суддівства. </b> Процедура рішення про перевагу є такою:
 <div className={s.rulesOl}><span className={s.num}>1. </span>Перед змаганнями всі суддівські офіційні особи беруть з собою картку переваги.</div>
 <div className={s.rulesOl}><span className={s.num}>2. </span>Коли поєдинок має бути вирішений за перевагою, рефері оголошує "У-сі-гірок (записати перевагу)".</div>
 <div className={s.rulesOl}><span className={s.num}>3. </span>Після оголошення рефері судді повинні записати переможця протягом 10 секунд з опущеними головами, підписати картку та передати її рефері.</div>
 <div className={s.rulesOl}><span className={s.num}>4. </span>Рефері збирає всі картки переваги, записує кінцевий результат, а потім оголошує переможця.</div>
 <div className={s.rulesOl}><span className={s.num}>5. </span>Після оголошення переможця рефері передає картки реєстратору, і реєстратор передає картки технічному делегату Всесвітнього тхеквондо.</div>
</div>

   
    
    <div className={s.rulesOl}><b>Керівництво для суддівства за системою "кращий з трьох".</b>
    <div className={s.rulesOl}><span className={s.num}>1. </span>Коли раунд має бути вирішений за перевагою, рефері оголошує "У-сі-гірок (записати перевагу)".</div>
    <div className={s.rulesOl}><span className={s.num}>2. </span>Після оголошення рефері судді оголошують переможця одночасно після рахунку рефері "три" за допомогою жесту переможця раунду, зверненого до головного столу.</div>
    <div className={s.rulesOl}><span className={s.num}>3. </span>У разі двох кутових суддів переможець визначається рефері та двома кутовими суддями.</div>
    <div className={s.rulesOl}><span className={s.num}>4. </span>У разі трьох (3) кутових суддів переможець визначається трьома кутовими суддями, крім рефері.</div>
    <div className={s.rulesOl}><span className={s.num}>5. </span>Журі перегляду записує кінцевий результат і оголошує операторам переможця раунду.</div>
    <div className={s.rulesOl}><span className={s.num}>6. </span>Після оголошення переможця рефері оголошує переможця раунду або матчу.</div>

    </div>
    <div >
    </div>
</div>


{/*Рішення*/}
<div className={s.rulesBlock}>
    <div className={s.rulesArticle} id='Decisions'>Стаття 16. Рішення.
        {isSmallScreen ? (<><br /></>) : null}
        <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
    </div>

    <div className={s.rulesOl}><span className={s.num}>1. </span>Перемога через зупинці поєдинку рефері (RSC)</div>
    <div className={s.rulesOl}><span className={s.num}>2. </span>Перемога за остаточним рахунком (PTF)</div>
    <div className={s.rulesOl}><span className={s.num}>3. </span>Перемога за перевагою балів (PTG)</div>
    <div className={s.rulesOl}><span className={s.num}>4. </span>Перемога за золоті бали (GDP)</div>
    <div className={s.rulesOl}><span className={s.num}>5. </span>Перемога за перевагою (SUP)</div>
    <div className={s.rulesOl}><span className={s.num}>6. </span>Перемога через зняття з поєдинку (WDR)</div>
    <div className={s.rulesOl}><span className={s.num}>7. </span>Перемога за дискваліфікацією (DSQ)</div>
    <div className={s.rulesOl}><span className={s.num}>8. </span>Перемога за дисциплінарним рішенням рефері (PUN)</div>
    <div className={s.rulesOl}><span className={s.num}>9. </span>Перемога за дискваліфікацією за неналежну поведінку</div>

    <div className={s.rulesOl}><b>Пояснення №1 </b>Зупинка поєдинку рефері. Рефері оголошує RSC в таких ситуаціях:
        <div className={s.rulesOl}><span className={s.num}>1. </span> якщо учасник був збитий з ніг легітимною технікою суперника і не може продовжити поєдинок до рахунку "Ye o-dul"; або якщо рефері вважає, що учасник не може продовжувати змагання незалежно від перебігу рахунку;</div>
        <div className={s.rulesOl}><span className={s.num}>2. </span> якщо учасник не виконує команду рефері щодо продовження поєдинку тричі;</div>
        <div className={s.rulesOl}><span className={s.num}>3. </span> якщо рефері визнає необхідність зупинити поєдинок для захисту безпеки учасника;</div>
        <div className={s.rulesOl}><span className={s.num}>4. </span> коли лікар визначає, що поєдинок слід зупинити через травму учасника;</div>
    </div>
    <div className={s.rulesOl}><b>Пояснення №2 </b>Перемога за перевагою балів: У разі різниці в двадцять (20) балів між двома спортсменами під час завершення 2-го раунду та/або в будь-який час під час 3-го раунду, рефері зупиняє поєдинок і оголошує переможця за перевагою балів. Перемога за перевагою балів не застосовується в півфіналах та фіналах у старшій категорії за регламентом турніру.</div>
    <div className={s.rulesOl}><b>Пояснення №3 </b>Перемога за зняттям з поєдинку: Переможець визначається завдяки зняттю суперника з поєдинку.
        <div className={s.rulesOl}><span className={s.num}>1. </span>Коли учасник знімається з поєдинку через травму або з інших причин</div>
        <div className={s.rulesOl}><span className={s.num}>2. </span>Коли тренер кидає рушник у корт для позначення відмови від поєдинку</div>
    </div>
    <div className={s.rulesOl}><b>Пояснення №4 </b> Перемога за дискваліфікацією: Це результат, визначений через невдачу учасника на зважуванні або коли учасник не прибуває на стіл виклику спортсмена після третього виклику. Наступні дії повинні відрізнятися залежно від причини дискваліфікації.
        <div className={s.rulesOl}><span className={s.num}>1. </span> У разі, якщо учасники не пройшли або не з'явилися на зважуванні: Результат має відображатися в турнірній таблиці, а інформація має бути надана технічним офіційним особам та всім відповідним особам. Рефері не будуть призначені для цього поєдинку. Супернику спортсменів, які не пройшли або не з'явилися на зважуванні, не потрібно буде з'являтися на кортах для змагань.</div>
        <div className={s.rulesOl}><span className={s.num}>2. </span>  У разі, якщо учасник пройшов зважування, але не з'явився на стіл виклику спортсменів: Призначений рефері та суперник входять на майданчик і чекають на своїх позиціях, доки рефері оголосить суперника переможцем поєдинку. Детальна процедура визначена в пункті 4.1 статті 10.</div>

   </div>
    <div className={s.rulesOl}><b>Пояснення №5 </b>(Пояснення #5) Перемога за  рішенням рефері: Рефері оголошує PUN якщо учасник набрав десять  "Гам-чжом"</div>
    <div className={s.rulesOl}><b>Пояснення №6 </b> Перемога за дискваліфікацією за неналежну поведінку: DQB оголошується в таких ситуаціях:
        <div className={s.rulesOl}><span className={s.num}>1. </span>Коли учасник або будь-який член його/її команди маніпулює сенсорами або системою підрахунку балів PSS</div>
        <div className={s.rulesOl}><span className={s.num}>2. </span>Коли учасник обманює процедуру зважування</div>
        <div className={s.rulesOl}><span className={s.num}>3. </span>Коли учасник порушує правила WT Anti-Doping</div>
        <div className={s.rulesOl}><span className={s.num}>4. </span>Коли учасник або тренер здійснює серйозні порушення, описані в статті 23.3.1 та 23.3.2
 
</div>
 <div className={s.rulesOl}>Результат учасника, який програв через DQB, має бути видалений, а результат іншого учасника, якого торкнулася DQB, має бути перерозподілений.</div>
    </div>
    <div className={s.rulesOl}><b>Пояснення №7 </b> У системі "кращий з трьох" рішення мають слідувати процедурі статті 16:
    <div className={s.rulesOl}><span className={s.num}>16.1  </span> Перемога завдяки зупинці поєдинку рефері (RSC)</div>
    <div className={s.rulesOl}><span className={s.num}>16.2 </span>Перемога за остаточним рахунком (PTF)</div>
    <div className={s.rulesOl}><span className={s.num}>16.6 </span>Перемога через зняття з поєдинку (WDR)
    <div className={s.rulesOl}><span className={s.num}>16.7 </span>Перемога за дискваліфікацією (DSQ) </div>
    <div className={s.rulesOl}><span className={s.num}>16.9 </span>Перемога за дискваліфікацією за неналежну поведінку (DQB)
        <div className={s.rulesOl}><span className={s.num}>1. </span>У разі статті 16.2. Перемога за остаточним рахунком (PTF), результат поєдинку має бути сумою кількості виграних раундів з трьох раундів.</div>
        <div className={s.rulesOl}><span className={s.num}>2. </span>У разі статті 16.3. Перемога за перевагою балів (PTG), у разі різниці в дванадцять (12) балів між двома спортсменами на раунд, рефері має зупинити поєдинок і оголосити переможця за перевагою балів для відповідного раунду. Перевага балів для відповідного раунду не застосовується в півфіналах та фіналах у старшій категорії за регламентом турніру.</div>
    </div>
</div>   

    </div>
    
    <div >
    </div>
</div>


{/*Нокдаун  */}
<div className={s.rulesBlock}>
    <div className={s.rulesArticle} id='KnockDown'>Стаття 17. Нокдаун.
        {isSmallScreen ? (<><br /></>) : null}
        <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
    </div>

    <div className={s.rulesOl}>Нокдаун оголошується, внаслідок результативного проведення легитимної атаки,що була оцінена PSS чи суддями, якщо:
    <div className={s.rulesOl}><span className={s.num}>1. </span>Коли будь-яка частина тіла, крім підошви стопи, торкається підлоги через силу застосованної суперником техніки.</div>
    <div className={s.rulesOl}><span className={s.num}>2. </span>Коли учасник похитнувся і не проявляє наміру чи здатності продовжувати поєдинок.</div>
    <div className={s.rulesOl}><span className={s.num}>3. </span>Коли рефері вважає, що поєдинок не може продовжуватись через те, що учасник зазнав удару.</div>
    
    </div>

    <div className={s.rulesOl}><b>Пояснення №1 </b>Нокдаун: Це ситуація, в якій учасник збитий з ніг або похитнувся, або не здатний адекватно реагувати на вимоги поєдинку через удар. Навіть за відсутності таких ознак, рефері може розцінити як нокдаун ситуацію, коли внаслідок контакту продовження поєдинку буде небезпечним або коли є сумніви щодо безпеки учасника.</div>
    
    <div >
    </div>
</div>


{/*Порядок дій у разі нокдауну  */}
<div className={s.rulesBlock}>
    <div className={s.rulesArticle} id='KnockDownProcedure'>Стаття 18.Порядок дій у разі нокдауну.
        {isSmallScreen ? (<><br /></>) : null}
        <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
    </div>

    <div className={s.rulesOl}><span className={s.num}>1. </span> Коли учасник зазнав нокдауну в результаті легітимної атаки суперника, рефері повинен вжити таких заходів:
        <div className={s.rulesOl}><span className={s.num}>1.1. </span>Рефері повинен віддалити атакуючого від поваленого учасника, оголосивши "Kal-yeo (перерва)". Секундомір зупиняється після команди рефері "Kal-yeo (перерва)".</div>
        <div className={s.rulesOl}><span className={s.num}>1.2. </span>Рефері спочатку повинен перевірити стан поваленого учасника та голосно рахувати від "Ha-nah   (один)" до "Yeol (десять)" з інтервалом в одну секунду, роблячи жестові сигнали, що вказують на проходження часу.</div>
        <div className={s.rulesOl}><span className={s.num}>1.3. </span>Якщо повалений учасник піднімається під час рахунку рефері і бажає продовжити бій, рефері продовжує рахувати до "Yeo-dul  (вісім)" для відновлення учасника. Потім рефері визначає, чи відновився учасник, і якщо так, продовжує поєдинок, оголосивши "Kye-sok  (продовжити)".</div>
        <div className={s.rulesOl}><span className={s.num}>1.4. </span> Коли учасник, який зазнав нокдауну, не може продемонструвати бажання відновити поєдинок до рахунку "Yeo-dul (вісім)", рефері оголошує іншого учасника переможцем за допомогою RSC (Рефері зупиняє поєдинок).</div>
        <div className={s.rulesOl}><span className={s.num}>1.5. </span>Якщо обидва учасники зазнали нокдауну, рефері продовжує рахунок, поки один з учасників не відновиться достатньо.</div>
        <div className={s.rulesOl}><span className={s.num}>1.6. </span>Якщо обидва учасники зазнали нокдауну і обидва не відновлюються до рахунку "Yeol", переможець визначається за рахунком до нокдауну.</div>
        <div className={s.rulesOl}><span className={s.num}>1.7. </span>Коли рефері вважає, що учасник не може продовжувати, він може вирішити переможця без рахунку або під час рахунку.</div>
    </div>
    <div className={s.rulesOl}><span className={s.num}>2. </span>Процедури, які слід виконати після поєдинку: Будь-який учасник, який не зміг продовжити поєдинок через серйозну травму незалежно від частин тіла, не може вступити в змагання протягом тридцяти (30) днів без дозволу голови медичної комісії WT після подання заяви від лікаря, призначеного відповідною національною федерацією.
        <div className={s.rulesOl}><span className={s.num}>2.1. </span>За винятком медичних надзвичайних ситуацій, будь-який учасник із серйозною травмою повинен бути оцінений лікарем місця проведення заходу та затвердженим головою медичної комісії (MC) у медичній кімнаті негайно після поєдинку.</div>
        <div className={s.rulesOl}><span className={s.num}>2.2. </span> Будь-який учасник, який отримав нокаут через травму голови, повинен бути перевірений лікарем у медичній кімнаті згідно з медичними правилами WT. Лікар місця проведення заходу повинен провести SCAT5 для діагностики струсів у випадку травми голови протягом 30 хвилин після травми голови.</div>
        <div className={s.rulesOl}><span className={s.num}>2.3. </span> Будь-яка значна (від середньої до важкої) травма голови або струс передбачає обов'язкове відсторонення спортсмена від змагань протягом періоду відновлення (див. 18.2.5). Цей обов'язковий медичний період відновлення не можна скоротити за жодних обставин після того, як його було встановлено.</div>
        <div className={s.rulesOl}><span className={s.num}>2.4. </span>Рішення про припинення участі учасника через значну травму голови або струс має бути прийнято на основі одного з таких критеріїв:
            <div className={s.rulesOl}><span className={s.num}>2.4.1. </span>Комплексний неврологічний огляд і нейрокогнітивне тестування (SCAT5 або інші дійсні інструменти оцінки струсів, дозволені головою MC) проведені комісійним лікарем у медичній кімнаті місця проведення заходу, про що має бути повідомлено голову MC.</div>
            <div className={s.rulesOl}><span className={s.num}>2.4.2. </span>Припинення поєдинку рефері через будь-яку втрату свідомості, зміненого психічного стану або нездатності зробити значущий, стабільний та добровільний рух в результаті прямої травми голови принаймні протягом десяти  секунд або до рахунку десяти .</div>
            <div className={s.rulesOl}><span className={s.num}>2.4.3. </span>Нездатність повністю відновитися після травми голови і відновити поєдинок протягом однієї (1) хвилини медичної оцінки на килимі після того, як рефері викликав лікаря через можливий струс.</div>
        </div>
        <div className={s.rulesOl}><span className={s.num}>2.5. </span> Будь-який учасник, якому поставили діагноз значної травми голови або струсу на основі одного з вищезгаданих критеріїв, отримає 30 днів відсторонення (старші), 40 днів відсторонення (юніори) або 50 днів відсторонення (кадети). Цей обов'язковий період медичного відсторонення не можна скоротити за жодних обставин після того, як його було надано.</div>
        <div className={s.rulesOl}><span className={s.num}>2.6. </span>Будь-який учасник, який отримав другий струс за останні 90 днів, отримає 90 днів відсторонення, а той, хто отримав третій струс за останні 180 днів, отримає 180 днів відсторонення.</div>
        <div className={s.rulesOl}><span className={s.num}>2.7. </span>У разі недоповідності, неправильного діагностування або неправильного ведення будь-якого випадку струсу або значної травми голови без обов'язкового медичного відсторонення, Спортивний відділ WT та Медичний комітет WT на чолі з головою MC WT розпочнуть розслідування випадку за ретроспективним відеооглядом навіть після закінчення змагань. Про випадок має бути повідомлено голову MC WT протягом 30 днів після дати інциденту для початку розслідування. Якщо відеоогляд, підтверджений щонайменше трьома (3) рецензентами медичного комітету WT, виявляє очевидний струс або серйозну травму голови (нокаут більше 10 секунд) або інші серйозні травми, що вимагають щонайменше 30 днів обов'язкового медичного відсторонення, медичний комітет WT повинен скасувати рішення медичного експерта (ОМД або призначеного лікаря) та застосувати обов'язкові правила відсторонення до спортсмена для захисту здоров'я та безпеки учасника.</div>
    </div>
 <div className={s.rulesOl}><b>Пояснення №1 </b>Відсторонити атакуючого суперника:
У цій ситуації стоячий суперник повинен повернутися на відповідну позначку учасника, однак, якщо повалений учасник перебуває на чи біля позначки суперника, суперник повинен чекати на межі зони перед стільцем свого тренера.</div>
 <div className={s.rulesOl}><b>Керівництво для офіційних осіб </b>Рефері повинен бути постійно готовим до раптового виникнення нокдауну або ситуації, коли учасник хитається, що зазвичай характеризується потужним ударом із супроводом удару.</div>
 <div className={s.rulesOl}><b>Пояснення №2 </b> Якщо повалений учасник піднімається під час рахунку рефері і бажає продовжити бій:
Основною метою рахунку є захист учасника. Навіть якщо учасник бажає продовжити матч до досягнення рахунку восьми, рефері повинен рахувати до "Yeo-dul (вісім)" перед відновленням матчу. Рахунок до "Yeo-dul" є обов'язковим і не може бути змінений рефері.</div>
  <div className={s.rulesOl}><b>* </b>Рахунок від одного до десяти: Ha-nah, Duhl, Seht, Neht, Da-seot, Yeo-seot, Il-gop, Yeo-dul, A-hop, Yeol.</div>
 <div className={s.rulesOl}><b>Пояснення №3 </b> Рефері потім визначає, чи відновився учасник, і якщо так, перезапускає бій оголошенням "Kye-sok":
Рефері повинен перевірити здатність учасника продовжити, рахуючи до восьми. Остаточне підтвердження стану учасника після рахунку восьми є лише процедурним, і рефері не повинен марно витрачати час перед поновленням матчу.</div>
 <div className={s.rulesOl}><b>Пояснення №4 </b>Коли учасник, який зазнав нокдауну, не може висловити бажання продовжити до рахунку "Yeo-dul", рефері повинен оголосити іншого учасника переможцем за допомогою RSC після рахунку до "Yeol":Учасник виражає бажання продовжити матч, декілька разів прийнявши бойову стійку з кулаками. Якщо учасник не може показати цей жест до рахунку "Yeo-dul", рефері повинен оголосити іншого учасника переможцем після першого рахунку "A-hop" і "Yeol". Виявлення бажання продовжити після рахунку "Yeo-dul" не можна вважати дійсним. Навіть якщо учасник висловлює бажання відновити гру до рахунку "Yeo-dul", рефері може продовжувати рахунок і оголосити поєдинок закінченим, якщо він/вона визначить, що учасник не здатний придовжувати.</div>
 <div className={s.rulesOl}><b>Пояснення №5 </b>Коли учасник зазнає потужного удару, і його стан здається серйозним, рефері може призупинити рахунок і викликати першу допомогу або зробити це разом із рахунком.</div>

<div className={s.rulesOl}><b>Керівництво для офіційних осіб</b>
<div className={s.rulesOl}><span className={s.num}>1. </span>Рефері не повинен витрачати додатковий час, підтверджуючи одужання учасника після рахунку до "Yeo-dul", оскільки він не зміг спостерігати цей стан під час рахунку.</div>
<div className={s.rulesOl}><span className={s.num}>2. </span>Коли учасник чітко одужує до рахунку "Коли учасник чітко одужує до рахунку "Yeo-dul" і висловлює бажання відновити, і рефері може чітко розпізнати стан учасника, але поновлення ускладнене вимогою медичної допомоги, рефері повинен спочатку відновити матч за допомогою оголошення "Kye-sok", а потім негайно оголосити “Kal-yeo” і "Kye-shi" та слідувати процедурам статті 19.</div>
</div>

    
    
    <div >
    </div>
</div>


{/*Процедури призупинення матчу*/}
<div className={s.rulesBlock}>
    <div className={s.rulesArticle} id='SuspendingMatch'>Стаття 19. Процедури призупинення матчу.
        {isSmallScreen ? (<><br /></>) : null}
        <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
    </div>

    <div className={s.rulesOl}><span className={s.num}>1. </span>Коли поєдинок припиняється через травму одного чи обох учасників, рефері повинен прийняти передбачені нижче заходи. Однак у ситуації, яка вимагає припинення поєдинку з інших причин, ніж травма, рефері повинен оголосити “Kal-yeo (перерва)” і відновити поєдинок, оголосивши “Kye-sok (продовжити)”.
        <div className={s.rulesOl}><span className={s.num}>1.1. </span>Рефері повинен призупинити поєдинок, оголосивши “Kal-yeo”, і наказати секретарям зупинити час.</div>
        <div className={s.rulesOl}><span className={s.num}>1.2. </span>Рефері повинен дозволити учаснику одну хвилину для отримання першої допомоги від лікаря комісії; рефері може дозволити командному лікарю надати першу допомогу, якщо лікар комісії недоступний або якщо це вважається необхідним.
            <div className={s.rulesOl}><span className={s.num}>1.2.1. </span> Лікар комісії може попросити більше часу (до 2 хвилин), якщо це необхідно.</div>
            <div className={s.rulesOl}><span className={s.num}>1.2.2. </span> Якщо немає лікаря комісії, командного лікаря або голови медичної комісії, будь-який лікар (або медичний співробітник) біля килима може бути запрошений надати спортсмену першу допомогу.</div>
        </div>
        <div className={s.rulesOl}><span className={s.num}>1.3. </span>Якщо травмований учасник не може повернутися до матчу після однієї хвилини, рефері повинен оголосити іншого учасника переможцем.</div>
        <div className={s.rulesOl}><span className={s.num}>1.4. </span>Якщо поновлення поєдинку неможливо після однієї хвилини, учасник, який завдав травму забороненим діянням, повинен бути оштрафований “Gam-jeom” і оголошений переможеним.</div>
        <div className={s.rulesOl}><span className={s.num}>1.5. </span>Якщо обидва учасники повалені і не можуть продовжити поєдинок після однієї хвилини, переможець буде визначений за очками, набраними до виникнення травм.
            <div className={s.rulesOl}><span className={s.num}>1.5.1. </span>У випадку системи "кращих з трьох" (3): якщо обидва учасники повалені і не можуть продовжити поєдинок після однієї хвилини в раунді 1 або 3, переможець визначається за очками, набраними до виникнення травм у відповідному раунді. Якщо це відбувається під час раунду 2, переможець визначається за рішенням раунду 1.</div>
            <div className={s.rulesOl}><span className={s.num}>1.5.2. </span>Якщо очки рівні, переможець визначається за критеріями переваги.</div>
        </div>
        <div className={s.rulesOl}><span className={s.num}>1.6. </span>Якщо рефері визначає, що біль учасника викликаний лише забоєм, рефері повинен оголосити “Kal-yeo” і дати команду продовжити поєдинок, оголосивши “stand-up”. Якщо учасник відмовляється продовжувати поєдинок після того, як рефері три рази дає команду “stand up”, рефері повинен оголосити поєдинок як “Referee Stops Contest”.</div>
        <div className={s.rulesOl}><span className={s.num}>1.7. </span>Якщо рефері визначає, що учасник отримав травму, наприклад, зламана кістка, вивих, розтягнення щиколотки або кровотеча, рефері повинен дозволити учаснику отримати першу допомогу протягом однієї хвилини після “Kye-shi”. Рефері може дозволити учаснику отримати першу допомогу навіть після оголошення “stand-up”, якщо учасник вважається травмованим в одній з зазначених категорій.</div>
        <div className={s.rulesOl}><span className={s.num}>1.8. </span>Зупинка поєдинку через травму: якщо рефері визначає, що учасник отримав травму, наприклад, зламану кістку, вивих, розтягнення щиколотки або кровотечу, рефері повинен проконсультуватися з головою медичної комісії або з призначеним комісією лікарем. Якщо учасник знову отримує травму таким же чином, голова медичної комісії або призначений лікарем комісії може порадити рефері зупинити поєдинок і оголосити пораненого переможеним.</div>
    </div>

    <div className={s.rulesOl}><b>Пояснення №1 </b>Коли рефері визначає, що змагання не може бути продовжене через травму або іншу надзвичайну ситуацію, він/вона може вжити таких заходів:
        <div className={s.rulesOl}><span className={s.num}>1. </span>Якщо ситуація критична, наприклад, учасник втрачає свідомість або страждає від серйозної травми, і час критичний, першу допомогу потрібно негайно надати, і матч має бути закритий. У цьому випадку результат поєдинку буде вирішено так:
            <div className={s.rulesOl}><span className={s.num}>1.1. </span>Учасник, який завдав травми, буде оголошений переможеним, якщо результат був наслідком забороненої дії, що призвело до покарання “Gam-jeom”.</div>
            <div className={s.rulesOl}><span className={s.num}>1.2. </span>Непрацездатний учасник буде оголошений переможеним, якщо результат був наслідком законної дії або випадкового, неминучого контакту.</div>
            <div className={s.rulesOl}><span className={s.num}>1.3. </span>Якщо результат не пов’язаний із змістом матчу, переможець буде вирішений за рахунком матчу до призупинення матчу. Якщо призупинення відбувається до кінця першого раунду, матч буде анульований. Якщо спортсмени набрали рівну кількість балів, переможець визначається за критеріями переваги.</div>
            <div className={s.rulesOl}><span className={s.num}>1.4. </span>У випадку системи "кращих з трьох": якщо результат не пов’язаний із змістом матчу, переможець буде визначений за очками, набраними до призупинення в відповідному раунді (у випадку раунду 1 або 3). Якщо спортсмени набрали рівну кількість балів, переможець визначається за критеріями переваги. Якщо це відбувається в раунді 2, переможець визначається за рішенням раунду 1.</div>
            </div>
        <div className={s.rulesOl}><span className={s.num}>2. </span>Якщо для травми потрібне надання першої допомоги, учасник може отримати необхідне лікування протягом однієї хвилини після оголошення “Kye-shi”.
            <div className={s.rulesOl}><span className={s.num}>2.1. </span>Наказ відновити поєдинок: це рішення головного рефері після консультації з лікарем комісії, чи можливе відновлення учасником поєдинку. Рефері може в будь-який час наказати учаснику відновити поєдинок протягом однієї хвилини. Рефері може оголосити учасника, який не дотримується наказу про відновлення поєдинку, переможеним.</div>
            <div className={s.rulesOl}><span className={s.num}>2.2. </span>Поки учасник отримує медичну допомогу або проходить процес відновлення, через 40 секунд після оголошення “Kye-shi”, рефері починає голосно оголошувати час, що проходить, з інтервалом у п'ять секунд. Коли учасник не може повернутися до позначки учасника до кінця однієї хвилини, результати поєдинку мають бути оголошені.</div>
            <div className={s.rulesOl}><span className={s.num}>2.3. </span>Після оголошення “Kye-shi” один хвилинний інтервал часу повинен рахуватися з моменту, коли лікар комісії входить на килим або після очікування лікаря комісії до 10 секунд, якщо він не доступний на килимі. Однак, коли потрібне лікування, але лікар відсутній або потрібне додаткове лікування, ліміт у хвилину може бути призупинений за рішенням рефері.</div>
            <div className={s.rulesOl}><span className={s.num}>2.4. </span>Якщо поновлення поєдинку неможливе після однієї хвилини, рішення поєдинку буде визначатися згідно з підпунктом "i" цієї статті.</div>
        </div>
        <div className={s.rulesOl}><span className={s.num}>3. </span>Якщо обидва учасники стали непрацездатними і не можуть відновити поєдинок після однієї хвилини або виникають термінові обставини, результат поєдинку визначається за наступними критеріями:
            <div className={s.rulesOl}><span className={s.num}>3.1. </span>Якщо результат є наслідком забороненої дії, що призвело до покарання “Gam-jeom” одним із учасників, ця особа буде переможена.</div>
            <div className={s.rulesOl}><span className={s.num}>3.2. </span>Якщо результат не пов'язаний із забороненою дією, що призвело до покарання “Gam-jeom”, результат поєдинку буде визначений за рахунком матчу на момент призупинення поєдинку. Однак якщо призупинення відбувається до закінчення першого раунду, матч буде анульований, і організаційний комітет визначить відповідний час для повторного проведення поєдинку. Учасник, який не може відновити поєдинок, вважається відкликаним з поєдинку.</div>
            <div className={s.rulesOl}><span className={s.num}>3.3. </span>У випадку системи "кращих з трьох": якщо результат не пов’язаний із покаранням “Gam-jeom”, результат змагання визначається за очками, набраними до призупинення в відповідному раунді (у випадку раунду 1 або 3). Якщо спортсмени набрали рівну кількість балів, переможець визначається за критеріями переваги. Якщо це відбувається в раунді 2, переможець визначається за рішенням раунду 1. </div>
            <div className={s.rulesOl}><span className={s.num}>3.4. </span>Якщо результат є наслідком заборонених дій, що призвело до покарання “Gam-jeom” обома учасниками, тоді обидва учасники програють.</div>
        </div>
    
    </div>
     <div className={s.rulesOl}><b>Пояснення №2 </b>Ситуація, яка вимагає призупинення поєдинку поза передбаченими процедурами, має розглядатися наступним чином:
        <div className={s.rulesOl}><span className={s.num}>1. </span>Коли неконтрольовані обставини вимагають призупинення поєдинку, рефері повинен призупинити поєдинок і виконувати директиви технічного делегата.</div>
        <div className={s.rulesOl}><span className={s.num}>2. </span>Якщо поєдинок призупиняється після завершення другого раунду, результат визначається за рахунком матчу на момент призупинення.
            <div className={s.rulesOl}><span className={s.num}>2.1. </span>У випадку системи "кращих з трьох", якщо поєдинок призупиняється, результат визначається за очками, набраними до призупинення в відповідному раунді (у випадку раунду 1 або 3 поєдинку).Якщо спортсмени набрали рівну кількість балів, переможець визначається за критеріями переваги.  У разі виникнення під час раунду 2, переможець визначається рішенням раунду 1. </div>
        </div>
        <div className={s.rulesOl}><span className={s.num}>3. </span> Якщо поєдинок призупиняється до закінчення другого раунду, повторний поєдинок повинен, у принципі, проводитися і буде проводитися у трьох раундах.</div>
     </div>
    <div >
    </div>
</div>


{/*Технічні делегати  */}
<div className={s.rulesBlock}>
    <div className={s.rulesArticle} id='TechnicalOfficials'>Стаття 20. Технічні делегати.
        {isSmallScreen ? (<><br /></>) : null}
        <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
    </div>

    <div className={s.rulesOl}><span className={s.num}>1. </span>Технічний делегат (ТД)
        <div className={s.rulesOl}><span className={s.num}>1.1. </span>Кваліфікація: Президент WT призначає ТД серед членів технічного комітету WT для чемпіонатів, що просуваються WT, за рекомендацією Генерального секретаря WT.</div>
        <div className={s.rulesOl}><span className={s.num}>1.2. </span>Ролі: ТД відповідає за забезпечення правильного застосування Правил змагань WT та головує на засіданні керівників команд і сесії жеребкування. ТД затверджує результат жеребкування, зважування та змагань перед тим, як їх офіційно оформити. ТД має право приймати остаточні рішення щодо зони змагань та загальних технічних питань на змаганнях після консультації з Наглядовою радою змагань. ТД приймає остаточні рішення щодо будь-яких питань, пов'язаних із змаганнями, які не передбачені Правилами змагань. ТД є головою Наглядової ради змагань. ТД відповідає за звітування про оцінку заходу.</div>
    </div>
    <div className={s.rulesOl}><span className={s.num}>2. </span>
        <div className={s.rulesOl}><span className={s.num}>2.1. </span>Кваліфікація: Члени CSB призначаються президентом WT за рекомендацією Генерального секретаря з числа тих, хто має достатній досвід і знання змагань з тхеквондо.</div>
        <div className={s.rulesOl}><span className={s.num}>2.2. </span>Склад: CSB складається з одного голови та не більше ніж 4 членів на чемпіонатах, які просуваються WT. Голови комітетів Ігор WT, комітетів рефері WT, медичних комітетів WT і комітетів спортсменів WT включаються в CSB як члени ex-officio. Однак склад може бути змінений президентом, якщо це необхідно.</div>
        <div className={s.rulesOl}><span className={s.num}>2.3. </span> Ролі: CSB допомагає ТД у змаганнях та технічних питаннях і забезпечує проведення змагань відповідно до графіку. CSB оцінює виступи Журі перевірки та суддівських офіційних осіб. CSB також виконує обов'язки Надзвичайного санкційного комітету під час змагань щодо питань управління змаганнями.</div>
    </div>
    <div className={s.rulesOl}><span className={s.num}>3. </span>Суддівські офіційні особи
        <div className={s.rulesOl}><span className={s.num}>3.1. </span> Кваліфікація: Власники міжнародного сертифіката рефері, зареєстровані WT.</div>
        <div className={s.rulesOl}><span className={s.num}>3.2. </span>Обов'язки:
            <div className={s.rulesOl}><span className={s.num}>3.2.1. </span>Рефері:
                <div className={s.rulesOl}><span className={s.num}>3.2.1.1. </span> Рефері контролює матч.</div>
                <div className={s.rulesOl}><span className={s.num}>3.2.1.2. </span>Рефері оголошує “Shi-jak”, “Keu-man”, “Kal-yeo”, “Kye-sok”, “Kye-shi”, “Shi-gan”, переможця та переможеного, зняття очок, штраф і відставку. Усі оголошення рефері робляться після підтвердження результатів.</div>
                <div className={s.rulesOl}><span className={s.num}>3.2.1.3. </span>Рефері має право самостійно приймати рішення відповідно до встановлених правил.</div>
                <div className={s.rulesOl}><span className={s.num}>3.2.1.4. </span>В принципі, центральний рефері не нараховує очки. Однак, якщо один із кутових суддів піднімає руку, оскільки очки не були нараховані, то центральний рефері скликає засідання з суддями. Якщо виявиться, що двоє кутових суддів запитують про зміну рішення, рефері повинен прийняти та виправити рішення (у випадку 1 рефері + 3 суддів). У разі установки двох кутових суддів, результат оцінювання може бути змінений, коли дві особи з двох суддів та рефері погодяться це зробити.</div>
                <div className={s.rulesOl}><span className={s.num}>3.2.1.5. </span>У випадках, визначених статтею 15, рішення про перевагу приймається суддівськими офіційними особами після закінчення чотирьох  раундів, коли це необхідно.</div>
            </div>
            <div className={s.rulesOl}><span className={s.num}>3.2.2. </span>Судді:
                <div className={s.rulesOl}><span className={s.num}>3.2.2.1. </span>Судді негайно відзначають дійсні очки.</div>
                <div className={s.rulesOl}><span className={s.num}>3.2.2.2. </span>Судді висловлюють свою думку прямо, коли рефері просить це зробити.</div>
            </div>
            <div className={s.rulesOl}><span className={s.num}>3.2.3. </span>Журі перевірки (RJ):
                <div className={s.rulesOl}><span className={s.num}>3.2.3.1. </span>RJ переглядає миттєвий повтор і інформує рефері про рішення протягом тридцяти секунд.</div>
            </div>
            <div className={s.rulesOl}><span className={s.num}>3.2.4. </span>Технічний помічник:
                <div className={s.rulesOl}><span className={s.num}>3.2.4.1. </span>TA продовжує моніторити табло під час змагання, щоб оцінювання, штрафи та хронометраж правильно висвітлювалися, і негайно повідомляє рефері про будь-яку проблемну ситуацію в цьому відношенні.</div>
                <div className={s.rulesOl}><span className={s.num}>3.2.4.2. </span>TA повідомляє рефері про початок або зупинку змагань у тісній взаємодії з оператором системи та хронометристом.</div>
                <div className={s.rulesOl}><span className={s.num}>3.2.4.3. </span>TA вручну реєструє всі бали, штрафи та результат IVR на папері TA.</div>
            </div>
        </div>
        <div className={s.rulesOl}><span className={s.num}>3.3. </span>Склад суддівських офіційних осіб на корті:
            <div className={s.rulesOl}><span className={s.num}>3.3.1. </span>Команда офіційних осіб складається з одного рефері та трьох суддів.</div>
            <div className={s.rulesOl}><span className={s.num}>3.3.2. </span>Команда офіційних осіб складається з одного рефері та двох суддів.</div>
        </div>
        <div className={s.rulesOl}><span className={s.num}>3.4. </span>Призначення суддівських офіційних осіб:
            <div className={s.rulesOl}><span className={s.num}>3.4.1. </span>Призначення рефері та суддів здійснюється після того, як графік змагань затверджено.</div>
            <div className={s.rulesOl}><span className={s.num}>3.4.2. </span>Рефері та судді тієї ж національності, що й будь-який із учасників, не повинні бути призначені для такого поєдинку. Однак, виняток може бути зроблений для суддів, коли кількість суддівських офіційних осіб є недостатньою.</div>
        </div>
        <div className={s.rulesOl}><span className={s.num}>3.5. </span>Відповідальність за рішення: Рішення, прийняті рефері та суддями, є остаточними, і вони несуть відповідальність перед Наглядовою радою змагань за зміст цих рішень.</div>
        <div className={s.rulesOl}><span className={s.num}>3.6. </span>Уніформа:
            <div className={s.rulesOl}><span className={s.num}>3.6.1. </span>Рефері та судді повинні носити уніформу, визначену WT.</div>
            <div className={s.rulesOl}><span className={s.num}>3.6.2. </span>Суддівські офіційні особи не повинні брати або брати будь-які матеріали на зону змагань, які можуть заважати змаганням. Використання мобільних телефонів суддівськими офіційними особами на полі може бути обмежено, якщо це необхідно.</div>
        </div>
    </div>
    <div className={s.rulesOl}><span className={s.num}>4. </span>Хронометрист: Хронометрист відраховує час змагань, періоди відпочинку та призупинення, а також записує та висвітлює нараховані бали та/або штрафи.</div>


    <div className={s.rulesOl}><b>Пояснення №1 </b>Суддівські офіційні особи мають зупинитися в окремому готелі, щоб уникнути будь-якого контакту з офіційними особами команди. Готель має бути розташований на відстані менше 20 хвилин їзди від місця проведення.</div>
    <div className={s.rulesOl}><b>Інтерпретація </b>Деталі кваліфікації, обов'язків, організації тощо суддівських офіційних осіб повинні відповідати регламенту WT про управління міжнародними рефері.</div>
    <div className={s.rulesOl}><b>Інтерпретація </b>ТД може замінити або покарати суддівських офіційних осіб після консультації з CSB у разі, якщо суддівські офіційні особи були неправильно призначені, або коли вважається, що будь-хто з призначених суддівських офіційних осіб несправедливо провели змагання або повторно припустилися несправедливих помилок.</div>
    <div className={s.rulesOl}><b>Рекомендація щодо суддівства </b>Якщо кожен суддя нараховує різну кількість балів, наприклад, один суддя нараховує один бал, інший - два, а інший - жодного, і що жодний бал не визнається дійсним, або в разі, якщо записувач припускається помилки в хронометражі, оцінюванні або штрафах, будь-який із суддів може вказати на помилку та попросити підтвердження серед суддів. Потім рефері може оголосити “Kal-yeo” (перерва)”, щоб зупинити змагання і зібрати суддів для запиту заяв. Після обговорення рефері повинен оприлюднити рішення. У випадку, коли тренер запитує відеоперегляд з тієї ж справи, що й один із суддів просить про засідання між суддівськими офіційними особами, рефері повинен спочатку зібрати суддів перед тим, як прийняти запит тренера. Якщо було прийнято рішення виправити рішення, тренер має залишатися на місці, не використовуючи квоту на апеляцію. Якщо тренер продовжує стояти і просить відеоперегляду, рефері повинен прийняти запит тренера.</div>
    
    <div >
    </div>
</div>


{/*Відеоповтор  */}
<div className={s.rulesBlock}>
    <div className={s.rulesArticle} id='IVR'>Стаття 21. Відеоповтор
        {isSmallScreen ? (<><br /></>) : null}
        <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
    </div>

    <div className={s.rulesOl}><span className={s.num}>1. </span>Якщо є заперечення щодо суддівського рішення під час змагань, тренер команди може подати запит на негайний перегляд відеоповтору до головного судді. Тренер може запитати відеоповтор для наступних випадків:
        <div className={s.rulesOl}><span className={s.num}>1.1. </span>Штрафи проти суперника за падіння, перетин межі або атаку суперника після "Kal-yeo" або атаку на впалого суперника.</div>
        <div className={s.rulesOl}><span className={s.num}>1.2. </span>Технічні очки.</div>
        <div className={s.rulesOl}><span className={s.num}>1.3. </span>Будь-який штраф проти власного спортсмена.</div>
        <div className={s.rulesOl}><span className={s.num}>1.4. </span> Будь-яка механічна несправність або помилка в управлінні часом. У разі апеляції щодо механічної несправності PSS, тренер може звернутися до головного судді з проханням перевірити PSS у будь-який час під час 2-го і/або 3-го раунду. Однак, якщо механічна функція PSS працює належним чином, квота апеляцій тренера буде втрачена. Крім того, ця апеляція тренера вважається порушенням з боку тренера, за що “Gam-jeom” надається власному спортсмену згідно зі статтею 14.4.1.3 "Наступні порушення з боку тренера". Це стосується лише системи "Кращий з 3".</div>
        <div className={s.rulesOl}><span className={s.num}>1.5. </span>Коли рефері забув анулювати очко(и) після того, як був наданий "Gam-jeom" за заборонену дію.</div>
        <div className={s.rulesOl}><span className={s.num}>1.6. </span>Неправильна ідентифікація суддею спортсмена, який атакує.</div>
        <div className={s.rulesOl}><span className={s.num}>1.7. </span>Удар по голові, який не зарахований.</div>
    </div>
    <div className={s.rulesOl}><span className={s.num}>2. </span>Коли тренер апелює, головний суддя підходить до тренера та запитує причину апеляції. Жодна апеляція не приймається щодо очок, нарахованих за удари ногою або рукою по тулубу або удар ногою по тулубу PSS. Тренер може запитати негайний відеоповтор для удару по голові незалежно від використання PSS для голови. Сфера запиту на негайний відеоповтор обмежується лише однією дією, яка сталася протягом п'яти (5) секунд з моменту подання запиту тренером. Як тільки тренер піднімає синю або червону картку, щоб запросити негайний відеоповтор, вважається, що тренер використав свою квоту апеляцій за будь-яких обставин, якщо зустріч суддів задовольняє тренера.</div>
    <div className={s.rulesOl}><span className={s.num}>3. </span>Суддя повинен звернутися до Журі перегляду для перегляду негайного відеоповтору. Журі перегляду, яке не належить до тієї ж національності, що й спортсмени, переглядає відеоповтор.
        <div className={s.rulesOl}><span className={s.num}>3.1. </span>У останні п'ять (5) секунд будь-якого раунду головний суддя може запросити перегляд IVR для перевірки можливих штрафів "Gam-jeom" за наступні дії:
            <div className={s.rulesOl}><span className={s.num}>3.1.1. </span>Падіння</div>
            <div className={s.rulesOl}><span className={s.num}>3.1.2. </span>Перетин межі</div>
            <div className={s.rulesOl}><span className={s.num}>3.1.3. </span>Атака після "Kal-yeo"</div>
            <div className={s.rulesOl}><span className={s.num}>3.1.4. </span>Атака на впалого суперника</div>
            <div className={s.rulesOl}><span className={s.num}>3.1.5. </span>Будь-які очки, нараховані після забороненої дії, будуть анульовані.</div>
        </div>
        <div className={s.rulesOl}><span className={s.num}>3.2. </span>Якщо рефері сприймає спортсмена як такого, що хитається, сильний удар по голові, удар по очах, кровотечу або збитий ударом по голові, і починає рахувати, але удар не був зарахований PSS для голови, рефері або тренер повинен запросити IVR, щоб прийняти рішення про нарахування або ненарахування очок після рахунку.</div>
        <div className={s.rulesOl}><span className={s.num}>3.3. </span>Рефері може запитати IVR для роз'яснення перед оголошенням "Gam-jeom" за симуляцію травми.</div>
    </div>
    <div className={s.rulesOl}><span className={s.num}>4. </span>Після перегляду негайного відеоповтору Журі перегляду має повідомити головного суддю про остаточне рішення протягом тридцяти (30) секунд після отримання запиту.</div>
    <div className={s.rulesOl}><span className={s.num}>5. </span>Тренер отримує одну (1) апеляцію на запит на негайний відеоповтор на кожен поєдинок. Однак, залежно від розміру та рівня чемпіонату, технічний делегат може вирішити кількість квот апеляцій під час наради голів команд. Якщо апеляція буде успішною, і запит буде виправлений, тренер збереже право на апеляцію для відповідного поєдинку.</div>
    <div className={s.rulesOl}><span className={s.num}>6. </span>Рішення Журі перегляду є остаточним; жодних подальших апеляцій під час змагань або протестів після змагань не буде прийнято, крім помилок у розрахунку очок у змаганнях Олімпійських кваліфікаційних турнірів, заходах G6 або вище, як це пояснюється в статті 21.7.1.</div>
    <div className={s.rulesOl}><span className={s.num}>7. У разі явного помилкового рішення суддівських офіційних осіб та операторів розрахунку результатів щодо ідентифікації спортсмена або помилок у розрахунку очок наступним чином:</span>
        <ul>
            <li>Введення оцінки та "Gam-jeom" оператором</li>
            <li>Неправильна ідентифікація спортсменів головним суддею</li>
        </ul>
        будь-який із суддівських офіційних осіб повинен запросити IVR і виправити рішення в будь-який час протягом раунду.
        <div className={s.rulesOl}><span className={s.num}>7.1 </span>Якщо помилки в розрахунку очок або неправильної ідентифікації спортсмена не були виправлені протягом раунду суддівськими офіційними особами, і ці помилки вплинули на результат переможця змагань або раунду в Олімпійських кваліфікаційних турнірах, заходах G6 або вище, технічний делегат (TD) має право розглянути цю справу за допомогою Наглядової ради змагань (CSB), щоб внести необхідне виправлення протягом 30 хвилин після відповідного раунду. Якщо помилки були виявлені та підтверджені, TD може запросити поновлення раунду з моменту, коли сталася помилка. У разі множинних помилок змагання буде поновлено з моменту, коли сталася перша помилка.</div>
    </div>
    <div className={s.rulesOl}><span className={s.num}>8. </span>У разі успішної апеляції Наглядова рада змагань може розслідувати змагання наприкінці змагального дня та, за необхідності, вжити дисциплінарних заходів проти зацікавлених суддівських офіційних осіб.</div>
    <div className={s.rulesOl}><span className={s.num}>9. </span>У будь-який час під час раунду будь-який із суддів може попросити про додавання або вилучення технічних очок незалежно від квоти апеляцій тренера.</div>
    <div className={s.rulesOl}><span className={s.num}>10. </span>У турнірі, де система негайного відеоповтору недоступна, застосовується наступна процедура протесту:
        <div className={s.rulesOl}><span className={s.num}>10.1. </span>Якщо є заперечення щодо суддівського рішення, офіційний делегат команди повинен подати заяву на переоцінку рішення (заяву про протест) разом із безповоротним збором за протест у розмірі 200 доларів США до Арбітражної колегії (Наглядової ради змагань) протягом 10 хвилин після відповідного поєдинку.</div>
        <div className={s.rulesOl}><span className={s.num}>10.2. </span>Розгляд переоцінки має проводитися, виключаючи тих членів, які належать до тієї ж національності, що й спортсмен, і рішення щодо розгляду має прийматися більшістю голосів.</div>
        <div className={s.rulesOl}><span className={s.num}>10.3. </span>Члени Арбітражної колегії (Наглядової ради змагань) можуть викликати суддівських офіційних осіб для підтвердження подій.</div>
        <div className={s.rulesOl}><span className={s.num}>10.4. </span>Рішення, прийняте Арбітражною колегією (Наглядовою радою змагань), буде остаточним, і жодних подальших апеляцій не буде застосовано.</div>
        <div className={s.rulesOl}><span className={s.num}>10.5. </span>Процедури розгляду такі:
            <div className={s.rulesOl}><span className={s.num}>10.5.1. </span>Тренеру або голові команди з боку нації, що подає протест, дозволяється зробити коротку усну презентацію Арбітражній колегії на підтримку своєї позиції. Тренеру або голові команди з боку відповідаючої нації дозволяється зробити коротке заперечення.</div>
            <div className={s.rulesOl}><span className={s.num}>10.5.2. </span>Після перегляду заяви на протест, змагання протесту має бути організовано відповідно до критерію "Прийнятно" або "Неприйнятно".</div>
            <div className={s.rulesOl}><span className={s.num}>10.5.3. </span>Якщо це необхідно, колегія може заслухати думки судді або суддів.</div>
            <div className={s.rulesOl}><span className={s.num}>10.5.4. </span> Якщо це необхідно, колегія може переглянути матеріальні докази рішення, такі як письмові або візуальні записані дані.</div>
            <div className={s.rulesOl}><span className={s.num}>10.5.5. </span>Після розгляду колегія проводить таємне голосування для прийняття рішення більшістю голосів.</div>
            <div className={s.rulesOl}><span className={s.num}>10.5.6. </span>Голова колегії підготує звіт, який документує результати розгляду, і зробить ці результати публічно відомими.</div>
            <div className={s.rulesOl}><span className={s.num}>10.5.7. </span>Подальший процес після прийняття рішення:
                <div className={s.rulesOl}><span className={s.num}>10.5.7.1. </span>Помилки у визначенні результатів матчу, помилки в обчисленні очок у матчі або неправильної ідентифікації спортсмена призводять до скасування рішення.</div>
                <div className={s.rulesOl}><span className={s.num}>10.5.7.2. </span>Помилка в застосуванні правил: коли колегія визначає, що суддя допустив чітку помилку у застосуванні Правил змагань, наслідки помилки буде виправлено, і суддю буде покарано.</div>
                <div className={s.rulesOl}><span className={s.num}>10.5.7.3. </span>Помилки у фактичному судженні: коли колегія вирішує, що була чітка помилка у визначенні фактів, таких як вплив удару, важкість дії або поведінки, намір, час дії щодо оголошення або області, рішення не змінюється, і офіційні особи, які, здається, допустили помилку, будуть попереджені.</div>
            </div>
        </div>
    </div>

    <div className={s.rulesOl}><b></b></div>
    
    <div >
    </div>
</div>


{/*Тхеквондо для глухих  */}
<div className={s.rulesBlock}>
    <div className={s.rulesArticle} id='DeafTaekwondo'>Стаття 22. Тхеквондо для глухих.
        {isSmallScreen ? (<><br /></>) : null}
        <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
    </div>
<div className={s.rulesOl}>Ця стаття висвітлює зміни до Правил змагань, які використовуються для Деф-тхеквондо. Щодо питань, не покритих статтею 22, діятимуть Правила змагань WT.</div>
    <div className={s.rulesOl}><span className={s.num}>1. </span>Кваліфікація спортсмена.
Спортсмен повинен пройти процедури класифікації, описані в Кодексі класифікації Пара-тхеквондо та Деф-тхеквондо у світі, і отримати Клас спорту та Статус класу спорту.</div>
    <div className={s.rulesOl}><span className={s.num}>2. </span>Вагові категорії. 
Олімпійські вагові категорії застосовуються до змагань з Деф-тхеквондо.</div>
    <div className={s.rulesOl}><span className={s.num}>3. </span>Чемпіонати світу з Деф-тхеквондо будуть організовані на основі останньої Діючої процедури чемпіонатів світу з Деф-тхеквондо.</div>

    <div >
    </div>
</div>


{/*Санкції  */}
<div className={s.rulesBlock}>
    <div className={s.rulesArticle} id='Sanctions'>Стаття 23. Санкції.
        {isSmallScreen ? (<><br /></>) : null}
        <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
    </div>

    <div className={s.rulesOl}><span className={s.num}>1. </span>Президент WT, генеральний секретар або технічний делегат можуть вимагати скликання надзвичайного санкційного комітету для обговорення, в разі вчинення девіантної(них) дії(й) з боку тренера, спортсмена, офіційної особи та/або будь-якого члена національної асоціації-члена.</div>
    <div className={s.rulesOl}><span className={s.num}>2. </span>Надзвичайний санкційний комітет повинен розслідувати справу та викликати осіб, яких це стосується, для підтвердження подій.</div>
    <div className={s.rulesOl}><span className={s.num}>3. </span>Надзвичайний санкційний комітет повинен обговорити справу і визначити, чи слід накладати дисциплінарні заходи. Результат обговорення буде негайно оголошений громадськості. Якщо виявлено порушення, письмове рішення, яке містить відповідні факти, правила, підтверджувальні докази (наприклад, свідчення свідків), накладені санкції та обґрунтування, буде надано стороні, яка зазнала санкцій, якомога швидше, а копія буде включена до звіту технічного делегата.
        <div className={s.rulesOl}><span className={s.num}>3.1. </span>Потенційні порушення щодо поведінки спортсмена:
            <div className={s.rulesOl}><span className={s.num}>3.1.1. </span>Відмова виконати команду судді щодо завершення процедури завершення поєдинку, включаючи непідтвердження оголошення переможця.</div>
            <div className={s.rulesOl}><span className={s.num}>3.1.2. </span>Кидання своїх речей (шолом, рукавиці тощо) як вираз невдоволення рішенням.</div>
            <div className={s.rulesOl}><span className={s.num}>3.1.3. </span>Не залишення зони змагань після завершення поєдинку.</div>
            <div className={s.rulesOl}><span className={s.num}>3.1.4. </span>Не повернення до поєдинку після повторної команди судді.</div>
            <div className={s.rulesOl}><span className={s.num}>3.1.5. </span> Не дотримання рішення або команди офіційних осіб змагань.</div>
            <div className={s.rulesOl}><span className={s.num}>3.1.6. </span>Не дотримання обґрунтованих інструкцій офіційних осіб змагань щодо впорядкованого проведення заходу.</div>
            <div className={s.rulesOl}><span className={s.num}>3.1.7. </span>Маніпуляція обладнанням для підрахунку очок, датчиками або/та будь-якою частиною системи PSS.</div>
            <div className={s.rulesOl}><span className={s.num}>3.1.8. </span>Будь-яка серйозна нечесна поведінка під час поєдинку або агресивна поведінка щодо офіційних осіб змагань.</div>
        </div>
        <div className={s.rulesOl}><span className={s.num}>3.2. </span>Потенційні порушення щодо поведінки тренера, офіційної особи команди або інших членів національної асоціації: 
            <div className={s.rulesOl}><span className={s.num}>3.2.1. </span>Скарги на рішення судді або/та суперечки проти рішення офіційної особи під час або після раунду. </div>
            <div className={s.rulesOl}><span className={s.num}>3.2.2. </span>Суперечки з суддею або іншими офіційними особами. </div>
            <div className={s.rulesOl}><span className={s.num}>3.2.3. </span>Агресивна поведінка або коментарі щодо офіційних осіб, суперників або супротивної сторони, або глядачів під час поєдинку. </div>
            <div className={s.rulesOl}><span className={s.num}>3.2.4. </span>Провокація глядачів або поширення неправдивих чуток. </div>
            <div className={s.rulesOl}><span className={s.num}>3.2.5. </span>Вказівка спортсменам брати участь у недопустимих діях, наприклад, залишатися в зоні змагань після поєдинку. </div>
            <div className={s.rulesOl}><span className={s.num}>3.2.6. </span> Агресивні дії, такі як кидання або удар по своїх речах чи матеріалах змагань. </div>
            <div className={s.rulesOl}><span className={s.num}>3.2.7. </span>Не дотримання інструкцій офіційних осіб змагань залишити зону змагань або місце проведення заходу. </div>
            <div className={s.rulesOl}><span className={s.num}>3.2.8. </span>Будь-яка інша серйозна недопустима поведінка щодо офіційних осіб змагань. </div>
            <div className={s.rulesOl}><span className={s.num}>3.2.9. </span> Будь-яка спроба підкупу офіційних осіб змагань. 
        </div>
        </div>
    </div>
    <div className={s.rulesOl}><span className={s.num}>4. </span>Дисциплінарні дії. Дисциплінарні дії, видані надзвичайним санкційним комітетом, можуть змінюватися залежно від ступеня порушення. Можуть бути застосовані наступні санкції:
        <div className={s.rulesOl}><span className={s.num}>4.1. </span>Дискваліфікація спортсмена.</div>
        <div className={s.rulesOl}><span className={s.num}>4.2. </span>Попередження та вимога офіційних вибачень.</div>
        <div className={s.rulesOl}><span className={s.num}>4.3. </span>Скасування акредитації.</div>
        <div className={s.rulesOl}><span className={s.num}>4.4. </span>аборона перебування на місці проведення змагань:</div>
            <div className={s.rulesOl}><span className={s.num}>4.4.1. </span>Заборона на день.</div>
            <div className={s.rulesOl}><span className={s.num}>4.4.2. </span>Заборона на тривалість чемпіонату.</div>
        <div className={s.rulesOl}><span className={s.num}>4.5. </span>Скасування результатів:
            <div className={s.rulesOl}><span className={s.num}>4.5.1. </span>Скасування результатів поєдинку та всіх пов'язаних заслуг.</div>
            <div className={s.rulesOl}><span className={s.num}>4.5.2. </span>Скасування очок WT в рейтингу.</div>
        </div>
        <div className={s.rulesOl}><span className={s.num}>4.6. </span> Грошовий штраф від 100 до 5000 доларів США за порушення.</div>
    </div>
    <div className={s.rulesOl}><span className={s.num}>5. </span>Надзвичайний санкційний комітет може рекомендувати WT, або WT за власною ініціативою може розслідувати та визначити, що слід вжити додаткові дисциплінарні заходи проти членів/МНА, включаючи, але не обмежуючись, довгострокове відсторонення, довічну заборону або додаткові грошові штрафи.</div>    
    <div className={s.rulesOl}>Такий рекомендаційний висновок може бути заснований на порушеннях Правил змагань та інтерпретацій, а також порушеннях Кодексу етики WT або інших відповідних правил WT.</div>
    <div >
    </div>
</div>


{/*  */}
<div className={s.rulesBlock}>
    <div className={s.rulesArticle} id='Other'>Стаття 24. Інші питання, не передбачені Правилами Змагань
        {isSmallScreen ? (<><br /></>) : null}
        <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
    </div>
 <div className={s.rulesOl}>
У разі виникнення будь-яких питань, не передбачених у правилах, вони будуть вирішуватися наступним чином.
    <div className={s.rulesOl}><span className={s.num}>1. </span> Питання, пов'язані з поєдинком, вирішуватимуться шляхом консенсусу суддівських офіційних осіб відповідного поєдинку. </div>
    <div className={s.rulesOl}><span className={s.num}>2. </span> Питання, не пов'язані з конкретним поєдинком протягом чемпіонату, такі як технічні питання, питання змагань тощо, вирішуватимуться технічним делегатом.</div>
 
    </div>
    <div >
    </div>
</div>
<br/>

{/*  */}
{/* 
<div className={s.rulesBlock}>
    <div className={s.rulesArticle} id='#'>Стаття .
        {isSmallScreen ? (<><br /></>) : null}
        <a href="#Content" className={s.rulesContentLink}> <b> Зміст</b></a>
    </div>

    <div className={s.rulesOl}><span className={s.num}>1. </span></div>

    <div className={s.rulesOl}><b></b></div>
    
    <div >
    </div>
</div> */}

<div className={s.rulesOl}><b>Вище наведена стаття носить інформативний характер та не є офіційним документом!</b></div>
<br/>&nbsp;
        </div>
        <BackButton langSwitch={langSwitch}/><br/>
    </>)
}

export default RulesKeirugue