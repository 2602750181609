import { useEffect, useState } from 'react';
import s from './adminCompetition.module.css'
import state from '../../../state/state';
import GridsModalWindow from './GridsModalWindow';
import lang from './../../../state/language';
import HandleGridsMakeCell from './HandleGridsMakeCell';

function HandleGridsMake({
    sortedCategories,
    athletesArray,
    competition,
    saveGrids,
    setHandleGridsMakeWeiw,
    langSwitch,
    setErrorMessage
}){

    const [handleGrids, setHandleGrids] = useState([]);
    const [data, setData] = useState([]);
    const [start, setStart] = useState (false);
    const [isGridsModalOpen1, setIsGridsModalOpen1] = useState (false);
  
    
    const corts = Number(competition.corts)

   
    const openGridsModal = () => {setIsGridsModalOpen1(true);}
    const closeGridsModal = () => {setIsGridsModalOpen1(false);}

const emptyGrids=[]

    const handleGridMaker = async () => { 
      let gridsData = [];
     for(const dataItem of data){

        for (let i = 1; i <= competition.corts; i++) {
          // Сортуємо обрані категорії 
          const sortedCategories2 = Object.keys(sortedCategories).reduce((acc, key) => {

              if (dataItem.cort !== i) return acc; // Якщо номер корту не збігається, пропускаємо
      
              // Перевіряємо, чи є `categoryItem`, який починається з `key`
              const hasCategory = dataItem.category.some(categoryItem => 
                  typeof categoryItem === "string" && key.startsWith(categoryItem)
              );
      
              // Перевіряємо, чи є `classBItem`, який закінчується на `key`
              const hasClass = dataItem.classB.some(classBItem => 
                  typeof classBItem === "string" && key.endsWith(classBItem)
              );
      
              if (hasCategory && hasClass) {
                  acc[key] = sortedCategories[key]; // Додаємо категорію у відфільтрований об'єкт
              }
      
              return acc;
          }, {});      
      
          if (Object.keys(sortedCategories2).length > 0) {
            const gridsDataForCort = await state.tournConstructor.gridsMaker(sortedCategories2, athletesArray, competition, langSwitch, i);
            gridsData = [...gridsData, ...gridsDataForCort];         
          }
        }
}
        state.grids=[...gridsData]
        setHandleGrids(gridsData);   
      };

useEffect(()=>{
  if (data.length===corts){
    handleGridMaker(); 
    
  }
  },[data])

  useEffect(()=>{
    if (handleGrids.length>0){
      openGridsModal();
      setStart(false)
    }
    },[handleGrids])
       

    return(<>
     <h1 className={s.h1}> {competition.title} </h1>
       
   
       <div >
      <h2 className={s.h3}>
      {lang[langSwitch].matchCategoriesToCourts /* Задайте відповідність категорій до Кортів*/}
      </h2>
<div className={s.handleGridsWarning}>{lang[langSwitch].handleGridsWarning /* Увага! В ручному режимі система генерує сітки ТІЛЬКИ ПО ОБРАНИМ ВАМИ КАТЕГОРІЯМ! Уважно слідкуйте за положенням перемикачів.*/}
</div>
    </div>
    <div className={s.gridContainerForHandleCortChange}>

        {new Array(corts).fill(null).map((_, index)=>{
            
        return(<div key={index}>
        <HandleGridsMakeCell setData={setData}  langSwitch={langSwitch} cortNumber={index+1} start={start}/>
            </div>)

        })}


    </div> 
{handleGrids.length>0?(<>
    <GridsModalWindow
          grids={handleGrids}
          gridsDB={emptyGrids}
          setHandleGridsMakeWeiw={setHandleGridsMakeWeiw}
          competition={competition}
          isOpen={isGridsModalOpen1}
          saveGrids={saveGrids}
          onClose={closeGridsModal}
          langSwitch={langSwitch}  />
    </>):null}



    <div className={s.handleGridsBox}>
    <div >
  
    <button type='button'  onClick = {()=>{setStart(true)}}>
        {lang[langSwitch].generateGrids }
    </button>
  
 
   </div>
   <div style={{textAlign:'right'}}>
    <button type='button' onClick={() => { setHandleGridsMakeWeiw(false)}}>
       {lang[langSwitch].close /*Закрити*/}
         </button>
    </div>
    </div>

    </>)
}

export default HandleGridsMake