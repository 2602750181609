import { useState } from 'react';
import s from '../adminCompetition/adminCompetition.module.css'
import state from '../../../state/state';
import { format } from 'date-fns';
import { uk } from 'date-fns/locale';
import axios from 'axios';
import CommentItem from './commentItem';
import lang from '../../../state/language';
import indexedDBConnection from '../../../state/indexedDBConnection';



function CommentBlock({ id, information,isSmallScreen,langSwitch }) {

  const [text, setText] = useState('')
  const [dataForRender, setDataForRender] = useState(null)


  if (information && information !== 'null' && !Array.isArray(dataForRender)) {
    try {
      const informationData = JSON.parse(information)
      setDataForRender(informationData)
    } catch (error) { setDataForRender(information) }
  }

  function textHandle(e) { setText(e.target.value) }

  function axiosPost(inform) {
    axios.post(state.api.compititionChat,
      {
        id: id,
        information: JSON.stringify(inform),
        headers: { 'Content-Type': 'application/json' }
      })
      .then((response) => {
        setDataForRender(inform); 
        const index = state.competition.findIndex((item) => item.id == id)
        state.competition[index].information = JSON.stringify(inform)
        indexedDBConnection.saveData ('competition', state.competition)
      })
      .catch((error) => {  })
  }


  function updateWall() {
    if (text && text !== '') {
      const authorName = state.athleteName(state.userProfile.id)
      const data = {}
      data.id = state.userProfile.id
      data.photo = state.userProfile.photo
      data.name = authorName
      data.text = text
      data.date = state.dateForMessage()
      data.time = format(new Date(), 'HH:mm:ss', { locale: uk })
      ////////////////////////////////////////////////////////////////////////////
      axios.post(state.api.getCompetitionById,
        {
          id: id,
        })
        .then((response) => {
         
          const competition = response.data.competition
          let inform

          try {
            inform = JSON.parse(competition.information)
          } catch (error) {
            inform = competition.information
          }
          if (inform === 'null' || !inform) { inform = []; inform.unshift(data) }
          if (Array.isArray(inform)) { inform.unshift(data) }
          setText('')
          axiosPost(inform)
        })
        .catch((error) => { })
    }

  }

    return (<>
    <div className={s.commentTitle}>
      <p><b>
      {lang[langSwitch].additionalInformation /*Додаткова інформація*/}
        </b></p>
    </div>
    {state.userProfile.status === "Тренер" ? (<>
      <div className={s.commentWriter}>
        <textarea
          className={s.commentInput}
          value={text}
          placeholder={lang[langSwitch].enterAdditionalInformation /*Внесіть додаткову інформацію*/}
          rows="3" // Вказуємо початкову кількість рядків
          onKeyDown={(e) => {
            if (e.key === "Enter" && e.shiftKey) {
              e.preventDefault(); // Забороняємо дійсне додавання нового рядка
              e.target.rows += 1; //  Збільшуємо кількість рядків на одиницю
            }
          }}
          onInput={(e) => {
            e.target.style.height = "auto"; // Автоматично розтягуємо текстове поле вгору при введенні тексту
            e.target.style.height = e.target.scrollHeight + "px"; // Встановлюємо висоту, яка дорівнює висоті контенту
          }}
          onChange={textHandle}
        />
        <button className={s.commentButton} onClick={() => { updateWall() }}>
        {lang[langSwitch].add /*Додати*/}
        </button>
      </div>
    </>) : null}
    <div className={s.commentBox}>
      {Array.isArray(dataForRender) ?
        dataForRender.map((item, index) => {
          return (
            <CommentItem
            key={item.time}
              item={item}
              index={index}
              dataForRender={dataForRender}
              axiosPost={axiosPost}
              isSmallScreen={isSmallScreen}
              langSwitch={langSwitch}
            />
         )
        })
        : null
      }
    </div>

  </>)
}

export default CommentBlock