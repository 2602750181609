//формування одного блоку з яких складається "плитка" турнирів в календарі

import s from './calendar.module.css';
import { NavLink } from 'react-router-dom';
import state from '../../../state/state.js';
import lang from './../../../state/language';

function CompetitionBlock(props) {
  const competition = props.competition; // Отримуємо атлета з props
const langSwitch=props.langSwitch

  return (
    <div className={s.athleteBlock} style={ +competition.active !==1 ? { backgroundColor: "#d86e6e" } : {} }>

      <NavLink to={ `/competition/${competition.id}`}>
        <img src={competition.logo?competition.logo:state.noLogoPhoto} 
        alt={competition.title} />
      </NavLink>
  
      <div className={s.name}>
        <p>{competition.title}</p>
        </div>
      <p>
        <b>{state.formatDate(competition.dateStart).slice(0, 2)}</b> - <b>{state.formatDate(competition.dateFinish)}</b>
      </p>
      <p style={{padding:'5px'}}>
          <i>{competition.location}</i>
      </p>
     
   
      {/* Умова для редагування та адміністрування */}
      {competition.creator == state.userProfile.id&&competition.secretary != state.userProfile.id&& 
competition.mainJudge != state.userProfile.id ? (
        <div className={s.blockButton}>
          <NavLink to={`/EditCompetition/${competition.id}`}>
            <button type='button'>
            {lang[langSwitch].edit /*Редагувати*/}
            </button>
          </NavLink>     
         </div>
      ):null}

       {/* Умова для редагування та адміністрування */}
      {competition.creator == state.userProfile.id &&(competition.secretary == state.userProfile.id || 
competition.mainJudge == state.userProfile.id)? (
        <div className={s.blockButton}>
         
         <div className={s.blockButtonItem1}>
          <NavLink to={`/EditCompetition/${competition.id}`}>
            <button type='button'>
            {lang[langSwitch].edit /*Редагувати*/}
            </button>
          </NavLink>
          </div>
           <div className={s.blockButtonItem2}>
          <NavLink to={`/AdminCompetition/${competition.id}`}>
            <button  type='button'>
            {lang[langSwitch].toAdminister /*Адмініструвати*/} 
            </button>
          </NavLink>
        </div></div>
      ):null}
  
      {/* Умова для адміністрування */}
      {((competition.secretary == state.userProfile.id || 
competition.mainJudge == state.userProfile.id) &&
competition.creator != state.userProfile.id) && (
        <div className={s.blockButton}>
          <NavLink to={`/AdminCompetition/${competition.id}`}>
            <button style={{ cursor: 'pointer' }} type='button'>
            {lang[langSwitch].toAdminister /*Адмініструвати*/} 
            </button>
          </NavLink>
        </div>
      )}
    </div>
  );
  
}

export default CompetitionBlock;
