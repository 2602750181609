// Календарний план федерації

import lang from '../../../state/language.js';
import state from '../../../state/state.js';
import CompetitionTable from './CompetitionTable';

function Calendar ({isSmallScreen,langSwitch}) {
  state.messageCount()  //лічильник непрочитаних повідомлень
  state.tempNewCompetition=[]

const title=lang[langSwitch].calendarOfCompetitions /*Календар змаганнь*/

  const tourn=state.competition
  console.log('state.competition: ',state.competition)
    console.log('state.userProfile: ',state.userProfile)



  return (<div> 
      <CompetitionTable 
      tourn={tourn} 
      title={title} 
      isSmallScreen={isSmallScreen}
      langSwitch={langSwitch}
      />
   </div>
    );
}

export default Calendar