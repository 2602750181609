// Адміністрування змагань, що були створені безпосередньо користувачем

import { useEffect, useState } from "react";
import state from "../../../state/state.js"
import compressImage from "../photo/compressImage";
import axios from "axios";
import s from './myCompetition.module.css'
import sAdmin from '../adminCompetition/adminCompetition.module.css'
import Select from 'react-select'
import TextEditor from './textEditor';
import {  useParams } from "react-router-dom";
import ConfirmButtons from "../adminCompetition/confirmButtons";
import { eachDayOfInterval, format } from 'date-fns';
import MakeFoodOptionsCell from './makeFoodOptionsCell.js';
import lang from "../../../state/language.js";
import indexedDBConnection from "../../../state/indexedDBConnection.js";


 function EditCompetition({isSmallScreen,langSwitch}){  


    state.messageCount()//лічильник непрочитаних повідомлень   
    const { id } = useParams();//отримуємо id турниру
    const [reglament, setReglament] = useState(null);
//Створюємо об'ект змагань , що містить потрібні данні для адміністрування турниру    
const compForAdminIndex = state.competition.findIndex(tourn => tourn.id == id);
const compForAdmin=state.competition[compForAdminIndex]

//розпарсюємо вікові категорії
if (typeof compForAdmin.ageCategories === 'string') {
  const parsedAgeCategories = JSON.parse(compForAdmin.ageCategories);
  compForAdmin.ageCategories = parsedAgeCategories;
}
//Робимо копію об'єкту compForAdmin для виконання перевірки, 
//чи були внесені зміни перед викликом axios
if (state.tempNewCompetition.length === 0) {
  state.tempNewCompetition = state.objectCopy(compForAdmin);
}
let foodOptionsData

try{
  foodOptionsData=JSON.parse(state.tempNewCompetition.foodOptions)
  state.tempNewCompetition.foodOptions=foodOptionsData
}catch(error){
  foodOptionsData=state.tempNewCompetition.foodOptions
}

//переводимо клас турниру з цифр в назви 
const rank = lang[langSwitch].rankTournForEdit

//параметри пхумсе
let poomsaeOptions =JSON.parse(JSON.stringify(state.poomsaeOptions))
let poomsaeOptionsData
if(compForAdmin.poomsaeOptions){
try {poomsaeOptions=JSON.parse(compForAdmin.poomsaeOptions)}
catch {poomsaeOptions=compForAdmin.poomsaeOptions}
 poomsaeOptionsData=poomsaeOptions
}

    const [compressedFileURL, setCompressedFileURL] = useState(null);    
    const [compressedFile, setCompressedFile] = useState(null);
    const [filePdf, setFilePdf] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [selectedSecretary, setSelectedSecretary] = useState(null);
    const [selectedMainJudge, setSelectedMainJudge] = useState(null);
    const [ageCategories, setAgeCategories] = useState([]);
    const [cort, setCort] = useState(null);
    const [activCompetition, setActivCompetition] = useState(Number(compForAdmin.active));
    const [title, setTitle] = useState(null);

   
    const [poomse,setPoomse]=useState(compForAdmin.poomse==1?true:false)

    const [traditionalPoomsae, setTraditionalPoomsae] = useState(poomsaeOptions?poomsaeOptions.category.traditional.personal||poomsaeOptions.category.traditional.couple||poomsaeOptions.category.traditional.team?true:false:false);
    const [traditionalPersonalPoomsae, setTraditionalPersonalPoomsae] = useState(poomsaeOptions?poomsaeOptions.category.traditional.personal?true:false:false);
    const [traditionalCouplePoomsae, setTraditionalCouplePoomsae] = useState(poomsaeOptions?poomsaeOptions.category.traditional.couple?true:false:false);
    const [traditionalTeamPoomsae, setTraditionalTeamPoomsae] = useState(poomsaeOptions?poomsaeOptions.category.traditional.team?true:false:false);
    
    const [freestylePoomsae, setFreestylePoomsae] = useState(poomsaeOptions?poomsaeOptions.category.freestyle.personal||poomsaeOptions.category.freestyle.couple||poomsaeOptions.category.freestyle.team?true:false:false);
    const [freestylePersonalPoomsae, setFreestylePersonalPoomsae] = useState(poomsaeOptions?poomsaeOptions.category.freestyle.personal?true:false:false);
    const [freestyleCouplePoomsae, setFreestyleCouplePoomsae] = useState(poomsaeOptions?poomsaeOptions.category.freestyle.couple?true:false:false);
    const [freestyleTeamPoomsae, setFreestyleTeamPoomsae] = useState(poomsaeOptions?poomsaeOptions.category.freestyle.team?true:false:false);
    
    const [mixPoomsae, setMixPoomsae] = useState(poomsaeOptions?poomsaeOptions.category.mixPoomsae.personal||poomsaeOptions.category.mixPoomsae.couple||poomsaeOptions.category.mixPoomsae.team?true:false:false);
    const [mixPersonalPoomsae, setMixPersonalPoomsae] = useState(poomsaeOptions?poomsaeOptions.category.mixPoomsae.personal?true:false:false);
    const [mixCouplePoomsae, setMixCouplePoomsae] = useState(poomsaeOptions?poomsaeOptions.category.mixPoomsae.couple?true:false:false);
    const [mixTeamPoomsae, setMixTeamPoomsae] = useState(poomsaeOptions?poomsaeOptions.category.mixPoomsae.team?true:false:false);
    const [mixMixedTeamPoomsae, setMixMixedTeamPoomsae] = useState(poomsaeOptions?poomsaeOptions.category.mixPoomsae.mixedTeam?true:false:false);
    const [classB, setClassB] = useState(poomsaeOptionsData?poomsaeOptionsData.classB:false);

    const [poomsaeLimit, setPoomsaeLimit]= useState(poomsaeOptions&&poomsaeOptions.poomsaeLimit?poomsaeOptions.poomsaeLimit:2)
     const [numberOfPerfomance, setNumberOfPerfomance]= useState(poomsaeOptions&&poomsaeOptions.perfomanceNumber?poomsaeOptions.perfomanceNumber:2)
    const [competitionSystem, setCompetitionSystem]= useState(poomsaeOptions&&poomsaeOptions.competitionSystem?poomsaeOptions.competitionSystem:'Система виключення')
    
     function handleClassChange(){setClassB(!classB);  
          
    }
    
    
    
    function handleTraditionalPoomsaeChange() { 
      setTraditionalPoomsae(!traditionalPoomsae);
      setTraditionalPersonalPoomsae(false)
      setTraditionalCouplePoomsae(false)
      setTraditionalTeamPoomsae(false)   
    }
    function handleTraditionalPersonalPoomsaeChange() { setTraditionalPersonalPoomsae(!traditionalPersonalPoomsae) }
    function handleTraditionalCouplePoomsaeChange() { setTraditionalCouplePoomsae(!traditionalCouplePoomsae) }
    function handleTraditionalTeamPoomsaeChange() { setTraditionalTeamPoomsae(!traditionalTeamPoomsae) }

    function handleFreestylePoomsaeChange() { 
      setFreestylePoomsae(!freestylePoomsae)
      setFreestylePersonalPoomsae(false)
      setFreestyleCouplePoomsae(false) 
      setFreestyleTeamPoomsae(false)
    }
    function handleFreestylePersonalPoomsaeChange() { setFreestylePersonalPoomsae(!freestylePersonalPoomsae) }
    function handleFreestyleCouplePoomsaeChange() { setFreestyleCouplePoomsae(!freestyleCouplePoomsae) }
    function handleFreestyleTeamPoomsaeChange() { setFreestyleTeamPoomsae(!freestyleTeamPoomsae) }
    
    function handleMixPoomsaeChange() { 
      setMixPoomsae(!mixPoomsae)
      setMixPersonalPoomsae(false)
      setMixCouplePoomsae(false)
      setMixTeamPoomsae(false)
      setMixMixedTeamPoomsae(false) 
    }
    function handleMixPersonalPoomsaeChange() { setMixPersonalPoomsae(!mixPersonalPoomsae) }
    function handleMixCouplePoomsaeChange() { setMixCouplePoomsae(!mixCouplePoomsae) }
    function handleMixTeamPoomsaeChange() { setMixTeamPoomsae(!mixTeamPoomsae) }
    function handleMixMixedTeamPoomsaeChange() { setMixMixedTeamPoomsae(!mixMixedTeamPoomsae) }

    // параметри турниру Пхумсе
if(poomse){ 
  if(!poomsaeOptionsData){
    poomsaeOptionsData=JSON.parse(JSON.stringify(state.poomsaeOptions))
  }
    
    poomsaeOptionsData.category.traditional.personal = traditionalPersonalPoomsae ? true : false
    poomsaeOptionsData.category.traditional.couple = traditionalCouplePoomsae ? true : false
    poomsaeOptionsData.category.traditional.team = traditionalTeamPoomsae ? true : false
    poomsaeOptionsData.category.freestyle.personal = freestylePersonalPoomsae ? true : false
    poomsaeOptionsData.category.freestyle.couple = freestyleCouplePoomsae ? true : false
    poomsaeOptionsData.category.freestyle.team = freestyleTeamPoomsae ? true : false
    poomsaeOptionsData.category.mixPoomsae.personal = mixPersonalPoomsae ? true : false
    poomsaeOptionsData.category.mixPoomsae.couple = mixCouplePoomsae ? true : false
    poomsaeOptionsData.category.mixPoomsae.team = mixTeamPoomsae ? true : false
    poomsaeOptionsData.category.mixPoomsae.mixedTeam = mixMixedTeamPoomsae ? true : false
    poomsaeOptionsData.poomsaeLimit=poomsaeLimit
    poomsaeOptionsData.perfomanceNumber=numberOfPerfomance
    poomsaeOptionsData.competitionSystem=competitionSystem
    poomsaeOptionsData.classB=classB
    
  }
    //вибір турнірної системи   
    const competitionSystemSelect = (event) => { setCompetitionSystem(event.target.value)};
//Кількість Категорій
const handleNumberOfCategoryes = (e) => {setPoomsaeLimit(e.target.value.trim()) };

//Кількість комплексів у турі
const handleNumberOfPerfomance = (e) => {setNumberOfPerfomance(e.target.value.trim()) };

    function handleTypeChange(){setPoomse(!poomse)}



    const handleFileChange = (event) => {//обробка фото 
      const selectedFile = event.target.files[0];
  
      state.tempNewCompetition.logo = state.imgAdress.newCompetitionLogo + selectedFile.name;
  
      // Прописуємо функцію стиснення зображення та отримати стиснутий файл
      compressImage(selectedFile, {
        maxSizeMB: 0.5,
        maxWidth: 400,
        maxHeight: 300,
      })
        .then((compressedFile) => {
         
          setCompressedFile(compressedFile)
          // Створити URL для стиснутого файлу
          const compressedFileURL = URL.createObjectURL(compressedFile);
  
          // Встановити URL стиснутого файлу у стані компонента
          setCompressedFileURL(compressedFileURL);
         
        })
        .catch((error) => {
          // console.error('Помилка стиснення файлу:', error);
        });
    };
    
  
    //Назва турніру
    const handleTitleChange = (e) => { const value = e.target.value;  state.tempNewCompetition.title= value.trim(); setTitle(value.trim())};
 
  //Кількість учасників
  const handleNumberOfAthletes = (e) => { const value = e.target.value; state.tempNewCompetition.numberOfAthletes= value.trim(); };
  
//закриття реєстрації
const handleSwitchChange = () => {setActivCompetition(!activCompetition);}

if(activCompetition){
  state.tempNewCompetition.active=1} else{state.tempNewCompetition.active=0}


   //Адреса проведення турніру
   const handleLocationChange = (e) => { const value = e.target.value; state.tempNewCompetition.location= value.trim(); };
    
   //Дата початку турніру
   const handleDateStartChange = (e) => { const value = e.target.value;state.tempNewCompetition.dateStart= value.trim(); };
    
   //Адреса проведення турніру
   const handleDateFinishChange = (e) => { const value = e.target.value;state.tempNewCompetition.dateFinish= value.trim(); };
   
  //Кількість Даянгів
   const numbers = Array.from({ length: 24 }, (_, i) => i + 1);
    const handleSelect = (event) => {
      const selectedNumber = parseInt(event.target.value, 10);
      state.tempNewCompetition.corts=selectedNumber
    };
    
    // Ранг Турниру
    const rt = lang[langSwitch].rankTourn
     state.rankTourn = Object.keys(rt);
   
     const handleRankCompChange = (e) => {
      const selectedKey = e.target.value;
      const selectedValue = lang[langSwitch].rankTourn[selectedKey];

      if (selectedValue) {
        state.tempNewCompetition.rankComp = selectedKey[0];
      } else {
        // Обробка випадку, коли значення не знайдено.
      }
    };
    
  // Головний секретар  
  const options = state.athletes
    .filter((athlete) => athlete.judge == 1)
    .map((coach) => ({
      value: coach.id,
      label: `${coach.secondName} ${
        coach.personName && coach.personName[0] ? `${coach.personName[0].toUpperCase()}.` : ''
      } ${coach.fatherName && coach.fatherName[0] ? `${coach.fatherName[0].toUpperCase()}.` : ''}`,
    }));
  
  const handleSelectChange = (selectedOption) => {
    if (selectedOption === null) {
      // Видалення значення зі стану
      setSelectedSecretary(null);
      state.tempNewCompetition.secretary = null; // Встановіть null для secretary
    } else if (selectedOption) {
      setSelectedSecretary(selectedOption);
      state.tempNewCompetition.secretary = selectedOption.value;
    }
  };



  //головний суддя
const handleSelectMainJudge = (selectedOption) => {
  if (selectedOption === null) {
    // Видалення значення зі стану
    setSelectedMainJudge(null);
    state.tempNewCompetition.mainJudge = null; // Встановіть null для MainJudge
  } else if (selectedOption) {
    setSelectedMainJudge(selectedOption);
    state.tempNewCompetition.mainJudge = selectedOption.value;
  }
};
   
//////////////////////харчування///////////////////////////////////////////////////////
const [prise, setPrise] = useState(foodOptionsData?JSON.parse(JSON.stringify(state.tempNewCompetition.foodOptions.prise)):null);
const [times, setTimes] = useState(foodOptionsData?JSON.parse(JSON.stringify(state.tempNewCompetition.foodOptions.times)):null);
const [isFood, setIsFood] = useState(foodOptionsData?true:false);
const [isPriseBreakfast, setPriseBreakfast] = useState(!prise||!prise.breakfast?null:prise.breakfast);
const [isPriseDinner, setPriseDinner] = useState(!prise||!prise.dinner?null:prise.dinner);
const [isPriseSupper, setIsPriseSupper] = useState(!prise||!prise.supper?null:prise.supper);
const [result, setResult] = useState(
  { 
  times:[],  
  prise:{ 
      breakfast:isPriseBreakfast,
      dinner:isPriseDinner,
      supper:isPriseSupper   }}
);

const handleFoodChange = () => {
  if(! state.tempNewCompetition.dateStart||! state.tempNewCompetition.dateFinish){
    setErrorMessage(
      lang[langSwitch].youNotSetTournamentDate /* Ви не встановили дати турниру!*/
    )         
}else{
  setIsFood(!isFood)
}    
}

const handleBreakfastPrise = (e) => { setPriseBreakfast(e.target.value.replace(',', '.')) } 
const handleDinnerPrise = (e) => { setPriseDinner(e.target.value.replace(',', '.'))} 
const handleSupperPrise = (e) => { setIsPriseSupper(e.target.value.replace(',', '.')) } 

const dStart = new Date( state.tempNewCompetition.dateStart);
const dFinish = new Date( state.tempNewCompetition.dateFinish);

const dates = eachDayOfInterval({ start: dStart, end: dFinish });
const formattedDates = dates.map(date => format(date, 'dd.MM.yyyy'));

function updateFoodResult(data){
  if(foodOptionsData){
 setTimes(prev => {
  const updatedTimes = prev.filter(item => item.date !== data.date);
  updatedTimes.push(data);
  return updatedTimes;
});
}else{  
  setResult(prev => {
    const updatedTimes = prev.times.filter(item => item.date !== data.date);
    updatedTimes.push(data);
    return { ...prev, times: updatedTimes };
});
}
}

function foodOptionsUpdate(){

   state.tempNewCompetition.foodOptions={ 
    times:[],  
    prise:{   }}
     state.tempNewCompetition.foodOptions.prise.breakfast=isPriseBreakfast
  state.tempNewCompetition.foodOptions.prise.dinner=isPriseDinner
  state.tempNewCompetition.foodOptions.prise.supper=isPriseSupper
  state.tempNewCompetition.foodOptions.times=foodOptionsData?times:result.times 

if(!state.tempNewCompetition.foodOptions||
  !state.tempNewCompetition.foodOptions.times.some(item=>item.breakfast||item.dinner||item.supper)||
  !isFood){
  state.tempNewCompetition.foodOptions=null
}
}
///////////////////////////////////////////////////////////////////////////////////
 function updateTournLogo() { // завантаження логотипу турніру
    if (compressedFileURL) {
      const formData = new FormData();
      formData.append('image', compressedFile);
      axios.post(state.api.uploadLogoTourn, formData)
        .then(response => { 
          // Після завантаження зображення ви можете виконати PUT-запит для оновлення профілю        
          if (compForAdmin.logo!==null){
            //ортимуємо назву старого фото
              const parts =  compForAdmin.logo.split('/');
                const photoName = parts[parts.length - 1];
             
             //видалення старого фото
                axios.delete(state.api.deleteTournLogo, {
                  data: {                
                    fileName: photoName
                  }
                })
                .then(response => { // Обробка успішного видалення  
                })
                .catch(error => {// Обробка помилок
                }); 
                      }
        })
        .catch(error => {
          console.error('Помилка під час відправлення зображення:', error);
        });
     } else {
    //  console.log('немае логотипу')
      // Якщо `compressedFileURL` не існує, виконуємо тільки PUT-запит для оновлення профілю
      
    }
  }
  // Вибір вікової категорії спортсменів 
let updatedCategories
  const handleCheckboxChange = (value) => { 
    // Перевірка, чи значення вже присутнє в ageCategories
    if (ageCategories.includes(value)) {
      // Відфільтрувати і видалити значення, якщо воно вже присутнє
       updatedCategories = ageCategories.filter((category) => category !== value);
      
      // Перевірка, чи внесені зміни, і встановлення нового стану тільки за умови
      if (!arraysAreEqual(updatedCategories, state.tempNewCompetition.ageCategories)) {
        setAgeCategories(updatedCategories);
        state.tempNewCompetition.ageCategories = updatedCategories;
      }
    } else {
      // Додати нове значення, якщо воно відсутнє
      updatedCategories = [...ageCategories, value];      
      // Перевірка, чи внесені зміни, і встановлення нового стану тільки за умови
      if (!arraysAreEqual(updatedCategories, state.tempNewCompetition.ageCategories)) {
        setAgeCategories(updatedCategories);
        state.tempNewCompetition.ageCategories = updatedCategories;
      }
    }
  }; 
                      // Допоміжна функція для порівняння двох масивів
                      const arraysAreEqual = (array1, array2) => {
                        if(array1.length>0&&array2===null||array1.length>0&&array2===undefined){
                          return false;
                        }
                        if (array1.length !== array2.length) {
                          return false;
                        }
                        for (let i = 0; i < array1.length; i++) {
                          if (array1[i] !== array2[i]) {
                            return false;
                          }
                        }
                        return true;
                      };
// Ростові категорії
  //Регламент PDF
  let formDataPdf; // Перемінна для зберігання об'єкту FormData
  useEffect(() => {  
    if (filePdf) {          
      state.tempNewCompetition.reglamentFhoto = state.imgAdress.newCompetitionReglament + filePdf.name;
    } else {
      // console.error('Файл не було вибрано.');
    }
  }, [filePdf]);
  const handlePDFChange = (e) => {setFilePdf (e.target.files[0])};   
  function oldPdf() {
    const parts = compForAdmin.reglamentFhoto.split('/');
    const photoName = parts[parts.length - 1];
    //видалення старого фото
    axios.delete(state.api.deleteTournReglamentPDF, {
      data: {                
        fileName: photoName
      }
    })
    .then(response => { // Обробка успішного видалення  
    })
    .catch(error => {// Обробка помилок
    });
  }
  function UploadPdf2() {// Завантаження Pdf
    if (filePdf) {
      formDataPdf = new FormData(); // Створіть новий об'єкт FormData
      formDataPdf.append('file', filePdf); // Додайте файл до об'єкту FormData
      } else {
      // console.error('Файл не було вибрано.');
    }

    if (formDataPdf) { 
      // Перевірте, чи об'єкт formDataPdf існує перед викликом Axios
      axios.post(state.api.uploadPdfTourn, formDataPdf, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
        .then(response => {
          console.log('Файл було завантажено на сервер', response.data);
          if (compForAdmin.reglamentFhoto!==null){// перейменовуємо старий файл PDF Регламенту
     oldPdf() 
                    }

        })
        .catch(error => {
          console.error("Помилка завантаження файлів:", error);
          if (error.response) {
            console.error("Деталі помилки:", error.response.data);
          }
        });
    }
  } 
 

  function uploadTournData(){// завантаження даних з текстових полів    
   
    state.tempNewCompetition.ageCategories=poomse?null:state.tempNewCompetition.ageCategories
    state.tempNewCompetition.heightCategories=poomse?0:state.tempNewCompetition.heightCategories
    state.tempNewCompetition.olimpicAdults=poomse?0:state.tempNewCompetition.olimpicAdults
    state.tempNewCompetition.olimpicJuniors=poomse?0:state.tempNewCompetition.olimpicJuniors
    state.tempNewCompetition.poomse=poomse?poomse:0
    poomsaeOptionsData=poomse?poomsaeOptionsData:null
    


    if( !state.tempNewCompetition.title ||
      state.tempNewCompetition.title === '' ||
      state.tempNewCompetition.title === null ||
      !state.tempNewCompetition.corts ||
      state.tempNewCompetition.corts === '' ||
      state.tempNewCompetition.corts === null ||
      !state.tempNewCompetition.location ||
      state.tempNewCompetition.location === '' ||
      state.tempNewCompetition.location === null ||
      !state.tempNewCompetition.rankComp ||
      state.tempNewCompetition.rankComp === '' ||
      state.tempNewCompetition.rankComp === null ||
      !state.tempNewCompetition.dateStart ||
      !state.tempNewCompetition.dateFinish ||
      state.tempNewCompetition.numberOfAthletes === null){      
        setErrorMessage(
          lang[langSwitch].needDataForTournament /*Для створення Турніру ви повинні вказати: назву турніру, клас турніру, місце, дати проведення кількість учасників, кількість кортів та вікові категорії!*/
      );
      return; // При незаповнених полях виходимо з функції
      }
    if(poomse){
      if((
        poomsaeOptionsData.category.traditional.personal === false &&
        poomsaeOptionsData.category.traditional.couple === false &&
        poomsaeOptionsData.category.traditional.team === false &&
        poomsaeOptionsData.category.freestyle.personal === false &&
        poomsaeOptionsData.category.freestyle.couple === false &&
        poomsaeOptionsData.category.freestyle.team === false &&
        poomsaeOptionsData.category.mixPoomsae.personal === false &&
        poomsaeOptionsData.category.mixPoomsae.couple === false &&
        poomsaeOptionsData.category.mixPoomsae.team === false &&
        poomsaeOptionsData.category.mixPoomsae.mixedTeam === false
    ) &&
    poomsaeOptionsData.competitionSystem === null){
      setErrorMessage(
        lang[langSwitch].needDataForTournament /*Для створення Турніру ви повинні вказати: назву турніру, клас турніру, місце, дати проведення кількість учасників, кількість кортів та вікові категорії!*/
    );
    return; // При незаповнених полях виходимо з функції
    }
    }
    if(!poomse){
      if(
        !state.tempNewCompetition.ageCategories || 
        state.tempNewCompetition.ageCategories.length === 0 
      ){ setErrorMessage(
          lang[langSwitch].needDataForTournament /*Для створення Турніру ви повинні вказати: назву турніру, клас турніру, місце, дати проведення кількість учасників, кількість кортів та вікові категорії!*/
      );
      return; // При незаповнених полях виходимо з функції}
    }
  }
    
    axios 
  .post(state.api.updateCompetition, {
    title:state.tempNewCompetition.title,
    location:state.tempNewCompetition.location,
    rankComp:state.tempNewCompetition.rankComp,
    secretary:state.tempNewCompetition.secretary,
    mainJudge:state.tempNewCompetition.mainJudge,
    reglament:reglament,
    dateStart:state.tempNewCompetition.dateStart,
    dateFinish:state.tempNewCompetition.dateFinish,    
    logo:state.tempNewCompetition.logo,
    reglamentFhoto: state.tempNewCompetition.reglamentFhoto,
    ageCategories:JSON.stringify(state.tempNewCompetition.ageCategories),
    heightCategories:state.tempNewCompetition.heightCategories,
    corts:state.tempNewCompetition.corts,
    numberOfAthletes:state.tempNewCompetition.numberOfAthletes,
    active:state.tempNewCompetition.active,
    foodOptions:JSON.stringify(state.tempNewCompetition.foodOptions),
    olimpicAdults:state.tempNewCompetition.olimpicAdults,
    olimpicJuniors:state.tempNewCompetition.olimpicJuniors,
    poomse:state.tempNewCompetition.poomse,
    poomsaeOptions:JSON.stringify(poomsaeOptionsData),
    id:id,
      headers: {'Content-Type': 'application/json'}
  })
  .then((response) => {
      state.tempNewCompetition.reglament=reglament
    setSuccessMessage(
      lang[langSwitch].successfullSavedInDB /*Внесені зміни успішно збережено в Базі Даних*/
    );
 updateTournLogo()
  UploadPdf2()
  state.tempNewCompetition.poomsaeOptions=poomsaeOptionsData
    state.competition[compForAdminIndex] = state.tempNewCompetition;
    indexedDBConnection.saveData('competition',state.competition);
    
    setTimeout(function() {
      window.location.href = '/myCompetition'     
    }, 1000);
    
  state.tempNewCompetition=[]
  })
  .catch((error) => {
    setErrorMessage(
      lang[langSwitch].serverRequestError/*Помилка запиту до сервера:*/  + error);
  });
  }


  
  function deleteCompetition(){
   let  parts; let photoName
    if (compForAdmin.logo!==null){
      //ортимуємо назву старого фото
         parts =  compForAdmin.logo.split('/');
          photoName = parts[parts.length - 1];}
 
 axios
 .delete(state.api.deleteCompetition, {
     params: {
         id: compForAdmin.id,
         fileName: photoName, // Додано передачу імені файлу
     },
     headers: { 'Content-Type': 'application/json' },
 })
 .then((response) => {     
     
     if (compForAdmin.reglament) {oldPdf(); }

     async function updateDataDB() {
         try {
             await indexedDBConnection.deleteData('competition', compForAdmin.id)
                                      .then((responceDB)=>{console.log(responceDB);});
           
             const updateCompetition = state.competition.filter((comp) => comp.id != compForAdmin.id);
             state.competition = updateCompetition;
             setSuccessMessage(
                 lang[langSwitch].successfullDeletedTournament
             );
            
             window.location.href = '/myCompetition';
         } catch (error) {
             console.error('Помилка при оновленні IndexedDB:', error);
         }
     }

     updateDataDB();
 })
 .catch((error) => {
     console.error('Помилка запиту до сервера:', error);
 });

   }
////////////////////////ВИДАЛЯЄМО ДАНІ ПІСЛЯ ЗАКІНЧЕННЯ ЗМАГАНЬ////////////////////////////////////////////////////////
function  clearResults(){
  axios 
  .post(state.api.clearCompetition, {
    athletes:null,
    results:null,
    grids:null,
    reglamentFhoto:null,
    secretary:null,
    mainJudge:null,
    reglament:null,
    location:null,
    foodOptions:null,
    foodData:null,
    poomsaeCategories:null,
    id:id,
      headers: {'Content-Type': 'application/json'}
  })
  .then((response) => {  
            if(compForAdmin.reglamentFhoto){
        // Знаходимо індекс останнього входження "/"
            const lastSlashIndex = compForAdmin.reglamentFhoto.lastIndexOf('/'); 
        // Обрізаємо рядок після останнього "/"
            const fileName = compForAdmin.reglamentFhoto.substring(lastSlashIndex + 1); 

              axios.delete(state.api.deleteTournReglamentPDF, {
                data: {                
                  fileName: fileName
                }
              })
              .then(response => { // Обробка успішного видалення  
                          
              })
              .catch(error => {// Обробка помилок            
              });     
   }

      axios.get(state.api.getCompetition)
       .then((response) => {   
          async function updateDB() {
               await indexedDBConnection.saveData ('competition', response.data.competition) 
                state.competition = response.data.competition
                     window.location.href = '/myCompetition'
              
              }      
                updateDB()                     
                        
               })
               .catch((error) => {
                    
              console.error(
                lang[langSwitch].serverRequestError /*Помилка запиту до сервера:*/, error
                );
            });  
            
            setSuccessMessage(
              lang[langSwitch].successfullSavedInDB /*Внесені зміни успішно збережено в Базі Даних*/
            );
  })
  .catch((error) => {
    setErrorMessage(
      lang[langSwitch].serverRequestError /*Помилка запиту до сервера:*/ + error
    );
  });


}
 ////////////////////////////////ПРОГРАМНА ЧАСТИНА КНОПОК ПІДТВЕРДЖЕННЯ////////////////////////////////////////////////////////////
 const [messageText, setMessageText] = useState('');
 const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState(false);

  function editResultConfirm(query) {// ФУНКЦІЯ ЯКА ЗАПУСКАЄ ОПИТУВАННЯ
//    встановлений текст над кнопками є трігером, 
//якщо він повертається з компоненти - спрацьовує функція
if(query==='delete Results'){
      setMessageText(
        lang[langSwitch].deleteResultsAthletesGrids /*Видалити заявлених атлетів, сітки та збережені результати турниру?*/
      )  
      openConfirmButtonsModal()
}
if(query==='update Competition'){
      setMessageText(
        lang[langSwitch].saveChangesInTourn /*Зберегти зміни у вашому турнирі?*/
      )  
      openConfirmButtonsModal()
}
if(query==='delete Competition'){
  setMessageText(
    lang[langSwitch].deletedTournConfirm /*УВАГА ВИДАЛЕННЯ ТУРНИРУ Є НЕЗВОРОТНИМ ПРОЦЕСОМ! Ви погоджуєте дію?*/
    )  
  openConfirmButtonsModal()
}

  }
  const confirmFunction = (messageText) => {
    if (messageText === lang[langSwitch].deleteResultsAthletesGrids /*Видалити заявлених атлетів, сітки та збережені результати турниру?*/) {
      clearResults() //ФУНКЦІЯ ЯКА ВИКОНУЄТЬСЯ В РАЗІ ПІДТВЕРДЖЕННЯ
      }
      if (messageText === lang[langSwitch].saveChangesInTourn /*Зберегти зміни у вашому турнирі?*/

      ) {     
          uploadTournData() //ФУНКЦІЯ ЯКА ВИКОНУЄТЬСЯ В РАЗІ ПІДТВЕРДЖЕННЯ         
      }
 if (messageText === lang[langSwitch].deletedTournConfirm /*УВАГА ВИДАЛЕННЯ ТУРНИРУ Є НЕЗВОРОТНИМ ПРОЦЕСОМ! Ви погоджуєте дію?*/

 ) {
  deleteCompetition() //ФУНКЦІЯ ЯКА ВИКОНУЄТЬСЯ В РАЗІ ПІДТВЕРДЖЕННЯ
      }
  }
  const openConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(true); }
  const closeConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(false); }
///////////////////////////////////////////////////////////////////////////////////////////////////


      return(
        <div className={!isSmallScreen?s.container1:null}>
            {/* Шапка */}
          <div className={s.Item1}>
             <div className={s.titleActive1}>
  <div className={s.editTournTitle}>
  {lang[langSwitch].tournamentEditing /* Редагування турниру*/} 
  {poomse?lang[langSwitch].poomsaeType/*Пхумсе*/
  :
  lang[langSwitch].kyorugiType/*Керугі*/
  }
    </div>
  </div>
   <div className={s.titleActive2}><br/>&nbsp;

              <form>
                <label>
                  <p className={s.lable}>
              {lang[langSwitch].chooseTypeOfTournament /*Оберіть тип турніру*/}
                    </p>
                  <div className={s.switchBox}>
                    <input
                      type='checkbox'
                      checked={poomse}
                      onChange={handleTypeChange}                      
                    />
                  </div>
                </label>
              </form>
   </div>
      <div className={s.titleActive3}>{/*Реєстрацію відкрито */} <br/>&nbsp;

     <form>
       <label>
         <p className={s.lable}>
           {activCompetition == 1 ||activCompetition === true ?
          lang[langSwitch].registrationIsOpen /*Реєстрацію відкрито*/ 
          :
          lang[langSwitch].registrationIsClosed /*Реєстрацію закрито*/
           }
           </p>
         <div className={s.switchBox}>
           <input
             type='checkbox'
             checked={activCompetition == 1||activCompetition === true}
             onChange={handleSwitchChange}                      
           />
         </div>
       </label>
     </form>
</div>
          </div> 
  
                                    {/* Лівий блок */}
          <div className={s.Item2}>   
          <form className={s.form}>            
  
          <p>
            <label htmlFor="title"><span className={s.lable}><b>{
            compForAdmin.title===null?'':compForAdmin.title
            
            }</b></span> </label><br />
            <input onChange={handleTitleChange} 
            placeholder={lang[langSwitch].nameOfTournament /*Назва турніру */}
            type="text" 
            id="title" 
            name="title" />
          </p>
          <p>
            <label htmlFor="location"><span className={s.lable}><b> {compForAdmin.location} </b></span> </label><br />
            <input 
            onChange={handleLocationChange}
            placeholder={lang[langSwitch].addressOfEvent /*Адреса проведення */}
            type="text" 
            id="location"
             name="location" />
          </p>        
  
          <p>
      <label htmlFor="rankComp">
        <span className={s.lable}><b>{rank[compForAdmin.rankComp]}</b>
        <br/>
        {lang[langSwitch].ChooseTournamentClass /*Оберіть клас турніру*/}
        </span>
      </label>
      <br />
      <select onChange={handleRankCompChange}  id="rankComp" name="rankComp">
        <option value="" ></option>
        {state.rankTourn.map((rank) => (
          <option key={rank} value={rank} >
            {rank}
          </option>
        ))}
      </select>
    </p>
  
  
    <p>
            <label htmlFor="dateStart"><span className={s.lable}><b>
                {state.formatDate(compForAdmin.dateStart)}
                {lang[langSwitch].yearShort /*р.*/}
                </b><br />
                {lang[langSwitch].dateStartOfCompetition /*Оберіть дату початку змагань*/}
                </span> </label><br />
            <input onChange={handleDateStartChange} type="date" id="dateStart" name="dateStart" />
          </p>
          <p>
            <label htmlFor="dateFinish"><span className={s.lable}><b> 
                {state.formatDate(compForAdmin.dateFinish)}
                {lang[langSwitch].yearShort /*р.*/}
                </b><br />
               {lang[langSwitch].dateEndOfCompetition /* Оберіть дату закінчення змагань*/}
                </span> </label><br />
            <input onChange={handleDateFinishChange} type="date" id="dateFinish" name="dateFinish" />
          </p>


          <p>
          <label htmlFor="numberOfAthletes"><span className={s.lable}>
          {lang[langSwitch].numberOfAthletesOnCompetition /*Вкажіть кількість учасників*/}
          <br />
          {lang[langSwitch].сurrentQuantity /*Поточна кількість*/}: 
           <b>{compForAdmin.numberOfAthletes}</b></span> </label><br />
          <input onChange={handleNumberOfAthletes} 
        style={{ width: '50px' }}
          id="numberOfAthletes" 
          name="numberOfAthletes" />
        </p>


    
    {/* Приймаємо кількість даянгів */}
   <i> <span className={s.lable}>
   {lang[langSwitch].numberOfDayangs /*Вкажіть кіл-сть даянгів для керугі*/} 
    <br />
   {lang[langSwitch].сurrentQuantity /*Поточна кількість*/}: 
    <b>{compForAdmin.corts}</b></span></i><br />

          <select value={cort?cort:''} onChange={handleSelect} style={{ marginBottom: '10px' }}>
        <option value=""></option>
        {numbers.map((number) => (
          <option key={number} value={number?number:''}>
            {number}
          </option>
        ))}
      </select>

     
{poomse?(<> {/*Параметри Пхумсе*/}
  <p>
                    <label htmlFor="numberOfCategoryes"><span className={s.lable}>
                        {lang[langSwitch].poomsaeLimitForAthlete /*Вкажіть у скількох категоріях може змагатись спорстмен.*/} <br />
                     {lang[langSwitch].сurrentQuantity /*Поточна кількість*/}: <b>{poomsaeOptionsData.poomsaeLimit}</b>

                    </span> </label><br />
                    <select  
                        onChange={handleNumberOfCategoryes}                     
                        style={{ marginBottom: '10px'}}>
                            <option value="">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>                           
                           {numbers.map((item)=>{
                            if(item<11){return (<><option value={item}>{item}</option></>)}
                           })}
                           
                            
                    </select>
                </p>
                <p> 
                    <label htmlFor="numberOfPerfomance"><span className={s.lable}>
                        {lang[langSwitch].NumberOfComplexesInRound /*Вкажіть кількість комплексів в одному турі.*/} <br />
                        {lang[langSwitch].сurrentQuantity /*Поточна кількість*/}: <b>{poomsaeOptionsData.perfomanceNumber}</b>
                    
                    </span> </label><br />
                   
                         <select 
                            onChange={handleNumberOfPerfomance} 
                            style={{ marginBottom: '10px'}}>
                                <option value="">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                        
                        </select>
                </p>
                {/* категорії Пхумсе */}
                <p className={s.lable}>
                    {lang[langSwitch].specifyPoomsaeСategories /*Вкажіть категорії Пхумсе*/}:
                </p>

                <div className={sAdmin.containerAdmin}>

                    <div className={sAdmin.itemPoomse1} >
                        <div >
                            <span className={s.poomsaeLableTitle}><b>
                                {lang[langSwitch].traditional/*Традиційне*/}
                            </b></span>
                            <div className={s.switchBox}>

                                <input
                                    type='checkbox'
                                    checked={traditionalPoomsae}
                                    onChange={handleTraditionalPoomsaeChange}
                                />
                            </div>
                            {traditionalPoomsae ? (<>
                                <form>
                                    <label>
                                        <div className={sAdmin.switchBox}>
                                            <input
                                                type='checkbox'
                                                checked={traditionalPersonalPoomsae}
                                                onChange={handleTraditionalPersonalPoomsaeChange}
                                            /><span className={s.poomsaeLable}>
                                                {lang[langSwitch].personal /*Особисті*/}
                                            </span>
                                        </div>
                                        <div className={sAdmin.switchBox}>
                                            <input
                                                type='checkbox'
                                                checked={traditionalCouplePoomsae}
                                                onChange={handleTraditionalCouplePoomsaeChange}
                                            /><span className={s.poomsaeLable}>
                                                {lang[langSwitch].couples /*Парні*/}
                                            </span>
                                        </div>
                                        <div className={sAdmin.switchBox}>
                                            <input
                                                type='checkbox'
                                                checked={traditionalTeamPoomsae}
                                                onChange={handleTraditionalTeamPoomsaeChange}
                                            /><span className={s.poomsaeLable}>
                                                <b>  {lang[langSwitch].teamPoomsae/*Командні*/} </b>
                                            </span>
                                        </div>
                                    </label>  </form>

                            </>) : null}
                        </div>
                    </div>

                    <div className={sAdmin.itemPoomse2} >
                        <div>
                            <span className={s.poomsaeLableTitle}><b>
                                {lang[langSwitch].freestyle/*Фристайл*/}
                            </b></span>
                            <div className={s.switchBox}>
                                <input
                                    type='checkbox'
                                    checked={freestylePoomsae}
                                    onChange={handleFreestylePoomsaeChange}
                                />
                            </div>
                            {freestylePoomsae ? (<>
                                <form>
                                    <label>
                                        <div className={sAdmin.switchBox}>
                                            <input
                                                type='checkbox'
                                                checked={freestylePersonalPoomsae}
                                                onChange={handleFreestylePersonalPoomsaeChange}
                                            /><span className={s.poomsaeLable}>
                                                {lang[langSwitch].personal /*Особисті*/}
                                            </span>
                                        </div>
                                        <div className={sAdmin.switchBox}>
                                            <input
                                                type='checkbox'
                                                checked={freestyleCouplePoomsae}
                                                onChange={handleFreestyleCouplePoomsaeChange}
                                            /><span className={s.poomsaeLable}>
                                                {lang[langSwitch].couples /*Парні*/}
                                            </span>
                                        </div>
                                        <div className={sAdmin.switchBox}>
                                            <input
                                                type='checkbox'
                                                checked={freestyleTeamPoomsae}
                                                onChange={handleFreestyleTeamPoomsaeChange}
                                            /><span className={s.poomsaeLable}>
                                                <b>  {lang[langSwitch].teamPoomsae/*Командні*/} </b>
                                            </span>
                                        </div>
                                    </label>  </form>

                            </>) : null}
                        </div>
                    </div>

                    <div className={sAdmin.itemPoomse3} >
                        <div>
                            <span className={s.poomsaeLableTitle}><b>
                                {lang[langSwitch].mixedPoomsae/*Змішане*/}
                            </b></span>
                            <div className={s.switchBox}>
                                <input
                                    type='checkbox'
                                    checked={mixPoomsae}
                                    onChange={handleMixPoomsaeChange}
                                />
                            </div>
                            {mixPoomsae ? (<>
                                <form>
                                    <label>
                                        <div className={sAdmin.switchBox}>
                                            <input
                                                type='checkbox'
                                                checked={mixPersonalPoomsae}
                                                onChange={handleMixPersonalPoomsaeChange}
                                            /><span className={s.poomsaeLable}>
                                                {lang[langSwitch].personal /*Особисті*/}
                                            </span>
                                        </div>
                                        <div className={sAdmin.switchBox}>
                                            <input
                                                type='checkbox'
                                                checked={mixCouplePoomsae}
                                                onChange={handleMixCouplePoomsaeChange}
                                            /><span className={s.poomsaeLable}>
                                                {lang[langSwitch].couples /*Парні*/}
                                            </span>
                                        </div>
                                        <div className={sAdmin.switchBox}>
                                            <input
                                                type='checkbox'
                                                checked={mixTeamPoomsae}
                                                onChange={handleMixTeamPoomsaeChange}
                                            /><span className={s.poomsaeLable}>
                                                <b>  {lang[langSwitch].teamPoomsae/*Командні*/} </b>
                                            </span>
                                        </div>
                                        <div className={sAdmin.switchBox}>
                                            <input
                                                type='checkbox'
                                                checked={mixMixedTeamPoomsae}
                                                onChange={handleMixMixedTeamPoomsaeChange}
                                            /><span className={s.poomsaeLable}>
                                                <b>  {lang[langSwitch].mixedTeamePoomsae/*Змішані команди*/} </b>
                                            </span>
                                        </div>
                                    </label>
                                </form>
                            </>) : null}
                        </div>
                    </div>

                </div>
                <div style={{paddingTop:'70px'}}>
                {/* Турнірна система */}
                <p className={s.lable}>
                    {lang[langSwitch].chooseTournamentSystem/*Oберіть турнирну систему*/}: <br/>
                 <b> {poomsaeOptionsData.competitionSystem}
                </b><br />
                    <select value={cort} onChange={competitionSystemSelect} style={{ marginBottom: '10px' }}>
                        <option value=""></option>
                        {state.competitionSystem.map((item) => (
                            <option key={item} value={item}>
                                {item}
                            </option>
                        ))}
                    </select>
                </p>
                </div>
                 {/*Класс змагань з Пхумсе*/}
                 <label>
                    <p className={s.lable}><b>
                        {classB ?
                            lang[langSwitch].classAB/*Класи "A" та "Б"*/
                            :
                            lang[langSwitch].classA/*Клас "A"*/}
                    </b> </p>
                    <div className={s.switchBox}>
                        <input
                            type='checkbox'
                            checked={classB}
                            onChange={handleClassChange}

                        />
                    </div>
                </label>
</>)
:
(<>{/*Параметри Керугі*/}

    <div>    {/*вибір вікової категорії */}
      <i> <p> <span className={s.lable}>
       {lang[langSwitch].ageCategoriesOfTournament /* Вкажіть вікові категорії турниру*/}
        <br />
       <b>
        {Array.isArray(compForAdmin.ageCategories) && compForAdmin.ageCategories.length > 0 ? 
    langSwitch==='ukr'?state.ageCatUkr(compForAdmin.ageCategories)
    :   
      compForAdmin.ageCategories.map((item,i)=>{ console.log()
       if(i<compForAdmin.ageCategories.length-1) 
          {return(item+', ')}
      else{return(item+'.')}
      })
    : 
    ''}
</b> </span></p>
        
        <label>
          <input
            type="checkbox"
            value="jrChildren"
            checked={ageCategories.includes("jrChildren")}
            onChange={() => handleCheckboxChange("jrChildren")}
          />
          <span className={s.lable}>&nbsp;&nbsp;
            <i>
            { lang[langSwitch].jrChildren/* Малюки*/}
            </i></span>
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            value="children"
            checked={ageCategories.includes("children")}
            onChange={() => handleCheckboxChange("children")}
          />
          <span className={s.lable}>&nbsp;&nbsp;
            <i>
            { lang[langSwitch].children/* Діти*/}
            </i></span>
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            value="jrYounkers"
            checked={ageCategories.includes("jrYounkers")}
            onChange={() => handleCheckboxChange("jrYounkers")}
          />
          <span className={s.lable}>&nbsp;&nbsp;
            <i>
            { lang[langSwitch].jrYounkers/* Молодші юнаки*/}
            </i></span>
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            value="younkers"
            checked={ageCategories.includes("younkers")}
            onChange={() => handleCheckboxChange("younkers")}
          />
          <span className={s.lable}>&nbsp;&nbsp;
            <i>
            { lang[langSwitch].younkers/* Юнаки*/}
              </i>
              </span>
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            value="cadets"
            checked={ageCategories.includes("cadets")}
            onChange={() => handleCheckboxChange("cadets")}
          />
          <span className={s.lable}>&nbsp;&nbsp;
            <i>
            { lang[langSwitch].cadets/* Кадети*/}
              </i>
              </span>
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            value="juniors"
            checked={ageCategories.includes("juniors")}
            onChange={() => handleCheckboxChange("juniors")}
          />
          <span className={s.lable}>&nbsp;&nbsp;
            <i>
            { lang[langSwitch].juniors/*Юніори*/}
              </i>
              </span>
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            value="adults"
            checked={ageCategories.includes("adults")}
            onChange={() => handleCheckboxChange("adults")}
          />
          <span className={s.lable}>&nbsp;&nbsp;
            <i>
            { lang[langSwitch].adults/*Дорослі*/}
              </i>
              </span>
        </label> 
        <br />
        <label>
          <input
            type="checkbox"
            value="veterans"
            checked={ageCategories.includes("veterans")}
            onChange={() => handleCheckboxChange("veterans")}
          />
          <span className={s.lable}>&nbsp;&nbsp;
            <i>
            { lang[langSwitch].veterans/*Ветерани*/}
              </i>
              </span>
        </label>
        <br /> <br />
        </i>
        </div>
</>)}


</form>
  </div>
  
  {/* Правий блок */}
  <div className={s.Item3}>
  <form className={s.form}>

{/* Вибір Секретаря  */}
<div> <br />
        <label htmlFor="secretary">
          <span className={s.lable}>
          {lang[langSwitch].secretary /*Секретар*/}: 
            <b> {state.myCoach(compForAdmin.secretary)}</b></span>
        </label>
        <br />
        <Select className={s.secretary}
          id="secretary"
          name="secretary"
          value={selectedSecretary}
          onChange={handleSelectChange}
          options={options}
          isClearable={true}
          placeholder={lang[langSwitch].chooseSecretary /*Оберіть секретаря*/}
        />
      </div><br />

      
{/* Вибір Головного судді  */}
  <div>
      <label htmlFor="mainJudge">
        <span className={s.lable}>
         {lang[langSwitch].chiefJudge /* Головний суддя*/}: 
          <b> {state.myCoach(compForAdmin.mainJudge)}</b></span>
      </label>
      <br />
      <Select className={s.secretary}
        id="mainJudge"
        name="mainJudge"
        value={selectedMainJudge}
        onChange={handleSelectMainJudge}
        options={options}
        isClearable={true}
        placeholder={lang[langSwitch].chooseChiefJudge /*Оберіть головного суддю*/}
      />
    </div><br />

   {/* Логотип*/}
  <div>
            <label htmlFor="photo"><span className={s.lable}>
            {lang[langSwitch].setCompetitionLogo /* Встановіть логотип змаганнь.*/}
              <br />
           {lang[langSwitch].sizeForCompetitionLogo /*   Для коректного виводу зображення співвідношення сторін у файлі має бути 4/3*/}
           </span></label>
            <div>
              {compressedFileURL && (
                <img src={compressedFileURL} alt="Стигнутий файл" />
              )}<br />
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
              /><br />
            </div>
          </div> 
          <br />
             {/* Регламент*/}
          <div>
          <label htmlFor="pdf"><span className={s.lable}>
           {lang[langSwitch].downloadRegulationsWithStamps /* Завантажте регламент з печатками (PDF)*/} 
            </span></label><br />
           
            <input type="file" accept=".pdf" onChange={handlePDFChange} />
          
          </div>

     
 {/* Харчування*/}
    <br/>
                <label>
                  <p className={s.lable}>
                    {isFood?
                    lang[langSwitch]. specifyParametersForCatering/*Вкажіть параметри для організації харчування */
                    : 
                   lang[langSwitch]. foodNotOrganized/*  Харчування не організовується*/
                   } 
                   </p>
                  <div className={s.switchBox}>
                    <input 
                      type='checkbox'
                      checked={isFood}
                      onChange={handleFoodChange}
                      
                    />
                  </div>
                </label>
                <br/>
                   {isFood?(<>

                    <div className={sAdmin.containerAdmin}>
   

        <div className={sAdmin.itemAdmin1}>
        <p className={s.lable}><b>
          {lang[langSwitch]. priceOfBreakfast/*  Ціна сніданку*/}
          </b> </p>
    <input 
    style={{width:isSmallScreen?'80px':'120px'}} 
    type='number' 
    onChange={handleBreakfastPrise}
    placeholder={prise&&prise.breakfast?prise.breakfast:
      lang[langSwitch].notSpecified /* Не вказано*/
    }
    /> 
        </div>
        <div className={sAdmin.itemAdmin2}>
        <p className={s.lable}><b>
          {lang[langSwitch]. priceOfLunch/*Ціна обіду*/}
          </b></p>
        <input 
        style={{width:isSmallScreen?'80px':'120px'}}
        type='number' 
        onChange={handleDinnerPrise}
        placeholder={prise&&prise.dinner?prise.dinner:
          lang[langSwitch].notSpecified /* Не вказано*/
        }
        />
        </div>
        <div className={sAdmin.itemAdmin3}>
        <p className={s.lable}><b> 
        {lang[langSwitch]. dinnerPrice/*Ціна вечері*/}  
          </b></p>
        <input 
        style={{width:isSmallScreen?'80px':'120px'}}
        type='number' 
        onChange={handleSupperPrise}
        placeholder={prise&&prise.supper?prise.supper:
          lang[langSwitch].notSpecified /* Не вказано*/
        }
        />
        </div>
        
    </div>

    {foodOptionsData ? 
    foodOptionsData.times.map((item, index) => (
        <MakeFoodOptionsCell 
            date={item.date} 
            key={index} 
            times={setTimes} 
            options={item} 
            updateFoodResult={updateFoodResult}
            langSwitch={langSwitch}
        />
    ))
    :
    formattedDates.map((item, index) => (
        <MakeFoodOptionsCell 
        langSwitch={langSwitch}
            date={item} 
            key={index} 
            setResult={setResult} 
            options={null} 
            updateFoodResult={updateFoodResult}
        />
    ))
}             
                   </>)
                   :null}
        
  </form>
  </div>
  
  
  {/* футер */}
      <div className={s.Item4}>
  
  <form className={s.form}>
    <p className={s.lable}>
    {lang[langSwitch].enterTextOfRegulation /* Введіть текст регламенту*/}:
      </p>
    
      {successMessage?
          (<><br/><br/><div className={s.successMessage} onClick={()=>{setSuccessMessage('')}}>
            
            {successMessage}</div><br/></>) :
          errorMessage?(<><br/><br/><div className={s.errorMessage} onClick={()=>{setErrorMessage('')}}>
            {errorMessage}</div><br/></>):''}
  
         <TextEditor 
            text={compForAdmin.reglament} 
            isSmallScreen={isSmallScreen}
            langSwitch={langSwitch}
            setResult={setReglament}
            textInformation={lang[langSwitch].informationInField /*<p>Після вводу тексту натисніть кнопку <b>'Додати Регламент до решти даних'</b>, після незабудьте натиснути <b>'Внести до БД'</b> щоб зберегти результати!</p>*/}
            textButton={lang[langSwitch].addRegulationtoRestData /*Додати Регламент до решти даних*/}
            textInstruction={lang[langSwitch].clickButtonToEditingTournament /*<h1>Тепер натисніть кнопку "Внести до БД" для завершення редагування Турніру!</h1>*/}
          />

         <br/><br/>
         <div style={{textAlign:isSmallScreen?'center':'right'}}> 
     <button type='button' 
    className={s.buttonForSmall}
     onClick={()=>{
      foodOptionsUpdate()
      editResultConfirm('update Competition')
      }}  >
        {lang[langSwitch].save /*Зберегти*/}
     </button></div>
         <div>
     
     <br/><br/><br/><br/><br/>
     <div style={{textAlign:isSmallScreen?'center':'right'}}>
     <button type='button'
     className={s.exitButton} 
     style={{padding:'3px'}}
     onClick={()=>{editResultConfirm('delete Results')}}  >
        {lang[langSwitch].deleteResults /*Видалити результати*/}
     </button>
   </div><br/><br/><br/><br/><br/><br/>
  <div style={{textAlign:isSmallScreen?'center':'right'}}>
     <button type='button'className={s.exitButton} onClick={()=>{editResultConfirm('delete Competition')}} >
     {lang[langSwitch].deleteTournament /*  Видалити турнир*/}
     </button><br/><br/>&nbsp;
   </div></div>
     
          </form>
  
         
  
        </div>
        <ConfirmButtons
            isOpen={isConfirmButtonsModalOpen}
            onClose={closeConfirmButtonsModal}
            confirmFunction={confirmFunction}
            messageText={messageText}
            langSwitch={langSwitch}
        />
          </div>
  
      )
  }
export default EditCompetition