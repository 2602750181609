import React from 'react';
import state from '../../state/state.js'
import s from './navBar.module.css'
import { NavLink } from 'react-router-dom';
import { useMailContext } from '../content/mail/MailContext.js';

function Avatar({ navBar }) {
    const { unreadMailCount } = useMailContext();

    return (
        <div className={navBar ? s.avatarForNavBar : s.avatar} >

            {navBar ? (<>
                <NavLink to="/mail">
                <div>
                    <img
                        className={navBar ? s.avatarImgForNavBar : s.avatarImg}
                        src={state.userProfile.photo ? state.userProfile.photo : state.noAvatarPhoto} />

                    <img className={s.mailImgForNavBar}
                        alt='фото'
                        src={unreadMailCount > 0 ? '/img/main/myMailRed.png' : '/img/main/myMail1.png'} />
                </div>
                </NavLink>
            </>) : (<>
                <img
                    className={navBar ? s.avatarImgForNavBar : s.avatarImg}
                    src={state.userProfile.photo ? state.userProfile.photo : state.noAvatarPhoto} />
                <NavLink to="/mail">
                <img className={s.mailImg}
                    alt='фото'
                    onClick={() => {
                         setMenu(false) 
                    }}
                    src={'/img/main/myMail1.png'} />
                </NavLink>
                <div className={s.unreadMessages}>
                    {unreadMailCount === 0 ? '' : unreadMailCount}
                </div>
            </>)}
        </div>
    );
};

export default Avatar;