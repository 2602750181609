import s from './food.module.css'
import FooterButtons from './footerButtons'
import state from './../../../../state/state';
import lang from './../../../../state/language';

function FoodDistribution({isSmallScreen, title,isOpen,foodData,foodOptions,langSwitch}){
const dates= foodOptions.times.map((item)=>(item.date))

const dataForTable=[]


dates.map((item)=>{
    const result={date:item,user:[]}
    foodData.map((item2)=>{
        item2.data.map((item3)=>{
if(item3.date===result.date){
    const result2={
        id:item2.id,
        phone:item2.phone,
        breakfast:item3.breakfast?item3.breakfast:null,
        dinner:item3.dinner?item3.dinner:null,
        supper:item3.supper?item3.supper:null
    } 
    
    result.user.push(result2)
}
        })
        
    })
    dataForTable.push(result)
})


    return(<>
       
<div className={s.printContent}>
<div className={s.printHeader}>
{lang[langSwitch].distributionOrder /*Роздача замовлення*/}       
 <div className={s.printTitle}>{title} </div>
</div>

<table className={s.foodPrintTable} style={{textAlign:'center'}}>
<thead>
    <tr>
        <td rowSpan={2}>№</td>
        <td rowSpan={2}>{lang[langSwitch].customer /*Замовник*/} </td>
        <td rowSpan={2}> {lang[langSwitch].telShort /*тел.*/}</td>
        <td colSpan={2}>{lang[langSwitch].breakfast /*Сніданок*/}</td>
        <td colSpan={2}>{lang[langSwitch].lunch /*Обід*/}</td>
        <td colSpan={2}>{lang[langSwitch].dinner /*Вечеря*/}</td>
        <td rowSpan={2}>{lang[langSwitch].totalCost /*Загальна вартість*/}</td>
        
    </tr>
    <tr>
        <td>{lang[langSwitch].portions /*Порції*/}</td>
        <td>{lang[langSwitch].cost /*Вартість*/}</td>
        <td>{lang[langSwitch].portions /*Порції*/}</td>
        <td>{lang[langSwitch].cost /*Вартість*/}</td>
        <td>{lang[langSwitch].portions /*Порції*/}</td>
        <td>{lang[langSwitch].cost /*Вартість*/}</td>
    </tr>
</thead>
{dataForTable.map((item)=>{
    return(<>
    <tbody>
<tr>
    <td colSpan={10}><b>{item.date}</b></td>
</tr>
{item.user.map((item,index)=>{
    return(<>
    <tr style={{ backgroundColor: index % 2 === 0 ? '#ebebeb' : null }}>
    <td><b>{index+1}</b></td>
    <td style={{textAlign:'left'}}>{state.myCoach(item.id)}</td>
    <td>{item.phone}</td>
    <td><b>{item.breakfast?item.breakfast+' шт.':null} </b></td>
    <td>{item.breakfast?item.breakfast*foodOptions.prise.breakfast +' грн.':null}</td>
    <td><b>{item.dinner?item.dinner+' шт.':null} </b></td>
    <td>{item.dinner?item.dinner*foodOptions.prise.dinner+' грн.':null}</td>
   <td><b>{item.supper?item.supper+' шт.':null} </b></td>
    <td>{item.supper?item.supper*foodOptions.prise.supper+' грн.':null}</td>
    <td>{(item.breakfast*foodOptions.prise.breakfast)+
         (item.dinner*foodOptions.prise.dinner)+
         (item.supper*foodOptions.prise.supper)+' грн.'
    }</td>
</tr>
    </>)
})}

    </tbody>
    </>)
})}

</table>

</div>
    <FooterButtons 
        isOpen={isOpen} 
        isSmallScreen={isSmallScreen}
        langSwitch={langSwitch}/>
    </>)
}

export default FoodDistribution