import s from './adminCompetition.module.css'
import lang from "../../../state/language";

function ConfirmButtons ({isOpen, onClose, confirmFunction,messageText, langSwitch }){
    // const createMarkup = (html) => {return{ __html: html };};


return( <>

  {isOpen?(<>
  <div className={s.modalWriper}>
    <div className={s.confirmButtonsModal}>
    <div>{messageText}</div>
    <button type='button' onClick={() => { onClose(); confirmFunction(messageText); }}>
    {lang[langSwitch].yes}{/*Так*/}
      </button>
    <button type='button' onClick={onClose}>
    {lang[langSwitch].no}{/* Ні*/}     
      </button>

    
    
    


    {/* <div dangerouslySetInnerHTML={createMarkup(regulationText)} /> */}
</div></div>
</>):null}
</> )
   
}

export default ConfirmButtons