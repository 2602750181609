import { useState } from 'react'
import state from '../../../state/state'
import s from '../adminCompetition/adminCompetition.module.css'
import lang from './../../../state/language';




function CommentItem({ item, index, dataForRender, axiosPost,isSmallScreen,langSwitch }) {
    

    const [textForEdit, setTextForEdit] = useState(null)
    const [indexPost, setIndexPost] = useState(null)
    
    function textHandle(e) { setTextForEdit(e.target.value) }

    function deletePost(text) {
        const dataForDelete = dataForRender.filter((post) => post.text !== text)
        axiosPost(dataForDelete)
    }

    function editPost(text) {
        const dataForUpdete = [...dataForRender]
        dataForUpdete[indexPost].text = text
        axiosPost(dataForUpdete)
        stopEdit()
    }

    function stopEdit() {
        setTextForEdit(null)
        setIndexPost(null)
    }

    function startEdit(text, index) {
        setTextForEdit(text)
        setIndexPost(index)
    }


    return (<>

        <div className={s.commentWriper} key={item.text}>
            <div>
                {isSmallScreen?(<>
                <table style={{width:'91%'}}>
                    <tr>
                        <td >
                          <img src={item.photo ? item.photo : state.noAvatarPhoto}
                    className={s.commentLogo} />  
                        </td>
                        <td  style={{textAlign:'left'}}>
                        <span className={s.commentName}>{item.name.props.children}</span>
                        <br/>
                               
                       <span className={s.commentDate}>{item.date}</span>
                        <span className={s.commentTime}>{item.time}</span>
                    
                        </td>
                        <td style={{textAlign:'right'}}>

                        {item.id == state.userProfile.id ? (<>
                    <img src={state.editHand}
                        className={s.editHand}
                        onClick={() => { textForEdit || indexPost ? stopEdit() : startEdit(item.text, index) }}
                    />
                    <img src={state.basketForComment}
                        className={s.basketForComment}
                        onClick={() => { deletePost(item.text) }}
                    />

                </>) : null}
                        </td>
                    </tr>
                </table>
                </>):(<>

                <img src={item.photo ? item.photo : state.noAvatarPhoto}
                    className={s.commentLogo} />
                <span className={s.commentName}> {item.name.props.children}</span>
                <span className={s.commentDate}>{item.date}</span>
                <span className={s.commentTime}>{item.time}</span>            

                {item.id == state.userProfile.id ? (<>
                    <img src={state.editHand}
                        className={s.editHand}
                        onClick={() => { textForEdit || indexPost ? stopEdit() : startEdit(item.text, index) }}
                    />
                    <img src={state.basketForComment}
                        className={s.basketForComment}
                        onClick={() => { deletePost(item.text) }}
                    />

                </>) : null}  
                
                 </>)}
                <div className={s.commentLine}> &nbsp;  </div>
            </div>
            <div className={s.commentTextDiv}>

                {indexPost!==null ? (<>

                    <textarea
                        className={s.commentInput}
                        style={{ width: '72%' }}
                        value={textForEdit}
                        placeholder={lang[langSwitch].enterAdditionalInformation /*Внесіть додаткову інформацію*/}
                        rows="3" // Вказуємо початкову кількість рядків
                        onKeyDown={(e) => {
                            if (e.key === "Enter" && e.shiftKey) {
                                e.preventDefault(); // Забороняємо дійсне додавання нового рядка
                                e.target.rows += 1; //  Збільшуємо кількість рядків на одиницю
                            }
                        }}
                        onInput={(e) => {
                            e.target.style.height = "auto"; // Автоматично розтягуємо текстове поле вгору при введенні тексту
                            e.target.style.height = e.target.scrollHeight + "px"; // Встановлюємо висоту, яка дорівнює висоті контенту
                        }}
                        onChange={textHandle}
                    />
                    <button className={s.commentButton} onClick={() => { editPost(textForEdit) }}>
                    {lang[langSwitch].edit /*Редагувати*/}
                    </button>

                </>) : (<>
                    <span > {item.text}</span>
                </>)}

            </div>
        </div>


    </>)
}

export default CommentItem