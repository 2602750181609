import React, { useEffect, useState } from 'react';
import s from '../menu/menu.module.css'
import sAdmin from '../../adminCompetition/adminCompetition.module.css'
import sApp from '../../../../App.module.css'
import lang from '../../../../state/language';
import state from '../../../../state/state';
import ConfirmButtons from '../../adminCompetition/confirmButtons';
import axios from 'axios';
import indexedDBConnection from '../../../../state/indexedDBConnection';




function AddVideoGalery({ langSwitch, isSmallScreen }) {


  const [title, setTitle] = useState(null);
 const [type, setType] = useState(null);
 const [videoTitle, setVideoTitle] = useState('');
  const [frameCode, setFrameCode] = useState('');
    const [	links, setLinks] = useState([]);
  const [messageText, setMessageText] = useState('');
  const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [uploading, setUploading] = useState(false);



  const openConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(true); }
  const closeConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(false); }

   function saveNewsConfirm() {  
      if(title&&links.length>0&&type){
              setMessageText(lang[langSwitch].createNewGalery+'?' /*Створити нову галерею?*/)
                openConfirmButtonsModal()  
      }else{setErrorMessage(
        lang[langSwitch].createVideoGaleryInstructions /*Для створення галереї потрібно вказати тип, назву, та URL відео.*/ 
    )}   
   }    

  const confirmFunction = (messageText) => {
    if (messageText === lang[langSwitch].createNewGalery+'?' /*Створити нову галерею?*/
      ) {
    //ВИКЛИК ФУНКЦІЇ СТВОРЕННЯ СТАТТІ
    uploadVideo()

    }else{
        // console.log('Помилка ')
    }

  }

  function handleTitle(e) { setTitle(e.target.value) }
  function handleVideoTitleChange(e) { setVideoTitle(e.target.value) }
  function handleFrameCodeChange(e) {
    const match = e.target.value.match(/src="([^"]+)"/);
    if (match) {
      setFrameCode(match[1]); // Беремо другий елемент масиву
    }
  }
   
  function handleGalleryTypeChange(e) { setType(e.target.value) }

  function addVideoToArray(){
    if(videoTitle&&frameCode){
      setLinks((prev)=>{
        return[...prev,{
          videoURL:frameCode,
          videoTitle:videoTitle
        }]
      })
      setVideoTitle('')
      setFrameCode('')
    }else{
      setErrorMessage(
        lang[langSwitch].addVideoToGalleryInstruction /*Щоб додати відео до галереї потрібно вказати його назву, та вставити посилання з YouTube (для цього на сайті YouTube під відео написніть "Поделитися"  / "Вставити" після чого скопіюйте  код та вставте у відповідне поле). */ 
    ) 
    }
  }

  //завантаження на фото сервер

      function uploadVideo  ()  {
        setUploading(true)

axios.post(state.api.addVideoGallery, {
  type:type,
  title:title,
  links:JSON.stringify(links),
  typeContent:'video' ,
    headers: {'Content-Type': 'application/json'}
})
.then((response) => {
  if(response.data.mediaContent){
state.mediaContent=[...response.data.mediaContent]
indexedDBConnection.saveData('mediaContent', state.mediaContent)
window.location.reload(true);
  } 
  console.log('response.data: ',response.data)
            
})
.catch((error) => {setUploading(false)
  setErrorMessage(lang[langSwitch].serverRequestError //Помилка запиту до сервера:
     + error);
  console.log('error: ',error)
});


        };
  


      
  return (<div>
    <div className={s.h1}>
      {lang[langSwitch].createNewGalery /*Створити нову галерею*/}
    </div>

    <div>
      <div className={s.h3}>  
        {lang[langSwitch].galeryTitle   /*Назва галереї*/}
      </div>


      <input
        type='text'
        className={s.inputNews}
        placeholder={title}
        onChange={(e) => { handleTitle(e) }}
      />
    </div>

    <div>
      <div className={s.h3}>
        {lang[langSwitch].typeOfGallery   /*Тип галереї*/}
      </div>
      <select onChange={handleGalleryTypeChange}  >
      <option value=""></option>
      {state.galleryType.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ))}
    </select>
       </div>

    
    <div className={s.videoWriper}>
      <div className={s.videoInnerWriper}>
   
      <br/>
      <div className={s.h3}>
        {lang[langSwitch].video   /*Відео*/}
      </div>

      {errorMessage ? (<><br /><br /><div className={sAdmin.errorMessage} onClick={() => { setErrorMessage('') }}>
      {errorMessage}</div><br /></>) : ''}

      {(links && Array.isArray(links) && links.length>0) && (
        <div>
          {links.map((item, index)=>{
            return( 
              <div className={s.videoPrewBox} key={item.videoURL+index}>
              <div className={s.videoPrewPlayer}>
             {state.youtubePlayer(item.videoURL, isSmallScreen)}
             </div> 
             <div className={s.videoPrewTitle}>
             {item.videoTitle}
              </div> 
              </div> 
            )
          })}
        </div>
      )}

 <div className={s.newsDate}>
 { lang[langSwitch].videoTitle /*Назва відео*/ }
      </div>
      
      <input
        type='text'
        className={s.inputNews}
        value={videoTitle}
        onChange={(e) => { handleVideoTitleChange(e) }}
      />

 <div className={s.newsDate}>
 { lang[langSwitch].frameCode /*Код фрейму*/ }
      </div>    
<input
        type='text'
        className={s.inputNews}
      value={frameCode}
        onChange={(e) => { handleFrameCodeChange(e) }}
      />

      <button 
        type='button'
        onClick={()=>{addVideoToArray()}}>
 { lang[langSwitch].addVideo /*Додати відео*/ }
       </button>   <br/>&nbsp;
    </div> 
    </div>
   
<button onClick={()=>{saveNewsConfirm()}}>
{lang[langSwitch].createNewGalery /*Створити нову галерею*/}
</button>
  

 <ConfirmButtons
        isOpen={isConfirmButtonsModalOpen}
        onClose={closeConfirmButtonsModal}
        confirmFunction={confirmFunction}
        messageText={messageText}
        langSwitch={langSwitch}
      />
      {  uploading&&(
        <div className={sAdmin.modalWriper}>
            <div className={sApp.flag}>
            { lang[langSwitch].pleaseWaitDataLoading /*Зачекайте, дані завантажуються...*/ }<br /><br />
                <img alt='фото' src='/img/main/ukrainian-waving-flag-30.gif' />
               </div>
          </div>
      )}

  </div>);
};

export default AddVideoGalery;