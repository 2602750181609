import state from '../../../state/state';
import s from '../adminCompetition/adminCompetition.module.css';



function AthleteItem (props){


    return(<>
    
    <div className={s.gridContainer}>
        {props.athletes.map((athlete) => (
          <div key={athlete.id} className={props.selectedAthletes.includes(athlete.id) ? `${s.athleteBlock} ${s.athleteBlock1}` : s.athleteBlock}>
            <img
              src={athlete.photo?athlete.photo:state.noAvatarPhoto}
              alt={`${athlete.secondName} ${athlete.personName}`}
              style={{ cursor: 'pointer' }}
              onClick={() => props.toggleAthleteSelection(athlete)}
            />
            <div className={s.name}><b>{`${athlete.secondName} ${athlete.personName}`}</b></div>
          </div>
        ))}
      </div>
    
    
    </>)
}

export default AthleteItem