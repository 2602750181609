import { useEffect, useState } from 'react'
import lang from '../../../../state/language'
import state from '../../../../state/state'
import s from './adminPoomsae.module.css'
import AthletesTable from './AthletesTable'
import sAdmin from '../adminCompetition.module.css'
import PoomsaeGridFooter from './poomsaeGridFooter'
import AthletesTableForCD from './AthletesTableForCD'


function BlockGrid({cortSecretary, setSaveSwitcher, judgeData, langSwitch, competition, grid, controlDesk,setGridForRestore,setPoomsaeGrids,indexDbData}) {

    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [winnerAthletes, setWinnerAthletes] = useState(null);
    const [winner, setWinner] = useState(null);


    const athletesList = []
    if(controlDesk===1||controlDesk===2){
 grid.athletes.forEach((item) => {
    athletesList.push(item)
 })
    }else{
    grid.athletes.forEach((item, index) => {
      
        if (Array.isArray(item)) {
            athletesList.push({
                gridId: grid.category+index,                
                finalResults: { technique: null, presentation: null, allScore: null },
                semiFinalResult: { technique: null, presentation: null, allScore: null },
                preliminaryResult: { technique: null, presentation: null, allScore: null },
                finalResults_2: { technique: null, presentation: null, allScore: null },
                semiFinalResult_2: { technique: null, presentation: null, allScore: null },
                preliminaryResult_2: { technique: null, presentation: null, allScore: null },
                preliminaryTotal:null,
                 semiFinalTotal:null,
                 finalTotal:null,
                 fullScore:null,
                 judgeScore:{},
                place: null,
                teamPoomsae: item,
                team: item[0].team
            })

        } else {
            athletesList.push({
                ...item,
                gridId: grid.category + item.id,               
                finalResults: { technique: null, presentation: null, allScore: null },
                semiFinalResult: { technique: null, presentation: null, allScore: null },
                preliminaryResult: { technique: null, presentation: null, allScore: null },
                finalResults_2: { technique: null, presentation: null, allScore: null },
                semiFinalResult_2: { technique: null, presentation: null, allScore: null },
                preliminaryResult_2: { technique: null, presentation: null, allScore: null },
                preliminaryTotal:null,
                semiFinalTotal:null,
                finalTotal:null,
                fullScore:null,
                place: null,
                judgeScore:{},
            })
        }

    })
}

    return (<>
        <div className={s.mainDiv} key={grid.category}>
            <div  className={s.gridHeader}>
            <div className={s.title}>{competition.title}</div>
                <div className={s.date}>
                    {competition.dateStart && competition.dateFinish ? (<>
                        {state.formatDate(competition.dateStart).slice(0, 2)}-
                           {state.formatDate(competition.dateFinish)} {lang[langSwitch].yearShort/*p.*/}
                    </>) : (<>
                          {competition.dateOfCompetition}{lang[langSwitch].yearShort/*p.*/}
                    </>)}
                </div>
                <div className={s.category}>
                    {lang.poomsaeCatUkr(langSwitch, grid.category)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {lang[langSwitch].court/*Корт*/}: {grid.cort} </div>
            </div>

            <table className={s.gridTable}>
              <thead className={s.header}>
                    <tr >
                        {athletesList.length > 19 ? (<><td style={{ width: '33%' }}>
                            {lang[langSwitch].qualifyingRound/*Відбірковий Тур*/}
                        </td></>) : null}
                        {athletesList.length > 8 ? (<><td style={{ width: athletesList.length > 19 ? '33%' : '50%' }}>
                            {lang[langSwitch].semifinal/*Півфінал*/}
                        </td></>) : null}
                        <td>
                            {lang[langSwitch].final/*Фінал*/}
                        </td>
                    </tr>
             </thead>

                {controlDesk===1? 
                <AthletesTableForCD
                    setWinnerAthletes={setWinnerAthletes}                
                    setWinner={setWinner}
                    athletesListData={athletesList}
                    grid={grid}
                    setErrorMessage={setErrorMessage}                  
                    langSwitch={langSwitch}
                    competition={competition} 
                    setGridForRestore={setGridForRestore} 
                    setPoomsaeGrids={setPoomsaeGrids} 
                    indexDbData={indexDbData} 
                    setSaveSwitcher={setSaveSwitcher} 
                    judgeData={judgeData}
                    cortSecretary={cortSecretary}
                    />
                :
                    <AthletesTable
                    setWinnerAthletes={setWinnerAthletes}                
                    setWinner={setWinner}
                    athletesListData={athletesList}
                    grid={grid}
                    setErrorMessage={setErrorMessage}                  
                    langSwitch={langSwitch}
                    competition={competition}
                    // setPoomsaeGrids={setPoomsaeGrids}
                     />}
            </table>
            <PoomsaeGridFooter competition={competition} winnerAthletes={winnerAthletes} winner={winner} />


        </div>
        {errorMessage ? (<><br /><div className={sAdmin.errorMessage} onClick={() => { setErrorMessage(null) }}
            style={{ cursor: 'pointer' }}>
            {errorMessage}
        </div>
        </>) : null}
        {successMessage ? (<><br /><div className={sAdmin.successMessage} onClick={() => { setSuccessMessage(null) }}
            style={{ cursor: 'pointer' }}>
            {successMessage}
        </div>
        </>) : null}
    </>)
}

export default BlockGrid