import React from 'react';
import s from '../result.module.css';
import lang from '../../../../state/language';

function CardsForHall({competition,setSwitcher,langSwitch}) {


function cortsNumbers(num) {
   let elements = [];

   for (let i = 1; i <= num; i++) {
       elements.push(
           <div className={s.mainDivForPrint} key={i}>
               <table>
                   <tbody>
                       <tr>
                           <td>
                               <div className={s.numCorts}>{i}</div>
                           </td>
                       </tr>
                   </tbody>
               </table>
           </div>
       );
   }

   return elements;
}

    return (<>
   <div className={s.resultTitle}>
    <div className={s.h3}>
    {lang[langSwitch].tablesForTheHall /*Таблички для зали */}
        </div>
   </div>

   
         {cortsNumbers(Number(competition.corts))}
        

       
<div className={s.mainDivForPrint}> <table ><tbody><tr><td>           
            <div className={s.infoForHall}>
              {lang[langSwitch].chiefJudgeShort /*Гол.Суддя */}  
                </div>
         </td></tr></tbody></table></div>
<div className={s.mainDivForPrint}> <table ><tbody><tr><td>           
            <div className={s.inspectionTest}>
               {lang[langSwitch].inspectionTest /*Суддівській контроль */} 
                </div>
         </td></tr></tbody></table></div>         
<div className={s.mainDivForPrint}> <table ><tbody><tr><td> 
     <div className={s.doctor}>
<img style={{width:'240px'}}
 src={'/img/main/png-transparent-cruz-red-red-cross-symbol-thumbnail.png'} />
              <br/>
              {lang[langSwitch].doctor /*Лікар*/}
              </div>
         </td></tr></tbody></table></div>

<div className={s.mainDivForPrint}> <table ><tbody><tr><td> 
     <div className={s.emergency}>
<img  style={{width:'240px'}}
src={'/img/main/png-transparent-cruz-red-red-cross-symbol-thumbnail.png'} />
              <br/>
              {lang[langSwitch].ambulance /*Швидка допомога*/}</div>
         </td></tr></tbody></table></div>


        <div className={s.resultTitle}>
        <div className={s.containerBadgeTop}>
<div className={s.itemResultsFooter1}>
<button type='button'  onClick={()=>{window.print()}}>
{lang[langSwitch].print /*Друкувати*/}
    </button>
</div>

<div className={s.itemResultsFooter2}>

    <button  type='button' onClick={()=>{setSwitcher(0)}}>
    {lang[langSwitch].close /*Закрити*/}
    </button>
        
</div></div>
</div>

        
   </> );
}

export default CardsForHall;
