import state from "../../../state/state.js"

import s from '../adminCompetition/adminCompetition.module.css';
import { useEffect, useState } from "react";
import MyResultOnCompetitiion from "../userProfile/myResultOnCompetitiion.js";

import GridForAthlets2 from "../adminCompetition/gridsForAthlets/GridForAthlets2";
import GridForAthlets3 from "../adminCompetition/gridsForAthlets/GridForAthlets3";
import GridForAthlets4 from "../adminCompetition/gridsForAthlets/GridForAthlets4";
import GridForAthlets5 from "../adminCompetition/gridsForAthlets/GridForAthlets5";
import GridForAthlets6 from "../adminCompetition/gridsForAthlets/GridForAthlets6";
import GridForAthlets7 from "../adminCompetition/gridsForAthlets/GridForAthlets7";
import GridForAthlets8 from "../adminCompetition/gridsForAthlets/GridForAthlets8";
import GridForAthlets9 from "../adminCompetition/gridsForAthlets/GridForAthlets9";
import GridForAthlets10 from "../adminCompetition/gridsForAthlets/GridForAthlets10";
import GridForAthlets11 from "../adminCompetition/gridsForAthlets/GridForAthlets11";
import GridForAthlets12 from "../adminCompetition/gridsForAthlets/GridForAthlets12";
import GridForAthlets13 from "../adminCompetition/gridsForAthlets/GridForAthlets13";
import GridForAthlets14 from "../adminCompetition/gridsForAthlets/GridForAthlets14";
import GridForAthlets15 from "../adminCompetition/gridsForAthlets/GridForAthlets15";
import GridForAthlets16 from "../adminCompetition/gridsForAthlets/GridForAthlets16";
import GridForAthlets17 from "../adminCompetition/gridsForAthlets/GridForAthlets17";
import GridForAthlets18 from "../adminCompetition/gridsForAthlets/GridForAthlets18";
import GridForAthlets19 from "../adminCompetition/gridsForAthlets/GridForAthlets19";
import GridForAthlets20 from "../adminCompetition/gridsForAthlets/GridForAthlets20";
import GridForAthlets21 from "../adminCompetition/gridsForAthlets/GridForAthlets21";
import GridForAthlets22 from "../adminCompetition/gridsForAthlets/GridForAthlets22";
import GridForAthlets23 from "../adminCompetition/gridsForAthlets/GridForAthlets23";
import GridForAthlets24 from "../adminCompetition/gridsForAthlets/GridForAthlets24";
import GridForAthlets25 from "../adminCompetition/gridsForAthlets/GridForAthlets25";
import GridForAthlets26 from "../adminCompetition/gridsForAthlets/GridForAthlets26";
import GridForAthlets27 from "../adminCompetition/gridsForAthlets/GridForAthlets27";
import GridForAthlets28 from "../adminCompetition/gridsForAthlets/GridForAthlets28";
import GridForAthlets29 from "../adminCompetition/gridsForAthlets/GridForAthlets29";
import GridForAthlets30 from "../adminCompetition/gridsForAthlets/GridForAthlets30";
import GridForAthlets31 from "../adminCompetition/gridsForAthlets/GridForAthlets31";
import GridForAthlets32 from "../adminCompetition/gridsForAthlets/GridForAthlets32";
import GridForAthlets33 from '../adminCompetition/gridsForAthlets/GridForAthlets33';
import GridForAthlets34 from '../adminCompetition/gridsForAthlets/GridForAthlets34';
import GridForAthlets35 from "../adminCompetition/gridsForAthlets/GridForAthlets35.js";
import GridForAthlets36 from "../adminCompetition/gridsForAthlets/GridForAthlets36.js";
import GridForAthlets37 from "../adminCompetition/gridsForAthlets/GridForAthlets37.js";
import GridForAthlets38 from "../adminCompetition/gridsForAthlets/GridForAthlets38.js";
import GridForAthlets39 from './../adminCompetition/gridsForAthlets/GridForAthlets39';
import lang from "../../../state/language.js";
import CoupleSell from "./coupleSell.js";
import AthletesTableForAthetes from "../adminCompetition/adminPoomsae/AthletesTableForAthetes.js";
import ConfirmButtons from "../adminCompetition/confirmButtons.js";
import indexedDBConnection from "../../../state/indexedDBConnection.js";
import { NavLink } from "react-router-dom";


function CategoryCell({ isOpen, onClose, cat, competition, isSmallScreen, langSwitch, poomse }) {

  const [errorMessage, setErrorMessage] = useState(''); //повідомлення про помилки та успіх
  const [successMessage, setSuccessMessage] = useState('');
  const [athletesModals, setAthletesModals] = useState(Array(cat.athletes.length).fill(false));
  const [changeCategory, setChangeCategory] = useState(null);
  const [updateData, setUpdateData] = useState([]);
  const [messageText, setMessageText] = useState('');
  const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState(false);
  const [athleteData, setAthleteData] = useState(null);
  const [athleteIndex, setAthleteIndex] = useState(null);

function resultWiew(index, atl) {

  setAthleteData(atl)
  setAthleteIndex(index) 
  openMyResultOnCompetitiionModal(index)  
}

  const openMyResultOnCompetitiionModal = (index) => {
    const newAthletesModals = [...athletesModals];
    newAthletesModals[index] = true;
    setAthletesModals(newAthletesModals);
  };

  const closeMyResultOnCompetitiionModal = (index) => {
    const newAthletesModals = [...athletesModals];
    newAthletesModals[index] = false;
    setAthletesModals(newAthletesModals);
  };

  const componentMap = {
    1: GridForAthlets2, 2: GridForAthlets2, 3: GridForAthlets3, 4: GridForAthlets4, 5: GridForAthlets5, 6: GridForAthlets6, 7: GridForAthlets7, 8: GridForAthlets8, 9: GridForAthlets9,
    10: GridForAthlets10, 11: GridForAthlets11, 12: GridForAthlets12, 13: GridForAthlets13, 14: GridForAthlets14, 15: GridForAthlets15, 16: GridForAthlets16, 17: GridForAthlets17, 18: GridForAthlets18,
    19: GridForAthlets19, 20: GridForAthlets20, 21: GridForAthlets21, 22: GridForAthlets22, 23: GridForAthlets23, 24: GridForAthlets24, 25: GridForAthlets25, 26: GridForAthlets26, 27: GridForAthlets27,
    28: GridForAthlets28, 29: GridForAthlets29, 30: GridForAthlets30, 31: GridForAthlets31, 32: GridForAthlets32, 33: GridForAthlets33, 34: GridForAthlets34, 35: GridForAthlets35, 36: GridForAthlets36,
    37: GridForAthlets37, 38: GridForAthlets38, 39: GridForAthlets39
  };

  let Component = null; let grids = []; let grid = []; let raitingOfAthlete;

  if (competition.grids !== null) {
    try { grids = JSON.parse(competition.grids) } catch { grids = competition.grids }
    grid = grids.filter((grid) => { return grid.category == cat.category })
    if (grid&&grid.length > 0) { Component = componentMap[grid[0].numberOfAthletes] }
  }



  function saveResultConfirm() {
   
    setMessageText(
      lang[langSwitch].changeWeightData //Змінити дані ваги та зросту спортсменів?
    )
    openConfirmButtonsModal()
  
  }
  const confirmFunction = (messageText) => {
    if (messageText === 
      lang[langSwitch].changeWeightData //Змінити дані ваги та зросту спортсменів?
    ) {
      update(updateData)
    }

  }
  const openConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(true); }
  const closeConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(false); }


  function update(updateData) {
  setSuccessMessage( lang[langSwitch].pleaseWait/*Зачекайте будь ласка*/)
    // axios.post(state.api.setScaleAthletes, {
    //   updateData: updateData
    // })
    //   .then(response => {
      
    //     setSuccessMessage('Антропометрику спортсмена оновлено')


        const updatedAthletes = state.athletes.map((athlete) => {
          // Перевірте, чи id співпадає з id в масиві updateData
          if (updateData.some((updateItem) => updateItem.id == athlete.id)) {
            // Визначте updateItem тут перед використанням
            const updateItem = updateData.find((item) => item.id == athlete.id);
        
            // Якщо співпадає, оновіть поле weiht і height зі значень updateItem
            return { ...athlete, weiht: updateItem.weiht, height: updateItem.height };
          }
          // Якщо id не співпадає, поверніть оригінальний об'єкт
          return athlete;
        });
      
       
       
        indexedDBConnection.saveData ('athletes', updatedAthletes)
                          .then(()=>{
                             state.athletes = updatedAthletes;
                             setUpdateData([])
                            window.location.reload(false)

                            setChangeCategory(null);
                          })
                          .catch((error)=>{
                            console.error(lang[langSwitch].failedToSaveDataToIndexedDB+': '/*Не вдалося зберегти дані в indexedDB*/, error);
        setErrorMessage(lang[langSwitch].failedToSaveDataToIndexedDB+': '/*Не вдалося зберегти дані в indexedDB*/, error)
                          })
        
//оновлюе сторінку після успішного зважування
        

      // })
      // .catch(error => {
      //   console.error('Помилка Axios-запиту, невдалось оновити статус тренера:', error);
      //   setErrorMessage('Помилка Axios-запиту, невдалось оновити статус тренера')
      // });

      
  }

  return (<>
  {isOpen?(<>
    <div className={s.modalWriper}>
    <div className={s.modal} >


      <div key={cat.category} className={s.categoryBlock}>
        <div className={s.title}>{competition.title}
          <p><u><b>{state.formatDate(competition.dateStart).slice(0, 2)}</b>-
            <b>{state.formatDate(competition.dateFinish)}&nbsp;
              {lang[langSwitch].yearShort /*р.*/}
            </b></u> <br /><br /></p>
        </div> {/* Закриття шапки */}

        {/* ///////////////////////////////////////////////////////////////////////////    */}
        <div className={s.boxTitile1}>
          <div className={s.categoryBlockTitle} >
            {competition.poomse == 1 ? lang.poomsaeCatUkr(langSwitch, cat.category) : state.weightCatUkr(cat.category)}</div>
          <div className={s.region}>
            {cat.athletes.length % 10 === 1 ?
              lang[langSwitch].declared1 /*Заявлений*/ + ':' + ' '
              :
              lang[langSwitch].declared /*Заявлено*/ + ':' + ' '
            }
            <b>{cat.athletes.length}</b>
            {cat.athletes.length % 10 === 0 ?
              ' ' + lang[langSwitch].participants /*учасників*/
              : ''}
            {cat.athletes.length % 10 === 1 && cat.athletes.length !== 11 ?
              ' ' + lang[langSwitch].participant /*учасник*/
              : ''}
            {(cat.athletes.length % 10 >= 2 && cat.athletes.length % 10 <= 4) && (cat.athletes.length % 100 < 10 || cat.athletes.length % 100 >= 20) ?
              ' ' + lang[langSwitch].participants1 /*учасникa*/
              : ''}

            {cat.athletes.length % 10 > 4 || cat.athletes.length === 11 ?
              ' ' + lang[langSwitch].participants /*учасників*/
              : ''}
            <br />&nbsp;</div>
        </div>

        {Component ?(<div  key={grid[0].category}>
          <Component
           
            grid={grid[0]}
            competition={competition}
            battleNumbers={grid[0].battleNumbers}
            cort={grid[0].cort}
            langSwitch={langSwitch}
          />
          </div>) : null}
{/* Сітки Пумсе за системою виключення */}
         {grid.length > 0 && !grid[0].numberOfAthletes?
         <AthletesTableForAthetes grid={grid[0]} langSwitch ={langSwitch} competition={competition}  />
         :null} 

        <div className={s.gridContainerForCategories}>
          {cat.athletes.map((athlete, index) => {
            

            return(
            <div key={athlete.id}>
              {Array.isArray(athlete) ? (
                <div className={s.cellPoomsae} >
                <div >
                  <div className={s.headerPoomsaeCell}>
                      <u>{athlete[0].team}</u>
                  </div>
                  </div>
                  <div className={s.coupelCellContaner}>
                <CoupleSell
                  athleteData={athlete}
                  langSwitch={langSwitch}
                  isSmallScreen={isSmallScreen} /> 
                  </div>
</div>
             ) : (<div key={athlete.id}>
               
                <div className={s.cell}>
                  <div className={s.categoryName}>
                    <div>  {state.athleteName(athlete.id)} </div>
                     <div className={s.categoryData}>  
                      <b>{state.formatDate(athlete.birthday)} </b> 
                       {athlete.birthday?lang[langSwitch].yearShort /*р.*/:null}
                      </div>
                    <div className={s.region}>
                      <u>
                        {langSwitch === 'ukr' ? athlete.region :
                          langSwitch === 'eng' ? lang.regionsEng(athlete.region) : null
                        }
                      </u>
                    </div>
                    <div className={s.region}>
                      {lang[langSwitch].coachShort /*трен.*/}:  <b> {state.myCoach(athlete.coach)}</b>; &nbsp;
                      {lang[langSwitch].coachShort /*трен.*/}2:  <b> {state.myCoach(athlete.coach2)}</b>

                    </div>
                  </div>



                {Number(state.userProfile.id)===Number(athlete.coach)||Number(state.userProfile.id)===Number(athlete.coach2)?(<>
                    <NavLink to={`/editProfileByCoach/${athlete.id}`}>
                  <div className={s.categoryImg} style={{cursor:'pointer'}}>
                    <img src={athlete.photo ? athlete.photo : state.noAvatarPhoto}
                      alt={state.myCoach(athlete.id)} />
                  </div>
                  </NavLink>
                  </>):(<>
                    <div className={s.categoryImg}>
                    <img src={athlete.photo ? athlete.photo : state.noAvatarPhoto}
                      alt={state.myCoach(athlete.id)} />
                  </div>
                  </>)}
                  <div className={s.categoryData}>

                    <div>
                      {lang[langSwitch].qualificationShort /*кваліф.*/}:
                      <b> {athlete.dan}</b>;  </div>
                    <div>
                      {lang[langSwitch].rank /* розряд*/}:
                      <b> {langSwitch === 'ukr' ? athlete.rank :
                        langSwitch === 'eng' ? lang.rankEng(athlete.rank) : null
                      }
                      </b></div>

                    <div>
                      {lang[langSwitch].height /*зріст*/}:
                      <b> {athlete.height}</b>
                      {lang[langSwitch].cm /*см.*/}
                    </div>
                    <div>
                      {lang[langSwitch].weight /*вага*/}:
                      <b> {athlete.weiht}</b>
                      {lang[langSwitch].kg /*кг.*/}
                    </div>

                    <div>
                      {lang[langSwitch].sex /*стать*/}:
                      <b> {athlete.sex === 'male' ?
                        lang[langSwitch].sexM /*Чоловік*/
                        : null}
                        {athlete.sex === 'female' ?
                          lang[langSwitch].sexF /*Жінка*/
                          : null}
                        {athlete.sex === null || athlete.sex === undefined ? 'athlete.sex' : null}

                      </b>

                    </div>

                    <div>
                      {lang[langSwitch].fights /*поєдинків*/}:
                      <b> {athlete.battles}</b>
                    </div>

                    <div>
                      {lang[langSwitch].victory /*перемог*/}:
                      <b> {athlete.victory}</b>
                    </div>
                    <div>
                      {lang[langSwitch].rating /*рейтинг*/}:
                      <b> {athlete.rating} </b>
                      {lang[langSwitch].points /*балів*/}
                    </div>
                  </div>

                  <div className={s.categoryRating}>

              {poomse ? null : (<>
                <div key={athlete.id} className={s.listForCoach}> {raitingOfAthlete = state.ratingQuery(athlete.id, competition.heightCategories, competition.olimpicAdults, competition.olimpicJuniors)}</div>

                    <div> <b>{raitingOfAthlete[0]} </b>
                      {lang[langSwitch].placeInRating /*місце загального рейтингу*/}
                    </div>                    
                    <div>  <b>{raitingOfAthlete[2]} </b>
                      {lang[langSwitch].placeInCategory /*місце в категорії*/}
                      <b> {state.weightCatUkr(raitingOfAthlete[1])}</b></div>
              </>)}

                    <button type='button' className={s.buttonOfPersonalResult} 
                    
                    onClick={() => { resultWiew(index, athlete)}}>

                      {lang[langSwitch].results /*Pезультати*/}
                    </button>

                    {/* Зміна категорії*/}
                    {Number(state.userProfile.id)===Number(competition.secretary)||Number(state.userProfile.id)===Number(competition.mainJudge)?(<>
                    
                    <br/>
                    <br/>
                    {successMessage ?
        (<><br /><br /><div className={s.successMessage} onClick={() => { setSuccessMessage('') }}>
          {successMessage}</div><br /></>) :
        errorMessage ? (<><br /><br /><div className={s.errorMessage} onClick={() => { setErrorMessage('') }}>
          {errorMessage}</div><br /></>) : ''}

              
  </>):null}
                   

                  </div>

                </div>

              </div>)}

            </div>)})} 
            
            <ConfirmButtons
        isOpen={isConfirmButtonsModalOpen}
        onClose={closeConfirmButtonsModal}
        confirmFunction={confirmFunction}
        messageText={messageText}
        langSwitch={langSwitch}
      /> </div>

        {/*  ///////////////////////////////////////////////////////////////////////////////////// */}



      </div>



      <button type='button' onClick={onClose}>
        {lang[langSwitch].close /*Закрити*/}
      </button>
    </div>
    </div>
</>):null}



{poomse ? null : (<>
             {athleteData?(<>
             <MyResultOnCompetitiion                  
               name={athleteData.secondName + ' ' + athleteData.personName + ' ' + athleteData.fatherName}
               isOpen={athletesModals[athleteIndex]}
               onClose={() => closeMyResultOnCompetitiionModal(athleteIndex)}
               myCompetition={JSON.parse(athleteData.myCompetition)}
               isSmallScreen={isSmallScreen}
               langSwitch={langSwitch}
             />
             </>):null}
           </>)}
               





            </>)
}
export default CategoryCell