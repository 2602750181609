//Відкликання заявки спортсменів на змагання. 
//містить модальне вікно для вибору спортсменів, прийом та обробку даних та 
// axios виклики для відправки даних в БД.

import React, { useEffect, useState } from "react";
import s from '../adminCompetition/adminCompetition.module.css';
import state from "../../../state/state.js";
import axios from "axios";
import lang from "../../../state/language.js";
import indexedDBConnection from "../../../state/indexedDBConnection.js";

function Withdraw({ isOpen, onClose, tournId,isSmallScreen,langSwitch }) {
  // Створіть стан для зберігання списку вибраних спортсменів
  const [selectedAthletes, setSelectedAthletes] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorAthletes, setErrorAthletes] = useState([]);
  const [bClasData, setBClasData] = useState(null);
  
useEffect(()=>{setSelectedAthletes([])},[bClasData])

  const handleClassChange = (e) => { setBClasData(e.target.value) }

  const comp = state.competition.filter((tourn) => tourn.id == tournId);// турнир в массиві
  const competition=comp[0]// виводимо турнир з БД 
  let athletesIds, athletes;


  if (competition.athletes===null&&!bClasData){}
  else{

      try {//обробляємо массив атлетів з таблиці змаганнь
          athletesIds = JSON.parse(competition.athletes)
            .filter(athlete => athlete.clasB===bClasData)//мої спортсмени
            .map(athlete => athlete.id); 
        } catch (error) {
          athletesIds = competition.athletes
            .filter(athlete => athlete.clasB===bClasData)//мої спортсмени
            .map(athlete => athlete.id);  
        }  
  
   const allAthletes = state.athletes.filter(athlete => athletesIds.includes(athlete.id));// Профілі зявлених спортсменів 
    athletes  = allAthletes.filter(at=>Number(at.coach)===Number(state.userProfile.id)||Number(at.coach2)===Number(state.userProfile.id))
  }  



  // Функція для додавання або видалення спортсмена зі списку (плитки) під час заявки
  const toggleAthleteSelection = (athlete) => {
    if (selectedAthletes.includes(athlete.id)) {
      // Спортсмен вже вибраний, видаляємо його зі списку
      setSelectedAthletes(selectedAthletes.filter((id) => id !== athlete.id));
    } else {
      // Спортсмен ще не вибраний, додаємо його до списку
      setSelectedAthletes([...selectedAthletes, athlete.id]);
    }
  };

  function updateAthletesOnCompetition(selectedAthletes, tournId) {

    //отримаємо список усіх спортсменів заявлених на змагання
    axios
      .post(state.api.getAthletesForComp, { id: tournId })
      .then((response) => {
        if (Array.isArray(response.data.athletes)&&selectedAthletes.length>0) {


          //вилучаємо спортсменів, яких знімаємо з турниру
          const filteredAthletes = typeof response.data.athletes === 'string' ?
            JSON.parse(response.data.athletes).filter(athlete => !(selectedAthletes.includes(athlete.id) && athlete.clasB===bClasData)) :
            response.data.athletes.filter(athlete =>!(selectedAthletes.includes(athlete.id) && athlete.clasB===bClasData))

          // завантажуємо оновлений масив спортсменів
          axios
            .post(state.api.addNewAthletes, {
              id: tournId,
              newAthletes: filteredAthletes,
            })
            .then((response) => {
              setSuccessMessage(
                lang[langSwitch].applicationSuccessfullyWithdrawn /* Заявку ваших спортсменів на туртир успішно відкликано.*/
              )
              //оновлюємо стан
              const indexOfCompetition = state.competition.findIndex(comp => comp.id == tournId);

              if (indexOfCompetition !== -1) {
                // Замініть поле athletes у знайденому об'єкті                
                state.competition[indexOfCompetition].athletes = filteredAthletes;
                indexedDBConnection.saveData ('competition', state.competition)
               
              }

    })
            .catch((error) => {
             
              setErrorMessage(lang[langSwitch].serverRequestError /*Помилка запиту до сервера:*/  + error);
            });

        }
        else { setErrorAthletes(
          lang[langSwitch].selectAthleteForWithdraw /*Оберіть спортсмена заявку якого ви бажаєте відкликати.*/) }

      })
      .catch((error) => {
        setErrorMessage(lang[langSwitch].serverRequestError /*Помилка запиту до сервера:*/  + error);
            });

  }

  const createMarkup = (html) => {
    return { __html: html };
  };


  return (<>
    {isOpen?(<>
      <div className={s.modalWriper}>
    <div appElement={document.getElementById('root')} className={s.modal}  contentLabel="Regulation Modal">

      <div className={errorAthletes.length === 0 ? '' : `${s.errorMessage}`} 
      dangerouslySetInnerHTML={createMarkup(errorAthletes)} 
      onClick={()=>{setErrorAthletes([])}}
      />
      <div className={s.teamContaner}>
        <div className={s.teamChange}> 
          <h2>
          {lang[langSwitch].myTeam /*Моя команда*/}
            </h2>
            </div>
            <div className={s.competitionClassChoise}>
              <label htmlFor="ClassChoise"><span className={s.teamChangeMenuSpan}>
                {lang[langSwitch].chooseClass /*Оберить клас*/}:
              </span> </label><br />
              <select onChange={handleClassChange} id="ClassChoise" name="ClassChoise">
                <option value=""> </option>
                <option value="B1">
                  {lang[langSwitch].bClass1 /*Б-1*/}
                </option>
                <option value="B2">
                  {lang[langSwitch].bClass2 /*Б-2*/}
                </option>
                <option value="B3">
                  {lang[langSwitch].bClass3 /*Б-3*/}
                </option>
                <option value="B4">
                  {lang[langSwitch].bClass4 /*Б-4*/}
                </option>

              </select>

            </div>
      </div>

      {/* "плитка спортсменів" */}
      <div className={s.gridContainer}>
        {athletes?(<>
        {athletes.map((athlete) => (
          <div key={athlete.id} className={selectedAthletes.includes(athlete.id) ? `${s.athleteBlock} ${s.athleteBlock1}` : s.athleteBlock}>
            <img
              src={athlete.photo?athlete.photo:state.noAvatarPhoto}
              alt={`${athlete.secondName} ${athlete.personName}`}
              style={{ cursor: 'pointer' }}
              onClick={() => toggleAthleteSelection(athlete)}
            />
            <div className={s.name}>{`${athlete.secondName} ${athlete.personName}`}</div>
          </div>
        ))}
        </>):null}
      </div>

      {successMessage ?
        (<><br /><br /><div className={s.successMessage} onClick={()=>{setSuccessMessage('')}}>
          {successMessage}</div><br /></>) :
        errorMessage ? (<><br /><br /><div className={s.errorMessage} onClick={()=>{ setErrorMessage(''); }}>
          {errorMessage}</div><br /></>) : ''}


          <div className={isSmallScreen?s.td1:null}> 
      <button type='button' onClick={() => { onClose(); setErrorMessage(''); setSuccessMessage(''); setErrorAthletes([]);}}>
      {lang[langSwitch].close /*Закрити*/}
        </button>
      <button className={!isSmallScreen?s.buttonR:null} type='button' onClick={() => {
        setErrorAthletes([]); setSuccessMessage('');
        updateAthletesOnCompetition(selectedAthletes, tournId);

      }}>
        {lang[langSwitch].withdrawApplication /*Відкликати заявку*/}
        </button>
      </div>
    </div>
    </div>
    </>):null}
 </> );
}

export default Withdraw;
