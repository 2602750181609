import React, {  useState } from 'react';
import s from '../menu/menu.module.css'
import sAdmin from '../../adminCompetition/adminCompetition.module.css'
import sApp from '../../../../App.module.css'
import lang from '../../../../state/language';
import MultiImageUploader from '../news/multiImageUploader';
import state from '../../../../state/state';
import ConfirmButtons from '../../adminCompetition/confirmButtons';
import axios from 'axios';
import indexedDBConnection from '../../../../state/indexedDBConnection';



function EditPhotoGalery({ langSwitch, isSmallScreen, editPhotoGalery, setEditStart }) {



  const [compressedFile, setCompressedFile] = useState([]);
  const [newsTitle, setNewsTitle] = useState(editPhotoGalery.title);
  const [type, setType] = useState(editPhotoGalery.type);
  const [currentPhoto, setCurrentPhoto] = useState(()=>{
    if(editPhotoGalery.links && editPhotoGalery.links !== 'null' )  {
       let result = []
      try{
        result = JSON.parse(editPhotoGalery.links) 
      }  catch{ 
        result = editPhotoGalery.links
      }
        return result
    }else{ return [] }
 }
  
  );
  const [photoForDelete, setPhotoForDelete] = useState([]);
  const [messageText, setMessageText] = useState('');
  const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [uploading, setUploading] = useState(false);


  const openConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(true); }
  const closeConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(false); }

  function saveGalleryConfirm() {  
      setMessageText(lang[langSwitch].saveChangesToGallery   /*Зберегти зміни в галереї?*/)
      openConfirmButtonsModal()
      }
  const confirmFunction = (messageText) => {
    if (messageText === lang[langSwitch].saveChangesToGallery   /*Зберегти зміни в галереї?*/
    ) {
      //ВИКЛИК ФУНКЦІЇ Оновлення СТАТТІ
      updateDB()

    }
  }

  function handleNewsTitle(e) { setNewsTitle(e.target.value) }
  function handleGalleryTypeChange(e) { setType(e.target.value) }

  function uploadImg() {
    const formData = new FormData();
    formData.append("uploadDir", editPhotoGalery.uploadDir);
    formData.append("globalUrl", editPhotoGalery.globalUrl);
    // Додаємо фото у FormData
    compressedFile.forEach((file) => {
      formData.append(`photos[]`, file);
    });
    //завантажуєм фото
    axios.post(state.api.uploadGalleryImg, formData)
      .then(response => { // Обробка успішного завантаження  
 
        const uploadedFiles = response.data.uploadedFiles || [];
        if (uploadedFiles.length > 0) {
                      
                updateNews( [...currentPhoto, ...uploadedFiles]);  
        }
      })
      .catch(error => {// Обробка помилок
        setUploading(false)
        console.error("Помилка завантаження файлів:", error);
        if (error.response) {
          console.error("Деталі помилки:", error.response.data);
        }
      });
  }

  function updateNews(photoData) {

    axios
      .post(state.api.editGallery, {
        title: newsTitle,
        type: 	type,
        links: photoData?JSON.stringify(photoData):JSON.stringify(currentPhoto),
        id: editPhotoGalery.id,
        headers: { 'Content-Type': 'application/json' }
      })
      .then((response) => {
   
        const index = state.mediaContent.findIndex((item) => +item.id === +editPhotoGalery.id)
        state.mediaContent[index] = {   
          id: editPhotoGalery.id,     
          title: newsTitle,
          type: 	type,
          links:  photoData?photoData:currentPhoto,
          uploadDir:editPhotoGalery.uploadDir,
          typeContent:editPhotoGalery.typeContent,
          globalUrl:editPhotoGalery.globalUrl
        }
        indexedDBConnection.saveData('mediaContent', [...state.mediaContent])

        setSuccessMessage(
          lang[langSwitch].successfullSavedInDB /*Внесені зміни успішно збережено в Базі Даних*/
        );

        window.location.href = '/ControlPanel'
      })
      .catch((error) => {
        setUploading(false)
        setErrorMessage(
          lang[langSwitch].serverRequestError/*Помилка запиту до сервера:*/);
        console.error("Помилка завантаження файлів:", error);
        if (error.response) {
          console.error("Деталі помилки:", error.response.data);
        }
      });


  };

  function updateDB() {
    setUploading(true)
    // Якщо є файли для видалення
    if (photoForDelete.length > 0) {
   
      //видалення старого фото
      axios.delete(state.api.deleteGalleryImg, {
        data: {
          filesName: photoForDelete,
          uploadDir:	editPhotoGalery.uploadDir
        }
      })
        .then(response => { // Обробка успішного видалення  

              //все видалено,  фото для завантаження немає
              if ((response.data.deletedFiles.length === photoForDelete.length) && compressedFile.length === 0) {
                updateNews()
              }

              //не знайдено  обране(ні) фото для видалення
              if (response.data.notFoundFiles.length > 0) {
                setUploading(false)
                setErrorMessage(lang[langSwitch].filedImgDeleteWarning /*Невдалось знайти на сервері обрані вами файли для видалення...*/)
              }   
              
              //все видалено, є фото для завантаження
              if ((response.data.deletedFiles.length === photoForDelete.length) && compressedFile.length > 0) {
                uploadImg()
              }
        })
        .catch(error => {// Обробка помилок
          console.error("Помилка завантаження файлів:", error);
          if (error.response) {
            console.error("Деталі помилки:", error.response.data);
          }
        });
    }

    //якщо фото тільки для завантаження
    if (photoForDelete.length === 0 && compressedFile.length > 0) {
      uploadImg()
    }

    //якщо оновлення стосується тільки тексту
    if (photoForDelete.length === 0 && compressedFile.length === 0) {
      updateNews()
    }

  }



  return (<div>

<div className={s.titleBox}>
    <div className={s.editButtonItem}>
      <div className={s.h3}>
        {lang[langSwitch].galeryTitle   /*Назва галереї*/}
      </div>   
     
      <input
        type='text'
        className={s.inputNews}
        value={newsTitle}
        onChange={(e) => { handleNewsTitle(e) }}
      />
    </div>
<div className={s.deleteButtonItem} 
      style={{textAlign:'right', paddingRight:'20px'}}>
<button     
    type='button'
    onClick={()=>{setEditStart(false)}}>
  {lang[langSwitch].close /*Закрити*/}
  </button>
  
</div>

</div>
<div>
      <div className={s.h3}>
        {lang[langSwitch].typeOfGallery   /*Тип галереї*/}
      </div>
      <select onChange={handleGalleryTypeChange}  >
      <option value={type}>{type}</option>
      {state.galleryType.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ))}
    </select>
       </div>
    <div>
      <div className={s.h3}><br/>
        {lang[langSwitch].photos   /*Світлини*/}
      </div>
      <div className={s.typeOfPhotos}>
      {lang[langSwitch].currents /*Поточні*/}
        </div>   
      <div className={s.photoPreview}>
        {currentPhoto.map((file, index) => {

          return (
            <div key={index}>
              <div className={s.imgWriper}>
                <img
                  className={s.deleteImg}
                  src={state.deleteImg}
                  onClick={
                    () => {
                      setCurrentPhoto((prev) => {
                        const result = prev.filter((item) => item !== file)
                        return result
                      })
                    
                      setPhotoForDelete((prev) => { 
                         const parts = file.split('/');                          
                        return [...prev, parts[parts.length - 1]]  // Отримуємо тільки ім'я старих фоток
                      })
                    }}
                />
                <img
                  key={index}
                  src={file}
                  width={200}
                  height={200}
                  style={{ objectFit: 'cover', borderRadius: '8px' }}
                />
              </div>
            </div>
          )
        }
        )}
      </div>
      <div className={s.typeOfPhotos}>
       {lang[langSwitch].new /*Нові*/}
        </div>   
      {successMessage&&(<>
        <br/><br/><div className={sAdmin.successMessage} onClick={()=>{setSuccessMessage('')}}>
                 {successMessage}
        </div><br/>
        </>) }
              {errorMessage ? (<><br /><br /><div className={sAdmin.errorMessage} onClick={() => { setErrorMessage('') }}>
              {errorMessage}</div><br /></>) : ''}
      <MultiImageUploader
        setCompressedFile={setCompressedFile}
        compressedFile={compressedFile}
        numberOfPhoto={(state.numberOfPhotoInGalery - currentPhoto.length) > 0 ? state.numberOfPhotoInGalery - currentPhoto.length : 0}
        langSwitch={langSwitch}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        

      />
      <button 
        type='button'
        onClick={()=>{saveGalleryConfirm()}}>
          { lang[langSwitch].editGallery /*Редагувати галерею*/ }
        </button>
    </div>
    
    {  uploading&&(
        <div className={sAdmin.modalWriper}>
            <div className={sApp.flag}>
            { lang[langSwitch].pleaseWaitDataLoading /*Зачекайте, дані завантажуються...*/ }<br /><br />
                <img alt='фото' src='/img/main/ukrainian-waving-flag-30.gif' />
               </div>
          </div>
      )}
   

    <ConfirmButtons
      isOpen={isConfirmButtonsModalOpen}
      onClose={closeConfirmButtonsModal}
      confirmFunction={confirmFunction}
      messageText={messageText}
      langSwitch={langSwitch}
    />

  </div>);
};

export default EditPhotoGalery;










