// формування "плитки" з турнирами на сторінці календаря

import s from './calendar.module.css'
import CompetitionBlock from './competitionBlock'


function CompetitionTable ({admin,langSwitch, isSmallScreen, title, tourn}) {

    const currentDate = new Date(); // Поточна дата 
      currentDate.setHours(0, 0, 0, 0)//дата без урахування часу
      
    const competitionsFilter = tourn.filter((comp) => {
      // Перетворення дати закінчення з рядка в об'єкт Date
      const compEndDate = new Date(comp.dateFinish)
      compEndDate.setHours(0, 0, 0, 0);//дата без урахування часу

      // Порівняння дати закінчення з поточною датою
      return (compEndDate >= currentDate);
    });
    const competitions = competitionsFilter.sort((b, a) => new Date(a.dateStart) - new Date(b.dateStart));


    return (<>
{admin?(<>
  <div className={!isSmallScreen?s.contentTitle:s.center}>
     <div className={s.contentTitleItem1}> 
     <h1 className={s.h1}>{title}</h1>
     </div>
     <div className={s.contentTitleItem2}>
   
      
      </div>
    </div>
  
      <div className={s.gridContainer}>
        
      {tourn.map((competition) => (
       
        <CompetitionBlock 
        key={competition.id} 
        admin={admin} 
        competition={competition} 
        langSwitch={langSwitch}
        />
    
      ))}
    </div>
</>):(<>
  <div className={!isSmallScreen?s.contentTitle:s.center}>
     <div className={s.contentTitleItem1}> 
     <h1 className={s.h1}>{title}</h1>
     </div>
     <div className={s.contentTitleItem2}>  
      </div>
    </div>
  
      <div className={s.gridContainer}>
        
      {competitions&&
      competitions.map((competition) => (
        <CompetitionBlock 
        key={competition.id} 
        admin={admin} 
        competition={competition} 
        langSwitch={langSwitch}
        />
      ))
      }
    </div>

</>)}

</> );
}

export default CompetitionTable