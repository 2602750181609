import { useState } from "react";
import state from "../../../state/state.js"
import s from '../mySportClubs/SportClubs.module.css'
import AthletesCell from "../mySportClubs/athletesCell";
import lang from './../../../state/language';

function CommunitySportClubsItem({
            club,
            creator,
            myTeamArray,
            myCoachArray,
            isSmallScreen,
            langSwitch}) {

    const [imageError, setImageError] = useState(false);

const myAthletes = myTeamArray.filter(item=>item.sportClub===club.name)
const myCoaches = myCoachArray.filter(item=>item.sportClub===club.name)

    // Модальне вікно з переглядом спортсменів
const [athletesCellModalOpen, setAthletesCellModalOpen] = useState (false);
const openAthletesCellModal = () => { setAthletesCellModalOpen(true); }
    const closeAthletesCellModal = () => {setAthletesCellModalOpen(false);}

  return (<>
           <div className={s.communityCell} style={{cursor: 'pointer'}} onClick={()=>{setAthletesCellModalOpen(true)}}>{/* блок с фото тренера */}
               <div className={s.communityСlubLogo}>
                   <img src={club.logo&&!imageError?club.logo:state.noLogoPhoto}
                   alt={club.name} 
                   onError={()=>{setImageError(true)}}/>
               </div>

               <div className={s.categoryData}>
                   <div className={s.coachName}>{club.name}</div>
                   <div className={s.region}>
                    {!isSmallScreen&&!state.isMediumScreen?
                     'м.'+club.city+'. '+ state.regionShort (club.region)
                    :
                    state.regionShort(club.region)}
                    </div>

                   <div >
                   {lang[langSwitch].seniorCoachShort /*Ст.трен.*/}:
                     
                    &nbsp;<b>
                     {!isSmallScreen&&!state.isMediumScreen?state.myCoachFull(creator[0].id):state.myCoach(creator[0].id)}
                     </b></div>
                   <div >
                   {!isSmallScreen&&!state.isMediumScreen?
                   lang[langSwitch].qualification /*Кваліфікація*/
                   :
                    lang[langSwitch].qualificationShort /*Кваліф.*/}

                        <b> {langSwitch==='ukr'?creator[0].dan:
                          langSwitch==='eng'?lang.danEng(creator[0].dan):null}
                           &nbsp;/&nbsp;
                          {langSwitch==='ukr'?creator[0].rank:
                          langSwitch==='eng'?lang.rankEng(creator[0].rank):null} 
                           </b>
</div>
            
                   <div>
                   {lang[langSwitch].team /*Команда*/}: <br/>
                   <b> {myAthletes.length} </b> 
                   {lang[langSwitch].athletesInGen /* атлетів */} <b> / </b>
                   <b> {myCoaches.length} </b>
                    {lang[langSwitch].coachesInGen /*тренерів*/}
                    </div>
               </div>
</div> 
            <AthletesCell athletes={myAthletes} 
        club={club}
          isOpen={athletesCellModalOpen}
          onClose={closeAthletesCellModal} 
          myCoachArray={myCoaches}
          isSmallScreen={isSmallScreen}
          langSwitch={langSwitch}
        /> 

    </>)


}

export default CommunitySportClubsItem