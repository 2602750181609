
import s from './adminCompetition.module.css'
import state from "../../../state/state.js";
import axios from "axios";
import { useState } from "react";

import ReportGrids from "./reportGrids";
import ConfirmButtons from './confirmButtons';

import Grid2 from "./grids/Grid2";
import Grid3 from "./grids/Grid3";
import Grid4 from "./grids/Grid4";
import Grid5 from "./grids/Grid5";
import Grid6 from "./grids/Grid6";
import Grid7 from "./grids/Grid7";
import Grid8 from "./grids/Grid8";
import Grid9 from "./grids/Grid9";
import Grid10 from "./grids/Grid10";
import Grid11 from "./grids/Grid11";
import Grid12 from "./grids/Grid12";
import Grid13 from "./grids/Grid13";
import Grid14 from "./grids/Grid14";
import Grid15 from "./grids/Grid15";
import Grid16 from "./grids/Grid16";
import Grid17 from "./grids/Grid17";
import Grid18 from "./grids/Grid18";
import Grid19 from "./grids/Grid19";
import Grid20 from "./grids/Grid20";
import Grid21 from "./grids/Grid21";
import Grid22 from "./grids/Grid22";
import Grid23 from "./grids/Grid23";
import Grid24 from "./grids/Grid24";
import Grid25 from "./grids/Grid25";
import Grid26 from "./grids/Grid26";
import Grid27 from "./grids/Grid27";
import Grid28 from "./grids/Grid28";
import Grid29 from "./grids/Grid29";
import Grid30 from "./grids/Grid30";
import Grid31 from "./grids/Grid31";
import Grid32 from "./grids/Grid32";
import Grid33 from "./grids/Grid33";
import Grid34 from "./grids/Grid34";
import Grid35 from "./grids/Grid35";
import Grid36 from "./grids/Grid36";
import Grid37 from "./grids/Grid37";
import Grid38 from "./grids/Grid38";
import Grid39 from './grids/Grid39';
import lang from "../../../state/language.js";
import indexedDBConnection from "../../../state/indexedDBConnection.js";

function GridsModalWindow(props) {
 const langSwitch=props.langSwitch
  const grids = props.grids

  const competition = props.competition
const poomse=competition.poomse==1?true:false
  const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState (false);
  const [messageText, setMessageText] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [gridReport, setGridReport] = useState(false);


  ///////////////////////////////////////////////////////////////////////////////////////
// Модальне вікно з кнопками погодження.

function deleteGridsConfirm(){
  setMessageText(
    lang[langSwitch].deleteGridsFromDB/*Видалити сітки з Бази даних?*/
  )  
  openConfirmButtonsModal()
}

function saveGridsConfirm(){
  setMessageText(
    lang[langSwitch].saveGridsToDB/* Зберегти сітки в Базі даних?*/
  )  
  openConfirmButtonsModal()
}

function saveResultConfirm(){
  setMessageText(
    lang[langSwitch].saveCompetitionResultsInDB/* Зберегти результати змаганнь в Базі даних?*/
    )  
  openConfirmButtonsModal()
}
const confirmFunction = (messageText) => {
  if(messageText===
    lang[langSwitch].deleteGridsFromDB/*Видалити сітки з Бази даних?*/
  ){deleteGridsFromDB()}
  if(messageText===
    lang[langSwitch].saveGridsToDB/* Зберегти сітки в Базі даних?*/
    ){state.saveGridsFunctionStart = true;  setTimeout(()=>{props.onClose()}, 1000);
      
    }
  if(messageText===
    lang[langSwitch].saveCompetitionResultsInDB/* Зберегти результати змаганнь в Базі даних?*/
  ){saveCompetitionResult()}
}


const openConfirmButtonsModal = () => {setIsConfirmButtonsModalOpen(true);}
const closeConfirmButtonsModal = () => {setIsConfirmButtonsModalOpen(false);}
/////////////////////////////////////////////////////////////////////////////////////

const competitionPoomsaeResult=[]
  function resultForAthletesPoomsae() {//Результат спортсменам Пумсе
let competitionAthletes
try{competitionAthletes=JSON.parse(competition.athletes)}
catch{competitionAthletes=competition.athletes}

    const result = competitionAthletes.map((athlete) => {
      const data={}
  
      data.title=competition.title
      data.poomse=poomse
    data.date=competition.dateStart
    data.place=[]

  
    
      state.competitionResult.forEach((athleteResult) => {
        if (athleteResult.teamPoomsae) {
          athleteResult.teamPoomsae.forEach((item)=>{
            if(item.id==athlete.id){
           data.place.push({category:athleteResult.competitionCategory,place:athleteResult.competitionPlace,team:athleteResult.team})
            }
          })
        } else {
          if (athlete.id == athleteResult.id) {
           data.place.push({category:athleteResult.competitionCategory,place:athleteResult.competitionPlace,team:athleteResult.team})
          }
        }
      })
      let myCompetition;
      const profile=state.athletes.filter((at)=>at.id==athlete.id)
  
    if(profile.length>0){
      if (profile[0].myCompetition){
      try {
        myCompetition=JSON.parse(profile[0].myCompetition)
      } catch (error) {
         myCompetition=profile[0].myCompetition;
      }}
      }
  let data1=[]
  if(Array.isArray(myCompetition)&&myCompetition.length>0){ data1=[...myCompetition,data]}
  if(!Array.isArray(myCompetition)&&myCompetition){data1.push(myCompetition); data1.push(data)}
  if(!myCompetition||Array.isArray(myCompetition)&&myCompetition.length===0) {data1.push(data)}
    athlete.poomsaeResult=data
    competitionPoomsaeResult.push(athlete)
      return {
        id: athlete.id,
        battles:athlete.battles,
        rating:athlete.rating,
        victory:athlete.victory,
        myCompetition:JSON.stringify(data1),
        lastChangesDate:state.lastChangesDateFormat()//Дата останьої зміни профіля
      };
      
    })
    return result
  }




function resultForAthletes() {//Результат спортсменам Керугі
  const result = state.competitionResult.map((item) => {

    const data={}
 
      data.title=competition.title
      data.poomse=poomse
    data.date=competition.dateStart
    data.place=item.competitionPlace
    data.battles=item.battlesOnComp
    data.victory=item.victoryOnComp
    data.category=item.competitionCategory
         
    let myCompetition;
    const profile=state.athletes.filter((at)=>at.id==item.id)

  if(profile.length>0){
    if (profile[0].myCompetition){
    try {
      myCompetition=JSON.parse(profile[0].myCompetition)
    } catch (error) {
       myCompetition=profile[0].myCompetition;
    }}
    }
let data1=[]
if(Array.isArray(myCompetition)&&myCompetition.length>0){ data1=[...myCompetition,data]}
if(!Array.isArray(myCompetition)&&myCompetition){data1.push(myCompetition); data1.push(data)}
if(!myCompetition||Array.isArray(myCompetition)&&myCompetition.length===0) {data1.push(data)}


    return {
      id: item.id,
      battles: +item.battlesOnComp,
      rating: +item.ratingOnComp,
      victory: +item.victoryOnComp,
      myCompetition:JSON.stringify(data1),
      lastChangesDate:state.lastChangesDateFormat()//Дата останьої зміни профіля
    };
  });

  return result;
}


 const updateData=poomse?null:resultForAthletes()

/////////////////////////////////////////////////////////////////////////////////////
function mergedData(data){// отримуємо рейтинг, поєдинки та перемоги спортсменів що заявлені у декілька классів
const result = data.reduce((acc, item) => {
  const existing = acc.find(obj => obj.id === item.id);

  if (existing) {
      // Додаємо числові значення
      existing.battles = (existing.battles || 0) + (item.battles || 0);
      existing.rating = (existing.rating || 0) + (item.rating || 0);
      existing.victory = (existing.victory || 0) + (item.victory || 0);

      // Об'єднуємо `myCompetition`
      const existingCompetition = JSON.parse(existing.myCompetition || "[]");
      const newCompetition = JSON.parse(item.myCompetition || "[]");

      existing.myCompetition = JSON.stringify([...existingCompetition, ...newCompetition]);
  } else {
      // Додаємо новий об'єкт у результат
      acc.push({ ...item });
  }

  return acc;
}, []);
const result2 = result.map((itemResult)=>{
  const profile = state.athletes.filter((atl)=> +atl.id === +itemResult.id)[0]
  return {...itemResult,
    battles:itemResult.battles+Number(profile.battles),
    rating:itemResult.rating+Number(profile.rating),
    victory:itemResult.victory+Number(profile.victory),
      }
})
return result2
}


  const componentMap = {
    1: Grid2, 2: Grid2, 3: Grid3, 4: Grid4, 5: Grid5, 6: Grid6, 7: Grid7, 8: Grid8, 9: Grid9,
    10: Grid10, 11: Grid11, 12: Grid12, 13: Grid13, 14: Grid14, 15: Grid15, 16: Grid16, 17: Grid17, 18: Grid18,
    19: Grid19, 20: Grid20, 21: Grid21, 22: Grid22, 23: Grid23, 24: Grid24, 25: Grid25, 26: Grid26, 27: Grid27,
    28: Grid28, 29: Grid29, 30: Grid30, 31: Grid31, 32: Grid32, 33: Grid33, 34: Grid34, 35: Grid35, 36: Grid36,
    37: Grid37,38: Grid38,39: Grid39,
  };

  function report() { setGridReport(!gridReport) }
  
  state.gridsForDB = []


  function saveCompetitionResult (){

const result ={
  competitionResult:poomse?competitionPoomsaeResult:state.competitionResult,
  grids:state.gridsResult
}




    // вносимо результати в профіль турниру
    axios 
    .post(state.api.updateCompetitionResult, {
      results:JSON.stringify(result),
      id:competition.id,
      headers: {'Content-Type': 'application/json'}
    })
    .then((response) => {

///////////////////////заносимо результати в архів///////////////////////////////////////
const dateForArchive=`${state.formatDate(competition.dateStart).slice(0, 2)} - ${state.formatDate(competition.dateFinish)}`
let shootingResult
if (typeof competition.shootingResult === 'string'){
  shootingResult = competition.shootingResult
}else[
  shootingResult = JSON.stringify(competition.shootingResult)
]



axios 
.post(state.api.saveResultInArchive, {
   title: competition.title,
   dateOfCompetition: dateForArchive,
  results: JSON.stringify(result),
  location: competition.location,
  secretary:competition.secretary,
  mainJudge:competition.mainJudge,
  poomse:poomse?1:0,
  athletes:competition.athletes,
  shootingResult:shootingResult,
    headers: {'Content-Type': 'application/json'}
})
.then((response) => {
  setSuccessMessage(
    lang[langSwitch].successfullSavedResultsTournamentInDB/*Результати змагань занесено в Базу Даних. Ваш архів буде оновлено після повторної авторизації.*/
    );
})
.catch((error) => {
   console.log(error);
  setErrorMessage(
    lang[langSwitch].serverRequestError/*Помилка запиту до сервера:*/ 
     + error);
});

  
//////////////////////////////////////////////////////////////////
  
      setSuccessMessage(
        lang[langSwitch].successfullSavedResultsTournamentInDB/*Результати змагань занесено в Базу Даних. Ваш архів буде оновлено після повторної авторизації.*/
      );
      const index = state.competition.findIndex((item)=>{return item.id==competition.id})
      state.competition[index].results = JSON.stringify(result);
      indexedDBConnection.saveData ('competition', state.competition)
     

//Заносимо результати в профіль спортсмена

      axios.post(state.api.postCompetitionResultToAthletProfile, { 
             updateData: poomse?resultForAthletesPoomsae() :mergedData(updateData)
               })
      .then(response => { })
      .catch(error => {
        console.error('Помилка Axios-запиту, невдалось зберегти результати турнирув профілі атлетів:', error);
      });

           })
    .catch((error) => {
      const reservResult={
        competition:competition,
        results:result,
        updateData:updateData
      }
      localStorage.setItem('reservResult',JSON.stringify(reservResult))
      setErrorMessage(
        lang[langSwitch].serverRequestError/*Помилка запиту до сервера: */
         + error);
         setSuccessMessage(
          lang[langSwitch].RequestWillBeExecutedInTimeConnectNetwork/*Ваш запит буде виконано у фоновому режимі під час наступного підключення до мережі.*/
         )
    });

  }

  function deleteGridsFromDB() { // видаляємо сітки з БД
    axios
      .post(state.api.gridsCompetition, {
        grids: null,
        id: competition.id
      })
      .then((response) => {
        props.setHandleGridsMakeWeiw(false)
        setSuccessMessage(
          lang[langSwitch].successfullyRemovedGrids/*Сіткі було успішно видалено з Бази Даних*/
          );
       
        state.grids=[]
        state.gridsDB=[]
        state.competition.filter((tourn) => tourn.id === competition.id)[0].grids=null
        indexedDBConnection.saveData ('competition', state.competition)
      })
      .catch((error) => {
        setErrorMessage(
          lang[langSwitch].serverRequestError/*Помилка запиту до сервера: */
           + error);
        console.log(errorMessage)      
      });
  }
 














  return (<>
{props.isOpen?(<>

    <div key={competition.id} 
          className={s.modalGrids} 
         >




 <div className={ s.modalButtons }>
      <div className={s.containerAdmin}>
        <div className={s.itemAdmin1}>
         
            <button type='button' className={s.button} onClick={() => { saveGridsConfirm(); }}>
            {lang[langSwitch].saveGridsInDB /*Зберегти сітки в БД*/}
              </button>
         
       
          <button type='button' className={s.button} onClick={() => {deleteGridsConfirm(); }}>
            {lang[langSwitch].deleteFromDB /* Видалити з БД*/}
            </button>
        
        </div>
        <div className={s.itemAdmin2}>

          <button type='button' className={s.button} onClick={() => { report() }}>
            {!gridReport ? 
            lang[langSwitch].showReport /*Показати звіт */
            : 
            lang[langSwitch].hideReport/*Приховати звіт*/
          }
          </button>

          <a href='#ReportGrids'>
            <button type='button' className={s.button} >
            {lang[langSwitch].backToReport /*Назад до звіту*/}
              </button>
          </a>
        </div>
        <div className={s.itemAdmin3}>
        <button type='button' className={s.button} onClick={() => { saveResultConfirm(); }}>
        {lang[langSwitch].saveResults /*Зберегти результати*/}
              </button>

          <button type='button' className={s.redButton} 
            onClick={()=>{
              props.onClose();
              props.setHandleGridsMakeWeiw(false);
              state.gridsReport=[];
              setGridReport(false);
              }}>
          {lang[langSwitch].exit /*Вийти*/}
            </button>
        </div>
      </div>


      {errorMessage ? (<div className={s.errorMessage} onClick={() => { setErrorMessage(null) }}
          style={{ cursor: 'pointer' }}>
          {errorMessage}
        </div>
        ) : null} 
        {successMessage ? (<div className={s.successMessage} onClick={() => { setSuccessMessage(null) }}
          style={{ cursor: 'pointer' }}>
          {successMessage}
        </div>
        ) : null}
    </div>



























      <div className={s.modalContent} >
        {gridReport ? (<div><ReportGrids /> </div>) : null}

        {Array.isArray(grids)&&grids.length > 0 ? grids.map((grid, index) => {
          const Component = componentMap[grid.numberOfAthletes];
          if (Component) {
            return (<div key={grid.category+index} >
              <Component                
                id={grid.category}
                athletes={grid.athletes} 
                category={grid.category} 
                competition={competition}
                battleNumbers={grid.battleNumbers} 
                cort={grid.cort} 
                saveGrids={props.saveGrids}
                langSwitch={langSwitch} /><br />
            </div>
            )
          }
          return null;
        }) : Array.isArray(state.grids)&&state.grids.length>0?
          state.grids.map((grid, index) => {
            const Component = componentMap[grid.numberOfAthletes];
            if (Component) {
              return (<div key={grid.category+index} >
                <Component
                  id={grid.category}
                  athletes={grid.athletes} 
                  category={grid.category} 
                  competition={competition}
                  battleNumbers={grid.battleNumbers} 
                  cort={grid.cort} 
                  saveGrids={props.saveGrids}  
                  langSwitch={langSwitch}/><br />
              </div>
              )
            }
            return null;
          }):null
        }

        {grids.length === 0 && state.grids.length === 0 ? props.gridsDB.map((grid, index) => {
          const Component = componentMap[grid.numberOfAthletes];
          if (Component) {
            return (<div key={grid.category+index} >
              <Component 
                id={grid.category} 
                category={grid.category}
                competition={competition} 
                battleNumbers={grid.battleNumbers} 
                cort={grid.cort}
                saveGrids={props.saveGrids}  
                gridDB={grid} 
                langSwitch={langSwitch}/><br />
            </div>
            )
          }
          return null;

        })
          : null

        }

      </div>

    </div>

           {/*Виклик Вікна погодження */}
 <ConfirmButtons
          key={messageText}
          isOpen={isConfirmButtonsModalOpen}
          onClose={closeConfirmButtonsModal}
          confirmFunction={confirmFunction}
          messageText={messageText}
          langSwitch={langSwitch}
        /> 
</>):null}

  </>)
}

export default GridsModalWindow