import s from './myCompetition.module.css'
import CompetitionTable from '../calendar/CompetitionTable';
import state from '../../../state/state.js';
import lang from '../../../state/language.js';
import { useState } from 'react';



function MyCompetition({isSmallScreen,langSwitch}) {
    state.messageCount()  //лічильник непрочитаних повідомлень
    const [createMenu,setCreateMenu]=useState(false)
    state.tempNewCompetition=[]
    const tournData = state.competition.filter((comp) => {
      
        return (comp.secretary == state.userProfile.id || comp.creator == state.userProfile.id||comp.mainJudge == state.userProfile.id);
    });
    const tourn = tournData.sort((b, a) => new Date(a.dateStart) - new Date(b.dateStart));

const admin=1

    return (
        <div>
            <div >
                <div className={!isSmallScreen?s.contentTitleItem1:s.center}>
                    <div className={s.titleL}><h1 className={s.h1}>
                    {lang[langSwitch].myEvents} {/* Мої заходи*/} 
                         </h1></div>

                </div>
                <div >
                    {tourn.length>0 ? (
                        <div >                           
                            <CompetitionTable 
                            admin={admin} 
                            tourn={tourn} 
                            isSmallScreen={isSmallScreen}
                            langSwitch={langSwitch}/>
                        </div>
                    ) : ''}



                    <div >


                    </div>

                </div>
            </div>
        </div>
    )
}

export default MyCompetition

