import React, {  useState } from 'react';
import s from '../menu/menu.module.css'
import sAdmin from '../../adminCompetition/adminCompetition.module.css'
import lang from '../../../../state/language';
import MultiImageUploader from './multiImageUploader';
import state from '../../../../state/state';
import TextEditor from '../../myCompetition/textEditor'
import ConfirmButtons from '../../adminCompetition/confirmButtons';
import axios from 'axios';
import indexedDBConnection from '../../../../state/indexedDBConnection';



function EditNews({ langSwitch, isSmallScreen, editNews, setEditStart }) {



  const [compressedFile, setCompressedFile] = useState([]);
  const [newsTitle, setNewsTitle] = useState(editNews.title);
  const [newsText, setNewsText] = useState(editNews.text);
  const [currentPhoto, setCurrentPhoto] = useState(editNews.photos && editNews.photos !== 'null' ? JSON.parse(editNews.photos) : []);
  const [photoForDelete, setPhotoForDelete] = useState([]);
  const [messageText, setMessageText] = useState('');
  const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');


  const imgAdress = []


  const openConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(true); }
  const closeConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(false); }

  function saveNewsConfirm() {
    if (newsTitle && newsText) {

      setMessageText(lang[langSwitch].editNewsTitle + '?'   /*Редагувати новину*/)
      openConfirmButtonsModal()


    } else { setErrorMessage(lang[langSwitch].yourNewsNeedsTitleAndText /*Ваша новина потребує назви та тексту!*/) }
  }

  const confirmFunction = (messageText) => {
    if (messageText === lang[langSwitch].editNewsTitle + '?'   /*Редагувати новину*/
    ) {
      //ВИКЛИК ФУНКЦІЇ Оновлення СТАТТІ

      updateDB()
    }

  }



  function handleNewsTitle(e) { setNewsTitle(e.target.value) }


  function uploadImg() {
    const formData = new FormData();
    // Додаємо фото у FormData
    compressedFile.forEach((file) => {
      formData.append(`photos[]`, file);
    });
    //завантажуєм фото
    axios.post(state.api.uploadNewsImg, formData)
      .then(response => { // Обробка успішного завантаження  
 
        const uploadedFiles = response.data.uploadedFiles || [];
        if (uploadedFiles.length > 0) {
          const globalUrl = uploadedFiles.map((item) => state.imgAdress.articleMsacImg + item);               
                updateNews( [...currentPhoto, ...globalUrl]);  
        }
      })
      .catch(error => {// Обробка помилок
        console.error("Помилка завантаження файлів:", error);
        if (error.response) {
          console.error("Деталі помилки:", error.response.data);
        }
      });
  }

  function updateNews(photoData) {

    axios
      .post(state.api.editNews, {
        title: newsTitle ? newsTitle : editNews.title,
        text: newsText,
        date: editNews.date,
        photos: photoData?JSON.stringify(photoData):JSON.stringify(currentPhoto),
        id: editNews.id,
        headers: { 'Content-Type': 'application/json' }
      })
      .then((response) => {
   
        const index = state.news.findIndex((item) => +item.id === +editNews.id)
        state.news[index] = {
          id: editNews.id,
          title: newsTitle ? newsTitle : editNews.title,
          text: newsText,
          date: editNews.date,
          photos:  photoData?photoData:currentPhoto
        }
        indexedDBConnection.saveData('news', state.news)

        setSuccessMessage(
          lang[langSwitch].successfullSavedInDB /*Внесені зміни успішно збережено в Базі Даних*/
        );
        window.location.href = '/ControlPanel'
      })
      .catch((error) => {
        setErrorMessage(
          lang[langSwitch].serverRequestError/*Помилка запиту до сервера:*/);
        console.error("Помилка завантаження файлів:", error);
        if (error.response) {
          console.error("Деталі помилки:", error.response.data);
        }
      });


  };





  function updateDB() {
    // Якщо є файли для видалення
    if (photoForDelete.length > 0) {
   
      //видалення старого фото
      axios.delete(state.api.deleteNewsImg, {
        data: {
          filesName: photoForDelete
        }
      })
        .then(response => { // Обробка успішного видалення  

              //все видалено,  фото для завантаження немає
              if ((response.data.deletedFiles.length === photoForDelete.length) && compressedFile.length === 0) {
                updateNews()
              }

              //не знайдено  обране(ні) фото для видалення
              if (response.data.notFoundFiles.length > 0) {
                setErrorMessage(lang[langSwitch].filedImgDeleteWarning /*Невдалось знайти на сервері обрані вами файли для видалення...*/)
              }   
              
              //все видалено, є фото для завантаження
              if ((response.data.deletedFiles.length === photoForDelete.length) && compressedFile.length > 0) {
                uploadImg()
              }
        })
        .catch(error => {// Обробка помилок
          console.error("Помилка завантаження файлів:", error);
          if (error.response) {
            console.error("Деталі помилки:", error.response.data);
          }
        });
    }

    //якщо фото тільки для завантаження
    if (photoForDelete.length === 0 && compressedFile.length > 0) {
      uploadImg()
    }

    //якщо оновлення стосується тільки тексту
    if (photoForDelete.length === 0 && compressedFile.length === 0) {
      updateNews()
    }

  }



  return (<div>

<div className={s.titleBox}>
    <div className={s.editButtonItem}>
      <div className={s.h3}>
        {lang[langSwitch].newsHeadline   /*Заголовок новин*/}
      </div>
      <div className={s.newsDate}>
        {state.formatDate(editNews.date) + lang[langSwitch].yearShort/*р.*/}
      </div>

      <input
        type='text'
        className={s.inputNews}
        value={newsTitle}
        onChange={(e) => { handleNewsTitle(e) }}
      />
    </div>
<div className={s.deleteButtonItem} 
      style={{textAlign:'right', paddingRight:'20px'}}>
<button     
    type='button'
    onClick={()=>{setEditStart(false)}}>
  {lang[langSwitch].close /*Закрити*/}
  </button>
  
</div>

</div>

    <div>
      <div className={s.h3}><br/>
        {lang[langSwitch].photos   /*Світлини*/}
      </div>
      <div className={s.typeOfPhotos}>
      {lang[langSwitch].currents /*Поточні*/}
        </div>   
      <div className={s.photoPreview}>
        {currentPhoto.map((file, index) => {

          return (
            <div key={index}>
              <div className={s.imgWriper}>
                <img
                  className={s.deleteImg}
                  src={state.deleteImg}
                  onClick={
                    () => {
                      setCurrentPhoto((prev) => {
                        const result = prev.filter((item) => item !== file)
                        return result
                      })
                    
                      setPhotoForDelete((prev) => { 
                         const parts = file.split('/');                          
                        return [...prev, parts[parts.length - 1]]  // Отримуємо тільки ім'я старих фоток
                      })
                    }}
                />
                <img
                  key={index}
                  src={file}
                  width={200}
                  height={200}
                  style={{ objectFit: 'cover', borderRadius: '8px' }}
                />
              </div>
            </div>
          )
        }
        )}
      </div>
      <div className={s.typeOfPhotos}>
       {lang[langSwitch].new /*Нові*/}
        </div>   
      {successMessage&&(<>
        <br/><br/><div className={sAdmin.successMessage} onClick={()=>{setSuccessMessage('')}}>
                 {successMessage}
        </div><br/>
        </>) }
              {errorMessage ? (<><br /><br /><div className={sAdmin.errorMessage} onClick={() => { setErrorMessage('') }}>
              {errorMessage}</div><br /></>) : ''}
      <MultiImageUploader
        setCompressedFile={setCompressedFile}
        compressedFile={compressedFile}
        numberOfPhoto={(state.numberOfPhotoInNews - currentPhoto.length) > 0 ? state.numberOfPhotoInNews - currentPhoto.length : 0}
        langSwitch={langSwitch}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        

      />
    </div>
    
             
    <div>
      <div className={s.h3}><br/>
        {lang[langSwitch].newsText   /*Текст новини*/}
      </div>

      <TextEditor
        text={newsText}
        isSmallScreen={isSmallScreen}
        langSwitch={langSwitch}
        setResult={setNewsText}
        textButton={lang[langSwitch].editNewsTitle   /*Редагувати новину*/}
        funcForRun={saveNewsConfirm}
      />


    </div>

    <ConfirmButtons
      isOpen={isConfirmButtonsModalOpen}
      onClose={closeConfirmButtonsModal}
      confirmFunction={confirmFunction}
      messageText={messageText}
      langSwitch={langSwitch}
    />

  </div>);
};

export default EditNews;













