import React, { useEffect, useState } from 'react';
import s from '../menu/menu.module.css'
import sAdmin from '../../adminCompetition/adminCompetition.module.css'
import lang from '../../../../state/language';
import MultiImageUploader from './multiImageUploader';
import state from '../../../../state/state';
import TextEditor from '../../myCompetition/textEditor'
import ConfirmButtons from '../../adminCompetition/confirmButtons';
import axios from 'axios';
import indexedDBConnection from '../../../../state/indexedDBConnection';



function AddNews({ langSwitch, isSmallScreen, currentText, currentTitle, id, dateOfNews }) {

  const [compressedFile, setCompressedFile] = useState([]);
  const [newsTitle, setNewsTitle] = useState(currentTitle);
  const [newsText, setNewsText] = useState(currentText);
  const [messageText, setMessageText] = useState('');
  const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');


  const imgAdress = []

  const openConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(true); }
  const closeConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(false); }

   function saveNewsConfirm() {  
      if(newsTitle&&newsText){
            if(id){

            }else{
              setMessageText(lang[langSwitch].createNewArticle /*Створити нову статтю?*/)
                openConfirmButtonsModal()
            }

      }else{setErrorMessage(lang[langSwitch].yourNewsNeedsTitleAndText /*Ваша новина потребує назви та тексту!*/ )}
   }

  const confirmFunction = (messageText) => {
    if (messageText === lang[langSwitch].createNewArticle /*Створити нову статтю?*/
      ) {
    //ВИКЛИК ФУНКЦІЇ СТВОРЕННЯ СТАТТІ
    uploadNews()

    }

  }

  function handleNewsTitle(e) { setNewsTitle(e.target.value) }


  //завантаження на фото сервер

      function uploadNews  ()  {
        const formData = new FormData();
        formData.append("title", newsTitle);
        formData.append("text", newsText);
        formData.append("date", state.lastChangesDateFormat());
        formData.append("uploadDir", "../public/img/articleMSAC/"); // Передаємо шлях для завантаження
        formData.append("targetDirectory", state.imgAdress.articleMsacImg ); 
    
        // Додаємо фото у FormData
        compressedFile.forEach((file) => {
          formData.append(`photos[]`, file);
        });
    
        axios.post(state.api.uploadArticleMsacImg,formData,
                  {headers: { "Content-Type": "multipart/form-data" },
                    timeout: 20000 // 20 секунд (повільний інтернет)
                  }
                )
              .then((response)=>{
                    if(response.data.newsArticle){
                    state.news=[...response.data.newsArticle]
                    indexedDBConnection.saveData('news', state.news)
                  } 
                    
                     window.location.reload(true);  //при перезавантаженні дані беруться з кешу                  

                })
              .catch((error)=>{
                setErrorMessage(lang[langSwitch].errorOccurredWhileSavingImage + ': ' /*Помилка під час збереження зображення*/, error.response?.data?.message || error.message)
 
              })
            
        };
  


      
  return (<div>
    <div className={s.h1}>
      {lang[langSwitch].addNewsTitle /* Додати новину*/}
    </div>

    <div>
      <div className={s.h3}>
        {lang[langSwitch].newsHeadline   /*Заголовок новин*/}
      </div>
      <div className={s.newsDate}>
        {state.formatDate(state.lastChangesDateFormat()) + lang[langSwitch].yearShort/*р.*/}
      </div>

      <input
        type='text'
        className={s.inputNews}
        placeholder={newsTitle}
        onChange={(e) => { handleNewsTitle(e) }}
      />
    </div>
    <div>
      <div className={s.h3}>
        {lang[langSwitch].photos   /*Світлини*/}
      </div>
      {errorMessage ? (<><br /><br /><div className={sAdmin.errorMessage} onClick={() => { setErrorMessage('') }}>
      {errorMessage}</div><br /></>) : ''}
      <MultiImageUploader
        setCompressedFile={setCompressedFile}
        compressedFile={compressedFile}
        numberOfPhoto={state.numberOfPhotoInNews}
        langSwitch={langSwitch}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage} 

      />
    </div>
    <div>
      <div className={s.h3}>
        {lang[langSwitch].newsText   /*Текст новини*/}
      </div>

      <TextEditor
        // text={compForAdmin.reglament} 
        isSmallScreen={isSmallScreen}
        langSwitch={langSwitch}
        setResult={setNewsText}
        textButton={lang[langSwitch].addArticle /*Додати статтю*/}
        funcForRun={saveNewsConfirm}
      />


    </div>

 <ConfirmButtons
        isOpen={isConfirmButtonsModalOpen}
        onClose={closeConfirmButtonsModal}
        confirmFunction={confirmFunction}
        messageText={messageText}
        langSwitch={langSwitch}
      />

  </div>);
};

export default AddNews;