
import state from '../../../state/state.js'
import sAdmin from '../adminCompetition/adminCompetition.module.css'

import lang from '../../../state/language.js'


function MyResultOnCompetitiion({ isOpen, onClose, myCompetition,isSmallScreen,name,langSwitch}){

    return(<>
     {isOpen?(<>
      <div className={sAdmin.modalWriper1}>
    <div className={sAdmin. modal}  contentLabel="athletesCell Modal" >
    <div className={!isSmallScreen?sAdmin.contentTitleItem2:sAdmin.td1}>
    <div className={!isSmallScreen?sAdmin.itemAdmin1:null}>
          
             <p>
             {lang[langSwitch].individualResults}{/*Індивідуальні результати спортсмена*/}: 
              <br/><b> {name}</b></p>
           
                    
                </div>
              <div  className={!isSmallScreen?sAdmin.title3ForEnrtyPrint:null}>
                   <button type='button' onClick={onClose}>
                    {lang[langSwitch].close}{/*Закрити*/} 
                    </button> <br />&nbsp;
                     </div>
             <br />
          </div>
         {myCompetition&&Array.isArray(myCompetition)&&myCompetition.length>0?(<>
        
     <div className={sAdmin.tableForAthleteResult}>
         <table>
<thead>
  <tr>
    <td>{lang[langSwitch].nameOfTournament}{/*Назва турниру*/}</td>
    <td style={{width:'7%'}}>{lang[langSwitch].fights}{/*Бої*/}</td>
    <td  style={{width:'7%'}}>{lang[langSwitch].victory}{/*Перемоги*/}</td>
      <td style={{width:'25%'}}>{lang[langSwitch].place}{/*Місце*/}</td>
  </tr>
</thead>
<tbody>
       {myCompetition.reverse().map((item,index) => (
         <tr 
         className={(index%2)===0?sAdmin.zebra:sAdmin.zebra1} 
         key={item.date+index}
         >
          <td><b>{item.title}</b><br/> 
          {state.formatDate(item.date)}</td>
             
<td> <b> {item.battles}</b></td>
<td><b> {item.victory}</b></td>

{Array.isArray(item.place)?(<>
<td>

{item.place.map((item2)=>{
  return(
  <div className={sAdmin.containerForAthletResult}  key={item.category+index}>
    <div className={sAdmin.itemForAthletResult} >{lang.poomsaeCatUkr(langSwitch,item2.category)}</div>
    <div className={sAdmin.itemForAthletResult}><b>{item2.place}</b></div>
   </div>
 )
})}
</td>
</>):(<>
<td><b> {item.place}</b></td>
</>)}



</tr>
        ))}
</tbody>
     </table>   
  </div>  </>):null   
      }

 </div>
 </div>
</>):null}
    </>)
}

export default MyResultOnCompetitiion