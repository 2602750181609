import React, { useEffect, useState } from 'react';
import s from './adminCompetition.module.css'
import lang from '../../../state/language';
import state from '../../../state/state';

function ShootingPlacesCell ({ itemAthlete, num, setGridAthletes, langSwitch}) {

const [editSwitcher, setEditSwitcher] = useState(false)
const [place, setPlace] = useState(null)

function placeData(e){setPlace(e.target.value.trim())}

function updatePlace() {
  if (place) {
    setGridAthletes((prev) => 
      prev.map((item) => 
        +item.id === +itemAthlete.id ? { ...item, shootingPlace: place } : item
      )
    );
    setGridAthletes((prev) => {
      const result = prev.sort((a, b)=>a.shootingPlace - b.shootingPlace)
      return result
    })
    setEditSwitcher(false);
  }
}

  return (<tr >
    <td>{num}</td>
    <td>{state.athleteName(itemAthlete.id)}</td>
    <td>{itemAthlete.shootingResult}</td>
    <td>
      {!editSwitcher?(<>
      <b>{itemAthlete.shootingPlace} </b>
      <img src={state.editHand}
           className={s.editHand}
           onClick={() => { setEditSwitcher(true) }}
                    />
      </>):(<>
        <form className={s.shootingForm}>
                <input
                    type="text"
                    id="secondName"
                    name="secondName"
                    onChange={placeData}
                    placeholder={itemAthlete.shootingPlace}
                />
        </form>
        <button 
            type='button'
            onClick={() => { updatePlace()  }}
                >
          {lang[langSwitch].change/*Змінити*/}
          </button>

      </>)}              
    </td>
    <td>{itemAthlete.rank} /<br />{itemAthlete.dan}</td>
    <td>{itemAthlete.team}</td>
    <td>{state.regionShort(itemAthlete.region)}</td>
    <td>{itemAthlete.fst}</td>
    <td>{state.myCoach(itemAthlete.coach)}{state.myCoach(itemAthlete.coach2) ? (<> /<br />{state.myCoach(itemAthlete.coach2)}</>) : null}</td>
      </tr>);
};

export default ShootingPlacesCell;