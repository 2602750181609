import React, { useEffect, useState } from "react";

import s from '../adminCompetition/adminCompetition.module.css';
import state from "../../../state/state.js";
import axios from "axios";
import ConfirmButtons from "../adminCompetition/confirmButtons";
import AthleteItem from "./athleteItem";
import lang from "../../../state/language.js";


function AppointJudge({ setAppointOpen, isSmallScreen, langSwitch }) {

  // Створіть стан для зберігання списку вибраних спортсменів
  const [selectedAthletes, setSelectedAthletes] = useState([]);// обрані атлети
  const [errorMessage, setErrorMessage] = useState(''); //повідомлення про помилки та успіх
  const [successMessage, setSuccessMessage] = useState('');
  const [data, setData] = useState('');
  const [messageText, setMessageText] = useState('');
  const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState(false);
  ///////////////////////
  const [visibleAthletes, setVisibleAthletes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const athletesPerPage = 45;
  const [fetching, setFetching] = useState(true);

  function saveResultConfirm() {
    if (selectedAthletes.length > 0) {
      setMessageText(
        lang[langSwitch].judgeStatusQuery //Надати обраним спортсменам статус судді?
      )
      openConfirmButtonsModal()
    } else { setErrorMessage(
      lang[langSwitch].notChosenCandidate //Ви не обрали жодного кандидата! 
      ) }
  }
  const confirmFunction = (messageText) => {
    if (messageText === lang[langSwitch].judgeStatusQuery //Надати обраним спортсменам статус судді?
      ){
      setCoach(selectedAthletes)
    }

  }
  const openConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(true); }
  const closeConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(false); }


  //////////////////////////////////////////////////////////////////////////

  function scrollHandler(e) {
    const scrollHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );

    if (scrollHeight - (window.scrollY + window.innerHeight) < 100) {
      setFetching(true);
    }
  }

  useEffect(() => {
    if (fetching && data.length == 0) {
      const endIndex = currentPage * athletesPerPage;
      const newVisibleAthletes = result.slice(0, endIndex);
      setVisibleAthletes((prevVisibleAthletes) => [...newVisibleAthletes]);


      setCurrentPage(currentPage + 1);
      setFetching(false);
    }
  }, [fetching]);


  useEffect(() => {
    if (data.length == 0) {
      document.addEventListener('scroll', scrollHandler);
      document.addEventListener('touchmove', scrollHandler);
    }
    return function () {
      document.removeEventListener('scroll', scrollHandler);
      document.removeEventListener('touchmove', scrollHandler);
    }
  }, []);

  const notCoahes = state.athletes.filter(athlete => athlete.judge.includes('0'));

  const handleTitleChange = (e) => {
    setData(e.target.value);
    // Якщо дані введені, викликаємо фільтрацію і вивід результатів
    if (e.target.value.trim() !== '') {
      const filteredResults = notCoahes.filter(item => {
        const fullName = `${item.secondName} ${item.personName} ${item.fatherName}`.toLowerCase();
        const searchData = e.target.value.toLowerCase();
        return fullName.includes(searchData);
      });
      setVisibleAthletes(filteredResults);
    } else {
      // Якщо дані не введені, використовуємо весь масив notCoahes
      const endIndex = currentPage * athletesPerPage;
      const newVisibleAthletes = notCoahes.slice(0, endIndex);
      setVisibleAthletes(newVisibleAthletes);
      setCurrentPage(currentPage + 1);
    }
  };

  //   форма пошуку
  let result
  data.length > 0 ?
    result = notCoahes.filter(item => {
      const fullName = `${item.secondName} ${item.personName} ${item.fatherName}`.toLowerCase();
      const searchData = data.toLowerCase();
      return fullName.includes(searchData);
    }) : result = notCoahes

  ///////////////////////////////////////////////////////////


  // Функція для додавання або видалення спортсмена зі списку (плитки) під час заявки
  const toggleAthleteSelection = (athlete) => {
    if (selectedAthletes.includes(athlete.id)) {
      // Спортсмен вже вибраний, видаляємо його зі списку
      setSelectedAthletes(selectedAthletes.filter((id) => id !== athlete.id));
    } else {
      // Спортсмен ще не вибраний, додаємо його до списку
      setSelectedAthletes([...selectedAthletes, athlete.id]);
    }
  };

  function setCoach(selectedAthletes) {
    let updateData = []
    for (let i = 0; i < selectedAthletes.length; i++) {
      updateData.push({
        id: selectedAthletes[i],
        judge: 1
      })
    }

    axios.post(state.api.setJudge, {
      updateData: updateData
    })
      .then(response => {
        // console.log('response: ', response);
        // console.log('response.data: ', response.data);
        setSuccessMessage(
          lang[langSwitch].grantedJudge //Статус судді успішно надано 
        )
        setSelectedAthletes([])

        const updatedAthletes = state.athletes.map((athlete) => {
          // Перевірте, чи id співпадає з id в масиві updateData
          if (updateData.some((updateItem) => updateItem.id == athlete.id)) {
            // Якщо співпадає, оновіть поле judge на '1'
            return { ...athlete, judge: '1' };
          }
          // Якщо id не співпадає, поверніть оригінальний об'єкт
          return athlete;
        });

        state.athletes = updatedAthletes



      })
      .catch(error => {
        // console.error('Помилка Axios-запиту, невдалось оновити статус судді:', error);
        setErrorMessage('Помилка Axios-запиту, невдалось оновити статус судді')
      });


  }



  return (<>
    <div className={s.boxTitile1}><br /><br />
      <div className={s.h1}><b>
        {lang[langSwitch].appointJudge}{/*Призначити суддею*/}
      </b></div>

      {successMessage ?
        (<><br /><br /><div className={s.successMessage} onClick={() => { setSuccessMessage('') }}>
          {successMessage}</div><br /></>) :
        errorMessage ? (<><br /><br /><div className={s.errorMessage} onClick={() => { setErrorMessage('') }}>
          {errorMessage}</div><br /></>) : ''}

      <div className={!isSmallScreen ? s.contentTitleItem1 : s.td1}>
        <div className={s.title1}>
          <label htmlFor="title"><span><b>
            {lang[langSwitch].startTypingName}{/*Почніть вводити призвище та ім'я*/}
          </b></span> </label><br /><br />
          <input onChange={handleTitleChange} type="text" id="title" name="title" />

        </div>
        <div className={s.title3}><br /><br /><i>
        {lang[langSwitch].close}{/*Закрити*/}: 
          </i><b> {selectedAthletes.length} </b><i> 
          {lang[langSwitch].ppl}{/*чол*/}.
            </i></div>

        <div className={s.title7}>
          <div className={!isSmallScreen ? s.switchers : s.switchersSmall}>


            <button className={!isSmallScreen ? s.switch1L : s.switch1LSmall} type='button' onClick={() => { saveResultConfirm(); }}>
            {lang[langSwitch].appointJudge}{/*Призначити суддею*/}
            </button>


            <button className={s.switch6L} type='button' onClick={() => {
              setAppointOpen(0);
              setErrorMessage('');
              setSuccessMessage('');
              setSelectedAthletes([])
            }}>
              {lang[langSwitch].close}{/*Закрити*/}
              </button>

          </div>
        </div>

      </div><br />



      {/* "плитка спортсменів" */}


      <AthleteItem athletes={visibleAthletes}
        toggleAthleteSelection={toggleAthleteSelection}
        selectedAthletes={selectedAthletes} />



      <ConfirmButtons
        isOpen={isConfirmButtonsModalOpen}
        onClose={closeConfirmButtonsModal}
        confirmFunction={confirmFunction}
        messageText={messageText}
        langSwitch={langSwitch}
      />


    </div>

  </>)
}


export default AppointJudge