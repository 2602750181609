import s from '../userProfile/userProfile.module.css'
import state from '../../../state/state.js';
import ChangePasswordForm from '../userProfile/changePasswordForm';
import React, { useEffect, useState } from "react";
import axios from 'axios';
import compressImage from '../photo/compressImage';
import CoachChoice from '../userProfile/coachChoice';
import ConfirmButtons from '../adminCompetition/confirmButtons';
import SportClubChoice from '../userProfile/sportClubChoice';
import { useParams } from 'react-router-dom';
import lang from '../../../state/language.js';
import indexedDBConnection from '../../../state/indexedDBConnection.js';





function EditProfileByCoach(props) {
  
  const langSwitch=props.langSwitch
  state.messageCount()//лічильник непрочитаних повідомлень

  const { id } = useParams();//отримуємо id

  
  const athleteProfileArray=state.athletes.filter((item)=>item.id==id)
  // const athleteProfile=athleteProfileArray[0]
  const [athleteProfile, setAthleteProfile] = useState(athleteProfileArray[0]);

  const [tempAthleteProfile, setTempAthleteProfile] = useState(JSON.parse(JSON.stringify(athleteProfile)));


  const [coachChoiceModalOpen, setCoachChoiceModalOpen] = useState(false);
  const [coachCoach2, setCoachCoach2] = useState(1);
   const [switcher, setSwitcher] = useState(0);
  const [coachForScrin, setCoachForScrin] = useState(tempAthleteProfile.coach);
   const [coachForScrin2, setCoachForScrin2] = useState(tempAthleteProfile.coach2);
   const [sportClubData, setSportClubData] = useState(tempAthleteProfile.sportClub);
   
  const [sportClubChoiceModalOpen, setSportClubChoiceModalOpen] = useState (false);

   // Модальне вікно з тренера

   const openCoachChoiceModal = () => { setCoachChoiceModalOpen(true); }
   const closeCoachChoiceModal = () => { setCoachChoiceModalOpen(false); }


   // Модальне вікно з Тренерами
 const openSportClubChoiceModal = () => {setSportClubChoiceModalOpen(true);}
 const closeSportClubChoiceModal = () => {setSportClubChoiceModalOpen(false);}


  const handleSecondNameChange = (e) => { setTempAthleteProfile((oldData)=>({...oldData,secondName: e.target.value.trim()}))}
  const handlePersonNameChange = (e) => { setTempAthleteProfile((oldData)=>({...oldData,personName: e.target.value.trim()}))}
  const handleFatherNameChange = (e) => { setTempAthleteProfile((oldData)=>({...oldData,fatherName: e.target.value.trim()}))}
  const handleBirthdayChange = (e) => { setTempAthleteProfile((oldData)=>({...oldData,birthday: e.target.value.trim()}))}


if(athleteProfile.status!=="Тренер"&&switcher===0){
setTempAthleteProfile((oldData)=>({...oldData,status: 'Спортсмен'}))
setSwitcher(1)
}

  const sex = [ 
    lang[langSwitch].sexM, //Чоловік
    lang[langSwitch].sexF //Жінка
  ]
  const handleSexChange = (e) => {
    let value = e.target.value;
    if (value === 'Чоловік'||value === 'Male') { value = 'male' }
    else if (value === 'Жінка'||value === 'Female') { value = 'female' }
    setTempAthleteProfile((oldData)=>({...oldData,sex:value.trim()}))
  };


  const handleWeihtChange = (e) => {
     setTempAthleteProfile((oldData)=>({...oldData,weiht: parseFloat(e.target.value.replace(',', '.'))}))
  };

  const handleHeightChange = (e) => {  
    setTempAthleteProfile((oldData)=>({...oldData,height: parseFloat(e.target.value.replace(',', '.'))}))
   };


  // вибираємо спорстменів що не є тренерами
  const notCoach = state.athletes.filter(athlete => athlete.status.includes('Тренер'));

function putCoach1 (value){  setCoachForScrin(value)}
function putCoach2 (value){  setCoachForScrin2(value)}

  const handleRegionChange = (e) => { setTempAthleteProfile((oldData)=>({...oldData,region: e.target.value.trim()}))}
  const region = [...state.region].sort();
  
  const handledDanChange = (e) => { setTempAthleteProfile((oldData)=>({...oldData,dan: e.target.value.trim()}))}
  const dan = state.dan;

  const handleRankChange = (e) => { setTempAthleteProfile((oldData)=>({...oldData,rank: e.target.value.trim()}))}
  const rank = [...state.rank];

  const handleFstChange = (e) => { setTempAthleteProfile((oldData)=>({...oldData,fst: e.target.value.trim()}))}
  const fst = [...state.fst];

  const handleInsuranceCompChange = (e) => { setTempAthleteProfile((oldData)=>({...oldData,insuranceComp: e.target.value.trim()}))}
  const handleInsuranceNumChange = (e) => { setTempAthleteProfile((oldData)=>({...oldData,insuranceNum: e.target.value.trim()}))}
  const handleInsuranceDateChange = (e) => { setTempAthleteProfile((oldData)=>({...oldData,insuranceDate: e.target.value.trim()}))}
   const handleMedicalCertificateDateChange = (e) => { setTempAthleteProfile((oldData)=>({...oldData,medicalCertificateDate: e.target.value.trim()}))}
  const handleMedicalCertificateNumChange = (e) => { setTempAthleteProfile((oldData)=>({...oldData,medicalCertificateNum: e.target.value.trim()}))}
  //обробка фото 
  const [compressedFileURL, setCompressedFileURL] = useState(null);
  const [compressedFile, setCompressedFile] = useState(null);
 
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
     setTempAthleteProfile((oldData)=>({...oldData,photo:  state.imgAdress.userProfilePhoto + selectedFile.name}))
  
    // Викликати функцію стиснення зображення та отримати стиснутий файл
    compressImage(selectedFile, {
      maxSizeMB: 0.3,
      maxWidth: 310,
      maxHeight: 394,
    })
      .then((compressedFile) => {
        console.log('Стигнутий файл:', compressedFile);
        setCompressedFile(compressedFile)
        // Створити URL для стиснутого файлу
        const compressedFileURL = URL.createObjectURL(compressedFile);

        // Встановити URL стиснутого файлу у стані компонента
        setCompressedFileURL(compressedFileURL);
      })
      .catch((error) => {
        console.error('Помилка стиснення файлу:', error);
      });
  };

  // отримуємо назву файлу, для видалення старого фото 

  //завантаження на сервер

  function update() {


    if (compressedFileURL) {
      const formData = new FormData();
      formData.append('image', compressedFile);
      axios.post(state.api.uploadAvatar, formData)
        .then(response => {
          // Отримано відповідь від сервера після завантаження зображення
          console.log('Відповідь від сервера:', response.data);
          
          if (athleteProfile.photo!==null){
//ортимуємо назву старого фото
  const parts = athleteProfile.photo.split('/');
    const photoName = parts[parts.length - 1];
 
 //видалення старого фото
    axios.delete(state.api.deleteProfileAvatar, {
      data: {                
        fileName: photoName
      }
    })
    .then(response => { // Обробка успішного видалення  
    })
    .catch(error => {// Обробка помилок
    });
          }     
          // Після завантаження зображення ви можете виконати PUT-запит для оновлення профілю
          updateProfile();
        })
        .catch(error => {
          console.error('Помилка під час відправлення зображення:', error);
        });
    } else {
      // Якщо `compressedFileURL` не існує, виконуємо тільки PUT-запит для оновлення профілю
     
      updateProfile();
    }
  }
 
  function updateProfile() { 

if(!state.testObjects(athleteProfile,tempAthleteProfile)){
     
  const updateAthleteProfile = async () => {
    try {
      // Відправка даних на сервер
      await axios.put(state.api.updateProfile, {
        id: tempAthleteProfile.id,
        secondName: tempAthleteProfile.secondName,
        personName: tempAthleteProfile.personName,
        fatherName: tempAthleteProfile.fatherName,
        birthday: tempAthleteProfile.birthday,
        status: tempAthleteProfile.status,
        sex: tempAthleteProfile.sex,
        weiht: tempAthleteProfile.weiht,
        height: tempAthleteProfile.height,
        coach: coachForScrin,
        coach2: coachForScrin2,
        sportClub: sportClubData,
        region: tempAthleteProfile.region,
        dan: tempAthleteProfile.dan,
        rank: tempAthleteProfile.rank,
        fst: tempAthleteProfile.fst,
        insuranceComp: tempAthleteProfile.insuranceComp,
        insuranceNum: tempAthleteProfile.insuranceNum,
        insuranceDate: tempAthleteProfile.insuranceDate,
        eMail: tempAthleteProfile.eMail,
        medicalCertificateDate: tempAthleteProfile.medicalCertificateDate,
        medicalCertificateNum: tempAthleteProfile.medicalCertificateNum,
        login: tempAthleteProfile.login,
        pass: tempAthleteProfile.pass,
        photo: tempAthleteProfile.photo
      });
  
      // Оновлюємо дані в state
      const index = state.athletes.findIndex((item) => item.id == tempAthleteProfile.id);
      const a = state.athletes[index];
      const b = {
        id: tempAthleteProfile.id,
        secondName: tempAthleteProfile.secondName,
        personName: tempAthleteProfile.personName,
        fatherName: tempAthleteProfile.fatherName,
        birthday: tempAthleteProfile.birthday,
        status: tempAthleteProfile.status,
        sex: tempAthleteProfile.sex,
        weiht: tempAthleteProfile.weiht,
        height: tempAthleteProfile.height,
        coach: coachForScrin,
        coach2: coachForScrin2,
        sportClub: sportClubData,
        region: tempAthleteProfile.region,
        dan: tempAthleteProfile.dan,
        rank: tempAthleteProfile.rank,
        fst: tempAthleteProfile.fst,
        insuranceComp: tempAthleteProfile.insuranceComp,
        insuranceNum: tempAthleteProfile.insuranceNum,
        insuranceDate: tempAthleteProfile.insuranceDate,
        eMail: tempAthleteProfile.eMail,
        medicalCertificateDate: tempAthleteProfile.medicalCertificateDate,
        medicalCertificateNum: tempAthleteProfile.medicalCertificateNum,
        login: tempAthleteProfile.login,
        pass: tempAthleteProfile.pass,
        photo: tempAthleteProfile.photo
      };
      const forUpdate = { ...a, ...b };
  
      // Оновлюємо state
      state.athletes[index] = forUpdate;
  
      // Зберігаємо в IndexedDB після оновлення state
      await indexedDBConnection.saveData('athletes', state.athletes);
      window.location.href = '/MySportClubs';  // Перехід на іншу сторінку після успішного збереження
    } catch (error) {
      console.error('Помилка:', error);
    }
  };
  
  // Викликаємо функцію
  updateAthleteProfile();
  





    }
  }
 function deleteProfile(){


  axios
  .delete(state.api.deleteUserProfile, {
      params: {
          id: athleteProfile.id,
          fileName: athleteProfile.photo, // Додано передачу імені файлу
      },
      headers: { 'Content-Type': 'application/json' },
  })
  .then((response) => {
    
   const resultDataForUpdate=state.athletes.filter((item)=>item.id!=athleteProfile.id)
   state.athletes=resultDataForUpdate
   indexedDBConnection.deleteData ('athletes', athleteProfile.id)
    window.location.href = '/MySportClubs'
    })
  .catch((error) => {
      // console.error(
      //  'Помилка запиту до сервера:' + error);
  });

 }
 ////////////////////////////////ПРОГРАМНА ЧАСТИНА КНОПОК ПІДТВЕРДЖЕННЯ////////////////////////////////////////////////////////////
 const [messageText, setMessageText] = useState('');
 const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState(false);

  function editResultConfirm(query) {// ФУНКЦІЯ ЯКА ЗАПУСКАЄ ОПИТУВАННЯ
//    встановлений текст над кнопками є трігером, 
//якщо він повертається з компоненти - спрацьовує функція
if(query==='update profile'){
      setMessageText(
        lang[langSwitch].saveChangesInProfileQuery //Зберегти зміни у вашому профілі?
      )  
      openConfirmButtonsModal()
}
if(query==='delete profile'){
  setMessageText(
    lang[langSwitch].deleteProfileQuery //УВАГА ВИДАЛЕННЯ ПРОФІЛЮ Є НЕЗВОРОТНИМ ПРОЦЕСОМ! Ви погоджуєте дію?
  )  
  openConfirmButtonsModal()
}

  }
  const confirmFunction = (messageText) => {
      if (messageText === 
        lang[langSwitch].saveChangesInProfileQuery //Зберегти зміни у вашому профілі?
      ) {
        update() //ФУНКЦІЯ ЯКА ВИКОНУЄТЬСЯ В РАЗІ ПІДТВЕРДЖЕННЯ
      }
 if (messageText === 
  lang[langSwitch].deleteProfileQuery //УВАГА ВИДАЛЕННЯ ПРОФІЛЮ Є НЕЗВОРОТНИМ ПРОЦЕСОМ! Ви погоджуєте дію?
 ) {
        deleteProfile() //ФУНКЦІЯ ЯКА ВИКОНУЄТЬСЯ В РАЗІ ПІДТВЕРДЖЕННЯ
      }
  }
  const openConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(true); }
  const closeConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(false); }
///////////////////////////////////////////////////////////////////////////////////////////////////
  return (<div className={!props.isSmallScreen?s.container:s.containerSmall}>
    <div className={s.header}><div className={s.title}>{state.myCoachFull(athleteProfile.id)} <br/>&nbsp;</div>
</div>
    <div className={s.leftCol}>

      <form className={s.form}>
        <p>
          <label htmlFor="secondName"><span className={s.span}>
          {lang[langSwitch].lastName}{/*Прізвище*/}:
            </span> {athleteProfile.secondName}</label><br />
          <input onChange={handleSecondNameChange} type="text" id="secondName" name="secondName" />
        </p>
        <p>
          <label htmlFor="personName"><span className={s.span}>
          {lang[langSwitch].firstName}{/*Ім'я*/}:
            </span> {athleteProfile.personName}</label><br />
          <input onChange={handlePersonNameChange} type="text" id="personName" name="personName" />
        </p>

        <p>
          <label htmlFor="fatherName"><span className={s.span}>
          {lang[langSwitch].patronymic}{/*По-батькові*/}:  
            </span>{athleteProfile.fatherName}</label><br />
          <input onChange={handleFatherNameChange} type="text" id="fatherName" name="fatherName" />
        </p>
        <p>
          <label htmlFor="birthday"><span className={s.span}>
          {lang[langSwitch].dateOfBirth}{/*Дата народження*/}:
            </span> {state.formatDate(athleteProfile.birthday)} 
          {state.formatDate(athleteProfile.birthday)!==null?
          lang[langSwitch].yearShort /*р.*/
          :''}</label><br />
          <input onChange={handleBirthdayChange} type="date" id="birthday" name="birthday" />
        </p>

        {athleteProfile.sex === null ? (

          <>
            <div>
              <div className={s.smallRed}>
              {lang[langSwitch].warningSex}{/*Будьте уважні! Подальші зміни в полі "Стать" робитимуться тількі службою технічної підтримки по заявці вашого тренера!*/}
              </div><br />
              <label htmlFor="sex"><span className={s.span}>
              {lang[langSwitch].enterYourGender}{/*Вкажіть вашу стать*/}
                </span></label><br />
              <select onChange={handleSexChange} id="sex" name="sex" >
                <option value=""></option>
                {sex.map((sex) => (
                  <option key={sex} value={sex}>
                    {sex}
                  </option>
                ))}
              </select>
            </div>
          </>
        ) : (
          // Код, який відображається, коли athleteProfile.sex є null
          '' // або може бути пустий рядок ('') в залежності від вашого вибору
        )}


        <div>
          <p>
            <label htmlFor="weiht"><span className={s.span}>
            {lang[langSwitch].weight}{/*вага*/}:
              </span> {athleteProfile.weiht} 
              {lang[langSwitch]. kg /*кг.*/}
              </label><br />
            <input  type="text" id="weiht" name="weiht" onChange={handleWeihtChange}/>
          </p>
        </div>

        <div>
          <p>
            <label htmlFor="height"><span className={s.span}>
            {lang[langSwitch].height}{/*Зріст*/}:
              </span> {athleteProfile.height} 
              {lang[langSwitch]. cm /*см.*/}
              </label><br />
            <input
              type="text"
              id="height"
              name="height"

              onChange={handleHeightChange}
            />
          </p>
        </div>

        <p>
          <label htmlFor="region"><span className={s.span}>
          {lang[langSwitch].regionalOffice}{/*Обласний осередок*/}:
            </span> 
            {langSwitch==='ukr'?athleteProfile.region:
            langSwitch==='eng'?lang.regionsEng(athleteProfile.region):null            
            }
            </label><br />
          <select onChange={handleRegionChange} 
           className= {props.isSmallScreen?s.regionSelect:null}
          id="region" name="region" >
            <option value="">
            {lang[langSwitch].chooseRegionalOffice}{/*Оберіть обласний осередок*/}
              </option>
            {region.map((region) => (
              <option key={region} value={region}>
                {region}
              </option>
            ))}
          </select>
        </p>
       
        <p >
          <label htmlFor="club">
            <span className={s.span}>
            {lang[langSwitch].sportsClub}{/*Спортклуб*/}:&nbsp;
              </span> 
            
            {sportClubData?sportClubData: athleteProfile.sportClub}
          </label>
          <br />

         <button type='button' className={s.button} onClick={()=>{openSportClubChoiceModal()}}>Оберіть спортклуб</button>

        </p>


    <p >
          <label htmlFor="coach">
            <span className={s.span}>
            1 {lang[langSwitch].coach}{/*тренер*/}:
              </span> 
            {coachForScrin?state.myCoach(coachForScrin):state.myCoach(athleteProfile.coach)}
          </label>
          <br />

         <button type='button' className={s.button} onClick={()=>{openCoachChoiceModal(); setCoachCoach2(1)}}>
         {lang[langSwitch].chooseCoach}{/*Оберіть тренера*/}
          </button>

        </p>

        <p>
          <label htmlFor="coach2">
            <span className={s.span}>
            2 {lang[langSwitch].coach}{/*тренер*/}:
              </span> 
             {coachForScrin2?state.myCoach(coachForScrin2):state.myCoach(athleteProfile.coach2)}
          </label>
          <br />

          <button type='button' className={s.button} onClick={()=>{openCoachChoiceModal(); setCoachCoach2(2)}}>
          {lang[langSwitch].chooseCoach}{/*Оберіть тренера*/}
            </button>


        </p>


      </form >

    </div>


    <div className={s.rigthCol}>
      <form className={s.form}>



      <p>
          <label htmlFor="dan"><span className={s.span}>
          {lang[langSwitch].qualification}{/*кваліфікація*/}:&nbsp;
            </span> 
{langSwitch==='ukr'?athleteProfile.dan:
  langSwitch==='eng'?lang.danEng(athleteProfile.dan):null}

            </label><br />
          <select onChange={handledDanChange} id="dan" name="dan" >
            <option value=""></option>
            {dan.map((dan) => (
              <option key={dan} value={dan}>
                {dan}
              </option>
            ))}
          </select>
        </p>

        <p>
          <label htmlFor="rank"><span className={s.span}>
          {lang[langSwitch].rank}{/*Розряд*/}:&nbsp;
            </span> 
            {langSwitch==='ukr'?athleteProfile.rank:
  langSwitch==='eng'?lang.rankEng(athleteProfile.rank):null}
            </label><br />
          <select onChange={handleRankChange} id="rank" name="rank" >
            <option value=""></option>
            {rank.map((rank) => (
              <option key={rank} value={rank}>
                {rank}
              </option>
            ))}
          </select>
        </p>

        <p>
          <label htmlFor="fst"><span className={s.span}>
          {lang[langSwitch].PCSS}{/*ФСТ*/}:
            </span> {athleteProfile.fst}</label><br />
          <select onChange={handleFstChange} id="fst" name="fst" >
            <option value=""></option>
            {fst.map((fst) => (
              <option key={fst} value={fst}>
                {fst}
              </option>
            ))}
          </select>
        </p>


        <p>
          <label htmlFor="insuranceComp"><span className={s.span}>
          {lang[langSwitch].insuranceCompany}{/*Страх.комп.*/}:
            </span> {athleteProfile.insuranceComp}</label><br />
          <input onChange={handleInsuranceCompChange} type="text" id="insuranceComp" name="insuranceComp" />
        </p>

        <p>
          <label htmlFor="insuranceNum"><span className={s.span}>
          {lang[langSwitch].numberOfInsurancePolicy}{/*Сер./ном. полісу*/}:
            </span> {athleteProfile.insuranceNum}</label><br />
          <input onChange={handleInsuranceNumChange} type="text" id="insuranceNum" name="insuranceNum" />
        </p>

        <p>
          <label htmlFor="insuranceDate"><span className={s.span}>
          {lang[langSwitch].insuranceDate}{/*Термін дії полісу*/}:
            </span> {state.formatDate(athleteProfile.insuranceDate)}
          {state.formatDate(athleteProfile.insuranceDate)!==null?
          lang[langSwitch]. yearShort /*р.*/
          :''}</label><br />
          <input onChange={handleInsuranceDateChange} type="date" id="insuranceDate" name="insuranceDate" />
        </p>
        <p>
          <label htmlFor="medicalCertificateNum"><span className={s.span}> 
          {lang[langSwitch].numberOfMedicalCertificate}{/*Сер./ном. мед. сертиф.*/}:
            </span> {athleteProfile.medicalCertificateNum}</label><br />
          <input onChange={handleMedicalCertificateNumChange} type="text" id="medicalCertificateNum" name="medicalCertificateNum" />
        </p>
        <p>
          <label htmlFor="medicalCertificateDate"><span className={s.span}>
          {lang[langSwitch].medicalCertificateDate}{/*Термін дії мед. сертиф.*/}:
            </span> {state.formatDate(athleteProfile.medicalCertificateDate)}
          {state.formatDate(athleteProfile.medicalCertificateDate)!==null?
          lang[langSwitch]. yearShort /*р.*/
          :''}</label><br />
          <input onChange={handleMedicalCertificateDateChange} type="date" id="medicalCertificateDate" name="medicalCertificateDate" />
        </p>


        <div>
          <label htmlFor="photo"><span className={s.span}>
          {lang[langSwitch].photo}{/*Фото*/}:
            </span></label><br />
          <div>
            {compressedFileURL && (
              <img src={compressedFileURL} alt="Стигнутий файл" />
            )}<br />
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
            /><br />
          </div>
        </div>

      </form>

    </div>
    <div className={s.footer}>

    <div className={!props.isSmallScreen?s.footerL:null}>
     
        <button type='button' onClick={()=>{editResultConfirm('update profile')}}  style={{ cursor: 'pointer' }}>
        {lang[langSwitch].save}{/*Зберегти*/}
        </button></div>

        <div className={!props.isSmallScreen?s.footerR:null}>
 { props.isSmallScreen?(<><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/></>):null}
 
        <button type='button'className={s.exitButton} onClick={()=>{editResultConfirm('delete profile')}}  style={{ cursor: 'pointer' }}>
        {lang[langSwitch].deleteProfile}{/*Видалити профіль*/}
        </button>
      </div>

    </div>
    <div>  {/*Модальне вікно з вибором  тренера */}
        <CoachChoice 
        isOpen={coachChoiceModalOpen} 
        onClose={closeCoachChoiceModal} 
        notCoach={notCoach}
        putCoach= {coachCoach2==1?putCoach1:putCoach2}
        isSmallScreen={props.isSmallScreen} 
        langSwitch={langSwitch}
         />

<SportClubChoice
           setSportClubData={setSportClubData}
          isOpen={sportClubChoiceModalOpen}
          onClose={closeSportClubChoiceModal}
          langSwitch={langSwitch}
          isSmallScreen={props.isSmallScreen}            
        />  

<ConfirmButtons
            isOpen={isConfirmButtonsModalOpen}
            onClose={closeConfirmButtonsModal}
            confirmFunction={confirmFunction}
          langSwitch={langSwitch}
          messageText={messageText}
        />
      </div>

  </div>)
}
export default EditProfileByCoach