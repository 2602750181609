
import { NavLink } from 'react-router-dom';
import lang from '../../state/language';
import state from '../../state/state';
import s from '../content/results/result.module.css'


function Information({isSmallScreen,langSwitch}){
 
    return(<>
            <br/> <div className={s.h1}><b>
              {lang[langSwitch].welcome}{/* Вітаємо Вас на нашому сайті*/}.
              </b></div>

             <div className={s.h3}>
              {lang[langSwitch].technicalInformation}{/* Тут ви можете отримати інформацію про технічний бік роботи нашої платформи*/}.
              </div><br/>
              
               {/* <NavLink to='/InformationForUsers'> 
            <div className={s.informationForUsersLink}>
              Умови користування платформою GridsTkd.
            </div>
          </NavLink> */}


     <div className={s.youtubeLink}>

{state.youtube.map((item)=>{

  return(
  <div className={s.youtubeItem} key={item}>

  <iframe
  key={item}
  width={isSmallScreen ? '340' : '560'}
  height={isSmallScreen ? '270' : '450'}
  src={`${item}?rel=0&controls=1&showinfo=0&modestbranding=1&enablejsapi=1&origin=${window.location.origin}&widgetid=1`}
  title="YouTube video player"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
  allowFullScreen
></iframe>

     <br/> <br/> 
     </div>
  )
})} &nbsp;
     
      </div>
      
        </>)
}
export default  Information
