import React, { useState } from 'react';
import s from "./forAllUsers.module.css"
import sMenu from '../controlPanel/menu/menu.module.css'
import lang from '../../../state/language';
import state from '../../../state/state';
import axios from 'axios';
import ConfirmButtons from '../adminCompetition/confirmButtons';
import indexedDBConnection from '../../../state/indexedDBConnection';



function LeadersItem ({index,
            isSmallScreen, 
            langSwitch, 
            leader, 
            moderator, 
            setEditStart, 
            setEditLeader}) {

const [messageText, setMessageText] = useState('');
const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState(false);
const openConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(true); }
const closeConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(false); }

function deleteLeaderConfirm() {  
    setMessageText(lang[langSwitch].deleteLeader /*Видалити керівника?*/)
    openConfirmButtonsModal()
   }   

const confirmFunction = (messageText) => {
  if (messageText === lang[langSwitch].deleteLeader /*Видалити керівника?*/
  ) {
    //ВИКЛИК ФУНКЦІЇ 
    deletePhoto()
  }

}
 
function deleteLeader() {
  axios.delete(state.api.deleteLeader, {
    params: {
      id: leader.id,
      // Додано передачу імені файлу
    },
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => {
      const updateLeaders = state.leaders.filter((m) => +m.id !== +leader.id)
      state.leaders = [...updateLeaders]
      indexedDBConnection.saveData('news',   state.leaders)
                          .then((responseDB)=>{ window.location.reload(true); } )
                          .catch((error) => {console.log('error: ', error); });
    })
    .catch((error) => {
      console.log('Помилка запиту до сервера: ' + error);
    });

}

function deletePhoto(){
//ортимуємо назву старого фото
const parts =leader.photos.split('/');
const photoName = parts[parts.length - 1];

      axios.delete(state.api.deleteLeaderPhoto, {
        data: { fileName: photoName}
      })
      .then(response => { // Обробка успішного видалення 
        deleteLeader()
      })
      .catch(error => { console.log('Помилка видалення старого аватару:', error);// Обробка помилок
      });  
}


  return (

    <div className={s.newsItemWriper} key={leader.id + index}>
      <div className={s.newsItemInnerWriper}>
        <div className={s.newsItemBackGround}>
      


<div className={s.leaderBox}>
<div className={s.leaderPhoto}>
  <img src={leader.photos}/>
</div>
 <div>
    <div className={s.leaderName}>{leader.name}</div>
    <div className={s.leaderJobTitle}>{leader.jobTitle}</div>
    <div className={s.leaderDescription}>{leader.description}</div>
</div>    
</div>

        {moderator && (
          <div className={sMenu.editButtonsBox}>
            <div className={sMenu.editButtonItem}>
              <button
                type='button'
                onClick={() => {
                    setEditLeader(leader)
                    setEditStart(true)
                }}>
                {lang[langSwitch].edit /*Редагувати*/}
              </button>
            </div>
            <div className={sMenu.deleteButtonItem}>
              <button
                type='button'
                onClick={() => { deleteLeaderConfirm() }}>
                {lang[langSwitch].deleteFromDB /*Видалити з БД*/}
              </button>
            </div>
          </div>
         
        )}


      </div> </div>
      <ConfirmButtons
      isOpen={isConfirmButtonsModalOpen}
      onClose={closeConfirmButtonsModal}
      confirmFunction={confirmFunction}
      messageText={messageText}
      langSwitch={langSwitch}
    />
    </div>
  );
};

export default LeadersItem;