import { NavLink, useParams } from 'react-router-dom'
import s from './result.module.css'
import state from '../../../state/state.js';
import lang from '../../../state/language.js';
import CompetitionHeder from '../adminCompetition/competitionHeder.js';


function Badge({langSwitch}) {


    const { id } = useParams();//отримуємо id трурниру  

  const competition = state.competition.filter((tourn) => tourn.id == id)[0]; //турнир в массиві
  const poomse=competition.poomse==1?true:false
 
  const sortedCategories= state.tournConstructor.sortAthletesByCategories(competition.athletes)
  
  let poomsaeCategories
  if(poomse&&competition.poomsaeCategories){
  try{poomsaeCategories=JSON.parse(competition.poomsaeCategories)}catch{poomsaeCategories=competition.poomsaeCategories}
  }


 const arrayCat= Object.entries(sortedCategories)

 const arrayForWork = arrayCat.map((item) => {
  return item[1].map((at) => ({ ...at, competitionCategory: item[0] }));
});
 //"вирівнюємо" масив родлячі з вкладених масивів "рівний масив"
let flattenedArray = [].concat(...arrayForWork);
 
  const uniqueCoachIds = new Set();
  
    // Перебираємо всіх тренерів для кожного спортсмена
    for (let j = 0; j < flattenedArray.length; j++) {
      // Отримуємо ідентифікатори тренерів для поточного спортсмена
      const coach = flattenedArray[j].coach; 
      const coach2 = flattenedArray[j].coach2;    
      // Додаємо ідентифікатори тренерів до об'єкту Set, щоб зберігати унікальні значення      
       if(coach){uniqueCoachIds.add(coach)}
       if(coach2){uniqueCoachIds.add(coach2)}        
    }
    
  // Перетворюємо Set на масив, щоб отримати список унікальних ідентифікаторів тренерів
  const uniqueCoaches = Array.from(uniqueCoachIds);

  // Перебираємо унікальні ідентифікатори тренерів для формування mailData
  uniqueCoaches.forEach(coachId => {
    // Отримуємо дані тренера за його ідентифікатором та додаємо до загального масиву
    flattenedArray.push(state.athletes.find(athlete => athlete.id == coachId))
  })

  // Сортуємо за полем sportClub
  flattenedArray.sort((a, b) => (a.sportClub > b.sportClub) ? 1 : -1);
    if(poomse){
  flattenedArray=flattenedArray.map((item)=>{
    item.categoryPoomsae=[]
    poomsaeCategories.forEach((poomsaeItem)=>{
      poomsaeItem.athletes.forEach((athletesItem)=>{
        if(Array.isArray(athletesItem)){
          athletesItem.forEach((teameAthlete)=>{
            if(item.id==teameAthlete.id){item.categoryPoomsae.push(poomsaeItem.category)}
          })
        }else{
          if(item.id==athletesItem.id){item.categoryPoomsae.push(poomsaeItem.category)}
        }
      })
    })

    return item 
  })
}


    return(<>
  
  <div className={s.resultTitle}>
  <CompetitionHeder 
    logo={competition.logo} 
    title={competition.title}
    notes={lang[langSwitch].participantBadges /*Бейджи учасників*/}
    />
    
  
    <div className={s.containerBadgeTop}>
<div className={s.itemResultsFooter1}>
<button type='button'  onClick={()=>{window.print()}}>
{lang[langSwitch].print /*Друкувати*/}
  </button>
</div>

<div className={s.itemResultsFooter2}>
 <NavLink to={`/AdminCompetition/${competition.id}`}> 
        <button  type='button'  className={s.redButton}>
        {lang[langSwitch].comeBack /*Повернутись*/}  
          </button>
        </NavLink>
</div>
</div></div>
  <div className={s.wripperBadge}>

    {flattenedArray.map((item)=>{
   
        return(<>
<div className={s.boxForBadge} key={item.id}>
 <img style={{width:'600px',borderRadius:'10px'}}
 src={competition.logo?competition.logo:state.noLogoPhoto}/>


<div className={s.title}>  {competition.title}</div>
<div className={s.date}> {state.formatDate(competition.dateStart).slice(0, 2)}- 
       {state.formatDate(competition.dateFinish)} р.</div>

<div className={s.photo}>
  <img  src={item.photo?item.photo:state.noAvatarPhoto} 
  alt="Аватар Атлета" />
</div>       

<div className={s.nameForBadge}>  {state.myCoachFull(item.id)}</div>

{item.status==='Тренер'?(<>
<div className={s.clubForBadge}>  {item.sportClub}</div>
<div className={s.statusForBadge}>Тренер</div>
<div className={s.regionForBadge}>  {item.region}</div>

</>):(<>
<div  className={s.clubForBadge}>  {item.team}</div>

<div className={s.coachForBadge}>тр.: &nbsp;{item.coach?state.myCoach(item.coach):null} 
{item.coach2?(<>&nbsp;/ {state.myCoach(item.coach2)}</>):null}</div>

{poomse?(<>
<div className={s.waigthForBadgePoomsae}> 
  {item.categoryPoomsae.map((item2)=>{
    return(<>{lang.poomsaeCatUkr(langSwitch,item2)}<br/> </>)
  })}
   </div>
</>):(<>
<div className={s.categoryForBadge}>  {state.weightCatUkr(item.competitionCategory)}</div>
<div className={s.waigthForBadge}> Фактична вага: </div>
</>)}



  <div className={s.regionForBadge}>  {item.region}</div>
</>)}


 </div>
</>)})}


   </div>
    </>)
}

export default Badge