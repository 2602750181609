
import state from '../../state/state.js';
import { Routes,Route } from 'react-router-dom';

import MyCompetition from './myCompetition/myCompetition';
import UserProfile from './userProfile/userProfile';
import EditProfile from './userProfile/editProfile';
import AddCompetition from './myCompetition/addCompetition';
import Mail from './mail/mail';
import Calendar from './calendar/calendar';
import EditCompetition from './myCompetition/editCompetition';
import EntryPrint from './adminCompetition/print/entryPrint';
import AdminCompetition from './adminCompetition/adminCompetition';
import Grids from './adminCompetition/grids';
import RandomWeigh from './adminCompetition/randomWeigh';
import Competition from './competition/competition';
import Results from './results/results';
import MySportClubs from './mySportClubs/mySportClubs';
import CreateSportClub from './mySportClubs/createSportClub';
import EditSportClub from './mySportClubs/editSportClub';
import Archive from './archive/archive';
import ArchiveResults from './archive/archiveResults';
import EditProfileByCoach from './mySportClubs/editProfileByCoach';
import FrameOfGlory from './frameOfGlory';
import Protocols from './results/protocols';
import Badge from './results/badge';
import EslePrint from './results/eslePrint';
import Information from './Information.js';
import FoodTable from './adminCompetition/food/foodTable.js';
import RulesKeirugue from './documents/rulesKeirugue.js';
import Documents from './documents/documents.js';
import Mouthguard from './documents/Mouthguard.js';
import Poomse from './documents/Poomse.js';
import ProgramWT from './documents/programWT';
import RulesPoomsae from './documents/RulesPoomsae.js';
import AddPoomsaeCompetition from './myCompetition/AddPoomsaeCompetition.js';
import RandomPoomsae from './results/esleForPrint/randomPoomsae.js';
import PoomsaeEntryPrint from './adminCompetition/print/poomsaeEntryPrint/poomsaeEntryPrint.js';
import InformationForUsers from './informationForUsers.js';
import PoomsaeCutOffSystemGrids from './adminCompetition/adminPoomsae/poomsaeCutOffSystemGrids.js';
import CommunityRegions from './community/communityRegions.js';
import CommunitySportClubs from './community/communitySportClubs.js';
import CommunityCoaches from './community/communityCoaches.js';
import CommunityAthletes from './community/communityAthletes.js';
import SportsHalls from './sportHalls/SportsHalls.js';
import Menu from './controlPanel/menu/menu.js';
import NewsPage from './forAllUsers/NewsPage.js';
import CalendarForAllUsers from './calendar/calendarForAllUsers.js';
import Leaders from './forAllUsers/leaders.js';
import PhotoContent from './forAllUsers/mediaContent/photoContent.js';
import VideoContent from './forAllUsers/mediaContent/videoContent.js';



 





function Content ({isSmallScreen,
                    langSwitch,
                    remoteControl,
                    remotePoomsaeMonitor, 
                    }){

 
    return (<div >
 
        <Routes>
           <Route path="/editProfile" element={<EditProfile formatDate={state.formatDate} isSmallScreen={isSmallScreen} langSwitch={langSwitch}/>} ></Route>
            <Route path="/userProfile" element={<UserProfile isSmallScreen={isSmallScreen} langSwitch={langSwitch}/>} ></Route>
             <Route path="/myCompetition" element={<MyCompetition isSmallScreen={isSmallScreen} langSwitch={langSwitch}/>} ></Route>
            <Route path="/addCompetition"  element={<AddCompetition isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/>
            <Route path="/AddPoomsaeCompetition"  element={<AddPoomsaeCompetition isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/>
           <Route path="/mail"  element={<Mail isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/>
            <Route path="/calendar"  element={<Calendar isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/>
            <Route path="/competition/:id"  element={<Competition isSmallScreen={isSmallScreen} langSwitch={langSwitch} remoteControl={remoteControl} remotePoomsaeMonitor={remotePoomsaeMonitor}/> }/>  {/*передаємо  id через роути */}
            <Route path="/editCompetition/:id"  element={<EditCompetition isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/>
            <Route path="/entryPrint/:id"  element={<EntryPrint isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/>
            <Route path="/grids/:id"  element={<Grids isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/>
            <Route path="PoomsaeCutOffSystemGrids/:id"  element={<PoomsaeCutOffSystemGrids isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/>
            <Route path="/AdminCompetition/:id"  element={<AdminCompetition isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/>
            <Route path="/randomWeigh/:id"  element={<RandomWeigh isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/>
            <Route path="/protocols/:id"  element={<Protocols isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/>
            <Route path="/results/:id"  element={<Results isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/>
            <Route path="/mySportClubs"  element={<MySportClubs isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/>
            <Route path="/CreateSportClub"  element={<CreateSportClub isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/>
            <Route path="/EditSportClub/:id"  element={<EditSportClub isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/>
            <Route path="/archive"  element={<Archive isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/>
            <Route path="/archiveResults/:id"  element={<ArchiveResults isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/>
            <Route path="/editProfileByCoach/:id"  element={<EditProfileByCoach isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/>
            <Route path="/FrameOfGlory"  element={<FrameOfGlory isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/>
            <Route path="/Badge/:id"  element={<Badge isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/>
            <Route path="/EslePrint/:id"  element={<EslePrint isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/>
             <Route path="/FoodTable/:id"  element={<FoodTable isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/>
           <Route path="/RandomPoomsae/:id"  element={<RandomPoomsae isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/>
           <Route path=""  element={<SportsHalls isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/>
            <Route path="/Information"  element={<Information isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/> 
            <Route path="/RulesKeirugue"  element={<RulesKeirugue isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/> 
            <Route path="/RulesPoomsae"  element={<RulesPoomsae isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/> 
            <Route path="/Documents"  element={<Documents isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/>
             <Route path="/Mouthguard"  element={<Mouthguard isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/>
             <Route path="/Poomse"  element={<Poomse isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/>
            <Route path="/ProgramWT"  element={<ProgramWT isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/>
             <Route path="/PoomsaeEntryPrint/:id"  element={<PoomsaeEntryPrint isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/>
            <Route path="/InformationForUsers"  element={<InformationForUsers isSmallScreen={isSmallScreen} langSwitch={langSwitch}/> }/>
           <Route path="/CommunityRegions"  element={<CommunityRegions isSmallScreen={isSmallScreen} langSwitch={langSwitch}/>}/>
           <Route path="/CommunitySportClubs"  element={<CommunitySportClubs isSmallScreen={isSmallScreen} langSwitch={langSwitch}/>}/>
           <Route path="/CommunityCoaches"  element={<CommunityCoaches isSmallScreen={isSmallScreen} langSwitch={langSwitch}/>}/>
           <Route path="/CommunityAthletes"  element={<CommunityAthletes isSmallScreen={isSmallScreen} langSwitch={langSwitch}/>}/>
           <Route path="/SportsHalls"  element={<SportsHalls isSmallScreen={isSmallScreen} langSwitch={langSwitch}/>}/>
           <Route path="/ControlPanel"  element={<Menu isSmallScreen={isSmallScreen} langSwitch={langSwitch}/>}/>
            <Route path="/News"  element={<NewsPage isSmallScreen={isSmallScreen} langSwitch={langSwitch}/>}/>
          <Route path="/CalendarForAllUsers"  element={<CalendarForAllUsers isSmallScreen={isSmallScreen} langSwitch={langSwitch}/>}/>
          <Route path="/leaders"  element={<Leaders isSmallScreen={isSmallScreen} langSwitch={langSwitch}/>}/>
          <Route path="/PhotoContent"  element={<PhotoContent isSmallScreen={isSmallScreen} langSwitch={langSwitch}/>}/>
         <Route path="/VideoContent"  element={<VideoContent isSmallScreen={isSmallScreen} langSwitch={langSwitch}/>}/>
         
        </Routes>
        
      </div>
    )
}

export default Content