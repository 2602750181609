
import s from './result.module.css'
import state from '../../../state/state.js';
import lang from '../../../state/language.js';





function ResultByCategoryesPoomsae({ competition, updateArchive, setDataForDB, grids, langSwitch }) {

  const resultData = JSON.parse(competition.results).competitionResult
  
  grids = JSON.parse(competition.results).grids
 
  const categoryesData = []

  grids.forEach((gridsItem) => {
    const result = { category: null, athletes: [] }
    result.category=gridsItem.category
      gridsItem.athleteList.forEach((athleteListItem)=>{        
       if(athleteListItem.teamPoomsae){
         const team=[]
            athleteListItem.teamPoomsae.forEach((teamPoomsaeItem)=>{             
              resultData.forEach((resultDataItem)=>{
                resultDataItem.poomsaeResult.place.forEach((placeItem)=>{
                  if(placeItem.category===gridsItem.category&&resultDataItem.id==teamPoomsaeItem.id){
                    team.push({...teamPoomsaeItem,competitionPlace:placeItem.place})                      
                  }
                })               
              })               
            })
            result.athletes.push(team)
       }else{     
          resultData.forEach((resultDataItem)=>{
            resultDataItem.poomsaeResult.place.forEach((placeItem)=>{
              if(placeItem.category===gridsItem.category&&resultDataItem.id==athleteListItem.id){
                result.athletes.push({...athleteListItem,competitionPlace:placeItem.place})                      
              }
            })           
          })                   
       }
      })  
    categoryesData.push(result)
  })

  const sortedCategoryesData = categoryesData.map(category => {
    category.athletes.sort((a, b) => {
        const getSortValue = (athlete) => {
            const place = Array.isArray(athlete) ? athlete[0].competitionPlace : athlete.competitionPlace;
            const match = place.toString().match(/^\d+/);
            return match ? parseInt(match[0], 10) : Infinity;
        };

        const valueA = getSortValue(a);
        const valueB = getSortValue(b);

        return valueA - valueB;
    });

    return category;
});


let count=0
///////////////////////////////////////
  return (<>

    <div className={s.mainDivForPrint}>
      <table className={s.mailTableResultByAthlets}>{/* початок зовнішньої таблиці */}
        <thead>
          <tr>
            <td colSpan='8'>

              <div className={s.headerTitle}>
                <div className={s.competitionTitle}>{competition.title}</div>
                <div className={s.competitionLocation}>{competition.location}</div>

                <div >
                  {competition.dateStart && competition.dateFinish ? (<>
                    <u><b>{state.formatDate(competition.dateStart).slice(0, 2)}</b>-
                      <b>{state.formatDate(competition.dateFinish)} р.</b></u>
                  </>) : (<>
                    <u><b>  {competition.dateOfCompetition}р.</b></u>
                  </>)}
                  <br /> <br />
                  <div className={s.textTitle}>Результати індивідуальних виступів спортсменів</div>

                </div>

              </div>
            </td>
          </tr>
          <tr className={s.headerTable}>
            <td>№</td>
            <td>Учасник</td>
            <td>Місце</td>
            <td>Спортивна<br />кваліф.</td>
            <td>Гип/Дан</td>
            <td>ФСТ</td>
            <td>Тренер / <br />Тренер 2</td>
            <td>Осередок / Спортклуб /<br />Команда</td>

          </tr>
        </thead>
        <tbody>
          {sortedCategoryesData.map((category,GrandIndex) => (<>
            <tr>
              <td colspan='8' className={s.category}>
                {lang.poomsaeCatUkr('ukr', category.category)}
              </td>
            </tr>

            {category.athletes.map((item,parentIndex) => { 
  count++;
  if (Array.isArray(item)) {
    return item.map((item2,index) => {
   
      if (index === 0) {
        return (
          <tr key={item2.id+category.category}>
            <td>{count}</td>
            <td>
              <b>{Array.isArray(item2) ? state.poomsaeTeam(item2) : state.myCoachFull(item2.id)}</b>
            </td>
            <td rowSpan={item.length}>
              <b>{Array.isArray(item2) ? item2[0].competitionPlace : item2.competitionPlace}</b>
            </td>
            <td>{item2.rank}</td>
            <td>{item2.dan}</td>
            <td>{item2.fst}</td>
            <td>{state.myCoach(item2.coach)} / <br />{state.myCoach(item2.coach2)}</td>
            <td>
              {state.regionShort(item2.region)}/ <br />
              {item2.sportClub}
              {item2.secondTeam ? (<>/<br />{item2.secondTeam}</>) : null}
            </td>
           
          </tr>
          
        );
      } else {
        return (<>
        <div style={{display:'none'}}>{ count++}</div>
          <tr key={item2.id+category.category}>
            <td>{count}</td>
            <td>
              <b>{Array.isArray(item2) ? state.poomsaeTeam(item2) : state.myCoachFull(item2.id)}</b>
            </td>
            <td>{item2.rank}</td>
            <td>{item2.dan}</td>
            <td>{item2.fst}</td>
            <td>{state.myCoach(item2.coach)} / <br />{state.myCoach(item2.coach2)}</td>
            <td>
              {state.regionShort(item2.region)}/ <br />
              {item2.sportClub}
              {item2.secondTeam ? (<>/<br />{item2.secondTeam}</>) : null}
            </td>
            
          </tr>
          </> );
      }
    });
  } else {
    return (
      <tr key={item.id+category.category}>
        <td>{count}</td>
        <td>
          <b>{Array.isArray(item) ? state.poomsaeTeam(item) : state.myCoachFull(item.id)}</b>
        </td>
        <td>
          <b>{Array.isArray(item) ? item[0].competitionPlace : item.competitionPlace}</b>
        </td>
        <td>{item.rank}</td>
        <td>{item.dan}</td>
        <td>{item.fst}</td>
        <td>{state.myCoach(item.coach)} / <br />{state.myCoach(item.coach2)}</td>
        <td>
          {state.regionShort(item.region)}/ <br />
          {item.sportClub}
          {item.secondTeam ? (<>/<br />{item.secondTeam}</>) : null}
        </td>
      </tr>
    );
  }
})}

          </>))}


        </tbody>
        <tfoot>
          <tr>
            <td colspan='5'>
              <div className={s.foterOfDocument}>
                <br />
                Гол. суддя  &nbsp;&nbsp;<b> {state.myCoach(competition.mainJudge)} </b>
                &nbsp;&nbsp; <span className={s.infoDescription}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  (підпис)
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>  &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;

                &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;м.п.
              </div>


            </td>
            <td colspan='3'>

              <div className={s.foterOfDocument}>
                <br />
                Гол. секретар &nbsp;&nbsp; <b> {state.myCoach(competition.secretary)}</b>
                &nbsp;&nbsp; <span className={s.infoDescription}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  (підпис)
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>  &nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

              </div>
            </td>  </tr>
        </tfoot>
      </table> {/* кінець зовнішньої таблиці */}
      <br /><br />


    </div>
  </>)
}

export default ResultByCategoryesPoomsae