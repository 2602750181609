
import sAdmin from '../adminCompetition/adminCompetition.module.css';
// import s from './SportClubs.module.css'
import { useState } from "react";
import state from "../../../state/state.js";
import { NavLink } from "react-router-dom";
import MyResultOnCompetitiion from "../userProfile/myResultOnCompetitiion.js";
import lang from './../../../state/language';



function AthletesCell({ isOpen, onClose, athletes, isSmallScreen, langSwitch }) {
  const handleTitleChange = (e) => { setData(e.target.value) };
  const [data, setData] = useState('');

  const [athletesModals, setAthletesModals] = useState(Array(athletes.length).fill(false));
  const [athleteData, setAthleteData] = useState(null);
  const [athleteIndex, setAthleteIndex] = useState(null);

function resultWiew(index, atl) {

  setAthleteData(atl)
  setAthleteIndex(index) 
  openMyResultOnCompetitiionModal(index)  
}

  const openMyResultOnCompetitiionModal = (index) => {
    const newAthletesModals = [...athletesModals];
    newAthletesModals[index] = true;
    setAthletesModals(newAthletesModals);
  };

  const closeMyResultOnCompetitiionModal = (index) => {
    const newAthletesModals = [...athletesModals];
    newAthletesModals[index] = false;
    setAthletesModals(newAthletesModals);
  };

  let raitingOfAthlete
  //   форма пошуку
  const athletesData = athletes.filter(athlete => {
    const fullName = `${athlete.secondName} ${athlete.personName}`.toLowerCase();
    const searchData = data.toLowerCase();
     return fullName.includes(searchData);
  });

  athletesData.sort((a, b) => {
    const lastNameComparison = a.secondName.localeCompare(b.secondName);
   if (lastNameComparison !== 0) {
      return lastNameComparison;  // Якщо прізвища відрізняються, сортуємо за прізвищем.
    }           
    return a.personName.localeCompare(b.personName);  // Якщо прізвища однакові, сортуємо за ім'ям.
  });

  return (<>
{isOpen?(<>

<div className={sAdmin.modalWriper}>
    <div className={sAdmin.modal}  contentLabel="athletesCell Modal" >
      <div className={!isSmallScreen ? sAdmin.contentTitleItem2 : sAdmin.td1}>
        <div className={!isSmallScreen ? sAdmin.itemAdmin1 : null}>
          {isSmallScreen ? (<><br /> &nbsp; </>) : null}
          <button type='button' onClick={onClose}>
            {lang[langSwitch].close /*Закрити*/}
          </button>
          {isSmallScreen ? (<><br /> <br />&nbsp; </>) : null}
        </div>



        <div className={!isSmallScreen ? sAdmin.itemAdmin2 : null}>
          <label htmlFor="title"><span><b>
          {lang[langSwitch].startTypingName /* Почніть вводити призвище та ім'я*/}
            </b></span> </label><br />

          {!isSmallScreen ? (<><br /> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</>) : null}
          <input onChange={handleTitleChange} type="text" id="title" name="title" /><br />
        </div>

      </div>
      <div className={sAdmin.gridContainerForCategories}>
        {athletesData.map((athlete, index) => {

          const isCoach = state.userProfile.id == athlete.coach || state.userProfile.id == athlete.coach2

          let imageError
          const categoryObject = state.tournConstructor.sortAthletesByCategories(
            JSON.stringify([{ "id": athlete.id, "entryCoach": athlete.coach, "team": "sportClub" }]), 0)
          const category = state.weightCatUkr(Object.keys(categoryObject)[0])
        
          return (
            <div key={athlete.id}>
              <div className={sAdmin.listForCoach}>

              </div>


              <div className={sAdmin.cell}   >

                <div className={sAdmin.categoryName}>
                  <div>  {state.athleteName(athlete.id)} </div>
                  <div className={sAdmin.athleteId}>  <b>id: {athlete.id}</b> </div>
                  <div className={sAdmin.region}><u>
                    {langSwitch==='ukr'?athlete.region:
                   langSwitch==='eng'? lang.regionsEng(athlete.region):null
                    }
                    </u></div>
                  <div className={sAdmin.region}>
                  {lang[langSwitch].coachShort /*трен.*/}:  <b> {state.myCoach(athlete.coach)}</b>; &nbsp;
                  {lang[langSwitch].coachShort /*трен.*/}2:  <b> {state.myCoach(athlete.coach2)}</b>
                  </div>
                </div>
                <div className={sAdmin.categoryImg} >
                  {isCoach ? (<>
                  <NavLink to={`/editProfileByCoach/${athlete.id}`}><div>
                    <img
                      src={athlete.photo && !imageError ? athlete.photo : state.noAvatarPhoto}
                      onError={() => { imageError = true }}
                      alt={state.myCoach(athlete.id)} /></div>
                  </NavLink>
                  </>) : (<>
                  
                    <img
                      src={athlete.photo && !imageError ? athlete.photo : state.noAvatarPhoto}
                      onError={() => { imageError = true }}
                      alt={state.myCoach(athlete.id)} />
                  </>)}

                </div>

                <div className={sAdmin.categoryData}>
                  <div>
                   {lang[langSwitch].qualificationShort /*кваліф.:*/}
                 <b>  {langSwitch==='ukr'?athlete.dan:
                   langSwitch==='eng'? lang.danEng(athlete.dan):null
                    } 
                    </b>  </div>
                  <div> 
                   {lang[langSwitch].rank /*розряд*/}:
                   <b>  {langSwitch==='ukr'?athlete.rank:
                   langSwitch==='eng'? lang.rankEng(athlete.rank):null
                    } 
                    </b> 
</div>
                  <div> 
                  {lang[langSwitch].height /*зріст*/}:  
                    <b> {athlete.height} </b>
                    {lang[langSwitch].cm /* см.*/} </div>
                  <div> 
                   {lang[langSwitch].weight /*вага*/}: 
                    <b> {athlete.weiht} </b>
                    {lang[langSwitch].kg /*кг. */} 
                    </div>

                  <div>
                    {lang[langSwitch].sex /*стать */}: 
                    <b> {athlete.sex === 'male' ? 
                    lang[langSwitch].sexM /*Чоловік*/
                     : null}
                      {athlete.sex === 'female' ?
                       lang[langSwitch].sexF /*Жінка*/
                       : null}
                      {athlete.sex === null || athlete.sex === undefined ? 
                     lang[langSwitch].notSpecified /*Не вказано*/ 
                      : null}
                    </b>
                  </div>
                  <div>
                   {lang[langSwitch].fights /*Бої*/}: 
                    <b> {athlete.battles}</b> </div>
                  <div>
                  {lang[langSwitch].victory /*Перемоги*/}: 
                    <b> {athlete.victory}</b></div>
                  <div> 
                   {lang[langSwitch].rating /*рейтинг*/}: 
                    <b> {athlete.rating}
                      </b> {lang[langSwitch].points /*балів*/} </div>
                  <div> <b>{category}</b> </div>
                </div>
                <div className={sAdmin.categoryRating}>
                  <button 
                    type='button' 
                    className={sAdmin.buttonOfPersonalResult} 
                    onClick={() => {resultWiew(index, athlete)}}>
             {lang[langSwitch].results /*Pезультати*/}       
                  </button>
                </div>
              </div>

             
            </div>)
        })}  </div>

    </div></div>
    </>):null}
    {athleteData?(<>
             <MyResultOnCompetitiion                  
               name={athleteData.secondName + ' ' + athleteData.personName + ' ' + athleteData.fatherName}
               isOpen={athletesModals[athleteIndex]}
               onClose={() => closeMyResultOnCompetitiionModal(athleteIndex)}
               myCompetition={JSON.parse(athleteData.myCompetition)}
               isSmallScreen={isSmallScreen}
               langSwitch={langSwitch}
             />
             </>):null}
 
  </>)
}
export default AthletesCell