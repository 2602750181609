import React from 'react';
import './index.css';

import reportWebVitals from './reportWebVitals';

import App from './App';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));




root.render(

  // <React.StrictMode>
   <BrowserRouter>
  <App />
         
      </BrowserRouter>
    
  // </React.StrictMode>
);

reportWebVitals();




