import { NavLink } from "react-router-dom";
import state from "../../../../state/state";
import s from '../result.module.css';
import sAdmin from '../../adminCompetition/adminCompetition.module.css'
import lang from "../../../../state/language";
import React from "react";
import axios from "axios";
import { useState } from "react";
import ConfirmButtons from "../../adminCompetition/confirmButtons";

function RandomPoomsaeItemA({ competition, langSwitch, personalData, coupleData, teamData, start, system, isSmallScreen }) {



  // Знаходимо максимальну кількість раундів
  let roundMax = 0, athletes
 try{athletes=JSON.parse(competition.athletes)}catch{athletes=competition.athletes}
  personalData.forEach((item) => { if (item.rounds > roundMax) { roundMax = item.rounds; } });
  coupleData.forEach((item) => { if (item.rounds > roundMax) { roundMax = item.rounds; } });
  teamData.forEach((item) => { if (item.rounds > roundMax) { roundMax = item.rounds; } });

  const roundsArray=['Фінал', 'Півфінал', '1/4 фіналу', '1/8 фіналу', '1/16 фіналу', '1/32 фіналу']

  // Створюємо заголовки таблиці
  const headerItems = []; const mailData = []
  for (let i = 0; i < roundMax; i++) {
    headerItems.push(
      <React.Fragment key={i}>
        <td>{lang[langSwitch].firstPoomsae /* Перше пумсе */}</td>
        <td style={{ textAlign: 'center' }}>{lang[langSwitch].secondPoomsae /* Друге пумсе */}</td>
      </React.Fragment>
    );
  }

  // Функція для заповнення пустих комірок
  const fillEmptyCells = (poomsaeArray) => {
    const filledArray = [...poomsaeArray];
    while (filledArray.length < roundMax) {
      filledArray.unshift(["", ""]);
    }
    return filledArray;
  };
////////////////////////////////////////////////////////////////////////////////////

function listCoaches() {

  // Пустий об'єкт Set для відстеження унікальних ідентифікаторів тренерів
  const uniqueCoachIds = new Set();
      athletes.forEach((item)=>{
         uniqueCoachIds.add(item.coach);
        uniqueCoachIds.add(item.coach2);
      })
  
  // Перетворюємо Set на масив, щоб отримати список унікальних ідентифікаторів тренерів
  const uniqueCoaches = Array.from(uniqueCoachIds);

  // Перебираємо унікальні ідентифікатори тренерів для формування mailData
  uniqueCoaches.forEach(coachId => {
    // Отримуємо дані тренера за його ідентифікатором
    const coachData = state.athletes.find(athlete => athlete.id == coachId);
    
    if (coachData) {
      // Створюємо об'єкт з інформацією про тренера та його поштовою адресою
      const coachObject = {
        id: coachId,
        // userEmail: 'Sergeyburak79@gmail.com',
        userEmail: coachData.eMail,
        subject: `Результат жеребкування обов'язкових пумсе`,
        text: `${coachData.sex === 'male' ? 'Шановний ' : 'Шановнa '} ${coachData.personName} ${coachData.fatherName}, повідомляємо вас про  результати  жеребкування обов'язкових пумсе на турнирі:<br/><b>${competition.title}</b><br/><br/><br/>  `,
        gridsPdfForMale: null
      };

        let textForMessage=''
        if(personalData.length>0){
        personalData.forEach((item)=>{
             textForMessage+=`<b><u>${lang.poomsaeCatUkr(langSwitch,item.category)}</u></b><br/><br/>`
          item.Poomsae.forEach((item2,index)=>{
    
        textForMessage+=`<b>${roundsArray[item.Poomsae.length-index-1]}</b><br/><b>1. </b>${item2[0]}; <b>2. </b>${item2[1]}; <br/><br/>`
        })
      })
        }
         if(coupleData.length>0){
        coupleData.forEach((item)=>{
           textForMessage+=`<b><u>${lang.poomsaeCatUkr(langSwitch,item.category)}</u></b><br/><br/>`
          item.Poomsae.forEach((item2,index)=>{ 
      
         textForMessage+=`<b>${roundsArray[item.Poomsae.length-index-1]}</b><br/><b>1. </b>${item2[0]}; <b>2. </b>${item2[1]}; <br/><br/>`
        })
      })
      }
      if(teamData.length>0){
        teamData.forEach((item)=>{
          textForMessage+=`<b><u>${lang.poomsaeCatUkr(langSwitch,item.category)}</u></b><br/><br/>`
          item.Poomsae.forEach((item2,index)=>{ 
         textForMessage+=`<b>${roundsArray[item.Poomsae.length-index-1]}</b><br/><b>1. </b>${item2[0]}; <b>2. </b>${item2[1]}; <br/><br/>`
        })
      })
      }
        coachObject.text+=textForMessage
        
      // Додаємо об'єкт до масиву mailData
      mailData.push(coachObject);
    }
  });
}
listCoaches()

////////////////////////////////////////////////////////////////////////////////////
  function mail(mailData) {
    setSuccessMessage(
      lang[langSwitch].eMailDistributionGridsStarted/*Розпочато eMail розсилку .*/
      )
    // Отримайте адресу вашого сервера
    const serverURL = state.api.emailSend;

    // Відправте POST-запит з даними
    axios.post(serverURL, { mailData })
      .then(response => {
      
      })
      .catch(error => {

        console.error('Помилка при відправленні POST-запиту:', error);
      });
  }
 ///////////////////////////////////////////////////////////////////////////////////////
// Модальне вікно з кнопками погодження eMail розсилки.
const messageText=
lang[langSwitch].startSendingGridsByEMail /*Розпочати роззсилку турнірних сіток по eMail?*/

const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState (false);
const [successMessage, setSuccessMessage] = useState(null);
const [errorMessage, setErrorMessage] = useState(null);
const confirmFunction = () => { 
  mail(mailData)
 
}
const openConfirmButtonsModal = () => {setIsConfirmButtonsModalOpen(true);}
const closeConfirmButtonsModal = () => {setIsConfirmButtonsModalOpen(false);}
/////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <div className={s.mainDivForPrint}>
        <table className={s.mailTableRandomPoomsae}>
          <thead>
            <tr>
              <td colSpan={roundMax===0?3:roundMax * 2 + 2}>
                <div className={s.headerTitle}>
                  <div className={s.competitionTitle}>{competition.title}</div>
                  <div className={s.competitionLocation}>{competition.location}</div>
                  <div>
                    {competition.dateStart && competition.dateFinish ? (
                      <>
                        <u><b>{state.formatDate(competition.dateStart).slice(0, 2)}</b>-<b>{state.formatDate(competition.dateFinish)} р.</b></u>
                      </>
                    ) : (
                      <>
                        <u><b>{competition.dateOfCompetition} р.</b></u>
                      </>
                    )}
                    <br /><br />
                    <div className={s.textTitle}>
                      {lang[langSwitch].mandatoryPoomsaeInCategories /* Обов'язкові пхумсе в категоріях */}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr className={s.headerTable}>
              <td rowSpan='3' style={{ width: '7%' }}>{lang[langSwitch].program /* Програма */}</td>
              <td rowSpan='3' style={{ width: '21%', textAlign: 'center' }}>{lang[langSwitch].categoryFromBigLetter /* Категорія */}</td>
              <td colSpan={roundMax * 2}>{lang[langSwitch].mandatoryComplex /* Обов'язковий комплекс */}</td>
            </tr>
            <tr className={s.headerTable}>
              {roundMax > 5 ? (<td colSpan='2'>{lang[langSwitch].thirtySecondFinals /* 1/32 фіналу */}</td>) : null}
              {roundMax > 4 ? (<td colSpan='2'>{lang[langSwitch].sixteenthFinals /* 1/16 фіналу */}</td>) : null}
              {roundMax > 3 ? (<td colSpan='2'>{lang[langSwitch].eighthFinals /* 1/8 фіналу */}</td>) : null}
              {roundMax > 2 ? (<td colSpan='2'>{system?
                        lang[langSwitch].qualifyingRound /* Відбірковий тур */
                        :
                        lang[langSwitch].quarterFinals /* 1/4 фіналу */}</td>) : null}
              {roundMax > 1 ? (<td colSpan='2'>{lang[langSwitch].semifinal /* Півфінал */}</td>) : null}
              <td colSpan='2'>{lang[langSwitch].final /* Фінал */}</td>
              
            </tr>
            <tr className={s.headerTable}>
              {headerItems}
            </tr>
          </thead>

          <tbody className={s.tbodyPaddings}>
  {personalData.length > 0 ? (
    <>
      {personalData.map((item, index) => (
        <tr key={index}>
          {index === 0 ? (<td rowSpan={personalData.length}><b>{lang[langSwitch].personal /* Особисті */}</b></td>) : null}
          <td><b>{lang.poomsaeCatUkr(langSwitch, item.category)}</b></td>
          {fillEmptyCells(item.Poomsae).map((item2, idx) => (
            <React.Fragment key={idx}>
              <td>{item2[0]}</td>
              <td>{item2[1]}</td>
            </React.Fragment>
          ))}
        </tr>
      ))}
    </>
  ) : null}
  {coupleData.length > 0 ? (
    <>
      {coupleData.map((item, index) => (
        <tr key={index}>
          {index === 0 ? (<td rowSpan={coupleData.length}><b>{lang[langSwitch].couples /* Парні */}</b></td>) : null}
          <td><b>{lang.poomsaeCatUkr(langSwitch, item.category)}</b></td>
          {fillEmptyCells(item.Poomsae).map((item2, idx) => (
            <React.Fragment key={idx}>
              <td>{item2[0]}</td>
              <td>{item2[1]}</td>
            </React.Fragment>
          ))}
        </tr>
      ))}
    </>
  ) : null}
  {teamData.length > 0 ? (
    <>
      {teamData.map((item, index) => (
        <tr key={index}>
          {index === 0 ? (<td rowSpan={teamData.length}><b>{lang[langSwitch].teamPoomsae /* Командні */}</b></td>) : null}
          <td><b>{lang.poomsaeCatUkr(langSwitch, item.category)}</b></td>
          {fillEmptyCells(item.Poomsae).map((item2, idx) => (
            <React.Fragment key={idx}>
              <td>{item2[0]}</td>
              <td>{item2[1]}</td>
            </React.Fragment>
          ))}
        </tr>
      ))}
    </>
  ) : null}
</tbody>

        </table>
        <table className={s.mailTableRandomPoomsae}>
          <tfoot>
            <tr>
              <td style={{ width: '60%' }}>
                <div className={s.foterOfDocument}>
                  <br />
                  Гол. суддя &nbsp;&nbsp;<b>{state.myCoach(competition.mainJudge)}</b>
                  &nbsp;&nbsp; <span className={s.infoDescription}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    (підпис)
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span> &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                  &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;м.п.
                </div>
              </td>
              <td style={{ width: '40%' }}>
                <div className={s.foterOfDocument}>
                  <br />
                  Гол. секретар &nbsp;&nbsp; <b>{state.myCoach(competition.secretary)}</b>
                  &nbsp;&nbsp; <span className={s.infoDescription}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    (підпис)
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span> &nbsp;&nbsp;
                  &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div className={s.noPrint} >
   <br/>
  {errorMessage ? (<div className={sAdmin.errorMessage} onClick={() => { setErrorMessage(null) }}
          style={{ cursor: 'pointer' }}>
          {errorMessage}
        </div>
        ) : null} 
        {successMessage ? (<div className={sAdmin.successMessage} onClick={() => { setSuccessMessage(null) }}
          style={{ cursor: 'pointer',}}>
          {successMessage}
        </div>
        ) : null}
 </div>
      <div className={!isSmallScreen?s.containerFood:null} style={{ paddingTop: "15px" }}>
        <div className={s.itemResults1}>
          <button className={s.button}
            type="button"
            onClick={() => { start(); }}>
            {lang[langSwitch].generate /* Згенерувати */}
          </button>
        </div>
        <div className={s.itemResults2}>
          <button className={s.button}
            type="button"
            onClick={() => { window.print(); }}>
            {lang[langSwitch].print /* Друкувати */}
          </button>
        </div>
        <div className={s.itemResults3}>
          <button className={s.button}
            type="button"
            onClick={() => { openConfirmButtonsModal()}}>
            {lang[langSwitch].emailNewsletter /* eMail розсилка */}
          </button>
        </div>
        <div className={s.itemResults4}>
          <NavLink to={`/AdminCompetition/${competition.id}`}>
            <button type="button" className={s.redButton1}>
              {lang[langSwitch].close /* Закрити */}
            </button>
          </NavLink>
        </div>
      </div>

       {/*Виклик Вікна погодження */}
 <ConfirmButtons
          isOpen={isConfirmButtonsModalOpen}
          onClose={closeConfirmButtonsModal}
          confirmFunction={confirmFunction}
          messageText={messageText}
          langSwitch={langSwitch}
        /> 
    </>
  );
}

export default RandomPoomsaeItemA;
