import {  useState } from 'react';
import state from '../../../../state/state.js'
import s from './grids.module.css'
import GridFooter from './GridFooter';
import lang from '../../../../state/language.js';


function Grid2(props) {


	const poomse=props.competition.poomse==1?true:false
	const [check, setCheck] = useState(0);

	let athleteInput
if (props.gridDB===null || props.gridDB===undefined){
	const data = state.tournConstructor.sortAthletesInGrid(props.athletes)

//перевіряємо співпадіння команд в першому бою

	  
if(check===0) { 
	state.tournConstructor.checkForDuplicates(data,props.category,poomse, props.langSwitch)
setCheck(1)
}
 
	athleteInput = data.flatMap(item => [item[1], item[2]]);//робимо простий масив з масив з об'ектів
	athleteInput = athleteInput.filter(item => item !== null); //обрізаємо порожні єлементи масиву
	athleteInput.forEach((athlete, index) => { // створюємо ордер для сотрування
		if (athlete) { athlete.order = index }
	});
}
else{ athleteInput = props.gridDB.athleteList}


const[athleteList1,setAthleteList1]=useState(athleteInput)
	const [currentAthlete, setCurrentAthlete] = useState(null)

	
	function dragStartHandler(e, athlete) {
		e.target.className = s.StartHandler;
		setCurrentAthlete(athlete);
	}	
	function dragEndHandler(e) {
		e.target.className = s.EndHandler;
	}	
	function dragOverHandler(e) {
		e.target.className = s.OverHandler;
		e.preventDefault();
	}	

	function dropHandler(e, droppedAthlete) {
		e.target.className = s.divAthleteInGrid;
		e.preventDefault();
	
		if (currentAthlete) {
			setAthleteList1((prevList) => {
				return prevList.map((a) => {
	
					if (a.gridId === droppedAthlete.gridId) {
						const index = state.competitionResult.findIndex(athl => athl.gridId === a.gridId);
						state.competitionResult[index].competitionPlace = currentAthlete.competitionPlace;
						return { ...a, order: currentAthlete.order, competitionPlace: currentAthlete.competitionPlace };
					}
					if (a.gridId === currentAthlete.gridId) {
						const index = state.competitionResult.findIndex(athl => athl.gridId === a.gridId);
						state.competitionResult[index].competitionPlace = droppedAthlete.competitionPlace;
						return { ...a, order: droppedAthlete.order, competitionPlace: droppedAthlete.competitionPlace };
					}
				
					return a;
				});
			});
		}
	}
	
	function sortAthletes(a, b) {
		const getOrder = (item) => item.order
		if (getOrder(a) > getOrder(b)) {
			return 1;
		} else {
			return -1;
		}
	}

	let athleteList //вкладаємо масив у змінну для виводу
	if (currentAthlete === null) { athleteList = athleteList1 }
	else { athleteList = athleteList1.sort(sortAthletes) }
	

	//////////////////////////////////////////////////////////////////////////////

	// проводимо поєдинки
	const [winner, setWinner] = useState(null);
	
	athleteList1.forEach((a) => { //завантажуємо спортсменів в масив результатів змагань
		
		// Перевірка, чи спортсмен вже є в state.competitionResult


		const athleteExists = state.competitionResult.some((existingAthlete) => existingAthlete.gridId === a.gridId);

		if (!athleteExists) {
			a.competitionCategory = props.category;//катег. в якій виступає спортсмен
			a.ratingOnComp = 0 //рейтинг зароблений в поточному турнірі
			a.competitionPlace = '2'  //змінити в кожній сітці
			a.battlesOnComp = 1 //кількість поєдинків на турнирі
			a.victoryOnComp = 0 //кількість перемог на турнирі
			state.competitionResult.push(a);
		}
	});
	function winnerAthlete(athlete, setWinner, rankComp, rankCount, place) {
		const athleteIndex = state.competitionResult.findIndex(athl => athl.gridId === athlete.gridId)
	
		rankComp = parseInt(rankComp)


		let points //бали які спортсмен заробив за поєдинок
		if (rankCount !== null) { points = rankComp * rankCount }
		if (rankCount === null) { points = rankCount }

		state.competitionResult[athleteIndex].ratingOnComp = //редагуємо рейтинг
			state.competitionResult[athleteIndex].ratingOnComp + points


		state.competitionResult[athleteIndex].competitionPlace = place//просуваємся по місцях
		state.competitionResult[athleteIndex].battlesOnComp ++ //редагуємо кількість поєдинків
		//редагуємо кількість перемог, перемог на 1 менше ніж поєдинків (крім 1 місця)
		state.competitionResult[athleteIndex].victoryOnComp = state.competitionResult[athleteIndex].battlesOnComp - 1
		if (points / 4 === rankComp) { //за перше місце повертаемо вилучену перемогу
			
			state.competitionResult[athleteIndex].battlesOnComp--
		}
		setWinner(athlete)
	}


	function handleCancel(athlete, setWinner, rankComp, rankCount) {


		rankComp = parseInt(rankComp)

		const athleteIndex = state.competitionResult.findIndex(athl => athl.gridId === athlete.gridId)
		//редагуємо поєдинки та перемоги
		// state.competitionResult[athleteIndex].battlesOnComp-- в сітці на два спортсмена один бій мінусіється за перемогу
		state.competitionResult[athleteIndex].victoryOnComp--


		state.competitionResult[athleteIndex].ratingOnComp =
			state.competitionResult[athleteIndex].ratingOnComp - (rankCount !== null ? rankComp * rankCount : rankComp)
		const i = parseInt(state.competitionResult[athleteIndex].competitionPlace) + 1 //з 1 місця спускаємся на 2, тому +1
		if (i === 2) { state.competitionResult[athleteIndex].competitionPlace = '2' }
		else if (i === 3) { state.competitionResult[athleteIndex].competitionPlace = '3' }
		else if (i >= 4 && i <= 8) { state.competitionResult[athleteIndex].competitionPlace = '5 - 8' }
		else if (i > 8 && i <= 16) { state.competitionResult[athleteIndex].competitionPlace = '9 - 16' }
		else if (i > 16 && i <= 32) { state.competitionResult[athleteIndex].competitionPlace = '17 - 32' }
		else { }
		setWinner(null)

	}


	if (state.saveGridsFunctionStart) {
		props.saveGrids(
			athleteList.length, athleteList, props.competition.title, props.category, props.cort, props.battleNumbers)
	}
	/////////////////////////////////////////////////////////////////////////////////////
	const selectedIds=athleteList.map((item=>(item.gridId)))
	const winnerAthletesData = state.competitionResult.filter(athlete => selectedIds.includes(athlete.gridId));
	
	 const winnerAthletes=state.sortWinnerAthletes(winnerAthletesData)
	
	////////////////////////////////////////////////////////////////////////////////////////////////
	const [wiew, setWiew] = useState(false)
	const [score1, setScore1] = useState(null)

	const handleScoreChange = (e) => { setScore1(e.target.value) };

	function gridsResultMaker(){
		setWiew(true)
		const battleScores={score1:score1}
		const winners={winner:winner}
		
		const result = {}
        result.numberOfAthletes = athleteList.length;
        result.athleteList = athleteList
        result.competitionTitle = props.competition.title
        result.category = props.category
        result.cort = props.cort
        result.battleNumbers = props.battleNumbers
		result.battleScores = battleScores
		result.winners=winners
		result.winnerAthletes=winnerAthletes
        state.gridsResult.push(result)
	}

	function deleteGridsResult(){
		state.gridsResult=state.gridsResult.filter(item=>item.category!==props.category)
		setWiew(false);setScore1(null)
	}

	//////////////////////////////////////////////////////////////////////////////
	return (<>
		<div className={s.mainDiv}>
			<div className={s.title}>
				<h3>{props.competition.title}</h3>
				<h3 >
  {props.competition.dateStart&&props.competition.dateFinish?(<>
  <u><b>{state.formatDate(props.competition.dateStart).slice(0, 2)}</b>- 
        <b>{state.formatDate(props.competition.dateFinish)} р.</b></u>         
      </>):(<>
    <u><b>  {props.competition.dateOfCompetition}р.</b></u> 
      </>)}
        <br/></h3>
				<h4 id={props.id}>
					{poomse?lang.poomsaeCatUkr(props.langSwitch,props.category):
					state.weightCatUkr (props.category,props.langSwitch, true)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				Корт: {props.cort}</h4>
			</div>
			<table>
				<tbody><tr>
					<td className={s.blueLeft}>
				
							{athleteList[0]?(<>
								
								<div 	onDragStart={(e)=>dragStartHandler(e,athleteList[0])}
									onDragLeave={(e)=>dragEndHandler(e)}
									onDragEnd={(e)=>dragEndHandler(e)}
									onDragOver={(e)=>dragOverHandler(e)}
									onDrop={(e)=>dropHandler(e,athleteList[0])}
									draggable ='true'
									className={s.divAthleteInGrid}>

								<div onClick={winner===null?()=>{winnerAthlete(athleteList[0],setWinner,props.competition.rankComp,4,1)}:null}>								
									
									{ athleteList[0].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[0].teamPoomsae)}<i>{athleteList[0]['team']}</i></>) : (<>{state.athleteName(athleteList[0]['id'])}<br /><i>{athleteList[0]['team']}</i></>) }
								
								</div></div>	
										</>):(<>
									<div className={s.divAthleteInGrid}>	
	
							
							
							</div></>)}
	
								<div className={s.figthLeft} >
									{props.cort}.{props.battleNumbers[0]} 
									</div>
							
					</td>


					<td className={s.nextRed}>

  <div className={s.sportCenter}>
	<div className={s.divAthleteInGrid} 
	onContextMenu={(e)=>{e.preventDefault(); handleCancel(athleteList[0],setWinner,props.competition.rankComp,4)}}>
    {winner !== null ? (<>
	<div><img className={s.goldCup} alt='фото' src = '/img/main/goldCup1.png'/></div>
	{winner.teamPoomsae?state.poomsaeTeam(winner.teamPoomsae):state.athleteNameShort(winner.id)}<br />
	</>) :
	(<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) }

	
	<div className={s.battleScore}>
	{wiew?
	score1&&wiew?score1:null:
	<div className={s.noPrint} style={{textAlign:'center'}}>
	<input onChange={handleScoreChange} type="text" id="title" name="title" />
		</div>}
	</div>

  </div></div>
</td>
</tr>

					<tr>
						<td></td><td className={s.nextBlue}></td>
					</tr>
					<tr>
						<td className={s.redLeft}>
							
						<div className={s.divAthleteInGrid}>

							{/* {athlete[0][2]?state.athleteName(athlete[0][2]['id']):null}<br/> 
							{athlete[0][2]?athlete[0][2]['team']:null} */}


									{athleteList[1]?(<>
								
								<div 	onDragStart={(e)=>dragStartHandler(e,athleteList[1])}
									onDragLeave={(e)=>dragEndHandler(e)}
									onDragEnd={(e)=>dragEndHandler(e)}
									onDragOver={(e)=>dragOverHandler(e)}
									onDrop={(e)=>dropHandler(e,athleteList[1])}
									draggable ='true'
									className={s.divAthleteInGrid}>

								<div onClick={winner===null?()=>{winnerAthlete(athleteList[1],setWinner,props.competition.rankComp,4,1)}:null}>								
									
									{ athleteList[1].teamPoomsae ? (<>{state.poomsaeTeam(athleteList[1].teamPoomsae)}<i>{athleteList[1]['team']}</i></>) : (<>{state.athleteName(athleteList[1]['id'])}<br /><i>{athleteList[1]['team']}</i></>) }
								
								</div></div>	
										</>):(<>
									<div className={s.divAthleteInGrid}>	
							
							</div></>)}

						</div>
						
							</td>
						<td >
							
							</td>
					</tr>
					<tr>
						<td></td>
						<td>
							
						</td>
						</tr>
				</tbody>
			
			</table>

{/* ////////////////////////////////////////////////////// */}
<GridFooter 
	competition={props.competition} 
	wiew={wiew} 
	deleteGridsResult={deleteGridsResult}
	gridsResultMaker={gridsResultMaker} 
	winnerAthletes={winnerAthletes} 
	winner={winner}/>

			
		</div> 
	</>)
}

export default Grid2