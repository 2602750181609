import { NavLink, useParams } from 'react-router-dom';
import s from '../adminCompetition.module.css'
import state from '../../../../state/state';
import { useState } from 'react';
import FullFoodOrder from './fullFoodOrder';
import OrderForProduction from './orderForProduction';
import FoodDistribution from './foodDistribution';
import lang from '../../../../state/language';
import CompetitionHeder from '../competitionHeder';


function FoodTable({isSmallScreen,langSwitch}){
    const { id } = useParams();//отримуємо id

    const[open,isOpen]=useState(null)

    const competitionArray = state.competition.filter((tourn) => tourn.id == id);
    const competition =competitionArray[0]
if(!competition.foodData){return}

let foodData
try{foodData=JSON.parse(competition.foodData)}
catch(error){foodData=competition.foodData}

let foodOptions 
 try{foodOptions=JSON.parse(competition.foodOptions)}
catch(error){foodOptions=competition.foodOptions}


    return(<>
     <div className={s.noPrint}>
     <CompetitionHeder 
    logo={competition.logo} 
    title={competition.title}
    notes={lang[langSwitch].mealsForParticipants /*Харчування учасників*/} 
    />

        
    
<div className={isSmallScreen?s.td1:s.containerAdmin}>
<div className={s.itemAdmin1}>
    <button  onClick={()=>{isOpen(1)}}>
    {lang[langSwitch].generalOrder /*Загальне замовлення*/}
        </button>
</div>
<div className={s.itemAdmin2}>
<button onClick={()=>{isOpen(2)}}>
{lang[langSwitch].productionOrder /*Замовлення на виробництво*/}
    </button>
</div>
<div className={s.itemAdmin3}>
<button onClick={()=>{isOpen(3)}}>
{lang[langSwitch].distributionOrder /* Роздача замовлення*/}
    </button>
</div>
<div className={s.itemAdmin4}>
<NavLink to={`/AdminCompetition/${competition.id}`}>
<button 
className={s.redButton2} 
type='button' >
{lang[langSwitch].exit /*Вийти*/} 
    </button>
                    </NavLink>
                    
</div>
   </div>

</div>


{open===1?
<FullFoodOrder 
isSmallScreen={isSmallScreen}
isOpen={isOpen}
title={competition.title}
foodData={foodData}
foodOptions={foodOptions}
langSwitch={langSwitch}
/>
:null}

{open===2?
<OrderForProduction 
isSmallScreen={isSmallScreen}
isOpen={isOpen}
title={competition.title}
foodData={foodData}
foodOptions={foodOptions}
langSwitch={langSwitch}
/>
:null}

{open===3?
<FoodDistribution 
isSmallScreen={isSmallScreen}
isOpen={isOpen}
title={competition.title}
foodData={foodData}
foodOptions={foodOptions}
langSwitch={langSwitch}
/>
:null}
    </>)
}

export default FoodTable