import React, { useState } from 'react';
import compressImage from '../../photo/compressImage';
import s from '../menu/menu.module.css'

import lang from '../../../../state/language';
import state from '../../../../state/state';

function MultiImageUploader({
    compressedFile, 
    setCompressedFile,
    numberOfPhoto,
    langSwitch,
errorMessage, setErrorMessage
}) {


  const handleFileChange = (event) => {

    let files = Array.from(event.target.files); // Отримуємо файли у масив
    if ((files.length + compressedFile.length) > numberOfPhoto) {
        // Якщо вибрано більше 5 файлів, то обрізаємо список до 5
        setErrorMessage(
            lang[langSwitch].limitPicturesWarning /*Кількість зображень в статті обмежено 5 світлинами.*/
        );
        const number = numberOfPhoto - compressedFile.length
        files = files.slice(0, number>0?number:0); // Обрізаємо список файлів до 5
      }
    files.forEach((item)=>{
        compressImage(item, {   maxSizeMB: 0.3, maxWidth: 800})//Стискаємо фотографії 
        .then((compressedFile) => {
        
          setCompressedFile((prev) => {
            if (!prev.some((item) => item.name === compressedFile.name)) {
              return [...prev, compressedFile];
            }
            return prev; // Повертаємо старий масив, якщо файл уже є
          });
          
        })
        .catch((error) => {
          console.error('Помилка стиснення файлу:', error);
        });
    })
  };
 

 

  return (
    <div>


      <input type="file" multiple accept="image/*" onChange={handleFileChange} />
      
      {/* Відображення вибраних фото */}
      <div className={s.photoPreview}>
        {(compressedFile && Array.isArray(compressedFile) && compressedFile.length>0) 
            && 
        compressedFile.map((file, index) => {
          
          return(
          <div key={index}>
          <div className={s.imgWriper}>
            <img  
              className={s.deleteImg}
              src={state.deleteImg}
              onClick={
                ()=>{setCompressedFile((prev)=>{
                const result = prev.filter((item)=>item.name!==file.name)
                return result
              })}}
            />
          <img 
            key={index} 
            src={URL.createObjectURL(file)} 
            alt={`selected-${index}`} 
            width={200} 
            height={200} 
            style={{ objectFit: 'cover', borderRadius: '8px' }} 
          />
          </div>
          </div>
    )}
    )}
      </div>
    </div>
  );
}

export default MultiImageUploader;
