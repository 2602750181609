
import sAdmin from '../adminCompetition/adminCompetition.module.css';
import state from '../../../state/state';
import { useState } from 'react';
import lang from '../../../state/language';

function AthleteItemForScale({ setUpdateData, athlete,updateData,langSwitch}) {

  const [wiewWeigth, setWiewWeigth] = useState(null)
  const [wiewAge, setWiewAge] = useState(null)

 const item = { id: athlete.id, weiht:  athlete.weiht, birthday: athlete.birthday};

        const handleWeigthChange = (e) => { item.weiht = parseFloat(e.target.value.replace(',', '.'))};

        const handleAgeChange = (e) => {      
          item.birthday  = state.birthdayDateFormat(e.target.value)
        };
       
function abort (id){

       
  const result = updateData.filter(item => item.id !== id);
  
  setUpdateData(result)
 
}

  return (
    <>
     <div className={sAdmin.gridContainerForScaile} key='AthleteItemForScale'>
     
       
         
            <div className={sAdmin.athleteBlock}>
              <img src={athlete.photo?athlete.photo:state.noAvatarPhoto}
              alt={`${athlete.secondName} ${athlete.personName}`} />
              <div className={sAdmin.name}>
                <b>{`${athlete.secondName} ${athlete.personName}`}</b><br />&nbsp;
              </div>

              <div>
                <label htmlFor={`weiht-${athlete.id}`}>
                  <span>
                  {lang[langSwitch].currentWeight}{/*Поточна вага*/}:&nbsp;
                    <b>{wiewWeigth?wiewWeigth:athlete.weiht}
                      {lang[langSwitch].kg}{/*кг.*/}
                      </b>
                  </span>{' '}
                </label>
            
                <br /> 
                  {!wiewWeigth?(<>
                {lang[langSwitch].newWalue}{/*Нове значення*/}:&nbsp;
                <input onChange={handleWeigthChange} 
          
                type="text" id={`weiht-${athlete.id}`} name="weiht" 
                className={sAdmin.scaileForm}/>
              </>):null}
              </div><br />

              <div>
                <label htmlFor={`birthday-${athlete.id}`}>
                  <span>
                  {lang[langSwitch].dateOfBirth}{/*Дата народження*/}: &nbsp;
                    <b>{wiewAge?state.formatDate(wiewAge):state.formatDate(athlete.birthday)}
                    </b>
                  </span>{' '}
                </label>
           
                <br />
                {!wiewAge?(<>
                  {lang[langSwitch].newWalue}{/*Нове значення*/}:  &nbsp;
                  <input onChange={handleAgeChange} 
              
              type="date" id={`birthday-${athlete.id}`} name="birthday" 
              className={sAdmin.birthdayChangeForm}/>
                    </>):null}
              </div>

              <button  type="button"  onClick={() => {
                setUpdateData((prevData) => [...prevData, item]); 
                setWiewWeigth(item.weiht)
                setWiewAge(item.birthday)
              }}  
              className={updateData.some((updateItem) => updateItem.id == athlete.id) ? sAdmin.scaileButt : null}
              >
               {lang[langSwitch].confirm}{/*Погодити*/}
              </button> <br /> &nbsp;

              <button  type="button"  onClick={() => {
                abort(athlete.id) 
                setWiewWeigth(null)
                setWiewAge(null)
            }}  
              className={ sAdmin.scaileButtAbort }
              >
                {lang[langSwitch].cancel}{/*Скасувати*/} 
              </button> <br /> &nbsp;

            </div>
          
     
    </div></>
  );
}

export default AthleteItemForScale;
