import { NavLink } from 'react-router-dom'
import state from '../../../state/state.js'
import s from './SportClubs.module.css'
import sAdmin from '../adminCompetition/adminCompetition.module.css'
import AthletesCell from './athletesCell'
import { useState } from 'react'
import RemoveAthleteFromClub from './removeAthleteFromClub'
import AthleteScale from './athleteScale'
import CoachRequest from './coachRequest'
import lang from '../../../state/language.js'
import AddAthlete from './addAthlete.js'



function SportClubItem(props) {
    const langSwitch = props.langSwitch
    const club = props.club
    const myFullTeam = state.athletes.filter(athlete => athlete.sportClub == club.name)

    const myTeamArray = state.athletes.filter(
        athlete => athlete.sportClub == club.name && athlete.status === 'Спортсмен')

    const myCoachArray = state.athletes.filter(
        athlete => athlete.status === 'Тренер' && athlete.sportClub == club.name)
    const creatorArray = state.athletes.filter(athlete => athlete.id == club.creator)
    const creator = creatorArray[0]
    function stateCopy(id) {
        const sportClubArray = state.sportClubs.filter((club) => club.id == id);
        const sportClub = sportClubArray[0] //отримуємо С\к з масиву          
        state.tempNewSportClub = JSON.parse(JSON.stringify(sportClub))//робимо копію масиву        
    }

    const [deleteSwitcher, setDeleteSwitcher] = useState(1)
    const [switcher, setSwitcher] = useState(1)
    const [removeAthlete, setRemoveAthlete] = useState(false);//видалення спортсменів
    const [scaleTeam, setScaleTeam] = useState(false);
    const [profileTest, setProfileTest] = useState(false);
    const [addAthletteOpen, setAddAthletteOpen] = useState(false);
    // Модальне вікно з переглядом спортсменів
    const [athletesCellModalOpen, setAthletesCellModalOpen] = useState(false);
       
    const closeAthletesCellModal = () => { setAthletesCellModalOpen(false); }

    function mainCoachClubs() {
        const clubDataArray = state.sportClubs.filter((item) => item.creator === club.creator);
        const result = clubDataArray.map((item) => {
            return (<>{item.name}<br /></>)
        })

        return result
    }

    return (<div key={club.id}>

        <div className={s.boxTitile}>
            <div className={s.invisiblePrint}>
                {!props.isSmallScreen ? (<>
                    <div className={s.h1}>
                        <div className={s.center}><b>{club.name}</b><br />
                            <span style={{ fontSize: '25px' }}> <u>м. {club.city}</u></span></div></div>
                </>) : null}
                <div className={!props.isSmallScreen ? s.contaner2 : null}>
                    {!props.isSmallScreen ? (<>
                        <div className={s.item1}>

                            <img className={s.clubLogo} src={club.logo ? club.logo : state.noLogoPhoto}
                                alt={club.name} />  {/*Лого спортклубу */}
                        </div>
                    </>) : null}


                    <div className={s.item2}>
                        {/* ///////////////////////////////////////////////////// */}
                        <div className={s.cell}>{/* блок с фото тренера */}
                            <div className={s.categoryImg}>
                                {!props.isSmallScreen ? (<>
                                    <img src={creator.photo} alt={state.myCoach(creator.id)} />
                                </>) : (<>
                                    <img src={club.logo ? club.logo : state.noLogoPhoto}
                                        alt={club.name} />  {/*Лого спортклубу */}
                                </>)}
                            </div>

                            <div className={s.categoryData}>
                                {!props.isSmallScreen ? (<>
                                    <div className={s.coachName}>{`${creator.secondName} 
                                            ${creator.personName} 
                                             ${creator.fatherName}`}</div>
                                    <div className={s.region}>{
                                        state.isMediumScreen ? state.regionShort(creator.region) : creator.region
                                    }</div>
                                    <div className={s.coachData}>
                                        {lang[langSwitch].qualificationShort}{/*Кваліф.:*/}
                                        <b> {creator.dan} &nbsp;/ &nbsp;{creator.rank}</b></div>
                                    <div className={s.coachData}>
                                        {lang[langSwitch].PCSS}{/*ФСТ*/}:
                                        <b> {creator.fst}</b></div>
                                    <div className={s.box1}>
                                        <div className={`${s.item1} ${s.coachData}`}>

                                            {lang[langSwitch].seniorCoachShort}{/*Ст. тренер*/}:
                                        </div>
                                        <div className={`${s.item2} ${s.coachData}`}>
                                            <b> {creator.sportClub === club.name ? creator.sportClub : mainCoachClubs()}</b></div></div>
                                    <div className={s.coachData}>

                                        {lang[langSwitch].team}{/*Команда*/}:
                                        <b> {myTeamArray.length} </b>
                                        {lang[langSwitch].athleteShort}{/*атлет.*/} &nbsp;&nbsp;&nbsp;
                                        <b> {myCoachArray.length} </b>
                                        {lang[langSwitch].coachShort}{/*трен.*/}
                                    </div>
                                </>) : (<>
                                    <div className={s.coachName}>{club.name}  <br />
                                        <span style={{ fontSize: '13px' }}> <u>
                                            {langSwitch === 'ukr' && (<>м. {club.city}</>)}
                                            {langSwitch === 'eng' && (<>{club.city} сity</>)}
                                        </u></span></div>
                                    <div >
                                        {lang[langSwitch].seniorCoachShort}{/*Ст. тренер*/}:
                                        <b> {state.myCoach(creator.id)}</b></div>
                                    <div >
                                        {lang[langSwitch].qualificationShort}{/*Кваліф.:*/}
                                        <b>{creator.dan} &nbsp;/ &nbsp;{creator.rank}</b></div>
                                    <div >
                                        {lang[langSwitch].PCSS}{/*ФСТ*/}:
                                        <b> {creator.fst}</b></div>
                                    <div>
                                        {lang[langSwitch].team}{/*Команда*/}:
                                        <b> {myTeamArray.length} </b>
                                        {lang[langSwitch].athleteShort}{/*атлет.*/}&nbsp;&nbsp;&nbsp;
                                        <b> {myCoachArray.length} </b>
                                        {lang[langSwitch].coachShort}{/*трен.*/}
                                    </div>

                                </>)}
                            </div>

                        </div>
                    </div> </div>

                {/* //////////////////////////////////////////////////////////////////// */}

                <div className={s.sportClubButtonsContaner}> {/* блок з кнопками під фото тренера */}

                    <div className={s.item1}>{/* ліва колонка кнопок */}

                        {club.creator == state.userProfile.id || (state.userProfile.status === 'Тренер' ? club.name === state.userProfile.sportClub : null) && props.setDataId &&
                            props.setDataFileName && props.deleteResultConfirm ?
                            (<>


                                <button type='button'
                                    className={s.button}

                                    onClick={() => { setScaleTeam(true) }}>
                                    {lang[langSwitch].weighTeam}{/*Зважити команду*/}
                                </button>

                                <button type='button' className={s.button} onClick={() => { setProfileTest(true) }}>
                                    {lang[langSwitch].checkingProfiles}{/*Перевірка профілів*/}
                                </button>

                            </>) : null}

                        {club.creator == state.userProfile.id && props.setDataId &&
                            props.setDataFileName && props.deleteResultConfirm ?
                            (<>
                                <NavLink to={`/EditSportClub/${club.id}`}>
                                    <button type='button' className={s.button}
                                        onClick={() => { stateCopy(club.id) }}
                                    >
                                        {lang[langSwitch].editSportsClub}{/*Редагувати спортклуб*/}
                                    </button>
                                </NavLink> </>) : null}

                    </div>

                    <div className={s.item2}>{/* середня колонка кнопок */}

                        <button type='button' className={s.button}
                            onClick={() => {
                                setAthletesCellModalOpen(true);
                                setSwitcher(2)
                            }}>
                            {lang[langSwitch].coaches}{/*Тренери*/}
                        </button>

                        <button type='button' className={s.button}
                            onClick={() => {
                                setAthletesCellModalOpen(true);
                                setSwitcher(1)
                            }}>
                            {lang[langSwitch].athletes}{/*Спортсмени*/}
                        </button>

                        <button type='button' className={s.button}
                            onClick={() => {
                                setAddAthletteOpen(true)
                            }}>
                            {lang[langSwitch].addAthlete}{/*Додати спортсмена*/}
                        </button>
                    </div>

                    <div className={s.item4}> {/* права колонка кнопок */}



                        {club.creator == state.userProfile.id && props.setDataId &&
                            props.setDataFileName && props.deleteResultConfirm ?
                            (<>
                                <button type='button' className={s.exitButton} onClick={() => {
                                    props.setDataId(club.id)
                                    props.setDataFileName(club.logo)
                                    props.deleteResultConfirm(club.id)
                                }}>
                                    {lang[langSwitch].deleteSportsClubButton}{/*Видалити спортклуб*/}

                                </button>

                                <button type='button' className={s.exitButton}
                                    onClick={() => {
                                        setDeleteSwitcher(2)
                                        setRemoveAthlete(true);
                                    }}>
                                    {lang[langSwitch].removeCoach}{/*Видалити тренера*/}

                                </button>


                                <button type='button' className={s.exitButton}
                                    onClick={() => {
                                        setDeleteSwitcher(1)
                                        setRemoveAthlete(true);
                                    }}>
                                    {lang[langSwitch].removeAthlete}{/*Видалити спортсмена*/}

                                </button>
                            </>) : null}


                    </div>

                </div>

            </div>

            {removeAthlete ? (<>{/*Видалення спортсменів з спортсклубу*/}
             <div className={sAdmin.modalWriper}>
               <div  className={sAdmin.modal} >
                <RemoveAthleteFromClub
                    deleteSwitcher={deleteSwitcher}
                    myTeamArray={deleteSwitcher === 1 ? myTeamArray : myCoachArray}
                    clubName={club.name}
                    setRemoveAthlete={setRemoveAthlete}
                    isSmallScreen={props.isSmallScreen}
                    langSwitch={langSwitch} />
                    </div>   </div> 
            </>) : null}

            {scaleTeam ? (<>{/*Зважування спортсменів з спортсклубу*/}
                <div className={sAdmin.modalWriper}>
               <div  className={sAdmin.modal} >
                <AthleteScale
                    setScaleTeam={setScaleTeam}
                    clubName={club.name}
                    isSmallScreen={props.isSmallScreen}
                    langSwitch={langSwitch} />
                </div>   </div>  
            </>) : null}
            {profileTest ? (<>{/*Перевірка профілів спортсменів*/}
                <div className={sAdmin.modalWriper}>   
                <div  className={sAdmin.modal} >
                <CoachRequest
                    myFullTeam={myFullTeam}
                    clubName={club.name}
                    setProfileTest={setProfileTest}
                    isSmallScreen={props.isSmallScreen}
                    langSwitch={langSwitch} />
                  </div>
             </div>         
            </>) : null}



            {/* модальне вікно з спортсменами для загального огляду */}
            <AthletesCell athletes={switcher === 1 ? myTeamArray : myCoachArray}
                club={club}
                isOpen={athletesCellModalOpen}
                onClose={closeAthletesCellModal}
                myCoachArray={myCoachArray}
                isSmallScreen={props.isSmallScreen}
                langSwitch={langSwitch}
            />

{addAthletteOpen && (
    <AddAthlete 
        langSwitch={langSwitch}
        sportClub={club.name}
        setAddAthletteOpen={setAddAthletteOpen}/>
)}

        </div>





        <br /><br />




    </div>)
}

export default SportClubItem