import lang from "../../../state/language"
import state from "../../../state/state"



const competitionFunctions={
        //EntryPoomsae
    makeCategoryesPoomsae:(langSwitch,poomsaeOptions,categoryesPoomsae)=> { //Дані для меню категорій на підставі параметрів з БД  
       if(!poomsaeOptions) {return}
      if (poomsaeOptions.category.traditional.personal) {
          categoryesPoomsae.push(lang[langSwitch].traditionalIndividualMale/*Традиційне. Індивідуальні виступи. Чоловіки*/)
          categoryesPoomsae.push(lang[langSwitch].traditionalIndividualFemale/*Традиційне. Індивідуальні виступи. Жінки*/)
        }
        if (poomsaeOptions.category.traditional.couple) { categoryesPoomsae.push(lang[langSwitch].traditionalCouple/*Традиційне. Парні виступи.*/) }
        if (poomsaeOptions.category.traditional.team) {
          categoryesPoomsae.push(lang[langSwitch].traditionalTeamMale/*Традиційне. Командні виступи.Чоловіки*/)
          categoryesPoomsae.push(lang[langSwitch].traditionalTeamFemale/*Традиційне. Командні виступи.Жінки*/)
        }
        if (poomsaeOptions.category.freestyle.personal) {
          categoryesPoomsae.push(lang[langSwitch].freestyleIndividualMale/*Фрістайл. Індивідуальні виступи.Чоловіки*/)
          categoryesPoomsae.push(lang[langSwitch].freestyleIndividualFemale/*Фрістайл. Індивідуальні виступи.Жінки*/)
        }
        if (poomsaeOptions.category.freestyle.couple) { categoryesPoomsae.push(lang[langSwitch].freestyleCouple/*Фрістайл. Парні виступи.*/) }
        if (poomsaeOptions.category.freestyle.team) { categoryesPoomsae.push(lang[langSwitch].freestyleTeam/*Фрістайл. Командні виступи.*/) }
        if (poomsaeOptions.category.mixPoomsae.personal) {
          categoryesPoomsae.push(lang[langSwitch].mixedIndividualMale/*Змішане. Індивідуальні виступи.Чоловіки*/)
          categoryesPoomsae.push(lang[langSwitch].mixedIndividualFemale/*Змішане. Індивідуальні виступи.Жінки*/)
        }
        if (poomsaeOptions.category.mixPoomsae.couple) { categoryesPoomsae.push(lang[langSwitch].mixedCouple/*Змішане. Парні виступи.*/) }
        if (poomsaeOptions.category.mixPoomsae.team) {
          categoryesPoomsae.push(lang[langSwitch].mixedTeamMale/*Змішане. Командні виступи.Чоловіки*/)
          categoryesPoomsae.push(lang[langSwitch].mixedTeamFemale/*Змішане. Командні виступи.Жінки*/)
        }
        if (poomsaeOptions.category.mixPoomsae.mixedTeam) { categoryesPoomsae.push(lang[langSwitch].mixedTeamMix/*Змішане. Змішані команди.*/) }
      }, 
    handleCategoryChange:(e,
        langSwitch,
        setNumberAthletesInCategory,
        setSuccessMessage,
        setIndicator,
        setSelectedCategory,
        setSelectedAthletes,setErrorMessage
        )=> { 
        setNumberAthletesInCategory(null);setSuccessMessage('');setErrorMessage('')
        //робимо індикатор для фільтрації заявлених спортсменів з "моєї команди"
     if (e.target.value ===lang[langSwitch].traditionalIndividualMale/*Традиційне. Індивідуальні виступи. Чоловіки*/||
          e.target.value ===lang[langSwitch].traditionalIndividualFemale/*Традиційне. Індивідуальні виступи. Жінки*/
     ){  setIndicator('TraditionalPersonale')}
      if (e.target.value ===lang[langSwitch].traditionalCouple/*Традиційне. Парні виступи.*/){
        setIndicator('TraditionalCouple')}
    if (e.target.value ===lang[langSwitch].traditionalTeamMale/*Традиційне. Командні виступи.Чоловіки*/ ||
      e.target.value ===lang[langSwitch].traditionalTeamFemale/*Традиційне. Командні виступи.Жінки*/
    ){setIndicator('TraditionalTeam')}
    if (e.target.value ===lang[langSwitch].freestyleIndividualMale/*Фрістайл. Індивідуальні виступи.Чоловіки*/ ||
      e.target.value ===lang[langSwitch].freestyleIndividualFemale/*Фрістайл. Індивідуальні виступи.Жінки*/ 
    ){setIndicator('freestylePersonale')}
    if (e.target.value ===lang[langSwitch].freestyleCouple/*Фрістайл. Парні виступи.*/)
      {setIndicator('freestyleCouple')}
    if (e.target.value ===lang[langSwitch].freestyleTeam/*Фрістайл. Командні виступи.*/)
      {setIndicator('freestyleTeam')}
    if (e.target.value ===lang[langSwitch].mixedIndividualMale/*Змішане. Індивідуальні виступи.Чоловіки*/ ||
      e.target.value ===lang[langSwitch].mixedIndividualFemale/*Змішане. Індивідуальні виступи.Жінки*/  
    ){setIndicator('mixPoomsaePersonale')}
    if (e.target.value ===lang[langSwitch].mixedCouple/*Змішане. Парні виступи.*/)
      {setIndicator('mixPoomsaeCouple')}
    if (e.target.value ===lang[langSwitch].mixedTeamMale/*Змішане. Командні виступи.Чоловіки*/ ||
      e.target.value ===lang[langSwitch].mixedTeamFemale/*Змішане. Командні виступи.Жінки*/  
    ){setIndicator('mixPoomsaeTeam')}
    if (e.target.value ===lang[langSwitch].mixedTeamMix/*Змішане. Змішані команди.*/)
      {setIndicator('mixPoomsaeMixedTeam')}
    
    //обмежуємо кількість виділяємих спортсменів в залежності від категорії
        if (e.target.value === lang[langSwitch].traditionalCouple/*Традиційне. Парні виступи.*/ ||
          e.target.value === lang[langSwitch].freestyleCouple/*Фрістайл. Парні виступи.*/ ||
          e.target.value === lang[langSwitch].mixedCouple/*Змішане. Парні виступи.*/
        ) { setNumberAthletesInCategory(2) }
        if (e.target.value === lang[langSwitch].traditionalTeamMale/*Традиційне. Командні виступи.Чоловіки*/ ||
          e.target.value === lang[langSwitch].traditionalTeamFemale/*Традиційне. Командні виступи.Жінки*/ ||
          e.target.value === lang[langSwitch].mixedTeamMale/*Змішане. Командні виступи.Чоловіки*/ ||
          e.target.value === lang[langSwitch].mixedTeamFemale/*Змішане. Командні виступи.Жінки*/
        ) { setNumberAthletesInCategory(3) }
      if (e.target.value === lang[langSwitch].mixedTeamMix/*Змішане. Змішані команди.*/ ||
          e.target.value === lang[langSwitch].freestyleTeam/*Фрістайл. Командні виступи.*/
        ) { setNumberAthletesInCategory('5+1') }
      if (e.target.value===''){setNumberAthletesInCategory(null);setIndicator(null)}
         setSelectedCategory(e.target.value);
        setSelectedAthletes([])

      }, 
    toggleAthleteSelection:(// Функція для додавання або видалення спортсмена зі списку (плитки) під час заявки
                    athlete,
                    setSuccessMessage,
                    selectedAthletes,
                    setSelectedAthletes,
                    selectedCategory,
                    langSwitch,setErrorMessage
                ) => {
        setSuccessMessage('')
        if (selectedAthletes.includes(athlete.id)) {
          // Спортсмен вже вибраний, видаляємо його зі списку
          setSelectedAthletes(selectedAthletes.filter((id) => id !== athlete.id));
        } else {
          if (
            (selectedCategory === lang[langSwitch].traditionalCouple/*Традиційне. Парні виступи.*/ ||
              selectedCategory === lang[langSwitch].freestyleCouple/*Фрістайл. Парні виступи.*/ ||
              selectedCategory === lang[langSwitch].mixedCouple/*Змішане. Парні виступи.*/) &&
            selectedAthletes.length < 2
          ) {
            setSelectedAthletes([...selectedAthletes, athlete.id]);// Спортсмен ще не вибраний, додаємо його до списку
          } else if (
            (selectedCategory === lang[langSwitch].traditionalTeamMale/*Традиційне. Командні виступи. чоловіки*/ ||
              selectedCategory === lang[langSwitch].traditionalTeamFemale/*Традиційне. Командні виступи.жінки*/ ||
              selectedCategory === lang[langSwitch].mixedTeamMale/*Змішане. Командні виступи.чоловіки*/ ||
              selectedCategory === lang[langSwitch].mixedTeamFemale/*Змішане. Командні виступи.жінки*/) &&
            selectedAthletes.length < 3
          ) {
            setSelectedAthletes([...selectedAthletes, athlete.id]);// Спортсмен ще не вибраний, додаємо його до списку
          } else if (
            (selectedCategory === lang[langSwitch].freestyleTeam/*Фрістайл. Командні виступи.*/ ||
              selectedCategory === lang[langSwitch].mixedTeamMix/*Змішане. Змішані команди.*/
            ) && selectedAthletes.length < 6
          ) {
            setSelectedAthletes([...selectedAthletes, athlete.id]);// Спортсмен ще не вибраний, додаємо його до списку
          } else if (
            selectedCategory === lang[langSwitch].traditionalIndividualMale/*Традиційне. Індивідуальні виступи.чоловіки*/ ||
            selectedCategory === lang[langSwitch].traditionalIndividualFemale/*Традиційне. Індивідуальні виступи.жінки*/ ||
            selectedCategory === lang[langSwitch].freestyleIndividualMale/*Фрістайл. Індивідуальні виступи.чоловіки*/ ||
            selectedCategory === lang[langSwitch].freestyleIndividualFemale/*Фрістайл. Індивідуальні виступи.жінки*/ ||
            selectedCategory === lang[langSwitch].mixedIndividualMale/*Змішане. Індивідуальні виступи.чоловіки*/ ||
            selectedCategory === lang[langSwitch].mixedIndividualFemale/*Змішане. Індивідуальні виступи.жінки*/
          ) {
            setSelectedAthletes([...selectedAthletes, athlete.id]);// Спортсмен ще не вибраний, додаємо його до списку
          }
        }
      }, 







}
export default competitionFunctions