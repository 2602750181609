
import React from 'react';
import s from '../menu/menu.module.css'
import lang from '../../../../state/language';
import Leaders from '../../forAllUsers/leaders';

function LeaderForEdit ({langSwitch, isSmallScreen}) {
  return (<div>
     <div className={s.h1}>
      {lang[langSwitch].editLeadersTitle   /*Редагувати керівників*/}
    </div>   
   <Leaders isSmallScreen={isSmallScreen} langSwitch={langSwitch} moderator={true}/>

  </div>);
};

export default LeaderForEdit;