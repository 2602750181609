import React, { useEffect } from 'react';
import s from '../adminCompetition/adminCompetition.module.css';

import GridForAthlets2 from "../adminCompetition/gridsForAthlets/GridForAthlets2";
import GridForAthlets3 from "../adminCompetition/gridsForAthlets/GridForAthlets3";
import GridForAthlets4 from "../adminCompetition/gridsForAthlets/GridForAthlets4";
import GridForAthlets5 from "../adminCompetition/gridsForAthlets/GridForAthlets5";
import GridForAthlets6 from "../adminCompetition/gridsForAthlets/GridForAthlets6";
import GridForAthlets7 from "../adminCompetition/gridsForAthlets/GridForAthlets7";
import GridForAthlets8 from "../adminCompetition/gridsForAthlets/GridForAthlets8";
import GridForAthlets9 from "../adminCompetition/gridsForAthlets/GridForAthlets9";
import GridForAthlets10 from "../adminCompetition/gridsForAthlets/GridForAthlets10";
import GridForAthlets11 from "../adminCompetition/gridsForAthlets/GridForAthlets11";
import GridForAthlets12 from "../adminCompetition/gridsForAthlets/GridForAthlets12";
import GridForAthlets13 from "../adminCompetition/gridsForAthlets/GridForAthlets13";
import GridForAthlets14 from "../adminCompetition/gridsForAthlets/GridForAthlets14";
import GridForAthlets15 from "../adminCompetition/gridsForAthlets/GridForAthlets15";
import GridForAthlets16 from "../adminCompetition/gridsForAthlets/GridForAthlets16";
import GridForAthlets17 from "../adminCompetition/gridsForAthlets/GridForAthlets17";
import GridForAthlets18 from "../adminCompetition/gridsForAthlets/GridForAthlets18";
import GridForAthlets19 from "../adminCompetition/gridsForAthlets/GridForAthlets19";
import GridForAthlets20 from "../adminCompetition/gridsForAthlets/GridForAthlets20";
import GridForAthlets21 from "../adminCompetition/gridsForAthlets/GridForAthlets21";
import GridForAthlets22 from "../adminCompetition/gridsForAthlets/GridForAthlets22";
import GridForAthlets23 from "../adminCompetition/gridsForAthlets/GridForAthlets23";
import GridForAthlets24 from "../adminCompetition/gridsForAthlets/GridForAthlets24";
import GridForAthlets25 from "../adminCompetition/gridsForAthlets/GridForAthlets25";
import GridForAthlets26 from "../adminCompetition/gridsForAthlets/GridForAthlets26";
import GridForAthlets27 from "../adminCompetition/gridsForAthlets/GridForAthlets27";
import GridForAthlets28 from "../adminCompetition/gridsForAthlets/GridForAthlets28";
import GridForAthlets29 from "../adminCompetition/gridsForAthlets/GridForAthlets29";
import GridForAthlets30 from "../adminCompetition/gridsForAthlets/GridForAthlets30";
import GridForAthlets31 from "../adminCompetition/gridsForAthlets/GridForAthlets31";
import GridForAthlets32 from "../adminCompetition/gridsForAthlets/GridForAthlets32";
import GridForAthlets33 from "../adminCompetition/gridsForAthlets/GridForAthlets33";
import GridForAthlets34 from "../adminCompetition/gridsForAthlets/GridForAthlets34";
import GridForAthlets35 from '../adminCompetition/gridsForAthlets/GridForAthlets35';
import GridForAthlets36 from '../adminCompetition/gridsForAthlets/GridForAthlets36';
import GridForAthlets37 from '../adminCompetition/gridsForAthlets/GridForAthlets37';
import GridForAthlets38 from '../adminCompetition/gridsForAthlets/GridForAthlets38';
import GridForAthlets39 from './../adminCompetition/gridsForAthlets/GridForAthlets39';
import state from '../../../state/state';



function GridsResult(props) {
 
  const competition=props.competition
  const competitionResult=JSON.parse(competition.results)
  const grids = JSON.parse(competition.results).grids

  ///////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        // Код для виклику друку
        window.print();
      }, []); // Пустий масив залежностей гарантує виклик ефекту лише після першого рендерингу компонента
    
      const componentMap = {
        1: GridForAthlets2, 2:GridForAthlets2, 3: GridForAthlets3, 4: GridForAthlets4, 5: GridForAthlets5, 6: GridForAthlets6, 7: GridForAthlets7, 8: GridForAthlets8, 9: GridForAthlets9,
        10: GridForAthlets10, 11:GridForAthlets11, 12: GridForAthlets12, 13: GridForAthlets13, 14: GridForAthlets14, 15: GridForAthlets15, 16: GridForAthlets16, 17: GridForAthlets17, 18: GridForAthlets18,
        19: GridForAthlets19, 20: GridForAthlets20, 21: GridForAthlets21, 22: GridForAthlets22, 23: GridForAthlets23, 24: GridForAthlets24, 25: GridForAthlets25, 26: GridForAthlets26, 27: GridForAthlets27,
        28: GridForAthlets28, 29: GridForAthlets29, 30: GridForAthlets30, 31: GridForAthlets31, 32: GridForAthlets32, 33: GridForAthlets33, 34: GridForAthlets34, 35: GridForAthlets35, 36: GridForAthlets36, 
        37: GridForAthlets37, 38: GridForAthlets38, 39: GridForAthlets39
      };
     return( <>  
      
      {grids?(   
        <div className={s.mainDiv}><br/>
          {grids.map((grid) => {
            const Component = componentMap[grid.numberOfAthletes];
            if (Component) {
              return (
                <Component
                  key={grid.category}
                  id={grid.category}
                  athletes={grid.athletes}
                  category={grid.category}
                  competition={competition}
                  battleNumbers={grid.battleNumbers}
                  cort={grid.cort}
                  grid={grid}
                  langSwitch={props.langSwitch}
                />
              );
            }
          
          })}
        </div>   ):  
(<h1 className={s.h1}>Для виведення сіток на друк їх потрібно попередньо згенерувати та зберегти в БД.</h1>)} 


</> )  
  }
  export default GridsResult
  