import React, { useEffect, useState } from "react";
import s from '../adminCompetition/adminCompetition.module.css';
import state from "../../../state/state.js";
import axios from "axios";
import lang from "../../../state/language.js";
import competitionFunctions from './competitionFunctions';
import indexedDBConnection from "../../../state/indexedDBConnection.js";



function WithdrawPoomsae({ isOpen, onClose, tournId, isSmallScreen, langSwitch }) {
    state.messageCount()//лічильник непрочитаних повідомлень 

    const competition = state.competition.filter((tourn) => tourn.id == tournId)[0];
    let poomsaeOptions, poomsaeCategories, category
    let myAthletes = [], myAthletes1 = [], competitionAthletes

    if(competition.athletes){
         try { competitionAthletes = JSON.parse(competition.athletes) }
        catch { competitionAthletes = competition.athletes }
    }
  
    if (competition.poomsaeOptions) {
        try { poomsaeOptions = JSON.parse(competition.poomsaeOptions) }
        catch { poomsaeOptions = competition.poomsaeOptions }
    }
    if (competition.poomsaeCategories) {
        try { poomsaeCategories = JSON.parse(competition.poomsaeCategories) }
        catch { poomsaeCategories = competition.poomsaeCategories }
    }

    const [selectedAthletes, setSelectedAthletes] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorAthletes, setErrorAthletes] = useState([]);
    const [errorAthletes1, setErrorAthletes1] = useState([]);
    const [errorAthletesMain, setErrorAthletesMain] = useState([]);
    const [count, setCount] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [numberAthletesInCategory, setNumberAthletesInCategory] = useState(null);
    const [classSwitcher, setClassSwitcher] = useState('ClassA');
    const [classB, setClassB] = useState(!poomsaeOptions.classB);
    const [indicator, setIndicator] = useState(null);
    const [data, setData] = useState('');
    const [secretaryTeam, setSecretaryTeam] = useState(true);

    let currentCategory = poomsaeCategories.filter((item) => { return (item.category.includes(indicator) && item.category.includes(classSwitcher)) })

    const handleTitleChange = (e) => { setData(e.target.value) };  
  //   форма пошуку
  myAthletes = myAthletes1.filter(athlete => {
    const fullName = `${athlete.secondName} ${athlete.personName}`.toLowerCase();
    const searchData = data.toLowerCase();

    return fullName.includes(searchData);
  });

    function handleClassChange() {
        const newClassB = !classB;
        setClassB(!classB);
        setSelectedAthletes([])
        setErrorMessage('')
        if (newClassB) { setClassSwitcher('ClassB') } else { setClassSwitcher('ClassA') }
    }
     //надсилаємо тренеру перелік проблем у профілі спортсмену
    useEffect(() => {
        if (errorAthletesMain.length > 0) {
            state.errorMassageSend(errorAthletesMain, state.userProfile.id, langSwitch);
        }
    }, [errorAthletesMain]);

    const categoryesPoomsae = []; const testArray = [];

    //Дані для меню категорій на підставі параметрів з БД 
    competitionFunctions.makeCategoryesPoomsae(langSwitch, poomsaeOptions, categoryesPoomsae)

    function myAthleteMaker() {//створюємо масив доступних спортсменів в категорії
       
       if(selectedCategory.length===0&&errorMessage===''){
         setErrorMessage(lang[langSwitch].selectСategory/*'Оберіть категорію*/)        
        }else{
            if (!poomsaeCategories || !indicator) { return }

        
   

        if (currentCategory.length === 0 || !currentCategory) {
            if (errorMessage === '') {
                setErrorMessage(lang[langSwitch].haveNotAthletesInCategory/*В обраній категорії відсутні Ваші спортсмени.*/)
            }
            return
        }

                    if (Array.isArray(currentCategory[0].athletes[0])) { //командні виступи       
                        currentCategory.forEach((item1) => {
                            item1.athletes.forEach((item2) => {
                                item2.forEach((item3) => { 
                                    if(item2.some((athlete)=>athlete.coach == state.userProfile.id || athlete.coach2 == state.userProfile.id)){
                                    myAthletes1.push(item3)} })
                            })
                        })
                    }
                    else if (!Array.isArray(currentCategory[0].athletes[0])) { // особисті виступи    
                        currentCategory.forEach((item1) => {
                            item1.athletes.forEach((item2) => {  
                                if(item2.coach == state.userProfile.id || item2.coach2 == state.userProfile.id){
                                myAthletes1.push(item2)} })
                        })
                    }
                    else { return }

    }
    }

    if ((state.userProfile.id==competition.mainJudge||state.userProfile.id==competition.secretary)&&!secretaryTeam){
        if (currentCategory.length === 0 || !currentCategory) {
            if (errorMessage === '') {
                setErrorMessage(lang[langSwitch].haveNotAthletesInCategory/*В обраній категорії відсутні Ваші спортсмени.*/)
            }
            
        }else{myAthletes1=competitionAthletes}

    }else{
       myAthleteMaker() 
    }
    
    myAthletes = myAthletes1.filter(athlete => {
        const fullName = `${athlete.secondName} ${athlete.personName}`.toLowerCase();
        const searchData = data.toLowerCase();
    
        return fullName.includes(searchData);
      });

    // Функція для додавання або видалення спортсмена зі списку (плитки) під час заявки       
    function toggleAthleteSelection(athlete) {
        setSuccessMessage('');
        setErrorMessage('');
    
        setSelectedAthletes((prevSelectedAthletes) => {
            let updatedSelectedAthletes = [...prevSelectedAthletes];
    
            // Перевірка на індивідуальні програми
            if (!Array.isArray(currentCategory[0].athletes[0])) {
                if (updatedSelectedAthletes.includes(athlete.id)) {
                    // Спортсмен вже вибраний, видаляємо його зі списку
                    updatedSelectedAthletes = updatedSelectedAthletes.filter((id) => id !== athlete.id);
                } else {
                    // Спортсмен ще не вибраний, додаємо його до списку
                    updatedSelectedAthletes.push(athlete.id);
                }
            } else {
                // Перевірка на командні програми
                currentCategory.forEach((category) => {
                    category.athletes.forEach((team) => {
                        if (team.some((teamMember) => teamMember.id == athlete.id)) {
                            // Спортсмен знайдений в команді
                            const teamIds = team.map((teamMember) => teamMember.id);
                            
                            if (teamIds.some((id) => updatedSelectedAthletes.includes(id))) {
                                // Якщо хтось з команди вже в selectedAthletes, видаляємо всю команду
                                updatedSelectedAthletes = updatedSelectedAthletes.filter((id) => !teamIds.includes(id));
                            } else {
                                // Якщо нікого з команди немає в selectedAthletes, додаємо всю команду
                                updatedSelectedAthletes = [...updatedSelectedAthletes, ...teamIds];
                            }
                        }
                    });
                });
            }
    
            return updatedSelectedAthletes;
        });
    }
    
    //замінюємо id на профіль користувача
    let athletesProfile = state.athletes.filter((sportsman) => { return selectedAthletes.includes(sportsman.id) })
    function sexFilter() {// сортуємо спортсменів за статтю

        if (selectedCategory === lang[langSwitch].traditionalIndividualMale/*Традиційне. Індивідуальні виступи. Чоловіки*/ ||
            selectedCategory === lang[langSwitch].traditionalTeamMale/*Традиційне. Командні виступи.Чоловіки*/ ||
            selectedCategory === lang[langSwitch].freestyleIndividualMale/*Фрістайл. Індивідуальні виступи.Чоловіки*/ ||
            selectedCategory === lang[langSwitch].mixedIndividualMale/*Змішане. Індивідуальні виступи.Чоловіки*/ ||
            selectedCategory === lang[langSwitch].mixedTeamMale/*Змішане. Командні виступи.Чоловіки*/) {
            myAthletes = myAthletes.filter((item) => item.sex === 'male')
        }
        if (selectedCategory === lang[langSwitch].traditionalIndividualFemale/*Традиційне. Індивідуальні виступи. Жінки*/ ||
            selectedCategory === lang[langSwitch].traditionalTeamFemale/*Традиційне. Командні виступи.Жінки*/ ||
            selectedCategory === lang[langSwitch].freestyleIndividualFemale/*Фрістайл. Індивідуальні виступи.Жінки*/ ||
            selectedCategory === lang[langSwitch].mixedIndividualFemale/*Змішане. Індивідуальні виступи.Жінки*/ ||
            selectedCategory === lang[langSwitch].mixedTeamFemale/*Змішане. Командні виступи.Жінки*/) {
            myAthletes = myAthletes.filter((item) => item.sex === 'female')
        }
        if (
            selectedCategory === lang[langSwitch].mixedIndividualFemale/*Змішане. Індивідуальні виступи.Жінки*/ ||
            selectedCategory === lang[langSwitch].mixedIndividualMale/*Змішане. Індивідуальні виступи.Чоловіки*/ ||
            selectedCategory === lang[langSwitch].mixedTeamMale/*Змішане. Командні виступи.Чоловіки*/ ||
            selectedCategory === lang[langSwitch].mixedTeamFemale/*Змішане. Командні виступи.Жінки*/ ||
            selectedCategory === lang[langSwitch].mixedCouple/*Змішане. Парні виступи.*/ ||
            selectedCategory === lang[langSwitch].mixedTeamMix/*Змішане. Змішані команди.*/) {
            myAthletes = myAthletes.filter((item) => state.age(item.birthday) > 17)
        }

    }
     sexFilter()

     function removeSelectedAthletes(entryPoomsaeCategories) {
        // Перебираємо всі категорії в entryPoomsaeCategories
        return entryPoomsaeCategories
            .map(category => {
                // Перевіряємо, чи атлети в категорії є масивами (команди) чи ні (індивідуальні)
                if (Array.isArray(category.athletes[0])) {
                    // Для командних категорій
                    const filteredTeams = category.athletes.filter(team => {
                        // Перевіряємо, чи в команді є спортсмени зі списку selectedAthletes
                        return !team.some(athlete => selectedAthletes.includes(athlete.id) && 
                                                     currentCategory.some(cat => cat.category === category.category));
                    });
                    return { ...category, athletes: filteredTeams };
                } else {
                    // Для індивідуальних категорій
                    const filteredAthletes = category.athletes.filter(athlete => {
                        return !selectedAthletes.includes(athlete.id) || 
                               !currentCategory.some(cat => cat.category === category.category);
                    });
                    return { ...category, athletes: filteredAthletes };
                }
            })
            // Видаляємо порожні категорії
            .filter(category => category.athletes.length > 0);
    }

    function  keepValidAthletes(entryAthletes, arrayPoomsaeCategories) {
        // Збираємо всі ID спортсменів з arrayPoomsaeCategories
    const validAthleteIds = new Set();
    
    arrayPoomsaeCategories.forEach(category => {
        if (Array.isArray(category.athletes[0])) {
            // Для командних категорій
            category.athletes.forEach(team => {
                team.forEach(athlete => validAthleteIds.add(athlete.id));
            });
        } else {
            // Для індивідуальних категорій
            category.athletes.forEach(athlete => validAthleteIds.add(athlete.id));
        }
    });

    // Фільтруємо athletesProfile, залишаючи тільки тих спортсменів, чиї ID є в validAthleteIds
    return entryAthletes.filter(athlete => validAthleteIds.has(athlete.id));
        
    }



 function updateCompetition() {
            if (competition.active == 1)//Перевіряємо чи відкрита реєстрація на турнир
            {
                axios //Оторимуємо свіжі дані з БД по заявлених атлетах
                    .post(state.api.getAthletesForPoomsaeComp, { id: tournId })
                    .then((response) => {


                        let entryAthletes
                        try { entryAthletes = JSON.parse(response.data.athletes) } catch { entryAthletes = response.data.athletes }

                        let entryPoomsaeCategories
                        try { entryPoomsaeCategories = JSON.parse(response.data.poomsaeCategories) } catch { entryPoomsaeCategories = response.data.poomsaeCategories }

                        const arrayPoomsaeCategories = removeSelectedAthletes(entryPoomsaeCategories);
                        const arrayAthletes  = keepValidAthletes(entryAthletes, arrayPoomsaeCategories);
     
                        //перевірка вільних місць на турнирі
                        if (arrayAthletes.length >= competition.numberOfAthletes) {
                            setErrorMessage(
                                lang[langSwitch].haveNoPlases /* Нажаль кількість обраних вами спорстменів перевищує кількість доступних для реєстрації місць.*/
                            )
                            return

                        }
                        else {
                            axios// Додаємо нових спортсменів до змагань
                                .post(state.api.addNewAthletesOnPoomsae, {
                                    id: tournId,
                                    newAthletes: JSON.stringify(arrayAthletes),
                                    poomsaeCategories: JSON.stringify(arrayPoomsaeCategories)
                                })
                                .then((response) => {

                                    const index = state.competition.findIndex(item => item.id == tournId);
                                    state.competition[index].athletes = JSON.stringify(arrayAthletes);
                                    state.competition[index].poomsaeCategories = JSON.stringify(arrayPoomsaeCategories);
                                    indexedDBConnection.saveData('competition',  state.competition)
                                                .then(() => {})
                                                .catch((error) => {
                                                console.error('Помилка при збереженні даних в IndexedDB:', error);
                                    });
                                    setSuccessMessage(
                                        lang[langSwitch].applicationSuccessfullyWithdrawn /*Заявку ваших спортсменів на туртир успішно відкликано.*/
                                    );
                                    setSelectedAthletes([]);
                                })
                                .catch((error) => {
                                    if (state.userProfile.id == competition.mainJudge ||state.userProfile.id == competition.secretary) {
                                        const index = state.competition.findIndex(item => item.id == tournId);
                                        state.competition[index].athletes = JSON.stringify(arrayAthletes);
                                        state.competition[index].poomsaeCategories = JSON.stringify(arrayPoomsaeCategories);
                                                  indexedDBConnection.saveData('competition',  state.competition)
                                                    .then(() => {
                                                       setSuccessMessage(
                                        lang[langSwitch].athleteAddedOffline /*Спортсмена додано в режимі offline.*/
                                      );
                                                    })
                                                    .catch((error) => {
                                                      console.error('Помилка при збереженні даних в IndexedDB:', error);
                                                    }); 
                                        } 
                                    setErrorMessage(lang[langSwitch].serverRequestError /*Помилка запиту до сервера: */ + error);
                                });
                        }//закінчення перевірки вільних місць на турнирі
                    })
                    .catch((error) => {
                        setErrorMessage(lang[langSwitch].serverRequestError /*Помилка запиту до сервера: */ + error);
                    });

            }
            else {//закриття перевірки реєстрації на турнир
                setErrorMessage(
                    lang[langSwitch].registrationForTournamentCompleted /*Нажаль реєстрацію на цей турнир завершено.*/
                )
            }

        } //закінчення функції викликів до БД

       



    const createMarkup = (html) => { return { __html: html } };
    return (<>
    {isOpen?(<>
        <div className={s.modalWriper}>
        <div appElement={document.getElementById('root')} className={s.modal}  contentLabel="Regulation Modal">
            <div className={errorAthletes1.length === 0 ? '' : `${s.errorMessage1}`} dangerouslySetInnerHTML={createMarkup(errorAthletes1)}
                onClick={() => { setErrorAthletes1([]) }} />
            <div className={errorAthletesMain.length === 0 ? '' : `${s.errorMessage1}`} dangerouslySetInnerHTML={createMarkup(errorAthletesMain)}
                onClick={() => { setErrorAthletesMain([]) }} />

            <div className={errorAthletes.length === 0 ? '' : `${s.errorMessage1}`} dangerouslySetInnerHTML={createMarkup(errorAthletes)}
                onClick={() => { setErrorAthletes([]) }} />
            <div className={s.teamContaner}>
                <div className={s.teamChange}>
                    <h2>
                        {lang[langSwitch].withdrawApplication /*Відкликати заявку*/}
                    </h2>
                    <div >
        <label htmlFor="title"><span><b>
        {lang[langSwitch].startTypingName /*  Почніть вводити призвище та ім'я*/}
          </b></span> </label><br /><br />
        <input onChange={handleTitleChange} type="text" id="title" name="title" />
      </div>
                </div>

                <div className={state.userProfile.id==competition.mainJudge||state.userProfile.id==competition.secretary?s.poomsaeSwitcherMenuForSecretary:s.poomsaeSwitcherMenu}>
 <label>
                    <span ><b>
                        {secretaryTeam?
                            lang[langSwitch].myAthletes/*Моя спортсмени*/
                            :
                            lang[langSwitch].allAthletesFromDB/*Усі спортсмени*/}
                    </b> </span>
                    <div className={s.switchBox} style={{paddingBottom:'10px'}}>
                        <input
                            type='checkbox'
                            checked={secretaryTeam}
                            onChange={()=>{setSecretaryTeam(!secretaryTeam)}}

                        />
                    </div>
                </label>

   {/*Класс змагань з Пхумсе*/}
   {poomsaeOptions.classB?(<>
   <label>
                    <span ><b>
                        {classSwitcher=== 'ClassB'?
                            lang[langSwitch].classB/*Клас "Б"*/
                            :
                            lang[langSwitch].classA/*Клас "A"*/}
                    </b> </span>
                    <div className={s.switchBox}>
                        <input
                            type='checkbox'
                            checked={classB}
                            onChange={handleClassChange}

                        />
                    </div>
                </label>
                </>):null}
 </div>

                {/* Обираем категорію пхумсе */}
                <div className={s.poomsaeCategoryMenu}>
                    <label htmlFor="status"><span className={s.teamChangeMenuSpan}>
                        {lang[langSwitch].selectСategory/*Оберіть категорію*/}:
                    </span> </label><br />
                    <select onChange={(e) =>
                        competitionFunctions.handleCategoryChange(e,
                            langSwitch,
                            setNumberAthletesInCategory,
                            setSuccessMessage,
                            setIndicator,
                            setSelectedCategory,
                            setSelectedAthletes,setErrorMessage
                        )}
                        id="status"
                        name="status"
                        placeholder ={selectedCategory}>
                        <option value=""> </option>
                        {categoryesPoomsae.map((item,index) => {
                            return (
                                <option value={item} key={item+index}>
                                    {item}
                                </option>
                            )
                        })}

                    </select>
                </div>




            </div>

            
            {/* "плитка спортсменів" */}
            <div className={s.gridContainer}>
                {myAthletes.map((athlete) => (
                    <div key={athlete.id} className={selectedAthletes.includes(athlete.id) ? `${s.athleteBlock} ${s.athleteBlock1}` : s.athleteBlock}>
                        <img

                            src={athlete.photo ? athlete.photo : state.noAvatarPhoto}
                            alt={`${athlete.secondName} ${athlete.personName}`}
                            style={{ cursor: 'pointer' }}
                            onClick={() => toggleAthleteSelection(athlete)}
                        />
                        <div className={s.name}>{`${athlete.secondName} ${athlete.personName}`}</div>
                    </div>
                ))}

            </div>
            <div className={errorAthletes1.length === 0 ? '' : `${s.errorMessage1}`} dangerouslySetInnerHTML={createMarkup(errorAthletes1)}
                onClick={() => { setErrorAthletes1([]) }} />
            <div className={errorAthletesMain.length === 0 ? '' : `${s.errorMessage1}`} dangerouslySetInnerHTML={createMarkup(errorAthletesMain)}
                onClick={() => { setErrorAthletesMain([]) }} />

            <div className={errorAthletes.length === 0 ? '' : `${s.errorMessage1}`} dangerouslySetInnerHTML={createMarkup(errorAthletes)}
                onClick={() => { setErrorAthletes([]) }} />
            {successMessage ?
                (<><br /><br /><div className={s.successMessage} onClick={() => { setSuccessMessage('') }}>
                    {successMessage}</div><br /></>) :
                errorMessage ? (<><br /><br /><div className={s.errorMessage} onClick={() => { setErrorMessage('') }}>
                    {errorMessage}</div><br /></>) : ''}


            <div className={isSmallScreen ? s.td1 : null}>
                <button type='button'//Закрити
                    onClick={() => {
                        setIndicator(null)
                        myAthletes=[]
                        currentCategory=null
                        setErrorMessage('');
                        setSelectedAthletes([]);
                        setSelectedCategory([]);
                        setSuccessMessage('');
                        setErrorAthletesMain([]);
                        setErrorAthletes([]);
                        setErrorAthletes1([]);
                        setNumberAthletesInCategory(null)
                         onClose();
                    }}>
                    {lang[langSwitch].close /*Закрити*/}
                </button>
                <button className={!isSmallScreen ? s.buttonR : null}//Відкликати заявку
                    type='button'
                    onClick={() => {  updateCompetition() }}>
                    {lang[langSwitch].withdrawApplication /*Відкликати заявку*/}
                </button>
            </div>
        </div>
        </div>
    </>):null}
    </>)
}
export default WithdrawPoomsae