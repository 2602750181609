import React, { useState } from 'react';
import axios from 'axios';
import s from './login.module.css';
import sAdmin from '../mod/content/adminCompetition/adminCompetition.module.css'
import state from './state';
import FogotPass from './fogotPass';
import indexedDBConnection from './indexedDBConnection';
import lang from './language';



function LoginForm({setRegistrationStart,setGlobalId,setLoginStart,langSwitch}) {
 

  const [login, setLogin] = useState('');
  const [pass, setPass] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [sendPass, setSendPass] = useState(false);

  const handleLoginChange = (e) => {
    setLogin(e.target.value.trim());
  };

  const handlePassChange = (e) => {
    setPass(e.target.value.trim());
  };



  const handleSubmit = (e) => {
    e.preventDefault();

    // Перевірка на заповненість полів
    if (!login || !pass) {
      setErrorMessage('Ви ввели не всі дані для авторизації. Будь ласка, заповніть усі поля форми.');
      return;
    }
 
    // Кодування паролю в форматі base64
  const encodedPassword = btoa(pass);


    // Відправлення запиту на сервер для авторизації
    axios
    .post(state.api.login, { login, pass: encodedPassword,  })
    .then((response) => {
      if (response.data.userProfile) {
        // Якщо авторизація успішна, встановлюємо `state.globalId` і переадресовуємо користувача 
        let userProfile 
        try{
         userProfile = JSON.parse(response.data.userProfile)
        }catch{
          userProfile = response.data.userProfile
        }
                
        indexedDBConnection.saveData('userProfile', userProfile);
       
        state.userProfile = userProfile
       
        state.tempUserProfile =  Object.assign({},userProfile )
        if(state.userProfile){
            state.globalId = parseInt(state.userProfile.id);
            setGlobalId(parseInt(state.userProfile.id))
        }        
        state.stateUpdate(); // Отримуємо дані з БД


        window.location.href='/Information'
       
      }
    
   else {
          setErrorMessage('Неправильний логін або пароль. Будь ласка, спробуйте знову.');
        }
      })
      .catch((error) => {
        console.error('Помилка запиту до сервера:', error.response?.data || error.message || error);
        setErrorMessage(
          'Помилка під час авторизації. Будь ласка, спробуйте пізніше.'
        );
      });
  };

 
  return (
  <div className={sAdmin.modalWriper}>
  <div className={sAdmin.modal}>
  
  <div>

    <div className={s.main}>
     
       
          <h2>
          { lang[langSwitch].authorization /*Авторизація*/ }
          </h2>
          <form onSubmit={handleSubmit}>
            <div>
              <label>
              { lang[langSwitch].login /*Повернутись*/ }:</label><br />
              <input
                type="text"
                value={login}
                onChange={handleLoginChange}
                required
              />
            </div>
            <div>
              <label>
              { lang[langSwitch].password /*Пароль*/ }:
              </label><br />
              <input
                type="password"
                value={pass}
                onChange={handlePassChange}
                required
              />
            </div>
          
            
            <div>
              <button type="submit"> { lang[langSwitch].logIn /*Вхід*/ }</button>

              <button onClick={()=>{
                setRegistrationStart(true)
                setLoginStart(false)
                }}>
              { lang[langSwitch].signUp /*Реєстрація*/ }
                </button>

              <button onClick={()=>{setLoginStart(false)}}>
              { lang[langSwitch].comeBack /*Повернутись*/ }
              </button>

           <div className={s.fogotPass} onClick={()=>{setSendPass(true)}}> 
           { lang[langSwitch].forgotYourPassword /*Забули пароль чи логін?*/ }
             </div>    
            </div>
            {sendPass?<FogotPass setErrorMessage={setErrorMessage}/>:null}

            {errorMessage && <p className={s.errorMessage} onClick={()=>{setErrorMessage('')}}>
              {errorMessage}</p>}
          </form>
        
    </div>
    
   
      
    </div>
    </div></div>
  );
}

export default LoginForm;
