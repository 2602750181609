import React, { useState } from 'react';
import s from "../forAllUsers.module.css"
import sAdmin from '../../adminCompetition/adminCompetition.module.css'
import sApp from '../../../../App.module.css'
import sMenu from '../../controlPanel/menu/menu.module.css'
import lang from '../../../../state/language';
import state from '../../../../state/state';
import axios from 'axios';
import ConfirmButtons from '../../adminCompetition/confirmButtons';
import indexedDBConnection from '../../../../state/indexedDBConnection';

function VideoGaleryItem({
  isSmallScreen,
  langSwitch,
  gallery,
  index,
  moderator,
  setEditVideoGalery,
  setEditStart }) {

  const [messageText, setMessageText] = useState('');
  const [isConfirmButtonsModalOpen, setIsConfirmButtonsModalOpen] = useState(false);
  const [galeryOpen, setGaleryOpen] = useState(false)
  const [ind, setInd] = useState(0)
  const [fade, setFade] = useState(true);
  const [uploading, setUploading] = useState(false);

  const openConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(true); }
  const closeConfirmButtonsModal = () => { setIsConfirmButtonsModalOpen(false); }

  function deleteNewsConfirm() {
    setMessageText(lang[langSwitch].deleteGallery /*Видалити галерею?*/)
    openConfirmButtonsModal()
  }

  const confirmFunction = (messageText) => {
    if (messageText === lang[langSwitch].deleteGallery /*Видалити галерею?*/
    ) {
      //ВИКЛИК ФУНКЦІЇ 
      deleteArticle()
    }

  }


  const changeImage = (newIndex) => {
    setFade(false); // Починаємо зникнення

    setTimeout(() => {
      setFade(true); // Плавно показуємо нове зображення
      setInd(newIndex); // Міняємо зображення після завершення анімації

    }, 300);
  };

  console.log('gallery.links: ', gallery.links)
  let photos = []


  if (gallery?.links && gallery.links !== null) {
    try { photos = JSON.parse(gallery.links) }
    catch { photos = gallery.links }
  }




  function deleteArticle() {
    axios.delete(state.api.deleteGallery, {
      params: {
        id: gallery.id,
        // Додано передачу імені файлу
      },
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => {
        const updateGallery = state.mediaContent.filter((m) => +m.id !== +gallery.id)
        state.mediaContent = [...updateGallery]
        indexedDBConnection.saveData('mediaContent', state.mediaContent)
          .then((responseDB) => { window.location.reload(true); })
          .catch((error) => { console.log('error: ', error); });
        setUploading(false)
      })
      .catch((error) => {
        setUploading(false)
        console.log('Помилка запиту до сервера: ' + error);
      });

  }



  return (


    <div className={s.newsItemWriper} key={gallery?.id + index}>
      <div className={s.newsItemInnerWriper}>
        <div className={s.newsItemBackGround}>
          <div 
            className={s.videoTitle}
            onClick={()=>{setGaleryOpen(!galeryOpen)}}>
            {gallery?.title}
          </div>

          <div className={s.galeryBlock}>
            {(photos && photos.length > 0 && galeryOpen) && (<>

              {photos.map((item, index) => {
                console.log('item: ', item)
                return (
                  <div className={sMenu.videoPrewBox} key={item.videoURL + index}>
                    <div className={sMenu.videoPrewPlayer}>
                      {state.youtubePlayer(item.videoURL, isSmallScreen)}
                    </div>
                    <div className={sMenu.videoPrewTitle}>
                      {item.videoTitle}
                    </div>
                  </div>
                )
              })}

            </>)}
          </div>


          {moderator && (
            <div className={sMenu.editButtonsBox}>
              <div className={sMenu.editButtonItem}>
                <button
                  type='button'
                  onClick={() => {
                    setEditVideoGalery(gallery)
                    setEditStart(true)
                  }}>
                  {lang[langSwitch].edit /*Редагувати*/}
                </button>
              </div>
              <div className={sMenu.deleteButtonItem}>
                <button
                  type='button'
                  onClick={() => { deleteNewsConfirm() }}>
                  {lang[langSwitch].deleteFromDB /*Видалити з БД*/}
                </button>
              </div>
            </div>

          )}

        </div>

      </div>

      {uploading && (
        <div className={sAdmin.modalWriper}>
          <div className={sApp.flag}>
            {lang[langSwitch].pleaseWaitDataLoading /*Зачекайте, дані завантажуються...*/}<br /><br />
            <img alt='фото' src='/img/main/ukrainian-waving-flag-30.gif' />
          </div>
        </div>
      )}
      <ConfirmButtons
        isOpen={isConfirmButtonsModalOpen}
        onClose={closeConfirmButtonsModal}
        confirmFunction={confirmFunction}
        messageText={messageText}
        langSwitch={langSwitch}
      />
    </div>

  );
};

export default VideoGaleryItem;
