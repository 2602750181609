import { useEffect, useState } from "react"
import state from "../../../../state/state"
import s from './adminPoomsae.module.css'
import sAdmin from '../adminCompetition.module.css'
import axios from "axios"
import lang from "../../../../state/language"

//ячейка спортсмене у сітці при роботі на пультах
function AthleteForCD({cortSecretary, setSaveSwitcher, setPoomsaeGrids, indexDbData, round, setRound, item, index, cort, battleNumber, id, langSwitch, category, judgeData, setJudgeScoreResult, setJudgeScoreTechnique,setJudgeScorePresentation, setJudgeScoreArray, setGridId}){

 
    const [wiew, setWiew]=useState(true)  
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [data, setData] = useState(null);
    const [presentationScore, setPresentationScore] = useState(null);
    const [techniqueScore, setTechniqueScore] = useState(null);
    const [roundResult, setRoundResult] = useState(null);
     const [switcher, setSwitcher]=useState(false)  

   
    //  console.log('round:', round)
    //  console.log('category:', category)
      
   useEffect(()=>{
    //формуємо збережену (зверху) чи згенеровану (знизу) таблицю результатів
    function start(){
        if(item.judgeScore){
            
            if(round==='preliminary'&&(Array.isArray(item.judgeScore.preliminaryScore)&&item.judgeScore.preliminaryScore.length>0)){
               setWiew(false)
            setSwitcher(true)  
            }
           if(round==='semiFinal'&&(Array.isArray(item.judgeScore.semiFinalScore)&&item.judgeScore.semiFinalScore.length>0)){
                   setWiew(false)
            setSwitcher(true)
           }
           if(round==='final'&&(Array.isArray(item.judgeScore.finalScore)&&item.judgeScore.finalScore.length>0)){           
            setWiew(false)
            setSwitcher(true)              
            }   
        }


        if(data!==null){
            setWiew(false)
            setSwitcher(false)
        }
    } 
    start()
},[data,item])




useEffect(()=>{
    //передаємо результати згенерованої таблиці результатів
    if(presentationScore&&techniqueScore&&roundResult&&data){
    setJudgeScoreResult(roundResult)
    setJudgeScoreTechnique(techniqueScore)
    setJudgeScorePresentation(presentationScore)
    setJudgeScoreArray((prev)=> {
        const result = structuredClone(prev)
         result.push({scorse: data.scorse, gridId:item.gridId})
         return result
      })
    setGridId(item.gridId)
    setRound(round)


    
//формуємо дані для indexedDB

    function indexedDbUpdate(array){
        // console.log('array: ',array)
        // console.log('round: ',round)

setPoomsaeGrids((prev) => {
    const result = prev.map((grid) => {
        if (grid.category === category) {
            const updatedAthletes = grid[array].map((athlete) => {
                if (athlete.gridId === item.gridId) {
                    const data2 = {
                        judgeScore: athlete.judgeScore ? { ...athlete.judgeScore } : {}
                    };

                    if (round === 'preliminary') {
                        data2.preliminaryResult = { technique: techniqueScore, presentation: presentationScore, allScore: roundResult };
                        data2.judgeScore.preliminaryScore = data.scorse;
                        data2.preliminaryTotal = roundResult;
                    }

                    if (round === 'semiFinal') {
                        data2.semiFinalResult = { technique: techniqueScore, presentation: presentationScore, allScore: roundResult };
                        data2.judgeScore.semiFinalScore = data.scorse;
                        data2.semiFinalTotal = roundResult;
                    }

                    if (round === 'final') {
                        data2.finalResults = { technique: techniqueScore, presentation: presentationScore, allScore: roundResult };
                        data2.judgeScore.finalScore = data.scorse;
                        data2.finalTotal = roundResult;
                    }

                    return { ...athlete, ...data2 };
                } else {
                    return athlete;
                }
            });

            // Повертаємо змінений grid з оновленими атлетами
            return { ...grid, [array]: updatedAthletes };
        } else {
            return grid;
        }
    });

    return result;
});

}
if ( round === 'preliminary'){
    indexedDbUpdate('athletes')
}
if ( round === 'semiFinal'){
    indexedDbUpdate('athletes')
    indexedDbUpdate('semiFinalAtletes')
}
if ( round === 'final'){
    indexedDbUpdate('athletes')
    indexedDbUpdate('finalAtletes')
}


//вмикаємо перемикач для запусу збереження через useEffect в tableGrids.js
setSaveSwitcher((prev) => {return prev+1});
}
},[presentationScore,techniqueScore,roundResult])
 



    const judgesData = judgeData.filter((itemJ)=>itemJ.cort==cort)[0]
    const judges = judgesData.judges.filter((item=>item!==cortSecretary))


    function resultTable( scorse   ) {
        if ( round === 'final'){

}
       if(successMessage) {setSuccessMessage(null)}
     let  scorePresentationArray=[], scoreTechniqueArray=[]        
//створюємо масив оцінок
 scorse.forEach((item)=>{
if(item.presentation1&&item.technique1){ scorePresentationArray.push(item.presentation1); scoreTechniqueArray.push(item.technique1);}
if(item.presentation2&&item.technique2){ scorePresentationArray.push(item.presentation2); scoreTechniqueArray.push(item.technique2);}
})

if (scorse.length <= 3) {  
// рахуєм результат
    const sumPres = scorePresentationArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    if (!presentationScore){setPresentationScore(Math.round((sumPres/scorePresentationArray.length)* 100) / 100)}
    
    const sumTech = scoreTechniqueArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
   if(!techniqueScore){setTechniqueScore(Math.round((sumTech/scoreTechniqueArray.length) * 100) / 100)} ;
   if(!roundResult) {setRoundResult(Math.round((presentationScore+techniqueScore)*100)/100)}

}else{
     // шукаємо найнижче та найвище значення
     function find(array2,array3) {
        const array=[...array2, ...array3]
        let lowest = 0, highest = 0
        array.forEach((item) => {
            if (lowest === 0) {
                lowest = item
            } else {
                if (item < lowest) { lowest = item }
            }

            if (highest === 0) {
                highest = item
            } else {
                if (item > highest) { highest = item }
            }

        })
        //інвекси значень
        const lowestIndex2 = array2.findIndex((item)=>item===lowest)
        const lowestIndex3 = array3.findIndex((item)=>item===lowest)
        const highestIndex2 = array2.findIndex((item)=>item===highest)
        const highestIndex3 = array3.findIndex((item)=>item===highest)
//видаляємо елементи з найнижчими та найвисчими значеннями
        if(lowestIndex2>=0){array2.splice(lowestIndex2, 1);}
         if(lowestIndex3>=0){array3.splice(lowestIndex3, 1);}
         if(highestIndex2>=0){array2.splice(highestIndex2, 1);}
         if(highestIndex3>=0){array3.splice(highestIndex3, 1);}
   }

    find(scorePresentationArray,scoreTechniqueArray) 
// рахуєм результат
            const sumPres = scorePresentationArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            if (!presentationScore){setPresentationScore(Math.round((sumPres/scorePresentationArray.length)* 100) / 100)}
            
            const sumTech = scoreTechniqueArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
           if(!techniqueScore){setTechniqueScore(Math.round((sumTech/scoreTechniqueArray.length) * 100) / 100)} ;
           if(!roundResult) {setRoundResult(Math.round((presentationScore+techniqueScore)*100)/100)}   
}
function dateMaker(){
   //передаємо дані збереженої таблиці результатів
    setJudgeScoreResult(roundResult)
    setJudgeScoreTechnique(techniqueScore)
    setJudgeScorePresentation(presentationScore)
    setJudgeScoreArray((prev)=> {
      const result = structuredClone(prev)
       result.push({...scorse, gridId:item.gridId})
       return result
    })
    setGridId(item.gridId)
    setRound(round)
}
if( round === 'preliminary'&&!data&&!item.judgeScore.preliminaryScore){ dateMaker() }
if( round === 'semiFinal'&&!data&&!item.judgeScore.semiFinalScore){ dateMaker() }
if( round === 'final'&&!data&&!item.judgeScore.finalScore){ dateMaker() }


//формуємо дані для монітора
const monitorData = {
    athlete:item,
    battleNumber:battleNumber,
    category:category,
    cort:cort,
    result:{
        scorse:scorse,
        presentationScore:presentationScore,
        techniqueScore:techniqueScore,
        roundResult:roundResult
    }
}
localStorage.setItem('controlDeskData',JSON.stringify(monitorData))

     scorse.sort((a, b)=>a.judge-b.judge)

        return (<>
 <div className={s.resultTableBox}>
                <table className={s.resultTable}>
                    <thead>
                        <tr>
                           <td>Суддя</td>
                           <td>Пумсе</td>
                           <td>Техніка</td>
                           <td>Презент.</td>
                           <td>Загалом</td>
                        </tr>
                    </thead>
                <tbody>
  { scorse.map((item)=>{
    return(<>
                        <tr>
                            <td rowSpan='2'>{state.myCoach(item.judge)}</td>
                            <td>1</td>
                            <td>{item.technique1}</td>
                            <td>{item.presentation1}</td>                            
                            <td>{item.result1}</td>                                                 
                        </tr>
                        <tr>                           
                            <td>2</td>
                            <td>{item.technique2?item.technique2:(<>&nbsp;</>)}</td>
                            <td>{item.presentation2?item.presentation2:(<>&nbsp;</>)}</td>                            
                            <td>{item.result2?item.result2:(<>&nbsp;</>)}</td>                            
                        </tr>
            </>)
  })} 
   <tr  style={{borderTop:'#1d2f43 2px solid'}}>
                            <td colSpan='2'>Загалом</td>
                            <td>{techniqueScore}</td>
                            <td>{presentationScore}</td>                            
                            <td>{roundResult}</td>
                           
                            
                        </tr>
   </tbody>
                </table>
            </div>
        </>)
    }


    function perfomanse(item, battleNumber) {
       
        axios.post(state.api.controlDeskDataRead, { id: id })
            .then((response) => {
          
            let data=[]
            if(response.data.controlDeskData&&Array.isArray(response.data.controlDeskData)&&response.data.controlDeskData.length>0){
              data=response.data.controlDeskData.filter((it)=>it.cort!==cort)
             } 
           
              data.push({ cort: cort, athlete: item, category:category,battleNumber:battleNumber })
            
                axios.post(state.api.controlDeskDataToDB, {
                        controlDeskData: JSON.stringify(data),
                        id: id,
                        headers: { 'Content-Type': 'application/json' }
                    })
                    .then((response) => {
                       localStorage.setItem('controlDeskData',JSON.stringify({ cort: cort, athlete: item, category:category,battleNumber:battleNumber })) 
                                      
                        setSuccessMessage(
                            lang[langSwitch]. newPerformanceHasStarted /*Розпочато новий виступ*/
                        );

                    })
                    .catch((error) => {
                        setErrorMessage(
                            lang[langSwitch].notPossibleToTransferPerformance/*Не вдалось передати виступ на пульти суддів*/+': ' + error);
                    });
            })
            .catch((error) => {
                setErrorMessage(lang[langSwitch].serverRequestError/*Помилка запиту до сервера:*/ + error);

            })


    } 
    
    function perfomanceResult() {

        axios.post(state.api.controlDeskResultRead, { id: id })
            .then((response) => {
                const controlDeskResult = response.data.controlDeskResult
                //отримуємо дані з поточного корту

                const data = controlDeskResult.filter((it) => it.cort === cort && it.gridId === item.gridId)[0]
                if (data) {
                    

                    //перевіряємо чи усі судді передали результати
                    const missData = []
                    judges.forEach((item) => {
                        let test = true
                        data.scorse.forEach((item2) => {
                            if (item === item2.judge) { test = false }
                        })
                        if (test) {
                            missData.push(<div key={item} style={{ textAlign: 'left' }}>- {state.myCoach(item)}</div>);
                        }
                    })

                    if (missData.length > 0) {
                        setSuccessMessage(null)
                        setErrorMessage(<>{lang[langSwitch].noResultsReceivedFromJudges/*Не отримано результатів від суддів: */}<br /><br />{missData}</>)
                    }else{ 
                        setData(data)
                        
                    }

                } else {
                    setSuccessMessage(null)
                    setErrorMessage(lang[langSwitch].athleteNotFinishedPerformance/*Обраний спортсмен ще не закінчив виступ*/)
                }

            })
            .catch((error) => {
                console.log(error)
                setSuccessMessage(null)
                setErrorMessage(lang[langSwitch].serverRequestError/*Помилка запиту до сервера:*/ + error)
            })
    }


    return(<>
    
        {errorMessage ? (<><br /><div className={sAdmin.errorMessage} onClick={() => { setErrorMessage(null); }}
            style={{ cursor: 'pointer' }}>
            {errorMessage}
        </div>
        </>) : null}
       
        {successMessage ? (<><br /><div className={sAdmin.successMessage} onClick={() => { setSuccessMessage(null); }}
            style={{ cursor: 'pointer' }}>
            {successMessage}
        </div>
        </>) : null}

     { Array.isArray(item.teamPoomsae)?(<>
                            <div className={index === 0 ? s.tableFirstItem : s.tableItem} key={item.gridId}>
                                <div className={s.battleNumForJudge}>{cort}.{battleNumber}</div>
                               
                                <div>
                                    <div className={s.athleteDivForJudge} >
                                        {state.poomsaeTeam(item.teamPoomsae)}
                                    </div>

                                    <div className={s.teamDivForJudge}>{state.regionShort(item.team)}</div>

                                </div>
                                {wiew ? (<>
                                    <div className={s.resultTableBox}>
                                <table className={s.buttonTable}>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <button className={s.tableButtons1}
                                                    onClick={()=>{perfomanse(item,battleNumber)}}
                                                >Розпочати виступ</button>
                                        </td>
                                        <td>
                                            <button className={s.tableButtons2}
                                                    onClick={()=>{perfomanceResult()}}>
                                                Результат</button>
                                        </td>
                                    </tr>
                                    </tbody> </table>
                                </div>
    </>) : (<>

 {/* викликаємо таблицю з результатами сгенеровану чи збережену */}
 {!switcher 
    ? (data ? resultTable(data.scorse) : null) 
    : (
        round === 'final' && item.judgeScore && Array.isArray(item.judgeScore.finalScore) && item.judgeScore.finalScore.length > 0 
        ? resultTable(item.judgeScore.finalScore) 
        : round === 'semiFinal' && item.judgeScore && Array.isArray(item.judgeScore.semiFinalScore) && item.judgeScore.semiFinalScore.length > 0 
        ? resultTable(item.judgeScore.semiFinalScore) 
        : round === 'preliminary' && item.judgeScore && Array.isArray(item.judgeScore.preliminaryScore) && item.judgeScore.preliminaryScore.length > 0 
        ? resultTable(item.judgeScore.preliminaryScore) 
        : null
    )
}


                                </>)}
                            </div>

                        </>):(<>
                            <div className={index === 0 ? s.tableFirstItem : s.tableItem} key={item.gridId}>
                                <div className={s.battleNumForJudge}>{cort}.{battleNumber}</div>
                                
                                <div className={s.athleteDivBox}>
                                    <div className={s.athleteDivForJudge}> {state.athleteName(item.id)}</div>

                                    <div className={s.teamDivForJudge}>{state.regionShort(item.team)}</div>

                                </div>

                                {wiew ? (<>
                                    <div className={s.resultTableBox}>
                                <table className={s.buttonTable}>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <button className={s.tableButtons1}
                                                    onClick={()=>{perfomanse(item,battleNumber)}}
                                                >Розпочати виступ</button>
                                        </td>
                                        <td>
                                            <button className={s.tableButtons2}
                                                     onClick={()=>{perfomanceResult()}}>
                                            Результат</button>
                                        </td>
                                    </tr>
                              </tbody>  </table>
                                </div>
                                    
                                </>) : (<>
                            {/* викликаємо таблицю з результатами сгенеровану чи збережену */}
                            {!switcher 
    ? (data ? resultTable(data.scorse) : null) 
    : (
        round === 'final' && item.judgeScore && Array.isArray(item.judgeScore.finalScore) && item.judgeScore.finalScore.length > 0 
        ? resultTable(item.judgeScore.finalScore) 
        : round === 'semiFinal' && item.judgeScore && Array.isArray(item.judgeScore.semiFinalScore) && item.judgeScore.semiFinalScore.length > 0 
        ? resultTable(item.judgeScore.semiFinalScore) 
        : round === 'preliminary' && item.judgeScore && Array.isArray(item.judgeScore.preliminaryScore) && item.judgeScore.preliminaryScore.length > 0 
        ? resultTable(item.judgeScore.preliminaryScore) 
        : null
    )
}

                                </>)}
                            </div>

     </>)
    }

    </>)
}

export default AthleteForCD