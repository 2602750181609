
import React, { useEffect, useState } from 'react';
import s from './menu.module.css'
import ControlPanel from '../controlPanel';
import state from '../../../../state/state';
import lang from '../../../../state/language';
import NewsForEdit from '../news/newsForEdit';
import AddNews from '../news/addNews';
import AddLeader from '../leadership/addLeader';
import LeaderForEdit from '../leadership/leaderForEdit';
import AddPhotoGalery from '../media/addPhotoGalery';
import GalleryForEdit from '../media/galleryForEdit';
import AddVideoGalery from '../media/addVideoGalery';
import VideoGalleryForEdit from '../media/videoGalleryForEdit';

function Menu({ isSmallScreen, langSwitch }) {
  const [activeTab, setActiveTab] = useState(lang[langSwitch].status   /*Статус*/);
  const styles = [s.tabs1, s.tabs2, s.tabs3];
   const [render, setRender] = useState(false)
  const [tabs, setTabs] = useState([
    // [
    //   'Перша кнопка', ' Друга кнопка', 'Третя кнопка', 'Четверта кнопка'
    // ],
    [
      lang[langSwitch].addVideo   /*Додати відео*/,
      lang[langSwitch].editVideo   /*Редагувати відео*/,
      lang[langSwitch].addPhoto   /*Додати фото*/,
      lang[langSwitch].editPhoto   /*Редагувати фото*/,
    ],   
     [lang[langSwitch].status   /*Статус*/,
    lang[langSwitch].addNewsTitle   /*Додати новину*/,
    lang[langSwitch].editNewsTitle   /*Редагувати новину*/,
    lang[langSwitch].addLeaderTitle   /*Додати керівника*/,
    lang[langSwitch].editLeadersTitle   /*Редагувати керівників*/,
    ]
  ]);

  const moderator = +state.userProfile.moderator === 1 || +state.userProfile.id === 1 ? true : false

  function switcher(index) {

    // Копіюємо масив стилів
    const newTabs = [...tabs];

    // Зберігаємо вибраний елемент і видаляємо його з масиву
    const [selectedTab] = newTabs.splice(index, 1);

    // Додаємо вибраний елемент в кінець масиву
    newTabs.push(selectedTab);

    // Оновлюємо стани
    setTabs(newTabs);

  }
useEffect(()=>{setRender(!render) },[langSwitch])

  return (<>
    {moderator ? (
      <div className={s.tabsContainer}>

        {tabs.map((tabs, index) => (
          <div className={styles[index]} key={`${styles[index]}-${tabs[0]}`}>
            {tabs.length > 0 &&
              tabs.map((tab, tabIndex) => (
                <div
                  key={tabIndex}
                  className={activeTab === tab ? `${s.tab} ${s.tabActive}` : s.tab}
                  onClick={() => {
                    setActiveTab(tab);
                    switcher(index);
                  }}
                >
                  {tab}
                </div>
              ))}
          </div>
        ))}
        <div className={s.tabContent}>

          {activeTab === lang[langSwitch].status   /*Статус*/ && (
            <ControlPanel isSmallScreen={isSmallScreen} langSwitch={langSwitch} />
          )}
          {activeTab === lang[langSwitch].addNewsTitle   /*Додати новину*/ && (
            <AddNews isSmallScreen={isSmallScreen} langSwitch={langSwitch} />
          )}

          {activeTab === lang[langSwitch].editNewsTitle   /*Редагувати новину*/ && (
            <NewsForEdit isSmallScreen={isSmallScreen} langSwitch={langSwitch} />
          )}
          {activeTab === lang[langSwitch].addLeaderTitle   /*Додати керівника*/ && (
            <AddLeader isSmallScreen={isSmallScreen} langSwitch={langSwitch} />
          )}
          {activeTab === lang[langSwitch].editLeadersTitle   /*Редагувати керівників*/ && (
            <LeaderForEdit isSmallScreen={isSmallScreen} langSwitch={langSwitch} />
          )}
          {activeTab === lang[langSwitch].addPhoto   /*Додати фото*/ && (
            <AddPhotoGalery isSmallScreen={isSmallScreen} langSwitch={langSwitch} />
          )}
          {activeTab === lang[langSwitch].editPhoto   /*Редагувати фото*/ && (
            <GalleryForEdit isSmallScreen={isSmallScreen} langSwitch={langSwitch} />
          )}
           {activeTab ===  lang[langSwitch].addVideo   /*Додати відео*/ && (
            <AddVideoGalery isSmallScreen={isSmallScreen} langSwitch={langSwitch} />
          )}
          {activeTab ===  lang[langSwitch].editVideo   /*Редагувати відео*/ && (
              <VideoGalleryForEdit isSmallScreen={isSmallScreen} langSwitch={langSwitch} />
            )}


        </div>
         
         
        
      </div>
    ) : (
      <ControlPanel isSmallScreen={isSmallScreen} langSwitch={langSwitch} />
    )}
  </>);
};

export default Menu;


