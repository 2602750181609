import s from '../mod/content/adminCompetition/adminCompetition.module.css'

//  { lang[langSwitch].videoTitle /*Назва відео*/ }
//   lang[langSwitch].editLeadersTitle/*Відсутня фотографія*/
// (<><br/></>)

const lang = {
  eng: {
    addVideoToGalleryInstruction:'To add a video to the gallery, you need to specify its name and insert a link from YouTube (to do this, on the YouTube website, write "Share" / "Insert" under the video, then copy the code and paste it into the appropriate field).',
    frameCode:'Frame code',
    videoTitle:'Video title',
    videoGallery:'Video gallery',
    deleteGallery:'Delete gallery?',
    editGallery:'Edit gallery',
    saveChangesToGallery:'Save changes to gallery?',
    pleaseWaitDataLoading:'Please wait, data is loading...',
    photoGallery:'Photo gallery',
    typeOfGallery:'Type of gallery',
    galeryTitle:'Gallery title',
    createVideoGaleryInstructions:'To create a video gallery, you need to specify the type, name, and videos URL.',    
    createGaleryInstructions:'To create a gallery, you need to specify the type, name, and upload photos.',
    createNewGalery:'Create a new gallery',
    addVideo:'Add video',
    editVideo:'Edit Video',
    addPhoto:'Add photo',
    editPhoto:'Edit photo',
    video:'Video',
    media:'Media',
    chooseJobTitle:'Choose job title',
    editLeaderTitle:'Edit leader', 
    deleteLeader:'Delete leader?',
    addLeaderWarning:'Fill in all the fields and upload a photo.',
    description:'Description',
    jobTitle:'Job title',
    missingPhoto:'Missing photo',
    editLeadersTitle:'Edit leaders', 
    addLeaderTitle:'Add leader',
    leadership:'Leadership',
    deleteNews:'Delete news?',
    new:'New',
    currents:'Current',
    filedImgDeleteWarning:'Failed to find the files you selected for deletion on the server. They may have already been deleted by another moderator. Please reauthorize on the website to obtain the latest information from the database.',
    RequestWillBeExecutedInTimeConnectNetwork:'Your request will be executed in the background the next time you connect to the network.',
    hidePassword:'Hide password',
    newUserRegistration:'New user registration',
    forgotYourPassword:'Forgot your password or login?',
    authorization:'Authorization',
    collapse:'Collapse',
    expand:'Expand',
    yourNewsNeedsTitleAndText:'Your news needs a title and text!',
    createNewArticle:'Create a new article?',
    photos:'Photos',
    newsText:'News text',    
    newsHeadline:'News headline',
    limitPicturesWarning:'The number of images in the article is limited to 5 pictures.',
    addArticle:'Add article',
    editNewsTitle:'Edit news',    
    addNewsTitle:'Add news',    
    news:'News',
    gridsSystemFooter:'GridsSystem - Sports event administration system adapted to your sport.',
    Rivne:'Rivne',
    Lutsk:'Lutsk', 
    IvanoFrankivsk:'Ivano-Frankivsk',
    Uzhhorod:'Uzhhorod', 
    KyivRegion :'Kyiv region',     
    Kyiv :'Kyiv',  
    Chernihiv:'Chernihiv',     
    Zhytomyr:'Zhytomyr',    
    Lviv:'Lviv',
    Chernivtsi:'Chernivtsi',
    Ternopil:'Ternopil',
    Khmelnytskyi:'Khmelnytskyi',
    Vinnytsia:'Vinnytsia',
    Cherkasy:'Cherkasy',
    Kropyvnytskyi:'Kropyvnytskyi',
    Odesa:'Odesa',
    Mykolaiv:'Mykolaiv',
    Kherson:'Kherson',
    Simferopol :'Simferopol ',
    Zaporizhzhia:'Zaporizhzhia',
    Dnipro:'Dnipro',
    Donetsk:'Donetsk',
    Luhansk:'Luhansk',
    Kharkiv:'Kharkiv',
    Poltava:'Poltava',
    Sumy:'Sumy',
    federationMilitaryAndSportsUkraine:'Federation of Military and Sports All-Around of Ukraine',
    createNewAthleteProfile:'Create a new athlete profile?',
    addAthleteInstruction:"To register an athlete, it is sufficient to fill in the red fields. To submit an athlete for a competition, all fields must be filled in without exception.",
    addAthleteWarning:"You have not filled in all the required fields. Also, don't forget to click the 'Set password' button",
    indicateAthleteGender:"Indicate the athlete's gender",
    change: 'Change',
    allocationOfPlacesOnShootingResults:'Allocation of places in categories based on shooting results',
    assignPlaces:'Assign Places',
    shootingResultInstruction: 'Enter the results of each shot in the "Points" field, separating the results of each shot with a comma. For example: "0, 8, 3".',
    shooting:'Shooting',
    handleGridsWarning:'WARNING! In manual mode, the system generates grids ONLY FOR THE CATEGORIES YOU HAVE SELECTED! Pay close attention to the position of the switches.',
    selectCategoryToTransferAthlete:'Select the category to which you want to transfer the athlete',
    selectAthleteYouWishRemoveFromCategory:'Select the athlete you wish to remove from the current category',
    selectCategoryCromRemoveAthlete:'Select the category from which you want to remove the athlete',
    B1:'B-1', B2:'B-2', B3:'B-3', B4:'B-4',
    bClass1:'Class B-1', bClass2:'Class B-2', bClass3:'Class B-3', bClass4:'Class B-4',
    chooseClass:'Choose a class',
    yourMessages:'Your messages',
    youHaveNoUnreadMessages: 'You have no unread messages',
    home:'Home', logIn:'Login', signUp:'Sign Up',  leadership:'Leadership', contactUs:'Contact us',
    requirements:'Participant requirements',
    cancel: 'Cancel', confirm: 'Confirm', print: 'Print', yearShort: 'y.', add: 'Add', emailNewsletter:'eMail newsletter',
    saveInDB: 'Save in DB', notSpecified: 'Not specified', closePrint: 'Close print', pleaseWait:'Please wait',
    serverRequestError: 'Server request error: ', exit: 'Exit',
    successfullSavedInDB: 'The changes made have been successfully saved in the Database',
    placeInOverallRanking:'Place in the overall ranking',
    selectAgeCategories:'Select age categories',
    lostLogin:'Important data has been lost. Please fill in the "Login" field',
   usedLoginByAnotherUser:'The login you selected is already in use by another user',
   errorOccurredWhileSavingImage:'An error occurred while saving the image',
   failedToSaveDataToIndexedDB:'Failed to save data to indexedDB',
   freestalNotes:'Freestal notes',
   traditionalNotes:'Traditional notes',
   poomsaeFreestyleRefereeNote:"Poomsae freestyle referee's note-taker",
   poomsaeTraditionalRefereeNote:"Poomsae traditional referee's note-taker",
    //Control Desk
    youDidNotAppointJudgesWorkOnCourts:"You did not appoint judges to work on the courts.",
    noBackup:'No backup',
    restoredAutomaticallySavedResults:'Restored automatically saved results',
   newPerformanceHasStarted:'A new performance has started',
    resultsBeenSavedForCourt:'The tournament results have been saved for court No. ',
    matchesNotCompletedOnYourCourt: 'Matches in the following categories have not yet been completed on your court:',
    restoreResults:'Restore results',
    musicalityAndChoreography:'Musicality and choreography',
    нarmony:'Harmony',
    сreativity:'Creativity',
    athleteNotFinishedPerformance:'The chosen athlete has not yet finished his performance',
    noResultsReceivedFromJudges:'No results received from judges: ',   
    youNotReceivDataForPerformance:'You have not received data for the current performance. Click the "next performance" button.',
    performanceResultSuccessfullySaved:'The performance results have been successfully saved',
    receivedDataForNewPerformance:'Received data for a new performance',
    secretaryNotProvidedData:'Please wait, the secretary has not provided data for the next performance',
    scoreboard:'Scoreboard', 
    notPossibleToTransferPerformance:"It was not possible to transfer the  to the judges' consoles",
    controlDesks:'Control desks',
    judgeNotes:"Judge's notes",
    exitFromControlDesk:'Exit from control desk?',
    nextPoomsae:'Next poomsae',
    nextPerformance:'Next performance',
    totalScore:'Total score',
    basicTechnique:'Basic technique',
    acrobatics:'Acrobatics',
    sparingLevel:'Sparing level',
    angleOfRotation:'Angle of rotation',
    numberOfKicks:'Number of kicks',
    heightOfJump:'Height',
    energyExpression:'Energy expression',
    rhythmAndCoordination:'Rhythm and coordination',
     strengthAndSpeed:'Strength and speed',
    chooseCourt:'Choose a court',
    remoteControl:'Remote control',
    performance:'Performance',
    reset:'Reset',
    cansel:'Cansel',
    accuracy:'Accuracy',
    presentation:'Presentation',   
    //EntryPoomsae
    allAthletesFromDB:'All athletes',
    myAthletes:'My athletes',
    failedToSetDateToBlockAthleteProfile:'Failed to set date to block athlete profile. Try again later.',
    dateForBlockingAthletesProfilesSuccessfullySet:"The date for blocking athletes' profiles has been successfully set.",
    yourAthletesApplicationsNotBeReceived:"Your athletes' applications could not be received. If you have declared your athletes, please contact the technical support service.",
    notSetDateToBlockAthleteProfiles:'You have not set a date to block athlete profiles.',
    secondPoomsae:'Second poomsae',
    firstPoomsae:'First poomsae',
    thirtySecondFinals:'1/32 finals',
    sixteenthFinals:'1/16 finals',
    eighthFinals:'1/8 finals',
    quarterFinals:'1/4 finals',
    final:'Final', 
    semifinal:'Semifinal', 
    qualifyingRound:'Qualifying round',
    numberOfPoomsae:'Number of poomsae',
    сontinue:'Continue',
    lotteryOfMandatoryComplexes:'Lottery of mandatory complexes',
    mandatoryComplex:'Mandatory complex',
    division:'Division',
    program: 'Program',
    categoryFromBigLetter:'Category',
    mandatoryPoomsaeInCategories:'Mandatory poomsae in categories',
    insufficientStaffingTeam:'The application was rejected due to insufficient staffing of the team!', 
    secondSportsClubIfNeeded:'Second Sports Club (if needed)',
    athletesOfSportsClub:'Athletes of the sports club',
    athletesOfRegionalCenter:'Athletes of the regional center',
    haveNotAthletesInCategory:'Your athletes are missing in the selected category.',
    minimumDeclare: "You need to declare a minimum in this category", 
    mixedTeamMix:'Mixed. Mixed team.',
    mixedTeamFemale:'Mixed. Team. Female.',
     mixedTeamMale:'Mixed. Team. Male.',
    mixedCouple:'Mixed. Couple.',
    mixedIndividualFemale:'Mixed. Individual. Female.',
    mixedIndividualMale:'Mixed. Individual. Male.',
     freestyleTeam:'Freestyle. Team.',
    freestyleCouple:'Freestyle. Couple.',
    freestyleIndividualFemale:'Freestyle. Individual. Female',
    freestyleIndividualMale:'Freestyle. Individual. Male.',
    traditionalTeamFemale:'Traditional. Team. Female.',
     traditionalTeamMale:'Traditional. Team. Male.',
    traditionalCouple:'Traditional. Couple.',
    traditionalIndividualFemale:'Traditional. Individual. Female.',
    traditionalIndividualMale:'Traditional. Individual. Male.',
    //AddPoomsaeCompetition
    NumberOfComplexesInRound: 'Specify the number of complexes in one round.',
    poomsaeLimitForAthlete:"Indicate how many categories an athlete can compete in.",
    classA:'Class "A"',
    classB:'Class "B"',
    classAB:'Classes "A" & "B"',
    chooseTournamentSystem:'Choose a tournament system',
   mixedTeamePoomsae:'Mixed teame', 
    mixedPoomsae:'Mixed',
    freestyle:'Freestyle',
    traditional:'Traditional',
    teamPoomsae:'Team',
    couples:'Pair',
    personal:'Personal',
    specifyPoomsaeСategories: 'Specify Poomsae categories',
    newTournamentPoomsae: 'New Poomsae Tournament',
    createPoomsae: 'Create poomsae',
    //RulesKeirugue
    source:'Source (English PDF)',   
    // Archive
    deleteFromArchive:'Delete from Archive',
    resultsOfTournamentSaveInDB: 'The results of the Tournament are entered in the Database. Your archive will be updated after re-authorization.',
    successfulDeletedFromArchive:'Tournament results have been successfully deleted from the archive.',
    saveChangesInArchive:'Save the changes made to the competition protocol to the archive?',
    deleteResultsFromArchiveQuery:'Delete the results of the tournament from the archive?',
    startTypingNameOfTournament:'Start typing the name of the tournament',
      //CommunityRegions
    HMSShort:'HMS: ',
    HMS:'Honored Master of Sport: ',
    IMSShort:'IMS: ',
    IMS:'Intl. Master of Sport: ',
    MSShort:'MS: ',
    MS:'Masters of Sport: ',
    CMSShort:'CMS: ',
    CMS:'Cdt. for Master of Sport: ',
    statisticDan:'Owners of 1-5 dans: ',
    statisticDanShort:'1-5 dan: ',
    athletesInGen:'Athletes',
    sportsClubsInGen:'Sports clubs',
    coachesInGen:'Coaches',
    judgesInGen:'Judges',
    competitionInYear:'Competition in year: ',
    competitionInYearShort:'Competition: ',
    statisticsByRegion:'Statistics by region',
    //Community
    sportsClubs:'Sports clubs',
    regions:'Regions',
    //FoodDistribution
    totalCost:(<>Total<br/>cost</>),
    customer:'Customer',
    //OrderForProduction
   costDailyOrder:(<>The cost of a daily <br/>order</>),
    orderForFoodProduction:'Order for food production',
    //FullFoodOrder
    sum:'sum',
    telShort:'tel. ',
    orderNo:'Order No. ',
    portions:'Portions',
    orderingFoodForTournamentParticipants:'Ordering food for tournament participants',
    //FoodTable
    distributionOrder:'Distribution order',
    productionOrder:'Production order',
    generalOrder:'General order',
    mealsForParticipants:'Meals for participants',
    //InformCards
   useProhibited:(<>Use<br/>Prohibited</>),
   warmUpArea:(<>Warm-up<br/>area</>),
   entryForbidden:(<>Entry<br/>Prohibited</>),
   entryInShoesProhibited:(<>Entry in shoes<br/>Prohibited</>),
   mealDistribution:(<>Meal<br/>distribution</>),
   preliminaryWeighing:(<>Preliminary<br/>weighing</>),
   weighingWoman:(<>Weighing<br/>woman</>),
   weighingMen:(<>Weighing<br/>men</>),
   mandateCommission:(<>Mandate<br/>commission</>),
   informationPointers:'Information pointers',
    //CardsForHall
   ambulance:'Ambulance',
   doctor:'Doctor',
   inspectionTest:(<>Inspection <br/> Test</>),
   tablesForTheHall:'Tables for the hall',
    //FoodCarts
   сhooseTypeOfCoupons:'Choose the type of coupons',
   numberOfPortions:'number of portions',
   drinks:'Drinks',
    //Permit
   passesForRefereeControl:'Passes for inspection test',
   signature:'Signature',
   fight:'Fight',
   surname:'Surname',
   name:"Name",
    //EslePrint
    pointers:'Pointers',
    tablets:'Tablets',
    foodStampsFull:'Food coupons',
    foodStamps:'Food coupons',
    passes:'Passes',
    miscellaneousForPrinting:'Miscellaneous for printing',
    //calculator
    warningInCalculator:'WARNING! When performing the calculation, the automatic transfer of athletes to another category is taken into account only after the publication of the grids.',
   changesNumberOfParticipants:' if the number of participants changes, apply for new calculations.',
    thisCalculationBeenMade:'This calculation has been made',
    numberOfDiplomas: "The number of diplomas is equal to the number of cups.",
    cupForBestJudge:'Cup for the best judge',
    cupForBestCoach:'Cup for the best coach',
    perAthleteArInGenderGategories:' (per athlete or separately in gender categories)',
    cupForBestAthlete:'Cup for the best athlete',
    cupsForFwardingFeams:'Cups for awarding teams',
    certificates:'Certificates of competition participants',
    awardCertificate:'award certificate',
    bronzeMedals:'Bronze medals',
    silverMedals:'Silver medals',
    goldMedals:'Gold medals',
    pcs:' pcs.',
    calculationOfAttributes:'Calculation of attributes',
    reference:(
    <><div className={s.info}><b>Reference:</b> </div>
    <div className={s.info}>
      <b>A certificate</b> - is an official document that confirms a person has successfully completed a training, course, exam, or other program. Certificates usually include the recipient's name, the name of the program, the date of issuance, the signature of an official, and can be used as a document that certifies qualifications or knowledge in a specific field.<br/>
      <b>Certificates are issued to all participants in competitions.</b>. 
      </div>
      <div className={s.info}> 
        <b>An award certificate</b> - is a recognition of achievement or success in a particular activity, typically awarded for accomplishments in education, sports, or professional activities. Award certificates usually include the recipient's name, a description of the achievements, and the date of issuance. They often have artistic designs and can be used as a document that certifies a high level of skills and knowledge in a specific field.<br/>
        <b>An award certificate is issued together with a medal</b> as evidence of the achieved victory. 
        </div>
        <div className={s.info}>
          <b>A diploma </b> is a testimony of completing an educational institution and of awarding an individual the qualification of bachelor, specialist, master, or scientific degree. An integral part of the main diploma is the transcript, which contains a list of studied subjects and completed practices with final grades, the title and grade of the final qualification work, the diploma number, and the university's official seal.<br/>
          <b>A diploma </b> is the highest tier among all listed documents and is therefore awarded <b>exclusively with Cups.</b>.
          </div></>),
    //Badge
    participantBadges:'Participant badges',
    //Protocols
    selectProtocolType:'Select the protocol type:',
    byCategories:'By categories',
    byTeams:'By teams',
    //randomWeigh
    randomWeighingAvailableAfterDraw:'Selective weighing is available only after the draw is completed and if the tournament brackets are saved.',
    printAndReportToCoaches:'Print and report to coaches',
    generate:'Generate',
    numberOfAthletes:'Number of athletes',
    randomWeigh:'Random weigh',
    cameUnderRandom:'selected for random weigh-in: ',
    informYouAboutAthletes:'we inform you that your athletes are registered for the tournament, ',
    dearM:'Dear ',
    DearF:'Dear ',
    //entryByJudge
    removeAthleteFromTournament: 'Remove the athlete from the tournament',
    withdrawnAthletesOffline: 'The application of your athletes for the tournament has been withdrawn offline.',
    entryAnAthlete: 'Entry an athlete',
    athleteAddedOffline: 'The athlete has been added offline.',
    //HandleGridsMake
    court: 'Court',
    generateGrids: 'Generate grids',
    matchCategoriesToCourts: 'Match categories to courts',
    // GridsModalWindow 
    saveResultsPerfomance: 'Save results of perfomance?',   
    saveResults: 'Save results',
    hideReport: 'Hide report',
    showReport: 'Show report',
    backToReport: 'Back to report',
    deleteFromDB: 'Delete from DB',
    saveGridsInDB: 'Save grids in DB',
    successfullyRemovedGrids: 'Grids have been successfully removed from the Database',
    successfullSavedResultsTournamentInDB: 'The results of the Tournament are entered in the Database. Your archive will be updated after re-authorization.',
    saveCompetitionResultsInDB: 'Save competition results in the Database?',
    saveGridsToDB: 'Save grids to Database?',
    deleteGridsFromDB: 'Delete grids from Database?',
    // Grids
    manuallyGenerateGrids: 'Manually generate grids',
    automaticallyGenerateGrids: 'Automatically generate grids',
    eMailMailingGrids: 'eMail mailing grids',
    savedGrids: 'Saved grids',
    youHaveNoSavedGrids: 'You have no saved grids. go to the "Generate grids" tab',
   
    tournamentGrids: 'Tournament grids',
    youNeedSaveGridsInDB: 'To start the mailing, you need to save the grids in the Database!',
    eMailDistributionGridsStarted: 'The eMail distribution has started.',
    startSendingGridsByEMail: 'Start sending tournament grids by eMail?',
    //AdminCompetition
    food: 'Food',
    apply: 'Apply',
    various: 'Various',
    calculator: 'Calculator',
    badges: 'Badges',
    changeCategory: 'Change category',
    weighingProtocols: 'Weighing protocols',
    withdrawAthlete: 'Withdraw athlete',
    addAthlete: 'Add athlete',
    grids: 'Grids',
    AdminCompetitionTitle: 'Administration of the tournament',
    // Results
    comeBack: 'Come back',
    gridPrinting: 'Grid printing',
    byRating: 'By rating',
    byRegions: 'By regions',
    byClubs: 'By clubs',
    byCoach: 'By coaches',
    byTeam: 'By teams',
    individual: 'Individual',
    competitionProtocols:'Competition protocols',
    fightResultOfCompetition:'Results of competitions in the "Fights" program',
    shootingResultOfCompetition:'Results of competitions in the "Shooting" program',
    overallCompetitionResults: 'Overall competition results',
    competitionResults: 'Competition results',
    //EntryPrint
    tournamentRegulations: "Tournament regulations",
    setDate: 'Set the date',
    setDateToBlockAthletesProfile: "Set a date to block the athletes' profile",
    setApplicationPrintingDate: 'Set the application printing date',
    positionHeadOfSportsDepartment: 'The position of the head of the sports department',
    positionOfChairmanOLFD: 'Position of the Chairman of the Regional medical and physical culture dispensary',
    positionOfHeadOfRegionalOffice: 'The position of the head of the regional office',
    enterDataForApplication: 'Enter data to form an application.',
    blockAthletesProfiles: "Block athletes' profiles until the specified date?",
    //withdraw
    withdrawApplication: 'Withdraw application',
    selectAthleteForWithdraw: 'Select the athlete whose application you want to withdraw.',
    applicationSuccessfullyWithdrawn: ' The application of your athletes for the tournament has been successfully withdrawn.',
    //Entry
    selectСategory:'Choose a category',
    declare: 'To declare',
    selectTeamForCompetetition: 'Select the team for which the athletes will compete!',
    selectTeam: 'Select a team',
    myTeam: 'My team',
    notSelectedAnyAthlete: 'You have not selected any athlete!',
    entrySuccessfulleSubmitted: 'The application has been successfully submitted.',
    haveNoPlases: 'Unfortunately, the number of athletes you selected exceeds the number of places available for registration.',
    registrationForTournamentCompleted: 'Unfortunately, registration for this tournament has been completed.',
    refusedRegistration: ' was refused registration for the tournament for the following reasons:',
    anAthlete: 'An athlete',
    notHaveCategories: 'Unfortunately, the organizer of the tournament did not specify the age categories for which the competition will be held.',
    //  foodEntry
    calculate: 'Calculate',
    send: 'Send',
    numberShort: 'Num.',
    number: 'Number',
    cost: 'Cost',
    date: 'Date',
    necessarily: 'necessarily',
    enterYourPhoneNumber: 'Enter your phone number',
    totalCostOfOrder: 'Total cost of the order',
    priceOfPortions: 'The price of portions is: ',
    enterNumberOfServings: 'Enter the number of servings for each meal.',
    needToYourPhoneNumber: 'You need to specify your phone number to place an order!',
    confirmSendingFoodEntry: 'Do you confirm sending the team meal request?',
    UAH: 'UAH',
    //CommentBlock.js
    enterAdditionalInformation: 'Enter additional information',
    additionalInformation: 'Additional Information',
    //Competition.js
    showCategories: 'Show categories',
    announcedTeams: 'Announced teams',
    showRegulations: 'Show regulations',
    printApplication: 'Print application',   
    submitAnApplication: 'Submit an application',
    applicationForFood: 'Application for food',
    regulationsPDF: 'Regulations PDF',
    coaches2: 'coaches',
    coaches1: 'coaches',
    categories2: 'categories',
    categories: 'categories',
    category: 'category',
    from: 'from',
    regionPlural: 'regions',
    regionSingular: 'region',
    teams: 'teams',
    teams2: 'teams',
    participantsShort: 'part.',
    participant: 'participant',
    participants1: 'participants',
    participants: 'participants',
    of: 'of',
    declared1: 'Declared',
    declared: 'Declared',
    //  Calendar.js
    calendarOfCompetitions: 'Calendar of competitions',
    //MyCompetition
    createEvent:'Create event',
    myEvents:'My events',
    eventManagement:'Event management',
    competition:'Competition',
    //CompetitionBlock.js
    toAdminister: 'To administer',
    edit: 'Edit',
    // EditCompetition.js
    poomsaeType:' - Forms',
    kyorugiType:' - Sparring',
    youDidNotSpecifyCategories: 'You did not specify the age categories of the competition participants!',
    deleteTournament: 'Delete tournament',
    deleteResults: 'Delete results',
    сurrentQuantity: 'Current quantity',
    ChooseTournamentClass: 'Choose a tournament class',
    addressOfEvent: 'Address of the event',
    nameOfTournament: 'Name of the tournament',
    registrationIsClosed: 'Registration is closed',
    chooseTypeOfTournament:'Choose the type of tournament',
    registrationIsOpen: 'Registration is open',
    tournamentEditing: 'Edit Tournament ',
    deletedTournConfirm: 'ATTENTION DELETING THE TOURNAMENT IS IRREVERSIBLE! Do you agree with the action?',
    saveChangesInTourn: 'Save changes to your tournament?',
    deleteResultsAthletesGrids: 'Delete declared athletes, grids and saved tournament results?',
    successfullDeletedTournament: 'The current tournament has been successfully deleted. You can leave the page.',
    rankTournForEdit: {
      '1': 'Regional championship',
      '2': 'All-Ukrainian tournament',
      '3': 'All-Ukrainian rating tournament',
      '4': 'Cup of Ukraine',
      '5': 'Championship of Ukraine'
    },
    //TextEditor.js
    addRegulationtoRestData: 'Add the Regulation to the rest of the data',
    informationInField: "<p>After entering the text, press the <b>'Add Regulation to the rest of the data'</b> button, then don't forget to press <b>'Add to DB'</b> to save the results!</p>",
    clickButtonToCreatingTournament: '<h1>Now click the "Add to DB" button to finish creating the Tournament!</h1>',
    clickButtonToEditingTournament:'<h1>Now click the "Add to Database" button to complete editing the Tournament!</h1>',
    //MakeFoodOptionsCell.js
    breakfast: 'Breakfast',
    lunch: 'Lunch',
    dinner: 'Dinner',
    //AddCompetition.js
    rankTourn: {//Клас турниру, використовується при створенні турниру
      '1. Regional championship': 1,
      '2. All-Ukrainian tournament': 2,
      '3. All-Ukrainian rating tournament': 3,
      '4. Cup of Ukraine': 4,
      '5. Championship of Ukraine': 5
    },
    сreateТewЕournament: 'Create a new tournament?',
    enterTextOfRegulation: 'Enter the text of the regulation',
    dinnerPrice: 'Dinner price',
    priceOfLunch: 'Lunch price',
    priceOfBreakfast: 'Breakfast price',
    foodNotOrganized: 'Food is not organized',
    specifyParametersForCatering: 'Specify the parameters for catering',
    downloadRegulationsWithStamps: 'Download the regulations with stamps (PDF)',
    sizeForCompetitionLogo: 'For the correct output of the image, the aspect ratio in the file must be 4/3',
    setCompetitionLogo: 'Set the competition logo',
    world: 'the world',
    olympic: 'olympic',
    growth: 'growth',
    weighty: 'weighty',
    typeOfWeightCategories: 'Set the type of weight categories: ',
    veterans:'Veterans',
    adults: 'Adults',
    juniors: 'Juniors',
    cadets: 'Sr.Youth',
    younkers: 'Younkers',
    jrYounkers: 'JrYounkers',
    children: 'Children',
    jrChildren: 'jrChildren',
    ageCategoriesOfTournament: 'Specify the age categories of the tournament',
    chooseChiefJudge: 'Choose the chief judge',
    chiefJudgeShort: 'Chief judge',
    chiefJudge: 'Chief judge',
    secretary: 'Secretary',
    chooseSecretary: 'Choose a secretary',
    numberOfDayangs: 'Specify the number of dayangs',
    numberOfAthletesOnCompetition: 'Enter the number of participants',
    dateEndOfCompetition: 'Choose the end date of the competition',
    dateStartOfCompetition: 'Choose the start date of the competition',
    tournamentClass: 'Specify the tournament class',
    addressDetails: 'Region, City, Institution and Address.',
    fullAddressOfTournament: 'Enter the full address of the tournament:',
    enterNameOfTournament: 'Enter the name of the tournament',
    mandatoryFields: 'Fields are mandatory.',   
    newTournamentKeirugue: 'New Keirugue Tournament',
    createNewTournament: 'Create a new tournament?',
    tournamentSuccessfullyCreated: 'A new tournament has been successfully created.',
    needDataForTournament: 'To create a Tournament, you must specify: the name of the tournament, the class of the tournament, the place, the dates of the number of participants, the number of courts and age categories (for kerugi), and the categories and tournament system (for poomsae)!',
    youNotSetTournamentDate: 'You have not set tournament dates!',
    createKeirugue: 'Create keirugue',    
    certification:'Certification',
    trainingСamp:'Trainings & Camps',
    //RemoveAthleteFromClub.js
    deleteCoachFromClubShort: 'Remove coach',
    deleteAthleteFromClubShort: 'Remove athlete',
    deleteCoachFromClub: 'Remove the coach from the club',
    deleteAthleteFromClub: 'Remove the athlete from the club',
    athletesExcludedFromClub: 'Selected athletes are excluded from the sports club',
    deleteSsersFromSportClub: 'Delete selected users from the sports club?',
    //editSportClub.js
    enterCityYourClub: 'Enter the city of your club:',
    makeChangesInSportClub: 'Make changes in the sports club?',
    sportClubSuccessfullyUpdated: 'The sports club has been successfully updated',
    failedUpdateClubInAthleteProfile: 'Failed to update club name in athlete profile: ',
    indicateYourCity: 'The "city" field cannot be empty!',
    clubNameCannotBeEmpty: "The name of the club cannot be empty!",
    //coachRequest.js
    reasonsRefusalTitle: 'Reasons for refusal of athletes to register for the tournament',
    noCommentsInCoachRequest: 'there are no comments and it is allowed to register for the competition',
    sportsInCoachRequest: ' are sports. ',
    remainingInCoachRequest: 'The remaining ',
    аthleteInCoachRequest: 'Athlete: ',
    byInCoachRequest: 'by',
    resultOfTeamVerification: 'Results of team verification',
    dateForCheckProfileOfAthlete: 'Enter the date on which you plan to register for the tournament:',    
    errorAthletes: {// повідомлення про відмову спортсмену у оеєстрації на турнир
      activFtu: '<br/><b>Not activated in FTU</b> (an annual fee to the Federation must be paid).',
      activSite: '<br/><b>Not activated on the site</b> (must pay a monthly fee for using the GridsTKD platform).',
      fatherName: "<br/>In the athlete's profile, the field <b>'fatherly'</b> is not filled. ",
      birthday: "<br/>The athlete's profile does not include<b> date of birth</b>.",
      sex: "<br/>Gender is not specified in the athlete's profile.",
      eMail: "<br/>There is no <b>e-Mail</b> specified in the athlete's profile.",
      photo: "<br/>There is no<b> photo</b> of the athlete in the profile.",
      weiht: "<br/>The athlete's weight is not indicated in the profile.",
      height: "<br/>The athlete's <b>height</b> is not specified in the profile.",
      coach: "<br/>The athlete's <b>coach</b> is not specified in the profile.",
      sportClub: "<br/>Sports Club</b> is not specified in the athlete's profile.",
      region: "<br/>The athlete's profile does not indicate <b>Regional office</b>.",
      dan: "<br/>The athlete's profile does not indicate <b>Qualification</b>.",
      rank: "<br/>The athlete's profile does not indicate <b>Sports category</b>.",
      fst: "<br/>There is no <b>PCSS</b> specified in the athlete's profile.",
      insuranceComp: "<br/>There is no <b>Insurance campaign</b> specified in the athlete's profile.",
      insuranceNum: "<br/>The <b>Series and number of the insurance policy</b> is not specified in the athlete's profile.",
      insuranceDate: "<br/>In the profile of the athlete, the <b>Duration of the insurance policy</b> is not specified.",
      insuranceActive: "</br>The term <b>of validity of the insurance policy expires</b> before the end of the competition (update the insurance policy and enter current data in the athlete's profile)",
      medicalCertificateNum: "<br/>The athlete's profile does not include <b>Series and number</b>.",
      medicalCertificateDate: "<br/>The athlete's profile does not indicate the <b>Duration of validity of the medical certificate</b>.",
      medicalCertificateActive: "</br>The term <b>validity of the medical certificate expires</b> before the end of the competition (pass a medical examination and enter current data in the athlete profile)",
      status: "<br/>There is no <b>Status</b> specified in the athlete's profile.",
      category: "<br/>The athlete does not fit any <b>age category</b> to participate in the selected tournament.",
    },
    //AthleteScale.js     
    currentHeight: 'Current height',
    newWalue: 'New value',
    currentWeight: 'Current weight',
    titleChangeAnthropometrics: 'Change anthropometrics to move athletes to another category',
    successfullyUpdatedAnthropometrics: 'The anthropometrics of your athletes have been updated',
    changeWeightData: 'Change data on weight and age of athletes?',
    //CreateSportClub.js
    notEnterLetterM: null,
    enterCityOfClub: 'Specify the city of the club ',
    enterNameOfClub: 'Enter the name of the club: ',
    sizeForClubLogo: 'For the correct output of the image, the aspect ratio in the file must be 1/1',
    installSportsClubLogo: 'Install the Sports Club logo',
    newSportsClub: 'New sports club',
    createClubRequest: 'Create a new sports club?',
    successfullyCreatedClub: 'The sports club was successfully created.',
    notSpacesAroundHyphen: 'Spaces are not allowed around the hyphen!',
    smallLetterBeforeApostrophe: 'The apostrophe must be followed by a small letter;',
    capitalLetterAfterDash: 'A capital letter must be placed after a dash about a space;',
    firstLetterMustBeCapitalized: 'The first letter of the name must be capitalized;',
    dotsNotFllowed: 'Dots are not allowed;',
    wrongCityNameInputFormat: 'Incorrect city name input format!',
    mustChooseCityWhereIsSportsClub: 'To create a sports club, you must specify the name of the city in which the sports club operates!',
    mustChooseRegionForCreateClub: 'To create a sports club, you must indicate your affiliation to the regional office on the "Edit profile" tab.',
    //MySportClubs.js
    removeAthlete: 'Remove athlete',
    removeCoach: 'Remove coach',
    deleteSportsClubButton: 'Delete sports club',
    editSportsClub: 'Edit sports club',
    athletes: 'Athletes',
    coaches: 'Coaches',
    checkingProfiles: 'Checking profiles',
    weighTeam: 'Weigh the team',
    notCreatedSportsClub: 'You have not yet created a sports club',
    createSportsClub: 'Create sports club',
    deleteSportsClub: 'Delete sports club?',
    successfulDeleteSportsClub: 'The sports club and the file have been successfully deleted, you can continue working',
    //SportClubChoice.js
    coachShort: 'C.',
    athleteShort: 'Athl.',
    team: 'Team',
    seniorCoachShort: 'Sr.coach',
    seniorCoach: 'Senior coach',
    choose: 'Choose',
    confirmYourChoice: 'Confirm your choice',
    //MyResultOnCompetitiion.js   
    individualResults: 'Individual results of the athlete',
    //ChangePasswordForm.js 
    password:'Password',   
    confirmNewPass: 'Confirm the new password',
    changePass: 'Change password',
    showPass: 'Show password',
    confirmNewPassword: 'Confirm the new password',
    newPass: 'New password',
    setPassword:'Set password',
    сurrentPass: 'Сurrent password',
    notConfirmPass: 'The new password does not match the confirmation.',
    passwordSymbolsWarnin: 'Password must contain at least 5 characters.',
    wrongCurrentPass: 'The current password is incorrect.',
    successfulPasswordChangeMessage: 'Password changed successfully. Do not forget to save the results!!! Bottom left button.',
    successfulPasswordAddMessage: 'Password successfully set. Do not forget to save the results!!! Bottom left button.',
       //editProfile.js
    deleteProfile: 'Delete profile',
    save: 'Save',
    photo: 'Photo',
     passwordChangeWarning: 'When changing the password, note that the system does not accept Cyrillic characters!',
   passwordaAddWarning: 'when setting a password, note that the system does not accept Cyrillic characters!',
   
     login: 'Login',
    eMailUsed: 'e-Mail is used for sending tournament grids and password recovery!',
    medicalCertificateDate: 'Val.Period of Med.Cert.',
    numberOfMedicalCertificate: 'Ser.& No.of Med.Cert.',
    insuranceDate: 'Val.Period of Ins.Policy',
    numberOfInsurancePolicy: 'Ser.& No.of Ins.Policy',
    insuranceCompany: 'Insur.Co.',
    chooseCoach: 'Choose a coach',
    coach: 'coach',
    chooseSportsClub: 'Choose a sports club',
    chooseRegionalOffice: 'Choose a regional office',
    regionalOffice: 'Regional office',
    weight: 'Weight',
    height: 'Height',
    enterYourGender: 'Enter your gender',
    warningSex: 'Be attentive! Further changes in the "Gender" field will be made only by the technical support service at the request of your coach!',
    dateOfBirth: 'Date of Birth',
    patronymic: 'Patronymic',
    firstName: 'First Name',
    lastName: 'Last Name',
    warningToSubmitCompetition: ' WARNING! To submit an application for participation in the competition, all fields of the form must be filled without exception!',
    deleteProfileQuery: 'ATTENTION DELETING A PROFILE IS IRREVERSIBLE! Do you agree with the action?',
    saveChangesInProfileQuery: 'Save changes to your profile?',
    usedMail: 'The e-mail you specified is already in use by another user',
    sexM: 'Male',
    sexF: 'Female',
    //Профіль
    results: 'Results',
    medicalCertificate: 'Medical certificate',
    insurancePolicy: 'Insurance policy',
    cm: 'сm.',
    kg: 'kg.',
    sex: 'Sex',
    placeInCategory: 'place in the category',
    placeInRating: 'place in the overall rating',
    points: 'points',
    rating: 'rating',
    isActivatedSite: 'Activated at Site',
    isActivatedFTU: 'Activated in FTU',
    fights: 'Fights',
    victory: 'Victory',
    place: 'Place',
    PCSS: 'PCSS',//Physical Culture and Sports Society
    rank: 'Rank',
    qualificationShort: 'Qualif.',
    qualification: 'Qualification',
    status: 'Status',
    YearOfBirth: 'DOB: ',
    sportsClubInProfile: 'Sports club',
    // контрольна панель
    activeSiteСonfirm: 'Selected users have been successfully activated on the Site',
    activeFtuСonfirm: 'Selected users have been successfully activated in FTU',
    activeProfilesQuery: 'Activate profiles of selected athletes?',
    removeModeratorСonfirm: 'Moderator status successfully revoke',
    removeModeratorQuery: 'Revoke moderator status from selected coaches?',
    appointModeratorСonfirm: 'Moderator status successfully granted',
    appointModeratorQuery: 'Give selected coaches moderator status?',
    removeJudgeСonfirm: 'Judge status successfully revoke',
    removeJudgeQuery: 'To revoke the status of the judge from the selected coaches?',
    removeRegionLeaderСonfirm: 'Region leader status successfully revoke',
    removeRegionLeaderQuery: 'To revoke the status of the region leader from the selected coaches?',
    appointRegionLeaderConfirm: 'The status of the region leader has been successfully granted',
    regionLeaderStatusQuery: 'Grant the selected coaches the statusregion leader?',
    removeCoachСonfirm: 'Coach status successfully revoke',
    removeCoachQuery: 'Revoke the coach status from selected athletes?',
    queryError: 'Axios query error failed to update status',
    coachStatusQuery: 'To give selected athletes the status of a coach?',
    judgeStatusQuery: 'To give selected athletes the status of a judge?',
    notChosenCandidate: 'You have not chosen any candidate!',
    grantedCoach: 'Coach status successfully granted',
    grantedJudge: 'Judge status successfully granted',
    onlyCoachModer: 'Only a coach can get the moderator status',
    onlyCoach: 'Only a coach can get the status of  region leader',
    attention: 'Attention',
    close: 'Close',
    ppl: 'ppl.',
    chosen: 'Chosen',
    startTypingClubName: 'Start typing club name',
    startTypingName: 'Start typing last name and first name',
    activateSite: 'Activate on the site',
    activateFTU: 'Activate in FTU',
    removeModerator: 'Remove a moderator',
    appointModerator: 'Appoint a moderator',
    removeJudge: 'Remove a judge',
    appointJudges:'Appoint a judges?',
    appointJudge: 'Appoint a judge',
    removeRegionLeader: 'Remove region leader',
    removeRegionLeaderButt: 'Remove region leader',
    appointRegionLeader: 'Appoint region leader',
    appointRegionLeaderButt: 'Appoint region leader',    
    appointCoach: 'Appoint coach',    
    // технічна інформація
    technicalInformation: 'Here you can get information about the technical side of our platform',
    welcome: 'Welcome to our website',
    //кнопки підтвердження
    yes: 'Yes',
    no: 'No',
    // навігаційна панель
    logout: 'Logout',
    documents: 'Documents',
    protocolsOfPresidium:'Protocols of Presidium',
    rulesOfCompetition:'Rules of competition',
    fightShoot:'Fight & Shoot',
    shooting:'Shooting',
    heptathlon:'Heptathlon',
    referenceToUsers: 'Reference',
    archive: 'Archive',
    community: 'Community',
    calendar: 'Calendar',
    editProfile: 'Edit profile',
    myСompetitions: 'My competitions',
    sportsClubShort: 'SС',
    sportsClub: 'Sports club',
    mySportsClubs: 'My sports clubs',
    myProfile: 'My profile',
    profile: 'Profile',
    controlPanel: 'Control panel',
    //хедер
    athletesInTitle: 'athletes',
    ourBest: 'Our best',
  },
  ukr: {
    addVideoToGalleryInstruction:'Щоб додати відео до галереї потрібно вказати його назву, та вставити посилання з YouTube (для цього на сайті YouTube під відео написніть "Поделитися"  / "Вставити" після чого скопіюйте  код та вставте у відповідне поле). ',
    frameCode:'Код фрейму',
    videoTitle:'Назва відео',
    videoGallery:'Відео галерея',
    deleteGallery:'Видалити галерею?',
    editGallery:'Редагувати галерею',
    saveChangesToGallery:'Зберегти зміни в галереї?',
    pleaseWaitDataLoading:'Зачекайте, дані завантажуються...',
    photoGallery:'Фото галерея',
    typeOfGallery:'Тип галереї',
    galeryTitle:'Назва галереї',
    createVideoGaleryInstructions:'Для створення галереї потрібно вказати тип, назву, та URL відео.',  
    createGaleryInstructions:'Для створення галереї потрібно вказати тип, назву, та завантажити світлини.',
    createNewGalery:'Створити нову галерею',
    addVideo:'Додати відео',
    editVideo:'Редагувати відео',
    addPhoto:'Додати фото',
    editPhoto:'Редагувати фото',
    video:'Відео',
    media:'Медіа',
    chooseJobTitle:'Оберіть посаду',
    editLeaderTitle:'Редагувати керівника',     
    deleteLeader:'Видалити керівника?',
    addLeaderWarning:'Заповніть усі поля та встановіть фото.',
    description:'Опис',
    jobTitle:'Посада',
    missingPhoto:'Відсутня фотографія',
    editLeadersTitle:'Редагувати керівників', 
    addLeaderTitle:'Додати керівника',
    leadership:'Керівництво',
    deleteNews:'Видалити новину?',
    new:'Нові',
    currents:'Поточні',
    filedImgDeleteWarning:'Невдалось знайти на сервері обрані вами файли для видалення. Можливо вони вже були видалені іншим модератором. Пройдіть повторну авторизацію на сайті для отримання актуальної інформації з Бази Даних.',
    RequestWillBeExecutedInTimeConnectNetwork:'Ваш запит буде виконано у фоновому режимі під час наступного підключення до мережі.',
    hidePassword:'Приховати пароль',
    newUserRegistration:'Реєстрація нового користувача',
    forgotYourPassword:'Забули пароль чи логін?',
    authorization:'Авторизація',
    collapse:'Згорнути',
    expand:'Розгорнути',
    yourNewsNeedsTitleAndText:'Ваша новина потребує назви та тексту!',
    createNewArticle:'Створити нову статтю?',
    photos:'Світлини',
    newsText:'Текст новини', 
    newsHeadline:'Заголовок новин',
    limitPicturesWarning:'Кількість зображень в статті обмежено 5 світлинами.',
    addArticle:'Додати статтю',
    editNewsTitle:'Редагувати новини', 
    addNewsTitle:'Додати новину', 
    news:'Новини',
    gridsSystemFooter:'GridsSystem - Система адміністрування спортивних подій адаптована до вашого виду спорту.',
    KyivRegion :'Київська обл.',      
    Kyiv :'Київ',     
    Chernihiv:'Чернігів',     
    Zhytomyr:'Житомир',     
    Rivne:'Рівне',     
    Lutsk:'Луцьк',     
    Lviv:'Львів', 
    Uzhhorod:'Ужгород',    
    IvanoFrankivsk:'Івано-Франківськ',
    Chernivtsi:'Чернівці',
    Ternopil:'Тернопіль',
    Khmelnytskyi:'Хмельницький',
    Vinnytsia:'Вінниця',
    Cherkasy:'Черкаси',
    Kropyvnytskyi:'Кропівницький',
    Odesa:'Одеса',
    Mykolaiv:'Миколаїв',
    Kherson:'Херсон',
    Simferopol :'Симферопіль',
    Zaporizhzhia:'Запоріжжя',
    Dnipro:'Дніпро',
    Donetsk:'Донецьк',
    Luhansk:'Луганск',
    Kharkiv:'Харків',
    Poltava:'Полтава',
    Sumy:'Суми',
    federationMilitaryAndSportsUkraine:'Федерація військово - спортивних багатоборств України',
    createNewAthleteProfile:'Створити новий профіль спортсмена?',
   addAthleteInstruction:"Для реєстрації спортсмена достатньо заповнити червоні поля, для заявки спортсмена на змагання потрібно заповнити усі поля без виключення.",
    addAthleteWarning:"Ви заповнили не усі обовязкові поля. Також не забудьте натиснути кнопку 'Встановити пароль'.",
    indicateAthleteGender:"Вкажіть стать спортсмена",
    change: 'Змінити',
    allocationOfPlacesOnShootingResults:'Розподілення місць в категоріях за результатами стрільби',
    assignPlaces:'Призначити місця',
    shootingResultInstruction: 'Введіть результати кожного пострілу в поле "Очки" відокремлюючи комою результати  пострілів. Наприклад: "0, 8, 3".',
    shooting:'Стрільба',
    handleGridsWarning:'Увага! В ручному режимі система генерує сітки ТІЛЬКИ ПО ОБРАНИМ ВАМИ КАТЕГОРІЯМ! Уважно слідкуйте за положенням перемикачів.',
    selectCategoryToTransferAthlete:'Оберіть категорію до якої ви хочете перенести спортсмена',
    selectAthleteYouWishRemoveFromCategory:'Оберіть спортсмена якого ви бажаєте вилучити з поточної категорії',
    selectCategoryCromRemoveAthlete:'Оберіть категорію з якої ви хочете вилучити спортсмена',
    B1:'Б-1', B2:'Б-2', B3:'Б-3', B4:'Б-4',
    bClass1:'Клас Б-1', bClass2:'Клас Б-2', bClass3:'Клас Б-3', bClass4:'Клас Б-4',
    chooseClass:'Оберить клас',
    youHaveNoUnreadMessages: 'Ви не маєте непрочитаних повідомлень',
    yourMessages:'Ваші повідомлення',
    home:'Головна',  logIn:'Вхід', signUp:'Реєстрація', leadership:'Керівництво', contactUs:'Контакти',
    requirements:'Вимоги до учасників',
    cancel: 'Скасувати', confirm: 'Погодити', print: 'Друкувати', yearShort: 'р.', add: 'Додати',emailNewsletter:'eMail розсилка',
    saveInDB: 'Зберегти в БД', notSpecified: 'Не вказано', closePrint: 'Закрити друк', pleaseWait:'Зачекайте будь ласка',
    serverRequestError: 'Помилка запиту до сервера: ', exit: 'Вийти',
    successfullSavedInDB: 'Внесені зміни успішно збережено в Базі Даних',
    placeInOverallRanking:'місце в загальному рейтингу',
    lostLogin:'Було втрачено важливі дані. Будь ласка заповніть поле "Логін"',
    usedLoginByAnotherUser:'Обраний вами логін вже використовується іншим користувачем',
    errorOccurredWhileSavingImage:'Помилка під час збереження зображення',
    failedToSaveDataToIndexedDB:'Не вдалося зберегти дані в indexedDB',
    freestalNotes:'Нотатки фрістал',
    traditionalNotes:'Нотатки традиц.',
    poomsaeFreestyleRefereeNote:"Суддівський нотатник для фрістал Пумсе",
    poomsaeTraditionalRefereeNote:"Суддівський нотатник для традиційного Пумсе",

    //ControlDesk
    youDidNotAppointJudgesWorkOnCourts:"Ви не призначили суддів для роботи на кортах.",
    noBackup:'Відсутня резервна копія',
    restoredAutomaticallySavedResults:'Відновлено автоматично збережені результати',
    newPerformanceHasStarted:'Розпочато новий виступ',
    resultsBeenSavedForCourt:'Збережено результати турниру по корту № ',
    matchesNotCompletedOnYourCourt: 'На вашому корті ще не завершені поєдинки в наступних категоріях:',
    restoreResults:'Відновити результати',
    musicalityAndChoreography:'Музичність та хореографія',
    нarmony:'Гармонія',
    сreativity:'Креативність',
    athleteNotFinishedPerformance:'Обраний спортсмен ще не закінчив виступ',
    noResultsReceivedFromJudges:'Не отримано результатів від суддів: ',   
    youNotReceivDataForPerformance:'Ви не отримали дані для проведення поточного виступу. Натисніть кнопку "Наступний виступ".',
    performanceResultSuccessfullySaved:'Результати виступу успішно збережено',
    receivedDataForNewPerformance:'Отримано дані для нового виступу',
    secretaryNotProvidedData:'Будь ласка зачекайте, секретар не надав дані наступного виступу',
    scoreboard:'Монітор', 
    notPossibleToTransferPerformance:" Не вдалось передати виступ на пульти суддів",
    controlDesks:'Пульти',
    judgeNotes:"Суддівські нотатки",
    exitFromControlDesk:'Закрити пульт?',
    nextPoomsae:'Наступне Пумсе',
    nextPerformance:'Наступний виступ',
    totalScore:'Загальний рахунок',
    basicTechnique:'Базова техника',
    acrobatics:'Акробатика',
    sparingLevel:'Спарингова техніка',
    angleOfRotation:'Кут повороту',
    numberOfKicks:'Кількість ударів',
    heightOfJump:'Висота',
    energyExpression:'Енергійність',
    rhythmAndCoordination:'Ритм та координація',
    strengthAndSpeed:'Сила та швидкість',
    chooseCourt:'Оберіть корт',
    remoteControl:'Пульт',
    performance:'Виступ',
    reset:'Скинути',
    cansel:'Скасувати',
    accuracy:'Техніка',
    presentation:'Презентація',    
        //EntryPoomsae
        allAthletesFromDB:'Усі спортсмени',
        myAthletes:'Мої спортсмени',
        failedToSetDateToBlockAthleteProfile:'Невдалось встановити дату для блокування профілю спортсменів. Спробуйте пізніше.',
        dateForBlockingAthletesProfilesSuccessfullySet:"Дату для блокування профілів спортсменів успішно встановлено.",
        yourAthletesApplicationsNotBeReceived:"Невданось отримати заявки ваших спортсменів. Якщо ви заявляли ваших спортсменів, будь ласка зверніться в службу тех.підтримки.",
        notSetDateToBlockAthleteProfiles:'Ви не встановили дату для блокування профілів спортсменів.',
        secondPoomsae:'Друге пумсе',
        firstPoomsae:'Перше пумсе',
        thirtySecondFinals:'1/32 фіналу',
        sixteenthFinals:'1/16 фіналу',
        eighthFinals:'1/8 фіналу',
        quarterFinals:'1/4 фіналу',
        final:'Фінал', 
        semifinal:'Півфінал', 
        qualifyingRound:'Відбірковий тур',
        numberOfPoomsae:'Кількість пумсе',
        сontinue:'Продовжити',
        selectAgeCategories:'Оберіть вікові категорії',
        lotteryOfMandatoryComplexes:"Жеребкування обов'язкових комплексів",
        mandatoryComplex:"Обов'язковий комплекс",
        division:'Дивізіон',
        program: 'Програма',
        categoryFromBigLetter:'Категорія',
        mandatoryPoomsaeInCategories:"Обов'язкові Пумсе в категорія",
        insufficientStaffingTeam:'Заявку відхилено через неукомплектованість команди!',
        secondSportsClubIfNeeded:'Другий Спортклуб (за потреби)',
        athletesOfSportsClub:'Спортсмени спортивного клубу',
        athletesOfRegionalCenter:'Спортсмени обласного осередку',
        haveNotAthletesInCategory:'В обраній категорії відсутні Ваші спортсмени.',
        minimumDeclare: "В цю категорію потрібно заявити мінімум", 
        mixedTeamMix:'Змішане. Змішані Команди.',
        mixedTeamFemale:'Змішане. Командні виступи. Жінки.',
        mixedTeamMale:'Змішане. Командні виступи. Чоловіки.',
        mixedCouple:'Змішане. Парні виступи.',
        mixedIndividualFemale:'Змішане. Індивідуальні виступи. Жінки.',
        mixedIndividualMale:'Змішане. Індивідуальні виступи. Чоловіки.',
        freestyleTeam:'Фрістайл. Командні виступи.',
        freestyleCouple:'Фрістайл. Парні виступи.',
        freestyleIndividualFemale:'Фрістайл. Індивідуальні виступи. Жінки.',
        freestyleIndividualMale:'Фрістайл. Індивідуальні виступи. Чоловіки.',
        traditionalTeamFemale:'Традиційне. Командні виступи. Жінки.',
         traditionalTeamMale:'Традиційне. Командні виступи.Чоловіки.',
        traditionalCouple:'Традиційне. Парні виступи.',
        traditionalIndividualFemale:'Традиційне. Індивідуальні виступи. Жінки.',
        traditionalIndividualMale:'Традиційне. Індивідуальні виступи. Чоловіки',
     //AddPoomsaeCompetition
     NumberOfComplexesInRound: 'Вкажіть кількість комплексів в одному турі.',
     poomsaeLimitForAthlete:"Вкажіть у скількох категоріях може змагатись спорстмен.",
     classA:'Клас "A"',
     classB:'Клас "Б"',
     classAB:'Класи "A" та "Б"',
     chooseTournamentSystem:'Oберіть турнирну систему',
     mixedTeamePoomsae:'Змішані Команди', 
     mixedPoomsae:'Змішане',
     freestyle:'Фристайл',
     traditional:'Традиційне',
     teamPoomsae:'Командні',
     couples:'Парні',
     personal:'Особисті',
    newTournamentPoomsae: 'Новий турнир з Пумсе',
    specifyPoomsaeСategories: 'Вкажіть категорії Пумсе',
    createPoomsae: 'Турнір Пумсе',
    //RulesKeirugue
    source:'Джерело',
    // Archive
    deleteFromArchive:' Видалити з Архіву',
    resultsOfTournamentSaveInDB: 'Результати Турниру занесено в Базу Даних. Ваш архів буде оновлено після повторної авторизації.',
    successfulDeletedFromArchive:'Результати Турниру успішно видалено з архіву.',
    saveChangesInArchive:'Зберегти в архів зміни внесені в протокол змаганнь?',
    deleteResultsFromArchiveQuery:'Видалити результати турниру з архіву?',
    startTypingNameOfTournament:'Почніть вводити назву турниру',     
    //CommunityRegions
    HMSShort:'ЗМC: ',
    HMS:'Заслужених майстрів спорту: ',
    IMSShort:'МСМК: ',
    IMS:'Майстрів спорту міжн. класу: ',
    MSShort:'МС: ',
    MS:'Майстри спорту: ',
    CMSShort:'КМС: ',
    CMS:'Канд. в майстри спорту: ',
    statisticDan:'Володарів 1-5 данів: ',
    statisticDanShort:'1-5 дан: ',
    athletesInGen:'Спортсменів',
    sportsClubsInGen:'Спортклубів',
    coachesInGen:'Тренерів',
    judgesInGen:'Суддів',
    competitionInYear:'Щорічних турнирів: ',
    competitionInYearShort:'Турнирів: ',
    statisticsByRegion:'Статистика по осередку',       
    //Community
        sportsClubs:'Спортклуби',
        regions:'Осередки',
    //FoodDistribution
    totalCost:(<>Загальна<br/>вартість</>),
    customer:'Замовник',      
    //OrderForProduction
       costDailyOrder:(<>Вартість добового <br/>замовлення</>),
       orderForFoodProduction:'Замовлення на виробництво харчування',
    //FullFoodOrder
    sum:'сума',
    telShort:'тел. ',
    orderNo:'Замовлення № ',
    portions:'Порції',
    orderingFoodForTournamentParticipants:'Замовлення на харчування учасників турниру',
    //FoodTable
    distributionOrder:'Роздача замовлення',
    productionOrder:'Замовлення на виробництво',
    generalOrder:'Загальне замовлення',
    mealsForParticipants:'Харчування учасників',
    //InformCards
   useProhibited:(<>Користуватися<br/>Заборонено</>),
   warmUpArea:(<>Зона<br/>розминки</>),
   entryInShoesProhibited:(<>Вхід у взутті<br/>Заборонено</>),
   entryForbidden:(<>Вхід<br/>Заборонено</>),
   mealDistribution:(<>Видача<br/>обідів</>),
   preliminaryWeighing:(<>Попереднє<br/>зважування</>),
   weighingWoman:(<>Зважування<br/>жінки</>),
   weighingMen:(<>Зважування<br/>чоловіки</>),
   mandateCommission:(<>Мандатна<br/>комісія</>),
   informationPointers:'Інформаційні вказівники',  
    //CardsForHall
   ambulance:'Швидка допомога',
   doctor:'Лікар',
   inspectionTest:(<>Суддівській <br/> контроль</>),
   tablesForTheHall:'Таблички для зали',   
    //FoodCarts
   сhooseTypeOfCoupons:'Оберіть тип талонів',
   numberOfPortions:'кіл-ть порцій',
   drinks:'Напої',   
    //Permit
   passesForRefereeControl:'Перепустки для суддівського контролю',
   signature:'Підпис',
   fight:'Бій',
   surname:'Призвище',
   name:"Ім'я",    
    //EslePrint
    pointers:'Вказівники',
    tablets:'Табличкі',
    foodStampsFull:'Талони на харчування',
    foodStamps:'Талони на харчув.',
    passes:'Перепустки',
    miscellaneousForPrinting:'Різне для друку',    
    //calculator
    warningInCalculator:' Увага! При розрахунку калькуляції автоматичний перенос спортсменів в іншу категорію враховується тільки після публікації сіток.',
    changesNumberOfParticipants:' при зміні кількості учасників зверніться за новими розрахунками.',
    thisCalculationBeenMade:'Дану калькулацію складено',
    numberOfDiplomas: "Кількість дипломів дорівнює кількості кубків.",
    cupForBestJudge:'Кубок кращому судді',
    cupForBestCoach:'Кубок кращому тренеру',
    perAthleteArInGenderGategories:' (одному спортсмену , чи окремо в гендерних категоріях)',
    cupForBestAthlete:'Кубок кращому спортсмену',
    cupsForFwardingFeams:'Кубків для нагородження команд',
    certificates:'Сертифікатів учасників змаганнь',
    awardCertificate:'Грамот до медалей',
    bronzeMedals:'Бронзових медалей',
    silverMedals:'Срібних медалей',
    goldMedals:'Золотих медалей',
    pcs:' шт.',
    calculationOfAttributes:'Калькуляція атрибутикі',
    reference: (
      <>
          <div className={s.info}><b>Довідка:</b></div>
          <div className={s.info}>
              <b>Сертифікат</b> - це офіційний документ, який підтверджує, що людина успішно пройшла тренінг, курс, екзамен або іншу програму. Сертифікати зазвичай містять ім'я отримувача, назву програми, дату видачі, підпис посадової особи та можуть бути використані як документ, що підтверджує кваліфікацію або знання у конкретній галузі.<br/>
              <b>Сертифікат видається усім учасникам змаганнь</b>.
          </div>
          <div className={s.info}>
              <b>Грамота</b> - це відзнака за досягнення або успіх у певній діяльності, яка зазвичай вручається в нагороду за досягнення у навчанні, спорті або професійній діяльності. Грамоти зазвичай містять ім'я отримувача, опис досягнень та дату видачі. Вони часто мають художнє оформлення та можуть бути використані як документ, що підтверджує високий рівень вмінь та знань у певній галузі.<br/>
              <b>Грамота видається разом з медаллю</b> як засвідчення досягнутої перемоги.
          </div>
          <div className={s.info}>
              <b>Диплом</b> є свідченням про закінчення навчального закладу та про присвоєння фізичній особі кваліфікації бакалавра, спеціаліста, магістра або наукового ступеня. Складовою частиною основного диплома вважається вкладиш, який вмістить список вивчених дисциплін і пройдених практик з підсумковими оцінками, назву і оцінку випускної кваліфікаційної роботи, номер диплома і гербову печатку вишу.<br/>
              <b>Диплом</b> є найвищою ланкою з усіх перерахованих документів тому він вручається <b>виключно з кубками</b>.
          </div>
      </>
  ),
   //Badge
    participantBadges:'Бейджи учасників',    
    //Protocols
    selectProtocolType:'Оберіть тип протоколів:',
    byCategories:'По категоріях',
    byTeams:'По командах',    
    //randomWeigh
    randomWeighingAvailableAfterDraw:'Вибіркове зважування доступне тільки після завершення жеребкування. При наявності збережених турнирних сіток.',
    printAndReportToCoaches:'Друкувати та повідомити тренерам',
    generate:'Згенерувати',
    numberOfAthletes:(<>Кіл-ть<br/>спортсменів</>),
    randomWeigh:'Вибіркове зважування',
    cameUnderRandom:'попали під вибіркове зважування: ',
    informYouAboutAthletes:'повідомляємо Вам, що  Ваші спортсмени заявлені на турнир, ',
    dearM:'Шановний ',
    DearF:'Шановнa ',
    //entryByJudge
    removeAthleteFromTournament: 'Зняти спортсмена з турниру',
    withdrawnAthletesOffline: 'Заявку ваших спортсменів на туртир відкликано в режимі offline.',
    entryAnAthlete: 'Заявити спортсмена',
    athleteAddedOffline: 'Спортсмена додано в режимі offline.',
    //HandleGridsMake
    court: 'Корт',
   
    matchCategoriesToCourts: 'Задайте відповідність категорій до кортів',
    // GridsModalWindow
    saveResultsPerfomance: 'Зберегти результати виступу?',
    saveResults: 'Зберегти результати',
    hideReport: 'Приховати звіт',
    showReport: 'Показати звіт',
    backToReport: 'Назад до звіту',
    deleteFromDB: 'Видалити з БД',
    saveGridsInDB: 'Зберегти сітки в БД',
    successfullyRemovedGrids: 'Сіткі було успішно видалено з Бази Даних',
    successfullSavedResultsTournamentInDB: 'Результати змагань занесено в Базу Даних. Ваш архів буде оновлено після повторної авторизації.',
    saveCompetitionResultsInDB: 'Зберегти результати змаганнь в Базі даних?',
    saveGridsToDB: 'Зберегти сітки в Базі даних?',
    deleteGridsFromDB: 'Видалити сітки з Бази даних?',
    // Grids
    manuallyGenerateGrids: 'Вручну генерувати сітки',
    automaticallyGenerateGrids: 'Автоматично генерувати сітки',
    eMailMailingGrids: 'eMail розсилка сіток',
    savedGrids: 'Збережені сітки',
    youHaveNoSavedGrids: 'У вас немає збережених сіток. Перейдіть у вкладку "Генерувати сітки"',
    generateGrids: 'Генерувати сітки',
    tournamentGrids: 'Турнірні сітки',
    youNeedSaveGridsInDB: 'Для розпочатку розсилки потрібно зберегти сітки в Бази Даних!',
    eMailDistributionGridsStarted: 'Розпочато eMail розсилку.',
    startSendingGridsByEMail: 'Розпочати роззсилку турнірних сіток по eMail?',
    // AdminCompetition
    food: ' Харчування',
    apply: ' Подати заявку',
    various: 'Різне',
    calculator: 'Калькулятор',
    badges: 'Бейджи',
    changeCategory: 'Змінити категорію',
    weighingProtocols: 'Протоколи зважування',
    withdrawAthlete: 'Зняти спортсмена',
    addAthlete: 'Додати спортсмена',
    grids: 'Сітки',
    AdminCompetitionTitle: 'Адміністрування турниру',
    // Results
    comeBack: 'Повернутись',
    gridPrinting: 'Друк сіток',
    byRating: 'По рейтингу',
    byRegions: 'По осередках',
    byClubs: 'По клубах',
    byCoach: 'По тренерах',
    byTeam: 'Командні',
    individual: 'Індивідуальні',
    competitionProtocols:'Протоколи змаганнь',
    fightResultOfCompetition:'Результати змаганнь у програмі "Двобої"',
    shootingResultOfCompetition:'Результати змаганнь у програмі "Стрільба"',
    overallCompetitionResults: 'Загальні результати змаганнь',
    competitionResults: 'Результати змаганнь',
    //EntryPrint
    tournamentRegulations: "Регламент турніру",
    setDate: 'Встановити дату',
    setDateToBlockAthletesProfile: "Встановіть дату для блокування профілю спортсменів",
    setApplicationPrintingDate: 'Встановіть дату друку заявки',
    positionHeadOfSportsDepartment: 'Посада Голови управління спорту (називний відмінок)',
    positionOfChairmanOLFD: ' Посада Голови ОЛФД (називний відмінок)',
    positionOfHeadOfRegionalOffice: 'Посада Голови обласного осередку (називний відмінок)',
    enterDataForApplication: 'Вкажіть дані для формування заявки.',
    blockAthletesProfiles: "Встановити блокування профілів спортсменів до вказаної дати?",
    //withdraw
    withdrawApplication: 'Відкликати заявку',
    selectAthleteForWithdraw: 'Оберіть спортсмена заявку якого ви бажаєте відкликати.',
    applicationSuccessfullyWithdrawn: 'Заявку ваших спортсменів на туртир успішно відкликано.',
    //Entry
    selectСategory:'Оберіть категорію',
    declare: 'Заявити',
    selectTeamForCompetetition: 'Оберіть команду, за яку виступатимуть спортсмени!',
    selectTeam: 'Оберіть команду',
    myTeam: 'Моя команда',
    notSelectedAnyAthlete: 'Ви не обрали жодного спортсмена!',
    entrySuccessfulleSubmitted: 'Заявку успішно подано.',
    haveNoPlases: 'Нажаль кількість обраних вами спорстменів перевищує кількість доступних для реєстрації місць.',
    registrationForTournamentCompleted: 'Нажаль реєстрацію на цей турнир завершено.',
    refusedRegistration: ' відмовлено в реєстрації на турнир по наступних причинах:',
    anAthlete: 'Спортсмену',
    notHaveCategories: 'Нажаль організатор турніру не вказав  категорій по яких проводитимуться змагання.',
    //  foodEntry
    calculate: 'Розрахувати',
    send: 'Відправити',
    numberShort: 'Кіл-ть',
    number: 'Кількість',
    cost: 'Вартість',
    date: 'Дата',
    necessarily: "обов'язково",
    enterYourPhoneNumber: 'Вкажіть номер вашого телефону',
    totalCostOfOrder: 'Загальна вартість замовлення',
    priceOfPortions: 'Ціна порцій становить: ',
    enterNumberOfServings: 'Вкажіть кількість порцій на кожен прийом їжі.',
    needToYourPhoneNumber: 'Для оформлення замовлення потрібно вказати ваш номер телефону!',
    confirmSendingFoodEntry: 'Ви підтверджуєте відправку заявки на харчування Команди?',
    UAH: 'грн.',
    //CommentBlock.js
    enterAdditionalInformation: 'Внесіть додаткову інформацію',
    additionalInformation: 'Додаткова інформація',
    //Competition.js
    showCategories: 'Показати категорії',
    announcedTeams: 'Заявлені Команди',
    showRegulations: 'Показати регламент',
    printApplication: 'Друкувати заявку',
    submitAnApplication: 'Подати заявку',
    applicationForFood: 'Заявка на харчування',
    regulationsPDF: 'Регламент PDF',
    coaches2: 'тренера',
    coaches1: 'тренерів',
    categories2: 'категорій',
    categories: 'категорії',
    category: 'категорія',
    from: 'з',
    regionPlural: 'регіонів',
    regionSingular: 'регіону',
    teams: 'команд',
    teams2: 'Команди',
    participantsShort: 'уч.',
    participant: 'учасник',
    participants1: 'учасникa',
    participants: 'учасників',
    of: 'з',
    declared1: 'Заявлений',
    declared: 'Заявлено',
    //  Calendar.js
    calendarOfCompetitions: 'Календар змаганнь',
        //MyCompetition
        createEvent:'Створити захід',
        myEvents:'Мої заходи',
        eventManagement:'Керування заходами',
        competition:'Змагання',
    //CompetitionBlock.js      
    toAdminister: 'Адмініструвати',
    edit: 'Редагувати',
    // EditCompetition.js
   poomsaeType:' - Форми',
    kyorugiType:' - Бої',
    youDidNotSpecifyCategories: 'Ви не вказали вікові категорії учасників змагань!',
    deleteTournament: 'Видалити турнир',
    deleteResults: 'Видалити результати',
    сurrentQuantity: 'Поточна кількість',
    ChooseTournamentClass: 'Оберіть клас турніру',
    addressOfEvent: 'Адреса проведення',
    nameOfTournament: 'Назва турніру',
    registrationIsClosed: 'Реєстрацію закрито',
    chooseTypeOfTournament:'Оберіть тип турніру',
    registrationIsOpen: 'Реєстрацію відкрито',
    tournamentEditing: 'Редагуваня турниру',
    deletedTournConfirm: 'УВАГА ВИДАЛЕННЯ ТУРНИРУ Є НЕЗВОРОТНИМ ПРОЦЕСОМ! Ви погоджуєте дію?',
    saveChangesInTourn: 'Зберегти зміни у вашому турнирі?',
    deleteResultsAthletesGrids: 'Видалити заявлених атлетів, сітки та збережені результати турниру?',
    successfullDeletedTournament: 'Поточний турнир було успішно видалено. Можете покинути сторінку.',
    rankTournForEdit: {
      '1': 'Чемпіонат області',
      '2': 'Всеукраїнській турнир',
      '3': 'Всеукраїнський ретинговий',
      '4': 'Кубок України',
      '5': 'Чемпіонат України'
    },
    //TextEditor.js
    addRegulationtoRestData: 'Додати Регламент до решти даних',
    informationInField: "<p>Після вводу тексту натисніть кнопку <b>'Додати Регламент до решти даних'</b>, після незабудьте натиснути <b>'Внести до БД'</b> щоб зберегти результати!</p>",
    clickButtonToCreatingTournament: '<h1>Тепер натисніть кнопку "Внести до БД" для завершення створення Турніру!</h1>',
    clickButtonToEditingTournament:'<h1>Тепер натисніть кнопку "Внести до БД" для завершення редагування Турніру!</h1>',
    //MakeFoodOptionsCell.js
    breakfast: 'Сніданок',
    lunch: 'Обід',
    dinner: 'Вечеря',
    //addCompetition
    rankTourn: {//Клас турниру, використовується при створенні турниру
      '1. Чемпіонат області': 1,
      '2. Всеукраїнській турнір': 2,
      '3. Всеукраїнський рейтинговий турнір': 3,
      '4. Кубок України': 4,
      '5. Чемпіонат України': 5
    },
    сreateТewЕournament: 'Create a new tournament?',
    enterTextOfRegulation: 'Введіть текст регламенту',
    dinnerPrice: ' Ціна вечері',
    priceOfLunch: 'Ціна обіду',
    priceOfBreakfast: 'Ціна сніданку',
    foodNotOrganized: 'Харчування не організовується',
    specifyParametersForCatering: 'Вкажіть параметри для організації харчування',
    downloadRegulationsWithStamps: 'Завантажте регламент з печатками (PDF)',
    sizeForCompetitionLogo: ' Для коректного виводу зображення співвідношення сторін у файлі має бути 4/3',
    setCompetitionLogo: 'Встановіть логотип змаганнь',
    world: 'світові',
    olympic: 'олімпійські',
    growth: 'ростові',
    weighty: 'вагові',
    typeOfWeightCategories: 'Встановіть тип вагових категорій: ',
    veterans: 'Ветерани',
    adults: 'Дорослі',
    juniors: 'Юніори',
    cadets: 'Ст.Юнаки',
    younkers: 'Юнаки',
    jrYounkers: 'Мол.Юнаки',
    children: 'Діти',
    jrChildren: 'Малюки',
    ageCategoriesOfTournament: 'Вкажіть вікові категорії турниру',
    chooseChiefJudge: 'Оберіть головного суддю',
    chiefJudgeShort: 'Гол. суддя',
    chiefJudge: 'Головний суддя',
    chooseSecretary: 'Оберіть секретаря',
    secretary: 'Секретар',
    numberOfDayangs: 'Вкажіть кіл-сть даянгів',
    numberOfAthletesOnCompetition: 'Вкажіть кількість учасників',
    dateEndOfCompetition: 'Оберіть дату закінчення змагань',
    dateStartOfCompetition: 'Оберіть дату початку змагань',
    tournamentClass: 'Вкажіть клас турніру',
    addressDetails: 'Область, Місто,  Заклад та Адресу.',
    fullAddressOfTournament: 'Вкажіть повну адресу проведення:',
    enterNameOfTournament: 'Введіть назву змагань',
    mandatoryFields: "Поля обов'язкові для заповнення.",  
    newTournamentKeirugue: 'Новий турнир з Керуги',
    createNewTournament: 'Створити новий турнир?',
    tournamentSuccessfullyCreated: 'Новий турнир було успішно створено.',
    needDataForTournament: 'Для створення Турніру ви повинні вказати: назву турніру, клас турніру, місце, дати проведення кількість учасників, кількість кортів та вікові категорії (для керугі), та категорії та турнирну систему (для Пумсе)!',
    youNotSetTournamentDate: 'Ви не встановили дати турниру!',
    createKeirugue: 'Турнір керуги',
    certification:'Атестація',
    trainingСamp:'НТЗ та тренінгі',
    //RemoveAthleteFromClub.js
    deleteCoachFromClubShort: 'Видалити тренера',
    deleteAthleteFromClubShort: 'Видалити спортсмена',
    deleteCoachFromClub: 'Видалити тренера з клубу',
    deleteAthleteFromClub: 'Видалити спортсмена з клубу',
    athletesExcludedFromClub: 'Обрані спортсмени виключені з складу спортивного клубу',
    deleteSsersFromSportClub: 'Видалити обраних користувачів з спортклубу?',
    //editSportClub.js
    enterCityYourClub: 'Введіть місто роботи вашого клубу:',
    makeChangesInSportClub: 'Внести зміни в спортивному клубі?',
    sportClubSuccessfullyUpdated: 'Спортклуб успішно оновлено',
    failedUpdateClubInAthleteProfile: 'Невдалось оновити назву клубу в профілі спортсмена: ',
    indicateYourCity: 'Поле "місто" не може бути порожнім!',
     //coachRequest.js
    reasonsRefusalTitle: 'Причини відмови спортсменам у реєстрації на турнир',
    noCommentsInCoachRequest: 'немає зауважень та допускається до реєстрацшї на змагання',
    sportsInCoachRequest: ' спорт. ',
    remainingInCoachRequest: 'Решта ',
    byInCoachRequest: 'до',
    аthleteInCoachRequest: 'Спортсмену: ',
    resultOfTeamVerification: 'Результати перевірки Команди',
    dateForCheckProfileOfAthlete: 'Введіть дату на яку ви плануєте реєструватись на турнир:',
     errorAthletes: {// повідомлення про відмову спортсмену у pеєстрації на турнир
      activFtu: '<br/><b>Не активований у ФТУ</b> (потрібно сплатити річний внесок у Федерацію).',
      activSite: '<br/><b>Не активований на сайті</b> (потрібно стлатити місячний внесок за користування платформою GridsTKD).',
      fatherName: '<br/>У профілі спортсмену не заповнене поле <b>"по-батькові"</b>. ',
      birthday: '<br/>У профілі спортсмену не вказано<b> дата народження</b>.',
      sex: '<br/>У профілі спортсмену не вказано <b>стать<.b>.',
      eMail: '<br/>У профілі спортсмену не вказано <b>e-Mail</b>.',
      photo: '<br/>У профілі немає<b> фото</b> спортсмена.',
      weiht: '<br/>У профілі не вказано<b> вагу</b> спортсмена.',
      height: '<br/>У профілі не вказано <b>зріст</b> спортсмена.',
      coach: '<br/>У профілі не вказано <b>тренера</b> спортсмена.',
      sportClub: '<br/>У профілі спортсмену не вказано <b>Спортивний Клуб</b>.',
      region: '<br/>У профілі спортсмену не вказано <b>Обласний осередок</b>.',
      dan: '<br/>У профілі спортсмену не вказано <b>Кваліфікацію</b>.',
      rank: '<br/>У профілі спортсмену не вказано <b>Спортивний розряд</b>.',
      fst: '<br/>У профілі спортсмену не вказано <b>ФСТ</b>.',
      insuranceComp: '<br/>У профілі спортсмену не вказано <b>Страхова кампанія</b>.',
      insuranceNum: '<br/>У профілі спортсмену не вказано <b>Серія та номер страхового полісу</b>.',
      insuranceDate: '<br/>У профілі спортсмену не вказано <b>Термін дії страхового полісу</b>.',
      insuranceActive: '</br>Термін <b>дії страхового полісу вибігає</b> до почарку змагань (оновіть страховий поліс, та внесіть актуальні данні в профіль спортсмена)',
      medicalCertificateNum: '<br/>У профілі спортсмену не вказано <b>Серія та номер</b>.',
      medicalCertificateDate: '<br/>У профілі спортсмену не вказано <b>Термін дії медичної довідки</b>.',
      medicalCertificateActive: '</br>Термін <b>дії медичної довідки вибігає</b> до почарку змагань (пройдіть медичний огляд, та внесіть актуальні данні в профіль спортсмена)',
      status: '<br/>У профілі спортсмену не вказано <b>Статус</b>.',
      category: '<br/>Спортсмен не підходить під жодну <b>вікову категорію</b> для участі в обраном турнирі.',
    },
    //AthleteScale.js
    currentHeight: 'Поточний зріст',
    newWalue: 'Нове значення',
    currentWeight: 'Поточна вага',
    titleChangeAnthropometrics: 'Змініть антропометрику для переносу спортсменів в іншу категорію',
    successfullyUpdatedAnthropometrics: 'Антропометрику ваших спортсменів оновлено',
    changeWeightData: 'Змінити дані ваги та віку спортсменів?',
    //CreateSportClub.js
    notEnterLetterM: '(літеру "м. " прописувати не потрібно)',
    enterCityOfClub: 'Вкажіть місто роботи клубу ',
    enterNameOfClub: 'Введіть назву клубу: ',
    sizeForClubLogo: ' Для коректного виводу зображення співвідношення сторін у файлі має бути 1/1',
    installSportsClubLogo: 'Встановіть логотип Спортклубу',
    newSportsClub: 'Новий спортклуб',
    createClubRequest: 'Створити новий спортивній клуб?',
    successfullyCreatedClub: 'Спортклуб успішно створено.',
    notSpacesAroundHyphen: 'Навколо дефісу недопускаються пробіли!',
    smallLetterBeforeApostrophe: 'Після апострофу повинна стояти маленька літера;',
    capitalLetterAfterDash: 'Після тире обо пробілу повинна стояти велика літера;',
    firstLetterMustBeCapitalized: 'Перша літера назви повинна бути великою;',
    dotsNotFllowed: 'Недопускається ввод крапок;',
    wrongCityNameInputFormat: 'Неправильний формат вводу назви міста!',
    mustChooseCityWhereIsSportsClub: 'Для створення клубу ви повинні вказати назву міста в якому функціонує спортклуб!',
    mustChooseRegionForCreateClub: 'Для створення клубу ви повинні вказати вашу приналежність до обласного осередку на вкладці "Редагувати профіль".',
    clubNameCannotBeEmpty: 'Назва клубу не може бути порожньою.',
    //MySportClubs.js
    removeAthlete: 'Видалити спортсмена',   
    deleteSportsClubButton: 'Видалити спортклуб',
    editSportsClub: 'Редагувати спортклуб',
    athletes: 'Спортсмени',
    coaches: 'Тренери',
    checkingProfiles: 'Перевірка профілів',
    weighTeam: 'Зважування Команди',
    notCreatedSportsClub: 'Ви ще на створили Спортклубу',
    createSportsClub: 'Створити спортклуб',
    deleteSportsClub: 'Видалити спортивний клуб?',
    successfulDeleteSportsClub: 'Спортклуб та файл успішно видалено, можете продовжувати роботу',
    //SportClubChoice.js
    coachShort: 'трен.',
    athleteShort: 'атлет.',
    team: 'Команда',
    c: 'Ст.трен.',
    seniorCoachShort: 'Ст.трен.',
    seniorCoach: 'Старший тренер',
    choose: 'Обрати',
    confirmYourChoice: 'Підтвердіть свій вибір',
    //MyResultOnCompetitiion.js   
    individualResults: 'Індивідуальні результати спортсмена',
    //ChangePasswordForm.js
    password:'Пароль',
    confirmNewPass: 'Підтвердіть новий пароль',
    changePass: 'Змінити пароль',
    showPass: 'Показати пароль',
    confirmNewPassword: 'Підтвердіть новий пароль',
    newPass: 'Новий пароль',
    setPassword:'Встановити пароль',
    сurrentPass: 'Поточний пароль',
    notConfirmPass: 'Новий пароль не співпадає з підтвердженням.',
    passwordSymbolsWarnin: 'Пароль повинен містити щонайменше 5 символів.',
    wrongCurrentPass: 'Поточний пароль невірний.',
    successfulPasswordChangeMessage: 'Пароль успішно змінено. Незабудьте зберегти результати!!! Кнопка знизу ліворуч.',
    successfulPasswordAddMessage: 'Пароль успішно встановлено. Незабудьте зберегти результати!!! Кнопка знизу ліворуч.',
       //editProfile.js
    deleteProfile: 'Видалити профіль',
    save: 'Зберегти',
    photo: 'Фото',
    passwordChangeWarning: 'При зміні паролю зважте, що система не приймає символи кирилиці!',
    passwordaAddWarning: 'При встановленні паролю зважте, що система не приймає символи кирилиці!',
    
    login: 'Логін',
    eMailUsed: 'e-Mail використовується для розсилкі турнірних сіток та відновлення паролю!',
    medicalCertificateDate: 'Термін дії мед. сертиф.',
    numberOfMedicalCertificate: 'Сер./ном. мед. сертиф.',
    insuranceDate: 'Термін дії полісу',
    numberOfInsurancePolicy: 'Сер./ном. полісу',
    insuranceCompany: 'Страх. комп.',
    chooseCoach: 'Оберіть тренера',
    coach: 'тренер',
    chooseSportsClub: 'Оберіть спортклуб',
    chooseRegionalOffice: 'Оберіть обласний осередок',
    regionalOffice: 'Обласний осередок',
    weight: 'Вага',
    height: 'Зріст',
    enterYourGender: 'Вкажіть вашу стать',
    warningSex: 'Будьте уважні! Подальші зміни в полі "Стать" робитимуться тількі службою технічної підтримки по заявці вашого тренера!',
    dateOfBirth: 'Дата народження',
    patronymic: 'По-батькові',
    lastName: 'Прізвище',
    firstName: "Ім'я",
    warningToSubmitCompetition: 'Увага! Для подачі заяки на участь в змаганнях повинні бути заповненні усі поля форми без вийнятку!',
    deleteProfileQuery: 'УВАГА ВИДАЛЕННЯ ПРОФІЛЮ Є НЕЗВОРОТНИМ ПРОЦЕСОМ! Ви погоджуєте дію?',
    saveChangesInProfileQuery: 'Зберегти зміни у вашому профілі?',
    usedMail: 'Вказаний вами e-Mail вже використовується іншим користувачем',
    sexM: 'Чоловік',
    sexF: 'Жінка',
    //Профіль
    results: 'Результати',
    medicalCertificate: 'Медичний сертифікат',
    insurancePolicy: 'Страховий поліс',
    cm: 'см.',
    kg: 'кг.',
    sex: 'Стать',
    placeInCategory: 'місце в категорії',
    placeInRating: 'місце загального рейтингу',
    points: 'Балів',
    rating: 'Рейтинг',
    isActivatedSite: 'Активований на сайті',
    isActivatedFTU: 'Активований у ФТУ',
    fights: 'Бої',
    victory: 'Перемоги',
    place: 'Місце',
    PCSS: 'ФСТ',//Physical Culture and Sports Society
    rank: 'Розряд',
    qualificationShort: 'Кваліф.',
    qualification: 'Кваліфікація',
    status: 'Статус',
    YearOfBirth: 'р.н.',
    sportsClubInProfile: 'Спортивний клуб',
    // контрольна панель
    activeSiteСonfirm: 'Обрані користувачі успішно активовані на сайті',
    activeFtuСonfirm: 'Обрані користувачі успішно активовані у ФТУ',
    activeProfilesQuery: 'Активувати профілі обраних спортсменів?',
    removeModeratorСonfirm: 'Статус модератора успішно надано відкликано',
    removeModeratorQuery: 'Відкликати статус модератора у обраних тренерів?',
    appointModeratorСonfirm: 'Статус модератора успішно надано',
    appointModeratorQuery: 'Надати обраним тренерам статус модератора?',
    removeJudgeСonfirm: 'Статус судді осередку успішно відкликано',
    removeJudgeQuery: 'Відкликати статус судді у обраних спортсменів?',
    removeRegionLeaderСonfirm: 'Статус керівника осередку успішно відкликано',
    removeRegionLeaderQuery: 'Відкликати у обраних тренерів статус керівника обласного осередку?',
    appointRegionLeaderConfirm: 'Статус керівника осередку успішно надано',
    regionLeaderStatusQuery: 'Надати обраним тренерам статус керівника обласного осередку?',
    removeCoachСonfirm: 'Статус тренера успішно відкликано',
    removeCoachQuery: 'Відкликати статус тренера у обраних спортсменів?',
    queryError: 'Помилка Axios-запиту, невдалось оновити статус',
    coachStatusQuery: 'Надати обраним спортсменам статус тренера?',
    judgeStatusQuery: 'Надати обраним спортсменам статус судді?',
    notChosenCandidate: 'Ви не обрали жодного кандидата!',
    grantedCoach: 'Статус тренера успішно надано',
    grantedJudge: 'Статус судді успішно надано ',
    onlyCoach: 'Статус голови обласного осередку може отримати тільки тренер',
    onlyCoachModer: 'Статус модератора може отримати тільки тренер',
    attention: 'Увага',
    close: 'Закрити',
    ppl: 'чол.',
    chosen: 'Обрано',
    startTypingClubName: "Почніть вводити назву клубу",
    startTypingName: "Почніть вводити призвище та ім'я",
    activateSite: 'Активувати на сайті',
    activateFTU: 'Активувати у ФТУ',
    removeModerator: 'Видалити модератора',
    appointModerator: 'Призначити  модеретаром',
    removeJudge: 'Видалити суддю',
    appointJudges: 'Призначити суддів?',
    appointJudge: 'Призначити суддю',
    removeRegionLeader: 'Видалити голову обласного осередку',
    removeRegionLeaderButt: 'Видалити голову осередку',
    appointRegionLeader: 'Призначити головою обласного осередку',
    appointRegionLeaderButt: 'Призначити головою осередку',
    removeCoach: 'Видалити тренера',
    appointCoach: 'Призначити тренером',   
    // технічна інформація
    technicalInformation: 'Тут ви можете отримати інформацію про технічний бік роботи нашої платформи',
    welcome: 'Вітаємо Вас на нашому сайті',
    //кнопки підтвердження
    yes: 'Так',
    no: 'Ні',
    // навігаційна панель
    logout: 'Вихід',
    documents: 'Документи',
    protocolsOfPresidium:'Протоколи Президії',
    rulesOfCompetition:'Правила змагань',
    fightShoot:'Двобої',
    shooting:'Стрільба',
    heptathlon:'Семиборство',
    referenceToUsers: 'Довідка',
    archive: 'Архів',
    community: 'Спільнота',
    calendar: 'Календар',
    editProfile: 'Редагувати профіль',
    myСompetitions: 'Мої турніри',
    sportsClubShort: 'С/K',
    sportsClub: 'Спортклуб',
    mySportsClubs: 'Мої спортклуби',    
    myProfile: 'Мій профіль',
    profile: 'Профіль',
    controlPanel: 'Панель керування',
    //хедер
    athletesInTitle: 'спортсмени',
    ourBest: 'Наші найкращі',
  },
  regionLeders:(langSwitch,region)=>{
    if(!langSwitch||!region){return}
    let result=langSwitch==='ukr'?'Голова Осередку ':
                    langSwitch==='eng'?'Head of the Center of the ':null
    if (region === 'Івано-Франківський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='ukr') { result=result+'Івано-Франківської області' }
        if (region === 'Івано-Франківський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='eng') { result=result+'Ivano-Frankivsk region' }
    if (region === 'Вінницький обласний осередок тхеквондо (ВТФ)'&&langSwitch==='ukr') { result=result+'Вінницької області' }
        if (region === 'Вінницький обласний осередок тхеквондо (ВТФ)'&&langSwitch==='eng') { result=result+'Vinnytsia region' }
    if (region === 'Волинський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='ukr') { result=result+'Волинської області' }
        if (region === 'Волинський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='eng') { result=result+'Volyn region' }
    if (region === 'Дніпропетровський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='ukr') { result=result+'Дніпропетровської області' }
        if (region === 'Дніпропетровський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='eng') { result=result+'Dnipropetrovsk region' }
    if (region === 'Донецький обласний осередок тхеквондо (ВТФ)'&&langSwitch==='ukr') { result=result+'Донецької області' }
        if (region === 'Донецький обласний осередок тхеквондо (ВТФ)'&&langSwitch==='eng') { result=result+'Donetsk region' }
    if (region === 'Житомирський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='ukr') { result=result+'Житомирської області' }
        if (region === 'Житомирський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='eng') { result=result+'Zhytomyr region' }
    if (region === 'Закарпатський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='ukr') { result=result+'Закарпатської області' }
        if (region === 'Закарпатський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='eng') { result=result+'Zakarpattia Region' }
    if (region === 'Запорізький обласний осередок тхеквондо (ВТФ)'&&langSwitch==='ukr') { result=result+'Запорізької області' }
        if (region === 'Запорізький обласний осередок тхеквондо (ВТФ)'&&langSwitch==='eng') { result=result+'Zaporizhzhia region' }
    if (region === 'Кропівницький обласний осередок тхеквондо (ВТФ)'&&langSwitch==='ukr') { result=result+'Кропівницької області' }
        if (region === 'Кропівницький обласний осередок тхеквондо (ВТФ)'&&langSwitch==='eng') { result=result+'Kropivnytsk region' }
    if (region === 'Київський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='ukr') { result=result+'Київської області' }
        if (region === 'Київський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='eng') { result=result+'Kyiv region' }
    if (region === 'Луганський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='ukr') { result=result+'Луганської області' }
        if (region === 'Луганський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='eng') { result=result+'Luhansk region' }
   if (region === 'Львівський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='ukr') { result=result+'Львівської області' }
       if (region === 'Львівський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='eng') { result=result+'Lviv region' }
   if (region === 'Миколайвський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='ukr') { result=result+'Миколайвської області' }
        if (region === 'Миколайвський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='eng') { result=result+'Mykolaiv region' }
    if (region === 'Одеський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='ukr') { result=result+'Одеської області' }
        if (region === 'Одеський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='eng') { result=result+'Odesa region' }
    if (region === 'Полтавський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='ukr') { result=result+'Полтавської області' }
        if (region === 'Полтавський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='eng') { result=result+'Poltava region' }
    if (region === 'Рівненський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='ukr') { result=result+'Рівненської області' }
        if (region === 'Рівненський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='eng') { result=result+'Rivne region' }
    if (region === 'Сумський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='ukr') { result=result+'Сумської області' }
        if (region === 'Сумський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='eng') { result=result+'Sumy region' }
    if (region === 'Тернопільський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='ukr') { result=result+'Тернопільської області' }
        if (region === 'Тернопільський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='eng') { result=result+'Ternopil region' }
    if (region === 'Харківський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='ukr') { result=result+'Харківської області' }
        if (region === 'Харківський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='eng') { result=result+'Kharkiv region' }
    if (region === 'Херсонський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='ukr') { result=result+'Херсонської області' }
        if (region === 'Херсонський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='eng') { result=result+'Kherson region' }
    if (region === 'Хмельницький обласний осередок тхеквондо (ВТФ)'&&langSwitch==='ukr') { result=result+'Хмельницької області' }
        if (region === 'Хмельницький обласний осередок тхеквондо (ВТФ)'&&langSwitch==='eng') { result=result+'Khmelnytskyi region' }
    if (region === 'Черкаський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='ukr') { result=result+'Черкаської області' }
        if (region === 'Черкаський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='eng') { result=result+'Cherkasy region' }
    if (region === 'Чернівецький обласний осередок тхеквондо (ВТФ)'&&langSwitch==='ukr') { result=result+'Чернівецької області' }
        if (region === 'Чернівецький обласний осередок тхеквондо (ВТФ)'&&langSwitch==='eng') { result=result+'Chernivtsi region' }
   if (region === 'Чернігівський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='ukr') { result=result+'Чернігівської області' }
        if (region === 'Чернігівський обласний осередок тхеквондо (ВТФ)'&&langSwitch==='eng') { result=result+'Chernihiv region' }
   return result
  },
  regionsEng:(region)=>{
    if(!region){return}
    let result
    if (region==='Івано-Франківський обласний осередок тхеквондо (ВТФ)')
        {result='Ivano-Frankivsk Regional Taekwondo Center (WT)'}
    if (region==='Вінницький обласний осередок тхеквондо (ВТФ)')
        {result='Vinnytsia Regional Taekwondo Center (WT)'}
    if (region==='Волинський обласний осередок тхеквондо (ВТФ)')
        {result='Volyn Regional Taekwondo Center (WT)'}
    if (region==='Дніпропетровський обласний осередок тхеквондо (ВТФ)')
        {result='Dnipropetrovsk Regional Taekwondo Center (WT)'}
    if (region==='Донецький обласний осередок тхеквондо (ВТФ)')
        {result='Donetsk Regional Center of Taekwondo (WT)'}
    if (region==='Житомирський обласний осередок тхеквондо (ВТФ)')
        {result='Zhytomyr Regional Taekwondo Center (WT)'}
    if (region==='Закарпатський обласний осередок тхеквондо (ВТФ)')
        {result='Zakarpattia Taekwondo Regional Center (WT)'}
    if (region==='Запорізький обласний осередок тхеквондо (ВТФ)')
        {result='Zaporizhia Regional Center of Taekwondo (WT)'}
    if (region==='Кропівницький обласний осередок тхеквондо (ВТФ)')
        {result='Kropivnytskyi Regional Center of Taekwondo (WT)'}
    if (region==='Київський обласний осередок тхеквондо (ВТФ)')
        {result='Kyiv Regional Taekwondo Center (WT)'}
    if (region==='Луганський обласний осередок тхеквондо (ВТФ)')
        {result='Luhansk Regional Center of Taekwondo (WT)'}
    if (region==='Львівський обласний осередок тхеквондо (ВТФ)')
        {result='Lviv Regional Taekwondo Center (WT)'}
    if (region==='Миколайвський обласний осередок тхеквондо (ВТФ)')
        {result='Mykolaiv Regional Taekwondo Center (WT)'}
    if (region==='Одеський обласний осередок тхеквондо (ВТФ)')
        {result='Odesa Regional Taekwondo Center (WT)'}
    if (region==='Полтавський обласний осередок тхеквондо (ВТФ)')
        {result='Poltava Regional Taekwondo Center (WT)'}
    if (region==='Рівненський обласний осередок тхеквондо (ВТФ)')
        {result='Rivne Regional Taekwondo Center (WT)'}
    if (region==='Сумський обласний осередок тхеквондо (ВТФ)')
        {result='Sumy Regional Taekwondo Center (WT)'}
    if (region==='Тернопільський обласний осередок тхеквондо (ВТФ)')
        {result='Ternopil Regional Taekwondo Center (WT)'}
    if (region==='Харківський обласний осередок тхеквондо (ВТФ)')
        {result='Kharkiv Regional Center of Taekwondo (WT)'}
    if (region==='Херсонський обласний осередок тхеквондо (ВТФ)')
        {result='Kherson Regional Taekwondo Center (WT)'}
    if (region==='Хмельницький обласний осередок тхеквондо (ВТФ)')
        {result='Khmelnytskyi Regional Center of Taekwondo (WT)'}
    if (region==='Черкаський обласний осередок тхеквондо (ВТФ)')
        {result='Cherkasy Regional Taekwondo Center (WT)'}
    if (region==='Чернівецький обласний осередок тхеквондо (ВТФ)')
        {result='Chernivtsi Regional Center of Taekwondo (WT)'}
    if (region=== 'Чернігівський обласний осередок тхеквондо (ВТФ)')
        {result='Chernihiv Regional Center of Taekwondo (WT)'}
   
        return result
  },
  danEng:(dan)=>{
    if(!dan){return}
    let result
    if (dan==='10 куп'){result='10 kup'}
    if (dan==='9 куп'){result='9 kup'}
    if (dan==='8 куп'){result='8 kup'}
    if (dan==='7 куп'){result='7 kup'}
    if (dan==='6 куп'){result='6 kup'}
    if (dan==='5 куп'){result='5 kup'}
    if (dan==='4 куп'){result='4 kup'}
    if (dan==='3 куп'){result='3 kup'}
    if (dan==='2 куп'){result='2 kup'}
    if (dan==='1 куп'){result='1 kup'}
    if (dan==='1 дан'){result='1 dan'}
    if (dan==='2 дан'){result='2 dan'}
    if (dan==='3 дан'){result='3 dan'}
    if (dan==='4 дан'){result='4 dan'}
    if (dan==='5 дан'){result='5 dan'}
    if (dan==='6 дан'){result='6 dan'}
    if (dan==='7 дан'){result='7 dan'}
    if (dan==='8 дан'){result='8 dan'}
    if (dan==='9 дан'){result='9 dan'}
    if (dan==='10 дан'){result='10 dan'}   
    return result
  },
  rankEng:(rank)=>{
    if(!rank){return}
    let result
    if (rank==='Без розряду'){result='No category'}
    if (rank==='2 юнацький'){result='2rd jrCat.'}
    if (rank==='1 юнацький'){result='1rd jrCat.'}
    if (rank==='3 розряд'){result='3rd'}
    if (rank==='2 розряд'){result='2rd'}
    if (rank==='1 розряд'){result='1rd'}
    if (rank==='КМС'){result='CMS'}
    if (rank==='МС'){result='MS'}
    if (rank==='МСМК'){result='IMS'}
    if (rank==='ЗМС'){result='HMS'}    
        return result
  },
  poomsaeCatUkr:(langSwitch,category)=>{//назви категорій Пумсе

    if(!langSwitch||!category){return}
    let result   
 
if(category==='jrYounkersMaleTraditionalPersonaleClassA'&&langSwitch==='ukr') {result='Трад. Особисті: мол.Юнаки чол. "Клас А"'}
    if(category==='jrYounkersMaleTraditionalPersonaleClassA'&&langSwitch==='eng'){result='Trad. Individ. jrYounkers Male "Class A"'} 
   if(category==='jrYounkersMaleTraditionalPersonaleClassB'&&langSwitch==='ukr'){result='Трад. Особисті: мол.Юнаки чол. "Клас Б"'}
    if(category==='jrYounkersMaleTraditionalPersonaleClassB'&&langSwitch==='eng'){result='Trad. Individ. jrYounkers Male "Class B"'}          

if(category==='jrYounkersFemaleTraditionalPersonaleClassA'&&langSwitch==='ukr'){result='Трад. Особисті: мол.Юнаки жін. "Клас А"'}
    if(category==='jrYounkersFemaleTraditionalPersonaleClassA'&&langSwitch==='eng'){result='Trad. Individ. jrYounkers Female "Class A"'}
if(category==='jrYounkersFemaleTraditionalPersonaleClassB'&&langSwitch==='ukr'){result='Трад. Особисті: мол.Юнаки жін. "Клас Б"'}
    if(category==='jrYounkersFemaleTraditionalPersonaleClassB'&&langSwitch==='eng'){result='Trad. Individ. jrYounkers Female "Class B"'}
    
if(category==='younkersMaleTraditionalPersonaleClassA'&&langSwitch==='ukr'){result='Трад. Особисті: Юнаки чол. "Клас А"'}
    if(category==='younkersMaleTraditionalPersonaleClassA'&&langSwitch==='eng'){result='Trad. Individ. Younkers Male "Class A"'}
if(category==='younkersMaleTraditionalPersonaleClassB'&&langSwitch==='ukr'){result='Трад. Особисті: Юнаки чол. "Клас Б"'}
    if(category==='younkersMaleTraditionalPersonaleClassB'&&langSwitch==='eng') {result='Trad. Individ. Younkers Male "Class B"'}

    if(category==='younkersFemaleTraditionalPersonaleClassA'&&langSwitch==='ukr') {result='Трад. Особисті: Юнаки жін. "Клас А"'}
    if(category==='younkersFemaleTraditionalPersonaleClassA'&&langSwitch==='eng'){result='Trad. Individ. Younkers Female "Class A"'}
if(category==='younkersFemaleTraditionalPersonaleClassB'&&langSwitch==='ukr') {result='Трад. Особисті: Юнаки жін. "Клас Б"'}
    if(category==='younkersFemaleTraditionalPersonaleClassB'&&langSwitch==='eng')        {result='Trad. Individ. Younkers Female "Class B"'}

if(category==='cadetsMaleTraditionalPersonaleClassA'&&langSwitch==='ukr')    {result='Трад. Особисті: Кадети чол. "Клас А"'}
    if(category==='cadetsMaleTraditionalPersonaleClassA'&&langSwitch==='eng')        {result='Trad. Individ. Younkers Male "Class A"'}
if(category==='cadetsMaleTraditionalPersonaleClassB'&&langSwitch==='ukr')    {result='Трад. Особисті: Кадети чол. "Клас Б"'}
    if(category==='cadetsMaleTraditionalPersonaleClassB'&&langSwitch==='eng')        {result='Trad. Individ. Younkers Male "Class B"'}

    if(category==='cadetsFemaleTraditionalPersonaleClassA'&&langSwitch==='ukr')        {result='Трад. Особисті: Кадети жін. "Клас А"'}
    if(category==='cadetsFemaleTraditionalPersonaleClassA'&&langSwitch==='eng')            {result='Trad. Individ. Younkers Female "Class A"'}
if(category==='cadetsFemaleTraditionalPersonaleClassB'&&langSwitch==='ukr')        {result='Трад. Особисті: Кадети жін. "Клас Б"'}
    if(category==='cadetsFemaleTraditionalPersonaleClassB'&&langSwitch==='eng')            {result='Trad. Individ. Younkers Female "Class B"'}

if(category==='juniorsMaleTraditionalPersonaleClassA'&&langSwitch==='ukr')    {result='Трад. Особисті: Юніори чол. "Клас А"'}
    if(category==='juniorsMaleTraditionalPersonaleClassA'&&langSwitch==='eng')        {result='Trad. Individ. Yuniors Male "Class A"'}
if(category==='juniorsMaleTraditionalPersonaleClassB'&&langSwitch==='ukr')    {result='Трад. Особисті: Юніори чол. "Клас Б"'}
    if(category==='juniorsMaleTraditionalPersonaleClassB'&&langSwitch==='eng')        {result='Trad. Individ. Yuniors Male "Class B"'}

    if(category==='juniorsFemaleTraditionalPersonaleClassA'&&langSwitch==='ukr')    {result='Трад. Особисті: Юніори жін. "Клас А"'}
    if(category==='juniorsFemaleTraditionalPersonaleClassA'&&langSwitch==='eng')        {result='Trad. Individ. Yuniors Female "Class A"'}
if(category==='juniorsFemaleTraditionalPersonaleClassB'&&langSwitch==='ukr')    {result='Трад. Особисті: Юніори жін. "Клас Б"'}
    if(category==='juniorsFemaleTraditionalPersonaleClassB'&&langSwitch==='eng')        {result='Trad. Individ. Yuniors Female "Class B"'}

if(category==='adultsMale30TraditionalPersonaleClassA'&&langSwitch==='ukr')    {result='Трад. Особисті: Дорослі -30 чол. "Клас А"'}
    if(category==='adultsMale30TraditionalPersonaleClassA'&&langSwitch==='eng')        {result='Trad. Individ. Аdults -30 Male "Class A"'}
if(category==='adultsMale30TraditionalPersonaleClassB'&&langSwitch==='ukr')    {result='Трад. Особисті: Дорослі -30 чол. "Клас Б"'}
    if(category==='adultsMale30TraditionalPersonaleClassB'&&langSwitch==='eng')        {result='Trad. Individ. Аdults -30 Male "Class B"'}

    if(category==='adultsFemale30TraditionalPersonaleClassA'&&langSwitch==='ukr')    {result='Трад. Особисті: Дорослі -30 жін. "Клас А"'}
    if(category==='adultsFemale30TraditionalPersonaleClassA'&&langSwitch==='eng')        {result='Trad. Individ. Аdults -30 Female "Class A"'}
if(category==='adultsFemale30TraditionalPersonaleClassB'&&langSwitch==='ukr')    {result='Трад. Особисті: Дорослі -30 жін. "Клас Б"'}
    if(category==='adultsFemale30TraditionalPersonaleClassB'&&langSwitch==='eng')        {result='Trad. Individ. Аdults -30 Female "Class B"'}

if(category==='adultsMale40TraditionalPersonaleClassA'&&langSwitch==='ukr')    {result='Трад. Особисті: Дорослі -40 чол. "Клас А"'}
    if(category==='adultsMale40TraditionalPersonaleClassA'&&langSwitch==='eng')        {result='Trad. Individ. Аdults -40 Male "Class A"'}
if(category==='adultsMale40TraditionalPersonaleClassB'&&langSwitch==='ukr')    {result='Трад. Особисті: Дорослі -40 чол. "Клас Б"'}
    if(category==='adultsMale40TraditionalPersonaleClassB'&&langSwitch==='eng')        {result='Trad. Individ. Аdults -40 Male "Class B"'}

    if(category==='adultsFemale40TraditionalPersonaleClassA'&&langSwitch==='ukr')    {result='Трад. Особисті: Дорослі -40 жін. "Клас А"'}
    if(category==='adultsFemale40TraditionalPersonaleClassA'&&langSwitch==='eng')        {result='Trad. Individ. Аdults -40 Female "Class A"'}
if(category==='adultsFemale40TraditionalPersonaleClassB'&&langSwitch==='ukr')    {result='Трад. Особисті: Дорослі -40 жін. "Клас Б"'}
    if(category==='adultsFemale40TraditionalPersonaleClassB'&&langSwitch==='eng')        {result='Trad. Individ. Аdults -40 Female "Class B"'}

if(category==='adultsMale50TraditionalPersonaleClassA'&&langSwitch==='ukr')    {result='Трад. Особисті: Дорослі -50 чол. "Клас А"'}
    if(category==='adultsMale50TraditionalPersonaleClassA'&&langSwitch==='eng')        {result='Trad. Individ. Аdults -50 Male "Class A"'}
if(category==='adultsMale50TraditionalPersonaleClassB'&&langSwitch==='ukr')    {result='Трад. Особисті: Дорослі -50 чол. "Клас Б"'}
    if(category==='adultsMale50TraditionalPersonaleClassB'&&langSwitch==='eng')        {result='Trad. Individ. Аdults -50 Male "Class B"'}

    if(category==='adultsFemale50TraditionalPersonaleClassA'&&langSwitch==='ukr')    {result='Трад. Особисті: Дорослі -50 жін. "Клас А"'}
    if(category==='adultsFemale50TraditionalPersonaleClassA'&&langSwitch==='eng')        {result='Trad. Individ. Аdults -50 Female "Class A"'}
if(category==='adultsFemale50TraditionalPersonaleClassB'&&langSwitch==='ukr')    {result='Трад. Особисті: Дорослі -50 жін. "Клас Б"'}
    if(category==='adultsFemale50TraditionalPersonaleClassB'&&langSwitch==='eng')        {result='Trad. Individ. Аdults -50 Female "Class B"'}
    
if(category==='adultsMale60TraditionalPersonaleClassA'&&langSwitch==='ukr')    {result='Трад. Особисті: Дорослі -60 чол. "Клас А"'}
    if(category==='adultsMale60TraditionalPersonaleClassA'&&langSwitch==='eng')        {result='Trad. Individ. Аdults -60 Male "Class A"'}
if(category==='adultsMale60TraditionalPersonaleClassB'&&langSwitch==='ukr')    {result='Трад. Особисті: Дорослі -60 чол. "Клас Б"'}
    if(category==='adultsMale60TraditionalPersonaleClassB'&&langSwitch==='eng')        {result='Trad. Individ. Аdults -60 Male "Class B"'}

    if(category==='adultsFemale60TraditionalPersonaleClassA'&&langSwitch==='ukr')    {result='Трад. Особисті: Дорослі -60 жін. "Клас А"'}
    if(category==='adultsFemale60TraditionalPersonaleClassA'&&langSwitch==='eng')        {result='Trad. Individ. Аdults -60 Female "Class A"'}
if(category==='adultsFemale60TraditionalPersonaleClassB'&&langSwitch==='ukr')    {result='Трад. Особисті: Дорослі -60 жін. "Клас Б"'}
    if(category==='adultsFemale60TraditionalPersonaleClassB'&&langSwitch==='eng')        {result='Trad. Individ. Аdults -60 Female "Class B"'}

if(category==='adultsMale65TraditionalPersonaleClassA'&&langSwitch==='ukr')    {result='Трад. Особисті: Дорослі -65 чол. "Клас А"'}
    if(category==='adultsMale65TraditionalPersonaleClassA'&&langSwitch==='eng')        {result='Trad. Individ. Аdults -65 Male "Class A"'}
if(category==='adultsMale65TraditionalPersonaleClassB'&&langSwitch==='ukr')    {result='Трад. Особисті: Дорослі -65 чол. "Клас Б"'}
    if(category==='adultsMale65TraditionalPersonaleClassB'&&langSwitch==='eng')        {result='Trad. Individ. Аdults -65 Male "Class B"'}

    if(category==='adultsFemale65TraditionalPersonaleClassA'&&langSwitch==='ukr')    {result='Трад. Особисті: Дорослі -65 жін. "Клас А"'}
    if(category==='adultsFemale65TraditionalPersonaleClassA'&&langSwitch==='eng')        {result='Trad. Individ. Аdults -65 Female "Class A"'}
if(category==='adultsFemale65TraditionalPersonaleClassB'&&langSwitch==='ukr')    {result='Трад. Особисті: Дорослі -65 жін. "Клас Б"'}
    if(category==='adultsFemale65TraditionalPersonaleClassB'&&langSwitch==='eng')        {result='Trad. Individ. Аdults -65 Female "Class B"'}

if(category==='adultsMale65PlusTraditionalPersonaleClassA'&&langSwitch==='ukr')    {result='Трад. Особисті: Дорослі 65+ чол. "Клас А"'}
    if(category==='adultsMale65PlusTraditionalPersonaleClassA'&&langSwitch==='eng')        {result='Trad. Individ. Аdults 65+ Male "Class A"'}
if(category==='adultsMale65PlusTraditionalPersonaleClassB'&&langSwitch==='ukr')    {result='Трад. Особисті: Дорослі 65+ чол. "Клас Б"'}
    if(category==='adultsMale65PlusTraditionalPersonaleClassB'&&langSwitch==='eng')        {result='Trad. Individ. Аdults 65+ Male "Class B"'}

    if(category==='adultsFemale65PlusTraditionalPersonaleClassA'&&langSwitch==='ukr')    {result='Трад. Особисті: Дорослі 65+ жін. "Клас А"'}
    if(category==='adultsFemale65PlusTraditionalPersonaleClassA'&&langSwitch==='eng')        {result='Trad. Individ. Аdults 65+ Female "Class A"'}
if(category==='adultsFemale65PlusTraditionalPersonaleClassB'&&langSwitch==='ukr')    {result='Трад. Особисті: Дорослі 65+ жін. "Клас Б"'}
    if(category==='adultsFemale65PlusTraditionalPersonaleClassB'&&langSwitch==='eng')        {result='Trad. Individ. Аdults 65+ Female "Class B"'}

if(category==='jrYounkersTraditionalCoupleClassA'&&langSwitch==='ukr')    {result='Трад. Парні: мол.Юнаки "Клас А"'}
    if(category==='jrYounkersTraditionalCoupleClassA'&&langSwitch==='eng')        {result='Trad. Couple jrYounkers "Class A"'}
if(category==='jrYounkersTraditionalCoupleClassB'&&langSwitch==='ukr')    {result='Трад. Парні: мол.Юнаки  "Клас Б"'}
    if(category==='jrYounkersTraditionalCoupleClassB'&&langSwitch==='eng')        {result='Trad. Couple jrYounkers  "Class B"'}

if(category==='younkersTraditionalCoupleClassA'&&langSwitch==='ukr')    {result='Трад. Парні: Юнаки "Клас А"'}
    if(category==='younkersTraditionalCoupleClassA'&&langSwitch==='eng')        {result='Trad. Couple Younkers "Class A"'}
if(category==='younkersTraditionalCoupleClassB'&&langSwitch==='ukr')    {result='Трад. Парні: Юнаки  "Клас Б"'}
    if(category==='younkersTraditionalCoupleClassB'&&langSwitch==='eng')        {result='Trad. Couple Younkers  "Class B"'}
    
if(category==='cadetsTraditionalCoupleClassA'&&langSwitch==='ukr')    {result='Трад. Парні: Кадети "Клас А"'}
    if(category==='cadetsTraditionalCoupleClassA'&&langSwitch==='eng')        {result='Trad. Couple Cadets "Class A"'}
if(category==='cadetsTraditionalCoupleClassB'&&langSwitch==='ukr')    {result='Трад. Парні: Кадети  "Клас Б"'}
    if(category==='cadetsTraditionalCoupleClassB'&&langSwitch==='eng')        {result='Trad. Couple Cadets  "Class B"'}

if(category==='juniorsTraditionalCoupleClassA'&&langSwitch==='ukr')    {result='Трад. Парні: Юніори "Клас А"'}
    if(category==='juniorsTraditionalCoupleClassA'&&langSwitch==='eng')        {result='Trad. Couple Juniors "Class A"'}
if(category==='juniorsTraditionalCoupleClassB'&&langSwitch==='ukr')    {result='Трад. Парні: Юніори "Клас Б"'}
    if(category==='juniorsTraditionalCoupleClassB'&&langSwitch==='eng')        {result='Trad. Couple Juniors "Class B"'}    

if(category==='adults30TraditionalCoupleClassA'&&langSwitch==='ukr')    {result='Трад. Парні: Дорослі -30 "Клас А"'}
    if(category==='adults30TraditionalCoupleClassA'&&langSwitch==='eng')        {result='Trad. Couple Adults -30 "Class A"'}
if(category==='adults30TraditionalCoupleClassB'&&langSwitch==='ukr')    {result='Трад. Парні: Дорослі -30 "Клас Б"'}
    if(category==='adults30TraditionalCoupleClassB'&&langSwitch==='eng')        {result='Trad. Couple Adults -30 "Class B"'}

if(category==='adults40TraditionalCoupleClassA'&&langSwitch==='ukr')    {result='Трад. Парні: Дорослі -40 "Клас А"'}
    if(category==='adults40TraditionalCoupleClassA'&&langSwitch==='eng')        {result='Trad. Couple Adults -40 "Class A"'}
if(category==='adults40TraditionalCoupleClassB'&&langSwitch==='ukr')    {result='Трад. Парні: Дорослі -40 "Клас Б"'}
    if(category==='adults40TraditionalCoupleClassB'&&langSwitch==='eng')        {result='Trad. Couple Adults -40 "Class B"'}

if(category==='adults50TraditionalCoupleClassA'&&langSwitch==='ukr')    {result='Трад. Парні: Дорослі -50 "Клас А"'}
    if(category==='adults50TraditionalCoupleClassA'&&langSwitch==='eng')        {result='Trad. Couple Adults -50 "Class A"'}
if(category==='adults50TraditionalCoupleClassB'&&langSwitch==='ukr')    {result='Трад. Парні: Дорослі -50 "Клас Б"'}
    if(category==='adults50TraditionalCoupleClassB'&&langSwitch==='eng')        {result='Trad. Couple Adults -50 "Class B"'}

if(category==='adults60TraditionalCoupleClassA'&&langSwitch==='ukr')    {result='Трад. Парні: Дорослі -60 "Клас А"'}
    if(category==='adults60TraditionalCoupleClassA'&&langSwitch==='eng')        {result='Trad. Couple Adults -60 "Class A"'}
if(category==='adults60TraditionalCoupleClassB'&&langSwitch==='ukr')    {result='Трад. Парні: Дорослі -60 "Клас Б"'}
    if(category==='adults60TraditionalCoupleClassB'&&langSwitch==='eng')        {result='Trad. Couple Adults -60 "Class B"'}

if(category==='adults60PlusTraditionalCoupleClassA'&&langSwitch==='ukr')    {result='Трад. Парні: Дорослі 60+ "Клас А"'}
    if(category==='adults60PlusTraditionalCoupleClassA'&&langSwitch==='eng')        {result='Trad. Couple Adults 60+ "Class A"'}
if(category==='adults60PlusTraditionalCoupleClassB'&&langSwitch==='ukr')    {result='Трад. Парні: Дорослі 60+ "Клас Б"'}
    if(category==='adults60PlusTraditionalCoupleClassB'&&langSwitch==='eng')        {result='Trad. Couple Adults 60+ "Class B"'}
    

if(category==='jrYounkersMaleTraditionalTeamClassA'&&langSwitch==='ukr')    {result='Трад. Команди: мол.Юнаки чол. "Клас А"'}
    if(category==='jrYounkersMaleTraditionalTeamClassA'&&langSwitch==='eng')        {result='Trad. Team jrYounkers Male "Class A"'}    
if(category==='jrYounkersMaleTraditionalTeamClassB'&&langSwitch==='ukr')    {result='Трад. Команди: мол.Юнаки чол. "Клас Б"'}
    if(category==='jrYounkersMaleTraditionalTeamClassB'&&langSwitch==='eng')        {result='Trad. Team jrYounkers Male "Class B"'}

    if(category==='jrYounkersFemaleTraditionalTeamClassA'&&langSwitch==='ukr')    {result='Трад. Команди: мол.Юнаки жін. "Клас А"'}
    if(category==='jrYounkersFemaleTraditionalTeamClassA'&&langSwitch==='eng')        {result='Trad. Team jrYounkers Female "Class A"'}    
if(category==='jrYounkersFemaleTraditionalTeamClassB'&&langSwitch==='ukr')    {result='Трад. Команди: мол.Юнаки жін. "Клас Б"'}
    if(category==='jrYounkersFemaleTraditionalTeamClassB'&&langSwitch==='eng')        {result='Trad. Team jrYounkers Female "Class B"'}

if(category==='younkersMaleTraditionalTeamClassA'&&langSwitch==='ukr')        {result='Трад. Команди: Юнаки чол. "Клас А"'}
    if(category==='younkersMaleTraditionalTeamClassA'&&langSwitch==='eng')            {result='Trad. Team Younkers Male "Class A"'}    
if(category==='younkersMaleTraditionalTeamClassB'&&langSwitch==='ukr')        {result='Трад. Команди: Юнаки чол. "Клас Б"'}
    if(category==='younkersMaleTraditionalTeamClassB'&&langSwitch==='eng')            {result='Trad. Team Younkers Male "Class B"'}

    if(category==='younkersFemaleTraditionalTeamClassA'&&langSwitch==='ukr')        {result='Трад. Команди: Юнаки жін. "Клас А"'}
    if(category==='younkersFemaleTraditionalTeamClassA'&&langSwitch==='eng')            {result='Trad. Team Younkers Female "Class A"'}    
if(category==='younkersFemaleTraditionalTeamClassB'&&langSwitch==='ukr')        {result='Трад. Команди: Юнаки жін. "Клас Б"'}
    if(category==='younkersFemaleTraditionalTeamClassB'&&langSwitch==='eng')            {result='Trad. Team Younkers Female "Class B"'}
    
if(category==='cadetsMaleTraditionalTeamClassA'&&langSwitch==='ukr')    {result='Трад. Команди: Кадети чол. "Клас А"'}
    if(category==='cadetsMaleTraditionalTeamClassA'&&langSwitch==='eng')        {result='Trad. Team Cadets Male "Class A"'}    
if(category==='cadetsMaleTraditionalTeamClassB'&&langSwitch==='ukr')    {result='Трад. Команди: Кадети чол. "Клас Б"'}
    if(category==='cadetsMaleTraditionalTeamClassB'&&langSwitch==='eng')        {result='Trad. Team Cadets Male "Class B"'}

    if(category==='cadetsFemaleTraditionalTeamClassA'&&langSwitch==='ukr')    {result='Трад. Команди: Кадети жін. "Клас А"'}
    if(category==='cadetsFemaleTraditionalTeamClassA'&&langSwitch==='eng')        {result='Trad. Team Cadets Female "Class A"'}    
if(category==='cadetsFemaleTraditionalTeamClassB'&&langSwitch==='ukr')    {result='Трад. Команди: Кадети жін. "Клас Б"'}
    if(category==='cadetsFemaleTraditionalTeamClassB'&&langSwitch==='eng')        {result='Trad. Team Cadets Female "Class B"'}
    
if(category==='juniorsMaleTraditionalTeamClassA'&&langSwitch==='ukr')    {result='Трад. Команди: Юніори чол. "Клас А"'}
    if(category==='juniorsMaleTraditionalTeamClassA'&&langSwitch==='eng')        {result='Trad. Team Juniors Male "Class A"'}    
if(category==='juniorsMaleTraditionalTeamClassB'&&langSwitch==='ukr')    {result='Трад. Команди: Юніори чол. "Клас Б"'}
    if(category==='juniorsMaleTraditionalTeamClassB'&&langSwitch==='eng')        {result='Trad. Team Juniors Male "Class B"'}

    if(category==='juniorsFemaleTraditionalTeamClassA'&&langSwitch==='ukr')    {result='Трад. Команди: Юніори жін. "Клас А"'}
    if(category==='juniorsFemaleTraditionalTeamClassA'&&langSwitch==='eng')        {result='Trad. Team Juniors Female "Class A"'}    
if(category==='juniorsFemaleTraditionalTeamClassB'&&langSwitch==='ukr')    {result='Трад. Команди: Юніори жін. "Клас Б"'}
    if(category==='juniorsFemaleTraditionalTeamClassB'&&langSwitch==='eng')        {result='Trad. Team Juniors Female "Class B"'}

if(category==='adultsMale30TraditionalTeamClassA'&&langSwitch==='ukr')    {result='Трад. Команди: Дорослі -30 чол. "Клас А"'}
    if(category==='adultsMale30TraditionalTeamClassA'&&langSwitch==='eng')        {result='Trad. Team Adults -30 Male "Class A"'}    
if(category==='adultsMale30TraditionalTeamClassB'&&langSwitch==='ukr')    {result='Трад. Команди: Дорослі -30 чол. "Клас Б"'}
    if(category==='adultsMale30TraditionalTeamClassB'&&langSwitch==='eng')        {result='Trad. Team Adults -30 Male "Class B"'}

    if(category==='adultsFemale30TraditionalTeamClassA'&&langSwitch==='ukr')    {result='Трад. Команди: Дорослі -30 жін. "Клас А"'}
    if(category==='adultsFemale30TraditionalTeamClassA'&&langSwitch==='eng')        {result='Trad. Team Adults -30 Female "Class A"'}    
if(category==='adultsFemale30TraditionalTeamClassB'&&langSwitch==='ukr')    {result='Трад. Команди: Дорослі -30 жін. "Клас Б"'}
    if(category==='adultsFemale30TraditionalTeamClassB'&&langSwitch==='eng')        {result='Trad. Team Adults -30 Female "Class B"'}

if(category==='adultsMale40TraditionalTeamClassA'&&langSwitch==='ukr')    {result='Трад. Команди: Дорослі -40 чол. "Клас А"'}
    if(category==='adultsMale40TraditionalTeamClassA'&&langSwitch==='eng')        {result='Trad. Team Adults -40 Male "Class A"'}    
if(category==='adultsMale40TraditionalTeamClassB'&&langSwitch==='ukr')    {result='Трад. Команди: Дорослі -40 чол. "Клас Б"'}
    if(category==='adultsMale40TraditionalTeamClassB'&&langSwitch==='eng')        {result='Trad. Team Adults -40 Male "Class B"'}

    if(category==='adultsFemale40TraditionalTeamClassA'&&langSwitch==='ukr')    {result='Трад. Команди: Дорослі -40 жін. "Клас А"'}
    if(category==='adultsFemale40TraditionalTeamClassA'&&langSwitch==='eng')        {result='Trad. Team Adults -40 Female "Class A"'}    
if(category==='adultsFemale40TraditionalTeamClassB'&&langSwitch==='ukr')    {result='Трад. Команди: Дорослі -40 жін. "Клас Б"'}
    if(category==='adultsFemale40TraditionalTeamClassB'&&langSwitch==='eng')        {result='Trad. Team Adults -40 Female "Class B"'}

if(category==='adultsMale50TraditionalTeamClassA'&&langSwitch==='ukr')    {result='Трад. Команди: Дорослі -50 чол. "Клас А"'}
    if(category==='adultsMale50TraditionalTeamClassA'&&langSwitch==='eng')        {result='Trad. Team Adults -50 Male "Class A"'}    
if(category==='adultsMale50TraditionalTeamClassB'&&langSwitch==='ukr')    {result='Трад. Команди: Дорослі -50 чол. "Клас Б"'}
    if(category==='adultsMale50TraditionalTeamClassB'&&langSwitch==='eng')        {result='Trad. Team Adults -50 Male "Class B"'}

    if(category==='adultsFemale50TraditionalTeamClassA'&&langSwitch==='ukr')    {result='Трад. Команди: Дорослі -50 жін. "Клас А"'}
    if(category==='adultsFemale50TraditionalTeamClassA'&&langSwitch==='eng')        {result='Trad. Team Adults -50 Female "Class A"'}    
if(category==='adultsFemale50TraditionalTeamClassB'&&langSwitch==='ukr')    {result='Трад. Команди: Дорослі -50 жін. "Клас Б"'}
    if(category==='adultsFemale50TraditionalTeamClassB'&&langSwitch==='eng')        {result='Trad. Team Adults -50 Female "Class B"'}

if(category==='adultsMale60TraditionalTeamClassA'&&langSwitch==='ukr')        {result='Трад. Команди: Дорослі -60 чол. "Клас А"'}
     if(category==='adultsMale60TraditionalTeamClassA'&&langSwitch==='eng')            {result='Trad. Team Adults -60 Male "Class A"'}    
if(category==='adultsMale60TraditionalTeamClassB'&&langSwitch==='ukr')        {result='Трад. Команди: Дорослі -60 чол. "Клас Б"'}
    if(category==='adultsMale60TraditionalTeamClassB'&&langSwitch==='eng')            {result='Trad. Team Adults -60 Male "Class B"'}

    if(category==='adultsFemale60TraditionalTeamClassA'&&langSwitch==='ukr')        {result='Трад. Команди: Дорослі -60 жін. "Клас А"'}
    if(category==='adultsFemale60TraditionalTeamClassA'&&langSwitch==='eng')            {result='Trad. Team Adults -60 Female "Class A"'}    
if(category==='adultsFemale60TraditionalTeamClassB'&&langSwitch==='ukr')        {result='Трад. Команди: Дорослі -60 жін. "Клас Б"'}
    if(category==='adultsFemale60TraditionalTeamClassB'&&langSwitch==='eng')            {result='Trad. Team Adults -60 Female "Class B"'}

if(category==='adultsMale60PlusTraditionalTeamClassA'&&langSwitch==='ukr')    {result='Трад. Команди: Дорослі 60+ чол. "Клас А"'}
    if(category==='adultsMale60PlusTraditionalTeamClassA'&&langSwitch==='eng')        {result='Trad. Team Adults 60+ Male "Class A"'}    
if(category==='adultsMale60PlusTraditionalTeamClassB'&&langSwitch==='ukr')    {result='Трад. Команди: Дорослі 60+ чол. "Клас Б"'}
    if(category==='adultsMale60PlusTraditionalTeamClassB'&&langSwitch==='eng')        {result='Trad. Team Adults 60+ Male "Class B"'}

    if(category==='adultsFemale60PlusTraditionalTeamClassA'&&langSwitch==='ukr')    {result='Трад. Команди: Дорослі 60+ жін. "Клас А"'}
    if(category==='adultsFemale60PlusTraditionalTeamClassA'&&langSwitch==='eng')        {result='Trad. Team Adults 60+ Female "Class A"'}    
if(category==='adultsFemale60PlusTraditionalTeamClassB'&&langSwitch==='ukr')    {result='Трад. Команди: Дорослі 60+ жін. "Клас Б"'}
    if(category==='adultsFemale60PlusTraditionalTeamClassB'&&langSwitch==='eng')        {result='Trad. Team Adults 60+ Female "Class B"'}

if(category==='freestylePersonaleMale17ClassA'&&langSwitch==='ukr')    {result='Фристайл Особисті:  чол. -17 "Клас A"'}
    if(category==='freestylePersonaleMale17ClassA'&&langSwitch==='eng')        {result='Freestyle Individ. Male -17 "Class A"'}
if(category==='freestylePersonaleMale17ClassB'&&langSwitch==='ukr')    {result='Фристайл Особисті:  чол. -17 "Клас B"'}
    if(category==='freestylePersonaleMale17ClassB'&&langSwitch==='eng')        {result='Freestyle Individ. Male -17 "Class B"'}

    if(category==='freestylePersonaleFemale17ClassA'&&langSwitch==='ukr')    {result='Фристайл Особисті:  жін. -17 "Клас A"'}
    if(category==='freestylePersonaleFemale17ClassA'&&langSwitch==='eng')        {result='Freestyle Individ. Female -17 "Class A"'}
if(category==='freestylePersonaleFemale17ClassB'&&langSwitch==='ukr')    {result='Фристайл Особисті:  жін. -17 "Клас Б"'}
    if(category==='freestylePersonaleFemale17ClassB'&&langSwitch==='eng')        {result='Freestyle Individ. Female -17 "Class B"'}

if(category==='freestylePersonaleMale17PlusClassA'&&langSwitch==='ukr')    {result='Фристайл Особисті:  чол. 17+ "Клас A"'}
    if(category==='freestylePersonaleMale17PlusClassA'&&langSwitch==='eng')        {result='Freestyle Individ. Male 17+ "Class A"'}
if(category==='freestylePersonaleMale17PlusClassB'&&langSwitch==='ukr')    {result='Фристайл Особисті:  чол. 17+ "Клас Б"'}
    if(category==='freestylePersonaleMale17PlusClassB'&&langSwitch==='eng')        {result='Freestyle Individ. Male 17+ "Class B"'}

    if(category==='freestylePersonaleFemale17PlusClassA'&&langSwitch==='ukr')    {result='Фристайл Особисті:  жін. 17+ "Клас A"'}
    if(category==='freestylePersonaleFemale17PlusClassA'&&langSwitch==='eng')        {result='Freestyle Individ. Female 17+ "Class A"'}
if(category==='freestylePersonaleFemale17PlusClassB'&&langSwitch==='ukr')    {result='Фристайл Особисті:  жін. 17+ "Клас Б"'}
    if(category==='freestylePersonaleFemale17PlusClassB'&&langSwitch==='eng')        {result='Freestyle Individ. Female 17+ "Class B"'}

if(category==='freestyleCouple17ClassA'&&langSwitch==='ukr')    {result='Фристайл Парні: -17 "Клас A"'}
    if(category==='freestyleCouple17ClassA'&&langSwitch==='eng')        {result='Freestyle Couple -17 "Class A"'}
if(category==='freestyleCouple17ClassB'&&langSwitch==='ukr')        {result='Фристайл Парні: -17 "Клас Б"'}
    if(category==='freestyleCouple17ClassB'&&langSwitch==='eng')            {result='Freestyle Couple -17 "Class B"'}

if(category==='freestyleCouple17PlusClassA'&&langSwitch==='ukr')    {result='Фристайл Парні: 17+ "Клас A"'}
    if(category==='freestyleCouple17PlusClassA'&&langSwitch==='eng')        {result='Freestyle Couple 17+ "Class A"'}
if(category==='freestyleCouple17PlusClassB'&&langSwitch==='ukr')        {result='Фристайл Парні: 17+ "Клас Б"'}
    if(category==='freestyleCouple17PlusClassB'&&langSwitch==='eng')            {result='Freestyle Couple 17+ "Class B"'}

if(category==='freestyleTeamClassA'&&langSwitch==='ukr')    {result='Фристайл Командні "Клас A"'}
    if(category==='freestyleTeamClassA'&&langSwitch==='eng')        {result='Freestyle Team "Class A"'}
if(category==='freestyleTeamClassB'&&langSwitch==='ukr')    {result='Фристайл Командні "Клас Б"'}
    if(category==='freestyleTeamClassB'&&langSwitch==='eng')        {result='Freestyle Team "Class B"'}

if(category==='mixPoomsaePersonaleMaleClassA'&&langSwitch==='ukr')    {result='Змішане Особисті: чол. "Клас A"'}
    if(category==='mixPoomsaePersonaleMaleClassA'&&langSwitch==='eng')        {result='Mixed Poomsae Individ. Male "Class A"'}
if(category==='mixPoomsaePersonaleMaleClassB'&&langSwitch==='ukr')    {result='Змішане Особисті: чол. "Клас Б"'}
    if(category==='mixPoomsaePersonaleMaleClassB'&&langSwitch==='eng')        {result='Mixed Poomsae Individ. Male "Class B"'}

    if(category==='mixPoomsaePersonaleFemaleClassA'&&langSwitch==='ukr')    {result='Змішане Особисті: жін. "Клас A"'}
    if(category==='mixPoomsaePersonaleFemaleClassA'&&langSwitch==='eng')        {result='Mixed Poomsae Individ. Female "Class A"'}
if(category==='mixPoomsaePersonaleFemaleClassB'&&langSwitch==='ukr')    {result='Змішане Особисті: жін. "Клас Б"'}
    if(category==='mixPoomsaePersonaleFemaleClassB'&&langSwitch==='eng')        {result='Mixed Poomsae Individ. Female "Class B"'}

if(category==='mixPoomsaeCoupleClassA'&&langSwitch==='ukr')    {result='Змішане Парні: "Клас A"'}
    if(category==='mixPoomsaeCoupleClassA'&&langSwitch==='eng')        {result='Mixed Poomsae Couple "Class A"'}
if(category==='mixPoomsaeCoupleClassB'&&langSwitch==='ukr')    {result='Змішане Парні: "Клас Б"'}
    if(category==='mixPoomsaeCoupleClassB'&&langSwitch==='eng')        {result='Mixed Poomsae Couple "Class B"'}    

if(category==='mixPoomsaeTeamMaleClassA'&&langSwitch==='ukr')    {result='Змішане Командні чол. "Клас A"'}
    if(category==='mixPoomsaeTeamMaleClassA'&&langSwitch==='eng')        {result='Mixed Poomsae Team Male "Class A"'}
if(category==='mixPoomsaeTeamMaleClassB'&&langSwitch==='ukr')    {result='Змішане Командні чол. "Клас Б"'}
    if(category==='mixPoomsaeTeamMaleClassB'&&langSwitch==='eng')        {result='Mixed Poomsae Team Male "Class B"'}

    if(category==='mixPoomsaeTeamFemaleClassA'&&langSwitch==='ukr')    {result='Змішане Командні жін. "Клас A"'}
    if(category==='mixPoomsaeTeamFemaleClassA'&&langSwitch==='eng')        {result='Mixed Poomsae Team Female "Class A"'}
if(category==='mixPoomsaeTeamFemaleClassB'&&langSwitch==='ukr')    {result='Змішане Командні жін. "Клас Б"'}
    if(category==='mixPoomsaeTeamFemaleClassB'&&langSwitch==='eng')        {result='Mixed Poomsae Team Female "Class B"'}

if(category==='mixPoomsaeMixedTeamClassA'&&langSwitch==='ukr')    {result='Зміщане Зведені Команди: "Клас A"'}
    if(category==='mixPoomsaeMixedTeamClassA'&&langSwitch==='eng')        {result='MixPoomsae Mixed team "Class A"'}
if(category==='mixPoomsaeMixedTeamClassB'&&langSwitch==='ukr')    {result='Зміщане Зведені Команди: "Клас Б"'}
    if(category==='mixPoomsaeMixedTeamClassB'&&langSwitch==='eng')        {result='MixPoomsae Mixed team "Class B"'}

    
    return result
  }


};

export default lang 