import s from '../adminCompetition/adminCompetition.module.css'
import state from "../../../state/state.js"
import { useState } from 'react';


import ArchiveResults from './archiveResults.js';
import lang from '../../../state/language.js';



function Archive({isSmallScreen,langSwitch}){

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
 const [data, setData] = useState('');
 const [wiew, setWiew] = useState(null);

const archiveData =[...state.archive].reverse();
// const archiveData=state.archive
const handleTitleChange = (e) => { setData(e.target.value) };


 //   форма пошуку
 let archive = archiveData.filter(item => {
  const fullName = `${item.title}`.toLowerCase();
  const searchData = data.toLowerCase();

  return fullName.includes(searchData);
});


archive.sort((a, b) => {
  const parseDate = (dateString) => {
      // Видаляємо все, що перед дефісом (тобто перший день)
      const cleanedDate = dateString.split('-')[1].trim(); // "30.03.2024"
      // Розбиваємо день, місяць і рік
      const [day, month, year] = cleanedDate.split('.');
      // Форматуємо у стандартний формат дати
      return new Date(`${year}-${month}-${day}`);
  };

  return parseDate(b.dateOfCompetition) - parseDate(a.dateOfCompetition);
});

    return(<>

   {!wiew?(<>

<div className={s.title}> <br/>
{lang[langSwitch]. archive /**/}
</div> {/* Закриття шапки */}
{!isSmallScreen?(<><br/></>):null}
<div className={!isSmallScreen?s.contentTitleItem1:s.td1}>
        <div className={s.title1}>
          <label htmlFor="title"><b>
          {lang[langSwitch].startTypingNameOfTournament /* Почніть вводити назву турниру*/}
            </b> </label><br /><br />
          <input onChange={handleTitleChange} type="text" id="title" name="title" />

        </div> 
        <div className={s.switch6L}>&nbsp;</div>
        
</div><br/>
{successMessage?
          (<><br/><br/><div className={s.successMessage} onClick={()=>{setSuccessMessage('')}}>
            
            {successMessage}</div><br/></>) :
          errorMessage?(<><br/><br/><div className={s.errorMessage} onClick={()=>{setErrorMessage('')}}>
            {errorMessage}</div><br/></>):''}
  <div>
        {archive.map((item) => (
         
          <div   key={item.id}        className={s.archiveBlock}>
           
            <div className={s.boxTitile}
            onClick={()=>{setWiew(item)}} 
            style={{ cursor: 'pointer' }}>
               <div className={s.archiveBlockTitle}>
                {item.title}
              </div>
              <div className={s.region}>
                {item.dateOfCompetition}<br/> &nbsp;
              </div>
            </div>
        
           </div>

          
        ))}
      </div>
   </>):(<>
      <ArchiveResults 
        isSmallScreen={isSmallScreen}
        competition={wiew} 
        setWiew={setWiew}
        setErrorMessage={setErrorMessage}
        setSuccessMessage={setSuccessMessage}
        langSwitch={langSwitch}/>

 </>)}

    
    </>)
}

export default Archive