import React from 'react';
import s from './navBar.module.css'
import Avatar from './avatar.js';
import LanguagePanel from './languagePanel.js';
import state from '../../state/state.js';
import lang from '../../state/language.js';
import { NavLink } from 'react-router-dom';



function NavBarForCoaches({ setLangSwitch, langSwitch }) {

//блокуємо редагування профілю спортсменів до вказаної при друку заявкі дати
const currentDate = new Date(); // Поточна дата
const blockProfileDate = new Date(state.userProfile.dateForBlockProfile);

    return (

        <div className={s.navBarMainDiv}>
            <LanguagePanel
                setLangSwitch={setLangSwitch}
                langSwitch={langSwitch} />
            <div className={s.avatarBox}>
                <div >
               
                    <Avatar navBar={true} />
              
                </div>

            </div>
            <nav>
                <ul>
                    <li><a >
                        {lang[langSwitch].home} {/*Головна*/}
                    </a></li>
                    {state.userProfile.regionLeader || state.userProfile.moderator == 1 || state.userProfile.id == 1 ? (<>

                        <li>
                            <NavLink to="/ControlPanel">
                                {lang[langSwitch].controlPanel} {/*контрольна панель*/}
                            </NavLink>
                        </li>

                    </>) : null}
                    <li><a>
                        {lang[langSwitch].profile} {/*Профіль*/}
                    </a>
                        <ul>

                            <li>

                                <NavLink to="/userProfile" >
                                    {lang[langSwitch].myProfile} {/* Мій профіль*/}
                                </NavLink>

                            </li>

                            <li>
                            {!blockProfileDate || currentDate > blockProfileDate ?(<>
                            <NavLink to="/editProfile">
                                    {lang[langSwitch].editProfile} {/*Редагувати профіль */}
                                </NavLink>
                            </>):(<>
                            {lang[langSwitch].editProfile} {/*Редагувати профіль */}
                            </>)}
                                
                            </li>
                        </ul>
                    </li>

                    {state.userProfile.status === 'Тренер' && (<>
                        <li><a >
                            {lang[langSwitch].eventManagement} {/*Керування заходами*/}
                        </a>
                            <ul>
                                <li>
                                    <NavLink to="/myCompetition">
                                        {lang[langSwitch].myEvents} {/* Мої заходи*/}
                                    </NavLink>
                                </li>
                                <li><a>
                                    {lang[langSwitch].createEvent} {/*Створити захід*/}
                                </a>
                                    <ul>
                                        <li><a>
                                            {lang[langSwitch].trainingСamp} {/*НТЗ та тренінгі*/}
                                        </a></li>
                                        <li><a>
                                            {lang[langSwitch].certification} {/*Атестація*/}
                                        </a></li>
                                        <li><a>
                                            {lang[langSwitch].competition} {/*Змагання*/}
                                        </a>
                                            <ul>
                                                <li><NavLink to="/addCompetition">
                                                    {lang[langSwitch].fightShoot} {/*Двобої*/}
                                                </NavLink>    </li>
                                                <li><a>
                                                    {lang[langSwitch].heptathlon} {/*Семиборство*/}
                                                </a></li>
                                            </ul>
                                        </li>

                                    </ul>
                                </li>

                            </ul>
                        </li>
                        <li><a >
                            {lang[langSwitch].sportsClub} {/*Спортклуб*/}
                        </a>
                            <ul>
                                <li>
                                    <NavLink to="/MySportClubs">
                                        {lang[langSwitch].mySportsClubs} {/*Мої спортклуби*/}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/CreateSportClub">
                                        {lang[langSwitch].createSportsClub}{/*Створити спортклуб*/}
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                    </>)}

                    <li>
                        <NavLink to="/calendar">
                            {lang[langSwitch].calendar} {/* Календар*/}
                        </NavLink>
                    </li>
                    <li><a>
                        {lang[langSwitch].community} {/* Спільнота*/}
                    </a>
                        <ul>
                            <li>
                                <NavLink to="/CommunityRegions">
                                    {lang[langSwitch].regions} {/*Осередки*/}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/CommunitySportClubs">
                                    {lang[langSwitch].sportsClubs} {/*Спортклуби*/}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/CommunityCoaches">
                                    {lang[langSwitch].coaches} {/*Тренери*/}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/CommunityAthletes">
                                    {lang[langSwitch].athletes} {/*Спортсмени*/}
                                </NavLink>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <NavLink to="/archive">
                            {lang[langSwitch].archive} {/*Архів*/}
                        </NavLink>
                    </li>
                    <li><a >
                        {lang[langSwitch].documents} {/*Документи*/}
                    </a>
                        <ul>
                            <li><a >
                                {lang[langSwitch].protocolsOfPresidium} {/*Протоколи Президії*/}
                            </a></li>
                            <li><a >
                                {lang[langSwitch].rulesOfCompetition} {/*Правила змагань*/}
                            </a>
                                <ul className={s.rightPositions}>
                                    <li><a >
                                        {lang[langSwitch].fightShoot} {/*Двобої*/}
                                    </a></li>
                                    <li><a >
                                        {lang[langSwitch].shooting} {/*Стрільба*/}
                                    </a></li>
                                    <li><a >
                                        {lang[langSwitch].heptathlon} {/*Семиборство*/}
                                    </a></li>
                                </ul>
                            </li>
                            <li><a >
                                {lang[langSwitch].requirements} {/*'Вимоги до учасників*/}
                            </a></li>
                        </ul>
                    </li>
                    <li>
                        <NavLink to="/Information">
                            {lang[langSwitch].referenceToUsers} {/* Довідка*/}
                        </NavLink>
                    </li>
                    <li> <a className={s.logo}
                        onClick={state.exit}>
                        {lang[langSwitch].logout} {/* Вихід*/}
                    </a> </li>
                </ul>



            </nav>
        </div>

    );
};

export default NavBarForCoaches;