import React, { useState } from 'react';
import s from './userProfile.module.css';
import state from '../../../state/state.js';
import MyResultOnCompetitiion from './myResultOnCompetitiion';
import lang from '../../../state/language.js';





function UserProfile({isSmallScreen,langSwitch}) {


  const [MyResultOnCompetitiionModal, setMyResultOnCompetitiionModalOpen] = useState (false);
const openMyResultOnCompetitiionModal = () => { setMyResultOnCompetitiionModalOpen(true); }
  const closeMyResultOnCompetitiionModal = () => {setMyResultOnCompetitiionModalOpen(false);}

  state.messageCount()  //лічильник непрочитаних повідомлень

const raitingOfAthlete=state.ratingQuery(state.userProfile.id,1)
let myCompetition
if(Array.isArray(JSON.parse(state.userProfile.myCompetition))){
 myCompetition=JSON.parse(state.userProfile.myCompetition).reverse()
}
  if (state.userProfile.status === 'Тренер') {
    
    return (

      <div className={!isSmallScreen?s.container:s.containerSmall}>

        <div className={s.header}>
        {/* {isSmallScreen?(<> */}
        <img
          className={s.avatar}
          src={state.userProfile.photo ? state.userProfile.photo : state.noAvatarPhoto} />
      <br/>&nbsp;
        {/* </>):null} */}
        
          <div className={s.title}>
          {`${state.userProfile.secondName} ${state.userProfile.personName} ${state.userProfile.fatherName}`}
           
          </div>
          <p><b>id: {state.userProfile.id}</b></p>

          <p><b>{langSwitch==='ukr'?state.userProfile.region:
         langSwitch==='eng'?lang.regionsEng( state.userProfile.region):null}</b></p>
          <p><span>
            {lang[langSwitch].sportsClubInProfile}{/*Спортивний клуб*/}:
            <br/></span> <b>{state.userProfile.sportClub}</b></p>
          <p>
            {langSwitch==='ukr'?(<>
            <b>{state.formatDate(state.userProfile.birthday)}</b>
            <span> {lang[langSwitch].YearOfBirth}{/*р.н.*/}</span>
            </>):null}
            {langSwitch==='eng'?(<>
            <span> {lang[langSwitch].YearOfBirth}{/*р.н.*/}</span>
            <b>{state.formatDate(state.userProfile.birthday)}</b>
            </>):null}
            </p>

        </div>


        <div className={s.leftCol}>

         
          <p> <span>{lang[langSwitch].status}{/*статус*/}: </span> <b>{state.userProfile.status}</b></p>
          <p><span> {lang[langSwitch].qualification}{/*кваліфікація*/}: </span><b>{state.userProfile.dan}</b></p>
          <p><span>{lang[langSwitch].rank}{/*розряд*/}: </span> <b>{state.userProfile.rank}</b></p>
          <p><span>{lang[langSwitch].PCSS}{/*ФСТ*/}: </span><b>{state.userProfile.fst}</b></p>

        </div>


        <div className={s.rigthCol}>
         
          <div className={s.p}>
            <span>{lang[langSwitch].isActivatedFTU}{/*Активований у ФТУ*/}: </span>
            <b> {state.userProfile.activFtu == true ? (<div className={s.blue}>
              {lang[langSwitch].yes}{/*Так*/}
              </div>) : (<div className={s.red}>
                {lang[langSwitch].no}{/*Ні*/}
                </div>)}</b><br />
          </div>
          <div className={s.p}>
            <span>{lang[langSwitch].isActivatedSite}{/*Активований на сайті*/}: </span>
            <b> {state.userProfile.activSite == true ? (<div className={s.blue}>
              {lang[langSwitch].yes}{/*Так*/}
              </div>) : (<div className={s.red}>
                {lang[langSwitch].no}{/*Ні*/}
                </div>)}</b>
          </div>

        </div>

        <div className={s.footer}>
         
        </div>

      </div>
    );
  } else {
    return (

      <div className={!isSmallScreen?s.container:s.containerSmall}>

        <div className={s.header}>
          <div className={s.title}>
          {`${state.userProfile.secondName} ${state.userProfile.personName} ${state.userProfile.fatherName}`}
          </div>
          <p><b>id: {state.userProfile.id}</b></p>
          <p><b>
          {langSwitch==='ukr'?state.userProfile.region:
         langSwitch==='eng'?lang.regionsEng( state.userProfile.region):null}
            </b></p>
          <p><span>
            {lang[langSwitch].sportsClubInProfile}{/*Спортивний клуб*/}:
            </span><br/> <b>{state.userProfile.sportClub}</b></p>
          <p>{langSwitch==='ukr'?(<>
            <b>{state.formatDate(state.userProfile.birthday)}</b>
            <span> {lang[langSwitch].YearOfBirth}{/*р.н.*/}</span>
            </>):null}
            {langSwitch==='eng'?(<>
            <span> {lang[langSwitch].YearOfBirth}{/*р.н.*/}</span>
            <b>{state.formatDate(state.userProfile.birthday)}</b>
            </>):null}</p>

          <p> <span>{lang[langSwitch].rating}{/*рейтинг*/}: </span>
            <b> {state.userProfile.rating}</b>,<br/><br/>
            
                       <b>{raitingOfAthlete[0]} </b>
                       <span>
                        {lang[langSwitch].placeInRating}{/*місце загального рейтингу*/}
                       </span>
            {raitingOfAthlete[2]?(<>           
                       ,&nbsp;<br/><br/>
                      <b>{raitingOfAthlete[2]} </b> 
                      <span> {lang[langSwitch].placeInCategory}{/*місце в категорії*/}</span>
                       <br/>
                       <b> {state.weightCatUkr(raitingOfAthlete[1])}</b>
                     </>):'.'}
             </p>


        </div>


        <div className={s.leftCol}>

          <p> <span>
          {lang[langSwitch].sex}{/*Стать*/}: 
            </span><b> {state.userProfile.sex === 'male' ?
           lang[langSwitch].sexM//"Чолвік"
           : state.userProfile.sex === 'female' ? 
           lang[langSwitch].sexF//"Жінка"
           : ''}</b></p>
          <p><span>
          {lang[langSwitch].weight}{/*Вага*/}: 
            </span> <b>{state.userProfile.weiht} </b><span>
            {lang[langSwitch].kg}{/*кг.*/}
              </span> </p>
          <p><span>
          {lang[langSwitch].height}{/*Зріст*/}:
        
            </span> <b>{state.userProfile.height} </b><span>
            {lang[langSwitch].сm}{/*см.*/}
              </span> </p>

          <p> <span>
          {lang[langSwitch].status}{/*Статус*/}: 
            </span> <b>{state.userProfile.status}</b></p>
          <p> <span>
            1 {lang[langSwitch].coach}{/*тренер*/}: 
            </span> <b>{state.myCoach(state.userProfile.coach)}</b></p>
          <p> <span>
            2 {lang[langSwitch].coach}{/*тренер*/}: 
            </span> <b>{state.myCoach(state.userProfile.coach2)}</b></p>
          <p><span>
          {lang[langSwitch].qualification}{/*Кваліфікація*/}: 
            </span><b>{state.userProfile.dan}</b></p>
          <p><span>
          {lang[langSwitch].rank}{/*Розряд*/}: 
            </span> <b>{state.userProfile.rank}</b></p>
          <p><span>
             {lang[langSwitch].PCSS}{/*ФСТ*/}: 
           </span><b> {state.userProfile.fst}</b></p>

        </div>


        <div className={s.rigthCol}>
          <p> <span>e-Mail: </span> <b>{state.userProfile.eMail}</b></p>
          <p> <b><u><span>
          {lang[langSwitch].insurancePolicy}{/*Страховий поліс*/}
            </span></u></b> <br /><br />
            <span>
              {lang[langSwitch].insuranceCompany}{/*Страх. кoмп.*/}: 
              </span> <b>{state.userProfile.insuranceComp}</b><br />
            <span>
               {lang[langSwitch].numberOfInsurancePolicy}{/*Сер./ном. полісу*/}: 
            
              </span> <b>{state.userProfile.insuranceNum}</b><br />
            <span>
              {lang[langSwitch].insuranceDate}{/*Термін дії полісу*/}: 
              </span> <b>{state.formatDate(state.userProfile.insuranceDate)}</b>
          </p>
          <p><b><u> <span>
             {lang[langSwitch]. medicalCertificate}{/* Медичний сертифікат */}
           
            </span></u></b> <br /><br />
            <span>
               {lang[langSwitch].numberOfMedicalCertificate}{/* Сер./ном. мед. сертиф. */}
              </span> <b>{state.userProfile.medicalCertificateNum}</b><br />
            
            <span>
              {lang[langSwitch].medicalCertificateDate}{/* Термін дії мед. сертиф.*/}
             
              </span> 
            <b>{state.formatDate(state.userProfile.medicalCertificateDate)}</b>
          </p>

          <div className={s.p}><span> 
            {lang[langSwitch]. isActivatedFTU}{/*  Активований у ФТУ */}: 
            </span>
            <b> {state.userProfile.activFtu == true ? (<div className={s.blue}>
               {lang[langSwitch].yes}{/*Так*/}              
              </div>) : (<div className={s.red}>
                {lang[langSwitch].no}{/* Ні*/}                
                </div>)}</b><br />
          </div>
          <div className={s.p}><span>
             {lang[langSwitch]. isActivatedSite}{/* Активований на сайті*/}:</span>
            <b> {state.userProfile.activSite == true ? 
            (<div className={s.blue}>
            {lang[langSwitch].yes}{/*Так*/}   
              </div>) : (<div className={s.red}>
                {lang[langSwitch].no}{/* Ні*/} 
                </div>)}</b>
          </div>


        </div>

        <div className={s.footer}>
<button type='button' className={s.button} onClick={()=>{openMyResultOnCompetitiionModal(true)}}>
  {lang[langSwitch].results}{/*Результати*/} 
  </button>
  
  <br/><br/><br/>

<MyResultOnCompetitiion 
name={ state.userProfile.secondName+' '+state.userProfile.personName+' '+state.userProfile.fatherName}
 isOpen={MyResultOnCompetitiionModal}
 onClose={closeMyResultOnCompetitiionModal} 
myCompetition={myCompetition}
isSmallScreen={isSmallScreen}
langSwitch={langSwitch}
/>


        </div>



        {/* Тут можна додати інші поля */}

      </div>);
  }
}

export default UserProfile;
