import React, { useState } from 'react';
import lang from '../../../state/language';
import s from './adminCompetition.module.css'

function HandleGridsMakeCell({langSwitch, cortNumber, setData, start}) {
    
    const [stopSwitcher, setStopSwitcher] = useState(false);
    
    const [jrChildren, setJrChildren] = useState(false);
    const [children, setChildren] = useState(false);
    const [jrYounkers, setJrYounkers] = useState(false);
    const [younkers, setYounkers] = useState(false);
    const [cadets, setCadets] = useState(false);
    const [juniors, setJuniors] = useState(false);
    const [adults, setAdults] = useState(false);
    const [veterans, setVeterans] = useState(false);
    
    const [classB1, setClassB1] = useState(false);
    const [classB2, setClassB2] = useState(false);
    const [classB3, setClassB3] = useState(false);
    const [classB4, setClassB4] = useState(false);

    function takeData() {
        setData((prev) => {
            const result = { 
                cort: cortNumber,
                category:[jrChildren&&'jrChildren', children&&'children', jrYounkers&&'jrYounkers', younkers&&'younkers', cadets&&'cadets', juniors&&'juniors', adults&&'adults',veterans&&'veterans'],
                classB:[classB1&&'B1', classB2&&'B2', classB3&&'B3', classB4&&'B4', ]
            }; // Новий об'єкт, щоб не змінювати попередній стан напряму
            return [...prev, result]; // Повертаємо НОВИЙ масив (React бачить зміну)
        });
        setStopSwitcher(true)
    }
 
    if( start &&  !stopSwitcher){ takeData(); }

    return (
        <div>
                <div className={s.handleCortChange}>
                    <div className={s.cortNumber}>{lang[langSwitch].court /*Корт*/} {cortNumber}</div>
                    <div className={s.handleGridsBox}>
                        <div>
                    <form>                      
                            <div className={s.switchBox}>
                                <input
                                type='checkbox'
                                onChange={()=>{  setJrChildren(true)}}
                                />
                                <span className={s.ageCategorySwitcher1}>
                                    {lang[langSwitch].jrChildren /*Діти*/}
                                </span>
                            </div> 
                            <div className={s.switchBox}>
                                <input
                                    type='checkbox'
                                    onChange={() => { setChildren(true) }}
                                />
                                <span className={s.ageCategorySwitcher1}>
                                    {lang[langSwitch].children /*Діти*/}
                                </span>
                            </div>
                            <div className={s.switchBox}>
                                <input
                                    type='checkbox'
                                    onChange={() => { setJrYounkers(true) }}
                                /><span className={s.ageCategorySwitcher1}>
                                    {lang[langSwitch].jrYounkers /*Мол. юнаки*/}
                                </span>
                            </div>
                            <div className={s.switchBox}>
                                <input
                                    type='checkbox'
                                    onChange={() => {setYounkers(true)  }}
                                /><span className={s.ageCategorySwitcher1}>
                                    {lang[langSwitch].younkers /* юнаки*/}
                                </span>
                            </div>
                            <div className={s.switchBox}>
                                <input
                                    type='checkbox'
                                    onChange={() => { setCadets(true) }}
                                /><span className={s.ageCategorySwitcher1}>
                                    {lang[langSwitch].cadets /* Кадети*/}
                                </span>
                            </div>
                            <div className={s.switchBox}>
                                <input
                                    type='checkbox'
                                    onChange={() => { setJuniors(true) }}
                                /><span className={s.ageCategorySwitcher1}>
                                    {lang[langSwitch].juniors /* Юніори*/}
                                </span>
                            </div>
                            <div className={s.switchBox}>
                                <input
                                    type='checkbox'
                                    onChange={() => { setAdults(true)}}
                                /><span className={s.ageCategorySwitcher1}>
                                    {lang[langSwitch].adults /* Дорослі*/}
                                </span>
                            </div>
                            <div className={s.switchBox}>
                                <input
                                    type='checkbox'
                                    onChange={() => { setVeterans(true) }}
                                /><span className={s.ageCategorySwitcher1}>
                                    {lang[langSwitch].veterans /* ветерани*/}
                                </span>
                            </div>
                     
                    </form>
                    </div>

                    <div className={s.hendleVerticalAlignBox}>
                        <form>
                        <div className={s.switchBox}>
                                <input
                                type='checkbox'
                                onChange={()=>{ setClassB1(true) }}
                                />
                                <span className={s.ageCategorySwitcher1}>
                                    {lang[langSwitch].bClass1 /*Клас Б-1*/}
                                </span>
                        </div>
                        <div>
                        <div className={s.switchBox}>
                                <input
                                type='checkbox'
                                onChange={()=>{ setClassB2(true) }}
                                />
                                <span className={s.ageCategorySwitcher1}>
                                    {lang[langSwitch].bClass2 /*Клас Б-2*/}
                                </span>
                        </div>
                        <div className={s.switchBox}>
                                <input
                                type='checkbox'
                                onChange={()=>{setClassB3(true) }}
                                />
                                <span className={s.ageCategorySwitcher1}>
                                    {lang[langSwitch].bClass3 /*Клас Б-3*/}
                                </span>
                        </div>
                        <div className={s.switchBox}>
                                <input
                                type='checkbox'
                                onChange={()=>{ setClassB4(true) }}
                                />
                                <span className={s.ageCategorySwitcher1}>
                                    {lang[langSwitch].bClass4 /*Клас Б-4*/}
                                </span>
                        </div>
                        </div>
                        </form>
                    </div>
</div>

                </div>

            </div>
        
    );
};

export default HandleGridsMakeCell;