import { NavLink, useParams } from 'react-router-dom';
import lang from '../../../../state/language'
import s from '../adminCompetition.module.css'
import state from '../../../../state/state';
import TableGrid from './tableGrid';
import { useEffect, useState } from 'react';
import PrintPoomsaeGrids from './printPoomsaeGrids';


function PoomsaeCutOffSystemGrids({ langSwitch, isSmallScreen }) {
    const { id } = useParams(); //отримуємо id трурниру
    const [isTableGridOpen, setTableGridOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [printGrids, setPrintGrids] = useState(false);
    const [controlDesk, setControlDesk] = useState(null);
    const [isFirstRender, setIsFirstRender] = useState(true);


    const competition = state.competition.filter((tourn) => tourn.id == id)[0]; //турнир в массиві
    let poomsaeCategories, gridsDB
    try { poomsaeCategories = JSON.parse(competition.poomsaeCategories) }
    catch { poomsaeCategories = competition.poomsaeCategories }


     try { gridsDB = JSON.parse(competition.grids) }
    catch { gridsDB = competition.grids }

    const corts = Number(competition.corts) ? Number(competition.corts) : 1


     useEffect(() => {
       if (isFirstRender) {
         setIsFirstRender(false); // Після першого рендеру скидаємо флаг
         return;
       }
       openTableGridModal();
     }, [controlDesk]);
     
   
    function cutOffSystemGridsMaker() { //формуємо сітки

        let battlesOnCort //кількість поединків на корті

        function battlesOnCortCounter(poomsaeCategories) {//рахуємо кількість поединків на корті
            let count = 0 //загальна кількість виступів
            poomsaeCategories.forEach((item) => {
                if (item.athletes.length > 19) {
                    count = count + (item.athletes.length + 19 + 8)
                    item.battlesInCategory = item.athletes.length + 19 + 8
                }
                if (item.athletes.length > 8 && item.athletes.length < 20) {
                    count = count + (item.athletes.length + 8)
                    item.battlesInCategory = item.athletes.length + 8
                }
                if (item.athletes.length < 9) {
                    count = count + item.athletes.length
                    item.battlesInCategory = item.athletes.length
                }
            })

            battlesOnCort = Math.round(count / corts)
        }
        battlesOnCortCounter(poomsaeCategories)

        function cortMaker() {//розподіляємо категорії по кортам
            //сортіемо категорії від більшої до меншої
            const poomsaeCategoriesData = poomsaeCategories.sort((a, b) => b.battlesInCategory - a.battlesInCategory)
            const result = []

            for (let i = 1; i <= corts; i++) {
                let battlesCount = 0 //кількість реальних поєдинків на корті
                poomsaeCategoriesData.forEach((item) => {
                    const test = result.some((item2) => item2.category === item.category)

                    if (i == corts && !test) {
                        result.push({
                            category: item.category,
                            athletes: item.athletes,
                            cort: i,
                            battleNumbers: []
                        })
                    } else {
                        if ((battlesCount + item.battlesInCategory) <= battlesOnCort && !test) {
                            battlesCount = battlesCount + item.battlesInCategory
                            result.push({
                                category: item.category,
                                athletes: item.athletes,
                                cort: i,
                                battleNumbers: []
                            })
                        }
                    }
                })
            }
            return result
        }
        const gridsWithoutBattelesNumbers = cortMaker()


        function spelBattleNumber(gridsData) {// прописуємо номери поэдинків
            let battleCount = 1
       
            const preliminaryRound = gridsData.map((category) => {   //відбірковий тур    
                if (category.athletes.length > 19) {
                    for (let i = 1; i <= category.athletes.length; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                }

                return category
            })

         
            const _1_2Finale = preliminaryRound.map((category) => { //півфінал
                if (category.athletes.length >= 19) {
                    for (let i = 1; i <= 19; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                }
                if (category.athletes.length > 8 && category.athletes.length < 19) {
                    for (let i = 1; i <= category.athletes.length; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                }

                return category
            })

            const Finale = _1_2Finale.map((category) => { //фінал
                if (category.athletes.length >= 8) {
                    for (let i = 1; i <= 8; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                }
                if (category.athletes.length < 8) {
                    for (let i = 1; i <= category.athletes.length; i++) { category.battleNumbers.push(battleCount); battleCount++; }
                }

                return category
            })
        }

        for (let i = 1; i <= corts; i++) {//сортуємо категорії за кортом та викликаєм прописник номерів поєдинків
            const filteredGrids = gridsWithoutBattelesNumbers.filter((item) => (item.cort == i))
            spelBattleNumber(filteredGrids)
        }

        return gridsWithoutBattelesNumbers
    }

    const poomsaeGrids = cutOffSystemGridsMaker()
  
    // Модальне вікно з сітками

    const openTableGridModal = () => { setTableGridOpen(true); }
    const closeTableGridModal = () => { setTableGridOpen(false); setControlDesk(null) }
    ////////////////////////////////////////////////////////////////////////////////

    return (<>
        <div className={s.noPrint} >
        {errorMessage ? (<><div className={s.errorMessage} onClick={() => { setErrorMessage(null) }}
          style={{ cursor: 'pointer' }}>
          {errorMessage}
        </div>
        </>) : null} 
        {successMessage ? (<><div className={s.successMessage} onClick={() => { setSuccessMessage(null) }}
          style={{ cursor: 'pointer' }}>
          {successMessage}
        </div>
        </>) : null}
            <div >
                <h1 className={s.h1}>{competition.title}</h1>
                <h2 className={s.h2}>{lang[langSwitch].tournamentGrids/* Турнірні сітки*/}</h2>
            </div>

{Number(state.userProfile.id) === Number(competition.secretary)||Number(state.userProfile.id) === Number(competition.mainJudge)?(<>

            <div className={!isSmallScreen ? s.contentTitleItem1 : s.td1}>
                <div className={s.title1}>
                    <button className={s.butt} type="button" onClick={() => { setControlDesk(3) }}>
                        {lang[langSwitch].generateGrids /* Генерувати сітки*/}
                    </button>
                </div> 
          
            <div className={s.title2}>
                
                <button className={s.butt} type='button' onClick={() => {
                  gridsDB&&Array.isArray(gridsDB)&&gridsDB.length > 0 ?                       
                  setControlDesk(2) 
                        :
                        setErrorMessage(
                            lang[langSwitch].youHaveNoSavedGrids/* У вас немає збережених сіток. перейдіть у вкладку "Генерувати сітки"*/
                        )
                }}>
                    {lang[langSwitch].judgeNotes /*Суддівські нотатки */}
                </button>

                 <button className={s.butt} type='button' onClick={() => {
                  gridsDB&&Array.isArray(gridsDB)&&gridsDB.length > 0 ?                       
                     setControlDesk(1) 
                
                        :
                        setErrorMessage(
                            lang[langSwitch].youHaveNoSavedGrids/* У вас немає збережених сіток. перейдіть у вкладку "Генерувати сітки"*/
                        )
                }}>
                    {lang[langSwitch].controlDesks/*Пульти*/}
                </button>
            </div>

            <div className={s.title3}>
        <button className={s.butt} type='button' onClick={() => { 
        gridsDB&&Array.isArray(gridsDB)&&gridsDB.length > 0 ?
        setPrintGrids(!printGrids) :
          setErrorMessage(
            lang[langSwitch].youHaveNoSavedGrids/*У вас немає збережених сіток. перейдіть у вкладку "Генерувати сітки"*/
                     );}}>
         
          {!printGrids?
           lang[langSwitch].print /*Друкувати*/
           :
            lang[langSwitch].closePrint /*Закрити друк*/
          }</button>

          <NavLink to={`/AdminCompetition/${competition.id}`}> 
        <button className={s.exitButton} type='button' >
        {lang[langSwitch].comeBack /* Повернутись*/}
          </button>
        </NavLink>
      </div>

        </div>  
        </>):(<>
            <button className={s.butt} type='button' onClick={() => {
                  gridsDB&&Array.isArray(gridsDB)&&gridsDB.length > 0 ?                       
                 setControlDesk(1)               
                        :
                        setErrorMessage(
                            lang[langSwitch].youHaveNoSavedGrids/* У вас немає збережених сіток. перейдіть у вкладку "Генерувати сітки"*/
                        )
                }}>
                    {lang[langSwitch].controlDesks/*Пульти*/}
                </button>

                
                <NavLink to={`/AdminCompetition/${competition.id}`}> 
        <button className={s.exitButton} type='button' >
        {lang[langSwitch].comeBack /* Повернутись*/}
          </button>
        </NavLink>
     
        
        </>)}  
        </div>
{controlDesk?(<>
        <TableGrid        
            langSwitch={langSwitch}
            grids={poomsaeGrids}
            gridsDB={gridsDB}
            isOpen={isTableGridOpen}
            onClose={closeTableGridModal}
            competition={competition}
            isSmallScreen={isSmallScreen}
            controlDesk={controlDesk}
        />
</>):null}
{printGrids?(<PrintPoomsaeGrids 
langSwitch={langSwitch} 
     gridsDB={gridsDB}     
     competition={competition}     
     setErrorMessage={setErrorMessage}  
        />):null}
    </>)
}

export default PoomsaeCutOffSystemGrids