import axios from 'axios';
import state from '../../../state/state.js'
import s from './mail.module.css'


function ConfirmAthlete(props) {
console.log(props)

    return(<div>

<div>Спортсмен {state.myCoachFull(props.parsedData.userId)} вказує вас 
{props.parsedData.coach1== 'coach'?' головним тренером ':null} 
    {props.parsedData.coach1== 'coach2'?' особистим тренером ':null}

    <div className={s.boxForButtons}>
     <div className={s.buttonL}>
    <button type='button'className={s.button} 
    onClick={()=>{props.updateTeame(props.parsedData.userId, props.parsedData.coach1)}}>Підтвердити</button>
    </div>
     <div className={s.buttonR}>
    <button type='button'className={s.button} 
    onClick={()=>{props.setIsRead(1); props.deleteMessage(props.id)}}>Скасувати</button>
    </div>
    </div>
</div>
    </div>)
}

export default ConfirmAthlete