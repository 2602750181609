import { NavLink, useParams } from 'react-router-dom';
import s from './result.module.css'
import state from '../../../state/state.js';


function ResultByRating({ competition, langSwitch }) {

  const resultData = JSON.parse(competition.results).competitionResult

  const result = resultData.reduce((acc, item) => {
    const existing = acc.find(obj => obj.id === item.id);

    if (existing) {
      // Додаємо числові значення
      existing.battlesOnComp = (+existing.battlesOnComp || 0) + (+item.battlesOnComp || 0);
      existing.ratingOnComp = (+existing.ratingOnComp || 0) + (+item.ratingOnComp || 0);
      existing.victoryOnComp = (+existing.victoryOnComp || 0) + (+item.victoryOnComp || 0);

      // Об'єднуємо `myCompetition`
      const existingCompetitionCategory = existing.competitionCategory || "";
      const newCompetitionCategory = item.competitionCategory || "";

      existing.competitionCategory = [existingCompetitionCategory, newCompetitionCategory];
    } else {
      // Додаємо новий об'єкт у результат
      acc.push({ ...item });
    }

    return acc;
  }, []);



  console.log('result: ', result)

  const data = result.sort((a, b) => b.ratingOnComp - a.ratingOnComp);

  return (<>
    <div className={s.mainDivForPrint}>
      <table className={s.mailTableResultByAthlets}>{/* початок зовнішньої таблиці */}
        <thead>
          <tr>
            <td colSpan='12'>
              <div className={s.headerTitle}>
                <div className={s.competitionTitle}>{competition.title}</div>
                <div className={s.competitionLocation}>{competition.location}</div>
                <div >
                  {competition.dateStart && competition.dateFinish ? (<>
                    <u><b>{state.formatDate(competition.dateStart).slice(0, 2)}</b>-
                      <b>{state.formatDate(competition.dateFinish)} р.</b></u>
                  </>) : (<>
                    <u><b>  {competition.dateOfCompetition}р.</b></u>
                  </>)}
                  <br /><br /></div>
                <div className={s.textTitle}>Результати індивідуальних виступів спортсменів в рейтинговому заліку у програмі "Двобої"</div>

              </div>
            </td>
          </tr>
          <tr className={s.headerTable}>
            <td style={{ width: '5%' }}>№</td>
            <td style={{ width: '10%' }}>Учасник</td>
            <td style={{ width: '10%' }}>Категорії</td>
            <td style={{ width: '5%' }}>Рейт.</td>
            <td style={{ width: '5%' }}>Бої</td>
            <td style={{ width: '5%' }}>Пер.</td>
            <td style={{ width: '10%' }}>Дата народження</td>
            <td style={{ width: '10%' }} >Осередок/<br />спортклуб</td>
            <td style={{ width: '10%' }}>Гип/Дан</td>
            <td style={{ width: '10%' }}>Сп.<br />Кваліф.</td>
            <td style={{ width: '10%' }}>ФСТ</td>
            <td style={{ width: '10%' }}>Тренер / <br />Тренер 2</td>
          </tr>
        </thead>

        <tbody>
          {data.map((item, index) => (
            <tr key={item.gridId + index}>{console.log(item)}
              <td >{index + 1}</td>
              <td >{item.secondName} {item.personName}</td>
              <td >
                {Array.isArray(item.competitionCategory)
                  ? item.competitionCategory.map((cat, index) => (
                    <span key={index}>
                      {state.weightCatUkr(cat, langSwitch)}
                      <br />
                    </span>
                  ))
                  : state.weightCatUkr(item.competitionCategory, langSwitch)}
              </td>

              <td >{item.ratingOnComp}</td>
              <td >{item.battlesOnComp}</td>
              <td >{item.victoryOnComp}</td>
              <td >{state.formatDate(item.birthday)}</td>
              <td>{state.regionShort(item.region)}<br />{item.sportClub}</td>
              <td >{item.dan}</td>
              <td >{item.rank}</td>
              <td >{item.fst}</td>
              <td> {state.myCoach(item.coach)}<br />{state.myCoach(item.coach2)}</td>
            </tr>

          ))}
        </tbody>

        <tfoot>
          <tr>
            <td colSpan='7'>
              <div className={s.foterOfDocument}>
                <br />
                Гол. суддя  &nbsp;&nbsp;<b> {state.myCoach(competition.mainJudge)} </b>
                &nbsp;&nbsp; <span className={s.infoDescription}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  (підпис)
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>  &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;

                &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                м.п.
              </div>
            </td>
            <td colSpan='5'>

              <div className={s.foterOfDocument}>
                <br />
                Гол. секретар &nbsp;&nbsp; <b> {state.myCoach(competition.secretary)}</b>
                &nbsp;&nbsp; <span className={s.infoDescription}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  (підпис)
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>  &nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

              </div>
            </td>
          </tr>
        </tfoot>


      </table> {/* кінець зовнішньої таблиці */}
      <br /><br />

    </div>
  </>)
}

export default ResultByRating