import React, { useState } from 'react';
import s from '../adminCompetition/adminCompetition.module.css'
import lang from '../../../state/language';
import state from '../../../state/state';
import indexedDBConnection from '../../../state/indexedDBConnection';


function ScaleCategoriesCell ({
              categoriesData,
              sortedCategories,           
              langSwitch, 
              openCategoryCellModal,            
              scale,
              setCategoryDataScaleAthletesCell,setIsOpenScaleAthletesCell,
              transferAthleteData,
              setTransferOpen,
              setTransferAthleteData,
              id}) {



categoriesData=categoriesData.filter((item)=>Array.isArray(item.athletes)&&item.athletes.length>0)


          function transfer(category){


            //видаляємо спортсмена з старої категорії
            if(sortedCategories){
const filteredAthlete = sortedCategories[transferAthleteData.category].filter((item)=>item.
gridId!==transferAthleteData.athlete.gridId)
sortedCategories[transferAthleteData.category]=filteredAthlete
}

// змінюємо йщго gridId згідно з новою категорією
let [categoryName, clasData] = category.category.split("_");
const clas = clasData.slice(0, 2)
const athleteFullData = {...transferAthleteData.athlete, gridId:category.category+transferAthleteData.athlete.id+clas}

//додаємо спортсмена в нову категорію
if(sortedCategories){
sortedCategories[category.category].push(athleteFullData)


       let  athletesForGrids =[]


 indexedDBConnection.loadData('athletesForGrids').then((athletesForGridsData)=>{
if(athletesForGridsData.length>0){
  athletesForGrids = athletesForGridsData.filter((item)=>Number(item.id)!==Number(id))
}
    })

athletesForGrids.push({id: id, sortedCategories:sortedCategories})

indexedDBConnection.saveData('athletesForGrids',athletesForGrids)


      setTransferOpen(false)
      setTransferAthleteData('')
          }      
   
  }        
    function categoryOpen (data){
      setCategoryDataScaleAthletesCell(data)
      setIsOpenScaleAthletesCell(true)
      openCategoryCellModal(data)
    }

  return (
    <div className={s.gridContainerForCategories}>
      
        
    {categoriesData.map((cat,index) => (
      <div 
        key={cat.category+index} 
        className={s.categoryBlock}
        onClick={()=>{transferAthleteData===''?categoryOpen(cat):transfer(cat)}}>
        <div className={s.boxTitile}  
         style={{ cursor: 'pointer' }}>
        <div className={s.categoryBlockTitle}>
          {
          scale?
            state.weightCatUkr(cat.category, langSwitch, true)
          :
            state.weightCatUkr(cat.category)            
          }

          </div>
<div className={s.region}>
{cat.athletes.length%10===1?
lang[langSwitch].declared1 /*Заявлений*/+': '
:
lang[langSwitch].declared /*Заявлено*/+': '
}
<b>{cat.athletes.length}</b>
{cat.athletes.length%10===0?
' '+lang[langSwitch].participants /*учасників*/
:''}
{cat.athletes.length%10===1&&cat.athletes.length!==11?
' '+lang[langSwitch].participant /*учасник*/
:''}
{(cat.athletes.length % 10 >= 2 && cat.athletes.length % 10 <= 4) && (cat.athletes.length % 100 < 10 || cat.athletes.length % 100 >= 20) ? 
' '+lang[langSwitch].participants1 /*учасникa*/
: ''}

{cat.athletes.length%10>4||cat.athletes.length===11?
' '+lang[langSwitch].participants /*учасників*/
:''}
<br/>&nbsp;</div>
      </div>

      </div>
    ))}

   


  </div>




  );
};

export default ScaleCategoriesCell;